
 
*{
  margin:0;
}
.navlog-linked-text{
  text-decoration: none;
  }
  .navlog-logo-10{
      margin-bottom: -20px;
    }
.navlog-yaa{

width:200px;
color:#054652;
font-weight: 800;
margin-top: 0px;
height:200px;



}
/* Navbar.css */
.tiger a {



  transition: transform 0.3s ease; /* Add transition property */
}

  .tiger ul p a{
  color:#054652
  COPY
  ;
  font-size: 20px;
  font-weight: 100px;

  transition: transform 0.3s ease; /* Add transition property */
  font-family:'Times New Roman', Times, serif;
}


.tiger a:hover {
  color: rgb(7, 7, 7);
  transform: scale(1.1);
 
  padding-left:4px;

}


.tiger{
  margin-right: -450px;
  margin-bottom: 40px;
 
}
.navlog-dropdown {
  position: relative;
  display: inline-block;
  margin-left: 0px;
}

.navlog-publish{
  margin-left: 25px;
}

.navlog-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 215px;
  margin-left: 0px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.navlog-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navlog-dropdown-content a:hover {
  background-color: #05144c;
  border:1px solid rgb(201, 189, 189);
  color:white;
  width:92%;
  margin-left:8px;
  height:40px;

}

.navlog-dropdown:hover .navlog-dropdown-content {
  display: block;
}






.tiger ul{
  display:flex;
  margin-top: 30px;
  margin-left:500px;


}




.navlog-icon{
  font-size: 30px;
  color:#054652
  COPY
  ;
  font-weight: 1060;

 
}
.navlog-icons{
  font-size: 20px;
  color:#054652 COPY;
  background-color: #dfdfe1;
  border:1px solid rgb(227, 219, 219);
  width:30px;
  height:30px;
  border-radius: 100px;
  font-weight: 1000;
  margin-left:0px;
  margin-bottom: 0px;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
  }
 
  :root {
  --mainColor: #f8f7f2;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: black;
  }
 
  header {
    position:fixed;
    z-index: 1000; /* Ensure the navbar stays on top of other elements */
    top: -3px;
    width:100%;
margin-top: 2%;
 
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 2rem;
  background-color:rgb(235, 229, 229);
  color: var(--textColor);
    /* padding-left: 25px; */
  }
 
  nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  }
 
 
 
 
  nav a:hover {
  color: var(--secondaryColor);
  }
 
  header .navlog-nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
 
  opacity: 0;
  font-size: 1.8rem;
  }
 
  header div,
  nav {
  display: flex;
  align-items: center;
  }
  .navlog-cnavlog{
    background-color: #05144c;
    width:100%;
    height:30px;
    position:fixed;
    z-index: 1000;
  }
 
  @keyframes eyeMove {
  0%, 10% {
    background-position: 0px 8px;
  }
  13%, 40% {
    background-position: -5px 0px;
  }
  43%, 70% {
    background-position: 5px 0px;
  }
  73%, 90% {
    background-position: 0px 8px;
  }
  93%, 100% {
    background-position: 0px 8px;
  }
  }
 
  @keyframes blink {
  0%, 10%, 12%, 20%, 22%, 40%, 42%, 60%, 62%, 70%, 72%, 90%, 92%, 98%, 100% {
    height: 28px;
  }
  11%, 21%, 41%, 61%, 71%, 91%, 99% {
    height: 9px;
  }
  }
 



  @media only screen and (min-width: 240px) and (max-width: 319px) {  
    .navlog-dropdown-content {
      display: none;
      position: absolute;
      background-color: white;
      min-width: 290px;
      margin-left: 10px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      margin-top: -70px;
    }
    header {
      position:fixed;
      z-index: 1000; /* Ensure the navbar stays on top of other elements */
      top: 0;
      width:100%;
  margin-top: 1%;
   
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 2rem;
    background-color:rgb(235, 229, 229);
    color: var(--textColor);
      /* padding-left: 25px; */
    }
    
    .navlog-dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
    
    .navlog-dropdown-content a:hover {
      background-color: #05144c;
      border:1px solid rgb(201, 189, 189);
      color:white;
      width:90%;
      margin-left:14px;
      height:50px;
    
    }
      /* Styles for medium devices (laptops) */
    .navlog-yaa{
      width:200px;
      height:200px;
      margin-top:2px;
    }
    /* .navlog-cnavlog{
      margin-top: -80px;
    } */
    
    .tiger ul{
      display: block;
      text-align: center;
      margin-right:250px;
     
     
    }
    .navlog-yee{
      display:flex;
      font-size:18px;
    }
    
    .tiger{
      margin-right: -50px;
    }
    .navlog-icon{
      margin-right: 350px;
    }
    .navlog-icons{
      margin-left: -50px;
      margin-top: -100px;
    }
    
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      font-family: "Titillium Web", sans-serif;
      }
     
      :root {
      --mainColor: #f8f7f2;
      --mainColorLight: #5767aa;
      --secondaryColor: #db2b39;
      --textColor: black;
      }
    
  }
  @media only screen and (min-width: 320px) and (max-width: 359px) {  
    .navlog-dropdown-content {
      display: none;
      position: absolute;
      background-color: white;
      min-width: 290px;
      margin-left: 10px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      margin-top: -70px;
    }
    
    .navlog-dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
    
    .navlog-dropdown-content a:hover {
      background-color: #05144c;
      border:1px solid rgb(201, 189, 189);
      color:white;
      width:90%;
      margin-left:14px;
      height:50px;
    
    }
      /* Styles for medium devices (laptops) */
    .navlog-yaa{
      width:200px;
      height:200px;
      margin-top:2px;
    }
    /* .navlog-cnavlog{
      margin-top: -80px;
    } */
    
    .tiger ul{
      display: block;
      text-align: center;
      margin-right:250px;
     
     
    }
    .navlog-yee{
      display:flex;
      font-size:18px;
    }
    
    .tiger{
      margin-right: -50px;
    }
    .navlog-icon{
      margin-right: 350px;
    }
    .navlog-icons{
      margin-left: -50px;
      margin-top: -100px;
    }
    
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      font-family: "Titillium Web", sans-serif;
      }
     
      :root {
      --mainColor: #f8f7f2;
      --mainColorLight: #5767aa;
      --secondaryColor: #db2b39;
      --textColor: black;
      }
    
  }

  @media only screen and (min-width: 360px) and (max-width: 374px) {  
    .navlog-dropdown-content {
      display: none;
      position: absolute;
      background-color: white;
      min-width: 290px;
      margin-left: 10px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      margin-top: -70px;
    }
    
    .navlog-dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
    
    .navlog-dropdown-content a:hover {
      background-color: #05144c;
      border:1px solid rgb(201, 189, 189);
      color:white;
      width:90%;
      margin-left:14px;
      height:50px;
    
    }
      /* Styles for medium devices (laptops) */
    .navlog-yaa{
      width:200px;
      height:200px;
      margin-top:2px;
    }
    /* .navlog-cnavlog{
      margin-top: -80px;
    } */
    
    .tiger ul{
      display: block;
      text-align: center;
      margin-right:250px;
     
     
    }
    .navlog-yee{
      display:flex;
      font-size:18px;
    }
    
    .tiger{
      margin-right: -50px;
    }
    .navlog-icon{
      margin-right: 350px;
    }
    .navlog-icons{
      margin-left: -50px;
      margin-top: -100px;
    }
    
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      font-family: "Titillium Web", sans-serif;
      }
     
      :root {
      --mainColor: #f8f7f2;
      --mainColorLight: #5767aa;
      --secondaryColor: #db2b39;
      --textColor: black;
      }
    
  }


  @media only screen and (min-width: 375px) and (max-width: 410px) {  
  
.navlog-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 290px;
  margin-left: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-top: -70px;
}

.navlog-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navlog-dropdown-content a:hover {
  background-color: #05144c;
  border:1px solid rgb(201, 189, 189);
  color:white;
  width:90%;
  margin-left:14px;
  height:50px;

}
  /* Styles for medium devices (laptops) */
.navlog-yaa{
  width:200px;
  height:200px;
  margin-top:2px;
}
/* .navlog-cnavlog{
  margin-top: -80px;
} */

.tiger ul{
  display: block;
  text-align: center;
  margin-right:250px;
 
 
}
.navlog-yee{
  display:flex;
  font-size:18px;
}

.tiger{
  margin-right: -50px;
}
.navlog-icon{
  margin-right: 350px;
}
.navlog-icons{
  margin-left: -50px;
  margin-top: -100px;
}



}
@media only screen and (min-width: 411px) and (max-width: 768px) {  
  
  .navlog-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 290px;
    margin-left: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -70px;
  }
  
  .navlog-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  header {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 2rem;
    background-color: rgb(235, 229, 229);
    color: var(--textColor);
    /* padding-left: 25px; */
}
  
  .navlog-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:14px;
    height:50px;
  
  }
    /* Styles for medium devices (laptops) */
  .navlog-yaa{
    width:200px;
    height:200px;
    margin-top:2px;
  }
  /* .navlog-cnavlog{
    margin-top: -80px;
  } */
  
  .tiger ul{
    display: block;
    text-align: center;
    margin-right:250px;
   
   
  }
  .navlog-yee{
    display:flex;
    font-size:18px;
  }
  
  .tiger{
    margin-right: -50px;
  }
  .navlog-icon{
    margin-right: 350px;
  }
  .navlog-icons{
    margin-left: -50px;
    margin-top: -100px;
  }
  
  
  
  }




@media only screen and (min-width: 769px) and (max-width: 1024px) {  

  .navlog-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 290px;
    margin-left: 0px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: -90px;
  }
 
  .navlog-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
 
  .navlog-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:14px;
    height:50px;
 
  }

  .navlog-icon{
    margin-right: 350px;
    margin-left: -60px;
    font-size: 30px;
  }
  .navlog-icons{
    margin-left: -20px;
   
    margin-top: -140px;
  }

  header .navlog-nav-btn {
  visibility: visible;
  opacity: 1;
color:black;
}
 

header nav {
  position: fixed;
  top: -100vh;
  left: 0;
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: rgb(231, 223, 223);
  transition: 1s;
}
.navlog-text{
  margin-left: 20px;;
}

.navlog-yaa{
  margin-top:20px;

  width:200px;
  height:200px;
}


nav .nav-close-btn {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
.tiger ul{
  display: block;

  font-size:50px;
  margin-right: 500px;
 
}

nav a {
  font-size: 1.5rem;
}



}



