 
/* Default styles */
.ridespage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.ridespage-search{
  margin-left: -30px;
}
.SA-searchContainer{
width: 71%;
height:50px;
margin-top: 150px;

 }

 .SA-search-input{
  margin: 10px 0px;
  padding: 7px;
  margin-right: auto;
  width:700px;
  height:40px;
  border-radius: 20px;
  margin-top: 5px;


}
.SA-searchcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
 
 
.ridespage-content {
  /* width: 100%; */
  max-width: 1175px;
  margin-top: 40px;
  /* border: 1px solid #f10909;  */
margin-left: 0px;
}
 
.ridespage-title {
  margin-bottom: 20px;
  margin-left: 0px;
  text-align: center;

  border:1px solid rgb(216, 207, 207);
  width:70vw;
  background-color: #05144c;
  color:white;
  font-family: 'Times New Roman', Times, serif;
}
 
.rides-table-container {
  max-height: 350px; /* Adjust the max height as per your requirement */
  overflow-y: scroll;
  width: 71vw;
  margin-top:-20px;
  margin-left: 0px;
  overflow-x: scroll;

  border: 1px solid #ccc; 
}
 
.rides-table {
  width: 170%;
  border-collapse: collapse;
  margin-top: 0px;
  /* border: 1px solid #f10909;  */

}
 
.rides-table thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  /*layanu*/
  border: 0px solid white;
 
  background-color: #05144c;
  color: #ffffff;
}
.lvp-rides-th, .lvp-rides-td {
  border: 1px solid #ddd;
  padding: px;
  text-align: left;
}
 
.lvp-rides-th {
  background-color:#05144c;
  color:white;
 
}
 
.lvp-action-btn {
  margin-right: 8px;
  padding: 6px 12px;
  cursor: pointer;
}
 
.lvp-view-btn {
  background-color: #05144c; /* Adjust the background color */
  color: white;
  border: none;
  border-radius: 5px;
}
 
.lvp-delete-btn {
  background-color: red; /* Adjust the background color */
  color: white;
  border: none;
  border-radius: 5px;
}
 
.lvp-view-btn:hover,
.lvp-delete-btn:hover {
  opacity: 0.8;
}
 
.lvp-rides-td1 {
  display: flex;
}
 
.lvp-rides-th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
 
.lvp-rides-th.increase-width {
  width: 150px; /* Adjust width as per your design */
}
@media only screen and (min-width: 320px) and (max-width: 359px) {
  .SA-searchContainer{
    width: 100%;
    height:50px;
    margin-top: 100px;

    margin-left: 0px;

    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:200px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 0px;



    }
 
  .ridespage-content {
    margin-top:0px;

    padding: 15px;
width: 300px;


  }
 
  .rides-table-container table{
 
    width: 1600px;
  }
 
  .ridespage-title {
    
    width:90vw;
    margin-left: -10px;
  }
 
  .rides-table-container {
    max-height: 180px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 90vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: -10px;
 
    border: 0px solid #ccc; /* Add border to the table container */
  }
  .lvp-action-btn {
    font-size: 12px;
    padding: 4px 8px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 6px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .SA-searchContainer{
    width: 100%;
    height:50px;
    margin-top: 100px;

    margin-left: 0px;

    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:300px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 0px;


    }
 
  .ridespage-content {
    margin-top:0px;

    padding: 15px;
width: 350px;


  }
  .rides-table-container table{
 
    width: 1600px;
  }
 
  .ridespage-title {
  
    text-align: center;
    border:0px solid rgb(216, 207, 207);
    width:85vw;
  background-color: #05144c;
  margin-left: 0px;
  color:white;
    font-family: 'Times New Roman', Times, serif;
  }
 
  .rides-table-container {
    max-height: 330px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 85vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: 0px;
 
    border: 0px solid #ccc; /* Add border to the table container */
  }
  .lvp-action-btn {
    font-size: 12px;
    padding: 4px 8px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 6px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 383px) {
  .SA-searchContainer{
    width: 100%;
    height:50px;
    margin-top: 100px;

    margin-left: 0px;

    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:300px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 0px;


    }
 
  .ridespage-content {
    padding: 15px;
    margin-top:0px;

width: 350px;


  }
  .rides-table-container table{
 
    width: 1600px;
  }
 
  .ridespage-title {
  
    text-align: center;
    border:0px solid rgb(216, 207, 207);
    width:85vw;
    background-color: #05144c;
    margin-left: 0px;
    color:white;
    font-family: 'Times New Roman', Times, serif;
  }
 
  .rides-table-container {
    max-height: 400px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 85vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: 0px;
 
    border: 0px solid #ccc; /* Add border to the table container */
  }
  .lvp-action-btn {
    font-size: 12px;
    padding: 4px 8px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 6px;
  }
}
@media only screen and (min-width: 390px) and (max-width: 411px) {
  .SA-searchContainer{
    width: 100%;
    height:50px;
    margin-top: 100px;

    margin-left: 0px;

    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:300px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 0px;


    }
 
  .ridespage-content {
    padding: 15px;
    margin-top:0px;

width: 350px;

  }
 
  .rides-table-container table{
 
    width: 1600px;
  }
 
  .ridespage-title {
  
    text-align: center;
    border:0px solid rgb(216, 207, 207);
    width:90vw;
  background-color: #05144c;
  margin-left: -15px;
  color:white;
    font-family: 'Times New Roman', Times, serif;
  }
 
  .rides-table-container {
    max-height: 400px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 90vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: -15px;
 
    border: 0px solid #ccc; /* Add border to the table container */
  }
  .lvp-action-btn {
    font-size: 12px;
    padding: 4px 8px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 6px;
  }
}
@media only screen and (min-width: 412px) and (max-width: 427px) {
  .SA-searchContainer{
    width: 100%;
    height:50px;
    margin-top: 100px;

    margin-left: 0px;

    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:300px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 0px;


    }
 
  .ridespage-content {
    padding: 15px;
    margin-top:0px;

width: 400px;

  }
 
  .ridespage-container {
    overflow: hidden;
    margin-left: 0px;

  }
 

 
  .rides-table-container table{
 
    width: 1600px;
  }
 
  .ridespage-title {
    text-align: center;
    border:0px solid rgb(216, 207, 207);
    width:87vw;
  background-color: #05144c;
  margin-left: -0px;
  color:white;
    font-family: 'Times New Roman', Times, serif;
  }
 
  .rides-table-container {
    max-height: 400px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 87vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: -0px;
 
    border: 0px solid #ccc; /* Add border to the table container */
  }
  .lvp-action-btn {
    font-size: 12px;
    padding: 4px 8px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 6px;
  }
}
@media only screen and (min-width: 428px) and (max-width: 767px) {
  .SA-searchContainer{
    width: 100%;
    height:50px;
    margin-top: 100px;

    margin-left: 0px;

    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:300px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 0px;


    }
 
  .ridespage-content {
    margin-top:0px;

    padding: 15px;
width: 400px;

  }
 

 

  .rides-table-container table{
 
    width: 1600px;
  }
 
  .ridespage-title {
    text-align: center;
    border:0px solid rgb(216, 207, 207);
    width:90vw;
  background-color: #05144c;
  margin-left: -10px;
  color:white;
    font-family: 'Times New Roman', Times, serif;
  }
 
  .rides-table-container {
    max-height: 430px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 90vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: -10px;
 
    border: 0px solid #ccc; /* Add border to the table container */
  }
  .lvp-action-btn {
    font-size: 12px;
    padding: 4px 8px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 6px;
  }
}
 
@media only screen and (min-width: 768px) and (max-width: 799px) {
  .ridespage-container {
    padding: 10px;
    margin-left: 0px;
  }
 
  .SA-searchContainer{
    width: 90%;
    height:50px;
    margin-top: 150px;

    margin-left: 0px;

    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:500px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 0px;


    }
 
  .ridespage-content {
    padding: 15px;
width: 700px;

  }
 
  .ridespage-title {
    margin-top: -10px;
    margin-bottom: 20px;
    text-align: center;
    border:1px solid rgb(216, 207, 207);
    width:90vw;
  background-color: #05144c;
  margin-left: -12px;
  color:white;
    font-family: 'Times New Roman', Times, serif;
  }
 
  .rides-table-container {
    max-height: 480px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 90vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: -12px;
 
    border: 1px solid #ccc; /* Add border to the table container */
  }
  .rides-table-container table{
 
    width: 1600px;
  }
  .lvp-action-btn {
    font-size: 14px;
    padding: 6px 10px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 8px;
  }
}
@media only screen and (min-width: 800px) and (max-width: 819px) {
  .ridespage-container {
    padding: 10px;
    margin-left: 0px;

  }
 
  .SA-searchContainer{
    width: 100%;
    height:50px;
    margin-top: 150px;


    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:500px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 0px;


    }
 
  .ridespage-content {
    padding: 15px;

  }
 
  .ridespage-title {
    margin-top: -10px;
    margin-bottom: 20px;
    text-align: center;
    border:1px solid rgb(216, 207, 207);

    width:90vw;
  background-color: #05144c;
  margin-left: 0px;
  color:white;
    font-family: 'Times New Roman', Times, serif;
  }
 
  .rides-table-container {
    max-height: 480px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 90vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: 0px;

    border: 1px solid #ccc; 
  }
  .rides-table-container table{
 
    width: 1600px;
  }
  .lvp-action-btn {
    font-size: 14px;
    padding: 6px 10px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 8px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 833px) {
  .ridespage-container {
    padding: 10px;
    margin-left: 0px;


  }
  .rides-table-container table{
 
    width: 1600px;
  }
 
  .SA-searchContainer{
    width: 100%;
    height:50px;
    margin-top: 150px;


    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:600px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 40px;



    }
 
  .ridespage-content {
    padding: 15px;
    margin-left: 0px;
width: 750px;
 
  }
 
  .ridespage-title {
    margin-top: -10px;
    margin-bottom: 20px;
    text-align: center;
    border:1px solid rgb(216, 207, 207);
    width:90vw;
  background-color: #05144c;
  margin-left: -10px;
  color:white;
    font-family: 'Times New Roman', Times, serif;
  }
 
  .rides-table-container {
    max-height: 480px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 90vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: -10px;
 
    border: 1px solid #ccc; /* Add border to the table container */
  }
  .lvp-action-btn {
    font-size: 14px;
    padding: 6px 10px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 8px;
  }
}
@media only screen and (min-width: 834px) and (max-width: 883px) {
  .ridespage-container {
    padding: 10px;
    margin-left: 10px;


  }
  .rides-table-container table{
 
    width: 1600px;
  }
 


  .SA-searchContainer{
    width: 100%;
    height:50px;
    margin-top: 150px;


    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:600px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 20px;


    }
 
  .ridespage-content {
    padding: 15px;
    margin-left: 0px;
width:780px;
 
  }
  .rides-table {
    width: 170%;
    border-collapse: collapse;
    margin-top: 0px;

}

 
  .ridespage-title {

    margin-top: -10px;
    margin-bottom: 20px;
    text-align: center;
    border:1px solid rgb(216, 207, 207);
    width:93vw;
  background-color: #05144c;
  margin-left: -12px;
  color:white;
    font-family: 'Times New Roman', Times, serif;
  }

  .rides-table-container {
    max-height: 480px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 93vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: -12px;
 
    border: 1px solid #ccc; /* Add border to the table container */
  }
  .lvp-action-btn {
    font-size: 14px;
    padding: 6px 10px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 8px;
  }
}
@media only screen and (min-width: 884px) and (max-width: 1279px) {
  .ridespage-container {
    padding: 10px;
        margin-left: 10px;


  }
 

  .SA-searchContainer{
    width: 100%;
    height:50px;
    margin-top: 150px;

    
     }
    
     .SA-search-input{
      margin: 10px 0px;
      padding: 7px;
      margin-right: auto;
      width:600px;
      height:40px;
      border-radius: 20px;
      margin-top: 5px;
    margin-left: 40px;

    
    }
 
  .ridespage-content {
    padding: 15px;
    margin-left: 0px;
width: 800px;
  }
 
  .ridespage-title {
    margin-top: -10px;
    margin-bottom: 20px;
    text-align: center;
    border:1px solid rgb(216, 207, 207);
    width:90vw;
  background-color: #05144c;
  margin-left: -14px;
  color:white;
    font-family: 'Times New Roman', Times, serif;
  }
 
  .rides-table-container {
    max-height: 480px; /* Adjust the max height as per your requirement */
    overflow-y: scroll;
    width: 90vw;
    margin-top:-20px;
    overflow-x: scroll;
    margin-left: -14px;
 
    border: 1px solid #ccc; /* Add border to the table container */
  }
  .rides-table-container table{
 
    width: 1600px;
  }
  .lvp-action-btn {
    font-size: 14px;
    padding: 6px 10px;
  }
 
  .lvp-rides-th,
  .lvp-rides-td {
    padding: 8px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
 
 
/* Default styles */
.ridespage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

}
.ridespage-search{
  margin-left: -30px;
}
 .SA-searchContainer{
  /* border: 1px solid #f10909;  */
width: 71%;
height:50px;
margin-top: 150px;

 }
.SA-searchcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;

}
 
.SA-search-input{
  margin: 10px 0px;
  padding: 7px;
  margin-right: auto;
  width:700px;
  height:40px;
  border-radius: 20px;
  margin-top: 5px;


}
 
.ridespage-content {
  /* width: 100%; */
  max-width: 1175px;
  margin-top: 40px;
margin-left: 0px;
}
 
.ridespage-title {
  margin-bottom: 20px;
  margin-left: 0px;
  text-align: center;

  border:0px solid rgb(216, 207, 207);
  width:71vw;
  background-color: #05144c;
  color:white;
  font-family: 'Times New Roman', Times, serif;
}
 
.rides-table-container {
  max-height: 350px; /* Adjust the max height as per your requirement */
  overflow-y: scroll;
  width: 71vw;
  margin-top:-20px;
  margin-left: 0px;
  overflow-x: scroll;

  /* border: 1px solid #ccc;  */
}
.rides-table-container table{
  width: 2000px;
}
 
.rides-table {
  width: 170%;
  border-collapse: collapse;
  margin-top: 0px;
  /* border: 1px solid #f10909;  */

}
 
.rides-table thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  /*layanu*/
  border: 0px solid white;

  background-color: #05144c;
  color: #ffffff;
}
.lvp-rides-th, .lvp-rides-td {
  border: 1px solid #ddd;
  padding: px;
  text-align: left;
}
 
.lvp-rides-th {
  background-color:#05144c;
  color:white;
 
}
 
.lvp-action-btn {
  margin-right: 8px;
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid #f10909; 

}
 
.lvp-view-btn {
  background-color: #05144c; /* Adjust the background color */
  color: white;
  border: none;
  border-radius: 5px;
  border: 1px solid #f10909; 

}
 
.lvp-delete-btn {
  background-color: red; /* Adjust the background color */
  color: white;
  border: none;
  border-radius: 5px;
  border: 1px solid #f10909; 

}
 
.lvp-view-btn:hover,
.lvp-delete-btn:hover {
  opacity: 0.8;
  border: 1px solid #f10909; 

}
 
.lvp-rides-td1 {
  display: flex;
  border: 1px solid #f10909; 

}
 
.lvp-rides-th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  border: 1px solid #f10909; 

}
 
.lvp-rides-th.increase-width {
  border: 1px solid #f10909; 

  width: 150px; /* Adjust width as per your design */
}
}
 