.lb-heads{
    border: 1px solid white;
  
  }
  .lb-head {
      margin-top: 150px;
      color: #05144c;
      text-align: center;
      border: 1px solid #eaeaea;
      border-radius: 10px;
      background-color: #f9f9f9;
      width:50%;
      height:520px;
      margin-left: 25%;
  }
  
  
  
  .lb-day {
      margin-bottom: 20px;
      text-align: center;
      font-weight: 400;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  .lb-dayy {
      margin-bottom: 20px;
      text-align: center;
  }
  
  .passenger-btn {
      color: #05144c;
      margin-left: 10px;
  }
  
  .passenger-count {
      margin-left: 5px;
  }
  
  .lb-timero {
   
      text-align: center;
  }
  
  .lb-timerr {
    margin-left: 5px;
   
  }
  .pickup-dropoff-time {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .lb-timeup{
      margin-top:-20px;
  }
  .pickup-dropoff-time span {
    margin-left: 5px;
  }
  
  
  .lb-icon {
      margin-left: 50px;
      font-size: 40px;
      margin-top: 10px;
  }
  
  .lb-timee {
      margin-top: 20px;
  }
  
  .book-detail {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .lb-travel {
    display: flex;
    flex-direction: column;
  margin-left: 30%;
  width:600px;
    padding: 20px;
  
    margin-top: -20px;
  }
  
  
  .lb-bold-text {
      font-weight: 700;
  }
  
  
  .lb-pickk {
    margin-bottom: 16px;
  }
  
  .lb-line {
      width: 40%;
      height: 10px;
      margin: 20px auto;
      border-radius: 25px;
      background-color: rgb(234, 234, 234);
  }
  
  .lb-cash {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      gap:80px;
      margin-left: -40px;
  }
  
  .lb-book-detaill {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  
  .lb-cashh {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
  }
  
  .lb-book {
      background-color: #05144c;
      color: white;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 25px;
      cursor: pointer;
      text-decoration: none;
      display: block;
      margin: 10px auto;
  }
  
  .lb-success-icon {
      margin-top: 30px;
      font-size: 36px;
      text-align: center;
  }
  
  .lb-seat {
      font-weight: 600;
      margin-right: 10px;
  }
  
  .lb-bookk {
      margin-top: 100px;
  }
  
  .lb-colo {
      color: #05144c;
  }
  
  .lb-popup {
      color: green;
  }
  
  .passenger-selector {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .passenger-box {
    display: flex;
    align-items: center;
    margin-left: 10px;
    border: 1px solid rgb(214, 209, 209);
    width: 100px;
    border-radius: 5px;
    justify-content: space-between;
    padding: 0 10px;
  }
  
  .passenger-btn {
    background: none;
    border: none;
    color: #05144c;
    cursor: pointer;
    font-size: 18px;
  }
  
  .passenger-count {
    font-size: 16px;
  }
  
  .error-message-container {
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
  }
  
  
  /* Responsive Styles */
  @media only screen and (min-width: 320px) and (max-width: 343px) {
      .lb-head {
          margin-top: 130px;
          font-size: 18px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          margin-left: 0px;
      }
      .lb-book-main-container {
          margin-top: 40px;
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 18px;
          margin-top: -30px;
      }
      .lb-line{
          margin-top:-20px;
          width:100%;
      }
      .lb-book {
          width: 50%;
          margin-top: -10px;
          font-size: 18px;
          
      }
      .lb-cash{
          margin-top: 10px;
          margin-left: 2px;
  
      }
    
      .lb-travel{
      margin-top: -20px;
          width:390px;
          margin-left: -30px;
      }
  }
  
  @media only screen and (min-width: 360px) and (max-width: 374px) {
      .lb-head {
          margin-top: 130px;
          font-size: 18px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          margin-left: 0px;
      }
      .lb-book-main-container {
          margin-top: 40px;
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 18px;
          margin-top: -30px;
      }
      .lb-line{
          margin-top:-20px;
          width:90%;
      }
      .lb-book {
          width: 50%;
          margin-top: -10px;
          font-size: 18px;
          
      }
      .lb-cash{
          margin-top: 10px;
      }
      .lb-travel{
      margin-top: -20px;
          width:390px;
          margin-left: 0px;
      }
  }
  
  @media only screen and (min-width: 375px) and (max-width: 383px) {
      .lb-head {
          margin-top: 130px;
          font-size: 18px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          margin-left: 0px;
      }
      .lb-book-main-container {
          margin-top: 40px;
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 18px;
          margin-top: -30px;
      }
      .lb-line{
          margin-top:-20px;
          width:90%;
      }
      .lb-book {
          width: 50%;
          margin-top: -10px;
          font-size: 18px;
          
      }
      .lb-cash{
          margin-top: 10px;
      }
      .lb-travel{
      margin-top: -20px;
          width:390px;
          margin-left: 0px;
      }
  }
  
  @media only screen and (min-width: 390px) and (max-width: 411px) {
      .lb-head {
          margin-top: 140px;
          font-size: 20px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          margin-left: 0px;
      }
      .lb-cash{
          margin-top: 10px;
      }
      .lb-line{
          margin-top:-20px;
      }
      .lb-book-main-container {
          margin-top: 40px;
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 18px;
          margin-top: -30px;
      }
      .lb-book {
          width: 50%;
          margin-top: px;
          font-size: 18px;
          
      }
      .lb-travel{
          margin-top: -20px;
              width:390px;
              margin-left: 0px;
          }
  }
  
  @media only screen and (min-width: 412px) and (max-width: 427px) {
      .lb-head {
          margin-top: 140px;
          font-size: 20px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          margin-left: 0px;
      }
      .lb-cash{
          margin-top: 10px;
      }
      .lb-line{
          margin-top:-20px;
          width:90%;
      }
      .lb-book-main-container {
          margin-top: 40px;
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 18px;
          margin-top: -30px;
      }
      .lb-book {
          width: 50%;
          margin-top: px;
          font-size: 18px;
          
      }
      .lb-travel{
          margin-top: -20px;
              width:390px;
              margin-left: 0px;
          }
  }
  
  @media only screen and (min-width: 428px) and (max-width: 767px) {
      .lb-head {
          margin-top: 140px;
          font-size: 20px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          margin-left: 0px;
      }
      .lb-cash{
          margin-top: 10px;
      }
      .lb-line{
          margin-top:-20px;
          width:90%;
      }
      .lb-book-main-container {
          margin-top: 40px;
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 18px;
          margin-top: -30px;
      }
      .lb-book {
          width: 50%;
          margin-top: px;
          font-size: 18px;
          
      }
      .lb-travel{
          margin-top: -20px;
              width:390px;
              margin-left: 0px;
          }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 799px) {
      .lb-head {
          margin-top: 140px;
          font-size: 20px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          margin-left: 0px;
      }
      .lb-cash{
          margin-top: 10px;
      }
      .lb-line{
          margin-top:-20px;
      }
      .lb-book-main-container {
          margin-top: 40px;
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 18px;
          margin-top: -30px;
      }
      .lb-book {
          width: 20%;
          margin-top: px;
          font-size: 18px;
          
      }
      .lb-travel{
          margin-top: -20px;
              width:390px;
              
          }
  }
  
  @media only screen and (min-width: 800px) and (max-width: 819px) {
      .lb-head {
          margin-top: 140px;
          font-size: 20px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          height:100%;
          margin-left: 0px;
      }
      .lb-cash{
          margin-top: 10px;
      }
      .lb-line{
          margin-top:-20px;
      }
      .lb-book-main-container {
        
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 18px;
          
      }
      .lb-book {
          width: 20%;
       
          font-size: 18px;
          
      }
      .lb-travel{
         
              width:390px;
              
          }
  }
  
  @media only screen and (min-width: 820px) and (max-width: 883px) {
      .lb-head {
          margin-top: 140px;
          font-size: 20px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          margin-left: 0px;
      }
      .lb-cash{
          margin-top: 10px;
      }
      .lb-line{
          margin-top:-20px;
      }
      .lb-book-main-container {
          margin-top: 40px;
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 25px;
          margin-top: -30px;
      }
      .lb-book {
          width: 20%;
          margin-top: px;
          font-size: 18px;
          
      }
      .lb-travel{
          margin-top: -20px;
              width:390px;
              
          }
  }
  
  @media only screen and (min-width: 884px) and (max-width: 1279px) {
      .lb-head {
          margin-top: 140px;
          font-size: 20px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          margin-left: 0px;
      }
      .lb-cash{
          margin-top: 10px;
      }
      .lb-line{
          margin-top:-20px;
      }
      .lb-book-main-container {
          margin-top: 40px;
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 18px;
          margin-top: -30px;
      }
      .lb-book {
          width: 20%;
          margin-top: px;
          font-size: 18px;
          
      }
      .lb-travel{
          margin-top: -20px;
              width:390px;
              
          }
  }
  
  @media only screen and (min-width: 1280px) and (max-width: 1440px) {
      .lb-head {
          margin-top: 140px;
          font-size: 20px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          background-color: #f9f9f9;
          width:100%;
          margin-left: 0px;
      }
      .lb-cash{
          margin-top: 10px;
      }
      .lb-line{
          margin-top:-20px;
      }
      .lb-book-main-container {
          margin-top: 40px;
          padding: 15px;
      }
      .lb-day, .lb-dayy {
          font-size: 18px;
          margin-top: -30px;
      }
      .lb-book {
          width: 20%;
          margin-top: px;
          font-size: 18px;
          
      }
      .lb-travel{
          margin-top: -20px;
              width:390px;
              
          }
  }