
/* Default styles */

.yourcrea-usernavbar{
  padding-top: 1px;
}

.yourRides-contents{
  width: 50%;
  margin-top: 150px;
    margin-left:350px;

}

.yourRides-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-left: 0%;
  }

.yourRides-no-booking{
  text-align: center;
}
  .yourRides-rides{   
    text-align: center;
   background-color: #05144c;
   color: #ffffff;
   border: 1px solid #eaebf2;
   width: 98%;
   margin-left: 0px;
   margin-top: 0px;
   height: 70px;
 }
 .yourRides-rides-your{
 padding-top: 15px;
   text-align: center;
 
 }
  
  .yourRides-content {
    width: 100%;
    max-width: 1300px;
    /* margin-top: 150px; */
  }
  
  .yourRides-title {
    /* margin-top: 200px; */
    margin-bottom: 0px;
    text-align: center;
    border: 1px solid rgb(216, 207, 207);
    width: 70vw;
    background-color: #05144c;
    color: white;
    font-family: 'Times New Roman', Times, serif;
  }
  
  
  .yourRides-table-container {
    max-height:463px ;
    overflow-y: scroll;
    width: 100%;
margin-top: 0px;
    /* overflow-x: scroll; */
    border: 0px solid #c8c6c6;
    margin-left: 0px;
  }
  
  .yourRides-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0px;
  }
  
  .yourRides-table thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    border: 1px solid white;
    background-color: #05144c;
    color: #ffffff;
    margin-top: auto;
  }
  
  .yourRides-rides-th, .yourRides-rides-td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .yourRides-rides-th {
    background-color: #05144c;
    color: white;
  }
  
  .yourRides-action-btn {
    margin-right: 8px;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .yourRides-view-button {
    background-color: #000080;
    color: white;
    width: 75px;
    height: 30px;
    border: none;
    border-radius: 5px;
  }

  
  .yourRides-view-btn:hover,
  .yourRides-delete-btn:hover {
    opacity: 0.8;
  }
  
  .yourRides-rides-td1 {
    display: flex;
  }
  
  .yourRides-rides-th.increase-width {
    width: 150px;
  }
  
  /* Media queries for responsiveness */
  @media only screen and (min-width: 320px) and (max-width: 343px) {
   
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:15px;
    }  
    .yourRides-table-container table{
      width: 550px;
    }  
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }

  }
  @media only screen and (min-width: 360px) and (max-width: 374px) {
   
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:20px;
    }  
    .yourRides-table-container table{
      width: 550px;
    }  
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }

  }
  @media only screen and (min-width: 375px) and (max-width: 383px) {
   
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:20px;
    }  
    .yourRides-table-container table{
      width: 550px;
    }  
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }

  }
  @media only screen and (min-width: 390px) and (max-width: 411px) {
  
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:20px;
    }  
    .yourRides-table-container table{
      width: 550px;
    }  
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }

  }
  @media only screen and (min-width: 412px) and (max-width: 427px) {
   
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:20px;
    }  
    .yourRides-table-container table{
      width: 550px;
    }  
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }

  }
  @media only screen and (min-width: 428px) and (max-width: 767px) {
   
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:20px;
    }  
    .yourRides-table-container table{
      width: 550px;
    }  
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }

  }
  @media only screen and (min-width: 768px) and (max-width: 799px) {
  
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:40px;
    }    
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }
  }
  @media only screen and (min-width: 800px) and (max-width: 819px) {
  
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:40px;
    }    
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }
  }
  @media only screen and (min-width: 820px) and (max-width: 833px) {
   
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:40px;
    }    
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }
  }
  @media only screen and (min-width: 834px) and (max-width: 852px) {
  
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:40px;
    }    
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }
  }
  @media only screen and (min-width: 884px) and (max-width: 1279px) {
    
    .yourRides-contents{
      width: 90%;
      margin-top: 150px;
        margin-left:40px;
    }    
    .yourRides-table-container {
      max-height:500px ;
      overflow-y: scroll;
      width: 90vw;
  margin-top: 0px;
      overflow-x: scroll;
      border: 1px solid #c8c6c6;
      margin-left: 0px;
    }

    .yourRides-rides{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 90vw;
     margin-left: 0px;
     margin-top: 0px;
     height: 70px;
   }
   .yourRides-rides-your{
   padding-top: 15px;
     text-align: center;
   
   }
  }
  @media only screen and (min-width: 1280px) and (max-width: 1440px) {
    
/* Default styles */

.yourcrea-usernavbar{
  padding-top: 1px;
}

.yourRides-contents{
  width: 50%;
  margin-top: 150px;
    margin-left:350px;

}

.yourRides-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-left: 0%;
  }

.yourRides-no-booking{
  text-align: center;
}
  .yourRides-rides{   
    text-align: center;
   background-color: #05144c;
   color: #ffffff;
   border: 1px solid #eaebf2;
   width: 100%;
   margin-left: 0px;
   margin-top: 0px;
   height: 70px;
 }
 .yourRides-rides-your{
 padding-top: 15px;
   text-align: center;
 
 }
  
  .yourRides-content {
    width: 100%;
    max-width: 1300px;
    /* margin-top: 150px; */
  }
  
  .yourRides-title {
    /* margin-top: 200px; */
    margin-bottom: 0px;
    text-align: center;
    border: 1px solid rgb(216, 207, 207);
    width: 70vw;
    background-color: #05144c;
    color: white;
    font-family: 'Times New Roman', Times, serif;
  }
  
  
  .yourRides-table-container {
    max-height:463px ;
    overflow-y: scroll;
    width: 100%;
margin-top: 0px;
    /* overflow-x: scroll; */
    border: 0px solid #c8c6c6;
    margin-left: 0px;
  }
  
  .yourRides-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0px;
  }
  
  .yourRides-table thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    border: 1px solid white;
    background-color: #05144c;
    color: #ffffff;
    margin-top: auto;
  }
  
  .yourRides-rides-th, .yourRides-rides-td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .yourRides-rides-th {
    background-color: #05144c;
    color: white;
  }
  
  .yourRides-action-btn {
    margin-right: 8px;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .yourRides-view-button {
    background-color: #000080;
    color: white;
    width: 75px;
    height: 30px;
    border: none;
    border-radius: 5px;
  }

  
  .yourRides-view-btn:hover,
  .yourRides-delete-btn:hover {
    opacity: 0.8;
  }
  
  .yourRides-rides-td1 {
    display: flex;
  }
  
  .yourRides-rides-th.increase-width {
    width: 150px;
  }
  
  }

  