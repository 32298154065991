.lr-main {
  border: 1px solid white;

}
.lr-contents{
  /* border:1px solid rgb(239, 237, 237); */
  width:50%;
  overflow-y: auto;
  /* overflow-y: scroll; */

  height:570px ;
  margin-left: 25%;
  border-radius: 10px;
}

.ride-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px; /* Adjust margin for spacing */
  padding: 20px;
  width: 98%; /* Set width to 100% of the container */
  box-shadow: 0 0 10px rgba(165, 54, 54, 0.1);
}
.rides-container {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  /* height: 62vh;  */
  padding-bottom: 10px; /* Add some padding for a better scrolling experience */
  margin-top: 150px;
  width: 100%; /* Set a fixed width for the container */
  margin: 0 auto; /* Center the container horizontally */
}
.no-booking{
  border: 1px solid rgb(12, 12, 12);;
}
.no-booking-found{
  width: 760px;
  border: 1px solid rgb(12, 12, 12);;

  height: 600px;

}
.lr-contents{
  margin-top:140px;
}

.lr-icom-hiarrow{
  margin-top: 200px;
}


.lr-bookedsect {
  margin-top: -10px;
}
.booking-details p,
.ride-details p {
  margin: 5px 0;
  margin-left: 10px;
  color: #05144c;
}

.booking-details {
  display: flex;
}

.ride-details h2,
.booking-details h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.lr-book {
  font-weight: 600;
  margin-left: 10px;
  color: #05144c;
}
.lr-bookprice {
  font-weight: 600;
  margin-left: 10px;
  color: #05144c;
}


.booking-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.booking-info {
 
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust spacing between items if needed */
}

.lr-book, .lr-books1, .lr-books2, .lr-books3 {
  margin: 0; /* Remove default margin */
}

@media only screen and (max-width: 768px) {
  .rides-container {
      display: flex;
      flex-direction: column; /* Ensure items stack vertically */
      align-items: center;
      
      margin-top: 0px;
      height: 100vh;
      overflow-y: auto; 
      /* Enable vertical scrolling */
  }

 

  .booking-details {
      flex-direction: column;
      /* width: 2000%; */
      
  }
  .lr-book {
 
    font-weight: 00;
    margin-left: 10px;
    color: #05144c;
    margin-bottom: 5px;
  }

  .booking-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .booking-info {

    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between items if needed */
  }
  
  .lr-book, .lr-books1, .lr-books2, .lr-books3 {
    margin: 0; /* Remove default margin */

  }
   
.ride-card {

  border: 1px solid #ccc;
  /* background-color: aqua; */
  border-radius: 8px;
  margin: 10px; /* Adjust margin for spacing */
  padding: 20px;
  margin-right: 20px;
  box-shadow: 0 0 10px rgba(165, 54, 54, 0.1);
  
}
  

 
  .ride-card {
    margin-left:10px;
    padding-left: 5px;
      width: 340px; /* Set width to 100% for better responsiveness */
      height: 150px; /* Adjust height to auto for better responsiveness */
  margin-top: 6px;
  padding-top: 10px;
  }
  .lr-contents{
    width:96%;
    overflow-y: scroll; /* Enable vertical scrolling */
    overflow-x: hidden;
    margin-left: 3%;
    border-radius: 10px;
   height: 500px;

  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .lr-contents{
    width:80%;
    overflow-y: scroll; /* Enable vertical scrolling */
    /* overflow-x: hidden; */
    height:760px;
    margin-left: 10%;
    border-radius: 10px;
  }
  .ride-card {
    border: 1px solid #f4f3f3;
    border-radius: 8px;
    margin: 10px; /* Adjust margin for spacing */
    padding: 20px;
    width: 97%; /* Set width to 100% of the container */
    box-shadow: 0 0 10px rgba(165, 54, 54, 0.1);
  }

  .booking-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .booking-info {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between items if needed */
  }
  
  .lr-book, .lr-books1, .lr-books2, .lr-books3 {
    margin: 0; /* Remove default margin */
  }
}
