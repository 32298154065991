.home-page{
  width: 100%;
 }

.ulg-hero-img{
      padding: 250px;    
      width: 100%;
      margin-top: 50px;
  
}
.ulg-hero-car-1{
  width: 100%;
  font-size: 30px;
   margin-top: -410px;
 
   text-align: center;
  
  }

.ulg-comes-lion-2{
  width:150%;
  margin-left: -60px;
    font-size: 25px;
   margin-top:-10px;
   color: white;
   
  }

 


  .ulg-cta-our{
    border: none ;
    border-radius: 10px;
    height: 350px;
    background-color: #ededed; 
    border-radius: 50px;
    margin-bottom: 200px;
 
  }
.ulg-our-img{
  width: 300px;
  margin-left: 200px;
  border-radius: 50px;
  margin-top: 20px;
  animation: moveRight 2s ease-in-out infinite alternate;

}

@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}




  .ulg-paragraph-our{
    width: calc(50% - 20px); /* Adjust width of each paragraph */
    margin-left: 50px; /* Adjust spacing between paragraphs */
  }
  .ulg-our-1{
    width: 65%;
   margin-top: -280px;
   font-size: 20px;
   margin-left: 750px;
   text-align: center ;
   margin-bottom: -80px;
   
  }
   
  .ulg-our-2{
  
    margin-left: 100px;
    font-size: 25px;
    margin-bottom: 20px;
  }
  
   
  .ulg-our-3 {
    margin-right:-150px;
  }


  
 
   
  .ulg-text-center {
    text-align: center;
  }
   
  .ulg-button-container {
    display: inline-block;
    margin-left: 350px;
  }
   
   
   

   
  .ulg-paragraph-car{
    width: calc(50% - 20px); /* Adjust width of each paragraph */
    margin-right: 20px; /* Adjust spacing between paragraphs */
 

 
  }
  
 

  .ulg-car-img{
    width: 350px;
    margin-left: 980px;
    border-radius: 50px;
    margin-top: -50px;
  overflow-x: hidden;
  animation: moveRight 2s ease-in-out infinite alternate;
  }
  @keyframes moveRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50px); /* Adjust this value as needed for the desired distance */
    }
}
  .ulg-car-pn-pm{
    margin-top: -150px;
    margin-bottom: 40px;
    border: 1px none;
  }
  .ulg-drive-1{
    width: 5%;
   margin-top: -310px;
   font-size: 20px;
   margin-left: 750px;
   text-align: center ;
   margin-bottom: -120px;
  
   
  }
  .ulg-paragraph-1{
   
width: 600px;
margin-top: 130px;
  font-size: 25px;
  margin-left: -600px;
  text-align: center ;
  margin-bottom: 20px;
  }
  .ulg-paragraph-2{
    margin-left: -600px;
    font-size: 20px;
    margin-bottom: 10px;
   
 
     
   }
  
   
  .ulg-paragraph-car{
    margin-top: 100px;
    margin-right: 750px;
    
  }
   
  
   
   
  .ulg-more-btn-2 {
    display: inline-block;
    padding: 10px 20px;
 
   
    margin-top: 100px; /* Adjust margin as needed */
   
  }
   
   
   
  .paragraph {
    text-align: center; /* Center-align the paragraph text */
  }
   
  .ulg-want-ps {
    border-radius: 50px;
    display: flex; /* Use Flexbox to allow side-by-side layout */
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center items horizontally */
    padding: 20px; /* Add padding */
    background-color:  #ededed; /* Set background color to blue */
    background-repeat: no-repeat; /* Ensure the image is not repeated */
    background-position: right; /* Position the image on the right side */
   
    color: black; /* Set text color to white for contrast */
  }
   
  .ulg-destination-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Adjust spacing between boxes */
  }
   

  
  .ulg-box-1 {
    display: flex;
    align-items: center;
    background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 20px;
  }
  .ulg-box-2 {
    display: flex;
    align-items: center;
    background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 15px;
  }
  .ulg-box-3 {
    display: flex;
    align-items: center;
    background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 10px;
  }

  .ulg-box-4 {
    display: flex;
    align-items: center;
    background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 20px;
    display: block;
  }
  .ulg-box-5 {
    display: flex;
    align-items: center;
    background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 15px;
    display: block;
  }
  .ulg-box-6 {
    display: flex;
    align-items: center;
    background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 10px;
    display: block;
  }
  .ulg-box-7 {
    display: flex;
    align-items: center;
    background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 20px;
    display: block;
  }
  .ulg-box-8 {
    display: flex;
    align-items: center;
    background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 15px;
    display: block;
  }
  .ulg-box-9 {
    display: flex;
    align-items: center;
    background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 10px;
    display: block;
 
    
  }
  
   
  .ulg-destination {
    color: black;
    margin: 0 15px; /* Adjust spacing between destinations and arrows */
   
  }
   
  .ulg-arrow {
    color: black;
    font-size: 20px; /* Increase arrow size */
  }
   
  .ulg-end-arrow {
    color: black;
    margin-left: auto; /* Push the ">" symbol to the right */
    font-size: 20px; /* Increase ">" symbol size */
  }
   
  .ulg-pn-pm-want{
    margin-bottom: 200px;
    margin-right: 25px;
    
    
  }
  .ulg-pn-pm-See{
    margin-top: 00px;
    color: rgb(27, 106, 156);
     margin-left: 80%; 
    
  }
    


/* Media query for screens smaller than 768px (typical mobile devices) */
@media only screen and (min-width: 320px) and (max-width: 359px) {  
  /* Adjustments for the navbar */
  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
.home-page{
  overflow: hidden;
}
  .ulg-hero-img{
    margin-top: 100px;
    padding: 20px;    
    width: 100%;
   


}

.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}




.ulg-cta-our{
  
  width: 300px;
  /* border: none ; */
  border-radius: 10px;
  height: 570px;
  background-color: #ededed; 
  border-radius: 50px;
  margin-left: 10px;
  margin-top: 30px;
}
.ulg-our-img{
 
  width: 200px;
  margin-left: 25px;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  animation: moveRight 2s ease-in-out infinite alternate;
}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
 
 margin-top: 10px;

 margin-left: 15px;
 text-align: center ;
 
}


 
.ulg-our-2{
  width: 210px;
  margin-top: 0px;
  margin-left: 35px;
  font-size: 22px;
  margin-bottom: 10px;
}


.ulg-our-3{
 
  width: 280px;
margin-left:0px;
margin-bottom: 0px;
font-size: 17px;
}
 



p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{
  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{

font-size: 20px;
margin-left: -600px;
margin-top: -600px;
}

.ulg-car-img{

  width: 200px;
margin-left: 820px;
border-radius: 0px;
margin-top: -350px;


}
.ulg-paragraph-1{

  width: 280px;
  margin-top: 700px;
   font-size: 22px;
   margin-left: 390px;
   text-align: center ;
 
}
.ulg-paragraph-2{

  width: 300px;

  font-size: 17px;
 
  margin-left: 390px;
  margin-bottom: -50px;

   
 }

.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 800px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
  
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
  
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
 
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
   
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.ulg-want-ps {
  
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {
   
   background-image: center;
   
   /* Center background image */
  }

  .ulg-box-1,
  .ulg-box-2,
  .ulg-box-3 {
    
    margin-right: 0; /* Remove right margin */
  }

 
.ulg-pn-pm-See{
 
  width: 200px;
  margin-left: 100px;

}






.ulg-card-container{
  overflow: hidden; 
  border: 1px solid #e90909;
  display:block;
  margin-top: 30px;
  
}

.ulg-card {
  overflow: hidden; 
  border: 1px solid #e90909;
 
  width: 350px;
  height: 200px;
 

 /* background-position: 10px;
 background-position:  center right 50px;  */
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.ulg-card-container{
  overflow: hidden; 
  border: 1px solid #e90909;
 
  margin-left: 5%;
}
.ulg-card{
  overflow: hidden; 
  border: 1px solid #e90909;
margin-top: 30px;
}

}
@media only screen and (min-width: 360px) and (max-width: 374px) {  
  /* Adjustments for the navbar */
  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }

  .ulg-hero-img{
    margin-top: 90px;
    padding: 20px;    
    width: 100%;
 

}
  
 


.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}




.ulg-cta-our{
  width: 340px;
  border: none ;
  border-radius: 10px;
  height: 570px;
  background-color: #ededed; 
  border-radius: 50px;
  margin-left: 10px;
  margin-top: 30px;
}
.ulg-our-img{
  width: 250px;
  margin-left: 20px;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  animation: moveRight 2s ease-in-out infinite alternate;
}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
 
 margin-top: 10px;

 margin-left: 15px;
 text-align: center ;
 
}


 
.ulg-our-2{
  width: 210px;
  margin-top: 0px;
  margin-left: 60px;
  font-size: 22px;
  margin-bottom: 10px;
}


.ulg-our-3{
  
 
  width: 320px;
margin-left:0px;
margin-bottom: 0px;
font-size: 17px;
}
 



p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{
  

font-size: 20px;
margin-left: -680px;
margin-top: -550px;
}
.ulg-more-btn-2{
  margin-left: -280px;

}
.ulg-car-img{
  width: 250px;
margin-left: 800px;
border-radius: 0px;
margin-top: -300px;


}
.ulg-paragraph-1{
  width: 280px;
  margin-top: 670px;
   font-size: 22px;
   margin-left: 450px;
   text-align: center ;
 
}
.ulg-paragraph-2{
  width: 320px;

  font-size: 17px;
 
  margin-left: 440px;
  margin-bottom: -50px;

   
 }

.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 800px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.ulg-want-ps {
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {

   background-image: center;
   
   /* Center background image */
  }

  .ulg-box-1,
  .ulg-box-2,
  .ulg-box-3 {
    margin-right: 0; /* Remove right margin */
  }

 
.ulg-pn-pm-See{
  
  width: 200px;
  margin-left: 100px;

}






.ulg-card-container{
  display:block;
  margin-top: 30px;
  
}

.ulg-card {
 
  width: 350px;
  height: 200px;
 

 /* background-position: 10px;
 background-position:  center right 50px;  */
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.ulg-card-container{

 
  margin-left: 5%;
}
.ulg-card{
margin-top: 30px;
}

}
@media only screen and (min-width: 375px) and (max-width: 389px) {  
  /* Adjustments for the navbar */
  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }

  .ulg-hero-img{
    margin-top: 100px;
    padding: 20px;    
    width: 100%;
 

}
  
 


.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}




.ulg-cta-our{
  width: 355px;
  border: none ;
  border-radius: 10px;
  height: 580px;
  background-color: #ededed; 
  border-radius: 50px;
  margin-left: 10px;
  margin-top: 30px;
}
.ulg-our-img{
  width: 260px;
  margin-left: 25px;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  animation: moveRight 2s ease-in-out infinite alternate;
}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
 
 margin-top: 10px;

 margin-left: 15px;
 text-align: center ;
 
}


 
.ulg-our-2{
  width: 210px;
  margin-top: 0px;
  margin-left: 60px;
  font-size: 22px;
  margin-bottom: 10px;
}


.ulg-our-3{
  
 
  width: 320px;
margin-left:10px;
margin-bottom: 0px;
font-size: 17px;
}
 



p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{
  

font-size: 20px;
margin-left: -680px;
margin-top: -550px;
}
.ulg-more-btn-2{
  margin-left: -280px;

}
.ulg-car-img{
  width: 260px;
margin-left: 820px;
border-radius: 0px;
margin-top: -300px;


}
.ulg-paragraph-1{
  width: 280px;
  margin-top: 670px;
   font-size: 22px;
   margin-left: 450px;
   text-align: center ;
 
}
.ulg-paragraph-2{
  width: 320px;

  font-size: 17px;
 
  margin-left: 420px;
  margin-bottom: -50px;

   
 }

.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 800px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.ulg-want-ps {
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {

   background-image: center;
   
   /* Center background image */
  }

  .ulg-box-1,
  .ulg-box-2,
  .ulg-box-3 {
    margin-right: 0; /* Remove right margin */
  }

 
.ulg-pn-pm-See{
  
  width: 200px;
  margin-left: 100px;

}






.ulg-card-container{
  display:block;
  margin-top: 30px;
  
}

.ulg-card {
 
  width: 350px;
  height: 200px;
 

 /* background-position: 10px;
 background-position:  center right 50px;  */
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.ulg-card-container{

 
  margin-left: 5%;
}
.ulg-card{
margin-top: 30px;
}

}
@media only screen and (min-width: 390px) and (max-width: 411px) {  
  /* Adjustments for the navbar */
  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }

  .ulg-hero-img{
    margin-top: 100px;
    padding: 20px;    
    width: 100%;
 

}
  
 


.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}




.ulg-cta-our{
  width: 370px;
  border: none ;
  border-radius: 10px;
  height: 580px;
  background-color: #ededed; 
  border-radius: 50px;
  margin-left: 10px;
  margin-top: 30px;
}
.ulg-our-img{
  width: 270px;
  margin-left: 25px;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  animation: moveRight 2s ease-in-out infinite alternate;
}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
 
 margin-top: 10px;

 margin-left: 15px;
 text-align: center ;
 
}


 
.ulg-our-2{
  width: 210px;
  margin-top: 0px;
  margin-left: 60px;
  font-size: 22px;
  margin-bottom: 10px;
}


.ulg-our-3{
  
 
  width: 320px;
margin-left:10px;
margin-bottom: 0px;
font-size: 17px;
}
 



p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{
  

font-size: 20px;
margin-left: -680px;
margin-top: -550px;
}
.ulg-more-btn-2{
  margin-left: -280px;

}
.ulg-car-img{
  width: 270px;
margin-left: 820px;
border-radius: 0px;
margin-top: -270px;


}
.ulg-paragraph-1{
  width: 280px;
  margin-top: 690px;
   font-size: 22px;
   margin-left: 450px;
   text-align: center ;
 
}
.ulg-paragraph-2{
  width: 350px;

  font-size: 17px;
 
  margin-left: 410px;
  margin-bottom: -50px;

   
 }

.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 800px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.ulg-want-ps {
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {

   background-image: center;
   
   /* Center background image */
  }

  .ulg-box-1,
  .ulg-box-2,
  .ulg-box-3 {
    margin-right: 0; /* Remove right margin */
  }

 
.ulg-pn-pm-See{
  
  width: 200px;
  margin-left: 100px;

}






.ulg-card-container{
  display:block;
  margin-top: 30px;
  
}

.ulg-card {
 
  width: 350px;
  height: 200px;
 

 /* background-position: 10px;
 background-position:  center right 50px;  */
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.ulg-card-container{

 
  margin-left: 5%;
}
.ulg-card{
margin-top: 30px;
}

}
@media only screen and (min-width: 412px) and (max-width: 427px) {  
  /* Adjustments for the navbar */
  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }

  .ulg-hero-img{
    margin-top: 100px;
    padding: 20px;    
    width: 100%;
 

}
  
 


.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}




.ulg-cta-our{
  width: 390px;
  border: none ;
  border-radius: 10px;
  height: 580px;
  background-color: #ededed; 
  border-radius: 50px;
  margin-left: 10px;
  margin-top: 30px;
}
.ulg-our-img{
  width: 290px;
  margin-left: 25px;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  animation: moveRight 2s ease-in-out infinite alternate;
}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
 
 margin-top: 10px;

 margin-left: 15px;
 text-align: center ;
 
}


 
.ulg-our-2{
  width: 210px;
  margin-top: 0px;
  margin-left: 60px;
  font-size: 22px;
  margin-bottom: 10px;
}


.ulg-our-3{
  
 
  width: 350px;
margin-left:10px;
margin-bottom: 0px;
font-size: 17px;
}
 



p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{
  

font-size: 20px;
margin-left: -680px;
margin-top: -550px;
}
.ulg-more-btn-2{
  margin-left: -280px;

}
.ulg-car-img{
  width: 290px;
margin-left: 820px;
border-radius: 0px;
margin-top: -270px;


}
.ulg-paragraph-1{
  width: 280px;
  margin-top: 700px;
   font-size: 22px;
   margin-left: 450px;
   text-align: center ;
 
}
.ulg-paragraph-2{
  width: 370px;

  font-size: 17px;
 
  margin-left: 400px;
  margin-bottom: -50px;

   
 }

.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 800px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.ulg-want-ps {
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {

   background-image: center;
   
   /* Center background image */
  }

  .ulg-box-1,
  .ulg-box-2,
  .ulg-box-3 {
    margin-right: 0; /* Remove right margin */
  }

 
.ulg-pn-pm-See{
  
  width: 200px;
  margin-left: 100px;

}






.ulg-card-container{
  display:block;
  margin-top: 30px;
  
}

.ulg-card {
 
  width: 350px;
  height: 200px;
 

 /* background-position: 10px;
 background-position:  center right 50px;  */
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.ulg-card-container{

 
  margin-left: 5%;
}
.ulg-card{
margin-top: 30px;
}

}
@media only screen and (min-width: 428px) and (max-width: 767px) {  
  /* Adjustments for the navbar */
  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }

  .ulg-hero-img{
    margin-top: 100px;
    padding: 20px;    
    width: 100%;
 

}
  
 


.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}




.ulg-cta-our{
  width: 410px;
  border: none ;
  border-radius: 10px;
  height: 600px;
  background-color: #ededed; 
  border-radius: 50px;
  margin-left: 10px;
  margin-top: 30px;
}
.ulg-our-img{
  width: 310px;
  margin-left: 25px;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  animation: moveRight 2s ease-in-out infinite alternate;
}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
 
 margin-top: 10px;

 margin-left: 15px;
 text-align: center ;
 
}


 
.ulg-our-2{
  width: 210px;
  margin-top: 0px;
  margin-left: 70px;
  font-size: 22px;
  margin-bottom: 10px;
}


.ulg-our-3{
  
 
  width: 350px;
margin-left:20px;
margin-bottom: 0px;
font-size: 17px;
}
 



p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{
  

font-size: 20px;
margin-left: -680px;
margin-top: -550px;
}
.ulg-more-btn-2{
  margin-left: -280px;

}
.ulg-car-img{
  width: 310px;
margin-left: 830px;
border-radius: 0px;
margin-top: -270px;


}
.ulg-paragraph-1{
  width: 280px;
  margin-top: 720px;
   font-size: 22px;
   margin-left: 410px;
   text-align: center ;
 
}
.ulg-paragraph-2{
  width: 390px;

  font-size: 17px;
 
  margin-left: 360px;
  margin-bottom: -50px;

   
 }

.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 800px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.ulg-want-ps {
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {

   background-image: center;
   
   /* Center background image */
  }

  .ulg-box-1,
  .ulg-box-2,
  .ulg-box-3 {
    margin-right: 0; /* Remove right margin */
  }

 
.ulg-pn-pm-See{
  
  width: 200px;
  margin-left: 100px;

}






.ulg-card-container{
  display:block;
  margin-top: 30px;
  
}

.ulg-card {
 
  width: 350px;
  height: 200px;
 

 /* background-position: 10px;
 background-position:  center right 50px;  */
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.ulg-card-container{

 
  margin-left: 5%;
}
.ulg-card{
margin-top: 30px;
}

}
@media only screen and (min-width: 768px) and (max-width: 820px) {  


  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .ulg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -240px;
   margin-left: 50px;
     text-align: center;
    
    }
  
  .ulg-comes-lion-2{
    width:300%;
    margin-left: -170px;
      font-size: 25px;
     margin-top: 12px;
     
    }
    .ulg-hero-img{
      padding: 100px; 
      margin-top: 30px;   
      width: 100%;
      margin-bottom: 40px;
    /* margin-left: 00px; */
    /* border-radius: 50px; */
    /* margin-top: 0px; */
  /* overflow-x: hidden; */
  /* animation: moveRight 2s ease-in-out infinite alternate; */

}
  
 


.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.ulg-cta-our{
  width: 720px;
  margin-left: 20px;
  border: none ;
  border-radius: 10px;
  height: 780px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -60px;
}
.ulg-our-img{
width: 400px;
margin-left: 130px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.ulg-our-2{
  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.ulg-our-3{
  
  width: 650px;
  margin-left:40px;
}
 

.ulg-learn-more-btn-1 {
  width: 100%;
  padding: 20px 40px; 
  margin-left: 10px;
  margin-top: 100px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{
  

 font-size: 20px;
 margin-left: -600px;

text-align: center;
margin-top: -600px;
 
}

.ulg-car-img{
  width: 400px;
  margin-left: 870px;
  border-radius: 50px;
  margin-top: -350px;



}
.ulg-paragraph-1{
  width: 600px;
 margin-top: 840px;
  font-size: 30px;
  margin-left: 130px;
  text-align: center ;
 
}
.ulg-paragraph-2{
  width: 650px;
 
  font-size: 25px;
 
  margin-left: 100px;

   
 }

 .ulg-more-btn-2{
  width: 150%;
  padding: 20px 40px; 
  margin-left: -290px;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

 .ulg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.ulg-want-ps {
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {

   background-image: center;
   
   /* Center background image */
  }

 
.ulg-pn-pm-See{
  
  width: 300px;
  margin-left: 480px;
  font-size: 20px;

}
.ulg-card-container{
  display:block;
  margin-top: 30px;
  
}
}
@media only screen and (min-width: 769px) and (max-width: 799px) {  


  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .ulg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -240px;
   margin-left: 50px;
     text-align: center;
    
    }
  
  .ulg-comes-lion-2{
    width:300%;
    margin-left: -170px;
      font-size: 25px;
     margin-top: 12px;
     
    }
    .ulg-hero-img{
      padding: 150px;    
      width: 100%;
    /* margin-left: 00px; */
    /* border-radius: 50px; */
    /* margin-top: 0px; */
  /* overflow-x: hidden; */
  /* animation: moveRight 2s ease-in-out infinite alternate; */

}
  
 


.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.ulg-cta-our{
  width: 780px;
  margin-left: 20px;
  border: none ;
  border-radius: 10px;
  height: 750px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -60px;
}
.ulg-our-img{
width: 400px;
margin-left: 160px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.ulg-our-2{
  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.ulg-our-3{
  
  width: 700px;
  margin-left:40px;
}
 

.ulg-learn-more-btn-1 {
  width: 100%;
  padding: 20px 40px; 
  margin-left: 10px;
  margin-top: 100px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{
  

 font-size: 20px;
 margin-left: -600px;

text-align: center;
margin-top: -600px;
 
}

.ulg-car-img{
  width: 400px;
  margin-left: 870px;
  border-radius: 50px;
  margin-top: -350px;



}
.ulg-paragraph-1{
  width: 600px;
 margin-top: 840px;
  font-size: 30px;
  margin-left: 130px;
  text-align: center ;
 
}
.ulg-paragraph-2{
  width: 650px;
 
  font-size: 25px;
 
  margin-left: 100px;

   
 }

 .ulg-more-btn-2{
  width: 150%;
  padding: 20px 40px; 
  margin-left: -290px;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

 .ulg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.ulg-want-ps {
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {

   background-image: center;
   
   /* Center background image */
  }

 
.ulg-pn-pm-See{
  
  width: 300px;
  margin-left: 480px;
  font-size: 20px;

}
.ulg-card-container{
  display:block;
  margin-top: 30px;
  
}
}
@media only screen and (min-width: 800px) and (max-width: 819px) {  


  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .ulg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -240px;
   margin-left: 50px;
     text-align: center;
    
    }
  
  .ulg-comes-lion-2{
    width:300%;
    margin-left: -170px;
      font-size: 25px;
     margin-top: 12px;
     
    }
    .ulg-hero-img{
      padding: 100px;    
      width: 100%;
      margin-bottom: 10px;

    /* margin-left: 00px; */
    /* border-radius: 50px; */
    /* margin-top: 0px; */
  /* overflow-x: hidden; */
  /* animation: moveRight 2s ease-in-out infinite alternate; */

}
  
 


.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.ulg-cta-our{
  width: 760px;
  margin-left: 20px;
  border: none ;
  border-radius: 10px;
  height: 750px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -60px;
}
.ulg-our-img{
width: 400px;
margin-left: 160px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.ulg-our-2{
  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.ulg-our-3{
  
  width: 700px;
  margin-left:40px;
}
 

.ulg-learn-more-btn-1 {
  width: 100%;
  padding: 20px 40px; 
  margin-left: 10px;
  margin-top: 100px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{
  

 font-size: 20px;
 margin-left: -600px;

text-align: center;
margin-top: -600px;
 
}

.ulg-car-img{
  width: 400px;
  margin-left: 870px;
  border-radius: 50px;
  margin-top: -350px;



}
.ulg-paragraph-1{
  width: 600px;
 margin-top: 840px;
  font-size: 30px;
  margin-left: 130px;
  text-align: center ;
 
}
.ulg-paragraph-2{
  width: 650px;
 
  font-size: 25px;
 
  margin-left: 100px;

   
 }

 .ulg-more-btn-2{
  width: 150%;
  padding: 20px 40px; 
  margin-left: -290px;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

 .ulg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.ulg-want-ps {
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {

   background-image: center;
   
   /* Center background image */
  }

 
.ulg-pn-pm-See{
  
  width: 300px;
  margin-left: 480px;
  font-size: 20px;

}
.ulg-card-container{
  display:block;
  margin-top: 30px;
  
}
}
@media only screen and (min-width: 820px) and (max-width: 883px) {  


  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .ulg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -240px;
   margin-left: 50px;
     text-align: center;
    
    }
  
  .ulg-comes-lion-2{
    width:300%;
    margin-left: -170px;
      font-size: 25px;
     margin-top: 12px;
     
    }
    .ulg-hero-img{
      padding: 100px;    
      width: 100%;
      margin-bottom: 10px;

   
}
  
 


.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.ulg-cta-our{
  width: 780px;
  margin-left: 25px;
  border: none ;
  border-radius: 10px;
  height: 750px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -60px;
}
.ulg-our-img{
width: 400px;
margin-left: 160px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.ulg-our-2{
  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.ulg-our-3{
  
  width: 700px;
  margin-left:40px;
}
 

.ulg-learn-more-btn-1 {
  width: 100%;
  padding: 20px 40px; 
  margin-left: 10px;
  margin-top: 100px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{
  

 font-size: 20px;
 margin-left: -600px;

text-align: center;
margin-top: -600px;
 
}

.ulg-car-img{
  width: 400px;
  margin-left: 870px;
  border-radius: 50px;
  margin-top: -350px;



}
.ulg-paragraph-1{
  width: 600px;
 margin-top: 840px;
  font-size: 30px;
  margin-left: 130px;
  text-align: center ;
 
}
.ulg-paragraph-2{
  width: 650px;
 
  font-size: 25px;
 
  margin-left: 100px;

   
 }

 .ulg-more-btn-2{
  width: 150%;
  padding: 20px 40px; 
  margin-left: -290px;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

 .ulg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.ulg-want-ps {
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {

   background-image: center;
   
   /* Center background image */
  }

 
.ulg-pn-pm-See{
  
  width: 300px;
  margin-left: 480px;
  font-size: 20px;

}
.ulg-card-container{
  display:block;
  margin-top: 30px;
  
}
}
@media only screen and (min-width: 884px) and (max-width: 1279px) {  


  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .ulg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -240px;
   margin-left: 50px;
     text-align: center;
    
    }
  
  .ulg-comes-lion-2{
    width:300%;
    margin-left: -170px;
      font-size: 25px;
     margin-top: 12px;
     
    }
    .ulg-hero-img{
      padding: 100px;    
      width: 100%;
    /* margin-left: 00px; */
    /* border-radius: 50px; */
    /* margin-top: 0px; */
  /* overflow-x: hidden; */
  /* animation: moveRight 2s ease-in-out infinite alternate; */

}
  
 


.ulg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-right: 20px; /* Adjust spacing between paragraphs */
}

.ulg-cta-our{
  width: 830px;
  margin-left: 25px;
  border: none ;
  border-radius: 10px;
  height: 750px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -60px;
}
.ulg-our-img{
width: 400px;
margin-left: 190px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.ulg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.ulg-our-2{
  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.ulg-our-3{
  
  width: 750px;
  margin-left:40px;
}
 

.ulg-learn-more-btn-1 {
  width: 100%;
  padding: 20px 40px; 
  margin-left: 10px;
  margin-top: 100px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.ulg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.ulg-drive-1{
  

 font-size: 20px;
 margin-left: -600px;

text-align: center;
margin-top: -600px;
 
}

.ulg-car-img{
  width: 400px;
  margin-left: 920px;
  border-radius: 50px;
  margin-top: -300px;



}
.ulg-paragraph-1{
  width: 600px;
 margin-top: 860px;
  font-size: 30px;
  margin-left: 100px;
  text-align: center ;
 
}
.ulg-paragraph-2{
  width: 750px;
 
  font-size: 25px;
 
  margin-left: 10px;

   
 }

 .ulg-more-btn-2{
  width: 150%;
  padding: 20px 40px; 
  margin-left: -290px;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

 .ulg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.ulg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  /* Adjustments for search box */
  .ulg-search-box {
    /* Stack inputs vertically */
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .ulg-location,
  .ulg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .ulg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .ulg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .ulg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .ulg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  /* Adjustments for other sections */
  .ulg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-left:-20px ;   
  }
.ulg-want-ps {
  width: 100%;
  display: block;
 
  
}
.ulg-pn-pm-want{
  margin-bottom: 50px;
}
  .ulg-car-pn-pm {

   background-image: center;
   
   /* Center background image */
  }

 
.ulg-pn-pm-See{
  
  width: 300px;
  margin-left: 480px;
  font-size: 20px;

}
.ulg-card-container{
  display:block;
  margin-top: 30px;
  
}
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {  

  .home-page{
    width: 100%;
   }
  
  .ulg-hero-img{
        padding: 250px;    
        width: 100%;
        margin-top: 50px;
        margin-bottom: 60px;
  }
 
  
   
  
  
    .ulg-cta-our{
      border: none ;
      border-radius: 10px;
      height: 350px;
      background-color: #ededed; 
      border-radius: 50px;
      margin-bottom: 200px;
     
   
    }
  .ulg-our-img{
    width: 300px;
    margin-left: 200px;
    border-radius: 50px;
    margin-top: 20px;
    animation: moveRight 2s ease-in-out infinite alternate;
  
  }
  
  @keyframes moveRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50px); /* Adjust this value as needed for the desired distance */
    }
  }
  
  
  
  
    .ulg-paragraph-our{
      width: calc(50% - 20px); /* Adjust width of each paragraph */
margin-left:-20px ;   
 }
    .ulg-our-1{
      width: 65%;
     margin-top: -280px;
     font-size: 20px;
     margin-left: 750px;
     text-align: center ;
     margin-bottom: -80px;
     
    }
     
    .ulg-our-2{
    
      margin-left: 0px;
      font-size: 25px;
      margin-bottom: 20px;
    }
    
     
    .ulg-our-3 {
      width: 550px;
      margin-left:-40px;
    }
  
  
    
   
     
    .ulg-text-center {
      text-align: center;
    }
     
    .ulg-button-container {
      display: inline-block;
      margin-left: 350px;
    }
     
     
  
     
    .ulg-paragraph-car{
      width: calc(50% - 20px); /* Adjust width of each paragraph */
      margin-right: 20px; /* Adjust spacing between paragraphs */
   
  
   
    }
    
   
  
    .ulg-car-img{
      width: 350px;
      margin-left: 800px;
      border-radius: 50px;
      margin-top: -50px;
    overflow-x: hidden;
    animation: moveRight 2s ease-in-out infinite alternate;
    }
    @keyframes moveRight {
      0% {
          transform: translateX(0);
      }
      100% {
          transform: translateX(50px); /* Adjust this value as needed for the desired distance */
      }
  }
    .ulg-car-pn-pm{
      margin-top: -150px;
      margin-bottom: 40px;
      border: 1px none;
    }
    .ulg-drive-1{
      width: 5%;
     margin-top: -310px;
     font-size: 20px;
     margin-left: 750px;
     text-align: center ;
     margin-bottom: -120px;
    
     
    }
    .ulg-paragraph-1{
     
  width: 600px;
  margin-top: 130px;
    font-size: 25px;
    margin-left: -700px;
    text-align: center ;
    margin-bottom: 20px;
    }
    .ulg-paragraph-2{
      margin-left: -650px;
      font-size: 20px;
      margin-bottom: 10px;
      width: 550px;
     
   
       
     }
    
     
    .ulg-paragraph-car{
      margin-top: 100px;
      margin-right: 750px;
      
    }
     
    
     
     
    .ulg-more-btn-2 {
      display: inline-block;
      color: white; /* Set text color to red */
      padding: 10px 20px;
      border-radius: 50px;
      text-decoration: none;
      margin-top: 100px; /* Adjust margin as needed */
     margin-left: 300px;
    }
     
     
     
    .paragraph {
      text-align: center; /* Center-align the paragraph text */
    }
     
    .ulg-want-ps {
      border-radius: 50px;
      display: flex; /* Use Flexbox to allow side-by-side layout */
      align-items: center; /* Align items vertically */
      justify-content: center; /* Center items horizontally */
      padding: 20px; /* Add padding */
      background-color:  #ededed; /* Set background color to blue */
      background-repeat: no-repeat; /* Ensure the image is not repeated */
      background-position: right; /* Position the image on the right side */
     
      color: black; /* Set text color to white for contrast */
    }
     
    .ulg-destination-box {
      display: flex;
      align-items: center;
      margin-bottom: 20px; /* Adjust spacing between boxes */
    }
     
  
    
    .ulg-box-1 {
      display: flex;
      align-items: center;
      background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 20px;
    }
    .ulg-box-2 {
      display: flex;
      align-items: center;
      background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 15px;
    }
    .ulg-box-3 {
      display: flex;
      align-items: center;
      background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 10px;
    }
  
    .ulg-box-4 {
      display: flex;
      align-items: center;
      background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 20px;
      display: block;
    }
    .ulg-box-5 {
      display: flex;
      align-items: center;
      background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 15px;
      display: block;
    }
    .ulg-box-6 {
      display: flex;
      align-items: center;
      background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 10px;
      display: block;
    }
    .ulg-box-7 {
      display: flex;
      align-items: center;
      background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 20px;
      display: block;
    }
    .ulg-box-8 {
      display: flex;
      align-items: center;
      background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 15px;
      display: block;
    }
    .ulg-box-9 {
      display: flex;
      align-items: center;
      background-color: rgba(10, 4, 89, 0.244); /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 10px;
      display: block;
   
      
    }
    
     
    .ulg-destination {
      color: black;
      margin: 0 15px; /* Adjust spacing between destinations and arrows */
     
    }
     
    .ulg-arrow {
      color: black;
      font-size: 20px; /* Increase arrow size */
    }
     
    .ulg-end-arrow {
      color: black;
      margin-left: auto; /* Push the ">" symbol to the right */
      font-size: 20px; /* Increase ">" symbol size */
    }
     
    .ulg-pn-pm-want{
      margin-bottom: 200px;
      margin-right: 25px;
      
      
    }
    .ulg-pn-pm-See{
      margin-top: 00px;
      color: rgb(27, 106, 156);
       margin-left: 80%; 
      
    }
      
  
}