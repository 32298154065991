 
/* Booked Component Styles */
 
.booked-main {
 
  border: 0px solid rgb(254, 253, 253);
    margin-top: 160px;
    overflow-y: hidden;
 
}
 
.booked-headd{
  /* border:1px solid rgb(26, 238, 19); */
  /* width: 100%; */
  margin: 20px auto;
 
  margin-top: 150px;
 
  max-width: 1200px;
 
  padding: 20px;
 
  background-color: #f0f0f0;
 
  border: 1px solid #ccc;
 
  border-radius: 5px;
 
}
.rides-container {
  margin: auto;
}
 
.booked-pagrmain{
 
  height: 200px;
  border:1px solid white;
 
}
 
.booked-main {
 
  margin-bottom: 20px;
 
  margin-top: 10px;
 
  /* border: 1px solid black; */
 
  max-height: 500px;
 
}
 
 
 
 
 
.styled-table {
 
  height: 400px;
  width: 90.8%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
 
}
 
.styled-table thead tr {
   
  text-align: left;
  background-color: #05144c;
  color: #ffffff;
}
 
.booked-main,
.styled-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
}
 
 
.styled-table th,
.styled-table td {
 
  padding: 12px 30px;
  position: sticky;
  z-index: 1;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
 
.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
 
.styled-table tbody tr:last-of-type {
  border-bottom: 1px solid #f4f5f5;
}
 
.styled-table th {
  position: sticky;
  top: 0;
  background-color: #05144c;
  color: #ffffff;
  z-index: 2;
  /*layanu*/
  border: 0px solid #ddd;
 
}
 
.lr-rode {
  text-decoration: none;
  color: #009879;
}
/* Booked.css */
.button-view {
  display: inline-block;
  background-color: #cfcece;
  color: white;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}
 
.button-view:hover {
  background-color: #cfcece;
}
 
.button-delete {
  display: inline-block;
  background-color:red;
  color: white;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
 
 
.button-delete:hover {
  background-color: red;
}
 
/*monthwise css*/
 
.MS_container {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
 
.MS_main-content {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  margin-top: 100px;
}
 
.MS_monthwise-head {
  padding: 20px;
  text-align: center;
  width: 100%;
}
 
.MS_monthwise-heading h2 {
  font-size: 28px;
  font-weight: bold;
  margin-top: 10px;
}
 
.MS_bookings {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 100%;
}
 
 
.MS_months {
  /* display: flex; */
  gap: 20px;
  margin-bottom: 0px;
}
 
.MS_monthwise-bookings {
  /* display: flex; */
  flex-direction: column;
  margin-left: 150px;
}
 
.MS_monthwise-bookings label {
  font-weight: bold;
  margin-bottom: 5px;
}
 
 
 .MS_form-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  border: 1px solid #f6f4f4;
  margin-top: -10px;
  padding: 10px;
}
.MS_monthly-reports {
  padding: 8px;
  border: 1px solid #888787;
  border-radius: 4px;
 
}
.MS_search {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: rgb(7, 52, 125);
  color: white;
  text-decoration: none;
  outline: none;
  margin-left: 470px;
}
 
.MS_bookings {
  margin-top: 10px;
}
 
.MS_table-bookingid {
  overflow-x: auto;
}
 
.MS-styled-table {
  height: 220px;
  width: 74%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
 
}
 
.MS-styled-table thead tr {
   
  text-align: left;
  background-color: #05144c;
  color: #ffffff;
}
 
 
.MS-styled-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
}
 
 
.MS-styled-table th,
.MS-styled-table td {
 
  padding: 12px 30px;
  position: sticky;
  z-index: 1;
}
.MS-styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
 
.MS-styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
 
.MS-styled-table tbody tr:last-of-type {
  border-bottom: 1px solid #f4f5f5;
}
 
.MS-styled-table th {
  position: sticky;
  top: 0;
  background-color: #05144c;
  color: #ffffff;
  z-index: 2;
  /*layanu*/
  border: 0px solid #ddd;
 
}
 
 
 
.MS_nodata {
  font-size: 18px;
  text-align: center;
  color: #666;
}
 
.MS_do, .MS_doo {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  text-align: center;
}
 
.MS_do:hover, .MS_doo:hover {
  background-color: #218838;
}
 
.MS_delete-button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
 
.MS_delete-button:hover {
  background-color: #c82333;
}
 
.MS_delete-multiple-button {
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 0px;
  margin-left: 750px;
}
 
.MS_delete-multiple-button:hover {
  background-color: #c82333;
}
@media only screen and (min-width: 320px) and (max-width: 343px) {  
  .booked-main {
    width: 95%;
    overflow-x: hidden;
    margin-left: 10px;
    height: 300px;
   
 
  }
 
  .booked-headd{
 
    margin-top: 100px;
 
  }
 
  .styled-table {
    border-collapse: collapse;
    overflow-y: scroll;
    overflow-x: scroll;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    font-size: 0.9em;
    font-family: sans-serif;
    height: 220px;
    width: 260px;
    margin-left: -15px;
  }
 
  .styled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
  }
 
  .styled-table th,
  .styled-table td {
    padding: 12px;
    text-align: left;
    vertical-align: middle;
    border: 1px solid #ddd;
  }
 
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
 
  .styled-table tbody tr:last-of-type {
    border-bottom: 1px solid #f4f5f5;
  }
 
  .styled-table th {
    position: sticky;
    top: 0;
    background-color: #05144c;
    color: #ffffff;
    z-index: 2;
    /*layanu*/
    border: 0px solid #ddd;
 
  }
 
  .lr-rode {
    text-decoration: none;
    color: #f2f6f5;
  }
  /* Booked.css */
  .button-view {
    background-color: #cfcece;
    color: white;
    padding: px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
  }
  .MS_bookings {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    /* height: 700px; */
    width: 100%;
    margin-top: -10px;
    /* border: 1px solid black; */
  }
 
  .button-view:hover {
    background-color: #cfcece;
  }
 
  .button-delete {
   
    margin-top: 5px;
    background-color:red;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
  .MS_monthly-reports {
    padding: 8px;
    border: 1px solid #888787;
    border-radius: 4px;
    width: 70px;
    height: 30px;
    padding-top: 4px;
    padding-bottom: 1px;
  }
 
  .MS-Smonths {
    font-size: 13px;
    margin-left: 0px;
  }
 
  .MS_delete-multiple-button {
    margin-left:70px;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 15px;
  }
 
  .MS_monthwise-bookings {
    /* display: flex; */
    flex-direction: column;
    margin-left: -10px;
  }
 
  .MS_search {
    padding: 5px 10px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125);
    color: white;
    text-decoration: none;
    outline: none;
    margin-left: 10px;
  }
  .MS-styled-table {
    height: 100px;
    width: 100%;
    overflow-y: scroll;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   margin-left: 0px;
  }
 
}
@media only screen and (min-width: 360px) and (max-width: 374px) {  
  .booked-main {
    width: 95%;
    overflow-x: hidden;
    margin-left: 10px;
    height: 450px;
 
  }
 
  .booked-headd{
 
    margin-top: 100px;
 
  }
 
  .styled-table {
    border-collapse: collapse;
    overflow-y: scroll;
    overflow-x: scroll;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    font-size: 0.9em;
    font-family: sans-serif;
    height: 370px;
    width: 295px;
    margin-left: -10px;
  }
 
  .styled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
  }
 
  .styled-table th,
  .styled-table td {
    padding: 12px;
    text-align: left;
    vertical-align: middle;
    border: 1px solid #ddd;
  }
 
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
 
  .styled-table tbody tr:last-of-type {
    border-bottom: 1px solid #f4f5f5;
  }
 
  .styled-table th {
    position: sticky;
    top: 0;
    background-color: #05144c;
    color: #ffffff;
    z-index: 2;
    /*layanu*/
    border: 0px solid #ddd;
 
  }
 
  .lr-rode {
    text-decoration: none;
    color: #f2f6f5;
  }
  /* Booked.css */
  .button-view {
    background-color: #cfcece;
    color: white;
    padding: px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
  }
  .MS_bookings {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    /* height: 700px; */
    width: 100%;
  }
 
  .button-view:hover {
    background-color: #cfcece;
  }
 
  .button-delete {
   
    margin-top: 5px;
    background-color:red;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
 
  .MS_monthwise-bookings {
    /* display: flex; */
    flex-direction: column;
    margin-left: 0px;
  }
 
  .MS_monthly-reports {
    padding: 8px;
    border: 1px solid #888787;
    border-radius: 4px;
    width: 70px;
    height: 30px;
    padding-top: 4px;
    padding-bottom: 1px;
  }
 
  .MS-Smonths {
    font-size: 13px;
    margin-left: 10px;
  }
 
  .MS_delete-multiple-button {
    margin-left:110px;
    font-size: 11px;
    margin-top: 0px;
    margin-bottom: -15px;
  }
 
  .MS_search {
    padding: 10px 20px;
    font-size: 12px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125);
    color: white;
    text-decoration: none;
    outline: none;
    margin-left: 10px;
  }
  .MS-styled-table {
    height: 200px;
    width: 100%;
    overflow-y: scroll;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   margin-left: 0px;
  }
 
}
@media only screen and (min-width: 375px) and (max-width: 383px) {  
  .booked-main {
    width: 95%;
    overflow-x: hidden;
    margin-left: 10px;
    height: 450px;
 
  }
 
 
  .styled-table {
    border-collapse: collapse;
    overflow-y: scroll;
    overflow-x: scroll;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    font-size: 0.9em;
    font-family: sans-serif;
    height: 370px;
    width: 320px;
    margin-left: -20px;
  }
 
  .styled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
  }
 
  .styled-table th,
  .styled-table td {
    padding: 12px;
    text-align: left;
    vertical-align: middle;
    border: 1px solid #ddd;
  }
 
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
 
  .styled-table tbody tr:last-of-type {
    border-bottom: 1px solid #f4f5f5;
  }
 
  .styled-table th {
    position: sticky;
    top: 0;
    background-color: #05144c;
    color: #ffffff;
    z-index: 2;
    /*layanu*/
    border: 0px solid #ddd;
 
  }
 
  .lr-rode {
    text-decoration: none;
    color: #f2f6f5;
  }
  /* Booked.css */
  .button-view {
    background-color: #cfcece;
    color: white;
    padding: px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
  }
  .MS_bookings {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    /* height: 700px; */
    width: 100%;
  }
 
  .button-view:hover {
    background-color: #cfcece;
  }
 
  .button-delete {
   
    margin-top: 5px;
    background-color:red;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
  .MS_monthly-reports {
    padding: 8px;
    border: 1px solid #888787;
    border-radius: 4px;
    width: 70px;
    height: 30px;
    padding-top: 4px;
    padding-bottom: 1px;
  }
 
  .MS-Smonths {
    font-size: 13px;
    margin-left: 12px;
  }
 
  .MS_delete-multiple-button {
    margin-left:110px;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: -15px;
  }
 
  .MS_monthwise-bookings {
    /* display: flex; */
    flex-direction: column;
    margin-left: 0px;
  }
 
  .MS_search {
    padding: 10px 20px;
    font-size: 12px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125);
    color: white;
    text-decoration: none;
    outline: none;
    margin-left: 10px;
  }
  .MS-styled-table {
    height: 200px;
    width: 100%;
    overflow-y: scroll;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   margin-left: 0px;
  }
 
}
@media only screen and (min-width: 390px) and (max-width: 411px) {  
  .booked-main {
    width: 95%;
    overflow-x: hidden;
    margin-left: 10px;
    height: 450px;
 
  }
 
 
  .styled-table {
    border-collapse: collapse;
    overflow-y: scroll;
    overflow-x: scroll;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    font-size: 0.9em;
    font-family: sans-serif;
    height: 370px;
    width: 330px;
    margin-left: -20px;
  }
 
  .styled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
  }
 
  .styled-table th,
  .styled-table td {
    padding: 12px;
    text-align: left;
    vertical-align: middle;
    border: 1px solid #ddd;
  }
 
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
 
  .styled-table tbody tr:last-of-type {
    border-bottom: 1px solid #f4f5f5;
  }
 
  .styled-table th {
    position: sticky;
    top: 0;
    background-color: #05144c;
    color: #ffffff;
    z-index: 2;
    /*layanu*/
    border: 0px solid #ddd;
 
  }
 
  .lr-rode {
    text-decoration: none;
    color: #f2f6f5;
  }
  /* Booked.css */
  .button-view {
    background-color: #cfcece;
    color: white;
    padding: px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
  }
  .MS_bookings {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    /* height: 700px; */
    width: 100%;
  }
 
  .button-view:hover {
    background-color: #cfcece;
  }
 
  .button-delete {
   
    margin-top: 5px;
    background-color:red;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
 
  .MS_monthwise-bookings {
    /* display: flex; */
    flex-direction: column;
    margin-left: 0px;
  }
 
  .MS_monthly-reports {
    padding: 8px;
    border: 1px solid #888787;
    border-radius: 4px;
    width: 70px;
    height: 30px;
    padding-top: 4px;
    padding-bottom: 1px;
  }
 
  .MS-Smonths {
    font-size: 13px;
    margin-left: 12px;
  }
 
  .MS_delete-multiple-button {
    margin-left:110px;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: -15px;
  }
 
  .MS_search {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125);
    color: white;
    text-decoration: none;
    outline: none;
    font-size: 12px;
    margin-left: 10px;
  }
  .MS-styled-table {
    height: 200px;
    width: 100%;
    overflow-y: scroll;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   margin-left: 0px;
  }
 
}
@media only screen and (min-width: 412px) and (max-width: 427px) {  
  .booked-main {
    width: 95%;
    overflow-x: hidden;
    margin-left: 10px;
    height: 450px;
 
  }
 
 
  .styled-table {
    border-collapse: collapse;
    overflow-y: scroll;
    overflow-x: scroll;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    font-size: 0.9em;
    font-family: sans-serif;
    height: 370px;
    width: 330px;
    margin-left: -5px;
  }
 
  .styled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
  }
 
  .styled-table th,
  .styled-table td {
    padding: 12px;
    text-align: left;
    vertical-align: middle;
    border: 1px solid #ddd;
  }
 
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
 
  .styled-table tbody tr:last-of-type {
    border-bottom: 1px solid #f4f5f5;
  }
 
  .styled-table th {
    position: sticky;
    top: 0;
    background-color: #05144c;
    color: #ffffff;
    z-index: 2;
    /*layanu*/
    border: 0px solid #ddd;
 
  }
 
  .lr-rode {
    text-decoration: none;
    color: #f2f6f5;
  }
  /* Booked.css */
  .button-view {
    background-color: #cfcece;
    color: white;
    padding: px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
  }
  .MS_bookings {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    /* height: 700px; */
    width: 100%;
  }
 
  .button-view:hover {
    background-color: #cfcece;
  }
 
  .button-delete {
   
    margin-top: 5px;
    background-color:red;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
  .MS_monthly-reports {
    padding: 8px;
    border: 1px solid #888787;
    border-radius: 4px;
    width: 90px;
    height: 30px;
    padding-top: 4px;
    padding-bottom: 1px;
  }
 
  .MS_delete-multiple-button {
    margin-left:140px;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: -10px;
  }
 
  .MS_monthwise-bookings {
    /* display: flex; */
    flex-direction: column;
    margin-left: 0px;
  }
 
  .MS_search {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125);
    color: white;
    text-decoration: none;
    outline: none;
    margin-left: 10px;
  }
  .MS-styled-table {
    height: 200px;
    width: 100%;
    overflow-y: scroll;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   margin-left: 0px;
  }
 
}
@media only screen and (min-width: 428px) and (max-width: 767px) {  
  .booked-main {
    width: 95%;
    overflow-x: hidden;
    margin-left: 10px;
    height: 450px;
 
  }
 
 
  .styled-table {
    border-collapse: collapse;
    overflow-y: scroll;
    overflow-x: scroll;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    font-size: 0.9em;
    font-family: sans-serif;
    height: 370px;
    width: 365px;
    margin-left: -10px;
  }
 
  .styled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
  }
 
  .styled-table th,
  .styled-table td {
    padding: 12px;
    text-align: left;
    vertical-align: middle;
    border: 1px solid #ddd;
  }
 
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
 
  .styled-table tbody tr:last-of-type {
    border-bottom: 1px solid #f4f5f5;
  }
 
  .styled-table th {
    position: sticky;
    top: 0;
    background-color: #05144c;
    color: #ffffff;
    z-index: 2;
    /*layanu*/
    border: 0px solid #ddd;
 
  }
 
  .lr-rode {
    text-decoration: none;
    color: #f2f6f5;
  }
  /* Booked.css */
  .button-view {
    background-color: #cfcece;
    color: white;
    padding: px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
  }
  .MS_bookings {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    /* height: 700px; */
    width: 100%;
  }
 
  .button-view:hover {
    background-color: #cfcece;
  }
 
  .button-delete {
   
    margin-top: 5px;
    background-color:red;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
 
  .MS_monthwise-bookings {
    /* display: flex; */
    flex-direction: column;
    margin-left: 0px;
  }
 
  .MS_search {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125);
    color: white;
    text-decoration: none;
    outline: none;
    margin-left: 10px;
  }
 
  .MS_delete-multiple-button {
    margin-left:130px;
    /* font-size: 12px; */
    margin-top: 0px;
    margin-bottom: -15px;
  }
 
  .MS-styled-table {
    height: 200px;
    width: 100%;
    overflow-y: scroll;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   margin-left: 0px;
  }
 
}
 
@media only screen and (min-width: 768px) and (max-width: 799px) {  
 
  .styled-table {
    border: 1px solid #f9f7f7;
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   height: 420px;
  }
 
  .button-delete {
   
    margin-top: 5px;
    background-color:red;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
 
  .MS_monthwise-bookings {
    /* display: flex; */
    flex-direction: column;
    margin-left: 100px;
  }
  .MS-Smonths{
    margin-left: -60px;
  }
  .MS_search {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125);
    color: white;
    text-decoration: none;
    outline: none;
    margin-left: 300px;
  }
 
  .MS_delete-multiple-button {
    margin-left:470px;
    /* font-size: 12px; */
    margin-top: 0px;
    margin-bottom: -15px;
  }
 
  .MS-styled-table {
    height: 260px;
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   
  }
}
@media only screen and (min-width: 800px) and (max-width: 819px) {  
 
  .styled-table {
    border: 1px solid #f0ebeb;
    width: 97%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   height: 420px;
  }
 
  .button-delete {
   
    margin-top: 5px;
    background-color:red;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
 
  .MS_monthwise-bookings {
    /* display: flex; */
    flex-direction: column;
    margin-left: 100px;
  }
 
  .MS-Smonths{
    margin-left: -50px;
  }
  .MS_search {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125);
    color: white;
    text-decoration: none;
    outline: none;
    margin-left: 290px;
  }
 
  .MS_delete-multiple-button {
    margin-left:470px;
    /* font-size: 12px; */
    margin-top: 0px;
    margin-bottom: -15px;
  }
 
  .MS-styled-table {
    height: 260px;
    width: 95%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   
  }
}
@media only screen and (min-width: 820px) and (max-width: 883px) {  
 
  .styled-table {
    border: 1px solid #f5f3f3;
    width: 95%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   height: 420px;
  }
 
  .button-delete {
   
    margin-top: 5px;
    background-color:red;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
 
  .MS_monthwise-bookings {
    /* display: flex; */
    flex-direction: column;
    margin-left: 100px;
  }
  .MS-Smonths{
    margin-left: -40px;
  }
  .MS_search {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125);
    color: white;
    text-decoration: none;
    outline: none;
    margin-left: 300px;
  }
 
  .MS_delete-multiple-button {
    margin-left:490px;
    /* font-size: 12px; */
    margin-top: 0px;
    margin-bottom: -15px;
  }
 
  .MS-styled-table {
    height: 260px;
    width: 90%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   
  }
}
@media only screen and (min-width: 884px) and (max-width: 1279px) {  
 
  .styled-table {
    border: 1px solid #fcfbfb;
    width: 90%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   height: 420px;
  }
 
  .button-delete {
   
    margin-top: 5px;
    background-color:red;
    color: white;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
 
 
  .MS_monthwise-bookings {
    /* display: flex; */
    flex-direction: column;
    margin-left: 100px;
  }
  .MS_search {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125);
    color: white;
    text-decoration: none;
    outline: none;
    margin-left: 430px;
  }
 
  .MS-Smonths{
    margin-left: -70px;
  }
 
  .MS_delete-multiple-button {
    margin-left:590px;
    /* font-size: 12px; */
    margin-top: 0px;
    margin-bottom: -15px;
  }
 
  .MS-styled-table {
    height: 260px;
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
   
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {  
 
/* Booked Component Styles */
 
.booked-main {
 
  border: 0px solid rgb(254, 253, 253);
    margin-top: 10px;
    overflow-y: hidden;
 
}
 
.booked-headd{
  /* border:1px solid rgb(26, 238, 19); */
  /* width: 100%; */
  margin: 20px auto;
 
  margin-top: 150px;
 
  max-width: 1200px;
 
  padding: 20px;
 
  background-color: #f0f0f0;
 
  border: 1px solid #ccc;
 
  border-radius: 5px;
 
 
}
.rides-container {
  margin: auto;
}
 
.booked-pagrmain{
 
  height: 200px;
  border:1px solid white;
 
}
 
 
 
 
 
 
 
.styled-table {
 
  height: 420px;
  width: 90%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
 
}
 
.styled-table thead tr {
   
  text-align: left;
  background-color: #05144c;
  color: #ffffff;
}
 
.booked-main,
.styled-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
}
 
 
.styled-table th,
.styled-table td {
 
  padding: 12px 30px;
  position: sticky;
  z-index: 1;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
 
.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
 
.styled-table tbody tr:last-of-type {
  border-bottom: 1px solid #f4f5f5;
}
 
.styled-table th {
  position: sticky;
  top: 0;
  background-color: #05144c;
  color: #ffffff;
  z-index: 2;
  /*layanu*/
  border: 0px solid #ddd;
 
}
 
.lr-rode {
  text-decoration: none;
  color: #009879;
}
/* Booked.css */
.button-view {
  display: inline-block;
  background-color: #cfcece;
  color: white;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}
 
.button-view:hover {
  background-color: #cfcece;
}
 
.button-delete {
  display: inline-block;
  background-color:red;
  color: white;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
 
 
.button-delete:hover {
  background-color: red;
}
 
/*monthwise css*/
 
.MS_container {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
 
.MS_main-content {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  margin-top: 100px;
}
 
.MS_monthwise-head {
  padding: 20px;
  text-align: center;
  width: 100%;
}
 
.MS_monthwise-heading h2 {
  font-size: 28px;
  font-weight: bold;
  margin-top: 10px;
}
 
.MS_bookings {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 100%;
}
 
 
.MS_months {
  /* display: flex; */
  gap: 20px;
  margin-bottom: 0px;
}
 
.MS_monthwise-bookings {
  /* display: flex; */
  flex-direction: column;
  margin-left: 200px;
}
 
.MS_monthwise-bookings label {
  font-weight: bold;
  margin-bottom: 5px;
}
 
 
 .MS_form-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  border: 1px solid #f6f4f4;
  margin-top: -10px;
  padding: 10px;
}
.MS_monthly-reports {
  padding: 8px;
  border: 1px solid #888787;
  border-radius: 4px;
}
.MS-Smonths{
  margin-left: -150px;
}
.MS_search {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: rgb(7, 52, 125);
  color: white;
  text-decoration: none;
  outline: none;
  margin-left: 700px;
}
 
.MS_bookings {
  margin-top: 10px;
}
 
.MS_table-bookingid {
  overflow-x: auto;
}
 
.MS-styled-table {
  height: 250px;
  width: 98%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
 
}
 
.MS-styled-table thead tr {
   
  text-align: left;
  background-color: #05144c;
  color: #ffffff;
}
 
 
.MS-styled-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
}
 
 
.MS-styled-table th,
.MS-styled-table td {
 
  padding: 12px 30px;
  position: sticky;
  z-index: 1;
}
.MS-styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
 
.MS-styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
 
.MS-styled-table tbody tr:last-of-type {
  border-bottom: 1px solid #f4f5f5;
}
 
.MS-styled-table th {
  position: sticky;
  top: 0;
  background-color: #05144c;
  color: #ffffff;
  z-index: 2;
  /*layanu*/
  border: 0px solid #ddd;
 
}
 
 
 
.MS_nodata {
  font-size: 18px;
  text-align: center;
  color: #666;
}
 
.MS_do, .MS_doo {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  text-align: center;
}
 
.MS_do:hover, .MS_doo:hover {
  background-color: #218838;
}
 
.MS_delete-button {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
 
.MS_delete-button:hover {
  background-color: #c82333;
}
 
.MS_delete-multiple-button {
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: -10px;
  margin-left: 885px;
}
 
.MS_delete-multiple-button:hover {
  background-color: #c82333;
}
}
 
 