.us_user-profile {
  display: flex;
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: auto;
  padding-top: 250px; 
  border:  none;
  border-radius: 10px;
  width: 1000px;
  margin-left: 250px;
  
  box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
  height: 500px;
margin-top: 150px;
}
.us_user-main{
  border: 1px solid white;
}

.us_profile-form {
  grid-template-columns: 1fr 1fr; /* Two columns of equal width */
  gap: 5px 15px; /* 10px row gap and 20px column gap */

  display: flex;
  flex-wrap: wrap; /* Allows wrapping of form groups */
  width: 800px;
}

.us_profile-title {
  font-weight: bold;
  margin-top: -300px;
  font-size: 35px;
  text-align: center; /* Center the title */
}

.us_form-group {
  margin-bottom: 20px;
  width: 48%; /* Adjust width to fit two columns */
  margin-left: 1%; /* Add some margin to separate columns */
  display: flex;
  flex-direction: column;
}

.us_form-group label {
  font-weight: bold;
  display: block; /* Ensures label and input are on separate lines */
}

.us_form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 12px;
}

.us_button-container {
  display: flex;
  margin: 20px;
  margin-top: 20px;
  justify-content: center;
  width: 100%; /* Full width to center button */

}

.us_button-container button {
  width: 100px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: rgb(7, 52, 125); /* Set background color to black */
  color: white; /* Set text color to red */
  text-decoration: none !important;outline: none; /* No outline on focus */

}


.us_save-success, .us_save-error {
  position: absolute;
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 320px) and (max-width: 343px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-x: hidden;
    padding-top: 250px; 
    border:  none;
    border-radius: 10px;
    width: 280px;
    margin-left: 20px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 140px;
  }
  .us_user-main{

    border: 1px solid rgb(243, 239, 239);
  }
  
  .us_profile-form {

    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 5px 15px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 500px;
    margin-left: 250px;
  }
  .us_button-container button {
    width: 150px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125); /* Set background color to black */
    color: white; /* Set text color to red */
    margin-right: 250px;
  }
  
}
@media only screen and (min-width: 360px) and (max-width: 374px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-x: hidden;
    padding-top: 250px; 
    border:  none;
    border-radius: 10px;
    width: 325px;
    margin-left: 20px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 140px;
  }
  .us_user-main{

    border: 1px solid rgb(243, 239, 239);
  }
  
  .us_profile-form {

    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 5px 15px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 600px;
    margin-left: 300px;
  }
  .us_button-container button {
    width: 150px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125); /* Set background color to black */
    color: white; /* Set text color to red */
    margin-right: 270px;
  }
  
}
@media only screen and (min-width: 375px) and (max-width: 383px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-x: hidden;
    padding-top: 250px; 
    border:  none;
    border-radius: 10px;
    width: 335px;
    margin-left: 20px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 140px;
  }
  .us_user-main{

    border: 1px solid rgb(243, 239, 239);
  }
  
  .us_profile-form {

    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 5px 15px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 600px;
    margin-left: 300px;
  }
  .us_button-container button {
    width: 150px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125); /* Set background color to black */
    color: white; /* Set text color to red */
    margin-right: 270px;
  }
  
}
@media only screen and (min-width: 390px) and (max-width: 411px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
    padding-top: 250px; 
    overflow-x: hidden;
    border-radius: 10px;
    width: 355px;
    margin-left: 20px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 140px;
  }
  .us_user-main{
    border: 1px solid white;
  }
  
  .us_profile-form {
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 5px 15px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 600px;
    margin-left: 300px;
  }
  .us_button-container button {
    width: 150px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125); /* Set background color to black */
    color: white; /* Set text color to red */
    margin-right: 270px;
  }
  
}
@media only screen and (min-width: 412px) and (max-width: 427px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-x: hidden;
    padding-top: 250px; 
    border:  none;
    border-radius: 10px;
    width: 375px;
    margin-left: 20px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 140px;
  }
  .us_user-main{
    border: 1px solid white;
  }
  
  .us_profile-form {
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 5px 15px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 600px;
    margin-left: 300px;
  }
  .us_button-container button {
    width: 150px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125); /* Set background color to black */
    color: white; /* Set text color to red */
    margin-right: 270px;
  }
  
}

@media only screen and (min-width: 428px) and (max-width: 767px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-x: hidden;
    padding-top: 250px; 
    border:  none;
    border-radius: 10px;
    width: 385px;
    margin-left: 20px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 140px;
  }
  .us_user-main{
    border: 1px solid white;
  }
  
  .us_profile-form {
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 5px 15px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 600px;
    margin-left: 300px;
  }
  .us_button-container button {
    width: 150px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125); /* Set background color to black */
    color: white; /* Set text color to red */
    margin-right: 270px;
  }
  
}


@media only screen and (min-width: 768px) and (max-width: 799px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
    padding-top: 300px; 
    border:  none;
    border-radius: 10px;
    width: 700px;
    margin-left: 30px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 150px;
  }
  .us_user-main{
    border: 1px solid white;
  }
  
  .us_profile-form {
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 10px 12px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 600px;
  }
}
@media only screen and (min-width: 800px) and (max-width: 819px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
    padding-top: 300px; 
    border:  none;
    border-radius: 10px;
    width: 750px;
    margin-left: 25px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 130px;
  }
  .us_user-main{
    border: 1px solid white;
  }
  
  .us_profile-form {
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 10px 12px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 600px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 833px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
    padding-top: 300px; 
    border:  none;
    border-radius: 10px;
    width: 750px;
    margin-left: 35px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 150px;
  }
  .us_user-main{
    border: 1px solid white;
  }
  
  .us_profile-form {
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 10px 12px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 600px;
  }
}
@media only screen and (min-width: 834px) and (max-width: 883px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
    padding-top: 325px; 
    border:  none;
    border-radius: 10px;
    width: 750px;
    margin-left: 40px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 150px;
  }
  .us_user-main{
    border: 1px solid white;
  }
  
  .us_profile-form {
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 10px 12px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 600px;
  }
}
@media only screen and (min-width: 884px) and (max-width: 1279px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
    padding-top: 320px; 
    border:  none;
    border-radius: 10px;
    width: 750px;
    margin-left: 65px;
    
    box-shadow: 2px  2px 2px 5px rgba(32, 32, 32, 0.1);
    height: 500px;
margin-top: 150px;
  }
  .us_user-main{
    border: 1px solid white;
  }
  
  .us_profile-form {
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 10px 12px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 600px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {

  .us_user-profile {
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
    padding-top: 250px; 
    border:  none;
    border-radius: 10px;
    width: 1000px;
    margin-left: 150px;
    
    box-shadow: 2px  2px 2px 5px rgba(41, 39, 39, 0.1);
    height: 500px;
  margin-top: 150px;
  }
  .us_user-main{
    border: 1px solid white;
  }
  
  .us_profile-form {
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 5px 15px; /* 10px row gap and 20px column gap */
  
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of form groups */
    width: 800px;
  }
  
  .us_profile-title {
    font-weight: bold;
    margin-top: -300px;
    font-size: 35px;
    text-align: center; /* Center the title */
  }
  
  .us_form-group {
    margin-bottom: 20px;
    width: 48%; /* Adjust width to fit two columns */
    margin-left: 1%; /* Add some margin to separate columns */
    display: flex;
    flex-direction: column;
  }
  
  .us_form-group label {
    font-weight: bold;
    display: block; /* Ensures label and input are on separate lines */
  }
  
  .us_form-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 12px;
  }
  
  .us_button-container {
    display: flex;
    margin: 20px;
    margin-top: 20px;
    justify-content: center;
    width: 100%; /* Full width to center button */
  
  }
  
  .us_button-container button {
    width: 100px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: rgb(7, 52, 125); /* Set background color to black */
    color: white; /* Set text color to red */
    text-decoration: none !important;outline: none; /* No outline on focus */
  
  }
  
  
  .us_save-success, .us_save-error {
    position: absolute;
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
}
