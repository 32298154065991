/* Container for the whole form */
.lca-containerr-city {
  border: 1px solid white;
}

.lca-container-city {
  margin: 20px auto;

  margin-top: 150px;

  max-width: 1200px;

  max-height: 550px;

  padding: 20px;

  background-color: #f0f0f0;

  border: 1px solid #ccc;

  border-radius: 5px;
}

.lca-tab-container-city {
  margin-bottom: 20px;
}

.lcg-city-container {
  width: 400px;

  /* height: 350px; */

  padding: 20px;

  background-color: #ffffff;

  border-radius: 8px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  margin: 20px auto;

  margin-top: 35px;
}

.lcg-container {
  border: 1px solid #ddd;
}

/* Heading */

.lcg-city-container h1 {
  font-size: 24px;

  margin-bottom: 20px;

  text-align: center;

  color: #333;
}

/* Form group */

.lcg-form-group {
  display: flex;

  flex-direction: column;

  gap: 10px;
}

/* Input for new city */

.lcg-input {
  width: 100%;

  padding: 10px;

  border: 1px solid #ccc;

  border-radius: 4px;

  box-sizing: border-box;

  font-size: 16px;

  margin-top: 10px;
}

/* Select for city dropdown */

.lcg-select {
  width: 100%;

  padding: 10px;

  border: 1px solid #ccc;

  border-radius: 4px;

  box-sizing: border-box;

  font-size: 16px;
}

/* Container for location inputs */

.lcg-form-group {
  margin-bottom: 10px;
}

/* Input for location name */

.lcg-input {
  width: calc(100% - 40px); /* Adjusted width to fit select and button */
}

/* Select for location city */

.lcg-select {
  margin-top: 10px;

  width: calc(100% - 40px); /* Adjusted width to fit input and button */
}

/* Container for location input group */

.lcg-location-input-group {
  display: flex;

  align-items: center;

  gap: 10px;
}

/* Button to add location */

.lcg-add-location-btn {
  width: 150px;

  height: 40px;

  border-radius: 5px;

  margin-top: 15px;

  background-color: #05144c;

  color: white;

  border: none;

  cursor: pointer;

  transition: background-color 0.3s;
}

.lcg-add-location-btn:hover {
  background-color: #0056b3;
}

/* Button to submit */

.lcg-add-btn {
  width: 100%;

  padding: 10px;

  margin-top: 10px;

  border-radius: 5px;

  background-color: #05144c;

  color: white;

  border: none;

  cursor: pointer;

  transition: background-color 0.3s;
}

.lcg-add-btn:hover {
  background-color: #0056b3;
}

/* Table container for cities */

.lcg-city-table-container {
  overflow-y: scroll;

  height: 320px;

  margin-top: 20px;
}

/* City table styles */

.lcg-city-table {
  width: 100%;

  border-collapse: collapse;

  margin-top: 10px;
}

.lcg-city-table th,
.lcg-city-table td {
  border: 1px solid #ddd;

  padding: 8px;

  text-align: center;
}

.lcg-edit-input {
  width: 100px;
}

.lcg-edit-btn,
.lcg-save-btn,
.lcg-delete-btn {
  padding: 6px 10px;

  margin-right: 5px;

  cursor: pointer;

  border: none;

  border-radius: 5px;

  color: white;

  transition: background-color 0.3s;
}

.lcg-edit-btn {
  background-color: #05144c;
}

.lcg-save-btn {
  background-color: #05144c;
}

.lcg-delete-btn {
  background-color: #05144c;
}

.lcg-edit-btn:hover,
.lcg-save-btn:hover,
.lcg-delete-btn:hover {
  background-color: #05144c;
}

/* Responsive */

@media (320px <= width <= 375px) {
  .lcg-city-container {
    width: 250px;
  }
  
}

@media (360px <= width <= 414px) {
  .lcg-city-container {
    width: 300px;
  }
}

@media (375px <= width <= 414px) {
  .lcg-city-container {
    width: 300px;
  }
}

@media (428px <= width <= 767px) {
  .lcg-city-container {
    width: 350px;
  }
  
}

@media (768px <= width <= 820px) {
}

@media (820px <= width <= 834px) {
}

@media (834px <= width <= 1194px) {
}

@media (800px <= width <= 834px) {
}

@media (884px <= width <= 1024px) {
  

 
}
