.header-user {
  height: 100px;
}

* {
  margin: 0;
}

.header-linked-text {
  text-decoration: none;
}

.header-logo-10 {

  margin-top: 20px;
}


.header-cheader {
  height: 300px;
}

.header-yaa {

  width: 200px;
  color: #054652;
  font-weight: 800;
  margin-top: 0px;
  height: 200px;



}

/* Navbar.css */
.header-tiger a {



  transition: transform 0.3s ease;
  /* Add transition property */
}

.header-tiger ul p a {
  color: #054652 COPY;
  font-size: 20px;
  font-weight: 100px;

  transition: transform 0.3s ease;
  /* Add transition property */
  font-family: 'Times New Roman', Times, serif;
}


.header-tiger a:hover {
  color: rgb(7, 7, 7);
  transform: scale(1.1);

  padding-left: 4px;

}



.header-tiger {
  margin-right: -450px;
  margin-bottom: 40px;

}



.header-dropdown {
  position: relative;
  display: inline-block;
  align-items: center;

  margin-right: 40px;
}


.header-details {

  display: inline-block;
  align-items: center;

}

.header-publish {
  margin-left: 25px;
}

.header-dropdown-content {
  display: none;
  height: 150px;
  margin-left: -30px;
  position: absolute;
  background-color: white;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.header-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.header-dropdown-content a:hover {
  background-color: #05144c;
  border: 1px solid rgb(201, 189, 189);
  width: 95%;
  color: white;
  margin-left: 10px;
  height: 50px;

}

.header-dropdown:hover .header-dropdown-content {
  display: block;
}

.header-profile-image {
  width: 70px;
  /* Adjust width as needed */
  height: 60px;
  /* Adjust height as needed */
  border-radius: 70%;
  /* To make it circular */
  background-size: cover;
  margin-top: 1px;
  background-position: center;
  background-image: url('../Imagess/profile.jpg');
}

/* Additional styles for the profile details */
/* UserHeader.css */
.header-profile-info-container {
  display: flex;
  margin-left: 500px;
  align-items: center;
}


.header-profile-details {
  position: absolute;
  top: 70%;
  /* Adjust as needed */
  right: 12%;
  transform: translateX(-50%);
  font-size: 10px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-profile-details p {
  margin: 0;
}

.header-profile-info-container:hover .header-profile-details {
  display: block;
  /* Show profile details when hovering over the image */
}


.header-tiger ul {
  display: flex;
  margin-top: 30px;



}




.header-header-icon {
  width: 60px;
  height: 60px;
  margin-top: 20px;

}

.header-icons {
  font-size: 1px;
  color: #054652 COPY;
  background-color: #dfdfe1;
  border: 1px solid rgb(227, 219, 219);
  width: 40px;
  height: 40px;
  border-radius: 100px;
  font-weight: 1000;
  margin-left: -30px;
  margin-top: 20px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #f8f7f2;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: black;
}

header {
  position: fixed;
  z-index: 1000;
  /* Ensure the navbar stays on top of other elements */
  top: 0;
  width: 100%;
  margin-top: 2%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 2rem;
  background-color: rgb(235, 229, 229);
  color: var(--textColor);
  /* padding-left: 25px; */
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
}




nav a:hover {
  color: var(--secondaryColor);
}

header .header-nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);

  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

.header-cheader {
  background-color: #05144c;
  width: 100%;
  height: 30px;
  position: fixed;
  z-index: 1000;
}

@keyframes eyeMove {

  0%,
  10% {
    background-position: 0px 8px;
  }

  13%,
  40% {
    background-position: -5px 0px;
  }

  43%,
  70% {
    background-position: 5px 0px;
  }

  73%,
  90% {
    background-position: 0px 8px;
  }

  93%,
  100% {
    background-position: 0px 8px;
  }
}

@keyframes blink {

  0%,
  10%,
  12%,
  20%,
  22%,
  40%,
  42%,
  60%,
  62%,
  70%,
  72%,
  90%,
  92%,
  98%,
  100% {
    height: 28px;
  }

  11%,
  21%,
  41%,
  61%,
  71%,
  91%,
  99% {
    height: 9px;
  }
}










@media only screen and (max-width: 768px) {
  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .header-clr {
    background-color: #05144c;
    width: 100%;
    height: 15px;
    position: fixed;
    z-index: 100;
  }

  .header-dropdown-content a:hover {
    background-color: #f1f1f1;
    border: 1px solid rgb(201, 189, 189);
    width: 90%;
    margin-left: 8px;
    height: 50px;

  }

  .header-tiger {
    margin-left: -400px;
  }

  .header-dropdown:hover .header-dropdown-content {
    display: block;
  }

  .header-profile-image {
    width: 70px;
    /* Adjust width as needed */
    height: 60px;
    /* Adjust height as needed */
    border-radius: 50%;
    /* To make it circular */
    background-size: cover;
    margin-top: 50px;
    background-position: center;
    margin-left: -130px;
  }

  /* Additional styles for the profile details */
  /* UserHeader.css */
  .header-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .header-dropdown-content a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }

  .header-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    color: white;
    width: 90%;
    margin-left: 10px;
    height: 50px;

  }

  .header-dropdown-content {
    display: none;
    height: 200px;
    margin-left: -100px;

    position: absolute;
    background-color: white;
    min-width: 160px;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -70px;
  }

  .header-profile-details {
    position: absolute;
    top: 55%;
    /* Adjust as needed */
    right: 40%;
    transform: translateX(-50%);
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-profile-details p {
    margin: 0;
  }

  .header-profile-info-container:hover .header-profile-details {
    display: block;
    /* Show profile details when hovering over the image */
  }


  .header-tiger ul {
    display: flex;
    margin-top: 0px;



  }




  .header-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: #dfdfe1;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 10px;
    font-weight: 100;
    margin-right: -40px;
    margin-top: -160px;
  }

  header .header-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 250px;
  }

}

@media only screen and (min-width: 769px) and (max-width: 1024px) {

  .header-clr {
    background-color: #05144c;
    width: 100%;
    height: 15px;
    position: fixed;
    z-index: 100;
  }

  .header-dropdown-content a:hover {
    background-color: #f1f1f1;
    border: 1px solid rgb(201, 189, 189);
    width: 90%;
    margin-left: 8px;
    height: 50px;

  }

  .header-tiger {
    margin-left: -400px;
  }

  .header-dropdown:hover .header-dropdown-content {
    display: block;
  }

  .header-profile-image {
    width: 70px;
    /* Adjust width as needed */
    height: 60px;
    /* Adjust height as needed */
    border-radius: 50%;
    /* To make it circular */
    background-size: cover;
    margin-top: 80px;
    background-position: center;
    margin-left: -150px;
  }

  .header-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: #dfdfe1;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 10px;
    font-weight: 100;
    margin-right: -0px;
    margin-top: -150px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .header-profile-details {
    position: absolute;
    top: 55%;
    /* Adjust as needed */
    right: 48%;
    transform: translateX(-50%);
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Additional styles for the profile details */
  /* UserHeader.css */
  .header-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .header-dropdown-content a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }

  .header-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    color: white;
    width: 90%;
    margin-left: 10px;
    height: 50px;

  }

  .header-dropdown-content {
    display: none;
    height: 200px;
    margin-left: -0px;

    position: absolute;
    background-color: white;
    min-width: 160px;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -60px;
  }



  .header-profile-details p {
    margin: 0;
  }

  .header-profile-info-container:hover .header-profile-details {
    display: block;
    /* Show profile details when hovering over the image */
  }


  .header-tiger ul {
    display: flex;
    margin-top: 0px;



  }





  header .header-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }


}