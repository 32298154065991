

.lrf-foot {
  background-color: #112777ea;
  height: 250px;
  width: 100%;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  padding: 60px;
}
.lrf-icon-img {
  width: 90px;
height: 90px;
  background-color: white;
  border-radius: 50px;
  margin-left: 80px;
  margin-top: 10px;
  font-size: 00px;

}
  .lrf-foot-section {
    flex: 1;
    margin-left: 120px;
    margin-top:-20px;
  }
 
  .lrf-foot-section h4 {
    margin-bottom: 10px;
  }
 
  .lrf-foot-section h2 {
    margin: 5px 0;
  }
  .lrf-foot-section-1 {
    flex: 1;
    margin-left: 150px;
    margin-top:-20px;
  }
 
  .lrf-foot-section-1 h4 {
    margin-bottom: 10px;
  }
 
  .lrf-foot-section-1 h2 {
    margin: 5px 0;
  }

  .lrf-foot-section-2 {
    flex: 1;
    margin-left: 20px;
    margin-top:-20px;
  }
 
  .lrf-foot-section-2 h4 {
    margin-bottom: 10px;
  }
 
  .lrf-foot-section-2 h2 {
    margin: 5px 0;
  }
  
  .lrf-language-dropdown {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
.lrf-top{
    font-size: 22px;
    text-align: left;
    width: 190px;
    letter-spacing: normal;
    color:white;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}  
.lrf-work{
    font-size: 16px;
    text-align: left;
    
    letter-spacing: normal;
    color:white;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.lrf-social-icons-container {
    display: flex;
    margin-top: 20px;
    
  }
 
  .lrf-iicon {
    margin-right: 20px; 
    font-size: 40px;
    color:rgb(240, 240, 247);
  }

  @media only screen and (min-width: 240px) and (max-width: 319px) {  

    .lrf-foot{
     
      border-radius: 10px;
      border:1px solid rgb(214, 18, 18);

      width:830px;
      margin-left: 20px;
      height:890px;
      display:block;
      margin-top: 20px;
  
    }
    .lrf-foot-section{
    

        margin-left: 0px;
        text-align: center;
        width: 100%;
        margin-bottom: 0px;
    }
    .lrf-foot-section-1{
  
      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-2{
  
    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
  }
  
    .lrf-icon-img{
  
      width: 130px;
      background-color: white;
      border-radius: 100px;
      margin-left: 270px;
      margin-top: -40px;
    
      
    }
   
    .lrf-top{
  
      margin-left: 150px;
      font-size: 35px;
      text-align: center;
      margin-top: 40px;
      width: 350px;
    }
  
    .lrf-work{
  
      text-align: center;
      font-size: 30px;

  
    }
    .lrf-social-icons-container{
  
      margin-left: 300px;
    }
    .lrf-iicon {
  
        margin-right: 15px; 
        font-size: 30px; 
        color: white;
        text-align: center;
      }
  
  
  
  }
@media only screen and (min-width: 320px) and (max-width: 343px) {  

  .lrf-foot{
   
    border-radius: 10px;
    overflow-y: hidden;

    width:97%;
    margin-left: 5px;
    height:570px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 90px;
    background-color: white;
    border-radius: 100px;
    margin-left: 50px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 0px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;
  

  }
  .lrf-social-icons-container{

    margin-left: 50px;
  }
  .lrf-iicon {

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}
@media only screen and (min-width: 344px) and (max-width: 352px) {  

  .lrf-foot{
   
    border-radius: 10px;
    overflow-y: hidden;

    width:97%;
    margin-left: 5px;
    height:580px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: -10px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 80px;
    background-color: white;
    border-radius: 80px;
    margin-left: 60px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 10px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;
  

  }
  .lrf-social-icons-container{

    margin-left: 50px;
  }
  .lrf-iicon {

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}
@media only screen and (min-width: 353px) and (max-width: 359px) {  

  .lrf-foot{
   
    border-radius: 10px;
    overflow-y: hidden;

    width:97%;
    margin-left: 5px;
    height:620px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 90px;
    background-color: white;
    border-radius: 100px;
    margin-left: 50px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 10px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;
  

  }
  .lrf-social-icons-container{

    margin-left: 50px;
  }
  .lrf-iicon {

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}
@media only screen and (min-width: 360px) and (max-width: 374px) {  

  .lrf-foot{
    overflow-x: hidden;
    border-radius: 10px;

    width:97%;
    margin-left: 5px;
    height:610px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 90px;
    background-color: white;
    border-radius: 100px;
    margin-left: 60px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 10px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;
  

  }
  .lrf-social-icons-container{

    margin-left: 70px;
  }
  .lrf-iicon {
    overflow-y: hidden;

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}
@media only screen and (min-width: 375px) and (max-width: 383px) {  

  .lrf-foot{
    overflow-x: hidden;
    border-radius: 10px;

    width:97%;
    margin-left: 5px;
    height:610px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 90px;
    background-color: white;
    border-radius: 100px;
    margin-left: 60px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 30px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;
  

  }
  .lrf-social-icons-container{

    margin-left: 90px;
  }
  .lrf-iicon {
    overflow-y: hidden;

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}
@media only screen and (min-width: 384px) and (max-width: 389px) {  

  .lrf-foot{
    overflow-x: hidden;
    border-radius: 10px;

    width:97%;
    margin-left: 5px;
    height:610px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 90px;
    background-color: white;
    border-radius: 100px;
    margin-left: 70px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 30px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;
  

  }
  .lrf-social-icons-container{

    margin-left: 80px;
  }
  .lrf-iicon {
    overflow-y: hidden;

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}
@media only screen and (min-width: 390px) and (max-width: 411px) {  

  .lrf-foot{
    overflow-x: hidden;
    border-radius: 10px;

    width:97%;
    margin-left: 5px;
    height:620px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: 10px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 90px;
    background-color: white;
    border-radius: 100px;
    margin-left: 75px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 30px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;
  

  }
  .lrf-social-icons-container{

    margin-left: 85px;
  }
  .lrf-iicon {
    overflow-y: hidden;

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}
@media only screen and (min-width: 412px) and (max-width: 413px) {  

  .lrf-foot{
    overflow-x: hidden;
    border-radius: 10px;

    width:97%;
    margin-left: 5px;
    height:630px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 90px;
    background-color: white;
    border-radius: 90px;
    margin-left: 90px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 50px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;


  }
  .lrf-social-icons-container{

    margin-left: 85px;
  }
  .lrf-iicon {
    overflow-y: hidden;

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}
@media only screen and (min-width: 414px) and (max-width: 427px) {  
  
  .lrf-foot{
    overflow-x: hidden;
    border-radius: 10px;

    width:97%;
    margin-left: 5px;
    height:610px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 90px;
    background-color: white;
    border-radius: 100px;
    margin-left: 85px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 40px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;
  

  }
  .lrf-social-icons-container{

    margin-left: 90px;
  }
  .lrf-iicon {
    overflow-y: hidden;

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}
@media only screen and (min-width: 428px) and (max-width: 479px) {  
  
  .lrf-foot{
    overflow-x: hidden;
    border-radius: 10px;

    width:97%;
    margin-left: 5px;
    height:610px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 90px;
    background-color: white;
    border-radius: 100px;
    margin-left: 90px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 40px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;
  

  }
  .lrf-social-icons-container{

    margin-left: 100px;
  }
  .lrf-iicon {
    overflow-y: hidden;

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}
@media only screen and (min-width: 480px) and (max-width: 480px) {  
  
  .lrf-foot{
    overflow-x: hidden;
    border-radius: 10px;

    width:97%;
    margin-left: 5px;
    height:620px;
    display:block;
    margin-top: 20px;

  }
  .lrf-foot-section{

      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 0px;
  }
  .lrf-foot-section-1{

    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
.lrf-foot-section-2{

  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

  .lrf-icon-img{

    width: 90px;
    background-color: white;
    border-radius: 100px;
    margin-left: 120px;
    margin-top: -40px;
  
    
  }
 
  .lrf-top{

    margin-left: 70px;
    text-align: center;
    margin-top: 40px;
  }

  .lrf-work{

    text-align: center;
  

  }
  .lrf-social-icons-container{

    margin-left: 120px;
  }
  .lrf-iicon {
    overflow-y: hidden;

      margin-right: 15px; 
      font-size: 20px; 
      color: white;
      text-align: center;
    }



}

@media only screen and (min-width: 481px) and (max-width: 539px) {  
  .lrf-foot{
      width:100%;
      height:250px;
    
      margin-top: 20px;
 
    }
    .lrf-foot-section{
        margin-left: 10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: -10px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: -70px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 100px;
      background-color: white;
      border-radius: 100px;
      margin-left: 0px;
      margin-top: 10px;
      margin-bottom: 15px;
      
    }
    .lrf-top{

      margin-left: 10px;
      text-align: center;
      font-size: 23px;
    }
   
    .lrf-work{
      text-align: center;
      
    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 30px; 
        color: white;
        text-align: center;
      }


}
@media only screen and (min-width: 540px) and (max-width: 599px) {  
  .lrf-foot{
      width:100%;
      height:200px;
    
      margin-top: 20px;
 
    }
    .lrf-foot-section{
        margin-left: -20px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: -50px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: -100px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 80px;
      background-color: white;
      border-radius: 80px;
      margin-left: -30px;
      margin-top: -10px;
      margin-bottom: 15px;
      
    }
    .lrf-top{
      margin-top: -20px;

      margin-left: 20px;
      text-align: center;
      font-size: 20px;

    }
   
    .lrf-work{
      text-align: center;
      font-size: 15px; 
      


    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 20px; 
        color: white;
        text-align: center;
      }


}
@media only screen and (min-width: 600px) and (max-width: 639px) {  
  .lrf-foot{
      width:100%;
      height:220px;
    
      margin-top: 20px;
 
    }
    .lrf-foot-section{
        margin-left: 10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: -10px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: -70px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 80px;
      background-color: white;
      border-radius: 50px;
      margin-left: -30px;
      margin-top: 10px;
      margin-bottom: 15px;
      
    }
    .lrf-top{

      margin-left: 0px;
      text-align: center;
      font-size: 23px;
    }
   
    .lrf-work{
      text-align: center;
      
    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 20px; 
        color: white;
        text-align: center;
      }


}
@media only screen and (min-width: 640px) and (max-width: 713px) {  
  .lrf-foot{
      width:100%;
      height:230px;
    
      margin-top: 20px;
 
    }
    .lrf-foot-section{
        margin-left: 10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: -10px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: -70px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 80px;
      background-color: white;
      border-radius: 50px;
      margin-left: -30px;
      margin-top: 10px;
      margin-bottom: 15px;
      
    }
    .lrf-top{

      margin-left: 10px;
      text-align: center;
      font-size: 23px;
    }
   
    .lrf-work{
      text-align: center;
      
    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 20px; 
        color: white;
        text-align: center;
      }


}
@media only screen and (min-width: 712px) and (max-width: 767px) {  
  .lrf-foot{
      width:100%;
      height:220px;
    
      margin-top: 20px;
 
    }
    .lrf-foot-section{
        margin-left: 20px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: -0px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: -50px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 80px;
      background-color: white;
      border-radius: 50px;
      margin-left: -30px;
      margin-top: 10px;
      margin-bottom: 15px;
      
    }
    .lrf-top{

      margin-left: 10px;
      text-align: center;
      font-size: 23px;
    }
   
    .lrf-work{
      text-align: center;
      
    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 20px; 
        color: white;
        text-align: center;
      }


}
@media only screen and (min-width: 768px) and (max-width: 768px) {  
  .lrf-foot{
      width:100%;
      height:220px;
    
      margin-top: 20px;
 
    }
    .lrf-foot-section{
        margin-left: 20px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: -0px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: -50px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 80px;
      background-color: white;
      border-radius: 50px;
      margin-left: -10px;
      margin-top: 10px;
      margin-bottom: 15px;
      
    }
    .lrf-top{

      margin-left: 20px;
      text-align: center;
      font-size: 23px;
    }
   
    .lrf-work{
      text-align: center;
      
    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 20px; 
        color: white;
        text-align: center;
      }


}
 
  @media only screen and (min-width: 769px) and (max-width: 799px) {  
    .lrf-foot{
        width:100%;
        height:250px;
      
        margin-top: 20px;
   
      }
      .lrf-foot-section{
          margin-left: 10px;
          text-align: center;
          width: 100%;
          margin-bottom: 20px;
      }
      .lrf-foot-section-1{
        margin-left: -10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-2{
      margin-left: -70px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
      .lrf-icon-img{
        width: 100px;
        background-color: white;
        border-radius: 100px;
        margin-left: 0px;
        margin-top: 10px;
        margin-bottom: 15px;
        
      }
      .lrf-top{
  
        margin-left: 10px;
        text-align: center;
        font-size: 23px;
      }
     
      .lrf-work{
        text-align: center;
        
      }
      .lrf-social-icons-container{
        margin-left: 70px;
      }
      .lrf-iicon {
          margin-right: 10px; 
          font-size: 30px; 
          color: white;
          text-align: center;
        }


}
@media only screen and (min-width: 800px) and (max-width: 819px) {  
  .lrf-foot{
      width:100%;
      height:220px;
    
      margin-top: 20px;
 
    }
    .lrf-foot-section{
        margin-left: 10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: -10px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: -70px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 100px;
      background-color: white;
      border-radius: 100px;
      margin-left: 0px;
      margin-top: 10px;
      margin-bottom: 15px;
      
    }
    .lrf-top{

      margin-left: 20px;
      text-align: center;
      font-size: 23px;
    }
   
    .lrf-work{
      text-align: center;
      
    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 30px; 
        color: white;
        text-align: center;
      }


}

@media only screen and (min-width: 820px) and (max-width: 852px) {  
  .lrf-foot{
      width:99%;
      margin-left: 10px;
      height:280px;
      margin-top: 20px;
      border-radius: 10px;

 
    }
    .lrf-foot-section{
        margin-left: 10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: -10px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: -70px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 100px;
      background-color: white;
      border-radius: 100px;
      margin-left: 10px;
      margin-top: 0px;
      margin-bottom: 15px;
      
    }
    .lrf-top{

      margin-left: 20px;
      text-align: center;
      font-size: 23px;
    }
   
    .lrf-work{
      text-align: center;
      
    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 30px; 
        color: white;
        text-align: center;
      }


}
@media only screen and (min-width: 853px) and (max-width: 883px) {  
  .lrf-foot{
      width:99%;
      margin-left: 10px;
      height:380px;
      margin-top: 20px;
      border-radius: 10px;

 
    }
    .lrf-foot-section{
        margin-left: 10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: -10px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: -70px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 100px;
      background-color: white;
      border-radius: 100px;
      margin-left: 10px;
      margin-top: 0px;
      margin-bottom: 15px;
      
    }
    .lrf-top{

      margin-left: 20px;
      text-align: center;
      font-size: 23px;
    }
   
    .lrf-work{
      text-align: center;
      
    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 28px; 
        color: white;
        text-align: center;
      }


}

@media only screen and (min-width: 884px) and (max-width: 913px) {  
  .lrf-foot{
      width:100%;
      height:230px;
    
      margin-top: 20px;
 
    }
    .lrf-foot-section{
        margin-left: 10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: 40px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: 10px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 100px;
      background-color: white;
      border-radius: 100px;
      margin-left: 0px;
      margin-top: 10px;
      margin-bottom: 15px;
      
    }
    .lrf-top{

      margin-left: 20px;
      text-align: center;
      font-size: 23px;
    }
   
    .lrf-work{
      text-align: center;
      
    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 30px; 
        color: white;
        text-align: center;
      }


}
@media only screen and (min-width: 912px) and (max-width: 1023px) {  
  .lrf-foot{
      width:100%;
      height:470px;
    
      margin-top: 20px;
 
    }
    .lrf-foot-section{
        margin-left: 10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .lrf-foot-section-1{
      margin-left: 40px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .lrf-foot-section-2{
    margin-left: 10px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .lrf-icon-img{
      width: 100px;
      background-color: white;
      border-radius: 100px;
      margin-left: -10px;
      margin-top: 10px;
      margin-bottom: 15px;
      
    }
    .lrf-top{

      margin-left: 20px;
      text-align: center;
      font-size: 23px;
    }
   
    .lrf-work{
      text-align: center;
      
    }
    .lrf-social-icons-container{
      margin-left: 70px;
    }
    .lrf-iicon {
        margin-right: 10px; 
        font-size: 30px; 
        color: white;
        text-align: center;
      }


}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {  


  .lrf-foot {
    background-color: #112777ea;
    height: 220px;
    width: 100%;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    padding: 60px;
  }
  .lrf-icon-img {
    width: 100px;
  
    background-color: white;
    border-radius: 100px;
    margin-left: 0px;
    margin-top: 0px;
    
  
  }
    .lrf-foot-section {
      flex: 1;
      margin-left: 120px;
      margin-top:-20px;
    }
   
    .lrf-foot-section h4 {
      margin-bottom: 10px;
    }
   
    .lrf-foot-section h2 {
      margin: 5px 0;
    }
    .lrf-foot-section-1 {
      flex: 1;
      margin-left: 100px;
      margin-top:-20px;
    }
   
    .lrf-foot-section-1 h4 {
      margin-bottom: 10px;
    }
   
    .lrf-foot-section-1 h2 {
      margin: 5px 0;
    }
  
    .lrf-foot-section-2 {
      flex: 1;
      margin-left: 0px;
      margin-top:-20px;
    }
   
    .lrf-foot-section-2 h4 {
      margin-bottom: 10px;
    }
   
    .lrf-foot-section-2 h2 {
      margin: 5px 0;
    }
    
    .lrf-language-dropdown {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  .lrf-top{
      font-size: 22px;
      text-align: left;
      width: 190px;
      letter-spacing: normal;
      color:white;
      FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }  
  .lrf-work{
      font-size: 16px;
      letter-spacing: normal;
      color:white;
      FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lrf-social-icons-container {
      display: flex;
      margin-top: 20px;
      
    }
   
    .lrf-iicon {
      margin-right: 20px; /* Adjust as needed */
      font-size: 40px; /* Adjust as needed */
      color:rgb(240, 240, 247);
    }
}
@media only screen and (min-width: 1280px) and (max-width: 1280px) {  


  .lrf-foot {
    background-color: #112777ea;
    height: 200px;
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    padding: 60px;
    padding-top: 50px;
  }
  .lrf-icon-img {
    width: 100px;
  
    background-color: white;
    border-radius: 100px;
    margin-left: 0px;
    margin-top: 0px;
    
  
  }
    .lrf-foot-section {
      flex: 1;
      margin-left: 120px;
      margin-top:-20px;
    }
   
    .lrf-foot-section h4 {
      margin-bottom: 10px;
    }
   
    .lrf-foot-section h2 {
      margin: 5px 0;
    }
    .lrf-foot-section-1 {
      flex: 1;
      margin-left: 100px;
      margin-top:-20px;
    }
   
    .lrf-foot-section-1 h4 {
      margin-bottom: 10px;
    }
   
    .lrf-foot-section-1 h2 {
      margin: 5px 0;
    }
  
    .lrf-foot-section-2 {
      flex: 1;
      margin-left: 0px;
      margin-top:-20px;
    }
   
    .lrf-foot-section-2 h4 {
      margin-bottom: 10px;
    }
   
    .lrf-foot-section-2 h2 {
      margin: 5px 0;
    }
    
    .lrf-language-dropdown {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  .lrf-top{
      font-size: 22px;
      text-align: left;
      width: 190px;
      letter-spacing: normal;
      color:white;
      FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }  
  .lrf-work{
      font-size: 16px;
      letter-spacing: normal;
      color:white;
      FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lrf-social-icons-container {
      display: flex;
      margin-top: 20px;
      
    }
   
    .lrf-iicon {
      margin-right: 20px; 
      font-size: 40px; 
      color:rgb(240, 240, 247);
    }
}


 


