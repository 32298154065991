.SAnavbar-header {
  height: 100px;
}

* {
  margin: 0;
}

.SAnavbar-linked-text {
  text-decoration: none;
}

.SAnavbar-logo-10 {
  margin-top: 20px;
}

.SAnavbar-clr {
  height: 300px;
}

.SAnavbar-yaa {
  width: 100px;
  color: #054652;
  height: 50px;
  margin-left: 50px;
  margin-top: -20px;
}

.tiger a {
  transition: transform 0.3s ease;
}

.SAnavbar-tiger ul p a {
  color: #054652 COPY;
  font-size: 20px;
  font-weight: 100px;

  transition: transform 0.3s ease;
  font-family: "Times New Roman", Times, serif;
}

.SAnavbar-tiger a:hover {
  color: rgb(7, 7, 7);
  transform: scale(1.1);

  padding-left: 4px;
}

.SAnavbar-tiger {
  margin-bottom: 40px;
}

.SAnavbar-dropdown {
  position: relative;
  display: inline-block;
  align-items: center;
}

.SAnavbar-details {
  display: inline-block;
  align-items: center;
}

.SAnavbar-publish {
  margin-left: 25px;
}

.SAnavbar-dropdown-content {
  display: none;
  height: auto;
  margin-right: 30px;
  position: absolute;
  background-color: white;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: -110px;
}

.SAnavbar-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.SAnavbar-dropdown-content a:hover {
  background-color: #05144c;
  border: 1px solid rgb(201, 189, 189);
  width: 100%;
  color: white;
  margin-left: 0px;
  height: 50px;
}

.SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
  display: block;
}

.SAnavbar-profile-image {
  width: 70px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  margin-top: 1px;
  background-position: center;
  margin-left: 1050px;
  background-image: url("../Imagess/profile.jpg");
}

.SAnavbar-profile-info-container {
  display: flex;
  margin-left: 100px;
  align-items: center;
}

.SAnavbar-profile-details {
  position: absolute;
  top: 70%;
  right: 4%;
  transform: translateX(-50%);
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
}

.SAnavbar-profile-details p {
  margin: 0;
}

.SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
  display: block;
}

.SAnavbar-tiger ul {
  display: flex;
  margin-top: 30px;
}

.SAnavbar-icon {
  width: 60px;
  height: 60px;
  margin-top: 20px;
}

.SAnavbar-icons {
  font-size: 20px;
  color: #054652 COPY;
  background-color: white;
  border: 1px solid rgb(227, 219, 219);
  width: 35px;
  height: 35px;
  border-radius: 100px;
  font-weight: 1000;
  margin-left: -25px;
  margin-top: 10px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #f8f7f2;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: black;
}

header {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  margin-top: 2%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 2rem;
  background-color: rgb(235, 229, 229);
  color: var(--textColor);
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .SAnavbar-nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);

  opacity: 0;
  font-size: 1.8rem;
}

.SAnavbar-nav-btnn {
  font-size: 0px;

  margin-top: 0px;
  visibility: visible;
  opacity: 1;
  color: rgb(243, 238, 238);
}

.SAnavbar-nav-btn-1 {
  background: none;
  border: none;
  color: rgb(15, 15, 15);
  outline: none;
  font-size: 0px;
}

header div,
nav {
  display: flex;
  align-items: center;
}

.SAnavbar-clr {
  background-color: #05144c;
  width: 100%;
  height: 30px;
  position: fixed;
  z-index: 1000;
}

@keyframes eyeMove {
  0%,
  10% {
    background-position: 0px 8px;
  }

  13%,
  40% {
    background-position: -5px 0px;
  }

  43%,
  70% {
    background-position: 5px 0px;
  }

  73%,
  90% {
    background-position: 0px 8px;
  }

  93%,
  100% {
    background-position: 0px 8px;
  }
}

@keyframes blink {
  0%,
  10%,
  12%,
  20%,
  22%,
  40%,
  42%,
  60%,
  62%,
  70%,
  72%,
  90%,
  92%,
  98%,
  100% {
    height: 28px;
  }

  11%,
  21%,
  41%,
  61%,
  71%,
  91%,
  99% {
    height: 9px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
  .SAnavbar-yaa {
    width: 100px;
    color: #054652;
    font-weight: 700;
    margin-left: -20px;
    height: 80px;
  }

  header .SAnavbar-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  .SAnavbar-clr {
    background-color: #05144c;
    width: 100%;
    height: 20px;
    position: fixed;
    z-index: 100;
  }

  .SAnavbar-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    width: 100%;
    color: white;
    margin-left: 0px;
    height: 50px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
    display: block;
  }

  .SAnavbar-profile-image {
    width: 90px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    margin-top: 40px;
    background-position: center;
    margin-left: -150px;
    background-image: url("../Imagess/profile.jpg");
  }

  .SAnavbar-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .SAnavbar-dropdown-content a {
    color: black;
    padding: 5px 10px;
    text-decoration: none;
    display: block;
  }

  .SAnavbar-dropdown-content {
    display: none;
    height: auto;
    margin-left: -70px;
    position: absolute;
    background-color: white;
    min-width: 205px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -76px;
  }

  .SAnavbar-profile-details {
    position: absolute;
    top: 63%;
    right: -5%;
    transform: translateX(-50%);
    background-color: white;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SAnavbar-profile-details p {
    margin: 0;
  }

  .SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
    display: block;
  }

  .SAnavbar-tiger ul {
    display: flex;
    margin-top: 0px;
  }

  .SAnavbar-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: white;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-weight: 100;
    margin-left: 40px;
    margin-top: -170px;
  }

  .SAnavbar-nav-btn-1 {
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
    font-size: 30px;
  }

  .SAnavbar-nav-btnn {
    font-size: 30px;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
    color: rgb(243, 238, 238);
  }
}

@media only screen and (min-width: 360px) and (max-width: 383px) {
  .SAnavbar-yaa {
    width: 100px;
    color: #054652;
    font-weight: 700;
    margin-left: -20px;
    height: 80px;
  }

  header .SAnavbar-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  .SAnavbar-clr {
    background-color: #05144c;
    width: 100%;
    height: 20px;
    position: fixed;
    z-index: 100;
  }

  .SAnavbar-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    width: 100%;
    color: white;
    margin-left: 0px;
    height: 50px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
    display: block;
  }

  .SAnavbar-profile-image {
    width: 90px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    margin-top: 40px;
    background-position: center;
    margin-left: -150px;
    background-image: url("../Imagess/profile.jpg");
  }

  .SAnavbar-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .SAnavbar-dropdown-content a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }

  .SAnavbar-dropdown-content {
    display: none;
    height: auto;
    margin-left: -70px;
    position: absolute;
    background-color: white;
    min-width: 205px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -76px;
  }

  .SAnavbar-profile-details {
    position: absolute;
    top: 63%;
    right: 5%;
    transform: translateX(-50%);
    background-color: white;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SAnavbar-profile-details p {
    margin: 0;
  }

  .SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
    display: block;
  }

  .SAnavbar-tiger ul {
    display: flex;
    margin-top: 0px;
  }

  .SAnavbar-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: white;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-weight: 100;
    margin-left: 40px;
    margin-top: -170px;
  }

  .SAnavbar-nav-btn-1 {
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
    font-size: 30px;
  }

  .SAnavbar-nav-btnn {
    font-size: 30px;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
    color: rgb(243, 238, 238);
  }
}

@media only screen and (min-width: 390px) and (max-width: 411px) {
  .SAnavbar-yaa {
    width: 100px;
    color: #054652;
    font-weight: 700;
    margin-left: -10px;
    height: 80px;
  }

  header .SAnavbar-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  .SAnavbar-clr {
    background-color: #05144c;
    width: 100%;
    height: 20px;
    position: fixed;
    z-index: 100;
  }

  .SAnavbar-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    width: 100%;
    color: white;
    margin-left: 0px;
    height: 50px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
    display: block;
  }

  .SAnavbar-profile-image {
    width: 90px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    margin-top: 40px;
    background-position: center;
    margin-left: -150px;
    background-image: url("../Imagess/profile.jpg");
  }

  .SAnavbar-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .SAnavbar-dropdown-content a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }

  .SAnavbar-dropdown-content {
    display: none;
    height: auto;
    margin-left: -70px;
    position: absolute;
    background-color: white;
    min-width: 205px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -76px;
  }

  .SAnavbar-profile-details {
    position: absolute;
    top: 63%;
    right: 5%;
    transform: translateX(-50%);
    background-color: white;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SAnavbar-profile-details p {
    margin: 0;
  }

  .SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
    display: block;
  }

  .SAnavbar-tiger ul {
    display: flex;
    margin-top: 0px;
  }

  .SAnavbar-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: white;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-weight: 100;
    margin-left: 40px;
    margin-top: -170px;
  }

  .SAnavbar-nav-btn-1 {
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
    font-size: 30px;
  }

  .SAnavbar-nav-btnn {
    font-size: 30px;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
    color: rgb(243, 238, 238);
  }
}

@media only screen and (min-width: 412px) and (max-width: 427px) {
  .SAnavbar-yaa {
    width: 100px;
    color: #054652;
    font-weight: 700;
    margin-left: 0px;
    height: 80px;
  }

  header .SAnavbar-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  .SAnavbar-clr {
    background-color: #05144c;
    width: 100%;
    height: 20px;
    position: fixed;
    z-index: 100;
  }

  .SAnavbar-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    width: 100%;
    color: white;
    margin-left: 0px;
    height: 50px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
    display: block;
  }

  .SAnavbar-profile-image {
    width: 90px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    margin-top: 40px;
    background-position: center;
    margin-left: -150px;
    background-image: url("../Imagess/profile.jpg");
  }

  .SAnavbar-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .SAnavbar-dropdown-content a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }

  .SAnavbar-dropdown-content {
    display: none;
    height: auto;
    margin-left: -70px;
    position: absolute;
    background-color: white;
    min-width: 205px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -76px;
  }

  .SAnavbar-profile-details {
    position: absolute;
    top: 63%;
    right: 10%;
    transform: translateX(-50%);
    background-color: white;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SAnavbar-profile-details p {
    margin: 0;
  }

  .SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
    display: block;
  }

  .SAnavbar-tiger ul {
    display: flex;
    margin-top: 0px;
  }

  .SAnavbar-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: white;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-weight: 100;
    margin-left: 40px;
    margin-top: -170px;
  }

  .SAnavbar-nav-btn-1 {
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
    font-size: 30px;
  }

  .SAnavbar-nav-btnn {
    font-size: 30px;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
    color: rgb(243, 238, 238);
  }
}

@media only screen and (min-width: 428px) and (max-width: 767px) {
  .SAnavbar-yaa {
    width: 100px;
    color: #054652;
    font-weight: 700;
    margin-left: 0px;
    height: 80px;
  }

  header .SAnavbar-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  .SAnavbar-clr {
    background-color: #05144c;
    width: 100%;
    height: 20px;
    position: fixed;
    z-index: 100;
  }

  .SAnavbar-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    width: 100%;
    color: white;
    margin-left: 0px;
    height: 50px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
    display: block;
  }

  .SAnavbar-profile-image {
    width: 90px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    margin-top: 40px;
    background-position: center;
    margin-left: -150px;
    background-image: url("../Imagess/profile.jpg");
  }

  .SAnavbar-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .SAnavbar-dropdown-content a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }

  .SAnavbar-dropdown-content {
    display: none;
    height: auto;
    margin-left: -70px;
    position: absolute;
    background-color: white;
    min-width: 205px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -76px;
  }

  .SAnavbar-profile-details {
    position: absolute;
    top: 63%;
    right: 10%;
    transform: translateX(-50%);
    background-color: white;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SAnavbar-profile-details p {
    margin: 0;
  }

  .SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
    display: block;
  }

  .SAnavbar-tiger ul {
    display: flex;
    margin-top: 0px;
  }

  .SAnavbar-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: white;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-weight: 100;
    margin-left: 40px;
    margin-top: -170px;
  }

  .SAnavbar-nav-btn-1 {
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
    font-size: 30px;
  }

  .SAnavbar-nav-btnn {
    font-size: 30px;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
    color: rgb(243, 238, 238);
  }
}

@media only screen and (min-width: 768px) and (max-width: 799px) {
  .SAnavbar-yaa {
    width: 150px;
    color: #054652;
    font-weight: 700;
    margin-left: 0px;
    height: 80px;
  }

  header .SAnavbar-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  .SAnavbar-clr {
    background-color: #05144c;
    width: 100%;
    height: 20px;
    position: fixed;
    z-index: 100;
  }

  .SAnavbar-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    width: 100%;
    color: white;
    margin-left: 0px;
    height: 50px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
    display: block;
  }

  .SAnavbar-profile-image {
    width: 90px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    margin-top: 40px;
    background-position: center;
    margin-left: -150px;
    background-image: url("../Imagess/profile.jpg");
  }

  .SAnavbar-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .SAnavbar-dropdown-content a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }

  .SAnavbar-dropdown-content {
    display: none;
    height: auto;
    margin-left: -60px;
    position: absolute;
    background-color: white;
    min-width: 205px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -76px;
  }

  .SAnavbar-profile-details {
    position: absolute;
    top: 63%;
    right: 34%;
    transform: translateX(-50%);
    background-color: white;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SAnavbar-profile-details p {
    margin: 0;
  }

  .SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
    display: block;
  }

  .SAnavbar-tiger ul {
    display: flex;
    margin-top: 0px;
  }

  .SAnavbar-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: white;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-weight: 100;
    margin-left: 40px;
    margin-top: -170px;
  }

  .SAnavbar-nav-btn-1 {
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
    font-size: 30px;
  }

  .SAnavbar-nav-btnn {
    font-size: 30px;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
    color: rgb(243, 238, 238);
  }
}

@media only screen and (min-width: 800px) and (max-width: 819px) {
  .SAnavbar-yaa {
    width: 150px;
    color: #054652;
    font-weight: 700;
    margin-left: 0px;
    height: 80px;
  }

  header .SAnavbar-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  .SAnavbar-clr {
    background-color: #05144c;
    width: 100%;
    height: 20px;
    position: fixed;
    z-index: 100;
  }

  .SAnavbar-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    width: 100%;
    color: white;
    margin-left: 0px;
    height: 50px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
    display: block;
  }

  .SAnavbar-profile-image {
    width: 90px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    margin-top: 40px;
    background-position: center;
    margin-left: -150px;
    background-image: url("../Imagess/profile.jpg");
  }

  .SAnavbar-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .SAnavbar-dropdown-content a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }

  .SAnavbar-dropdown-content {
    display: none;
    height: auto;
    margin-left: -60px;
    position: absolute;
    background-color: white;
    min-width: 205px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -76px;
  }

  .SAnavbar-profile-details {
    position: absolute;
    top: 63%;
    right: 34%;
    transform: translateX(-50%);
    background-color: white;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SAnavbar-profile-details p {
    margin: 0;
  }

  .SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
    display: block;
  }

  .SAnavbar-tiger ul {
    display: flex;
    margin-top: 0px;
  }

  .SAnavbar-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: white;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-weight: 100;
    margin-left: 40px;
    margin-top: -170px;
  }

  .SAnavbar-nav-btn-1 {
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
    font-size: 30px;
  }

  .SAnavbar-nav-btnn {
    font-size: 30px;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
    color: rgb(243, 238, 238);
  }
}

@media only screen and (min-width: 820px) and (max-width: 852px) {
  .SAnavbar-yaa {
    width: 150px;
    color: #054652;
    font-weight: 700;
    margin-left: 0px;
    height: 80px;
  }

  header .SAnavbar-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  .SAnavbar-clr {
    background-color: #05144c;
    width: 100%;
    height: 20px;
    position: fixed;
    z-index: 100;
  }

  .SAnavbar-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    width: 100%;
    color: white;
    margin-left: 0px;
    height: 50px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
    display: block;
  }

  .SAnavbar-profile-image {
    width: 90px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    margin-top: 40px;
    background-position: center;
    margin-left: -150px;
    background-image: url("../Imagess/profile.jpg");
  }

  .SAnavbar-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .SAnavbar-dropdown-content a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }

  .SAnavbar-dropdown-content {
    display: none;
    height: auto;
    margin-left: -60px;
    position: absolute;
    background-color: white;
    min-width: 205px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -76px;
  }

  .SAnavbar-profile-details {
    position: absolute;
    top: 63%;
    right: 34%;
    transform: translateX(-50%);
    background-color: white;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SAnavbar-profile-details p {
    margin: 0;
  }

  .SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
    display: block;
  }

  .SAnavbar-tiger ul {
    display: flex;
    margin-top: 0px;
  }

  .SAnavbar-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: white;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-weight: 100;
    margin-left: 40px;
    margin-top: -170px;
  }

  .SAnavbar-nav-btn-1 {
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
    font-size: 30px;
  }

  .SAnavbar-nav-btnn {
    font-size: 30px;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
    color: rgb(243, 238, 238);
  }
}

@media only screen and (min-width: 884px) and (max-width: 1279px) {
  .SAnavbar-yaa {
    width: 150px;
    color: #054652;
    font-weight: 700;
    margin-left: 0px;
    height: 80px;
  }

  header .SAnavbar-nav-btn {
    visibility: visible;
    opacity: 1;
    color: black;
  }

  .SAnavbar-clr {
    background-color: #05144c;
    width: 100%;
    height: 20px;
    position: fixed;
    z-index: 100;
  }

  .SAnavbar-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    width: 100%;
    color: white;
    margin-left: 0px;
    height: 50px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    height: 180px;
  }

  .SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
    display: block;
  }

  .SAnavbar-profile-image {
    width: 90px;
    height: 80px;
    border-radius: 50%;
    background-size: cover;
    margin-top: 40px;
    background-position: center;
    margin-left: -150px;
    background-image: url("../Imagess/profile.jpg");
  }

  .SAnavbar-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .SAnavbar-dropdown-content a {
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
  }

  .SAnavbar-dropdown-content {
    display: none;
    height: auto;
    margin-left: -60px;
    position: absolute;
    background-color: white;
    min-width: 205px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: -76px;
  }

  .SAnavbar-profile-details {
    position: absolute;
    top: 63%;
    right: 34%;
    transform: translateX(-50%);
    background-color: white;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SAnavbar-profile-details p {
    margin: 0;
  }

  .SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
    display: block;
  }

  .SAnavbar-tiger ul {
    display: flex;
    margin-top: 0px;
  }

  .SAnavbar-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: white;
    border: 1px solid rgb(227, 219, 219);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-weight: 100;
    margin-left: 40px;
    margin-top: -170px;
  }

  .SAnavbar-nav-btn-1 {
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
    font-size: 30px;
  }

  .SAnavbar-nav-btnn {
    font-size: 30px;
    margin-top: 0px;
    visibility: visible;
    opacity: 1;
    color: rgb(243, 238, 238);
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
  .SAnavbar-header {
    height: 100px;
  }

  * {
    margin: 0;
  }

  .SAnavbar-linked-text {
    text-decoration: none;
  }

  .SAnavbar-logo-10 {
    margin-top: 20px;
  }

  .SAnavbar-clr {
    height: 300px;
  }

  .SAnavbar-yaa {
    width: 150px;
    color: #054652;
    font-weight: 800;
    margin-top: 0px;
    height: 80px;
  }

  .tiger a {
    transition: transform 0.3s ease;
  }

  .SAnavbar-tiger ul p a {
    color: #054652 COPY;
    font-size: 20px;
    font-weight: 100px;

    transition: transform 0.3s ease;
    font-family: "Times New Roman", Times, serif;
  }

  .SAnavbar-tiger a:hover {
    color: rgb(7, 7, 7);
    transform: scale(1.1);

    padding-left: 4px;
  }

  .SAnavbar-tiger {
    margin-bottom: 40px;
  }

  .SAnavbar-dropdown {
    position: relative;
    display: inline-block;
    align-items: center;
  }

  .SAnavbar-details {
    display: inline-block;
    align-items: center;
  }

  .SAnavbar-publish {
    margin-left: 25px;
  }

  .SAnavbar-dropdown-content {
    display: none;
    height: auto;
    margin-right: 30px;
    position: absolute;
    background-color: white;
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-left: -100px;
  }

  .SAnavbar-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .SAnavbar-dropdown-content a:hover {
    background-color: #05144c;
    border: 1px solid rgb(201, 189, 189);
    width: 100%;
    color: white;
    margin-left: 0px;
    height: 50px;
  }

  .SAnavbar-dropdown:hover .SAnavbar-dropdown-content {
    display: block;
  }

  .SAnavbar-profile-image {
    width: 70px;
    height: 60px;
    border-radius: 50%;
    background-size: cover;
    margin-top: 1px;
    background-position: center;
    margin-left: 760px;
    background-image: url("../Imagess/profile.jpg");
  }

  .SAnavbar-profile-info-container {
    display: flex;
    margin-left: 100px;
    align-items: center;
  }

  .SAnavbar-profile-details {
    position: absolute;
    top: 70%;
    right: 5%;
    transform: translateX(-50%);
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    font-size: 10px;
    align-items: center;
    justify-content: center;
  }

  .SAnavbar-profile-details p {
    margin: 0;
  }

  .SAnavbar-profile-info-container:hover .SAnavbar-profile-details {
    display: block;
  }

  .SAnavbar-tiger ul {
    display: flex;
    margin-top: 30px;
  }

  .SAnavbar-icon {
    width: 60px;
    height: 60px;
    margin-top: 20px;
  }

  .SAnavbar-icons {
    font-size: 20px;
    color: #054652 COPY;
    background-color: white;
    border: 1px solid rgb(227, 219, 219);
    width: 35px;
    height: 35px;
    border-radius: 100px;
    font-weight: 1000;
    margin-left: -25px;
    margin-top: 10px;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Titillium Web", sans-serif;
  }

  :root {
    --mainColor: #f8f7f2;
    --mainColorLight: #5767aa;
    --secondaryColor: #db2b39;
    --textColor: black;
  }

  header {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    margin-top: 2%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 2rem;
    background-color: rgb(235, 229, 229);
    color: var(--textColor);
  }

  nav a {
    margin: 0 1rem;
    color: var(--textColor);
    text-decoration: none;
  }

  nav a:hover {
    color: var(--secondaryColor);
  }

  header .SAnavbar-nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);

    opacity: 0;
    font-size: 1.8rem;
  }

  .SAnavbar-nav-btnn {
    font-size: 0px;

    margin-top: 0px;
    visibility: visible;
    opacity: 1;
    color: rgb(243, 238, 238);
  }

  .SAnavbar-nav-btn-1 {
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
    font-size: 0px;
  }

  header div,
  nav {
    display: flex;
    align-items: center;
  }

  .SAnavbar-clr {
    background-color: #05144c;
    width: 100%;
    height: 30px;
    position: fixed;
    z-index: 1000;
  }

  @keyframes eyeMove {
    0%,
    10% {
      background-position: 0px 8px;
    }

    13%,
    40% {
      background-position: -5px 0px;
    }

    43%,
    70% {
      background-position: 5px 0px;
    }

    73%,
    90% {
      background-position: 0px 8px;
    }

    93%,
    100% {
      background-position: 0px 8px;
    }
  }

  @keyframes blink {
    0%,
    10%,
    12%,
    20%,
    22%,
    40%,
    42%,
    60%,
    62%,
    70%,
    72%,
    90%,
    92%,
    98%,
    100% {
      height: 28px;
    }

    11%,
    21%,
    41%,
    61%,
    71%,
    91%,
    99% {
      height: 9px;
    }
  }
}
