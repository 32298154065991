
*{
  margin:0;
}
.navbar-linked-text{
  text-decoration: none;
  }
  .navbar-logo-10{
      margin-bottom: -20px;
    }
.navbar-yaa{
  /* border:1px solid rgb(246, 12, 12); */

width:100px;
color:#ebf0f0;
font-weight: 800;
margin-top: -20px;
height:50px;

margin-left: 50px;


}
/* Navbar.css */
.navbar-tiger a {



  transition: transform 0.3s ease; /* Add transition property */
}

  .navbar-tiger ul p a{
  color:#054652
  COPY
  ;
  font-size: 20px;
  font-weight: 100px;

  transition: transform 0.3s ease; 
  font-family:'Times New Roman', Times, serif;
}


.navbar-tiger a:hover {
  color: rgb(7, 7, 7);
  transform: scale(1.1);
 
  padding-left:4px;

}


.navbar-tiger{
 
  margin-top: 0px;
  margin-left: 300px;
 
}

.search-main{
  width: 120px;
  margin-left: 50px;

}
.navbar-publish-main a:hover {
  color: black;
  transform: scale(1.1);
 
  padding-left:0px;

}
.navbar-publish-main{
  width: 250px;
  margin-left: 40px;
}

.navbar-dropdown {
  width: 250px;
  position: relative;
  display: inline-block;
  margin-left: -60px;
 
}

.navbar-publish{
  margin-left: 25px;
 
}

.navbar-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 100px;
  height: 95px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: -40px;
  margin-top: 5px;
}

.navbar-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navbar-dropdown-content a:hover {
  background-color: #05144c;
  border:1px solid rgb(201, 189, 189);
  color:white;
  width:90%;
  margin-left:8px;
  height:40px;

}

.navbar-dropdown:hover .navbar-dropdown-content {
  display: block;
}

.navbar-tiger ul{
  display:flex;
  margin-top: 30px;
  margin-left:450px;


}

.navbar-icon{
  font-size: 30px;
  color:#054652 COPY;
}
.navbar-icons{
  font-size: 20px;
  color:#054652 COPY;
  background-color: white;
  border:1px solid rgb(227, 219, 219);
  width:30px;
  height:30px;
  border-radius: 100px;
  font-weight: 1000;
  margin-left:0px;
  margin-bottom: 0px;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
  }
 
  :root {
  --mainColor: #f8f7f2;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: black;
  }
 
  header {
    position:fixed;
    z-index: 1000; /* Ensure the navbar stays on top of other elements */
    top: 0;
    width:100%;
margin-top: 1%;
 
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 2rem;
  background-color:white;
  color: var(--textColor);
  }
 
  nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  }
 
 
 
 
  nav a:hover {
  color: var(--secondaryColor);
  }
 
  header .navbar-nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
 
  opacity: 0;
  font-size: 1.8rem;
  }
 
  header div,
  nav {
  display: flex;
  align-items: center;
  }
  .navbar-cnavbar{
    background-color: #05144c;
    width:100%;
    height:40px;
    position:fixed;
    z-index: 1000;
  }
 
  @keyframes eyeMove {
  0%, 10% {
    background-position: 0px 8px;
  }
  13%, 40% {
    background-position: -5px 0px;
  }
  43%, 70% {
    background-position: 5px 0px;
  }
  73%, 90% {
    background-position: 0px 8px;
  }
  93%, 100% {
    background-position: 0px 8px;
  }
  }
 
  @keyframes blink {
  0%, 10%, 12%, 20%, 22%, 40%, 42%, 60%, 62%, 70%, 72%, 90%, 92%, 98%, 100% {
    height: 28px;
  }
  11%, 21%, 41%, 61%, 71%, 91%, 99% {
    height: 9px;
  }
  }
  @media only screen and (min-width: 240px) and (max-width: 319px) {  
    header .responsive_nav {
      transform: translateY(100vh);
   height: 190px;
    }
    header {
      position:fixed;
      z-index: 1000; /* Ensure the navbar stays on top of other elements */
      top: 0;
      width:100%;
  margin-top: 1%;
   
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 2rem;
    background-color:white;
    color: var(--textColor);
    }
    .navbar-yaa{
  
      width:200px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 20px;
      height:200px;
      margin-left: -70px;
      }
    .navbar-icon{
      margin-right: 200px;
      margin-left: 20px;
      font-size: 30px;
      margin-top: -250px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
     
      margin-top: -250px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-top: 0px;
    margin-left: -80px;
   
  }
  .navbar-publish-main a:hover {
    color: rgb(255, 254, 254);
    transform: scale(1.1);
   
    padding-left:0px;
  
  }
  .navbar-publish{
    margin-left: 10px;
    color: white;
  }
  .navbar-dropdown{
    margin-right: 150px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 0px;
    margin-top: -142px;
    height: 117px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:rgb(243, 238, 238);
  }
   .navbar-nav-btn-1{
    
   
      background: none;
      border: none;
      color: rgb(15, 15, 15);
      outline: none;
    
    
   }
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
  
  .navbar-yaa{
    margin-top:20px;
  
    width:200px;
    height:200px;
  }

  
  nav .nav-close-btn {
    position: absolute;
    top: 0rem;
    right: 2rem;
    
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-cnavbar{
 
    width:100%;
    height:20px;
    position:fixed;
    margin-top: -90px;
    z-index: 1000;
  }
  
  
  }
  @media only screen and (min-width: 320px) and (max-width: 359px) {  
    header .responsive_nav {
      transform: translateY(100vh);
   height: 190px;
    }
    .navbar-yaa{
      width:100px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 5px;
      height:50px;
      margin-left: -10px;
      }
     
    
    .navbar-icon{
      margin-right: 200px;
      margin-left: -30px;
      font-size: 30px;
      margin-top: -250px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
     
      margin-top: -250px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-top: 0px;
    margin-left: -120px;
   
  }
  .navbar-publish-main a:hover {
    color: rgb(255, 254, 254);
    transform: scale(1.1);
   
    padding-left:0px;
  
  }
  .navbar-publish{
    margin-left: 0px;
    color: white;
  }
  .navbar-dropdown{
    margin-right: 0px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: -60px;
    margin-top: -142px;
    height: 117px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:rgb(243, 238, 238);
  }
   .navbar-nav-btn-1{
    
   
      background: none;
      border: none;
      color: rgb(15, 15, 15);
      outline: none;
    
    
   }
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
  

  
  nav .nav-close-btn {
    position: absolute;
    top: 0rem;
    right: 2rem;
    
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-cnavbar{
 
    width:100%;
    height:20px;
    position:fixed;
    margin-top: -0px;
    z-index: 1000;
  }
  
  
  }
 
  @media only screen and (min-width: 360px) and (max-width: 374px) {  
    header .responsive_nav {
      transform: translateY(100vh);
   height: 190px;
    }
    .navbar-yaa{
      /* border:1px solid rgb(246, 12, 12); */

      width:100px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 35px;
      height:50px;
      margin-left: -10px;
      }
       
  .navbar-yaa{
    margin-top:20px;
    width:100px;
    height:70px;
  }
    .navbar-icon{
      margin-right: 200px;
      margin-left: -25px;
      font-size: 30px;
      margin-top: -250px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
     
      margin-top: -250px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-top: 0px;
    margin-left: -130px;
   
  }
  .navbar-publish-main a:hover {
    color: rgb(255, 254, 254);
    transform: scale(1.1);
   
    padding-left:0px;
  
  }
  .navbar-publish{
    margin-left: 10px;
    color: white;
  }
  .navbar-dropdown{
    margin-right: 0px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: -60px;
    margin-top: -142px;
    height: 117px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:rgb(243, 238, 238);
  }
   .navbar-nav-btn-1{
    
   
      background: none;
      border: none;
      color: rgb(15, 15, 15);
      outline: none;
    
    
   }
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
 

  
  nav .nav-close-btn {
    position: absolute;
    top: 0rem;
    right: 2rem;
    
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-cnavbar{
 
    width:100%;
    height:20px;
    position:fixed;
    margin-top: -0px;
    z-index: 1000;
  }
  
  
  }
  @media only screen and (min-width: 375px) and (max-width: 389px) {  
    header .responsive_nav {
      transform: translateY(100vh);
   height: 190px;
    }
    .navbar-yaa{
      /* border:1px solid rgb(246, 12, 12); */
      width:100px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 0px;
      height:50px;
      margin-left: -10px;
      }
      
     
    .navbar-icon{
      margin-right: 200px;
      margin-left: -30px;
      font-size: 30px;
      margin-top: -250px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
     
      margin-top: -250px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-top: 0px;
    margin-left: -140px;
   
  }
  .navbar-publish-main a:hover {
    color: rgb(255, 254, 254);
    transform: scale(1.1);
   
    padding-left:0px;
  
  }
  .navbar-publish{
    margin-left: 10px;
    color: white;
  }
  .navbar-dropdown{
    margin-right: -10px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: -50px;
    margin-top: -142px;
    height: 117px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:rgb(243, 238, 238);
  }
   .navbar-nav-btn-1{
    
   
      background: none;
      border: none;
      color: rgb(15, 15, 15);
      outline: none;
    
    
   }
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
  
 

  
  nav .nav-close-btn {
    position: absolute;
    top: 0rem;
    right: 2rem;
    
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-cnavbar{
 
    width:100%;
    height:20px;
    position:fixed;
    margin-top: -0px;
    z-index: 1000;
  }
  
  
  }

  @media only screen and (min-width: 390px) and (max-width: 411px) {  
    header .responsive_nav {
      transform: translateY(100vh);
   height: 190px;
    }
    .navbar-yaa{
      /* border:1px solid rgb(246, 12, 12); */

      width:100px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 0px;
      height:50px;
      margin-left: -10px;
      }
    .navbar-icon{
      margin-right: 200px;
      margin-left: -30px;
      font-size: 30px;
      margin-top: -250px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
     
      margin-top: -250px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-top: 0px;
    margin-left: -140px;
   
  }
  .navbar-publish-main a:hover {
    color: rgb(255, 254, 254);
    transform: scale(1.1);
   
    padding-left:0px;
  
  }
  .navbar-publish{
    margin-left: 10px;
    color: white;
  }
  .navbar-dropdown{
    margin-right: -10px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: -50px;
    margin-top: -142px;
    height: 117px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:rgb(243, 238, 238);
  }
   .navbar-nav-btn-1{
    
   
      background: none;
      border: none;
      color: rgb(15, 15, 15);
      outline: none;
    
    
   }
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
/*   
  .navbar-yaa{
    margin-top:20px;
    width:200px;
    height:200px;
  } */

  
  nav .nav-close-btn {
    position: absolute;
    top: 0rem;
    right: 2rem;
    
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-cnavbar{
 
    width:100%;
    height:20px;
    position:fixed;
    margin-top: -0px;
    z-index: 1000;
  }
  
  
  }
  @media only screen and (min-width: 412px) and (max-width: 413px) {  
    header .responsive_nav {
      transform: translateY(100vh);
   height: 190px;
    }
    .navbar-yaa{
      /* border:1px solid rgb(246, 12, 12); */

      width:100px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 0px;
      height:50px;
      margin-left: -10px;
      }
    .navbar-icon{
      margin-right: 200px;
      margin-left: -30px;
      font-size: 30px;
      margin-top: -250px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
     
      margin-top: -250px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-top: 0px;
    margin-left: -140px;
   
  }
  .navbar-publish-main a:hover {
    color: rgb(255, 254, 254);
    transform: scale(1.1);
   
    padding-left:0px;
  
  }
  .navbar-publish{
    margin-left: 10px;
    color: white;
  }
  .navbar-dropdown{
    margin-right: 0px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: -50px;
    margin-top: -142px;
    height: 117px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:rgb(243, 238, 238);
  }
   .navbar-nav-btn-1{
    
   
      background: none;
      border: none;
      color: rgb(15, 15, 15);
      outline: none;
    
    
   }
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
/*   
  .navbar-yaa{
    margin-top:20px;
    width:200px;
    height:200px;
  } */

  
  nav .nav-close-btn {
    position: absolute;
    top: 0rem;
    right: 2rem;
    
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-cnavbar{
 
    width:100%;
    height:20px;
    position:fixed;
    margin-top: -0px;
    z-index: 1000;
  }
  
  
  }
 
  @media only screen and (min-width: 414px) and (max-width: 427px) {  
    header .responsive_nav {
      transform: translateY(100vh);
   height: 190px;
    }
    .navbar-yaa{
      /* border:1px solid rgb(246, 12, 12); */

      width:100px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 0px;
      height:50px;
      margin-left: -10px;
      }
     
    .navbar-icon{
      margin-right: 200px;
      margin-left: -40px;
      font-size: 30px;
      margin-top: -250px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
     
      margin-top: -250px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-top: 0px;
    margin-left: -140px;
   
  }
  .navbar-publish-main a:hover {
    color: rgb(255, 254, 254);
    transform: scale(1.1);
   
    padding-left:0px;
  
  }
  .navbar-publish{
    margin-left: 10px;
    color: white;
  }
  .navbar-dropdown{
    margin-right: -10px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: -70px;
    margin-top: -142px;
    height: 117px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:rgb(243, 238, 238);
  }
   .navbar-nav-btn-1{
    
   
      background: none;
      border: none;
      color: rgb(15, 15, 15);
      outline: none;
    
    
   }
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
  


  
  nav .nav-close-btn {
    position: absolute;
    top: 0rem;
    right: 2rem;
    
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-cnavbar{
 
    width:100%;
    height:20px;
    position:fixed;
    margin-top: -0px;
    z-index: 1000;
  }
  
  
  }
  @media only screen and (min-width: 428px) and (max-width: 767px) {  
    header .responsive_nav {
      transform: translateY(100vh);
   height: 190px;
    }
    .navbar-yaa{
      /* border:1px solid rgb(246, 12, 12); */

      width:100px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 0px;
      height:50px;
      margin-left: -10px;
      }
    .navbar-icon{
      margin-right: 200px;
      margin-left: 20px;
      font-size: 30px;
      margin-top: -250px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
     
      margin-top: -250px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-top: 0px;
    margin-left: -80px;
   
  }
  .navbar-publish-main a:hover {
    color: rgb(255, 254, 254);
    transform: scale(1.1);
   
    padding-left:0px;
  
  }
  .navbar-publish{
    margin-left: 10px;
    color: white;
  }
  .navbar-dropdown{
    margin-right: 100px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 0px;
    margin-top: -142px;
    height: 117px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:rgb(243, 238, 238);
  }
   .navbar-nav-btn-1{
    
   
      background: none;
      border: none;
      color: rgb(15, 15, 15);
      outline: none;
    
    
   }
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
  
  

  
  nav .nav-close-btn {
    position: absolute;
    top: 0rem;
    right: 2rem;
    
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-cnavbar{
 
    width:100%;
    height:20px;
    position:fixed;
    margin-top: -0px;
    z-index: 1000;
  }
  
  
  }

  @media only screen and (min-width: 768px) and (max-width: 799px) {  



    .navbar-yaa{
      /* border:1px solid rgb(246, 12, 12); */

      width:100px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 0px;
      height:50px;
      margin-left: -10px;
      }
    .navbar-icon{
      margin-right: 200px;
      margin-left: 40px;
      font-size: 40px;
      margin-top: -200px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
      width:35px;
      height:35px;
      margin-top: -190px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-bottom: -30px;
    margin-left: -80px;
    
  }
  
  
    .navbar-publish-main a:hover {
      color: white;
      transform: scale(1.1);
     
      padding-left:0px;
    
    }
  
  .navbar-publish{
    margin-left: 25px;
    color: white;
    margin-top: 80px;
    
  }
  .navbar-publish-1{
    font-size: 30px;
  }
  .navbar-dropdown{
    margin-right: 150px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 10px;
    ;
    margin-top: -105px;
    height: 120px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:white;
  font-size: 30px;
  }
   
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
  .navbar-tiger{
    background-color: #05144c;
    margin-top: 0px;
    margin-left: 350px;
   
  }

  
  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-nav-btn-1{
    
   
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
  font-size: 30px;
  
  }
  
  }
  @media only screen and (min-width: 800px) and (max-width: 819px) {  



    .navbar-yaa{
  
      width:100px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 0px;
      height:50px;
      margin-left: -10px;
      }
    .navbar-icon{
      margin-right: 200px;
      margin-left: 40px;
      font-size: 40px;
      margin-top: -200px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
      width:35px;
      height:35px;
      margin-top: -190px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-bottom: -30px;
    margin-left: -80px;
    
  }
  
  
    .navbar-publish-main a:hover {
      color: white;
      transform: scale(1.1);
     
      padding-left:0px;
    
    }
  
  .navbar-publish{
    margin-left: 25px;
    color: white;
    margin-top: 80px;
    
  }
  .navbar-publish-1{
    font-size: 30px;
  }
  .navbar-dropdown{
    margin-right: 150px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 10px;
    ;
    margin-top: -105px;
    height: 120px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:white;
  font-size: 30px;
  }
   
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
  .navbar-tiger{
    background-color: #05144c;
    margin-top: 0px;
    margin-left: 350px;
   
  }
  
  
  
  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-nav-btn-1{
    
   
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
  font-size: 30px;
  
  }
  
  }

  @media only screen and (min-width: 820px) and (max-width: 852px) {  



    .navbar-yaa{
  
      width:100px;
      color:#ebf0f0;
      font-weight: 800;
      margin-bottom: 0px;
      height:50px;
      margin-left: -10px;
      }
    .navbar-icon{
      margin-right: 200px;
      margin-left: 40px;
      font-size: 40px;
      margin-top: -200px;
      color: white;
    }
    .navbar-icons{
      margin-left: -195px;
      width:35px;
      height:35px;
      margin-top: -190px;
    }
  
  
  .navbar-publish-main{
    width: 200px;
    margin-bottom: -30px;
    margin-left: -80px;
    
  }
  
  
    .navbar-publish-main a:hover {
      color: white;
      transform: scale(1.1);
     
      padding-left:0px;
    
    }
  
  .navbar-publish{
    margin-left: 25px;
    color: white;
    margin-top: 80px;
    
  }
  .navbar-publish-1{
    font-size: 30px;
  }
  .navbar-dropdown{
    margin-right: 150px;
  }
  .navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: 10px;
    ;
    margin-top: -105px;
    height: 120px;
  }
  .navbar-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    color:white;
    width:90%;
    margin-left:8px;
    height:50px;
  
  }
  header .navbar-nav-btn {
    visibility: visible;
    opacity: 1;
  color:white;
  font-size: 30px;
  }
   
  
  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #05144c ;
    box-shadow: 2px 2px 2px 2px gray;
    transition: 1s;
  }
  .navbar-text{
    margin-left: 20px;;
  }
  .navbar-tiger{
    background-color: #05144c;
    margin-top: 0px;
    margin-left: 350px;
   
  }
  
  
  
  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .navbar-tiger ul{
    display: block;
  
    font-size:50px;
    margin-right: 500px;
    
  }
  
  nav a {
    font-size: 1.5rem;
  }
  
  .navbar-nav-btn-1{
    
   
    background: none;
    border: none;
    color: rgb(15, 15, 15);
    outline: none;
  font-size: 30px;
  
  }
  
  }
@media only screen and (min-width: 884px) and (max-width: 1024px) {  



  .navbar-yaa{

    width:100px;
    color:#ebf0f0;
    font-weight: 800;
    margin-bottom: 0px;
    height:50px;
    margin-left: -10px;
    }
  .navbar-icon{
    margin-right: 200px;
    margin-left: 40px;
    font-size: 40px;
    margin-top: -200px;
    color: white;
  }
  .navbar-icons{
    margin-left: -195px;
    width:35px;
    height:35px;
    margin-top: -190px;
  }


.navbar-publish-main{
  width: 200px;
  margin-bottom: -30px;
  margin-left: -80px;
  
}


  .navbar-publish-main a:hover {
    color: white;
    transform: scale(1.1);
   
    padding-left:0px;
  
  }

.navbar-publish{
  margin-left: 25px;
  color: white;
  margin-top: 80px;
  
}
.navbar-publish-1{
  font-size: 30px;
}
.navbar-dropdown{
  margin-right: 150px;
}
.navbar-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: 10px;
  ;
  margin-top: -105px;
  height: 120px;
}
.navbar-dropdown-content a:hover {
  background-color: #05144c;
  border:1px solid rgb(201, 189, 189);
  color:white;
  width:90%;
  margin-left:8px;
  height:50px;

}
header .navbar-nav-btn {
  visibility: visible;
  opacity: 1;
color:white;
font-size: 30px;
}
 

header nav {
  position: fixed;
  top: -100vh;
  left: 0;
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #05144c ;
  box-shadow: 2px 2px 2px 2px gray;
  transition: 1s;
}
.navbar-text{
  margin-left: 20px;;
}
.navbar-tiger{
  background-color: #05144c;
  margin-top: 0px;
  margin-left: 350px;
 
}



nav .nav-close-btn {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
.navbar-tiger ul{
  display: block;

  font-size:50px;
  margin-right: 500px;
  
}

nav a {
  font-size: 1.5rem;
}

.navbar-nav-btn-1{
  
 
  background: none;
  border: none;
  color: rgb(15, 15, 15);
  outline: none;
font-size: 30px;

}

}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {  



 
*{
  margin:0;
}
.navbar-linked-text{
  text-decoration: none;
  }
  .navbar-logo-10{
      margin-bottom: -20px;
    }
.navbar-yaa{
  /* border:1px solid rgb(246, 12, 12); */

  width:100px;
  color:#ebf0f0;
  font-weight: 800;
  margin-top: -20px;
  height:50px;
  
  margin-left: 50px;

}
/* Navbar.css */
.navbar-tiger a {



  transition: transform 0.3s ease; /* Add transition property */
}

  .navbar-tiger ul p a{
  color:#054652
  COPY
  ;
  font-size: 20px;
  font-weight: 100px;

  transition: transform 0.3s ease; 
  font-family:'Times New Roman', Times, serif;
}


.navbar-tiger a:hover {
  color: rgb(7, 7, 7);
  transform: scale(1.1);
 
  padding-left:4px;

}


.navbar-tiger{
 
  margin-top: 0px;
  margin-left: 200px;
 
}

.search-main{
  width: 120px;
  margin-left: 50px;

}
.navbar-publish-main a:hover {
  color: black;
  transform: scale(1.1);
 
  padding-left:0px;

}
.navbar-publish-main{
  width: 250px;
  margin-left: -30px;
}

.navbar-dropdown {
  width: 250px;
  position: relative;
  display: inline-block;
  margin-left: -60px;
 
}

.navbar-publish{
  margin-left: 25px;
 
}

.navbar-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 100px;
  height: 95px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-left: -40px;
  margin-top: 5px;
}

.navbar-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navbar-dropdown-content a:hover {
  background-color: #05144c;
  border:1px solid rgb(201, 189, 189);
  color:white;
  width:90%;
  margin-left:8px;
  height:40px;

}

.navbar-dropdown:hover .navbar-dropdown-content {
  display: block;
}

.navbar-tiger ul{
  display:flex;
  margin-top: 30px;
  margin-left:400px;


}

.navbar-icon{
  font-size: 30px;
  color:#054652 COPY;
}
.navbar-icons{
  font-size: 20px;
  color:#054652 COPY;
  background-color: white;
  border:1px solid rgb(227, 219, 219);
  width:30px;
  height:30px;
  border-radius: 100px;
  font-weight: 1000;
  margin-left:0px;
  margin-bottom: 0px;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
  }
 
  :root {
  --mainColor: #f8f7f2;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: black;
  }
 
  header {
    position:fixed;
    z-index: 1000; /* Ensure the navbar stays on top of other elements */
    top: 0;
    width:100%;
margin-top: 1%;
 
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 2rem;
  background-color:white;
  color: var(--textColor);
  }
 
  nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  }
 
 
 
 
  nav a:hover {
  color: var(--secondaryColor);
  }
 
  header .navbar-nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
 
  opacity: 0;
  font-size: 1.8rem;
  }
 
  header div,
  nav {
  display: flex;
  align-items: center;
  }
  .navbar-cnavbar{
    background-color: #05144c;
    width:100%;
    height:40px;
    position:fixed;
    z-index: 1000;
  }
 
  @keyframes eyeMove {
  0%, 10% {
    background-position: 0px 8px;
  }
  13%, 40% {
    background-position: -5px 0px;
  }
  43%, 70% {
    background-position: 5px 0px;
  }
  73%, 90% {
    background-position: 0px 8px;
  }
  93%, 100% {
    background-position: 0px 8px;
  }
  }
 
  @keyframes blink {
  0%, 10%, 12%, 20%, 22%, 40%, 42%, 60%, 62%, 70%, 72%, 90%, 92%, 98%, 100% {
    height: 28px;
  }
  11%, 21%, 41%, 61%, 71%, 91%, 99% {
    height: 9px;
  }
  }

}



