.licard {
 
  border: 1px solid #ddd;
 
  border-radius: 8px;
 
  padding: 16px;
 
  margin: 16px;
 
  color: #05144c;
 
  width: 65vw;
 
  height: 31vh;
 
margin-left: 17%;
 
  margin-top: 150px;
 
  display: flex;
 
  flex-direction: column;
 
  align-items: center;
 
}
 
.SA-info-ride-card {
  /* border: 1px solid black; */
  margin-top: -10px;
}
 
 
.licard-details {
 
 
  width: 100%;
 
  /* margin-top: 200px; */
 
  display: flex;
 
  flex-direction: column;
 
  align-items: center;
 
}
 
 
 
 
.licard-location {
 
 
  width: 100%;
 
  display: flex;
 
  flex-direction: column;
 
  align-items: center;
 
  margin-bottom: 16px;
 
}
 
 
 
 
.licard-ride-info {
 
 
  width: 100%;
 
}
 
 
 
 
.licard-dest {
 
 
  display: flex;
 
  justify-content: space-between;
 
  width: 70%;
 
}
 
 
 
 
.licard-ride {
 
 
  margin-right: 0px;
  font-size: 20px;
 
}
.licard-dest-ride{
  /* border: 1px solid rgb(247, 10, 10); */
margin-top: 0px;
  width: 100%;
  height: 30px;
  font-size: 20px;
}
.licard-arrow {
  font-size: 24px;
}
.licard-time-date {
  /* border: 1px solid rgb(247, 10, 10); */
 
  font-size: 20px;
margin-top: -17px;
  display: flex;
 
  align-items: center;
 
  justify-content: space-between;
 
  width: 50%;
  height: auto;
  margin-right: 480px;
 
}
 
 
 
 
.licard-time {
  /* border: 1px solid rgb(247, 10, 10); */
 
 
  display: block;
  height: auto;
  flex-direction: row;
 
  align-items: center;
 
  justify-content: space-between;
  margin-top: 0px;
 
  width: 100%;
 
}
.licard-time-up {
 
  /* border: 1px solid rgb(247, 10, 10); */
 
  display: block;
  height: auto;
  flex-direction: row;
  align-items: center;
 
  justify-content: space-between;
  margin-top: 0px;
 
  width: 100%;
 
}
.licard-times {
  /* border: 1px solid rgb(247, 10, 10); */
 
 
  display: block;
  height: auto;
  flex-direction: row;
 
  align-items: center;
 
 
  justify-content: space-between;
  margin-top: -20px;
 
  width: 100%;
 
}
.licard-timest {
  /* border: 1px solid rgb(247, 10, 10); */
 
  display: block;
  height: auto;
  flex-direction: row;
 
  align-items: center;
 
  justify-content: space-between;
  margin-top: 0px;
 
  width: 100%;
 
}
 
 
.licard-price {
 
  display: block;
  /* border: 1px solid rgb(247, 10, 10); */
width: 50%;
margin-right: 50%;
font-size: 20px;
 
 
}
.licard-time-price{
  display: block;
  /* border: 1px solid rgb(247, 10, 10); */
 
margin-left: 0%;
padding-left: 0px;
  margin-top: 0px;
 
 
}
.licard-seats-no-of{
  /* border: 1px solid rgb(247, 10, 10); */
 
  margin-left: 0px;
}
 
.licard-price-icon{
margin-right: 5px;
 
}
.licard-pricenoof-ofseats{
  margin-right: 2px;
 
}
 
 
.licard-ride-time,
 
.licard-ride-duration {
 
  margin: 0 16px;
 
}
 
 
 
 
.licard-date {
 
  font-size: 24px;
 
  font-weight: 600;
margin-left: 400px;
 
margin-top: -40px;
margin-bottom: 0px;
 
}
 
 
 
 
.licard-user {
 
  display: flex;
 
 
 
 
margin-left:65%;
 
  margin-top: -150px;
 
height: 250px;
 
 
}
 
.licard-seats-seats{
  height: 30px;
width: 190px;
margin-left: 30px;
margin-top: -40px;
font-size: 15px;
 
 
}
 
 
.licard-user-images{
 
  width: 70px;
 
  height: 70px;
  border-radius: 50%;
  margin-left: -130px;
  margin-top: 0px;
 
}
 
 
.licard-seats-name{
  /* border: 1px solid #de0e0e; */
  width: 100%;
  margin-top: 80px;
  height: 30px;
  margin-left: -90px;
 
}
 
.licard-user-id {
 
  font-size: 16px;
  /* border: 1px solid #e70f0f; */
margin-top: 110px;
height: 20px;
margin-left: -80px;
 
}
 
.licard-user-id-rider{
  font-size: 16px;
  /* border: 1px solid #e70f0f; */
margin-top: 140px;
height: 20px;
margin-left: -55px;
}
 
 
 
 
 
 
 
.card-price-icon {
 
  margin-right: 4px;
 
}
 
 
 
 
.licard-back-button {
 
  color: #007BFF;
 
  padding: 5px 5px;
 
  border: none;
 
  border-radius: 4px;
margin-top: -30px;
  cursor: pointer;
 
  margin-left: 90%;
 
}
 
 
 
 
.licard-back-button:hover {
  transform: scale(1.1); /* Zoom in on hover */
color: #007BFF;
 
}
 
 
 
 
 
 
 
 
.li-ain {
 
  display: flex;
 
  justify-content: center;
 
  margin: 20px 0;
 
}
 
 
 
 
 
 
.li-dest {
 
  display: flex;
 
  align-items: center;
 
  padding-left: 200px;
 
  padding-top: 20px;
 
  width: 200px;
 
  position: relative; /* Position relative to control child positioning */
 
}
 
 
 
 
.li-ride {
 
  padding-left: 20px;
 
  font-size: 16px;
 
 
 
 
  font-weight: 600;
 
  color: #05144c;
 
 /* Adjust the spacing as needed */
 
}
 
 
 
 
.li-arrow {
 
 
 
  width: 100px;
 
  color: #05144c;
 
  margin-left: 190px;
 
  font-size: 24px; /* Adjust the size as needed */
 
  position: absolute; /* Absolute positioning to control placement */
 
  left: 50%; /* Position arrow in the center */
 
  transform: translateX(-50%); /* Center the arrow horizontally */
 
}
 
 
 
 
.li-ride-info {
 
  display: flex;
 
  flex-direction: column;
 
  width: 60%;
 
  height: 200px;
 
  margin-top: 150px;
 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 
  padding: 20px;
 
  background-color: #ffffff;
 
  border-radius: 12px;
 
  transition: transform 0.2s ease-in-out;
 
 
 
 
}
 
 
 
 
.li-ride-info:hover {
 
  transform: translateY(-5px);
 
}
 
 
 
 
 
 
 
 
.li-date-time {
 
  display: flex;
 
  flex-direction: column;
 
  align-items: center;
 
  margin-top: -250px;
 
  height:100px;
 
 
 
}
 
.li-detail{
 
 
 
  margin-left: 74%;
 
  display: block;
 
 
 
 
 
 
}
 
 
 
 
.li-date {
 
  text-align: center;
 
  font-size: 15px;
 
  margin-top: 10px;
 
  font-weight: 600;
 
  font-family: Arial, Helvetica, sans-serif;
 
}
 
 
 
 
.li-time {
 
  display: flex;
 
  flex-direction: column;
 
  align-items: center;
 
}
 
 
 
 
.li-time p {
 
  margin: 5px 0;
 
}
 
 
 
 
.li-man-image {
 
  width: 50px;
 
  height: 50px;
 
  border-radius: 50%;
 
  margin-right: 15px;
 
}
 
 
 
 
.li-text-container  {
 
  font-size: 14px;
 
  font-weight: 600;
 
  color: #05144c;
 
}
 
 
 
 
 
 
.li-location-info div {
 
  margin: 0 10px;
 
}
 
 
 
 
 
 
 
 
.li-fa {
 
  display: flex;
 
  align-items: center;
 
  font-size: 18px;
 
  font-weight: 600;
 
  width:100px;
 
  margin-left: -10px;
 
  color: #05144c;
 
  margin-top:20px;
 
 
 
 
}
 
 
 
 
.icon {
 
  margin-right: 8px;
 
  color: #888888;
 
}
 
 
 
 
.li-back-button {
 
  position: absolute;
 
  top: 20px;
 
  right: 20px;
 
  border: none;
 
  background: none;
 
  color: #05144c;
 
  cursor: pointer;
 
  font-size: 14px;
 
  text-decoration: underline;
 
  transition: color 0.3s;
 
  margin-left: -200px;
 
}
 
 
 
 
.li-back-button:hover {
 
  color: #007BFF;
 
}
 
 
 
 
.li-price {
 
  display: flex;
 
  justify-content: center;
 
  align-items: center;
 
  font-size: 18px;
 
  font-weight: 600;
 
  color: #05144c;
 
  margin-top: 20px;
 
}
 
.li-Reportride {
 
  margin-left: 270px;
 
   line-height: normal;
 
 margin-top: 30px;
 
 text-decoration: none !important;outline: none; /* No outline on focus */
 
 
 
 
 }
 
 
 
 
.li-card {
 
  width: calc(100% - 20px); /* Set card width to 100% minus padding */
 
  padding: 10px; /* Add padding to the card content */
 
}
 
.li-details {
 
  flex: 1;
 
  margin-left: 20px;
 
}
 
 
 
 
.li-detail {
 
  display: flex;
 
  align-items: center;
 
  margin-bottom: 10px;
 
}
 
.li-mainbb{
 
  border:1px solid rgb(252, 250, 250);
 
 
 
}
 
 
 
 
.li-detaill {
 
  font-weight: 300;
 
}
 
.li-date {
 
  font-size: 15px;
 
  font-weight: 600;
 
  margin-right: 10px;
 
}
 
 
 
 
.li-man-image {
 
  width: 50px;
 
  height: 50px;
 
  border-radius: 50%;
 
  border: 2px solid #05144c;
 
  margin-right: 10px;
 
}
 
 
 
 
.li-immtext {
 
  display: flex;
 
  margin-left: 20px;
 
  align-items: center; /* Align items vertically */
 
}
 
 
 
 
.li-slidee {
 
  font-size: 60px;
 
  margin-left: -250px;
 
  margin-bottom: 80px;
 
}
 
 
 
 
.li-text-container {
 
  display: flex;
 
  flex-direction: column;
 
 
 
 
  align-items: flex-start;
 
}
 
 
 
 
.li-location-info {
 
  flex: 1; /* Allow flex grow */
 
  text-align: center; /* Center align text */
 
  margin-left: 16px;
 
}
 
 
 
 
.li-ride {
 
  margin-top: 10px;
 
}
 
 
 
 
.li-location-info > div {
 
  margin-left:-240px;
 
  font-family: "Times New Roman", Times, serif;
 
}
 
.li-additional-details {
 
  text-align: left; /* Align starting line */
 
}
 
 
 
 
.li-additional-details > div {
 
color: #05144c;
 
font-family: 'Times New Roman', Times, serif;
 
  width:60%;
 
  margin-top: 10px;;
 
  margin-left: 270px;/* Center align text */
 
  font-size: 20px; /* Set font size */
 
  margin-bottom: -20px; /* Add space between each text */
 
}
 
.li-hr {
 
  margin-top: 20px;;
 
  border-color:rgb(246, 242, 242); /* Set the border color to grey */
 
}
 
.li-book {
 
  background-color: #05144c;
 
  color: white;
 
  border: none;
 
  padding: 20px 20px;
 
  font-size: 16px;
 
margin-left:550px;
 
  border-radius: 50px;
 
  cursor: pointer;
 
  text-decoration: none;
 
 
 
 
}
 
.li-bookk{
 
  margin-top: 40px;
 
  margin-left: 100px;;
margin-bottom: 50px;
}
 
.li-date{
 
  text-align: center;
 
  font-size: 25px;
 
  margin-top: 130px;
 
  font-weight: 600;
 
  font-family: Arial, Helvetica, sans-serif;
 
}
 
.li-icon {
 
  margin-right: 10px;
 
  color: grey;
 
}
 
 
 
 
.li-question-icon {
 
  color: rgb(39, 39, 182);
 
  filter: blur(1px);
 
}
 
.li-ask{
 
  color:rgb(24, 116, 192);
 
}
 
 
 
 
 
 
.li-fa{
 
 
 
  color: navy;
 
  margin-right: 20px;
 
  display: flex;
 
  align-items: center;
 
  font-size: 18px;
 
}
 
 
 
 
.li-co-travellers {
 
  margin-top: 20px;
 
  margin-left: 16%;
 
  padding: 15px;
 
  width:58vw;
 
 
 
 
}
 
.li-co-arrow {
 
  margin-left: 30px;
 
  color: grey;
 
}
 
.li-co-traveller {
 
  display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  margin-bottom: 10px;
 
}
 
.li-co-traveller-image {
 
  width: 50px;
 
  height: 50px;
 
  border-radius: 50%;
 
  margin-left: 10px;
 
}
 
 
 
 
.li-co-sect {
 
  flex: 1 0 21%; /* Adjust the width as needed */
 
  margin: 10px;
 
  display: flex;
 
  align-items: center;
 
  text-align: left;
 
  display: block;
 
}
 
 
 
 
.li-hrr {
 
  margin-left: 16%;
 
  height: 10px;
 
  border-radius: 5px;
 
  background-color: rgb(191, 192, 192);
 
  border: 1px solid black;
 
  width: 60vw;
 
}
 
 
 
 
.li-co {
 
  font-weight: 600;
 
}
 
 
 
 
.li-co-traveller-details {
 
  display: flex;
 
  flex-wrap: wrap;
 
  justify-content: space-between;
 
  width: 100%;
 
}
 
 
 
 
.li-co-divs {
 
  display: flex;
 
  align-items: center;
 
}
 
.li-co-traveller-text {
 
  flex-grow: 1;
 
}

@media only screen and (min-width: 320px) and (max-width: 343px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard-dest{
    width: 400px;
    margin-top: 70px;
    margin-left: -10px;
    font-size: 14px;
  }
  .licard {
 
    border: 1px solid #acaaaa;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 65vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
   
 
    flex-direction: column;
 
    align-items: center;
 
  }
  .licard-back-button{
margin-bottom: 10px;
    width: 100px;
    margin-left: 180px;
    font-size: 10px;
 
  }
  .licard-date{
    width: 120px;
    margin-left: 10px;
    font-size: 18px;
 
  }
  .licard-dest-ride{
    font-size: 13px;
    margin-left: -10px;
    display: block;
  }
  .licard-price{
    margin-right: 145px;
    font-size: 13px;
    margin-top: -3px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
 
 
.licard-time-date{
  margin-top: -30px;
  font-size: 13px;
  width: 80%;
  margin-left: 410px;
}
.licard-seats-no-of{
  margin-left: 0px;
}
.licard-seats-seats{
  font-size: 15px;
  margin-right: 20px;
  margin-top: -50px;
}
.licard-user{
  margin-left: -100px;
  margin-top: 20px;
 
 
}
 
.licard-user-images{
  width: 40px;
  height: 40px;
  margin-top: -272px;
  margin-right: 20px;
 
}
.licard-seats-name{
  margin-top: -230px;
  margin-right: 20px;
 
}
.licard-user-id{
  margin-top: 60px;
}
.licard-user-id-rider{
  margin-top: 80px;
 
}
.li-additional-details{
  display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 font-size: 10px;
    margin-left: -240px;

    align-items: center; /* Center align items */
}
.li-Reportride{
 
  margin-left: 10px;
  margin-top: 0px;
 
}
.li-co-travellers{
  margin-left: 40px;
}
.li-co-traveller{
  margin-left: 0px;
}
.li-co-traveller-image{
  margin-left: 60px;
}
.li-co-arrow{
  margin-left: 130px;
  margin-top: -60px;
}
.li-co-leavi{
 
  margin-right: 0px;
  width: 380px;
}
 
.li-bookk{
 
  margin-top: 40px;
 
  margin-left: -130px;;
 
 
}
  .li-book{
 
    margin-left:200px;
 
  }
 
 .licard-ride{
  margin-left: 0px;
 }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
 
.li-hrr{
  width: 250px;
  margin-left: 30px;
}
 
 
}
 
 
@media only screen and (min-width: 360px) and (max-width: 374px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard-dest{
    width: 400px;
    margin-top: 70px;
    margin-left: -10px;
 
  }
  .licard {
 
    border: 1px solid #acaaaa;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 52vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
   
 
    flex-direction: column;
 
    align-items: center;
 
  }
  .licard-back-button{
margin-bottom: 10px;
    width: 100px;
    margin-left: 220px;
    font-size: 10px;
 
  }
  .licard-date{
    width: 120px;
    margin-left: 10px;
    font-size: 18px;
 
  }
  .licard-dest-ride{
    font-size: 15px;
    margin-left: -10px;
  }
  .licard-price{
    margin-right: 160px;
    font-size: 15px;
    margin-top: -0px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
 
 
.licard-time-date{
  margin-top: -25px;
  font-size: 15px;
  width: 80%;
  margin-left: 405px;
}
.licard-seats-no-of{
  margin-left: 0px;
}
.licard-seats-seats{
  font-size: 15px;
  margin-right: 30px;
  margin-top: -50px;
}
.licard-user{
  margin-left: -120px;
  margin-top: 20px;
 
 
}
 
.licard-user-images{
  width: 40px;
  height: 40px;
  margin-top: -290px;
  margin-right: 20px;
 
}
.licard-seats-name{
  margin-top: -250px;
  margin-right: 30px;
 
}
.licard-user-id{
  margin-top: 60px;
}
.licard-user-id-rider{
  margin-top: 80px;
 
}
.li-additional-details{
  display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -230px;
 
    align-items: center; /* Center align items */
}
.li-Reportride{
 
  margin-left: 60px;
  margin-top: 0px;
 
}
.li-co-travellers{
  margin-left: 40px;
}
.li-co-traveller{
  margin-left: 0px;
}
.li-co-traveller-image{
  margin-left: 150px;
}
.li-co-arrow{
  margin-left: 230px;
  margin-top: -60px;
}
.li-co-leavi{
 
  margin-right: 0px;
  width: 380px;
}
 
.li-bookk{
 
  margin-top: 40px;
 
  margin-left: -80px;;
 
 
}
  .li-book{
 
    margin-left:200px;
 
  }
 
 .licard-ride{
  margin-left: 5px;
 }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
 
.li-hrr{
  width: 330px;
  margin-left: 30px;
}
 
 
}
 
 
@media only screen and (min-width: 375px) and (max-width: 383px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard-dest{
    width: 400px;
    margin-top: 70px;
 
  }
  .licard {
 
    border: 1px solid #acaaaa;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 50vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
   
 
    flex-direction: column;
 
    align-items: center;
 
  }
  .licard-back-button{
margin-bottom: 10px;
    width: 100px;
    margin-left: 220px;
    font-size: 10px;
 
  }
  .licard-date{
    width: 120px;
    margin-left: 10px;
    font-size: 18px;
 
  }
  .licard-dest-ride{
    font-size: 15px;
    margin-left: -10px;
  }
  .licard-price{
    margin-right: 170px;
    font-size: 15px;
    margin-top: -0px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
  .licard-time-date{
    margin-top: -25px;
    font-size: 15px;
    width: 80%;
    margin-left: 405px;
  }
 
 
.licard-seats-no-of{
  margin-left: 0px;
}
.licard-seats-seats{
  font-size: 15px;
  margin-right: 10px;
  margin-top: -45px;
}
.licard-user{
  margin-left: -150px;
  margin-top: 20px;
 
 
}
 
.licard-user-images{
  width: 40px;
  height: 40px;
  margin-top: -290px;
  margin-right: 20px;
 
}
.licard-seats-name{
  margin-top: -250px;
  margin-right: 10px;
 
}
.licard-user-id{
  margin-top: 60px;
}
.licard-user-id-rider{
  margin-top: 80px;
 
}
.li-additional-details{
  display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -230px;
 
    align-items: center; /* Center align items */
}
.li-Reportride{
 
  margin-left: 60px;
  margin-top: 0px;
 
}
.li-co-travellers{
  margin-left: 40px;
}
.li-co-traveller{
  margin-left: 0px;
}
.li-co-traveller-image{
  margin-left: 150px;
}
.li-co-arrow{
  margin-left: 230px;
  margin-top: -60px;
}
.li-co-leavi{
 
  margin-right: 0px;
  width: 380px;
}
 
.li-bookk{
 
  margin-top: 40px;
 
  margin-left: -80px;;
 
 
}
  .li-book{
 
    margin-left:200px;
 
  }
 
 .licard-ride{
  margin-left: 20px;
 }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
 
.li-hrr{
  width: 330px;
  margin-left: 30px;
}
 
 
}
@media only screen and (min-width: 390px) and (max-width: 411px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard-dest{
    width: 400px;
    margin-top: 70px;
 
  }
  .licard {
 
    border: 1px solid #acaaaa;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 48vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
   
 
    flex-direction: column;
 
    align-items: center;
 
  }
  .licard-back-button{
margin-bottom: 10px;
    width: 100px;
    margin-left: 240px;
    font-size: 10px;
 
  }
  .licard-date{
    width: 120px;
    margin-left: 10px;
    font-size: 18px;
 
  }
  .licard-dest-ride{
    font-size: 15px;
    margin-left: -10px;
  }
  .licard-price{
    margin-right: 170px;
    font-size: 15px;
    margin-top: -0px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
 
 
.licard-time-date{
  margin-top: -25px;
  font-size: 15px;
  margin-left: 305px;
}
.licard-seats-no-of{
  margin-left: 0px;
}
.licard-seats-seats{
  font-size: 15px;
  margin-right: 0px;
  margin-top: -45px;
}
.licard-user{
  margin-left: -150px;
  margin-top: 20px;
 
 
}
 
.licard-user-images{
  width: 40px;
  height: 40px;
  margin-top: -290px;
  margin-right: 40px;
 
}
.licard-seats-name{
  margin-top: -250px;
  margin-right: 20px;
 
}
.licard-user-id{
  margin-top: 60px;
}
.licard-user-id-rider{
  margin-top: 80px;
 
}
.li-additional-details{
  display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -230px;
 
    align-items: center; /* Center align items */
}
.li-Reportride{
 
  margin-left: 60px;
  margin-top: 0px;
 
}
.li-co-travellers{
  margin-left: 40px;
}
.li-co-traveller{
  margin-left: 0px;
}
.li-co-traveller-image{
  margin-left: 150px;
}
.li-co-arrow{
  margin-left: 230px;
  margin-top: -60px;
}
.li-co-leavi{
 
  margin-right: 0px;
  width: 380px;
}
 
.li-bookk{
 
  margin-top: 40px;
 
  margin-left: -80px;;
 
 
}
  .li-book{
 
    margin-left:200px;
 
  }
 
 .licard-ride{
  margin-left: 20px;
 }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
 
.li-hrr{
  width: 330px;
  margin-left: 30px;
}
 
 
}
@media only screen and (min-width: 412px) and (max-width: 413px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard-dest{
    width: 400px;
    margin-top: 70px;
 
  }
  .licard {
 
    border: 1px solid #acaaaa;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 33vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
   
 
    flex-direction: column;
 
    align-items: center;
 
  }
  .licard-back-button{
margin-bottom: 10px;
    width: 100px;
    margin-left: 270px;
    font-size: 10px;
 
  }
  .licard-date{
width: 100px;
    margin-left: 10px;
    font-size: 18px;
 
  }
  .licard-dest-ride{
    font-size: 15px;
  }
  .licard-price{
    margin-right: 170px;
    font-size: 15px;
    margin-top: -0px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
 
 
.licard-time-date{
  margin-top: -25px;
  font-size: 15px;
  margin-left: 305px;
}
.licard-seats-no-of{
  margin-left: 0px;
}
.licard-seats-seats{
  font-size: 15px;
  margin-right: -30px;
}
.licard-user{
  margin-left: -150px;
  margin-top: 20px;
 
 
}
 
.licard-user-images{
  width: 40px;
  height: 40px;
  margin-top: -235px;
  margin-right: 40px;
 
}
.licard-seats-name{
  margin-top: -195px;
  margin-right: 30px;
 
}
.licard-user-id{
  margin-top: 60px;
}
.licard-user-id-rider{
  margin-top: 80px;
 
}
.li-additional-details{
  display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -210px;
 
    align-items: center; /* Center align items */
}
.li-Reportride{
 
  margin-left: 60px;
  margin-top: 0px;
 
}
.li-co-travellers{
  margin-left: 40px;
}
.li-co-traveller{
  margin-left: 0px;
}
.li-co-traveller-image{
  margin-left: 150px;
}
.li-co-arrow{
  margin-left: 230px;
  margin-top: -60px;
}
.li-co-leavi{
 
  margin-right: 0px;
  width: 380px;
}
 
.li-bookk{
 
  margin-top: 40px;
 
  margin-left: -80px;;
 
 
}
  .li-book{
 
    margin-left:200px;
 
  }
 
 .licard-ride{
  margin-left: 20px;
 }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
 
.li-hrr{
  width: 350px;
  margin-left: 30px;
}
 
 
}
@media only screen and (min-width: 414px) and (max-width: 427px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard-dest{
    width: 400px;
    margin-top: 70px;
 
  }
  .licard {
 
    border: 1px solid #acaaaa;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 45vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
   
 
    flex-direction: column;
 
    align-items: center;
 
  }
  .licard-back-button{
margin-bottom: 10px;
    width: 100px;
    margin-left: 270px;
    font-size: 10px;
 
  }
  .licard-date{
width: 120px;
    margin-left: 10px;
    font-size: 18px;
 
  }
  .licard-dest-ride{
    font-size: 15px;
  }
  .licard-price{
    margin-right: 170px;
    font-size: 15px;
    margin-top: -0px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
 
 
.licard-time-date{
  margin-top: -25px;
  font-size: 15px;
  margin-left: 305px;
}
.licard-seats-no-of{
  margin-left: 0px;
}
.licard-seats-seats{
  font-size: 15px;
  margin-right: 0px;
  margin-top: -45px;
}
.licard-user{
  margin-left: -150px;
  margin-top: 20px;
 
 
}
 
.licard-user-images{
  width: 40px;
  height: 40px;
  margin-top: -290px;
  margin-right: 40px;
 
}
.licard-seats-name{
  margin-top: -250px;
  margin-right: 30px;
 
}
.licard-user-id{
  margin-top: 60px;
}
.licard-user-id-rider{
  margin-top: 80px;
 
}
.li-additional-details{
  display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -210px;
 
    align-items: center; /* Center align items */
}
.li-Reportride{
 
  margin-left: 60px;
  margin-top: 0px;
 
}
.li-co-travellers{
  margin-left: 40px;
}
.li-co-traveller{
  margin-left: 0px;
}
.li-co-traveller-image{
  margin-left: 150px;
}
.li-co-arrow{
  margin-left: 230px;
  margin-top: -60px;
}
.li-co-leavi{
 
  margin-right: 0px;
  width: 380px;
}
 
.li-bookk{
 
  margin-top: 40px;
 
  margin-left: -80px;;
 
 
}
  .li-book{
 
    margin-left:200px;
 
  }
 
 .licard-ride{
  margin-left: 20px;
 }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
 
.li-hrr{
  width: 350px;
  margin-left: 30px;
}
 
 
}
@media only screen and (min-width: 428px) and (max-width: 767px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard-dest{
    width: 400px;
    margin-top: 70px;
 
  }
  .licard {
 
    border: 1px solid #acaaaa;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 45vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
   
 
    flex-direction: column;
 
    align-items: center;
 
  }
  .licard-back-button{
margin-bottom: 10px;
    width: 100px;
    margin-left: 270px;
    font-size: 10px;
 
  }
  .licard-date{
width: 120px;
    margin-left: 10px;
    font-size: 18px;
 
  }
  .licard-dest-ride{
    font-size: 15px;
  }
  .licard-price{
    margin-right: 170px;
    font-size: 15px;
    margin-top: -0px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
 
  .li-additional-details {
 
    display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -300px;
 
    align-items: center; /* Center align items */
 
  }
 
.licard-time-date{
  margin-top: -25px;
  font-size: 15px;
  margin-left: 305px;
}
.licard-seats-no-of{
  margin-left: 0px;
}
.licard-seats-seats{
  font-size: 15px;
  margin-right: 10px;
  margin-top: -45px;
}
.licard-user{
  margin-left: -150px;
  margin-top: 20px;
 
 
}
 
.licard-user-images{
  width: 40px;
  height: 40px;
  margin-top: -290px;
  margin-right: 40px;
 
}
.licard-seats-name{
  margin-top: -250px;
  margin-right: 40px;
 
}
.licard-user-id{
  margin-top: 60px;
}
.licard-user-id-rider{
  margin-top: 80px;
 
}
.li-additional-details{
  display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -230px;
 
    align-items: center; /* Center align items */
}
.li-Reportride{
 
  margin-left: 30px;
 
}
.li-co-travellers{
  margin-left: 40px;
}
.li-co-traveller{
  margin-left: 0px;
}
.li-co-traveller-image{
  margin-left: 150px;
}
.li-co-arrow{
  margin-left: 230px;
  margin-top: -60px;
}
.li-co-leavi{
 
  margin-right: 0px;
  width: 380px;
}
 
.li-bookk{
 
  margin-top: 40px;
 
  margin-left: -80px;;
 
 
}
  .li-book{
 
    margin-left:200px;
 
  }
 
 .licard-ride{
  margin-left: 20px;
 }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
 
.li-hrr{
  width: 370px;
  margin-left: 30px;
}
 
 
}
 
@media only screen and (min-width: 768px) and (max-width: 799px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard {
 
    border: 1px solid #858484;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 25vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
 
    flex-direction: column;
 
    align-items: center;
 
  }
  .licard-back-button{
    width: 190px;
    margin-left: 530px;
  }
  .licard-date{
    margin-left: 300px;
  }
  .licard-price{
    margin-right: 340px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
  .licard-ride {
 
 
    margin-right: 0px;
    font-size: 20px;
 
  }
  .licard-dest-ride{
  margin-top: 0px;
    width: 400px;
    height: 30px;
    font-size: 20px;
 
  }
  .licard-dest{
    width: 400px;
    margin-left: -5px;
 
  }
 
  .li-additional-details {
 
    display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -300px;
 
    align-items: center; /* Center align items */
 
  }
 
  .licard-time-date {
 
    font-size: 20px;
  margin-top: -17px;
    display: flex;
 
    align-items: center;
 
    justify-content: space-between;
 
    width: 50%;
    height: auto;
    margin-right: 340px;
 
  }
 
 
  .li-book{
 
    margin-left:200px;
 
  }
 
 .licard-ride{
  margin-left: 20px;
 }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
  .li-Reportride{
 
    margin-left: 60px;
 
  }
.li-hrr{
  width: 650px;
  margin-left: 50px;
}
.licard-user {
 
  display: flex;
margin-left:55%;
 
  margin-top: -140px;
 
height: 250px;
 
 
}
 
 
}
@media only screen and (min-width: 800px) and (max-width: 819px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard {
 
    border: 1px solid #858484;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 20vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
 
    flex-direction: column;
 
    align-items: center;
 
  }
  .licard-back-button{
    width: 190px;
    margin-left: 530px;
  }
  .licard-date{
    margin-left: 300px;
  }
  .licard-price{
    margin-right: 340px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
  .licard-ride {
 
 
    margin-right: 0px;
    font-size: 20px;
 
  }
  .licard-dest-ride{
  margin-top: 0px;
    width: 400px;
    height: 30px;
    font-size: 20px;
 
  }
  .licard-dest{
    width: 400px;
 
  }
 
  .li-additional-details {
 
    display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -300px;
 
    align-items: center; /* Center align items */
 
  }
 
  .licard-time-date {
 
    font-size: 20px;
  margin-top: -17px;
    display: flex;
 
    align-items: center;
 
    justify-content: space-between;
 
    width: 50%;
    height: auto;
    margin-right: 340px;
 
  }
 
 
  .li-book{
 
    margin-left:200px;
 
  }
 
 .licard-ride{
  margin-left: 20px;
 }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
  .li-Reportride{
 
    margin-left: 60px;
 
  }
.li-hrr{
  width: 700px;
  margin-left: 50px;
}
.licard-user {
 
  display: flex;
margin-left:55%;
 
  margin-top: -140px;
 
height: 250px;
 
 
}
 
 
}
 


@media only screen and (min-width: 820px) and (max-width:852px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard {
 
    border: 1px solid #ddd;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 21vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
 
    flex-direction: column;
 
    align-items: center;
 
  }
 
  .licard-back-button{
    width: 190px;
    margin-left: 570px;
  }
  .licard-date{
    margin-left: 300px;
  }
  .licard-price{
    margin-right: 350px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
 
  .li-additional-details {
 
    display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -300px;
 
    align-items: center; /* Center align items */
 
  }
 
 
 
 
  .li-book{
 
    margin-left:200px;
 
  }
 
  .licard-time-date {
 
    font-size: 20px;
  margin-top: -17px;
    display: flex;
 
    align-items: center;
 
    justify-content: space-between;
 
    width: 50%;
    height: auto;
    margin-right: 350px;
 
  }
  .licard-user {
 
    display: flex;
 
 
 
 
  margin-left:55%;
 
    margin-top: -150px;
 
  height: 250px;
 
 
  }
 
  .licard-seats-seats{
    height: 30px;
  width: 190px;
  margin-left: 0px;
  margin-top: -30px;
  font-size: 15px;
 
 
  }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
  .li-Reportride{
 
    margin-left: 70px;
 
  }
.li-hrr{
  width: 705px;
  margin-left: 50px;
}
 
.licard-user-images{
 
  width: 70px;
 
  height: 70px;
  border-radius: 50%;
  margin-left: -130px;
  margin-top: 20px;
 
}
 
 
.licard-seats-name{
  /* border: 1px solid #de0e0e; */
  width: 100%;
  margin-top: 100px;
  height: 30px;
  margin-left: -80px;
 
}
 
}
@media only screen and (min-width: 884px) and (max-width: 1279px) {
 
  /* Your CSS adjustments for iPad screens */
  .li-mainbb{
    overflow-x: hidden;
  }
  .licard {
 
    border: 1px solid #ddd;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 90vw;
 
    height: 23vh;
 
  margin-left: 5%;
 
    margin-top: 130px;
 
    display: flex;
 
    flex-direction: column;
 
    align-items: center;
 
  }
  .licard-back-button{
    width: 200px;
    padding-right: 110px;
  }
  .licard-date{
    margin-left: 300px;
  }
  .licard-price{
    margin-right: 380px;
  }
  .li-ride-info{
 
   
 
    display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  height: 200px;
 
  width: 80%; /* Set width to 60% */
 
 
 
 
  margin: auto; /* Center the card horizontally */
 
  }
 
  .li-additional-details {
 
    display: flex;
 
    flex-direction: column;
 
    margin-top: 20px;
 
    margin-left: -320px;
 
    align-items: center; /* Center align items */
 
  }
 
 
 
 
  .li-book{
 
    margin-left:200px;
 
  }
 
  .licard-time-date {
 
    font-size: 20px;
  margin-top: -17px;
    display: flex;
 
    align-items: center;
 
    justify-content: space-between;
 
    width: 50%;
    height: auto;
    margin-right: 380px;
 
  }
  .licard-user {
 
    display: flex;
 
 
 
 
  margin-left:55%;
 
    margin-top: -150px;
 
  height: 250px;
 
 
  }
 
  .licard-seats-seats{
    height: 30px;
  width: 190px;
  margin-left: 0px;
  margin-top: -30px;
  font-size: 15px;
 
 
  }
 
  .li-slidee{
 
    margin-left: -90px;
 
    font-size:40px;
 
  }
 
  .li-location-info{
 
    margin-left: 200px;
 
    margin-top: -10px;
 
  }
 
  .li-details{
 
    margin-left: 60px;
 
  }
 
  .li-Reportride{
 
    margin-left: 60px;
 
  }
.li-hrr{
  width: 775px;
  margin-left: 50px;
}
 
.licard-user-images{
 
  width: 70px;
 
  height: 70px;
  border-radius: 50%;
  margin-left: -130px;
  margin-top: 20px;
 
}
 
 
.licard-seats-name{
  /* border: 1px solid #de0e0e; */
  width: 100%;
  margin-top: 100px;
  height: 30px;
  margin-left: -80px;
 
}
 
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
 
  .licard {
 
    border: 1px solid #ddd;
 
    border-radius: 8px;
 
    padding: 16px;
 
    margin: 16px;
 
    color: #05144c;
 
    width: 65vw;
 
    height: 31vh;
 
  margin-left: 17%;
 
    margin-top: 150px;
 
    display: flex;
 
    flex-direction: column;
 
    align-items: center;
 
  }
 
  .SA-info-ride-card {
    /* border: 1px solid black; */
    margin-top: -10px;
  }
 
 
  .licard-details {
 
 
    width: 100%;
 
    /* margin-top: 200px; */
 
    display: flex;
 
    flex-direction: column;
 
    align-items: center;
 
  }
 
 
 
 
  .licard-location {
 
 
    width: 100%;
 
    display: flex;
 
    flex-direction: column;
 
    align-items: center;
 
    margin-bottom: 16px;
 
  }
 
 
 
 
  .licard-ride-info {
 
 
    width: 100%;
 
  }
 
 
 
 
  .licard-dest {
 
 
    display: flex;
 
    justify-content: space-between;
 
    width: 70%;
 
  }
 
 
 
 
  .licard-ride {
 
 
    margin-right: 0px;
    font-size: 20px;
 
  }
  .licard-dest-ride{
    /* border: 1px solid rgb(247, 10, 10); */
  margin-top: 0px;
    width: 100%;
    height: 30px;
    font-size: 20px;
  }
  .licard-arrow {
    font-size: 24px;
  }
  .licard-time-date {
    /* border: 1px solid rgb(247, 10, 10); */
 
    font-size: 20px;
  margin-top: -17px;
    display: flex;
 
    align-items: center;
 
    justify-content: space-between;
 
    width: 50%;
    height: auto;
    margin-right: 480px;
 
  }
 
 
 
 
  .licard-time {
    /* border: 1px solid rgb(247, 10, 10); */
 
 
    display: block;
    height: auto;
    flex-direction: row;
 
    align-items: center;
 
    justify-content: space-between;
    margin-top: 0px;
 
    width: 100%;
 
  }
  .licard-time-up {
 
    /* border: 1px solid rgb(247, 10, 10); */
 
    display: block;
    height: auto;
    flex-direction: row;
    align-items: center;
 
    justify-content: space-between;
    margin-top: 0px;
 
    width: 100%;
 
  }
  .licard-times {
    /* border: 1px solid rgb(247, 10, 10); */
 
 
    display: block;
    height: auto;
    flex-direction: row;
 
    align-items: center;
   
 
    justify-content: space-between;
    margin-top: -20px;
 
    width: 100%;
 
  }
  .licard-timest {
    /* border: 1px solid rgb(247, 10, 10); */
 
    display: block;
    height: auto;
    flex-direction: row;
 
    align-items: center;
 
    justify-content: space-between;
    margin-top: 0px;
 
    width: 100%;
 
  }
 
 
  .licard-price {
 
    display: block;
    /* border: 1px solid rgb(247, 10, 10); */
  width: 50%;
  margin-right: 50%;
  font-size: 20px;
 
 
  }
  .licard-time-price{
    display: block;
    /* border: 1px solid rgb(247, 10, 10); */
 
  margin-left: 0%;
  padding-left: 0px;
    margin-top: 0px;
 
 
  }
  .licard-seats-no-of{
    /* border: 1px solid rgb(247, 10, 10); */
 
    margin-left: 0px;
  }
 
  .licard-price-icon{
  margin-right: 5px;
 
  }
  .licard-pricenoof-ofseats{
    margin-right: 2px;
 
  }
 
 
  .licard-ride-time,
 
  .licard-ride-duration {
 
    margin: 0 16px;
 
  }
 
 
 
 
  .licard-date {
 
    font-size: 24px;
 
    font-weight: 600;
  margin-left: 400px;
   
  margin-top: -40px;
  margin-bottom: 0px;
 
  }
 
 
 
 
  .licard-user {
 
    display: flex;
 
 
 
 
  margin-left:65%;
 
    margin-top: -150px;
 
  height: 250px;
 
 
  }
 
  .licard-seats-seats{
    height: 30px;
  width: 190px;
  margin-left: 30px;
  margin-top: -40px;
  font-size: 15px;
 
 
  }
 
 
  .licard-user-images{
 
    width: 70px;
 
    height: 70px;
    border-radius: 50%;
    margin-left: -130px;
    margin-top: 0px;
 
  }
 
 
  .licard-seats-name{
    /* border: 1px solid #de0e0e; */
    width: 100%;
    margin-top: 80px;
    height: 30px;
    margin-left: -90px;
 
  }
 
  .licard-user-id {
 
    font-size: 16px;
    /* border: 1px solid #e70f0f; */
  margin-top: 110px;
  height: 20px;
  margin-left: -80px;
 
  }
 
  .licard-user-id-rider{
    font-size: 16px;
    /* border: 1px solid #e70f0f; */
  margin-top: 140px;
  height: 20px;
  margin-left: -55px;
  }
 
 
 
 
 
 
 
  .card-price-icon {
 
    margin-right: 4px;
 
  }
 
 
 
 
  .licard-back-button {
 
    color: #007BFF;
 
    padding: 5px 5px;
 
    border: none;
 
    border-radius: 4px;
  margin-top: -30px;
    cursor: pointer;
 
    margin-left: 89%;
 
  }
 
 
 
 
  .licard-back-button:hover {
    transform: scale(1.1); /* Zoom in on hover */
  color: #007BFF;
 
  }
 
 
 
 
 
 
 
 
  .li-ain {
 
    display: flex;
 
    justify-content: center;
 
    margin: 20px 0;
 
  }
 
 
 
 
 
 
  .li-dest {
 
    display: flex;
 
    align-items: center;
 
    padding-left: 200px;
 
    padding-top: 20px;
 
    width: 200px;
 
    position: relative; /* Position relative to control child positioning */
 
  }
 
 
 
 
  .li-ride {
 
    padding-left: 20px;
 
    font-size: 16px;
 
 
 
 
    font-weight: 600;
 
    color: #05144c;
 
   /* Adjust the spacing as needed */
 
  }
 
 
 
 
  .li-arrow {
 
   
 
    width: 100px;
 
    color: #05144c;
 
    margin-left: 190px;
 
    font-size: 24px; /* Adjust the size as needed */
 
    position: absolute; /* Absolute positioning to control placement */
 
    left: 50%; /* Position arrow in the center */
 
    transform: translateX(-50%); /* Center the arrow horizontally */
 
  }
 
 
 
 
  .li-ride-info {
 
    display: flex;
 
    flex-direction: column;
 
    width: 60%;
 
    height: 200px;
 
    margin-top: 150px;
 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 
    padding: 20px;
 
    background-color: #ffffff;
 
    border-radius: 12px;
 
    transition: transform 0.2s ease-in-out;
 
 
 
 
  }
 
 
 
 
  .li-ride-info:hover {
 
    transform: translateY(-5px);
 
  }
 
 
 
 
 
 
 
 
  .li-date-time {
 
    display: flex;
 
    flex-direction: column;
 
    align-items: center;
 
    margin-top: -250px;
 
    height:100px;
 
   
 
  }
 
  .li-detail{
 
   
 
    margin-left: 74%;
 
    display: block;
 
 
 
 
   
 
  }
 
 
 
 
  .li-date {
 
    text-align: center;
 
    font-size: 15px;
 
    margin-top: 10px;
 
    font-weight: 600;
 
    font-family: Arial, Helvetica, sans-serif;
 
  }
 
 
 
 
  .li-time {
 
    display: flex;
 
    flex-direction: column;
 
    align-items: center;
 
  }
 
 
 
 
  .li-time p {
 
    margin: 5px 0;
 
  }
 
 
 
 
  .li-man-image {
 
    width: 50px;
 
    height: 50px;
 
    border-radius: 50%;
 
    margin-right: 15px;
 
  }
 
 
 
 
  .li-text-container  {
 
    font-size: 14px;
 
    font-weight: 600;
 
    color: #05144c;
 
  }
 
 
 
 
 
 
  .li-location-info div {
 
    margin: 0 10px;
 
  }
 
 
 
 
 
 
 
 
  .li-fa {
 
    display: flex;
 
    align-items: center;
 
    font-size: 18px;
 
    font-weight: 600;
 
    width:100px;
 
    margin-left: -10px;
 
    color: #05144c;
 
    margin-top:20px;
 
 
 
 
  }
 
 
 
 
  .icon {
 
    margin-right: 8px;
 
    color: #888888;
 
  }
 
 
 
 
  .li-back-button {
 
    position: absolute;
 
    top: 20px;
 
    right: 20px;
 
    border: none;
 
    background: none;
 
    color: #05144c;
 
    cursor: pointer;
 
    font-size: 14px;
 
    text-decoration: underline;
 
    transition: color 0.3s;
 
    margin-left: -200px;
 
  }
 
 
 
 
  .li-back-button:hover {
 
    color: #007BFF;
 
  }
 
 
 
 
  .li-price {
 
    display: flex;
 
    justify-content: center;
 
    align-items: center;
 
    font-size: 18px;
 
    font-weight: 600;
 
    color: #05144c;
 
    margin-top: 20px;
 
  }
 
  .li-Reportride {
 
    margin-left: 230px;
 
     line-height: normal;
 
   margin-top: 30px;
 
   text-decoration: none !important;outline: none; /* No outline on focus */
 
 
 
 
   }
 
 
 
 
  .li-card {
 
    width: calc(100% - 20px); /* Set card width to 100% minus padding */
 
    padding: 10px; /* Add padding to the card content */
 
  }
 
  .li-details {
 
    flex: 1;
 
    margin-left: 20px;
 
  }
 
 
 
 
  .li-detail {
 
    display: flex;
 
    align-items: center;
 
    margin-bottom: 10px;
 
  }
 
  .li-mainbb{
 
    border:1px solid rgb(252, 250, 250);
 
   
 
  }
 
 
 
 
  .li-detaill {
 
    font-weight: 300;
 
  }
 
  .li-date {
 
    font-size: 15px;
 
    font-weight: 600;
 
    margin-right: 10px;
 
  }
 
 
 
 
  .li-man-image {
 
    width: 50px;
 
    height: 50px;
 
    border-radius: 50%;
 
    border: 2px solid #05144c;
 
    margin-right: 10px;
 
  }
 
 
 
 
  .li-immtext {
 
    display: flex;
 
    margin-left: 20px;
 
    align-items: center; /* Align items vertically */
 
  }
 
 
 
 
  .li-slidee {
 
    font-size: 60px;
 
    margin-left: -250px;
 
    margin-bottom: 80px;
 
  }
 
 
 
 
  .li-text-container {
 
    display: flex;
 
    flex-direction: column;
 
 
 
 
    align-items: flex-start;
 
  }
 
 
 
 
  .li-location-info {
 
    flex: 1; /* Allow flex grow */
 
    text-align: center; /* Center align text */
 
    margin-left: 16px;
 
  }
 
 
 
 
  .li-ride {
 
    margin-top: 10px;
 
  }
 
 
 
 
  .li-location-info > div {
 
    margin-left:-240px;
 
    font-family: "Times New Roman", Times, serif;
 
  }
 
  .li-additional-details {
 
    text-align: left; /* Align starting line */
 
  }
 
 
 
 
  .li-additional-details > div {
 
  color: #05144c;
 
  font-family: 'Times New Roman', Times, serif;
 
    width:60%;
 
    margin-top: 10px;;
 
    margin-left: 230px;/* Center align text */
 
    font-size: 20px; /* Set font size */
 
    margin-bottom: -20px; /* Add space between each text */
 
  }
 
  .li-hr {
 
    margin-top: 20px;;
 
    border-color:rgb(246, 242, 242); /* Set the border color to grey */
 
  }
 
  .li-book {
 
    background-color: #05144c;
 
    color: white;
 
    border: none;
 
    padding: 20px 20px;
 
    font-size: 16px;
 
  margin-left:550px;
 
    border-radius: 50px;
 
    cursor: pointer;
 
    text-decoration: none;
 
 
 
 
  }
 
  .li-bookk{
 
    margin-top: 40px;
 
    margin-left: 0px;;
    margin-bottom: 50px;
  }
 
  .li-date{
 
    text-align: center;
 
    font-size: 25px;
 
    margin-top: 130px;
 
    font-weight: 600;
 
    font-family: Arial, Helvetica, sans-serif;
 
  }
 
  .li-icon {
 
    margin-right: 10px;
 
    color: grey;
 
  }
 
 
 
 
  .li-question-icon {
 
    color: rgb(39, 39, 182);
 
    filter: blur(1px);
 
  }
 
  .li-ask{
 
    color:rgb(24, 116, 192);
 
  }
 
 
 
 
 
 
  .li-fa{
 
   
 
    color: navy;
 
    margin-right: 20px;
 
    display: flex;
 
    align-items: center;
 
    font-size: 18px;
 
  }
 
 
 
 
  .li-co-travellers {
 
    margin-top: 20px;
 
    margin-left: 16%;
 
    padding: 15px;
 
    width:58vw;
 
 
 
 
  }
 
  .li-co-arrow {
 
    margin-left: 30px;
 
    color: grey;
 
  }
 
  .li-co-traveller {
 
    display: flex;
 
    justify-content: space-between;
 
    align-items: center;
 
    margin-bottom: 10px;
 
  }
 
  .li-co-traveller-image {
 
    width: 50px;
 
    height: 50px;
 
    border-radius: 50%;
 
    margin-left: 10px;
 
  }
 
 
 
 
  .li-co-sect {
 
    flex: 1 0 21%; /* Adjust the width as needed */
 
    margin: 10px;
 
    display: flex;
 
    align-items: center;
 
    text-align: left;
 
    display: block;
 
  }
 
 
 
 
  .li-hrr {
 
    margin-left: 16%;
 
    height: 10px;
 
    border-radius: 5px;
 
    background-color: rgb(191, 192, 192);
 
    border: 1px solid black;
 
    width: 60vw;
 
  }
 
 
 
 
  .li-co {
 
    font-weight: 600;
 
  }
 
 
 
 
  .li-co-traveller-details {
 
    display: flex;
 
    flex-wrap: wrap;
 
    justify-content: space-between;
 
    width: 100%;
 
  }
 
 
 
 
  .li-co-divs {
 
    display: flex;
 
    align-items: center;
 
  }
 
  .li-co-traveller-text {
 
    flex-grow: 1;
 
  }
}
 