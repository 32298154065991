 
/* Main container */
.suam_profile-main {
  display: flex;
  margin-top:0px;
  justify-content: center;
  align-items: center;
  height: 99.8vh;
  background-color: #eeeeee;
}
.suam-head{
  border:1px solid white;
}
 
.suam_complete-profile-text {
  font-size: 19px;
 
  margin-top: 20px;
  margin-left: 900px;
  width: 30%;
}
 
.suam_complete-profile-text a {
  color: #001A4F;
 
}
 
 
.suam_profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-left: 20px;
}
/* Form container */
.suam_profile-form {
  width: 80%; /* Adjust form width */
  max-width: 1000px; /* Limit form width */
  margin-top: 100px;
}
 
/* Form rows */
.suam_profile-form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Space between input groups */
  align-items: center; /* Align items vertically */
  margin-bottom: 40px; /* Increase space between rows */
 
}
 
/* Form groups */
.suam_profile-form-group {
  flex: 0 0 calc(45% - 10px); /* Two items per row with space for margins */
  margin-bottom: 20px; /* Adjust spacing between groups */
}
 
.suam_profile-form-row-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Space between input groups */
  align-items: center; /* Align items vertically */
  margin-bottom: 40px; /* Increase space between rows */
  margin-left: 430px;
}
 
/* Form groups */
.suam_profile-form-group-1 {
  flex: 0 0 calc(45% - 10px); /* Two items per row with space for margins */
  margin-bottom: 20px; /* Adjust spacing between groups */
}
 
.suam_profile-form-group-1 label {
  display: block;
  margin-bottom: 5px;
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif; /* Use Arial font */
 
}
 
.suam_profile-form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif; /* Use Arial font */
 
}
 
/* Inputs */
.suam_profile-form-group input {
  width: 100%; /* Full width */
  padding: 12px;
  border: 1px solid #001A4F; /* Light gray border */
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 10px; /* Adjust spacing */
  font-size: 16px;
}
 
 
/* Form rows */
.suam_edit-form-row {
  display: flex;
  flex-direction: column; /* Display children elements in a column */
  align-items: center; /* Center-align items horizontally */
  /* Increase space between rows */
}
 
/* Update button */
.suam_edit-button {
  padding: 10px 20px; /* Adjust button padding */
  background-color: #001A4F; /* Dark blue background */
  color: #fff; /* White text color */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  height: 55px;
  transition: background-color 0.3s;
  width: 220px; /* Increase button width */
  max-width: 100%; /* Allow button to expand up to 100% width */
  font-size: 19px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0 auto; /* Center button horizontally */
  display: block; /* Ensure button takes full width */
}
 
.suam_edit-button:hover {
  background-color: #7cb2c0; /* Light blue on hover */
}
.profile-image-suam{
  width: 50px;
  height: 50px;
  border-radius: 50px;
 
}
 
/* Responsive design for mobile screens */
@media only screen and (min-width: 320px) and (max-width: 343px) {  
  .suam_profile-main {
     height: 100%;
     width: 100%;
     flex-direction: column;
     align-items: center;
     padding-top: 50px;
     overflow-x: hidden;
     background-color: #eeeeee;
     /* Remove fixed height */
   }
 
   /* .suam_profile-form {
    overflow-x: hidden;
   } */
 
   .suam_complete-profile-text {
     font-size: 15px;
   
     margin-top: -10px;
     margin-left: 50px;
     width: 100%;
     height: 30px;
 
   }
 
   .suam_profile-form-row-1 {
    margin-left: 70px;
  }
 
   .suam_profile-form-row{
       width: 300px;
 
       margin-top: 0px;
   }
 
 
   .suam_profile-image {
     width: 80px;
     height: 80px;
     border-radius: 50px;
   }
 
   .suam_profile-form-profile{
     width: 150px;
   }
 
 
   .suam_profile-form-group input {
     width: 250px; /* Input fields take full width */
     padding: 10px; /* Adjust padding for better appearance */
     box-sizing: border-box; /* Include padding in width calculation */
     border: 1px solid #ccc; /* Add border for better visibility */
     border-radius: 5px; /* Add border-radius for rounded corners */
   }
 
 
   .suam_edit-button {
     width: 200px; /* Edit button takes full width */
     padding: 10px; /* Adjust padding for better appearance */
     background-color: #001A4F; /* Dark blue background */
     color: #fff; /* White text color */
     border: none;
     border-radius: 5px; /* Add border-radius for rounded corners */
     cursor: pointer;
     font-size: 16px;
     transition: background-color 0.3s;
   }
 
   .suam_edit-button:hover {
     background-color: #7cb2c0; /* Light blue on hover */
   }
 }
@media only screen and (min-width: 360px) and (max-width: 374px) {  
  .suam_profile-main {
     height: 100%;
     width: 100%;
     flex-direction: column;
     align-items: center;
     padding-top: 50px;
     overflow-x: hidden;
     background-color: #eeeeee;
     /* Remove fixed height */
   }
 
   .suam_complete-profile-text {
     font-size: 19px;
   
     margin-top: -10px;
     margin-left: 50px;
     width: 100%;
     height: 30px;
 
   }
 
   .suam_profile-form-row-1 {
    margin-left: 70px;
  }
 
   .suam_profile-form-row{
       width: 300px;
 
       margin-top: 0px;
   }
 
 
   .suam_profile-image {
     width: 120px;
     height: 120px;
     border-radius: 100px;
   }
 
   .suam_profile-form-profile{
     width: 150px;
   }
 
 
   .suam_profile-form-group input {
     width: 300px; /* Input fields take full width */
     padding: 10px; /* Adjust padding for better appearance */
     box-sizing: border-box; /* Include padding in width calculation */
     border: 1px solid #ccc; /* Add border for better visibility */
     border-radius: 5px; /* Add border-radius for rounded corners */
   }
 
 
   .suam_edit-button {
     width: 200px; /* Edit button takes full width */
     padding: 10px; /* Adjust padding for better appearance */
     background-color: #001A4F; /* Dark blue background */
     color: #fff; /* White text color */
     border: none;
     border-radius: 5px; /* Add border-radius for rounded corners */
     cursor: pointer;
     font-size: 16px;
     transition: background-color 0.3s;
   }
 
   .suam_edit-button:hover {
     background-color: #7cb2c0; /* Light blue on hover */
   }
 }
@media only screen and (min-width: 375px) and (max-width: 383px) {  
  .suam_profile-main {
     height: 100%;
     width: 100%;
     flex-direction: column;
     align-items: center;
     padding-top: 50px;
     overflow-x: hidden;
     background-color: #eeeeee;
     /* Remove fixed height */
   }
 
   .suam_complete-profile-text {
     font-size: 19px;
   
     margin-top: -10px;
     margin-left: 55px;
     width: 100%;
     height: 30px;
 
   }
 
   .suam_profile-form-row-1 {
    margin-left: 70px;
  }
 
   .suam_profile-form-row{
       width: 300px;
 
       margin-top: 0px;
   }
 
 
   .suam_profile-image {
     width: 120px;
     height: 120px;
     border-radius: 100px;
   }
 
   .suam_profile-form-profile{
     width: 150px;
   }
 
 
   .suam_profile-form-group input {
     width: 300px; /* Input fields take full width */
     padding: 10px; /* Adjust padding for better appearance */
     box-sizing: border-box; /* Include padding in width calculation */
     border: 1px solid #ccc; /* Add border for better visibility */
     border-radius: 5px; /* Add border-radius for rounded corners */
   }
 
 
   .suam_edit-button {
     width: 200px; /* Edit button takes full width */
     padding: 10px; /* Adjust padding for better appearance */
     background-color: #001A4F; /* Dark blue background */
     color: #fff; /* White text color */
     border: none;
     border-radius: 5px; /* Add border-radius for rounded corners */
     cursor: pointer;
     font-size: 16px;
     transition: background-color 0.3s;
   }
 
   .suam_edit-button:hover {
     background-color: #7cb2c0; /* Light blue on hover */
   }
 }
@media only screen and (min-width: 390px) and (max-width: 411px) {  
  .suam_profile-main {
     height: 100%;
     width: 100%;
     flex-direction: column;
     align-items: center;
     padding-top: 50px;
     overflow-x: hidden;
     background-color: #eeeeee;
     /* Remove fixed height */
   }
 
   .suam_complete-profile-text {
     font-size: 19px;
   
     margin-top: -10px;
     margin-left: 70px;
     width: 100%;
     height: 30px;
 
   }
 
   .suam_profile-form-row-1 {
    margin-left: 80px;
   }
 
   .suam_profile-form-row{
       width: 300px;
 
       margin-top: 0px;
   }
 
 
   .suam_profile-image {
     width: 120px;
     height: 120px;
     border-radius: 100px;
   }
 
   .suam_profile-form-profile{
     width: 150px;
   }
 
 
   .suam_profile-form-group input {
     width: 300px; /* Input fields take full width */
     padding: 10px; /* Adjust padding for better appearance */
     box-sizing: border-box; /* Include padding in width calculation */
     border: 1px solid #ccc; /* Add border for better visibility */
     border-radius: 5px; /* Add border-radius for rounded corners */
   }
 
 
   .suam_edit-button {
     width: 200px; /* Edit button takes full width */
     padding: 10px; /* Adjust padding for better appearance */
     background-color: #001A4F; /* Dark blue background */
     color: #fff; /* White text color */
     border: none;
     border-radius: 5px; /* Add border-radius for rounded corners */
     cursor: pointer;
     font-size: 16px;
     transition: background-color 0.3s;
   }
 
   .suam_edit-button:hover {
     background-color: #7cb2c0; /* Light blue on hover */
   }
 }
@media only screen and (min-width: 412px) and (max-width: 767px) {  
 .suam_profile-main {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    overflow-x: hidden;
    background-color: #eeeeee;
    /* Remove fixed height */
  }
 
  .suam_complete-profile-text {
    font-size: 19px;
   
    margin-top: -10px;
    margin-left: 90px;
    width: 100%;
    height: 40px;
 
  }
 
  .suam_profile-form-row-1 {
    margin-left: 90px;
  }
 
  .suam_profile-form-row{
      width: 300px;
 
      margin-top: 0px;
  }
 
 
  .suam_profile-image {
    width: 120px;
    height: 120px;
    border-radius: 100px;
  }
 
  .suam_profile-form-profile{
    width: 150px;
  }
 
 
  .suam_profile-form-group input {
    width: 300px; /* Input fields take full width */
    padding: 10px; /* Adjust padding for better appearance */
    box-sizing: border-box; /* Include padding in width calculation */
    border: 1px solid #ccc; /* Add border for better visibility */
    border-radius: 5px; /* Add border-radius for rounded corners */
  }
 
 
  .suam_edit-button {
    width: 200px; /* Edit button takes full width */
    padding: 10px; /* Adjust padding for better appearance */
    background-color: #001A4F; /* Dark blue background */
    color: #fff; /* White text color */
    border: none;
    border-radius: 5px; /* Add border-radius for rounded corners */
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
 
  .suam_edit-button:hover {
    background-color: #7cb2c0; /* Light blue on hover */
  }
}
@media only screen and (min-width: 768px) and (max-width: 799px) {  
 
  .suam_profile-form-row-1 {
    margin-left: 250px;
  }
 
  .suam_complete-profile-text {
    font-size: 25px;
     
    margin-top: -150px;
    margin-left: 300px;
    width: 100%;
    height: 50px;
 
  }
}
 
 
@media only screen and (min-width: 800px) and (max-width: 852px) {  
 
  .suam_profile-form-row-1 {
    margin-left: 270px;
  }
 
  .suam_complete-profile-text {
    font-size: 25px;
     
    margin-top: -200px;
    margin-left: 350px;
    width: 100%;
    height: 100px;
 
  }
}
@media only screen and (min-width: 884px) and (max-width: 1279px) {  
 
  .suam_profile-form-row-1 {
    margin-left: 300px;
  }
 
  .suam_complete-profile-text {
    font-size: 25px;
     
    margin-top: -200px;
    margin-left: 400px;
    width: 100%;
    height: 100px;
 
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {  
 
/* Main container */
.suam_profile-main {
  display: flex;
  margin-top:0px;
  justify-content: center;
  align-items: center;
  height: 99.8vh;
  background-color: #eeeeee;
}
.suam-head{
  border:1px solid white;
}
 
.suam_complete-profile-text {
  font-size: 19px;
 
  margin-top: 20px;
  margin-left: 800px;
  width: 30%;
}
 
.suam_complete-profile-text a {
  color: #001A4F;
 
}
 
 
.suam_profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-left: 20px;
}
/* Form container */
.suam_profile-form {
  width: 80%; /* Adjust form width */
  max-width: 1000px; /* Limit form width */
  margin-top: 100px;
}
 
/* Form rows */
.suam_profile-form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Space between input groups */
  align-items: center; /* Align items vertically */
  margin-bottom: 40px; /* Increase space between rows */
}
 
/* Form groups */
.suam_profile-form-group {
  flex: 0 0 calc(45% - 10px); /* Two items per row with space for margins */
  margin-bottom: 20px; /* Adjust spacing between groups */
}
 
.suam_profile-form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif; /* Use Arial font */
 
}
 
/* Inputs */
.suam_profile-form-group input {
  width: 100%; /* Full width */
  padding: 12px;
  border: 1px solid #001A4F; /* Light gray border */
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 10px; /* Adjust spacing */
  font-size: 16px;
}
 
 
/* Form rows */
.suam_edit-form-row {
  display: flex;
  flex-direction: column; /* Display children elements in a column */
  align-items: center; /* Center-align items horizontally */
  /* Increase space between rows */
}
 
/* Update button */
.suam_edit-button {
  padding: 10px 20px; /* Adjust button padding */
  background-color: #001A4F; /* Dark blue background */
  color: #fff; /* White text color */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  height: 55px;
  transition: background-color 0.3s;
  width: 220px; /* Increase button width */
  max-width: 100%; /* Allow button to expand up to 100% width */
  font-size: 19px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0 auto; /* Center button horizontally */
  display: block; /* Ensure button takes full width */
}
 
.suam_edit-button:hover {
  background-color: #7cb2c0; /* Light blue on hover */
}
.profile-image-suam{
  width: 50px;
  height: 50px;
  border-radius: 50px;
 
}
}
 