/* Citybooked.css */

.lca-containerr {
  border: 1px solid white;
}

.lca-container {
  margin: 20px auto;

  margin-top: 150px;

  max-width: 1200px;

  padding: 20px;

  background-color: #f0f0f0;

  border: 1px solid #ccc;

  border-radius: 5px;
}

.lca-tab-container {
  margin-bottom: 20px;

  /* border: 1px solid black; */

  max-height: 500px;
}

.lca-tab {
  padding: 10px 20px;

  cursor: pointer;

  background-color: #ddd;

  border: 1px solid #ac3232;

  border-top-left-radius: 5px;

  border-top-right-radius: 5px;

  margin-right: 5px;

  color: #333;

  height: 40px;
  /* Set the height for the tabs */

  line-height: 40px;
  /* Center the text vertically */
}

.lca-tab.active {
  background-color: #05144c;

  color: white;
}

/* .tab-content {
 
    max-height: 400px;
 
    overflow-y: auto;
 
  } */

.super-admin-cities-table {
  /* border: 3px solid black; */

  max-height: 430px;

  overflow-y: scroll;

  overflow-x: auto;

  margin-top: 1px;

  border: 1px solid #ddd;
}

.lca-styled-table {
  width: 100%;

  border-collapse: collapse;

  margin-top: 1px;

  /* border: 1px solid #e70a0a; */
}

.lca-styled-table .SA-tahe {
  padding: 8px;
  text-align: center;
  border: 1px solid white;
  position: sticky;
}

.lca-styled-table th,
.lca-styled-table td {
  border: 1px solid #ddd;

  padding: 8px;

  text-align: left;

  position: sticky;

  z-index: 1;
}

.lca-styled-table thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #05144c;
  color: white;
  /*layanu*/
  border: 0px solid white;
}

.lca-styled-table th {
  background-color: #05144c;

  color: white;
}

.lca-delete-btn,
.lca-edit-btn,
.lca-save-btn {
  padding: 8px 12px;

  margin-right: 5px;

  border: none;

  cursor: pointer;

  background-color: #05144c;

  color: white;

  border-radius: 3px;

  transition: background-color 0.3s;

  width: 100%;

  max-width: 150px;
}

.lca-delete-btn{
  padding: 8px 12px;

  margin-right: 5px;

  border: none;

  cursor: pointer;

  background-color: red;

  color: white;

  border-radius: 3px;

  transition: background-color 0.3s;

  width: 100%;

  max-width: 150px;
}
.lca-delete-btn:hover{
  background-color: red;
}

.lca-edit-btn:hover,
.lca-save-btn:hover {
  background-color: #333;
}

.lca-edit-delete-container {
  display: flex;

  align-items: center;
}

.lca-edit-delete-container button {
  margin-right: 5px;
}

.column-actions {
  text-align: center;
}

.column-city-id,
.column-location-id,
.column-location-city-id {
  width: 10%;
}

.column-city-name,
.column-location-name {
  width: 50%;
}

.column-actions {
  width: 20%;
}

/* Responsive */

@media (320px <=width <=359px) {
  .super-admin-cities-table {
    max-height: 330px;
  }

  .lca-tab-container {
    margin-bottom: 10px;

    max-height: 400px;
  }
  .super-admin-cities-table table{
 
    width: 950px;
  }
}

@media (360px <=width <=374px) {
  .super-admin-cities-table {
    max-height: 330px;
  }
  .super-admin-cities-table table{
 
    width: 950px;
  }
  .lca-tab-container {
    margin-bottom: 10px;

    max-height: 400px;
  }
  .super-admin-cities-table {
    max-height: 330px;
  }
}

@media (375px <=width <=413px) {
  .super-admin-cities-table table{
 
    width: 950px;
  }

  .lca-tab-container {
    margin-bottom: 10px;

    max-height: 400px;
  }
}
@media (390px <=width <=411px) {
  .super-admin-cities-table {
    max-height: 330px;
  }

  .lca-tab-container {
    margin-bottom: 10px;

    max-height: 400px;
  }
  .super-admin-cities-table table{
 
    width: 950px;
  }
}
@media (412px <=width <=427px) {
  .super-admin-cities-table {
    max-height: 330px;
  }

  .lca-tab-container {
    margin-bottom: 10px;

    max-height: 400px;
  }
  .super-admin-cities-table table{
 
    width: 950px;
  }
}

@media (428px <=width <=568px) {
  .super-admin-cities-table {
    max-height: 350px;
  }

  .lca-tab-container {
    margin-bottom: 10px;

    max-height: 450px;
  }
  .super-admin-cities-table table{
 
    width: 950px;
  }
}

@media (768px <=width <=799px) {
  .super-admin-cities-table table{
 
    width: 950px;
  }
}
@media (800px <=width <=819px) {
  .super-admin-cities-table table{
 
    width: 950px;
  }
}

@media (820px <=width <=883px) {
  .super-admin-cities-table table{
 
    width: 950px;
  }

}


@media (884px <=width <=1024px) {
  .super-admin-cities-table table{
 
    width: 950px;
  }
}

@media (1024px <=width <=1280px) {}