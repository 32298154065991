 
* {
  box-sizing: border-box;
  font-family: sans-serif;
 
}
.rg_background {
  background-image: url('../Images/blueinn.png'); 
  background-size: cover;
  background-position: center;

 margin-top: -60px;
  justify-content: center;
  align-items: center;
}
 
.rg_register-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
 
}
 
.rg_register {
  width: 1200px;
  height: 750px; 
 
  border-radius: 20px;
  overflow: hidden;
  display: flex;
 
}
.rg_register h3 {
  color: #FFF;
  text-align: center;
  font-weight: normal;
  font-size: 40px;
  margin-bottom: 30px;
}
 
 
.rg_register h2 {
  color: #FFF;
  text-align: center;
  font-weight: normal;
  font-size: 30px;
  margin-bottom: 30px;
}
 
 
.rg_animation1 {
  width: 50%;
  margin-top: 50px;
  height: 660px;
  background: url(https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExeWkzbHhzeWdsajE1cjJhbm5yZncxNHdoMHYwMjdpN2d3MDh1cTdyZiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o7WIFkzzJiyRNIBbO/giphy.gif) center center no-repeat;
  background-size: cover;
}
.rg_heading {
  margin-top: 50px;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.rg_form1 {
  width: 50%;
  margin-top: 50px;
  height: 660px;
  padding: 30px;
  background-color: #001f3f; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}
 
.rg_form1 h2 {
  color: #FFF;
  text-align: center;
  font-weight: normal;
  font-size: 35px;
  margin-bottom: 30px;
}
 
.rg_form1 input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  background: rgba(255,255,255,.5);
  border: 1px solid rgba(255,255,255,.1);
  padding: 0 15px;
  color: #FFF;
  border-radius: 5px;
  font-size: 20px;
}
 
.rg_form1 input:focus {
  border: 1px solid rgba(255,255,255,.8);
  outline: none;
}
 
::-webkit-input-placeholder {
  color: #DDD;
}
 
.rg_signup {
  margin-top: 50px;
  width: 50%;
  background: #1dffdf;
  color: #444;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  margin: 10px;
  margin-left: 140px;
  transition: 0.5s;
}
 
.rg_signup:hover {
  background-color: #001A4F;
  color: #CCC;
}
 
.error-message{
  color: #CCC;
}

 
.signup-link {
  color: #1dffdf; 
  text-decoration: none;
}
 
.signup-link:hover {
  text-decoration: underline; 
}
 
@media (max-width: 768px) {
  .rg_register {
    width: 100%;
   
    height: auto;
  }
 
  .rg_animation1 {
    width: 100%;
    height: auto;
  }
 
  .rg_form1 {
    width: 100%;
   
  }
 
  .rg_signup {
    width: 70%;
    margin: 10px auto;
   
  }
}