@import url('https://fonts.googleapis.com/css2?family=Buda:wght@300&family=Catamaran:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:wght@300&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

  .lr-background-image-container {
    background-image: url('../Images/ld.png');
    background-size: cover; /* This ensures that the background image covers the entire element */
    background-position: center; /* This centers the background image */
    height: 100vh; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color */
    font-size: 2rem; /* Adjust font size */
    text-align: center; /* Center-align text */
  }
 
  .lr-card {
    position: absolute;
    top: 50%;
    left: 16%;
    transform: translate(-50%, -50%);
    background-color: rgba(203, 193, 193, 0.5); /* Semi-transparent white background */
    padding: 20px;
    color: black;
    text-align: center;
    border-radius: 10px;
    width: 300px;
    height: 350px;
    padding-top: 50px;
  }
 
  .lr-color{
    font-size: 17px;
  }
  p {
    text-align: left; /* Align the text to the left */
    text-indent: 0; /* Remove any indentation */
  }
  .lr-icon-input {
    display: flex;
    align-items: center;
   
  }
  
  .lr-icon-input input {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    border: none; /* Remove border */
    outline: none;
    color: black; /* Remove outline */
  }
 .lr-input-field {
  width: 100px;
  color: black;
  height: 40px;
  
 }
  .lr-card h2 {
    margin: 10px 0;
  }
 
  .lr-card hr {
    width: 50%;
    margin: 10px auto;
  }
  .lr-save{
    font-family: "Catamaran", sans-serif;
    color:rgb(64, 61, 61);
    font-size: 30px;
    margin-top: 6px;
  }
  .lr-btn {
    color: white;
    border-radius: 5px;
    width: 200px;
    height: 40px; /* Adjusted height for better visualization */
    background-color: #05144c;
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Added to ensure the link is displayed as a block */
    line-height: 40px; /* Centering the text vertically */
  }
  
  .lr-btn:hover {
    background-color: #05144c;
   
  }
  
  .lr-white{
    background-color: white;
    height:300px;
    width:100%;
    position:relative;
    margin-top: 70px;
  }
  .lr-text {
    font-size: 34px;
    color: black;
    margin-top: -30px;
    text-align: center;
    font-family: "Catamaran", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
  .lr-flex-container {
    display: flex;
    width: 85%;
    justify-content: space-between;
    margin-left: 120px;
  }
 
  .lr-flex-item {
    width: 30%;
    padding: 20px;
    text-align: center;
    margin-left: 47px;
  }
 
  
  .lr-flex-item1{
    text-align: center;
    width: 30%;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
  }

  .lr-flex-item2{
    text-align: center;
    width: 30%;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
  }
  .lr-flex-item3{
    text-align: center;
    width: 30%;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
  }

  .lr-flex-containerr {
    display: flex;
    width:100%;
   
    justify-content: space-between; /* Adjust as needed */
  }
 .lr-parra-5{
  font-size: 23px;
  font-family: "Catamaran", sans-serif;
 }
  .lr-flex-itemm {
    width: 30%;
    padding: 20px;
    text-align: center;
    margin-left: 143px;
  }

  .lr-text-create-1,.lr-text-create-2, .lr-text-create-3{
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
  }
 
  .lr-textsection{
    background-color: white;
    height:100px;
    width:80%;
    border:1px solid rgb(244, 240, 240);
    border-radius: 5px;
    margin-left: 10%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    margin-top: 37px;
  }
  .lr-color{
    color:black;
    font-family: "Catamaran", sans-serif;
  }
  .lr-texx{
    font-family: "Catamaran", sans-serif;
  }

  .lr-text-2{
    width: 300px;
    margin-right: 170px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }
  .lr-text-3{
    width: 300px;
    margin-right: 50px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }
  .lr-text-4{
    width: 300px;
    margin-right: 120px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }

  .lr-color-9{
    font-family: "Catamaran", sans-serif;
    margin-left: 42px;
  }
  .lr-icon-mdoutli{
    margin-bottom: 10px;
    font-size: 30px;
    margin-left: 0px;
    color: black;
  }
  
  .lr-colorr{
    color:black;
    font-family: "Catamaran", sans-serif;
  }
  .lr-textt-1{
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 103px;
    margin-bottom: 10px;
  }
  .lr-textt-2{
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 36px;
    margin-bottom: 10px;
  }
  .lr-textt-3{
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 102px;
    margin-bottom: 10px;
  }
 
  .lr-section1{
    background-color: #f0e4e4;
    width: 100%;
    height: 340px;
    position: relative;
    z-index: -1;
    display: flex;
    margin-top: 0px;
    border-radius: 36px;
  }
  .lr-people{
    margin-top: 11px;
    margin-left: -4px;

  }
  .lr-carousel{
    border:1px solid black;
    width:300px;
    height:300px;
    margin-top:100px;
    margin-left: 200px;
  }
 
  .lr-video-player{
   border-radius: 10px;
    width:600px;
    height:auto;
    margin-top: 70px;
    margin-left: 100px;
  }
  .lr-texx {
    font-size: 50px;
    margin-top: 50px;
    text-align: center;
    margin-left: 6%;
    color:black;
 
  }
  .lr-sect{
    display:flex;
  }
  .lr-horizontal-container {
    display: block;
  }
 
  .lr-horizontal-item {
    display:flex;
 
 
 
  }
  .lr-horizontal-item {
    display: flex;
    width:450px;
    align-items: center; /* Align items vertically */
    gap: 20px; /* Add a gap of 20px between the icon and the text */
  }
  .lr-test{
    text-align: center;
  }
  .lr-whitte{
    margin-top:50px;
    margin-left:300px;
  }
  .lr-ico{
    font-size:50px;
    color:black;
    margin-bottom: 50px;
    margin-right: 0px;
  }
  .lr-horizontal-container{
    margin-left: -200px;
  }
  .lr-icoo{
    font-size:100px;
    margin-bottom: 70px;
    color:black;
 
  }
  .lr-icooo{
    font-size:80px;
    color:black;
    margin-bottom: 50px;
  }
  .lr-secttext{
    margin-top:110px;
    background-color: #08144c;
    height: 310px;
  }
  .lr-test{
    font-size: 40px;
    color: #fff;
    font-family: "Catamaran", sans-serif;
    font-weight: 600px;
    
    margin-bottom: 20px;
  }
  .lr-colorr{
    color: #fff;
  }
  
  
  .lr-text-atyour-1{
    color: #fff;
    margin-right: 14px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .lr-text-atyour-2{
    color: #fff;
    margin-right: 20px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .lr-text-atyour-3{
    color: #fff;
    margin-right: 25px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .lr-icon-just-1{
    font-size: 40px;
    margin-right:31px;
    color: #fff;
      }
  .lr-icon-just-2{
    font-size: 40px;
    margin-right:35px;
    color: #fff;
      }
  .lr-icon-just-3{
font-size: 40px;
margin-right:35px;
color: #fff;
  }
  .lr-parra{
    font-size: 20px;
    text-align: left;
    color:white
   
;    
    letter-spacing: normal;
    font-family: "Catamaran", sans-serif;
  }
  .lr-slider-image{
    margin-top: 32px;
  }
  .lr-slider{
    margin-left: 115px;
  }
  .lr-sectbod{
    border: 1px solid white;
    margin-top: 110px;
    margin-left: 208px;
    width: 400px;
    height: 130px;
    padding-top: 10px;
    background-color: rgb(245, 237, 237);
    border-radius: 5px;
  }

  .lr-flex-item5{
    text-align: center;
    width: 30%;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
  }
  .lr-flex-item6{
    text-align: center;
    width: 30%;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
  }
  .lr-flex-item7{
    text-align: center;
    width: 30%;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
  }
  .fade-in {
    animation: fadeInLeft 0.5s ease-in-out forwards;
  }
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }
    100% {
      opacity: 2;
      transform: translateX(0);
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 343px) {  
   .lr-main-container{
    
      overflow: hidden;
    
    
   }
    .lr-background-image-container {
      background-size: cover; /* This ensures that the background image covers the entire element */
      background-position: center; /* This centers the background image */
      height: 80vh; /* Adjust the height as needed */
      display: flex;
      justify-content: center;
      align-items: center;
      color: white; /* Text color */
      font-size: 2rem; /* Adjust font size */
      text-align: center; /* Center-align text */
      overflow: hidden;
    }
   
    .lr-card {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(203, 193, 193, 0.5);
      padding: 20px;
      color: black;
      text-align: center;
      border-radius: 10px;
      width: 300px;
      height: 320px;
      padding-top: 50px;
    }
    .lr-text-2{
     font-size: 15px;
     
     
    }

    
 
    .lr-flex-containerr {

      display: flex;
      width: 47%;
      justify-content: space-between;
     
      justify-content: space-between; /* Adjust as needed */
    }
     
    .lr-textsection{
      overflow: hidden;
      background-color: white;
        height: 240px;
        width: 81%;
        border: 1px solid rgb(244, 240, 240);
        border-radius: 20px;
        margin-left: 10%;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
        margin-top: 85px;
    }
   

    .lr-horizontal-container{
      display: block;
      margin-left: -300px;
    }

    .lr-ico{
      font-size:40px;
      color:black;
      margin-bottom: 100px;
      margin-left: 10px;
    }
  
    .lr-icoo{
      margin-left: 5px;
    
      margin-bottom: 120px;
      color:black;
   
    }
    .lr-icooo{
      margin-left: 5px;
      color:black;
      margin-bottom: 60px;
    }
    .lr-text-create-1 {
      margin-left: -10px;

      font-size: 20px;
      font-family: "Catamaran", sans-serif;
    }
    .lr-text-create-2 {
      
    width: 150px;
    margin-top: 30px;
      font-size: 20px;
      margin-left: -10px;
      margin-right: 0px;
      font-family: "Catamaran", sans-serif;
    }
    .lr-text-create-3 {
      margin-top: 50px;

      width: 200x;
      margin-left: -10px;
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
    }
 
    .lr-horizontal-item {
      height: 140px;
      display: flex;
        width: 310px;
        align-items: center;
        gap: 20px;
    }
    .lr-secttext{
      margin-top: 10px;
      background-color: #08144c;
      height: 970px;
      overflow: hidden;
    }

    
  

    .lr-flex-item1{
      width: 300px;
      padding: 20px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 20px;
      margin-top: 10px;

    }
    .lr-flex-item2{
      width: 300px;
      padding: 20px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 20px;
      margin-top: 10px;

    }
    .lr-flex-item3{
      width: 300px;
      padding: 22px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 20px;
      margin-top: 10px;

    }
    p {
      text-align: left; /* Align the text to the left */
      text-indent: 0; /* Remove any indentation */
    }
    .lr-icon-input {
      display: flex;
      align-items: center;
      margin-top: -10px;
     
    }
   
    .lr-icon-input input {
      flex: 1;
      padding: 10px;
      margin-left: 10px;
      border: none; /* Remove border */
      outline: none;
      color: black; /* Remove outline */
    }
   .lr-input-field {
    color: black;
   }
    .lr-card h2 {
      margin: 10px 0;
    }
   
    .lr-card hr {
      width: 50%;
      margin: 10px auto;
    }
    .lr-save{
      font-family: "Catamaran", sans-serif;
      color:rgb(64, 61, 61);
      font-size: 30px;
      margin-top: 6px;
    }
    .lr-btn{
      color:white;
      border-radius: 5px;
      width: 200px;
      height:40px;
      font-family: "Catamaran", sans-serif;
      background-color:rgb(7, 52, 125);
    }
    .lr-btn:hover {
      background-color: rgb(7, 52, 125); /* Darker Green */
    }
    .lr-white{
      background-color: white;
      height:820px;
      width:100%;
      position:relative;
      margin-top: 70px;
   
    }
    .lr-text {
      font-size: 25px;
      color: black;
      margin-top: -40px;
      text-align: center;
      font-family: "Catamaran", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
    }
    .lr-flex-container {
      width: 80px;
      display: grid;
 
      justify-content: space-between;
      margin-left: -10px;
    }
    .lr-color{
      font-size: 17px;
     
    }
  
    .lr-flex-item {
      width: 73%;
        padding: 20px;
        text-align: center;
        margin-left: -3px;
        margin-top: 0px;
    }
 

 
   .lr-parra-5{
    font-size: 23px;
    font-family: "Catamaran", sans-serif;
   }
    .lr-flex-itemm {
      width: 30%;
      padding: 20px;
      text-align: center;
      margin-left: 143px;
    }
  
  
  
    .lr-color{
      margin-left: -10px;

      color:black;
      font-family: "Catamaran", sans-serif;
    }
    .lr-texx{
      font-family: "Catamaran", sans-serif;
    }
    .lr-text-2{
      margin-left: -30px;
      margin-bottom: 10px;
      font-size: 25px;
      font-family: "Catamaran", sans-serif;
      color: black;
    }
    .lr-text-3{
      margin-left: -20px;
      margin-bottom: 10px;
      font-size: 25px;
      font-family: "Catamaran", sans-serif;
      color: black;
    }
    .lr-text-4{
      margin-left: -30px;
      margin-bottom: 10px;
      font-size: 25px;
      font-family: "Catamaran", sans-serif;
      color: black;
    }
  
    .lr-color-9{
      font-family: "Catamaran", sans-serif;
      margin-left: 0px;
    }
    .lr-icon-mdoutli{
      font-size: 40px;
      margin-right: 0px;
      color: black;
    }
    
    .lr-colorr{
      color:black;
      font-family: "Catamaran", sans-serif;
    }
    .lr-textt-1{
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
      font-weight: 700;
      color: black;
      margin-right: 103px;
      margin-bottom: 10px;
    }
    .lr-textt-2{
     
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
      font-weight: 700;
      color: black;
      margin-right: 36px;
      margin-bottom: 10px;
    }
    .lr-textt-3{
     
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
      font-weight: 700;
      color: black;
      margin-right: 102px;
      margin-bottom: 10px;
    }
   
    .lr-section1{
      overflow: hidden;

      background-color: #f0e4e4;
      width: 99%;
      height:360px;
      position: relative;
      z-index: -1;
      display: flex;
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 36px;
    }
    .lr-people{
      margin-top: 11px;
      margin-left: -4px;
  
    }
    .lr-carousel{
      border:1px solid black;
      width:300px;
      height:300px;
      margin-top:100px;
      margin-left: 200px;
    }
   
    .lr-video-player{
      border-radius: 10px;
      width: 300px;
      height: auto;
      margin-top: 10px;
      margin-left: 10px;
    }
    .lr-texx {
      font-size: 25px;
      margin-top: 10px;
      text-align: center;
      margin-left: 3%;
      color:black;
      width: 300px;
      overflow: hidden;
   
    }
    .lr-sect{
      overflow: hidden;
      display: grid;
    }
   
   
    
    .lr-test{
      text-align: center;
    }
    .lr-whitte{
      margin-top:20px;
      margin-left:300px;
      height: 440px;
    }
  
   
  
    .lr-test{
      font-size: 25px;
      color: #fff;
      font-family: "Catamaran", sans-serif;
      font-weight: 600px;
      
      margin-bottom: 0px;
    }
    .lr-colorr{
      font-size: 17px;
      color: #fff;
    }
    
    
    .lr-text-atyour-1{
      color: #fff;
      width: 180px;
      margin-right: 110px;
      margin-bottom: 10px;
      font-size: 20px;
    }
  
    .lr-text-atyour-2{
      width: 240px;

      color: #fff;
      margin-right: 60px;
      margin-bottom: 10px;
      font-size: 20px;
    }
    .lr-text-atyour-3{
      width: 230px;

      color: #fff;
      margin-right: 70px;
      margin-bottom: 10px;
      font-size: 20px;
    }
    .lr-icon-just-1{
      font-size: 40px;
margin-bottom: 10px;
      margin-right:30px;
      color: #fff;
        }
    .lr-icon-just-2{
      margin-bottom: 10px;

      font-size: 40px;
      margin-right:35px;
      color: #fff;
        }
    .lr-icon-just-3{
      margin-bottom: 10px;

  font-size: 40px;
  margin-right:35px;
  color: #fff;
    }
    .lr-parra{
      font-size: 20px;
      text-align: left;
      color:white
     
  ;    
      letter-spacing: normal;
      font-family: "Catamaran", sans-serif;
    }
    .lr-slider-image{
      border-radius: 10px;

      margin-top: 22px;
        width: 295px;
        margin-left: 8px;
    }
    .lr-slider{
      margin-left: 3px;
    }
    .lr-sectbod{
      border: 1px solid white;
      margin-top:170px;
      margin-left: -290px;
      width: 290px;
      height: 162px;
      padding-top: 10px;
      background-color: rgb(245, 237, 237);
      border-radius: 5px;
    }
  
    .fade-in {
      animation: fadeInLeft 0.5s ease-in-out forwards;
    }
  
  }
  @media only screen and (min-width: 360px) and (max-width: 374px) {  
    .lr-background-image-container {
      background-size: cover; /* This ensures that the background image covers the entire element */
      background-position: center; /* This centers the background image */
      height: 80vh; /* Adjust the height as needed */
      display: flex;
      justify-content: center;
      align-items: center;
      color: white; /* Text color */
      font-size: 2rem; /* Adjust font size */
      text-align: center; /* Center-align text */
      overflow: hidden;
    }
   
    .lr-card {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(203, 193, 193, 0.5);
      padding: 20px;
      color: black;
      text-align: center;
      border-radius: 10px;
      width: 300px;
      height: 350px;
      padding-top: 50px;
    }
    .lr-text-2{
     font-size: 15px;
     
     
    }

    
 
    .lr-flex-containerr {

      display: flex;
      width: 47%;
      justify-content: space-between;
     
      justify-content: space-between; /* Adjust as needed */
    }
     
    .lr-textsection{
      overflow: hidden;
      background-color: white;
        height: 240px;
        width: 81%;
        border: 1px solid rgb(244, 240, 240);
        border-radius: 20px;
        margin-left: 10%;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
        margin-top: 35px;
    }
   

    .lr-horizontal-container{
      display: block;
      margin-left: -300px;
    }

    .lr-ico{
      font-size:40px;
      color:black;
      margin-bottom: 100px;
      margin-left: 20px;
    }
  
    .lr-icoo{
      margin-left: 10px;
    
      margin-bottom: 120px;
      color:black;
   
    }
    .lr-icooo{
      margin-left: 10px;
      color:black;
      margin-bottom: 60px;
    }
    .lr-text-create-1 {
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
    }
    .lr-text-create-2 {
      
    width: 150px;
    margin-top: 30px;
      font-size: 20px;
      margin-left: -0px;
      margin-right: 0px;
      font-family: "Catamaran", sans-serif;
    }
    .lr-text-create-3 {
      margin-top: 30px;

      width: 200x;
      margin-left: 0px;
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
    }
 
    .lr-horizontal-item {
      height: 140px;
      display: flex;
        width: 355px;
        align-items: center;
        gap: 20px;
    }
    .lr-secttext{
      margin-top: 10px;
      background-color: #08144c;
      height: 920px;
      overflow: hidden;
    }

    
    .lr-flex-item1{
      width: 465px;
      padding: 20px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 180px;
      margin-top: 10px;
    }

    .lr-flex-item1{
      width: 340px;
      padding: 20px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 20px;
    }
    .lr-flex-item2{
      width: 340px;
      padding: 20px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 20px;
      margin-top: 10px;

    }
    .lr-flex-item3{
      width: 340px;
      padding: 22px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 20px;
      margin-top: 10px;

    }
    p {
      text-align: left; /* Align the text to the left */
      text-indent: 0; /* Remove any indentation */
    }
    .lr-icon-input {
      display: flex;
      align-items: center;
     
    }
   
    .lr-icon-input input {
      flex: 1;
      padding: 10px;
      margin-left: 10px;
      border: none; /* Remove border */
      outline: none;
      color: black; /* Remove outline */
    }
   .lr-input-field {
    color: black;
   }
    .lr-card h2 {
      margin: 10px 0;
    }
   
    .lr-card hr {
      width: 50%;
      margin: 10px auto;
    }
    .lr-save{
      font-family: "Catamaran", sans-serif;
      color:rgb(64, 61, 61);
      font-size: 30px;
      margin-top: 6px;
    }
    .lr-btn{
      color:white;
      border-radius: 5px;
      width: 200px;
      height:40px;
      font-family: "Catamaran", sans-serif;
      background-color:rgb(7, 52, 125);
    }
    .lr-btn:hover {
      background-color: rgb(7, 52, 125); /* Darker Green */
    }
    .lr-white{
      background-color: white;
      height:820px;
      width:100%;
      position:relative;
      margin-top: 70px;
   
    }
    .lr-text {
      font-size: 25px;
      color: black;
      margin-top: -40px;
      text-align: center;
      font-family: "Catamaran", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
    }
    .lr-flex-container {
      width: 80px;
      display: grid;
 
      justify-content: space-between;
      margin-left: -10px;
    }
    .lr-color{
      font-size: 17px;
     
    }
  
    .lr-flex-item {
      width: 73%;
        padding: 20px;
        text-align: center;
        margin-left: -3px;
        margin-top: 0px;
    }
 

 
   .lr-parra-5{
    font-size: 23px;
    font-family: "Catamaran", sans-serif;
   }
    .lr-flex-itemm {
      width: 30%;
      padding: 20px;
      text-align: center;
      margin-left: 143px;
    }
  
  
  
    .lr-color{
      color:black;
      font-family: "Catamaran", sans-serif;
    }
    .lr-texx{
      font-family: "Catamaran", sans-serif;
    }
    .lr-text-2{
      margin-left: -30px;
      margin-bottom: 10px;
      font-size: 25px;
      font-family: "Catamaran", sans-serif;
      color: black;
    }
    .lr-text-3{
      margin-left: -20px;
      margin-bottom: 10px;
      font-size: 25px;
      font-family: "Catamaran", sans-serif;
      color: black;
    }
    .lr-text-4{
      margin-left: -30px;
      margin-bottom: 10px;
      font-size: 25px;
      font-family: "Catamaran", sans-serif;
      color: black;
    }
  
    .lr-color-9{
      font-family: "Catamaran", sans-serif;
      margin-left: 0px;
    }
    .lr-icon-mdoutli{
      font-size: 40px;
      margin-right: 0px;
      color: black;
    }
    
    .lr-colorr{
      color:black;
      font-family: "Catamaran", sans-serif;
    }
    .lr-textt-1{
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
      font-weight: 700;
      color: black;
      margin-right: 103px;
      margin-bottom: 10px;
    }
    .lr-textt-2{
     
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
      font-weight: 700;
      color: black;
      margin-right: 36px;
      margin-bottom: 10px;
    }
    .lr-textt-3{
     
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
      font-weight: 700;
      color: black;
      margin-right: 102px;
      margin-bottom: 10px;
    }
   
    .lr-section1{
      overflow: hidden;

      background-color: #f0e4e4;
      width: 99%;
      height: 407px;
      position: relative;
      z-index: -1;
      display: flex;
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 36px;
    }
    .lr-people{
      margin-top: 11px;
      margin-left: -4px;
  
    }
    .lr-carousel{
      border:1px solid black;
      width:300px;
      height:300px;
      margin-top:100px;
      margin-left: 200px;
    }
   
    .lr-video-player{
      border-radius: 10px;
      width: 340px;
      height: auto;
      margin-top: 10px;
      margin-left: 10px;
    }
    .lr-texx {
      font-size: 25px;
      margin-top: 10px;
      text-align: center;
      margin-left: 1%;
      color:black;
      width: 350px;
      overflow: hidden;
   
    }
    .lr-sect{
      overflow: hidden;
      display: grid;
    }
   
   
    
    .lr-test{
      text-align: center;
    }
    .lr-whitte{
      margin-top:20px;
      margin-left:300px;
    }
  
   
  
    .lr-test{
      font-size: 30px;
      color: #fff;
      font-family: "Catamaran", sans-serif;
      font-weight: 600px;
      
      margin-bottom: 0px;
    }
    .lr-colorr{
      font-size: 17px;
      color: #fff;
    }
    
    
    .lr-text-atyour-1{
      color: #fff;
      margin-right: 110px;
      margin-bottom: 10px;
      font-size: 20px;
    }
  
    .lr-text-atyour-2{
      color: #fff;
      margin-right: 60px;
      margin-bottom: 10px;
      font-size: 20px;
    }
    .lr-text-atyour-3{
      color: #fff;
      margin-right: 70px;
      margin-bottom: 10px;
      font-size: 20px;
    }
    .lr-icon-just-1{
      font-size: 40px;
margin-bottom: 10px;
      margin-right:30px;
      color: #fff;
        }
    .lr-icon-just-2{
      margin-bottom: 10px;

      font-size: 40px;
      margin-right:35px;
      color: #fff;
        }
    .lr-icon-just-3{
      margin-bottom: 10px;

  font-size: 40px;
  margin-right:35px;
  color: #fff;
    }
    .lr-parra{
      font-size: 20px;
      text-align: left;
      color:white
     
  ;    
      letter-spacing: normal;
      font-family: "Catamaran", sans-serif;
    }
    .lr-slider-image{
      border-radius: 10px;

      margin-top: 22px;
        width: 335px;
        margin-left: 8px;
    }
    .lr-slider{
      margin-left: 3px;
    }
    .lr-sectbod{
      border: 1px solid white;
      margin-top: 213px;
      margin-left: -334px;
      width: 335px;
      height: 162px;
      padding-top: 0px;
      background-color: rgb(245, 237, 237);
      border-radius: 5px;
    }
  
    .fade-in {
      animation: fadeInLeft 0.5s ease-in-out forwards;
    }
  
  }
  @media only screen and (min-width: 375px) and (max-width: 383px) {  
    .lr-background-image-container {
      background-size: cover; /* This ensures that the background image covers the entire element */
      background-position: center; /* This centers the background image */
      height: 80vh; /* Adjust the height as needed */
      display: flex;
      justify-content: center;
      align-items: center;
      color: white; /* Text color */
      font-size: 2rem; /* Adjust font size */
      text-align: center; /* Center-align text */
      overflow: hidden;
    }
   
    .lr-card {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(203, 193, 193, 0.5);
      padding: 20px;
      color: black;
      text-align: center;
      border-radius: 10px;
      width: 300px;
      height: 350px;
      padding-top: 50px;
    }
    .lr-text-2{
     font-size: 15px;
     
     
    }

    
 
    .lr-flex-containerr {

      display: flex;
      width: 47%;
      justify-content: space-between;
     
      justify-content: space-between; /* Adjust as needed */
    }
     
    .lr-textsection{
      overflow: hidden;
      background-color: white;
        height: 240px;
        width: 81%;
        border: 1px solid rgb(244, 240, 240);
        border-radius: 20px;
        margin-left: 10%;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
        margin-top: 40px;
    }
   

    .lr-horizontal-container{
      display: block;
      margin-left: -300px;
    }

    .lr-ico{
      font-size:40px;
      color:black;
      margin-bottom: 100px;
      margin-left: 20px;
    }
  
    .lr-icoo{
      margin-left: 10px;
    
      margin-bottom: 100px;
      color:black;
   
    }
    .lr-icooo{
      margin-left: 10px;
      color:black;
      margin-bottom: 40px;
    }
    .lr-text-create-1 {
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
    }
    .lr-text-create-2 {

      
    width: 150px;
    margin-top: 30px;
      font-size: 20px;
      margin-left: -0px;
      margin-right: 0px;
      font-family: "Catamaran", sans-serif;
    }
    .lr-text-create-3 {
      margin-top: 30px;

      width: 200x;
      margin-left: 0px;
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
    }
 
    .lr-horizontal-item {
      height: 150px;
      display: flex;
        width: 355px;
        align-items: center;
        gap: 20px;
    }
    .lr-secttext{
      margin-top: 20px;
      background-color: #08144c;
      height: 910px;
      overflow: hidden;
    }

    
    .lr-flex-item1{
      width: 465px;
      padding: 20px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 180px;
      margin-top: 10px;
    }

    .lr-flex-item1{
      width: 350px;
      padding: 20px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 20px;
    }
    .lr-flex-item2{
      width: 350px;
      padding: 20px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 20px;
      margin-top: 10px;

    }
    .lr-flex-item3{
      width: 350px;
      padding: 22px;
      text-align: center;
      border: 3px solid;
      border-radius: 35px;
      color: wheat;
      margin-left: 20px;
      margin-top: 10px;

    }
    p {
      text-align: left; /* Align the text to the left */
      text-indent: 0; /* Remove any indentation */
    }
    .lr-icon-input {
      display: flex;
      align-items: center;
     
    }
   
    .lr-icon-input input {
      flex: 1;
      padding: 10px;
      margin-left: 10px;
      border: none; /* Remove border */
      outline: none;
      color: black; /* Remove outline */
    }
   .lr-input-field {
    color: black;
   }
    .lr-card h2 {
      margin: 10px 0;
    }
   
    .lr-card hr {
      width: 50%;
      margin: 10px auto;
    }
    .lr-save{
      font-family: "Catamaran", sans-serif;
      color:rgb(64, 61, 61);
      font-size: 30px;
      margin-top: 6px;
    }
    .lr-btn{
      color:white;
      border-radius: 5px;
      width: 200px;
      height:40px;
      font-family: "Catamaran", sans-serif;
      background-color:rgb(7, 52, 125);
    }
    .lr-btn:hover {
      background-color: rgb(7, 52, 125); /* Darker Green */
    }
    .lr-white{
      background-color: white;
      height:820px;
      width:100%;
      position:relative;
      margin-top: 70px;
   
    }
    .lr-text {
      font-size: 25px;
      color: black;
      margin-top: -40px;
      text-align: center;
      font-family: "Catamaran", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
    }
    .lr-flex-container {
      width: 80px;
      display: grid;
 
      justify-content: space-between;
      margin-left: -10px;
    }
    .lr-color{
      font-size: 17px;
     
    }
  
    .lr-flex-item {
      width: 73%;
        padding: 20px;
        text-align: center;
        margin-left: -3px;
        margin-top: 0px;
    }
 

 
   .lr-parra-5{
    margin-left: 10px;
    margin-top: 10px;
    font-size: 23px;
    font-family: "Catamaran", sans-serif;
   }
    .lr-flex-itemm {
      width: 30%;
      padding: 20px;
      text-align: center;
      margin-left: 143px;
    }
  
  
  
    .lr-color{
      color:black;
      font-family: "Catamaran", sans-serif;
    }
    .lr-texx{
      font-family: "Catamaran", sans-serif;
    }
    .lr-text-2{
      margin-left: -30px;
      margin-bottom: 10px;
      font-size: 25px;
      font-family: "Catamaran", sans-serif;
      color: black;
    }
    .lr-text-3{
      margin-left: -20px;
      margin-bottom: 10px;
      font-size: 25px;
      font-family: "Catamaran", sans-serif;
      color: black;
    }
    .lr-text-4{
      margin-left: -30px;
      margin-bottom: 10px;
      font-size: 25px;
      font-family: "Catamaran", sans-serif;
      color: black;
    }
  
    .lr-color-9{
      font-family: "Catamaran", sans-serif;
      margin-left: 0px;
    }
    .lr-icon-mdoutli{
      font-size: 40px;
      margin-right: 0px;
      color: black;
    }
    
    .lr-colorr{
      color:black;
      font-family: "Catamaran", sans-serif;
    }
    .lr-textt-1{
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
      font-weight: 700;
      color: black;
      margin-right: 103px;
      margin-bottom: 10px;
    }
    .lr-textt-2{
     
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
      font-weight: 700;
      color: black;
      margin-right: 36px;
      margin-bottom: 10px;
    }
    .lr-textt-3{
     
      font-size: 20px;
      font-family: "Catamaran", sans-serif;
      font-weight: 700;
      color: black;
      margin-right: 102px;
      margin-bottom: 10px;
    }
   
    .lr-section1{
      overflow: hidden;

      background-color: #f0e4e4;
      width: 99%;
      height: 407px;
      position: relative;
      z-index: -1;
      display: flex;
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 36px;
    }
    .lr-people{
      margin-top: 11px;
      margin-left: -4px;
  
    }
    .lr-carousel{
      border:1px solid black;
      width:300px;
      height:300px;
      margin-top:100px;
      margin-left: 200px;
    }
   
    .lr-video-player{
      border-radius: 10px;
      width: 350px;
      height: auto;
      margin-top: 10px;
      margin-left: 10px;
    }
    .lr-texx {
      font-size: 25px;
      margin-top: 10px;
      text-align: center;
      margin-left: 1%;
      color:black;
      width: 350px;
      overflow: hidden;
   
    }
    .lr-sect{
      overflow: hidden;
      display: grid;
    }
   
   
    
    .lr-test{
      text-align: center;
    }
    .lr-whitte{
      margin-top:20px;
      margin-left:300px;
    }
  
   
  
    .lr-test{
      font-size: 30px;
      color: #fff;
      font-family: "Catamaran", sans-serif;
      font-weight: 600px;
      
      margin-bottom: 0px;
    }
    .lr-colorr{
      font-size: 17px;
      color: #fff;
    }
    
    
    .lr-text-atyour-1{
      color: #fff;
      margin-right: 110px;
      margin-bottom: 10px;
      font-size: 20px;
    }
  
    .lr-text-atyour-2{
      color: #fff;
      margin-right: 60px;
      margin-bottom: 10px;
      font-size: 20px;
    }
    .lr-text-atyour-3{
      color: #fff;
      margin-right: 70px;
      margin-bottom: 10px;
      font-size: 20px;
    }
    .lr-icon-just-1{
      font-size: 40px;
margin-bottom: 10px;
      margin-right:30px;
      color: #fff;
        }
    .lr-icon-just-2{
      margin-bottom: 10px;

      font-size: 40px;
      margin-right:35px;
      color: #fff;
        }
    .lr-icon-just-3{
      margin-bottom: 10px;

  font-size: 40px;
  margin-right:35px;
  color: #fff;
    }
    .lr-parra{
      font-size: 20px;
      text-align: left;
      color:white
     
  ;    
      letter-spacing: normal;
      font-family: "Catamaran", sans-serif;
    }
    .lr-slider-image{
      border-radius: 10px;

      margin-top: 22px;
        width: 350px;
        margin-left: 8px;
    }
    .lr-slider{
      margin-left: 3px;
    }
    .lr-sectbod{
      border: 1px solid white;
      margin-top: 213px;
      margin-left: -340px;
      width: 335px;
      height: 162px;
      padding-top: 0px;
      background-color: rgb(245, 237, 237);
      border-radius: 5px;
    }
  
    .fade-in {
      animation: fadeInLeft 0.5s ease-in-out forwards;
    }
  
  }
 @media only screen and (min-width: 390px) and (max-width: 411px) {  
        .lr-background-image-container {
          background-size: cover; /* This ensures that the background image covers the entire element */
          background-position: center; /* This centers the background image */
          height: 80vh; /* Adjust the height as needed */
          display: flex;
          justify-content: center;
          align-items: center;
          color: white; /* Text color */
          font-size: 2rem; /* Adjust font size */
          text-align: center; /* Center-align text */
          overflow: hidden;
        }
       
        .lr-card {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(203, 193, 193, 0.5);
          padding: 20px;
          color: black;
          text-align: center;
          border-radius: 10px;
          width: 300px;
          height: 350px;
          padding-top: 50px;
        }
        .lr-text-2{
         font-size: 15px;
         
         
        }
    
        
     
        .lr-flex-containerr {
    
          display: flex;
          width: 47%;
          justify-content: space-between;
         
          justify-content: space-between; /* Adjust as needed */
        }
         
        .lr-textsection{
          overflow: hidden;
          background-color: white;
            height: 240px;
            width: 81%;
            border: 1px solid rgb(244, 240, 240);
            border-radius: 20px;
            margin-left: 10%;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
            margin-top: 20px;
        }
       
    
        .lr-horizontal-container{
          display: block;
          margin-left: -300px;
        }
    
        .lr-ico{
          font-size:40px;
          color:black;
          margin-bottom: 100px;
          margin-left: 20px;
        }
      
        .lr-icoo{
          margin-left: 10px;
        
          margin-bottom: 120px;
          color:black;
       
        }
        .lr-icooo{
          margin-left: 10px;
          color:black;
          margin-bottom: 60px;
        }
        .lr-text-create-1 {
          font-size: 20px;
          font-family: "Catamaran", sans-serif;
        }
        .lr-text-create-2 {
          
        width: 150px;
        margin-top: 30px;
          font-size: 20px;
          margin-left: -0px;
          margin-right: 0px;
          font-family: "Catamaran", sans-serif;
        }
        .lr-text-create-3 {
          margin-top: 30px;
    
          width: 200x;
          margin-left: 0px;
          font-size: 20px;
          font-family: "Catamaran", sans-serif;
        }
     
        .lr-horizontal-item {
          height: 140px;
          display: flex;
            width: 365px;
            align-items: center;
            gap: 20px;
        }
        .lr-secttext{
          margin-top: 10px;
          background-color: #08144c;
          height: 920px;

          overflow: hidden;
        }
    
        
     
    
        .lr-flex-item1{
          width: 370px;
          padding: 20px;
          text-align: center;
          border: 3px solid;
          border-radius: 35px;
          color: wheat;
          margin-left: 20px;
          margin-bottom: 20px;
        }
        .lr-flex-item2{
          margin-bottom: 20px;

          width: 370px;
          padding: 20px;
          text-align: center;
          border: 3px solid;
          border-radius: 35px;
          color: wheat;
          margin-left: 20px;
    
        }
        .lr-flex-item3{

          width: 370px;
          padding: 22px;
          text-align: center;
          border: 3px solid;
          border-radius: 35px;
          color: wheat;
          margin-left: 20px;
    
        }
        p {
          text-align: left; /* Align the text to the left */
          text-indent: 0; /* Remove any indentation */
        }
        .lr-icon-input {
          display: flex;
          align-items: center;
         
        }
       
        .lr-icon-input input {
          flex: 1;
          padding: 10px;
          margin-left: 10px;
          border: none; /* Remove border */
          outline: none;
          color: black; /* Remove outline */
        }
       .lr-input-field {
        color: black;
       }
        .lr-card h2 {
          margin: 10px 0;
        }
       
        .lr-card hr {
          width: 50%;
          margin: 10px auto;
        }
        .lr-save{
          font-family: "Catamaran", sans-serif;
          color:rgb(64, 61, 61);
          font-size: 30px;
          margin-top: 6px;
        }
        .lr-btn{
          color:white;
          border-radius: 5px;
          width: 200px;
          height:40px;
          font-family: "Catamaran", sans-serif;
          background-color:rgb(7, 52, 125);
        }
        .lr-btn:hover {
          background-color: rgb(7, 52, 125); /* Darker Green */
        }
        .lr-white{
          background-color: white;
          height:820px;
          width:100%;
          position:relative;
          margin-top: 70px;
       
        }
        .lr-text {
          font-size: 25px;
          color: black;
          margin-top: -40px;
          text-align: center;
          font-family: "Catamaran", sans-serif;
          font-optical-sizing: auto;
          font-style: normal;
          margin-bottom: 20px;

        }
        .lr-flex-container {
          width: 80px;
          display: grid;
     
          justify-content: space-between;
          margin-left: -10px;
        }
        .lr-color{
          font-size: 17px;
         
        }
      
        .lr-flex-item {
          width: 73%;
            padding: 20px;
            text-align: center;
            margin-left: -3px;
            margin-top: 0px;
        }
     
    
     
       .lr-parra-5{
        font-size: 23px;
        font-family: "Catamaran", sans-serif;
       }
        .lr-flex-itemm {
          width: 30%;
          padding: 20px;
          text-align: center;
          margin-left: 143px;
        }
      
      
      
        .lr-color{
          color:black;
          font-family: "Catamaran", sans-serif;
        }
        .lr-texx{
          font-family: "Catamaran", sans-serif;
        }
        .lr-text-2{
          margin-left: -30px;
          margin-bottom: 10px;
          font-size: 25px;
          font-family: "Catamaran", sans-serif;
          color: black;
        }
        .lr-text-3{
          margin-left: -20px;
          margin-bottom: 10px;
          font-size: 25px;
          font-family: "Catamaran", sans-serif;
          color: black;
        }
        .lr-text-4{
          margin-left: -30px;
          margin-bottom: 10px;
          font-size: 25px;
          font-family: "Catamaran", sans-serif;
          color: black;
        }
      
        .lr-color-9{
          font-family: "Catamaran", sans-serif;
          margin-left: 0px;
        }
        .lr-icon-mdoutli{
          font-size: 40px;
          margin-right: 0px;
          color: black;
        }
        
        .lr-colorr{
          color:black;
          font-family: "Catamaran", sans-serif;
        }
        .lr-textt-1{
          font-size: 20px;
          font-family: "Catamaran", sans-serif;
          font-weight: 700;
          color: black;
          margin-right: 103px;
          margin-bottom: 10px;
        }
        .lr-textt-2{
         
          font-size: 20px;
          font-family: "Catamaran", sans-serif;
          font-weight: 700;
          color: black;
          margin-right: 36px;
          margin-bottom: 10px;
        }
        .lr-textt-3{
         
          font-size: 20px;
          font-family: "Catamaran", sans-serif;
          font-weight: 700;
          color: black;
          margin-right: 102px;
          margin-bottom: 10px;
        }
       
        .lr-section1{
          overflow: hidden;
    
          background-color: #f0e4e4;
          width: 99%;
          height: 407px;
          position: relative;
          z-index: -1;
          display: flex;
          margin-top: 10px;
          margin-right: 10px;
          border-radius: 36px;
        }
        .lr-people{
          margin-top: 11px;
          margin-left: -4px;
      
        }
        .lr-carousel{
          border:1px solid black;
          width:300px;
          height:300px;
          margin-top:100px;
          margin-left: 200px;
        }
       
        .lr-video-player{
          border-radius: 10px;
          width: 360px;
          height: auto;
          margin-top: 10px;
          margin-left: 15px;
        }
        .lr-texx {
          font-size: 25px;
          margin-top: 10px;
          text-align: center;
          margin-left: 5%;
          color:black;
          width: 350px;
          overflow: hidden;
       
        }
        .lr-sect{
          overflow: hidden;
          display: grid;
        }
       
       
        
        .lr-test{
          text-align: center;
        }
        .lr-whitte{
          margin-top:20px;
          margin-left:300px;
        }
      
       
      
        .lr-test{
          font-size: 30px;
          color: #fff;
          font-family: "Catamaran", sans-serif;
          font-weight: 600px;
          
          margin-bottom: 30px;
        }
        .lr-colorr{
          font-size: 17px;
          color: #fff;
        }
        
        
        .lr-text-atyour-1{
          color: #fff;
          margin-right: 110px;
          margin-bottom: 10px;
          font-size: 20px;
        }
      
        .lr-text-atyour-2{
          color: #fff;
          margin-right: 60px;
          margin-bottom: 10px;
          font-size: 20px;
        }
        .lr-text-atyour-3{
          color: #fff;
          margin-right: 70px;
          margin-bottom: 10px;
          font-size: 20px;
        }
        .lr-icon-just-1{
          font-size: 40px;
    margin-bottom: 10px;
          margin-right:30px;
          color: #fff;
            }
        .lr-icon-just-2{
          margin-bottom: 10px;
    
          font-size: 40px;
          margin-right:35px;
          color: #fff;
            }
        .lr-icon-just-3{
          margin-bottom: 10px;
    
      font-size: 40px;
      margin-right:35px;
      color: #fff;
        }
        .lr-parra{
          font-size: 20px;
          text-align: left;
          color:white
         
      ;    
          letter-spacing: normal;
          font-family: "Catamaran", sans-serif;
        }
        .lr-slider-image{
          border-radius: 10px;
    
          margin-top: 22px;
            width: 350px;
            margin-left: 15px;
        }
        .lr-slider{
          margin-left: 3px;
        }
        .lr-sectbod{
          border: 1px solid white;
          margin-top: 213px;
          margin-left: -334px;
          width: 335px;
          height: 162px;
          padding-top: 0px;
          background-color: rgb(245, 237, 237);
          border-radius: 5px;
        }
      
        .fade-in {
          animation: fadeInLeft 0.5s ease-in-out forwards;
        }
      
 }
 @media only screen and (min-width: 412px) and (max-width: 427px) {  
  .lr-background-image-container {
    background-size: cover; /* This ensures that the background image covers the entire element */
    background-position: center; /* This centers the background image */
    height: 80vh; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color */
    font-size: 2rem; /* Adjust font size */
    text-align: center; /* Center-align text */
    overflow: hidden;
  }
 
  .lr-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(203, 193, 193, 0.5);
    padding: 20px;
    color: black;
    text-align: center;
    border-radius: 10px;
    width: 300px;
    height: 350px;
    padding-top: 50px;
  }
  .lr-text-2{
   font-size: 15px;
   
   
  }

  

  .lr-flex-containerr {

    display: flex;
    width: 47%;
    justify-content: space-between;
   
    justify-content: space-between; /* Adjust as needed */
  }
   
  .lr-textsection{
    overflow: hidden;
    background-color: white;
      height: 240px;
      width: 81%;
      border: 1px solid rgb(244, 240, 240);
      border-radius: 20px;
      margin-left: 10%;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
      margin-top: 30px;
  }
 

  .lr-horizontal-container{
    display: block;
    margin-left: -300px;
  }

  .lr-ico{
    font-size:40px;
    color:black;
    margin-bottom: 100px;
    margin-left: 20px;
  }

  .lr-icoo{
    margin-left: 10px;
  
    margin-bottom: 120px;
    color:black;
 
  }
  .lr-icooo{
    margin-left: 10px;
    color:black;
    margin-bottom: 60px;
  }
  .lr-text-create-1 {
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
  }
  .lr-text-create-2 {
    
  width: 150px;
  margin-top: 30px;
    font-size: 20px;
    margin-left: -0px;
    margin-right: 0px;
    font-family: "Catamaran", sans-serif;
  }
  .lr-text-create-3 {
    margin-top: 30px;

    width: 200x;
    margin-left: 0px;
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
  }

  .lr-horizontal-item {
    height: 140px;
    display: flex;
      width: 365px;
      align-items: center;
      gap: 20px;
  }
  .lr-secttext{
    margin-top: 10px;
    background-color: #08144c;
    height: 920px;

    overflow: hidden;
  }

  


  .lr-flex-item1{
    width: 380px;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
    margin-left: 25px;
    margin-bottom: 20px;
  }
  .lr-flex-item2{
    margin-bottom: 20px;

    width: 380px;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
    margin-left: 25px;

  }
  .lr-flex-item3{

    width: 380px;
    padding: 22px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
    margin-left: 25px;

  }
  p {
    text-align: left; /* Align the text to the left */
    text-indent: 0; /* Remove any indentation */
  }
  .lr-icon-input {
    display: flex;
    align-items: center;
   
  }
 
  .lr-icon-input input {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    border: none; /* Remove border */
    outline: none;
    color: black; /* Remove outline */
  }
 .lr-input-field {
  color: black;
 }
  .lr-card h2 {
    margin: 10px 0;
  }
 
  .lr-card hr {
    width: 50%;
    margin: 10px auto;
  }
  .lr-save{
    font-family: "Catamaran", sans-serif;
    color:rgb(64, 61, 61);
    font-size: 30px;
    margin-top: 6px;
  }
  .lr-btn{
    color:white;
    border-radius: 5px;
    width: 200px;
    height:40px;
    font-family: "Catamaran", sans-serif;
    background-color:rgb(7, 52, 125);
  }
  .lr-btn:hover {
    background-color: rgb(7, 52, 125); /* Darker Green */
  }
  .lr-white{
    background-color: white;
    height:820px;
    width:100%;
    position:relative;
    margin-top: 70px;
 
  }
  .lr-text {
    font-size: 25px;
    color: black;
    margin-top: -40px;
    text-align: center;
    font-family: "Catamaran", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    margin-bottom: 20px;

  }
  .lr-flex-container {
    width: 80px;
    display: grid;

    justify-content: space-between;
    margin-left: -10px;
  }
  .lr-color{
    font-size: 17px;
   
  }

  .lr-flex-item {
    width: 73%;
      padding: 20px;
      text-align: center;
      margin-left: -3px;
      margin-top: 0px;
  }



 .lr-parra-5{
  font-size: 23px;
  font-family: "Catamaran", sans-serif;
 }
  .lr-flex-itemm {
    width: 30%;
    padding: 20px;
    text-align: center;
    margin-left: 143px;
  }



  .lr-color{
    color:black;
    font-family: "Catamaran", sans-serif;
  }
  .lr-texx{
    font-family: "Catamaran", sans-serif;
  }
  .lr-text-2{
    margin-left: -30px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }
  .lr-text-3{
    margin-left: -20px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }
  .lr-text-4{
    margin-left: -30px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }

  .lr-color-9{
    font-family: "Catamaran", sans-serif;
    margin-left: 0px;
  }
  .lr-icon-mdoutli{
    font-size: 40px;
    margin-right: 0px;
    color: black;
  }
  
  .lr-colorr{
    color:black;
    font-family: "Catamaran", sans-serif;
  }
  .lr-textt-1{
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 103px;
    margin-bottom: 10px;
  }
  .lr-textt-2{
   
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 36px;
    margin-bottom: 10px;
  }
  .lr-textt-3{
   
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 102px;
    margin-bottom: 10px;
  }
 
  .lr-section1{
    overflow: hidden;

    background-color: #f0e4e4;
    width: 99%;
    height: 407px;
    position: relative;
    z-index: -1;
    display: flex;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 36px;
  }
  .lr-people{
    margin-top: 11px;
    margin-left: -4px;

  }
  .lr-carousel{
    border:1px solid black;
    width:300px;
    height:300px;
    margin-top:100px;
    margin-left: 200px;
  }
 
  .lr-video-player{
    border-radius: 10px;
    width: 380px;
    height: auto;
    margin-top: 10px;
    margin-left: 15px;
  }
  .lr-texx {
    font-size: 25px;
    margin-top: 10px;
    text-align: center;
    margin-left: 5%;
    color:black;
    width: 350px;
    overflow: hidden;
 
  }
  .lr-sect{
    overflow: hidden;
    display: grid;
  }
 
 
  
  .lr-test{
    text-align: center;
  }
  .lr-whitte{
    margin-top:20px;
    margin-left:320px;
  }

 

  .lr-test{
    font-size: 30px;
    color: #fff;
    font-family: "Catamaran", sans-serif;
    font-weight: 600px;
    
    margin-bottom: 30px;
  }
  .lr-colorr{
    font-size: 17px;
    color: #fff;
  }
  
  
  .lr-text-atyour-1{
    color: #fff;
    margin-right: 110px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .lr-text-atyour-2{
    color: #fff;
    margin-right: 60px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .lr-text-atyour-3{
    color: #fff;
    margin-right: 70px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .lr-icon-just-1{
    font-size: 40px;
margin-bottom: 10px;
    margin-right:30px;
    color: #fff;
      }
  .lr-icon-just-2{
    margin-bottom: 10px;

    font-size: 40px;
    margin-right:35px;
    color: #fff;
      }
  .lr-icon-just-3{
    margin-bottom: 10px;

font-size: 40px;
margin-right:35px;
color: #fff;
  }
  .lr-parra{
    font-size: 20px;
    text-align: left;
    color:white
   
;    
    letter-spacing: normal;
    font-family: "Catamaran", sans-serif;
  }
  .lr-slider-image{
    border-radius: 10px;

    margin-top: 22px;
      width: 370px;
      margin-left: 15px;
  }
  .lr-slider{
    margin-left: 3px;
  }
  .lr-sectbod{
    border: 1px solid white;
    margin-top: 213px;
    margin-left: -350px;
    width: 335px;
    height: 162px;
    padding-top: 0px;
    background-color: rgb(245, 237, 237);
    border-radius: 5px;
  }

  .fade-in {
    animation: fadeInLeft 0.5s ease-in-out forwards;
  }

}
@media only screen and (min-width: 428px) and (max-width: 479px) {  
  .lr-background-image-container {
    background-size: cover; /* This ensures that the background image covers the entire element */
    background-position: center; /* This centers the background image */
    height: 80vh; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color */
    font-size: 2rem; /* Adjust font size */
    text-align: center; /* Center-align text */
    overflow: hidden;
  }
 
  .lr-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(203, 193, 193, 0.5);
    padding: 20px;
    color: black;
    text-align: center;
    border-radius: 10px;
    width: 300px;
    height: 350px;
    padding-top: 50px;
  }
  .lr-text-2{
   font-size: 15px;
   
   
  }

  

  .lr-flex-containerr {

    display: flex;
    width: 47%;
    justify-content: space-between;
   
    justify-content: space-between; /* Adjust as needed */
  }
   
  .lr-textsection{
    overflow: hidden;
    background-color: white;
      height: 240px;
      width: 81%;
      border: 1px solid rgb(244, 240, 240);
      border-radius: 20px;
      margin-left: 10%;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
      margin-top: 30px;
  }
 

  .lr-horizontal-container{
    display: block;
    margin-left: -300px;
  }

  .lr-ico{
    font-size:40px;
    color:black;
    margin-bottom: 100px;
    margin-left: 20px;
  }

  .lr-icoo{
    margin-left: 10px;
  
    margin-bottom: 120px;
    color:black;
 
  }
  .lr-icooo{
    margin-left: 10px;
    color:black;
    margin-bottom: 60px;
  }
  .lr-text-create-1 {
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
  }
  .lr-text-create-2 {
    
  width: 150px;
  margin-top: 30px;
    font-size: 20px;
    margin-left: -0px;
    margin-right: 0px;
    font-family: "Catamaran", sans-serif;
  }
  .lr-text-create-3 {
    margin-top: 60px;

    width: 200x;
    margin-left: 0px;
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
  }

  .lr-horizontal-item {
    height: 170px;
    display: flex;
      width: 365px;
      align-items: center;
      gap: 20px;
  }
  .lr-secttext{
    margin-top: 10px;
    background-color: #08144c;
    height: 920px;

    overflow: hidden;
  }

  


  .lr-flex-item1{
    width: 400px;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
    margin-left: 25px;
    margin-bottom: 20px;
  }
  .lr-flex-item2{
    margin-bottom: 20px;

    width: 400px;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
    margin-left: 25px;

  }
  .lr-flex-item3{

    width: 400px;
    padding: 22px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
    margin-left: 25px;

  }
  p {
    text-align: left; /* Align the text to the left */
    text-indent: 0; /* Remove any indentation */
  }
  .lr-icon-input {
    display: flex;
    align-items: center;
   
  }
 
  .lr-icon-input input {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    border: none; /* Remove border */
    outline: none;
    color: black; /* Remove outline */
  }
 .lr-input-field {
  color: black;
 }
  .lr-card h2 {
    margin: 10px 0;
  }
 
  .lr-card hr {
    width: 50%;
    margin: 10px auto;
  }
  .lr-save{
    font-family: "Catamaran", sans-serif;
    color:rgb(64, 61, 61);
    font-size: 30px;
    margin-top: 6px;
  }
  .lr-btn{
    color:white;
    border-radius: 5px;
    width: 200px;
    height:40px;
    font-family: "Catamaran", sans-serif;
    background-color:rgb(7, 52, 125);
  }
  .lr-btn:hover {
    background-color: rgb(7, 52, 125); /* Darker Green */
  }
  .lr-white{
    background-color: white;
    height:820px;
    width:100%;
    position:relative;
    margin-top: 70px;
 
  }
  .lr-text {
    font-size: 25px;
    color: black;
    margin-top: -40px;
    text-align: center;
    font-family: "Catamaran", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    margin-bottom: 20px;

  }
  .lr-flex-container {
    width: 80px;
    display: grid;

    justify-content: space-between;
    margin-left: -10px;
  }
  .lr-color{
    font-size: 20px;
   
  }

  .lr-flex-item {
    width: 73%;
      padding: 20px;
      text-align: center;
      margin-left: -3px;
      margin-top: 0px;
  }



 .lr-parra-5{
  font-size: 23px;
  font-family: "Catamaran", sans-serif;
 }
  .lr-flex-itemm {
    width: 30%;
    padding: 20px;
    text-align: center;
    margin-left: 143px;
  }



  .lr-color{
    color:black;
    font-family: "Catamaran", sans-serif;
  }
  .lr-texx{
    font-family: "Catamaran", sans-serif;
  }
  .lr-text-2{
    width: 300px;
    margin-left: -30px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }
  .lr-text-3{
    width: 400px;

    margin-left: -20px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }
  .lr-text-4{
    width: 400px;

    margin-left: -30px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }

  .lr-color-9{
    font-family: "Catamaran", sans-serif;
    margin-left: 0px;
  }
  .lr-icon-mdoutli{
    font-size: 40px;
    margin-right: 0px;
    color: black;
  }
  
  .lr-colorr{
    color:black;
    font-family: "Catamaran", sans-serif;
  }
  .lr-textt-1{
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 103px;
    margin-bottom: 10px;
  }
  .lr-textt-2{
   
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 36px;
    margin-bottom: 10px;
  }
  .lr-textt-3{
   
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 102px;
    margin-bottom: 10px;
  }
 
  .lr-section1{
    overflow: hidden;

    background-color: #f0e4e4;
    width: 99%;
    height: 407px;
    position: relative;
    z-index: -1;
    display: flex;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 36px;
  }
  .lr-people{
    margin-top: 11px;
    margin-left: -4px;

  }
  .lr-carousel{
    border:1px solid black;
    width:300px;
    height:300px;
    margin-top:100px;
    margin-left: 200px;
  }
 
  .lr-video-player{
    border-radius: 10px;
    width: 400px;
    height: auto;
    margin-top: 10px;
    margin-left: 15px;
  }
  .lr-texx {
    font-size: 25px;
    margin-top: 10px;
    text-align: center;
    margin-left: 5%;
    color:black;
    width: 350px;
    overflow: hidden;
 
  }
  .lr-sect{
    overflow: hidden;
    display: grid;
  }
 
 
  
  .lr-test{
    text-align: center;
  }
  .lr-whitte{
    height: 530px;
    margin-top:20px;
    margin-left:320px;
  }

 

  .lr-test{
    font-size: 30px;
    color: #fff;
    font-family: "Catamaran", sans-serif;
    font-weight: 600px;
    
    margin-bottom: 30px;
  }
  .lr-colorr{
    font-size: 17px;
    color: #fff;
  }
  
  
  .lr-text-atyour-1{
    color: #fff;
    margin-right: 110px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .lr-text-atyour-2{
    color: #fff;
    margin-right: 60px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .lr-text-atyour-3{
    color: #fff;
    margin-right: 70px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .lr-icon-just-1{
    font-size: 40px;
margin-bottom: 10px;
    margin-right:30px;
    color: #fff;
      }
  .lr-icon-just-2{
    margin-bottom: 10px;

    font-size: 40px;
    margin-right:35px;
    color: #fff;
      }
  .lr-icon-just-3{
    margin-bottom: 10px;

font-size: 40px;
margin-right:35px;
color: #fff;
  }
  .lr-parra{
    font-size: 20px;
    text-align: left;
    color:white
   
;    
    letter-spacing: normal;
    font-family: "Catamaran", sans-serif;
  }
  .lr-slider-image{
    border-radius: 10px;

    margin-top: 22px;
      width: 370px;
      margin-left: 25px;
  }
  .lr-slider{
    margin-left: 3px;
  }
  .lr-sectbod{
    border: 1px solid white;
    margin-top: 213px;
    margin-left: -360px;
    width: 355px;
    height: 162px;
    padding-top: 0px;
    background-color: rgb(245, 237, 237);
    border-radius: 5px;
    padding-left: 15px;
    padding-top: 10px;
  }

  .fade-in {
    animation: fadeInLeft 0.5s ease-in-out forwards;
  }

}

@media only screen and (min-width: 768px) and (max-width: 799px) {  
  .lr-background-image-container {
    background-size: cover; /* This ensures that the background image covers the entire element */
    background-position: center; /* This centers the background image */
    height: 100vh; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color */
    font-size: 2rem; /* Adjust font size */
    text-align: center; /* Center-align text */
  }
 
  .lr-card {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(203, 193, 193, 0.5);
    padding: 20px;
    color: black;
    text-align: center;
    border-radius: 10px;
    width: 400px;
    height: 350px;
    padding-top: 50px;
  }
  .lr-text-2{
   font-size: 15px;
   
   
  }

  

  .lr-flex-containerr {

    display: flex;
    width: 47%;
    justify-content: space-between;
   
    justify-content: space-between; /* Adjust as needed */
  }
   
  .lr-textsection{
    background-color: white;
      height: 150px;
      width: 81%;
      border: 1px solid rgb(244, 240, 240);
      border-radius: 5px;
      margin-left: 10%;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
      margin-top: 570px;
  }
 

  .lr-horizontal-container{
    display: block;
    margin-left: -300px;
  }

  .lr-ico{
    font-size:50px;
    color:black;
    margin-bottom: 100px;
    margin-left: 30px;
    margin-top: 0px;

  }

  .lr-icoo{
    margin-left: 20px;
    margin-top: 20px;

    margin-bottom: 80px;
    color:black;
 
  }
  .lr-icooo{
    margin-left: 20px;
    margin-top: 80px;
    color:black;
    margin-bottom: 80px;
  }
  .lr-text-create-1 {
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    margin-right: 0px;
    margin-left: 0px;
padding-top: 0px;
  }
  .lr-text-create-2 {
    padding-top: 80px; 
  width: 200px;
    font-size: 25px;
    margin-left: 0px;
    margin-right: 50px;
    font-family: "Catamaran", sans-serif;
  }
  .lr-text-create-3 {
    padding-top: 100px;
    width: 200x;
    margin-left: 0px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
  }

  .lr-horizontal-item {
    height: 140px;
    display: flex;
      width: 500px;
      align-items: center;
      gap: 20px;
      margin-left: 100px;
  }
  .lr-secttext{
    margin-top: 50px;
    background-color: #08144c;
    height: 1150px;
    width: 750px;
    border-radius: 10px;

    margin-left: 10px;
  }

  .lr-flex-item1{
    width: 465px;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
    margin-left: 180px;
    margin-top: 20px;
  }

  .lr-flex-item2{
    width: 465px;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
    margin-top: 10px;
    margin-left: 180px;
  }
  .lr-flex-item3{
    width: 465px;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    margin-top: 10px;
    color: wheat;
    margin-left: 180px;
  }
  p {
    text-align: left; /* Align the text to the left */
    text-indent: 0; /* Remove any indentation */
  }
  .lr-icon-input {
    display: flex;
    align-items: center;
   
  }
 
  .lr-icon-input input {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    border: none; /* Remove border */
    outline: none;
    color: black; /* Remove outline */
  }
 .lr-input-field {
  color: black;
 }
  .lr-card h2 {
    margin: 10px 0;
  }
 
  .lr-card hr {
    width: 50%;
    margin: 10px auto;
  }
  .lr-save{
    font-family: "Catamaran", sans-serif;
    color:rgb(64, 61, 61);
    font-size: 30px;
    margin-top: 6px;
  }
  .lr-btn{
    color:white;
    border-radius: 5px;
    width: 200px;
    height:40px;
    font-family: "Catamaran", sans-serif;
  }

  .lr-white{
    background-color: white;
    height:300px;
    width:100%;
    position:relative;

    margin-top: -200px;
  }
  .lr-text {
     padding-top: 20px;
    font-size: 35px;
    color: black;
    margin-top: 0px;
    text-align: center;
    font-family: "Catamaran", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
  .lr-flex-container {
    width: 100px;
    display: grid;
    
margin-top: 20px;
    justify-content: space-between;
    margin-left: -10px;
  }
  .lr-color{
    font-size: 20px;
   
  }

  .lr-flex-item {
    width: 73%;
      padding: 20px;
      text-align: center;
      margin-left: -3px;
      margin-top: 0px;
  }



 .lr-parra-5{
  font-size: 23px;
  font-family: "Catamaran", sans-serif;
 }
  .lr-flex-itemm {
    width: 30%;
    padding: 20px;
    text-align: center;
    margin-left: 143px;
  }



  .lr-color{
    color:black;
    font-family: "Catamaran", sans-serif;
  }
  .lr-texx{
    font-family: "Catamaran", sans-serif;
  }
  .lr-text-2{
    margin-right: 170px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }
  .lr-text-3{
    margin-right: 50px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }
  .lr-text-4{
    margin-right: 120px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }

  .lr-color-9{
    font-family: "Catamaran", sans-serif;
    margin-left: 10px;
    font-size: 20px;
  }
  .lr-icon-mdoutli{
    font-size: 30px;
    margin-right: 50px;
    color: black;
  }
  
  .lr-colorr{
    font-size: 25px;
    color: #fff;
    font-family: "Catamaran", sans-serif;
  }
  .lr-textt-1{
    width: 140px;
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 100px;
    margin-bottom: 10px;
  }
  .lr-textt-2{
    width: 200px;
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 100px;
    margin-bottom: 10px;
  }
  .lr-textt-3{
    width: 140px;
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 102px;
    margin-bottom: 10px;
  }
 
  .lr-section1{
    background-color: #f0e4e4;
    width: 100%;
    height: 250px;
    position: relative;
    z-index: -1;
    display: flex;
    margin-top: 20px;
    border-radius: 36px;
  }
  .lr-people{
    margin-top: 11px;
    margin-left: -4px;

  }
  .lr-carousel{
    border:1px solid black;
    width:300px;
    height:300px;
    margin-top:100px;
    margin-left: 200px;
  }
 
  .lr-video-player{
    border-radius: 10px;
    width: 650px;
    height: auto;
    margin-top: 20px;
    margin-left: 60px;
  }
  .lr-texx {
    font-size: 30px;
    margin-top: 50px;
    text-align: center;
    margin-left: 10%;
    color:black;
    width: 600px;
 
  }
  .lr-sect{
    display: grid;
  }
 
 
  
  .lr-test{
    text-align: center;
  }
  .lr-whitte{
    margin-top:50px;
    margin-left:300px;
  }

 

  .lr-test{
    font-size: 40px;
    color: #fff;
    font-family: "Catamaran", sans-serif;
    font-weight: 600px;
    
    margin-bottom: 20px;
  }
  
  
  
  .lr-text-atyour-1{
    color: #fff;
    margin-right: 150px;
    margin-bottom: 10px;
    font-size: 30px;
  }

  .lr-text-atyour-2{
    color: #fff;
    margin-right: 70px;
    margin-bottom: 10px;
    font-size: 30px;
  }
  .lr-text-atyour-3{
    color: #fff;
    margin-right: 80px;
    margin-bottom: 10px;
    font-size: 30px;
  }
  .lr-icon-just-1{
    font-size: 50px;
    margin-right:31px;
    color: #fff;
      }
  .lr-icon-just-2{
    font-size: 50px;
    margin-right:35px;
    color: #fff;
      }
  .lr-icon-just-3{
font-size: 50px;
margin-right:35px;
color: #fff;
  }
  .lr-parra{
    font-size: 20px;
    text-align: left;
    color:white
   
;    
    letter-spacing: normal;
    font-family: "Catamaran", sans-serif;
  }
  .lr-slider-image{
    margin-top: 30px;
      width: 375px;
      margin-left: 20px;
      border-radius: 10px;

      
  }
  .lr-slider{
    margin-left: 3px;
  }
  .lr-sectbod{
    border: 1px solid white;
    margin-top: 30px;
    margin-left: 30px;
    width: 320px;
    height: 160px;
    padding-top: 10px;
    background-color: rgb(245, 237, 237);
    border-radius: 10px;
  }

  .fade-in {
    animation: fadeInLeft 0.5s ease-in-out forwards;
  }
}
     
@media only screen and (min-width: 800px) and (max-width: 883px) {  
        .lr-background-image-container {
          background-size: cover; /* This ensures that the background image covers the entire element */
          background-position: center; /* This centers the background image */
          height: 100vh; /* Adjust the height as needed */
          display: flex;
          justify-content: center;
          align-items: center;
          color: white; /* Text color */
          font-size: 2rem; /* Adjust font size */
          text-align: center; /* Center-align text */
        }
       
        .lr-card {
          position: absolute;
          top: 30%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(203, 193, 193, 0.5);
          padding: 20px;
          color: black;
          text-align: center;
          border-radius: 10px;
          width: 400px;
          height: 350px;
          padding-top: 50px;
        }
        .lr-text-2{
         font-size: 15px;
         
         
        }
    
        
     
        .lr-flex-containerr {
    
          display: flex;
          width: 47%;
          justify-content: space-between;
         
          justify-content: space-between; /* Adjust as needed */
        }
         
        .lr-textsection{
          background-color: white;
            height: 150px;
            width: 81%;
            border: 1px solid rgb(244, 240, 240);
            border-radius: 5px;
            margin-left: 10%;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
            margin-top: 570px;
        }
       
    
        .lr-horizontal-container{
          display: block;
          margin-left: -300px;
        }
    
        .lr-ico{
          font-size:50px;
          color:black;
          margin-bottom: 50px;
          margin-left: 30px;
          margin-top: 0px;

        }
      
        .lr-icoo{
          margin-left: 20px;
          margin-top: 20px;

          margin-bottom: 50px;
          color:black;
       
        }
        .lr-icooo{
          margin-left: 20px;
          margin-top: 80px;
          color:black;
          margin-bottom: 50px;
        }
        .lr-text-create-1 {
          font-size: 25px;
          font-family: "Catamaran", sans-serif;
          margin-right: 0px;
          margin-left: 0px;
      padding-top: 0px;
        }
        .lr-text-create-2 {
          padding-top: 60px; 
        width: 200px;
          font-size: 25px;
          margin-left: 0px;
          margin-right: 50px;
          font-family: "Catamaran", sans-serif;
        }
        .lr-text-create-3 {
          padding-top: 80px;
          width: 200x;
          margin-left: 0px;
          font-size: 25px;
          font-family: "Catamaran", sans-serif;
        }
     
        .lr-horizontal-item {
          height: 140px;
          display: flex;
            width: 500px;
            align-items: center;
            gap: 20px;
            margin-left: 100px;
        }
        .lr-secttext{
          margin-top: 50px;
          background-color: #08144c;
          height: 980px;
          width: 800px;
          margin-left: 10px;
        }
    
        .lr-flex-item1{
          width: 465px;
          padding: 20px;
          text-align: center;
          border: 3px solid;
          border-radius: 35px;
          color: wheat;
          margin-left: 180px;
          margin-top: 20px;
        }

        .lr-flex-item2{
          width: 465px;
          padding: 20px;
          text-align: center;
          border: 3px solid;
          border-radius: 35px;
          color: wheat;
          margin-top: 10px;
          margin-left: 180px;
        }
        .lr-flex-item3{
          width: 465px;
          padding: 20px;
          text-align: center;
          border: 3px solid;
          border-radius: 35px;
          margin-top: 10px;
          color: wheat;
          margin-left: 180px;
        }
        p {
          text-align: left; /* Align the text to the left */
          text-indent: 0; /* Remove any indentation */
        }
        .lr-icon-input {
          display: flex;
          align-items: center;
         
        }
       
        .lr-icon-input input {
          flex: 1;
          padding: 10px;
          margin-left: 10px;
          border: none; /* Remove border */
          outline: none;
          color: black; /* Remove outline */
        }
       .lr-input-field {
        color: black;
       }
        .lr-card h2 {
          margin: 10px 0;
        }
       
        .lr-card hr {
          width: 50%;
          margin: 10px auto;
        }
        .lr-save{
          font-family: "Catamaran", sans-serif;
          color:rgb(64, 61, 61);
          font-size: 30px;
          margin-top: 6px;
        }
        .lr-btn{
          color:white;
          border-radius: 5px;
          width: 200px;
          height:40px;
          font-family: "Catamaran", sans-serif;
        }
      
        .lr-white{
          background-color: white;
          height:300px;
          width:100%;
          position:relative;

          margin-top: -200px;
        }
        .lr-text {
           padding-top: 20px;
          font-size: 35px;
          color: black;
          margin-top: 0px;
          text-align: center;
          font-family: "Catamaran", sans-serif;
          font-optical-sizing: auto;
          font-style: normal;
        }
        .lr-flex-container {
          width: 100px;
          display: grid;
          
margin-top: 20px;
          justify-content: space-between;
          margin-left: -10px;
        }
        .lr-color{
          font-size: 20px;
         
        }
      
        .lr-flex-item {
          width: 73%;
            padding: 20px;
            text-align: center;
            margin-left: -3px;
            margin-top: 0px;
        }
     
    
     
       .lr-parra-5{
        font-size: 23px;
        font-family: "Catamaran", sans-serif;
       }
        .lr-flex-itemm {
          width: 30%;
          padding: 20px;
          text-align: center;
          margin-left: 143px;
        }
      
      
      
        .lr-color{
          color:black;
          font-family: "Catamaran", sans-serif;
        }
        .lr-texx{
          font-family: "Catamaran", sans-serif;
        }
        .lr-text-2{
          margin-right: 170px;
          margin-bottom: 10px;
          font-size: 25px;
          font-family: "Catamaran", sans-serif;
          color: black;
        }
        .lr-text-3{
          margin-right: 50px;
          margin-bottom: 10px;
          font-size: 25px;
          font-family: "Catamaran", sans-serif;
          color: black;
        }
        .lr-text-4{
          margin-right: 120px;
          margin-bottom: 10px;
          font-size: 25px;
          font-family: "Catamaran", sans-serif;
          color: black;
        }
      
        .lr-color-9{
          font-family: "Catamaran", sans-serif;
          margin-left: 10px;
          font-size: 20px;
        }
        .lr-icon-mdoutli{
          font-size: 30px;
          margin-right: 50px;
          color: black;
        }
        
        .lr-colorr{
          font-size: 20px;
          color:black;
          font-family: "Catamaran", sans-serif;
        }
        .lr-textt-1{
          width: 140px;
          font-size: 20px;
          font-family: "Catamaran", sans-serif;
          font-weight: 700;
          color: black;
          margin-right: 100px;
          margin-bottom: 10px;
        }
        .lr-textt-2{
          width: 200px;
          font-size: 20px;
          font-family: "Catamaran", sans-serif;
          font-weight: 700;
          color: black;
          margin-right: 100px;
          margin-bottom: 10px;
        }
        .lr-textt-3{
          width: 140px;
          font-size: 20px;
          font-family: "Catamaran", sans-serif;
          font-weight: 700;
          color: black;
          margin-right: 102px;
          margin-bottom: 10px;
        }
       
        .lr-section1{
          background-color: #f0e4e4;
          width: 100%;
          height: 250px;
          position: relative;
          z-index: -1;
          display: flex;
          margin-top: 0px;
          border-radius: 36px;
        }
        .lr-people{
          margin-top: 11px;
          margin-left: -4px;
      
        }
        .lr-carousel{
          border:1px solid black;
          width:300px;
          height:300px;
          margin-top:100px;
          margin-left: 200px;
        }
       
        .lr-video-player{
          border-radius: 10px;
          width: 650px;
          height: auto;
          margin-top: 20px;
          margin-left: 80px;
        }
        .lr-texx {
          font-size: 30px;
          margin-top: 50px;
          text-align: center;
          margin-left: 10%;
          color:black;
          width: 600px;
       
        }
        .lr-sect{
          display: grid;
        }
       
       
        
        .lr-test{
          text-align: center;
        }
        .lr-whitte{
          margin-top:50px;
          margin-left:300px;
        }
      
       
      
        .lr-test{
          font-size: 40px;
          color: #fff;
          font-family: "Catamaran", sans-serif;
          font-weight: 600px;
          
          margin-bottom: 20px;
        }
        .lr-colorr{
          color: #fff;
        }
        
        
        .lr-text-atyour-1{
          color: #fff;
          margin-right: 14px;
          margin-bottom: 10px;
          font-size: 30px;
        }
      
        .lr-text-atyour-2{
          color: #fff;
          margin-right: 20px;
          margin-bottom: 10px;
          font-size: 30px;
        }
        .lr-text-atyour-3{
          color: #fff;
          margin-right: 25px;
          margin-bottom: 10px;
          font-size: 30px;
        }
        .lr-icon-just-1{
          font-size: 40px;
          margin-right:31px;
          color: #fff;
            }
        .lr-icon-just-2{
          font-size: 40px;
          margin-right:35px;
          color: #fff;
            }
        .lr-icon-just-3{
      font-size: 40px;
      margin-right:35px;
      color: #fff;
        }
        .lr-parra{
          font-size: 20px;
          text-align: left;
          color:white
         
      ;    
          letter-spacing: normal;
          font-family: "Catamaran", sans-serif;
        }
        .lr-slider-image{
          margin-top: 30px;
            width: 375px;
            margin-left: 20px;
            
        }
        .lr-slider{
          margin-left: 3px;
        }
        .lr-sectbod{
          border: 1px solid white;
          margin-top: 30px;
          margin-left: 50px;
          width: 370px;
          height: 160px;
          padding-top: 10px;
          background-color: rgb(245, 237, 237);
          border-radius: 5px;
        }
      
        .fade-in {
          animation: fadeInLeft 0.5s ease-in-out forwards;
        }
}
@media only screen and (min-width: 884px) and (max-width: 1027px) {  
  .lr-background-image-container {
    background-size: cover; /* This ensures that the background image covers the entire element */
    background-position: center; /* This centers the background image */
    height: 100vh; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color */
    font-size: 2rem; /* Adjust font size */
    text-align: center; /* Center-align text */
  }
 
  .lr-card {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(203, 193, 193, 0.5);
    padding: 20px;
    color: black;
    text-align: center;
    border-radius: 10px;
    width: 400px;
    height: 350px;
    padding-top: 50px;
  }
  .lr-text-2{
   font-size: 15px;
   
   
  }

  

  .lr-flex-containerr {

    display: flex;
    width: 47%;
    justify-content: space-between;
   
    justify-content: space-between; /* Adjust as needed */
  }
   
  .lr-textsection{
    background-color: white;
      height: 150px;
      width: 81%;
      border: 1px solid rgb(244, 240, 240);
      border-radius: 5px;
      margin-left: 10%;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
      margin-top: 570px;
  }
 

  .lr-horizontal-container{
    display: block;
    margin-left: -300px;
  }

  .lr-ico{
    font-size:50px;
    color:black;
    margin-bottom: 50px;
    margin-left: 30px;
    margin-top: 0px;

  }

  .lr-icoo{
    margin-left: 20px;
    margin-top: 20px;

    margin-bottom: 50px;
    color:black;
 
  }
  .lr-icooo{
    margin-left: 20px;
    margin-top: 80px;
    color:black;
    margin-bottom: 50px;
  }
  .lr-text-create-1 {
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    margin-right: 0px;
    margin-left: 0px;
padding-top: 0px;
  }
  .lr-text-create-2 {
    padding-top: 60px; 
  width: 200px;
    font-size: 25px;
    margin-left: 0px;
    margin-right: 50px;
    font-family: "Catamaran", sans-serif;
  }
  .lr-text-create-3 {
    padding-top: 80px;
    width: 200x;
    margin-left: 0px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
  }

  .lr-horizontal-item {
    height: 140px;
    display: flex;
      width: 500px;
      align-items: center;
      gap: 20px;
      margin-left: 100px;
  }
  .lr-secttext{
    margin-top: 50px;
    background-color: #08144c;
    height: 980px;
    width: 860px;
    border-radius: 20px;

    margin-left: 10px;
  }

  .lr-flex-item1{
    width: 500px;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
    margin-left: 190px;
    margin-top: 20px;
  }

  .lr-flex-item2{
    width: 465px;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    color: wheat;
    margin-top: 10px;
    margin-left: 180px;
  }
  .lr-flex-item3{
    width: 465px;
    padding: 20px;
    text-align: center;
    border: 3px solid;
    border-radius: 35px;
    margin-top: 10px;
    color: wheat;
    margin-left: 180px;
  }
  p {
    text-align: left; /* Align the text to the left */
    text-indent: 0; /* Remove any indentation */
  }
  .lr-icon-input {
    display: flex;
    align-items: center;
   
  }
 
  .lr-icon-input input {
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    border: none; /* Remove border */
    outline: none;
    color: black; /* Remove outline */
  }
 .lr-input-field {
  color: black;
 }
  .lr-card h2 {
    margin: 10px 0;
  }
 
  .lr-card hr {
    width: 50%;
    margin: 10px auto;
  }
  .lr-save{
    font-family: "Catamaran", sans-serif;
    color:rgb(64, 61, 61);
    font-size: 30px;
    margin-top: 6px;
  }
  .lr-btn{
    color:white;
    border-radius: 5px;
    width: 200px;
    height:40px;
    font-family: "Catamaran", sans-serif;
  }

  .lr-white{
    background-color: white;
    height:300px;
    width:100%;
    position:relative;

    margin-top: -200px;
  }
  .lr-text {
     padding-top: 20px;
    font-size: 35px;
    color: black;
    margin-top: 0px;
    text-align: center;
    font-family: "Catamaran", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
  .lr-flex-container {
    width: 100px;
    display: grid;
    
margin-top: 20px;
    justify-content: space-between;
    margin-left: -10px;
  }
  .lr-color{
    font-size: 20px;
   
  }

  .lr-flex-item {
    width: 73%;
      padding: 20px;
      text-align: center;
      margin-left: -3px;
      margin-top: 0px;
  }



 .lr-parra-5{
  font-size: 23px;
  font-family: "Catamaran", sans-serif;
 }
  .lr-flex-itemm {
    width: 30%;
    padding: 20px;
    text-align: center;
    margin-left: 143px;
  }



  .lr-color{
    color:black;
    font-family: "Catamaran", sans-serif;
  }
  .lr-texx{
    font-family: "Catamaran", sans-serif;
  }
  .lr-text-2{
    margin-right: 170px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }
  .lr-text-3{
    margin-right: 50px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }
  .lr-text-4{
    margin-right: 120px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Catamaran", sans-serif;
    color: black;
  }

  .lr-color-9{
    font-family: "Catamaran", sans-serif;
    margin-left: 10px;
    font-size: 20px;
  }
  .lr-icon-mdoutli{
    font-size: 30px;
    margin-right: 50px;
    color: black;
  }
  
  .lr-colorr{
    font-size: 20px;
    color:black;
    font-family: "Catamaran", sans-serif;
  }
  .lr-textt-1{
    width: 140px;
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 100px;
    margin-bottom: 10px;
  }
  .lr-textt-2{
    width: 200px;
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 100px;
    margin-bottom: 10px;
  }
  .lr-textt-3{
    width: 140px;
    font-size: 20px;
    font-family: "Catamaran", sans-serif;
    font-weight: 700;
    color: black;
    margin-right: 102px;
    margin-bottom: 10px;
  }
 
  .lr-section1{
    background-color: #f0e4e4;
    width: 100%;
    height: 250px;
    position: relative;
    z-index: -1;
    display: flex;
    margin-top: 0px;
    border-radius: 36px;
  }
  .lr-people{
    margin-top: 11px;
    margin-left: -4px;

  }
  .lr-carousel{
    border:1px solid black;
    width:300px;
    height:300px;
    margin-top:100px;
    margin-left: 200px;
  }
 
  .lr-video-player{
    border-radius: 10px;
    width: 700px;
    height: auto;
    margin-top: 20px;
    margin-left: 90px;
  }
  .lr-texx {
    font-size: 30px;
    margin-top: 50px;
    text-align: center;
    margin-left: 17%;
    color:black;
    width: 600px;
 
  }
  .lr-sect{
    display: grid;
  }
 
 
  
  .lr-test{
    text-align: center;
  }
  .lr-whitte{
    margin-top:50px;
    margin-left:360px;
  }

 

  .lr-test{
    font-size: 40px;
    color: #fff;
    font-family: "Catamaran", sans-serif;
    font-weight: 600px;
    
    margin-bottom: 20px;
  }
  .lr-colorr{
    color: #fff;
  }
  
  
  .lr-text-atyour-1{
    color: #fff;
    margin-right: 160px;
    margin-bottom: 10px;
    font-size: 30px;
  }

  .lr-text-atyour-2{
    color: #fff;
    margin-right: 90px;
    margin-bottom: 10px;
    font-size: 30px;
  }
  .lr-text-atyour-3{
    color: #fff;
    margin-right: 110px;
    margin-bottom: 10px;
    font-size: 30px;
  }
  .lr-icon-just-1{
    font-size: 40px;
    margin-right:31px;
    color: #fff;
      }
  .lr-icon-just-2{
    font-size: 40px;
    margin-right:35px;
    color: #fff;
      }
  .lr-icon-just-3{
font-size: 40px;
margin-right:35px;
color: #fff;
  }
  .lr-parra{
    font-size: 20px;
    text-align: left;
    color:white
   
;    
    letter-spacing: normal;
    font-family: "Catamaran", sans-serif;
  }
  .lr-slider-image{
    margin-top: 30px;
      width: 375px;
      margin-left: 20px;
      
  }
  .lr-slider{
    margin-left: 3px;
  }
  .lr-sectbod{
    border: 1px solid white;
    margin-top: 30px;
    margin-left: 50px;
    width: 370px;
    height: 160px;
    padding-top: 10px;
    background-color: rgb(245, 237, 237);
    border-radius: 5px;
  }

  .fade-in {
    animation: fadeInLeft 0.5s ease-in-out forwards;
  }
}
@media only screen and (min-width: 1028px) and (max-width: 1440px) {  

.lr-background-image-container {
  background-image: url('../Images/ld.png');
  background-size: cover; /* This ensures that the background image covers the entire element */
  background-position: center; /* This centers the background image */
  height: 100vh; /* Adjust the height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Text color */
  font-size: 2rem; /* Adjust font size */
  text-align: center; /* Center-align text */
}

.lr-card {
  position: absolute;
  top: 50%;
  left: 16%;
  transform: translate(-50%, -50%);
  background-color: rgba(203, 193, 193, 0.5); /* Semi-transparent white background */
  padding: 20px;
  color: black;
  text-align: center;
  border-radius: 10px;
  width: 300px;
  height: 350px;
  padding-top: 50px;
}

.lr-color{
  font-size: 17px;
}
p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}
.lr-icon-input {
  display: flex;
  align-items: center;
 
}

.lr-icon-input input {
  flex: 1;
  padding: 10px;
  margin-left: 10px;
  border: none; /* Remove border */
  outline: none;
  color: black; /* Remove outline */
}
.lr-input-field {
width: 100px;
color: black;
height: 40px;

}
.lr-card h2 {
  margin: 10px 0;
}

.lr-card hr {
  width: 50%;
  margin: 10px auto;
}
.lr-save{
  font-family: "Catamaran", sans-serif;
  color:rgb(64, 61, 61);
  font-size: 30px;
  margin-top: 6px;
}
.lr-btn {
  color: white;
  border-radius: 5px;
  width: 200px;
  height: 40px; /* Adjusted height for better visualization */
  background-color: #05144c;
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Added to ensure the link is displayed as a block */
  line-height: 40px; /* Centering the text vertically */
}

.lr-btn:hover {
  background-color: #05144c;
 
}

.lr-white{
  background-color: white;
  height:300px;
  width:100%;
  position:relative;
  margin-top: 70px;
}
.lr-text {
  font-size: 34px;
  color: black;
  margin-top: -30px;
  text-align: center;
  font-family: "Catamaran", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.lr-flex-container {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-left: 35px;
}

.lr-flex-item {
  width: 30%;
  padding: 20px;
  text-align: center;
  margin-left: 47px;
}


.lr-flex-item1{
  text-align: center;
  width: 30%;
  padding: 20px;
  text-align: center;
  border: 3px solid;
  border-radius: 35px;
  color: wheat;
}

.lr-flex-item2{
  text-align: center;
  width: 30%;
  padding: 20px;
  text-align: center;
  border: 3px solid;
  border-radius: 35px;
  color: wheat;
}
.lr-flex-item3{
  text-align: center;
  width: 30%;
  padding: 20px;
  text-align: center;
  border: 3px solid;
  border-radius: 35px;
  color: wheat;
}

.lr-flex-containerr {
  display: flex;
  width:100%;
 
  justify-content: space-between; /* Adjust as needed */
}
.lr-parra-5{
  padding-top: 10px;
  padding-left: 10px;
font-size: 23px;
font-family: "Catamaran", sans-serif;
}
.lr-flex-itemm {
  width: 30%;
  padding: 20px;
  text-align: center;
  margin-left: 143px;
}

.lr-text-create-1,.lr-text-create-2, .lr-text-create-3{
  font-size: 25px;
  font-family: "Catamaran", sans-serif;
}

.lr-textsection{
  background-color: white;
  height:100px;
  width:80%;
  border:1px solid rgb(244, 240, 240);
  border-radius: 5px;
  margin-left: 10%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  margin-top: 37px;
}
.lr-color{
  color:black;
  font-family: "Catamaran", sans-serif;
}
.lr-texx{
  font-family: "Catamaran", sans-serif;
}

.lr-text-2{
  width: 300px;
  margin-right: 170px;
  margin-bottom: 10px;
  font-size: 25px;
  font-family: "Catamaran", sans-serif;
  color: black;
}
.lr-text-3{
  width: 300px;
  margin-right: 50px;
  margin-bottom: 10px;
  font-size: 25px;
  font-family: "Catamaran", sans-serif;
  color: black;
}
.lr-text-4{
  width: 300px;
  margin-right: 120px;
  margin-bottom: 10px;
  font-size: 25px;
  font-family: "Catamaran", sans-serif;
  color: black;
}

.lr-color-9{
  font-family: "Catamaran", sans-serif;
  margin-left: 42px;
}
.lr-icon-mdoutli{
  margin-bottom: 10px;
  font-size: 30px;
  margin-left: 0px;
  color: black;
}

.lr-colorr{
  font-size: 20px;
  color:white;
  font-family: "Catamaran", sans-serif;
}
.lr-textt-1{
  font-size: 20px;
  font-family: "Catamaran", sans-serif;
  font-weight: 700;
  color: black;
  margin-right: 103px;
  margin-bottom: 10px;
}
.lr-textt-2{
  font-size: 20px;
  font-family: "Catamaran", sans-serif;
  font-weight: 700;
  color: black;
  margin-right: 36px;
  margin-bottom: 10px;
}
.lr-textt-3{
  font-size: 20px;
  font-family: "Catamaran", sans-serif;
  font-weight: 700;
  color: black;
  margin-right: 102px;
  margin-bottom: 10px;
}

.lr-section1{
  background-color: #f0e4e4;
  width: 100%;
  height: 340px;
  position: relative;
  z-index: -1;
  display: flex;
  margin-top: 20px;
  border-radius: 36px;
}
.lr-people{
  margin-top: 11px;
  margin-left: -4px;

}
.lr-carousel{
  border:1px solid black;
  width:300px;
  height:300px;
  margin-top:100px;
  margin-left: 200px;
}

.lr-video-player{
 border-radius: 10px;
  width:600px;
  height:auto;
  margin-top: 70px;
  margin-left: 100px;
}
.lr-texx {
  font-size: 50px;
  margin-top: 50px;
  text-align: center;
  margin-left: 6%;
  color:black;

}
.lr-sect{
  display:flex;
}
.lr-horizontal-container {
  display: block;
}

.lr-horizontal-item {
  display:flex;



}
.lr-horizontal-item {
  display: flex;
  width:450px;
  align-items: center; /* Align items vertically */
  gap: 20px; /* Add a gap of 20px between the icon and the text */
}
.lr-test{
  text-align: center;
}
.lr-whitte{
  margin-top:50px;
  margin-left:300px;
}
.lr-ico{
  font-size:50px;
  color:black;
  margin-bottom: 50px;
  margin-right: 0px;
}
.lr-horizontal-container{
  margin-left: -200px;
}
.lr-icoo{
  font-size:100px;
  margin-bottom: 70px;
  color:black;

}
.lr-icooo{
  font-size:80px;
  color:black;
  margin-bottom: 50px;
}
.lr-secttext{ 
  margin-left: 10px;
   border-radius: 10px;
width: 98%;
  margin-top:110px;
  background-color: #08144c;
  height: 430px;
}
.lr-test{
  font-size: 40px;
  color: #fff;
  font-family: "Catamaran", sans-serif;
  font-weight: 600px;
  
  margin-bottom: 20px;
}



.lr-text-atyour-1{
  color: #fff;
  margin-right: 70px;
  margin-bottom: 10px;
  font-size: 25px;
}

.lr-text-atyour-2{
  color: #fff;
  margin-right: 30px;
  margin-bottom: 10px;
  font-size: 24px;
}
.lr-text-atyour-3{
  color: #fff;
  margin-right: 25px;
  margin-bottom: 10px;
  font-size: 25px;
}
.lr-icon-just-1{
  font-size: 40px;
  margin-right:31px;
  color: #fff;
    }
.lr-icon-just-2{
  font-size: 40px;
  margin-right:35px;
  color: #fff;
    }
.lr-icon-just-3{
font-size: 40px;
margin-right:35px;
color: #fff;
}
.lr-parra{
  font-size: 20px;
  text-align: left;
  color:white
 
;    
  letter-spacing: normal;
  font-family: "Catamaran", sans-serif;
}
.lr-slider-image{
  border-radius: 20px;

  margin-top: 32px;
}
.lr-slider{
  margin-left: 115px;
}
.lr-sectbod{
  border: 1px solid white;
  margin-top: 90px;
  margin-left: 90px;
  width: 400px;
  height: 150px;
  padding-top: 10px;
  background-color: rgb(245, 237, 237);
  border-radius: 5px;
}

.lr-flex-item5{
  text-align: center;
  width: 30%;
  padding: 20px;
  text-align: center;
  border: 3px solid;
  border-radius: 35px;
  color: wheat;
}
.lr-flex-item6{
  text-align: center;
  width: 30%;
  padding: 20px;
  text-align: center;
  border: 3px solid;
  border-radius: 35px;
  color: wheat;
}
.lr-flex-item7{
  text-align: center;
  width: 30%;
  padding: 20px;
  text-align: center;
  border: 3px solid;
  border-radius: 35px;
  color: wheat;
}
.fade-in {
  animation: fadeInLeft 0.5s ease-in-out forwards;
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 2;
    transform: translateX(0);
  }
}}
    