

.lr-search-container {
  border: 1px solid #b4b1b1;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
 /* width: 1000px; */
  margin-left: 50px;
  margin-right: 50px; 
  margin-bottom: 550px;
  margin-top: -800px;
  height: 80px;
}

.lr-search-inputs {
  display: flex;
margin-left: 60px;
  gap: 20px;
}
.lr-sectp{
  border-radius: 30px;

  width:230px;
}
.lr-cal{
  border-radius: 30px;

  width:230px;
}
.lr-sectt{
  border-radius: 30px;

  width:230px;
}
.lr-gap1 {
  display: flex;
  gap: 10px;
}

.lr-sectt, .lr-sectp {
  display: flex;
  align-items: center;
  gap: 10px;
}

.lr-radio-icon {
  color: #333;
}

.lr-arrow-icon {
  font-size: 24px;
  color: #666;
  margin-top: 20px;
}


.lr-leaving {
  width: 100%;
}

.lr-calendar-icon {
  font-size: 24px;
  color: #666;
}

.lr-cal {
  border: 1px solid #bbb8b8;
width:230px;
height:60px;
padding-left:10px;
  font-size: 16px;
  border-radius: 4px;

}

.lr-passenger-input {
  display: flex;
  
  align-items: center;
  gap: 10px;
}

.lr-user-icon {
  font-size: 24px;
  color: #666;
}


.lr-passenger-count {
  border: none;
  background: none;
  background-color: white;;
  font-size: 16px;
  padding-left:10px;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 10px;

  width:200px;
  height:60px;
}

.lr-passenger-controls {
  display: flex;
  align-items: center;
margin-left: -100px;
}

.lr-passenger-minus-icon, .lr-passenger-plus-icon {
  cursor: pointer;
  font-size: 18px;
  color: #666;

}

.lr-passenger-customm {
border:none;
  padding: 5px;
  
  font-size: 16px;
  width: 40px;
  text-align: center;
  border-radius: 4px;
}

.lr-search {
  background-color: #05144c;
  color: #fff;
  border: none;
  padding: 15px;
  font-size: 18px;
  cursor: pointer;
  width:200px;
  height:60px;
  margin-left: 30px;
  margin-top: 0px;
  border-radius: 50px;
  text-decoration: none !important;outline: none; /* No outline on focus */
}
.lr-gap1{
  display:flex;
}
@media only screen and (min-width: 320px) and (max-width: 359px) {

  
  .lr-search-container {
    border: 1px solid #b4b1b1;
    border-radius: 50px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height:430px;
    padding-top:0px;
    margin-left: 10px;
    margin-right: 0px; 
    margin-bottom: 0px;
    margin-top: -10px;
    /* height: 100px; */
  }
  
  .lr-passenger-controls{
    margin-top: 10px;
  
  }
  .lr-passenger-customm{
    border:none;
   
  }
  .lr-search{
    margin-left: 30px;
    width:150px;
  }
    .lr-search-inputs {
      margin-left: 50px;

      flex-direction: column;
    }
  
    .lr-gap1 {
      width: 260px;
      flex-direction: column;
      margin-left: -55px;
  
    }
  .lr-passenger-count{
    width:270px;
    background-color:white;
  }
    .lr-sectt, .lr-sectp {
      width: 100%;
      margin-left: 20px;
      margin-top: 0px;
    }
    .lr-gap1,.lr-sectt, .lr-sectp{
      height: 150px;
    
    }
  
    .lr-cal {
      width: 100%;
    }
  
    .lr-passenger-input {
    display: flex;
    margin-left: 14px;
    margin-top: 10px;
      align-items: flex-start;
    }
    .lr-user-icon{
      margin-top: 14px;
    }
   
  
    .lr-arrow-icon{
      display:none;
    }
  }
@media only screen and (min-width: 360px) and (max-width: 374px) {

  
  .lr-search-container {
    border: 1px solid #b4b1b1;
    border-radius: 50px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 340px;
    height:430px;
    padding-top:0px;
    margin-left: 10px;
    margin-right: 0px; 
    margin-bottom: 0px;
    margin-top: -10px;
    /* height: 100px; */
  }
  
  .lr-passenger-controls{
    margin-top: 10px;
  
  }
  .lr-passenger-customm{
    border:none;
   
  }
  .lr-search{
    margin-left: 50px;
    width:150px;
  }
    .lr-search-inputs {
      flex-direction: column;
      margin-left: 50px;

    }
  
    .lr-gap1 {
      flex-direction: column;
      margin-left: -55px;
  
    }
  .lr-passenger-count{
    width:270px;
    background-color:white;
  }
    .lr-sectt, .lr-sectp {
      width: 100%;
      margin-left: 20px;
      margin-top: 0px;
    }
    .lr-gap1,.lr-sectt, .lr-sectp{
      height: 150px;
    
    }
  
    .lr-cal {
      width: 100%;
    }
  
    .lr-passenger-input {
    display: flex;
    margin-left: 14px;
    margin-top: 10px;
      align-items: flex-start;
    }
    .lr-user-icon{
      margin-top: 14px;
    }
   
  
    .lr-arrow-icon{
      display:none;
    }
  }
  @media only screen and (min-width: 375px) and (max-width: 389px) {

  
    .lr-search-container {
      border: 1px solid #b4b1b1;
      border-radius: 50px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 345px;
      height:430px;
      padding-top:0px;
      margin-left: 15px;
      margin-right: 0px; 
      margin-bottom: 0px;
      margin-top: -10px;
      /* height: 100px; */
    }
    
    .lr-passenger-controls{
      margin-top: 10px;
    
    }
    .lr-passenger-customm{
      border:none;
     
    }
    .lr-search{
      margin-left: 50px;
      width:150px;
    }
      .lr-search-inputs {
        margin-left: 50px;

        flex-direction: column;
      }
    
      .lr-gap1 {
        flex-direction: column;
        margin-left: -55px;
    
      }
    .lr-passenger-count{
      width:270px;
      background-color:white;
    }
      .lr-sectt, .lr-sectp {
        width: 100%;
        margin-left: 20px;
        margin-top: 0px;
      }
      .lr-gap1,.lr-sectt, .lr-sectp{
        height: 150px;
      
      }
    
      .lr-cal {
        width: 100%;
      }
    
      .lr-passenger-input {
      display: flex;
      margin-left: 14px;
      margin-top: 10px;
        align-items: flex-start;
      }
      .lr-user-icon{
        margin-top: 14px;
      }
     
    
      .lr-arrow-icon{
        display:none;
      }
    }

@media only screen and (min-width: 390px) and (max-width: 411px) {

  
      .lr-search-container {
        border: 1px solid #b4b1b1;
        border-radius: 50px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 350px;
        height:470px;
        padding-top:20px;
        margin-left: 20px;
        margin-right: 0px; 
        margin-bottom: 0px;
        margin-top: -15px;
        /* height: 100px; */
      }
      
      .lr-passenger-controls{
        margin-top: 10px;
      
      }
      .lr-passenger-customm{
        border:none;
       
      }
      .lr-search{
        margin-left: 50px;
        width:150px;
      }
        .lr-search-inputs {
          margin-left: 50px;

          flex-direction: column;
        }
      
        .lr-gap1 {
          flex-direction: column;
          margin-left: -55px;
      
        }
      .lr-passenger-count{
        width:270px;
        background-color:white;
      }
        .lr-sectt, .lr-sectp {
          width: 100%;
          margin-left: 20px;
          margin-top: 0px;
        }
        .lr-gap1,.lr-sectt, .lr-sectp{
          height: 150px;
        
        }
      
        .lr-cal {
          width: 100%;
        }
      
        .lr-passenger-input {
        display: flex;
        margin-left: 14px;
        margin-top: 10px;
          align-items: flex-start;
        }
        .lr-user-icon{
          margin-top: 14px;
        }
       
      
        .lr-arrow-icon{
          display:none;
        }
      }
@media only screen and (min-width: 412px) and (max-width: 427px) {

  
        .lr-search-container {
          border: 1px solid #b4b1b1;
          border-radius: 50px;
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 370px;
          height:470px;
          padding-top:20px;
          margin-left: 20px;
          margin-right: 0px; 
          margin-bottom: 0px;
          margin-top: -15px;
          /* height: 100px; */
        }
        
        .lr-passenger-controls{
          margin-top: 10px;
        
        }
        .lr-passenger-customm{
          border:none;
         
        }
        .lr-search{
          margin-left: 60px;
          width:150px;
        }
          .lr-search-inputs {
            margin-left: 50px;

            flex-direction: column;
          }
        
          .lr-gap1 {
            flex-direction: column;
            margin-left: -40px;
        
          }
        .lr-passenger-count{
          width:270px;
          background-color:white;
        }
          .lr-sectt, .lr-sectp {
            width: 100%;
            margin-left: 20px;
            margin-top: 0px;
          }
          .lr-gap1,.lr-sectt, .lr-sectp{
            height: 150px;
          
          }
        
          .lr-cal {
            width: 100%;
          }
        
          .lr-passenger-input {
          display: flex;
          margin-left: 14px;
          margin-top: 10px;
            align-items: flex-start;
          }
          .lr-user-icon{
            margin-top: 14px;
          }
         
        
          .lr-arrow-icon{
            display:none;
          }
        }
 @media only screen and (min-width: 428px) and (max-width: 767px) {

  
          .lr-search-container {
            border: 1px solid #b4b1b1;
            border-radius: 50px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 390px;
            height:470px;
            padding-top:20px;
            margin-left: 20px;
            margin-right: 0px; 
            margin-bottom: 0px;
            margin-top: 0px;
            /* height: 100px; */
          }
          
          .lr-passenger-controls{
            margin-top: 10px;
          
          }
          .lr-passenger-customm{
            border:none;
          }
          .lr-search{
            margin-left: 70px;
            width:150px;
          }
            .lr-search-inputs {
              flex-direction: column;
              margin-left: 50px;

            }
          
            .lr-gap1 {

              width: 300px;
              flex-direction: column;
              margin-left: -35px;
          
            }
          .lr-passenger-count{
            width:270px;
            background-color:white;
          }
            .lr-sectt, .lr-sectp {
              width: 100%;
              margin-left: 20px;
              margin-top: 0px;
            }
            .lr-gap1,.lr-sectt, .lr-sectp{
              height: 150px;
            
            }
          
            .lr-cal {
              width: 100%;
            }
          
            .lr-passenger-input {
            display: flex;
            margin-left: 14px;
            margin-top: 10px;
              align-items: flex-start;
            }
            .lr-user-icon{
              margin-top: 14px;
            }
           
          
            .lr-arrow-icon{
              display:none;
            }
          }

@media only screen and (min-width: 768px) and (max-width: 799px) {
  .lr-gap1{
    display: block;
margin-left: 0px;


  }
  .lr-passenger-count{
 
    width:250px;
  }
  .lr-passenger-customm{
    border:none;
  }
  .lr-arrow-icon{
    display:none;
  }
  .lr-search-inputs{
    margin-left: 20px;
 
  
  }
  .lr-calendar-icon{
    font-size: 24px;
    color: #666;
    margin-left: 20px;
  }
  .lr-cal{
    margin-left: 0px;
    margin-top: 10px;
 
  }
  .lr-passenger-input{
    margin-top: 20px;
margin-left: 20px;
  }
  .lr-sectt{
    margin-top: 10px;
    width:320px;
  }
  .lr-sectp{
    width:320px;
  }
  .lr-search{
    margin-top: 180px;
    margin-left: -420px;
    width:160px;
  }
  .lr-search-container{
    height:290px;
    width:90%;
    padding-top:0px;
    margin-bottom: 80px;
    margin-top: -100px;
    margin-left: 40px;
  }
  .lr-leaving{
    margin-top: 15px;
 
  }


}
@media only screen and (min-width: 768px) and (max-width: 819px) {
  .lr-gap1{
    display: block;
margin-left: 0px;


  }
  .lr-passenger-count{
 
    width:250px;
  }
  .lr-passenger-customm{
    border:none;
  }
  .lr-arrow-icon{
    display:none;
  }
  .lr-search-inputs{
    margin-left: 20px;
 
  
  }
  .lr-calendar-icon{
    font-size: 24px;
    color: #666;
    margin-left: 20px;
  }
  .lr-cal{
    margin-left: 0px;
    margin-top: 10px;
 
  }
  .lr-passenger-input{
    margin-top: 20px;
margin-left: 20px;
  }
  .lr-sectt{
    margin-top: 10px;
    width:320px;
  }
  .lr-sectp{
    width:320px;
  }
  .lr-search{
    margin-top: 180px;
    margin-left: -420px;
    width:160px;
  }
  .lr-search-container{
    height:290px;
    width:90%;
    padding-top:0px;
    margin-bottom: 80px;
    margin-top: -100px;
    margin-left: 40px;
  }
  .lr-leaving{
    margin-top: 15px;
 
  }


}
@media only screen and (min-width:820px) and (max-width: 883px) {
  .lr-gap1{
    display: block;
margin-left: 0px;


  }
  .lr-passenger-count{
 
    width:250px;
  }
  .lr-passenger-customm{
    border:none;
  }
  .lr-arrow-icon{
    display:none;
  }
  .lr-search-inputs{
    margin-left: 40px;
 
  
  }
  .lr-calendar-icon{
    font-size: 24px;
    color: #666;
    margin-left: 20px;
  }
  .lr-cal{
    margin-left: 0px;
    margin-top: 10px;
 
  }
  .lr-passenger-input{
    margin-top: 20px;
margin-left: 20px;
  }
  .lr-sectt{
    margin-top: 10px;
    width:320px;
  }
  .lr-sectp{
    width:320px;
  }
  .lr-search{
    margin-top: 180px;
    margin-left: -420px;
    width:160px;
  }
  .lr-search-container{
    height:290px;
    width:90%;
    padding-top:0px;
    margin-bottom: 80px;
    margin-top: -100px;
    margin-left: 40px;
  }
  .lr-leaving{
    margin-top: 15px;
 
  }


}
@media only screen and (min-width: 884px) and (max-width: 1279px) {
  .lr-gap1{
    display: block;
margin-left: 20px;


  }
  .lr-passenger-count{
 
    width:250px;
  }
  .lr-passenger-customm{
    border:none;
  }
  .lr-arrow-icon{
    display:none;
  }
  .lr-search-inputs{
    margin-left: 30px;
 
  
  }
  .lr-calendar-icon{
    font-size: 24px;
    color: #666;
    margin-left: 20px;
  }
  .lr-cal{
    margin-left: 0px;
    margin-top: 10px;
 
  }
  .lr-passenger-input{
    margin-top: 20px;
margin-left: 20px;
  }
  .lr-sectt{
    margin-top: 10px;
    width:320px;
  }
  .lr-sectp{
    width:320px;
  }
  .lr-search{
    margin-top: 180px;
    margin-left: -420px;
    width:160px;
  }
  .lr-search-container{
    height:290px;
    width:88%;
    padding-top:0px;
    margin-bottom: 80px;
    margin-top: -60px;
  }
  .lr-leaving{
    margin-top: 15px;
 
  }


}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
 

.lr-search-container {
  border: 1px solid #b4b1b1;
  border-radius: 30px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
 
  margin-left: 50px;
  margin-right: 50px; 
  margin-bottom: 550px;
  margin-top: -770px;
  height: 80px;
}

.lr-search-inputs {
  display: flex;
margin-left: 50px;
  gap: 20px;
}
.lr-sectp{
  width:230px;
}
.lr-cal{
  width:230px;
}

.lr-gap1 {
  display: flex;
  gap: 10px;
 
}

.lr-sectt, .lr-sectp {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: -10px;
  width:195px;
}

.lr-radio-icon {
  color: #333;
}

.lr-arrow-icon {
  font-size: 24px;
  color: #666;
  margin-top: 20px;
}


.lr-leaving {
  width: 100%;
}

.lr-calendar-icon {
  font-size: 24px;
  color: #666;
}

.lr-cal {
  border: 1px solid #ddd;
width:250px;
height:60px;
padding-left:10px;
  font-size: 16px;
  border-radius: 4px;

}

.lr-passenger-input {
  display: flex;
  
  align-items: center;
  gap: 10px;
}

.lr-user-icon {
  font-size: 24px;
  color: #666;
}


.lr-passenger-count {
  border: none;
  background: none;
  background-color: white;;
  font-size: 16px;
  padding-left:10px;
  color: #333;
  border: 1px solid #ddd;
  width:190px;
  height:60px;
}

.lr-passenger-controls {
  display: flex;
  align-items: center;
margin-left: -100px;
}

.lr-passenger-minus-icon, .lr-passenger-plus-icon {
  cursor: pointer;
  font-size: 18px;
  color: #666;
}

.lr-passenger-customm {
border:none;
  padding: 5px;
  
  font-size: 16px;
  width: 40px;
  text-align: center;
  border-radius: 4px;
}

.lr-search {
  background-color: #05144c;
  color: #fff;
  border: none;
  padding: 15px;
  font-size: 18px;
  cursor: pointer;
  width:190px;
  height:60px;
  margin-left: 30px;
  margin-top: 0px;
  border-radius: 50px;
  text-decoration: none !important;outline: none; /* No outline on focus */
}
.lr-gap1{
  display:flex;
}


}