.lrd-main {
  display: block;
  justify-content: center;
  align-items: center;
  
  min-height: 100vh;
  height: 100vh;
  background-color: #f8f9fa;
  border: 1px solid white;
}

.lrd-bd {
  margin-top: 150px;
  margin-left: 45%;
  font-family: 'Times New Roman', Times, serif;
}

.lrd-bdd {

margin-left: -30px;
width:100%;
  font-family: 'Times New Roman', Times, serif;
}


.lrd-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 600px;
  height: 400px;
  padding: 20px;
  margin: 20px;
  margin-top: 200px;
  padding-left: 220px;
  padding-top: 35px;
  margin-left: 30%;
  text-align: center;
}

.lrd-head {
  text-align: center;
  margin-bottom: 20px;
}

.lrd-mainn {
  border: 1px solid white;
}

.lrd-cardd {
  background-color: #fff;
  margin-top: 350px;
  text-align: center;
  border: 1px solid rgb(240, 235, 235);
  width: 500px;
  box-shadow: #fff;
  margin-left: 35%;

  height: 110px;
  border-radius: 10px;
  padding-left: 90px;
  /* padding-top: -30px; */
}

.ride-id {
  font-size: 1.2em;
  font-weight: bold;
}

.lrd-details p {
  margin: 10px 0;
  font-size: 1em;
  display: block;
  text-align: left;
}

.lrd-details-left, .lrd-details-right {
  flex: 1;
  margin-top: -20px;
}

.lrd-details-left p, .lrd-details-right p {
  margin: 5px 0;
}

.lrd-btn-delete {
  background-color: #05144c;
  /* padding: 20px 20px; */
  border: 1px solid black;
 
  color: white;
  border-radius: 50px;
  height: 50px;
  width: 180px;
  margin-left: -25px;
  margin-top: 30px;
  text-decoration: none !important;outline: none; /* No outline on focus */

}

.lrd-close {
  margin-top: 10px;
  margin-left: 120px;
  padding: 5px 10px;
  background-color: #05144c; 
   color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none !important;outline: none; /* No outline on focus */

}

.lrd-details {
  color: #333;
  font-weight: 400;
  display: block;
  text-align: left;
}

.lr-cancel-button {
  display: block;
  width: 52%;
  height: 40px;
  padding: 10px;
  margin-top: 20px;
  margin-left: 100px;
  background-color: #001f3f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.lr-cancel-button:hover {
  background-color: #001f3f;
}


@media only screen and (max-width: 768px) {
  .lrd-main {
      display: block;
      justify-content: center;
      align-items: center;
      height: 200%;
      background-color: #f8f9fa;
  }

  .lrd-bd {
      margin-left: 100px;
  }
  .lrd-bdd {
    margin-left: -30px;
    font-size: 30px;
}
  .lrd-card {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 90%;
      height: auto;
      padding: 20px;
      padding-left: 27%;
      text-align: center;
      margin: 20px auto;
  }
  .lrd-details5{
    width: 390px;
  }

  .lrd-head {
      text-align: center;
      margin-bottom: 20px;
  }

  .lrd-details {
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .lrd-details-left, .lrd-details-right {
      width: 100%;
      margin-bottom: 10px;
  }

  .lrd-details-left p, .lrd-details-right p {
      margin: 5px 0;
  }

  .lr-cancel-button {
      width: 100%;
      max-width: 200px;
      height: 40px;
      padding: 10px;
      margin-top: 20px;
      background-color: #001f3f;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }

  .lr-cancel-button:hover {
      background-color: #001f3f;
  }
  .lrd-cardd {

    margin-top: 300px;
    height: 150px;
    

    width: 350px;
   
    margin-left: 5%;
 
  
    /* padding-top: -30px; */
  }
  .lrd-close {
    margin-top: 10px;
    margin-left: -70px;
    padding: 5px 10px;
    background-color: #05144c; 
     color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    text-decoration: none !important;outline: none; /* No outline on focus */
  
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .lrd-card {
      margin-left: 100px;
  }
  .lrd-bdd {
    margin-left: -15px;
    font-size: 30px;
}

  .lrd-bd {
      margin-left: 300px;
  }
  .lrd-details {
  
    margin-right: 0px;
}
.lrd-cardd {

  margin-top: 350px;
  
 
  width: 500px;
 
  margin-left: 20%;
}
}
