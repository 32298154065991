
  .fp_forgetboxouter {
    height: 100vh;
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(to bottom, white, black);
 
  }
  .fp_forgetboxoute-imges{
    width:700px;
    height:500px;
    margin-left: 600px;
    margin-top: -350px;
    padding-bottom: 00px;
 
  }
 
  .fp_previous_but {
    width: 150px;
  height: 30px;
  cursor: pointer;
  margin-right: 900px;
  margin-top: 180px;
  margin-bottom: 10px;
  }
 
  .fp_previous_but :hover{
    text-align: center;
    transform: scale(1.1);
  }
 
  .fp_back{
    margin-top: 500px;
  }
 
  
  .fp_forgetpass {
    background: rgba(255, 255, 255, 0.9);
    padding: 30px;
    margin-top: 0px;
    margin-right: 900px;
   
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 350px;
    text-align: center;
    color: #333;
  }
 
  .fp_forgetpass input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
 
  .fp_forgetpass button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    background: #05144c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s;
  }
 
  .fp_forgetpass button:hover {
    background:   #05144c;
  }
 
  .fp_forgetpass button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
 
  .fp_forgetpass p {
    margin: 0;
    font-size: 14px;
    color: #333;
  }
 
  .fp_forgetpass a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
  }
 
  .fp_forgetpass a:hover {
    text-decoration: underline;
  }
 
  .fp_forgetpass input::placeholder {
    color: #999;
  }
 
 
@media only screen and (min-width: 320px) and (max-width: 343px) {
 
  .fp_forgetboxoute-imges{
    width:200px;
    height:100px;
    margin-left: 0px;
    margin-top: 50px;
 
  }
 
  .fp_forgetpass {
    margin-top: 0px;
    margin-right: 0px;
    width: 250px;
  }
 
  .fp_previous_but {
  margin-right: 0px;
  margin-top: 10px;
  }
}
 
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .fp_forgetboxoute-imges{
    width:300px;
    height:150px;
    margin-left: 0px;
    margin-top: 50px;
 
  }
 
  .fp_forgetpass {
    margin-top: 0px;
    margin-right: 0px;
    width: 250px;
  }
 
  .fp_previous_but {
  margin-right: 0px;
  margin-top: 10px;
  }
}
 
@media only screen and (min-width: 375px) and (max-width: 383px) {
  .fp_forgetboxoute-imges{
    width:300px;
    height:150px;
    margin-left: 0px;
    margin-top: 50px;
  }
 
  .fp_forgetpass {
    margin-top: 0px;
    margin-right: 0px;
    width: 250px;
  }
 
  .fp_previous_but {
  margin-right: 0px;
  margin-top: 10px;
  }
}
 
@media only screen and (min-width: 390px) and (max-width: 411px) {
  .fp_forgetboxoute-imges{
    width:300px;
    height:150px;
    margin-left: 0px;
    margin-top: 50px;
 
  }
 
  .fp_forgetpass {
    margin-top: 0px;
    margin-right: 0px;
    width: 250px;
  }
 
  .fp_previous_but {
  margin-right: 0px;
  margin-top: 10px;
  }
}
 
@media only screen and (min-width: 412px) and (max-width: 767px) {
  .fp_forgetboxoute-imges{
    width:350px;
    height:200px;
    margin-left: 0px;
    margin-top: 50px;
 
  }
 
  .fp_forgetpass {
    margin-top: 0px;
    margin-right: 0px;
    width: 300px;
  }
 
  .fp_previous_but {
  margin-right: 0px;
  margin-top: 10px;
  }
}
 
@media only screen and (min-width: 428px) and (max-width: 767px) {
  .fp_forgetboxoute-imges{
    width:350px;
    height:200px;
    margin-left: 0px;
    margin-top: 50px;
 
  }
 
  .fp_forgetpass {
    margin-top: 0px;
    margin-right: 0px;
    width: 300px;
  }
 
  .fp_previous_but {
  margin-right: 0px;
  margin-top: 50px;
  }
}
 
@media only screen and (min-width: 768px) and (max-width: 819px) {
  .fp_forgetboxoute-imges{
    width:550px;
    height:300px;
    margin-left: 0px;
    margin-top: 50px;
 
  }
 
  .fp_forgetpass {
    margin-top: 0px;
    margin-right: 0px;
    width: 400px;
  }
 
  .fp_previous_but {
  margin-right: 0px;
  margin-top: 0px;
  }
}
 
@media only screen and (min-width: 820px) and (max-width: 833px) {
  .fp_forgetboxoute-imges{
    width:650px;
    height:350px;
    margin-left: 0px;
    margin-top: 50px;
 
  }
 
  .fp_forgetpass {
    margin-top: 0px;
    margin-right: 0px;
    width: 400px;
  }
 
  .fp_previous_but {
  margin-right: 0px;
  margin-top: 0px;
  }
}
 
@media only screen and (min-width: 834px) and (max-width: 883px) {
  .fp_forgetboxoute-imges{
    width:650px;
    height:350px;
    margin-left: 0px;
    margin-top: 50px;
 
  }
 
  .fp_forgetpass {
    margin-top: 0px;
    margin-right: 0px;
    width: 400px;
  }
 
  .fp_previous_but {
  margin-right: 0px;
  margin-top: 0px;
  }
}
 
@media only screen and (min-width: 884px) and (max-width: 1279px) {
  .fp_forgetboxoute-imges{
    width:650px;
    height:350px;
    margin-left: 0px;
    margin-top: 50px;
 
  }
 
  .fp_forgetpass {
    margin-top: 0px;
    margin-right: 0px;
    width: 400px;
  }
 
  .fp_previous_but {
  margin-right: 0px;
  margin-top: 0px;
  }
}
 
@media only screen and (min-width: 1280px) and (max-width: 1440px) {}
 