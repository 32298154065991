.lg-help {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  margin-top: 70px;
  margin-bottom: 30px;
  color:black;
  font-family: 'Times New Roman', Times, serif;
}

.lg-flex-container {
  display: flex;
  flex-wrap: wrap; /* Allow contents to wrap to the next row */
  justify-content: space-between; /* Adjust spacing between contents */
}

.lg-content-1 {
  width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
  height: 300px; /* Set a fixed height for each content block */
  padding: 50px;
  margin-bottom: 20px;
  border: 0px solid #bab5b5;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 100px;
  font-size:17px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.lg-more-hr{
  margin-top: 10px;
}
.lg-content-2 {
  width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
  height: 400px; /* Set a fixed height for each content block */
  padding: 50px;
  margin-bottom: 20px;
  border: 0px solid #bab5b5;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 80px;

  font-size:17px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.lg-content-3 {
  width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
  height: 500px; /* Set a fixed height for each content block */
  padding: 50px;
  margin-bottom: 20px;
  border: 0px solid #bab5b5;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 100px;
  margin-top: -190px;
  font-size:17px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.lg-content-4 {
  width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
  height: 400px; /* Set a fixed height for each content block */
  padding: 50px;
  margin-bottom: 20px;
  border: 0px solid #bab5b5;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 80px;
  font-size:17px;
  margin-top: -190px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.lg-content-5 {
  width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
  height: 300px; /* Set a fixed height for each content block */
  padding: 50px;
  margin-bottom: 20px;
  border: 0px solid #bab5b5;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 100px;
  margin-top: -260px;
  font-size:17px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.lg-content-6 {
  width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
  height: 280px; /* Set a fixed height for each content block */
  padding: 50px;
  margin-bottom: 20px;
  border: 0px solid #bab5b5;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 80px;
  font-size:17px;
  margin-top: -220px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.lg-howdo-1{
  margin-bottom: 20px;
  font-size: 20px;
}
.lg-content h4 {
  margin-top: 0;
  font-size:20px;
  font-weight: 500;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 10px;

}

.lr-Readour-1 {
width: 240px;
background-color: rgb(7, 52, 125); /* Blue color */
color: white; /* Text color set to white */
border-radius: 50px; /* Rounded corners */
padding: 20px 20px; /* Padding adjusted */
cursor: pointer; /* Cursor changes to pointer on hover */
margin-left: 700px;
text-decoration: none !important;outline: none; /* No outline on focus */
}




@media only screen and (min-width: 240px) and (max-width: 319px) {  
  .lg-flex-container{

    display:block;
    overflow: hidden;
width: 850px;
  }
 
  .lg-help{
    
width: 500px;
    margin-top: 30px;
    margin-left: 170px;
    font-size: 50px;
  }
  .lg-content-1{

    font-size: 35px;

    width:870px;
    height:650px;
    margin-left: 10px;
    margin-top: -50px;
    
  }
  .lg-content-2{

    font-size: 35px;
    width:870px;
    height:700px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -80px;
  }
  .lg-content-3{
  
    font-size: 35px;
    width:870px;
    height:800px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -300px;
  }
  .lg-content-4{
    font-size: 35px;

    width:870px;
    height:800px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -320px;
  }
  .lg-content-5{
    font-size: 35px;
    width:870px;
    height:700px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -310px;
  }
  .lg-content-6{
    font-size: 35px;
    width:870px;
    height:570px;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-top: -320px;
    
  }
  .lg-howdo-1{
    margin-left: -15px;

width: 800px;
    font-size: 40px;

  }
  .lg-bt,
  .lg-btt{


    margin-left: 100px;
  }
  .lg-more-btn-3 {


    margin-left: 25%;
  }

  .lr-Readour-1{
    border:1px solid rgb(214, 18, 18);

    width: 340px;
    border: none;
font-size: 30px;
    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 250px;
    margin-top: 20px;
  
}
}
@media only screen and (min-width: 320px) and (max-width: 343px) {  
  .lg-flex-container{
    display:block;
    overflow: hidden;

  }
  .lg-help{
    

    margin-top: 30px;
    font-size: 30px;
  }
  .lg-content-1{


    width:400px;
    height:430px;
    margin-left: -40px;
    margin-top: -50px;
    
  }
  .lg-content-2{
  

    width:400px;
    height:560px;
    margin-left: -40px;
    margin-bottom: 250px;
    margin-top: -60px;
  }
  .lg-content-3{
  

    width:400px;
    height:470px;
    margin-left: -40px;
    margin-bottom: 250px;
    margin-top: -420px;
  }
  .lg-content-4{


    width:400px;
    height:500px;
    margin-left: -40px;
    margin-bottom: 250px;
    margin-top: -280px;
  }
  .lg-content-5{

    width:400px;
    height:450px;
    margin-left: -40px;
    margin-bottom: 250px;
    margin-top: -310px;
  }
  .lg-content-6{
    width:400px;
    height:350px;
    margin-left: -40px;
    margin-bottom: 0px;
    margin-top: -290px;
    
  }
  .lg-howdo-1{

width: 300px;
    font-size: 20px;

  }
  .lg-bt,
  .lg-btt{


    margin-left: 100px;
  }
  .lg-more-btn-3 {
  

    margin-left: 25%;
  }

  .lr-Readour-1{

    width: 240px;
    border: none;

    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 40px;
    margin-top: 20px;
  
}
}
@media only screen and (min-width: 344px) and (max-width: 352px) {  
  .lg-flex-container{
    display:block;
    overflow: hidden;

  }
  .lg-help{
    
    margin-left: -20px;

    margin-top: 20px;
    font-size: 25px;
  }
  .lg-content-1{


    width:400px;
    height:430px;
    margin-left: -30px;
    margin-top: -50px;
    
  }
  .lg-content-2{
  

    width:400px;
    height:560px;
    margin-left: -30px;
    margin-bottom: 250px;
    margin-top: -60px;
  }
  .lg-content-3{
  

    width:400px;
    height:470px;
    margin-left: -30px;
    margin-bottom: 250px;
    margin-top: -420px;
  }
  .lg-content-4{


    width:400px;
    height:500px;
    margin-left: -30px;
    margin-bottom: 250px;
    margin-top: -280px;
  }
  .lg-content-5{

    width:400px;
    height:450px;
    margin-left: -30px;
    margin-bottom: 250px;
    margin-top: -310px;
  }
  .lg-content-6{
    width:400px;
    height:370px;
    margin-left: -30px;
    margin-bottom: 0px;
    margin-top: -290px;
    
  }
  .lg-howdo-1{

width: 300px;
    font-size: 20px;

  }
  .lg-bt,
  .lg-btt{


    margin-left: 100px;
  }
  .lg-more-btn-3 {
  

    margin-left: 25%;
  }

  .lr-Readour-1{

    width: 200px;
    border: none;

    border-radius: 50px; 
    padding: 10px 20px; 
    cursor: pointer;
    margin-left: 65px;
    margin-top: 20px;
  
}
}
@media only screen and (min-width: 353px) and (max-width: 359px) {  
  .lg-flex-container{
    display:block;
    overflow: hidden;

  }
  .lg-help{
    

    margin-top: 30px;
    font-size: 30px;
  }
  .lg-content-1{


    width:400px;
    height:430px;
    margin-left: -20px;
    margin-top: -50px;
    
  }
  .lg-content-2{
  

    width:400px;
    height:560px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -60px;
  }
  .lg-content-3{
  

    width:400px;
    height:470px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -420px;
  }
  .lg-content-4{


    width:400px;
    height:500px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -280px;
  }
  .lg-content-5{

    width:400px;
    height:450px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -310px;
  }
  .lg-content-6{
    width:400px;
    height:350px;
    margin-left: -20px;
    margin-bottom: 0px;
    margin-top: -290px;
    
  }
  .lg-howdo-1{
    margin-left: -10px;

width: 300px;
    font-size: 20px;

  }
  .lg-bt,
  .lg-btt{


    margin-left: 100px;
  }
  .lg-more-btn-3 {
  

    margin-left: 25%;
  }

  .lr-Readour-1{

    width: 240px;
    border: none;

    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 40px;
    margin-top: 20px;
  
}
}
@media only screen and (min-width: 360px) and (max-width: 374px) {  

  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
width: 350px;
    margin-top: 10px;
    font-size: 25px;
  }
  .lg-content-1{

    width:420px;
    height:500px;
    margin-left: -20px;
    margin-top: -60px;
    
  }
  .lg-content-2{

    width:420px;
    height:500px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -130px;
  }
  .lg-content-3{

    width:420px;
    height:600px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -380px;
  }
  .lg-content-4{

    width:420px;
    height:650px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -430px;
  }
  .lg-content-5{

    width:420px;
    height:600px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -460px;
  }
  .lg-content-6{

    width:420px;
    height:400px;
    margin-left: -20px;
    margin-bottom: 0px;
    margin-top: -460px;
    
  }
  .lg-howdo-1{

    font-size: 20px;
    margin-left: -10px;
    width: 330px;
  }
  .lg-bt,
  .lg-btt{

    margin-left: 100px;
  }
  .lg-more-btn-3 {

    margin-left: 25%;
  }

  .lr-Readour-1{


    width: 200px;
    border: none;
    
    border-radius: 50px; 
    padding: 10px 10px; 
    cursor: pointer;
    margin-left: 80px;
    margin-top: 0px;
  
}


}
@media only screen and (min-width: 375px) and (max-width: 383px) {  

  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
width: 350px;
    margin-top: 10px;
    font-size: 25px;
  }
  .lg-content-1{

    width:400px;
    height:500px;
    margin-left: -10px;
    margin-top: -50px;
    
  }
  .lg-content-2{

    width:400px;
    height:500px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -130px;
  }
  .lg-content-3{

    width:400px;
    height:600px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -360px;
  }
  .lg-content-4{

    width:400px;
    height:650px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -410px;
  }
  .lg-content-5{

    width:400px;
    height:600px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -460px;
  }
  .lg-content-6{

    width:400px;
    height:400px;
    margin-left: -10px;
    margin-bottom: 0px;
    margin-top: -460px;
    
  }
  .lg-howdo-1{

    font-size: 20px;
    margin-left: -10px;
    width: 320px;
  }
  .lg-bt,
  .lg-btt{

    margin-left: 100px;
  }
  .lg-more-btn-3 {

    margin-left: 25%;
  }

  .lr-Readour-1{


    width: 200px;
    border: none;
    
    border-radius: 50px; 
    padding: 10px 10px; 
    cursor: pointer;
    margin-left: 80px;
    margin-top: 0px;
  
}


}
@media only screen and (min-width: 384px) and (max-width: 389px) {  

  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
width: 350px;
    margin-top: 10px;
    font-size: 25px;
  }
  .lg-content-1{

    width:400px;
    height:500px;
    margin-left: -10px;
    margin-top: -50px;
    
  }
  .lg-content-2{

    width:400px;
    height:500px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -130px;
  }
  .lg-content-3{

    width:400px;
    height:600px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -360px;
  }
  .lg-content-4{

    width:400px;
    height:650px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -410px;
  }
  .lg-content-5{

    width:400px;
    height:600px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -460px;
  }
  .lg-content-6{

    width:400px;
    height:400px;
    margin-left: -10px;
    margin-bottom: 0px;
    margin-top: -460px;
    
  }
  .lg-howdo-1{

    font-size: 20px;
    margin-left: -10px;
    width: 320px;
  }
  .lg-bt,
  .lg-btt{

    margin-left: 100px;
  }
  .lg-more-btn-3 {

    margin-left: 25%;
  }

  .lr-Readour-1{


    width: 200px;
    border: none;
    
    border-radius: 50px; 
    padding: 10px 10px; 
    cursor: pointer;
    margin-left: 80px;
    margin-top: 0px;
  
}


}
@media only screen and (min-width: 390px) and (max-width: 413px) {  

  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
    margin-left: 20px;
width: 350px;
    margin-top: 10px;
    font-size: 25px;
  }
  .lg-content-1{

    width:450px;
    height:500px;
    margin-left: -20px;
    margin-top: -50px;
    
  }
  .lg-content-2{

    width:450px;
    height:500px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -190px;
  }
  .lg-content-3{

    width:450px;
    height:600px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -420px;
  }
  .lg-content-4{

    width:450px;
    height:650px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -470px;
  }
  .lg-content-5{

    width:450px;
    height:600px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -490px;
  }
  .lg-content-6{

    width:450px;
    height:380px;
    margin-left: -20px;
    margin-bottom: 0px;
    margin-top: -520px;
    
  }
  .lg-howdo-1{

    font-size: 20px;
    margin-left: -5px;
    width: 350px;
  }
  .lg-bt,
  .lg-btt{

    margin-left: 100px;
  }
  .lg-more-btn-3 {

    margin-left: 25%;
  }

  .lr-Readour-1{


    width: 200px;
    border: none;
    
    border-radius: 50px; 
    padding: 10px 10px; 
    cursor: pointer;
    margin-left: 95px;
    margin-top: -15px;
  
}


}
@media only screen and (min-width: 414px) and (max-width: 479px) {  

  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
width: 350px;
    margin-top: 10px;
    font-size: 25px;
    margin-left: 20px;

  }
  .lg-content-1{

    width:450px;
    height:500px;
    margin-left: -10px;
    margin-top: -50px;
    
  }
  .lg-content-2{

    width:450px;
    height:500px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -200px;
  }
  .lg-content-3{

    width:450px;
    height:600px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -400px;
  }
  .lg-content-4{

    width:450px;
    height:650px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -450px;
  }
  .lg-content-5{

    width:450px;
    height:600px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -480px;
  }
  .lg-content-6{

    width:450px;
    height:370px;
    margin-left: -10px;
    margin-bottom: 0px;
    margin-top: -500px;
    
  }
  .lg-howdo-1{

    font-size: 20px;
    margin-left: -10px;
    width: 380px;
  }
  .lg-bt,
  .lg-btt{

    margin-left: 100px;
  }
  .lg-more-btn-3 {

    margin-left: 25%;
  }

  .lr-Readour-1{


    width: 200px;
    border: none;
    
    border-radius: 50px; 
    padding: 10px 10px; 
    cursor: pointer;
    margin-left: 100px;
    margin-top: 0px;
  
}


}
@media only screen and (min-width: 480px) and (max-width: 539px) {
  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
    margin-top: 20px;
    font-size: 30px;
    margin-right: 20px;
  }
  .lg-content-1{
    width:500px;
    height:450px;
    margin-left: 0px;
    margin-top: -50px;
    font-size: 20px;
    
  }
  .lg-content-2{
    width:500px;
    height:600px;
    margin-left: 0px;
    margin-bottom: 250px;
    margin-top: -90px;
    font-size: 20px;
  }
  .lg-content-3{
    width:500px;
    height:550px;
    margin-left: 0px;
    margin-bottom: 250px;
    margin-top: -440px;
    font-size: 20px;
  }
  .lg-content-4{
    width:500px;
    height:550px;
    margin-left: 0px;
    margin-bottom: 250px;
    margin-top: -370px;
    font-size: 20px;
  }
  .lg-content-5{
    width:500px;
    height:500px;
    margin-left: 0px;
    margin-bottom: 250px;
    margin-top: -340px;
    font-size: 20px;
  }
  .lg-content-6{
    width:500px;
    height:400px;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: -350px;
    font-size: 20px;
    
  }

  .lg-howdo-1{
    font-size: 25px;
    margin-left: -20px;
    width: 450px;
  }
  .lr-Readour-1{

    width: 200px;
    border: none;
    
    border-radius: 50px; 
    padding: 10px 10px; 
    cursor: pointer;
    margin-left: 140px;
    margin-top: -0px;
  
}
}
@media only screen and (min-width: 540px) and (max-width: 599px) {
  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
    margin-top: 20px;
    font-size: 30px;
    margin-right: 20px;
  }
  .lg-content-1{
    width:550px;
    height:400px;
    margin-left: 0px;
    margin-top: -50px;
    font-size: 20px;
    
  }
  .lg-content-2{
    width:550px;
    height:580px;
    margin-left: 0px;
    margin-bottom: 250px;
    margin-top: -90px;
    font-size: 20px;
  }
  .lg-content-3{
    width:550px;
    height:540px;
    margin-left: 0px;
    margin-bottom: 250px;
    margin-top: -440px;
    font-size: 20px;
  }
  .lg-content-4{
    width:550px;
    height:540px;
    margin-left: 0px;
    margin-bottom: 250px;
    margin-top: -370px;
    font-size: 20px;
  }
  .lg-content-5{
    width:550px;
    height:480px;
    margin-left: 0px;
    margin-bottom: 250px;
    margin-top: -340px;
    font-size: 20px;
  }
  .lg-content-6{
    width:550px;
    height:380px;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: -350px;
    font-size: 20px;
    
  }

  .lg-howdo-1{
    font-size: 25px;
    margin-left: -20px;
    width: 450px;
  }
  .lr-Readour-1{

    width: 200px;
    border: none;
    
    border-radius: 50px; 
    padding: 10px 10px; 
    cursor: pointer;
    margin-left: 160px;
    margin-top: -0px;
  
}
}
@media only screen and (min-width: 600px) and (max-width: 713px) {  
  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
    margin-top: 20px;
    font-size: 40px;
    margin-right: 20px;
  }
  .lg-content-1{

    width:650px;
    height:340px;
    margin-left:-10px;
    margin-top: -50px;
    font-size: 20px;
    
  }
  .lg-content-2{

    width:650px;
    height:380px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -70px;
    font-size: 20px;
  }
  .lg-content-3{

    width:650px;
    height:400px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -310px;
    font-size: 20px;
  }
  .lg-content-4{

    width:650px;
    height:430px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -300px;
    font-size: 20px;
  }
  .lg-content-5{

    width:650px;
    height:400px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -300px;
    font-size: 20px;
  }
  .lg-content-6{

    width:650px;
    height:350px;
    margin-left: -10px;
    margin-bottom: 0px;
    margin-top: -330px;
    font-size: 20px;
    
  }

  .lg-howdo-1{

    font-size: 25px;
    margin-left: -20px;
    width: 570px;
  }
  .lr-Readour-1{

    width: 240px;
    border: none;
    
    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 180px;
    margin-top: 0px;
  
}
}
@media only screen and (min-width: 712px) and (max-width: 767px) {  
  .lg-flex-container{
    display:block;
width: 95%;
  }
  .lg-help{

    margin-top: 20px;
    font-size: 40px;
    margin-right: 20px;
  }
  .lg-content-1{

    width:750px;
    height:340px;
    margin-left:-20px;
    margin-top: -50px;
    font-size: 20px;
    
  }
  .lg-content-2{

    width:750px;
    height:380px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -100px;
    font-size: 20px;
  }
  .lg-content-3{

    width:750px;
    height:400px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -370px;
    font-size: 20px;
  }
  .lg-content-4{

    width:750px;
    height:430px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -360px;
    font-size: 20px;
  }
  .lg-content-5{

    width:750px;
    height:400px;
    margin-left: -20px;
    margin-bottom: 250px;
    margin-top: -360px;
    font-size: 20px;
  }
  .lg-content-6{

    width:750px;
    height:300px;
    margin-left: -20px;
    margin-bottom: 0px;
    margin-top: -360px;
    font-size: 20px;
    
  }

  .lg-howdo-1{

    font-size: 24px;
    margin-left: -10px;
    width: 620px;
  }
  .lr-Readour-1{

    width: 240px;
    border: none;
    
    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 200px;
    margin-top: 20px;
  
}
}

@media only screen and (min-width: 768px) and (max-width: 768px) {  
  .lg-flex-container{
    display:block;
width: 95%;
  }
  .lg-help{

    margin-top: 20px;
    font-size: 40px;
    margin-right: 20px;
  }
  .lg-content-1{

    width:750px;
    height:340px;
    margin-left:10px;
    margin-top: -50px;
    font-size: 20px;
    
  }
  .lg-content-2{

    width:750px;
    height:380px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -100px;
    font-size: 20px;
  }
  .lg-content-3{

    width:750px;
    height:400px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -370px;
    font-size: 20px;
  }
  .lg-content-4{

    width:750px;
    height:430px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -360px;
    font-size: 20px;
  }
  .lg-content-5{

    width:750px;
    height:400px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -360px;
    font-size: 20px;
  }
  .lg-content-6{

    width:750px;
    height:300px;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-top: -360px;
    font-size: 20px;
    
  }

  .lg-howdo-1{

    font-size: 24px;
    margin-left: -10px;
    width: 620px;
  }
  .lr-Readour-1{

    width: 240px;
    border: none;
    
    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 200px;
    margin-top: 20px;
  
}
}

@media only screen and (min-width: 769px) and (max-width: 799px) {
  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
    margin-top: 20px;
    font-size: 50px;
    margin-right: 20px;
  }
  .lg-content-1{
    width:700px;
    height:350px;
    margin-left: 70px;
    margin-top: -50px;
    font-size: 20px;
    
  }
  .lg-content-2{
    width:700px;
    height:500px;
    margin-left: 70px;
    margin-bottom: 250px;
    margin-top: -60px;
    font-size: 20px;
  }
  .lg-content-3{
    width:700px;
    height:400px;
    margin-left: 70px;
    margin-bottom: 250px;
    margin-top: -420px;
    font-size: 20px;
  }
  .lg-content-4{
    width:700px;
    height:480px;
    margin-left: 70px;
    margin-bottom: 250px;
    margin-top: -280px;
    font-size: 20px;
  }
  .lg-content-5{
    width:700px;
    height:400px;
    margin-left: 70px;
    margin-bottom: 250px;
    margin-top: -350px;
    font-size: 20px;
  }
  .lg-content-6{
    width:700px;
    height:350px;
    margin-left: 70px;
    margin-bottom: 0px;
    margin-top: -320px;
    font-size: 20px;
    
  }

  .lg-howdo-1{
    font-size: 30px;
  }
  .lr-Readour-1{

    width: 240px;
    border: none;
    
    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 280px;
    margin-top: 0px;
  
}
}
@media only screen and (min-width: 800px) and (max-width: 819px) {
  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
    margin-top: 20px;
    font-size: 50px;
    margin-right: 20px;
  }
  .lg-content-1{
    width:800px;
    height:350px;
    margin-left: -10px;
    margin-top: -50px;
    font-size: 20px;
    
  }
  .lg-content-2{
    width:800px;
    height:500px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -110px;
    font-size: 20px;
  }
  .lg-content-3{
    width:800px;
    height:350px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -490px;
    font-size: 20px;
  }
  .lg-content-4{
    width:800px;
    height:380px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -310px;
    font-size: 20px;
  }
  .lg-content-5{
    width:800px;
    height:400px;
    margin-left: -10px;
    margin-bottom: 250px;
    margin-top: -340px;
    font-size: 20px;
  }
  .lg-content-6{
    width:800px;
    height:280px;
    margin-left: -10px;
    margin-bottom: 20px;
    margin-top: -390px;
    font-size: 20px;
    
  }

  .lg-howdo-1{
    margin-left: -10px;
width: 700px;
    font-size: 25px;
  }
  .lr-Readour-1{

    width: 240px;
    border: none;
    
    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 280px;
    margin-top: -10px;
  
}
}
@media only screen and (min-width: 820px) and (max-width: 883px) {
  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
    margin-top: 20px;
    font-size: 50px;
    margin-right: 20px;
  }
  .lg-content-1{
    width:800px;
    height:350px;
    margin-left: 10px;
    margin-top: -50px;
    font-size: 20px;
    
  }
  .lg-content-2{
    width:800px;
    height:500px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -110px;
    font-size: 20px;
  }
  .lg-content-3{
    width:800px;
    height:350px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -490px;
    font-size: 20px;
  }
  .lg-content-4{
    width:800px;
    height:380px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -310px;
    font-size: 20px;
  }
  .lg-content-5{
    width:800px;
    height:400px;
    margin-left: 10px;
    margin-bottom: 250px;
    margin-top: -340px;
    font-size: 20px;
  }
  .lg-content-6{
    width:800px;
    height:280px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: -390px;
    font-size: 20px;
    
  }

  .lg-howdo-1{
    margin-left: -10px;
width: 700px;
    font-size: 25px;
  }
  .lr-Readour-1{

    width: 240px;
    border: none;
    
    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 280px;
    margin-top: -10px;
  
}
}
@media only screen and (min-width: 884px) and (max-width: 911px) {  
  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
    margin-top: 20px;
    font-size: 50px;
    margin-right: 20px;
  }
  .lg-content-1{
    width:700px;
    height:350px;
    margin-left: 90px;
    margin-top: -50px;
    font-size: 20px;
    
  }
  .lg-content-2{
    width:700px;
    height:500px;
    margin-left: 90px;
    margin-bottom: 250px;
    margin-top: -60px;
    font-size: 20px;
  }
  .lg-content-3{
    width:700px;
    height:400px;
    margin-left: 90px;
    margin-bottom: 250px;
    margin-top: -420px;
    font-size: 20px;
  }
  .lg-content-4{
    width:700px;
    height:480px;
    margin-left: 90px;
    margin-bottom: 250px;
    margin-top: -280px;
    font-size: 20px;
  }
  .lg-content-5{
    width:700px;
    height:400px;
    margin-left: 90px;
    margin-bottom: 250px;
    margin-top: -350px;
    font-size: 20px;
  }
  .lg-content-6{
    width:700px;
    height:350px;
    margin-left: 90px;
    margin-bottom: 0px;
    margin-top: -320px;
    font-size: 20px;
    
  }

  .lg-howdo-1{
    font-size: 30px;
    margin-left: -10px;
    width: 650px;
    
  }
  .lr-Readour-1{

    width: 240px;
    border: none;
    
    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 300px;
    margin-top: 0px;
  
}
}
@media only screen and (min-width: 912px) and (max-width: 1023px) {  
  .lg-flex-container{
    display:block;
   
  }
  .lg-help{
    margin-top: 20px;
    font-size: 50px;
    margin-right: 30px;
  }
  .lg-content-1{
    width:800px;
    height:350px;
    margin-left: 70px;
    margin-top: -50px;
    font-size: 20px;
    
  }
  .lg-content-2{
    width:800px;
    height:500px;
    margin-left: 70px;
    margin-bottom: 250px;
    margin-top: -100px;
    font-size: 20px;
  }
  .lg-content-3{
    width:800px;
    height:400px;
    margin-left: 70px;
    margin-bottom: 250px;
    margin-top: -450px;
    font-size: 20px;
  }
  .lg-content-4{
    width:800px;
    height:480px;
    margin-left: 70px;
    margin-bottom: 250px;
    margin-top: -320px;
    font-size: 20px;
  }
  .lg-content-5{
    width:800px;
    height:400px;
    margin-left: 70px;
    margin-bottom: 250px;
    margin-top: -400px;
    font-size: 20px;
  }
  .lg-content-6{
    width:800px;
    height:300px;
    margin-left: 70px;
    margin-bottom: 0px;
    margin-top: -390px;
    font-size: 20px;
    
  }

  .lg-howdo-1{
    font-size: 30px;
    margin-left: -10px;
    width: 650px;
    
  }
  .lr-Readour-1{

    width: 240px;
    border: none;
    
    border-radius: 50px; 
    padding: 20px 20px; 
    cursor: pointer;
    margin-left: 350px;
    margin-top: 0px;
  
}
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) {  
  .lg-help {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    margin-top: 70px;
    margin-bottom: 30px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .lg-flex-container {
    display: flex;
    flex-wrap: wrap; /* Allow contents to wrap to the next row */
    justify-content: space-between; /* Adjust spacing between contents */
  }
  
  .lg-content-1 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 300px; /* Set a fixed height for each content block */
    padding: 0px;
 margin-top: 10px;
     border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 80px;
    font-size:17px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lg-more-hr{
    margin-top: 10px;
  }
  .lg-content-2 {
    margin-top: 10px;

    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 400px; /* Set a fixed height for each content block */
    padding: 0px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 60px;
  
    font-size:17px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lg-content-3 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 500px; /* Set a fixed height for each content block */
    padding: 0px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 80px;
    margin-top: -140px;
    font-size:17px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lg-content-4 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 400px; /* Set a fixed height for each content block */
    padding: 0px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 60px;
    font-size:17px;
    margin-top: -120px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lg-content-5 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 300px; /* Set a fixed height for each content block */
    padding: 0px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 80px;
    margin-top: -160px;
    font-size:17px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lg-content-6 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 280px; /* Set a fixed height for each content block */
    padding: 0px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 60px;
    font-size:17px;
    margin-top: -140px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .lg-howdo-1{
    margin-bottom: 20px;
    font-size: 20px;
  }
  .lg-content h4 {
    margin-top: 0;
    font-size:20px;
    font-weight: 500;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 10px;
  
  }
  
  .lr-Readour-1 {
  width: 240px;
  background-color: rgb(7, 52, 125); /* Blue color */
  color: white; /* Text color set to white */
  border-radius: 50px; /* Rounded corners */
  padding: 20px 20px; /* Padding adjusted */
  cursor: pointer; /* Cursor changes to pointer on hover */
  margin-left: 400px;
  text-decoration: none !important;outline: none; /* No outline on focus */
  }
  
  
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {  
  .lg-help {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 30px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .lg-flex-container {
    display: flex;
    flex-wrap: wrap; /* Allow contents to wrap to the next row */
    justify-content: space-between; /* Adjust spacing between contents */
  }
  
  .lg-content-1 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 450px; /* Set a fixed height for each content block */
    padding: 50px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 80px;
    font-size:20px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lg-more-hr{
    margin-top: 10px;
  }
  .lg-content-2 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 550px; /* Set a fixed height for each content block */
    padding: 50px;
    margin-bottom: 30px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 80px;
  
    font-size:20px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lg-content-3 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 480px; /* Set a fixed height for each content block */
    padding: 50px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 100px;
    margin-top: -180px;
    font-size:20px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lg-content-4 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 500px; /* Set a fixed height for each content block */
    padding: 50px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 80px;
    font-size:20px;
    margin-top: -190px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lg-content-5 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 430px; /* Set a fixed height for each content block */
    padding: 50px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 100px;
    margin-top: -60px;
    font-size:20px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lg-content-6 {
    width: calc(50% - 100px); /* Adjust content width to fit two columns with margin */
    height: 300px; /* Set a fixed height for each content block */
    padding: 50px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 80px;
    font-size:17px;
    margin-top: -70px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .lg-howdo-1{
    margin-bottom: 20px;
    font-size: 25px;
  }
  .lg-content h4 {
    margin-top: 0;
    font-size:20px;
    font-weight: 500;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 10px;
  
  }
  
  .lr-Readour-1 {
  width: 240px;
  background-color: rgb(7, 52, 125); /* Blue color */
  color: white; /* Text color set to white */
  border-radius: 50px; /* Rounded corners */
  padding: 20px 20px; /* Padding adjusted */
  cursor: pointer; /* Cursor changes to pointer on hover */
  margin-left: 550px;
  text-decoration: none !important;outline: none; /* No outline on focus */
  }
}



 
