/* General Styles */
.edit-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.edit-travel-backsss{

  /* margin-left: 30%; */
  padding-left: 300px;
  font-size: 20px;

}
.edit-travel-edit{
width: 100%;
  text-align: center;
  margin-bottom: 10px;
  text-align: center;
  margin-left: 150px;
  padding: 5px;  


}
.edit-travel-preferences {
  border: 1px solid #ccc;
  height: auto;

  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  margin-top: 150px;
}

.edit-sections-contents {
  margin-bottom: 10px;
}

.edit-travel, .vehicle {
width: 100%;
  text-align: center;
  margin-bottom: 10px;
}



.edit-preferences, .edit-vehicle-preferences {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.edit-preference {
  flex: 1 1 300px; /* Adjust the width of each preference item */
}

.edit-preference label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.edit-custom-dropdown,
.edit-custom-input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.edit-custom-dropdown {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.edit-custom-input:disabled {
  background-color: #f9f9f9;
  cursor: not-allowed;
}

.edit-custom-links {
  color: #fff;
  text-decoration: none;
  background-color: #001A4F; /* Dark blue background */
  border-radius: 50px;
  padding: 10px 20px;
  text-align: center;
  display: block;
  margin: 20px auto 0;
  /* margin-top: -20px; */
}

.edit_save-success, .edit_save-error {
  position: fixed;
  top: 140px; /* Adjust top position to clear the navbar */
  right: 20px; /* Adjust right position to align with the right side of the screen */
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.edit-error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Responsive Styles */
@media only screen and (min-width: 320px) and (max-width: 411px) {
  .edit-travel-preferences {
    width: 95%;
    margin-top: 150px;
    padding: 15px;
  }

  .edit-preferences, .edit-vehicle-preferences {
    gap: 10px;
  }

  .edit-custom-dropdown,
  .edit-custom-input {
    max-width: 100%;
    padding: 8px;
    font-size: 16px; /* Increase font size for tablet view */
  }

  .edit-preference label {
    font-size: 16px; /* Increase font size for labels in tablet view */
  }

  .edit-custom-links {
    padding: 8px 15px;
    font-size: 16px; /* Increase font size for links in tablet view */
  }

  .edit_save-success, .edit_save-error {
    top: 100px;
    right: 10px;
    padding: 3px 5px;
  }
  .edit-travel-backsss{
    margin-left: 90%;
  }
}
@media only screen and (min-width: 412px) and (max-width: 472px) {
  .edit-travel-preferences {
    width: 95%;
    margin-top: 150px;
    padding: 15px;
  }

  .edit-preferences, .edit-vehicle-preferences {
    gap: 10px;
  }

  .edit-custom-dropdown,
  .edit-custom-input {
    max-width: 100%;
    padding: 8px;
    font-size: 16px; /* Increase font size for tablet view */
  }

  .edit-preference label {
    font-size: 16px; /* Increase font size for labels in tablet view */
  }

  .edit-custom-links {
    padding: 8px 15px;
    font-size: 16px; /* Increase font size for links in tablet view */
  }

  .edit_save-success, .edit_save-error {
    top: 100px;
    right: 10px;
    padding: 3px 5px;
  }
  .edit-travel-backsss{
    margin-left: 90%;
  }
}
@media only screen and (min-width: 428px) and (max-width: 767px) {
  .edit-travel-preferences {
    width: 95%;
    margin-top: 150px;
    padding: 15px;
  }

  .edit-preferences, .edit-vehicle-preferences {
    gap: 10px;
  }

  .edit-custom-dropdown,
  .edit-custom-input {
    max-width: 100%;
    padding: 8px;
    font-size: 16px; /* Increase font size for tablet view */
  }

  .edit-preference label {
    font-size: 16px; /* Increase font size for labels in tablet view */
  }

  .edit-custom-links {
    padding: 8px 15px;
    font-size: 16px; /* Increase font size for links in tablet view */
  }

  .edit_save-success, .edit_save-error {
    top: 100px;
    right: 10px;
    padding: 3px 5px;
  }
  .edit-travel-backsss{
    margin-left: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 819px) {
  .edit-travel-backsss{
    margin-left: 90%;
  }

}
@media only screen and (min-width: 800px) and (max-width: 819px) {
  .edit-travel-backsss{
    margin-left: 90%;
  }

}
@media only screen and (min-width: 820px) and (max-width: 833px) {
  .edit-travel-backsss{
    margin-left: 90%;
  }

}
@media only screen and (min-width: 884px) and (max-width: 1279px) {
  .edit-travel-backsss{
    margin-left: 90%;
  }

}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
/* General Styles */
.edit-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.edit-travel-backsss{
  margin-left: 95%;
}
.edit-travel-preferences {
  border: 1px solid #ccc;
  height: 530px;

  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  margin-top: 160px;
}

.edit-sections-contents {
  margin-bottom: 20px;
}

.edit-travel, .vehicle {
  text-align: center;
  margin-bottom: 20px;
}

.edit-preferences, .edit-vehicle-preferences {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.edit-preference {
  flex: 1 1 300px; /* Adjust the width of each preference item */
}

.edit-preference label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.edit-custom-dropdown,
.edit-custom-input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.edit-custom-dropdown {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.edit-custom-input:disabled {
  background-color: #f9f9f9;
  cursor: not-allowed;
}

.edit-custom-links {
  color: #fff;
  text-decoration: none;
  background-color: #001A4F; /* Dark blue background */
  border-radius: 50px;
  padding: 10px 20px;
  text-align: center;
  display: block;
  margin: 20px auto 0;
  /* margin-top: -20px; */
}

.edit_save-success, .edit_save-error {
  position: fixed;
  top: 140px; /* Adjust top position to clear the navbar */
  right: 20px; /* Adjust right position to align with the right side of the screen */
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.edit-error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

}