/* Reportride.css */

.ride-main {
  
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 700px;

}

/* The Modal (background) */
.modal-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* background-color: rgb(0,0,0); */
  
}
.Success{
  text-align: center;
}




/* The Modal (background) */
.modal-cont-popyour {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.Success{
  text-align: center;
}
/* Modal Content */



/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #a4a1a1;
 max-height: 200px;
  max-width: 300px;
  box-shadow: 0 5px 15px rgba(25, 24, 24, 0.3);
  animation: fadeIn 0.3s;
  flex-grow: 1;

}


.ok-button-your{
  border-radius: 30px;
color: white;
  background-color: rgb(7, 52, 125);
  width: 50px;
margin-left: 100px;
  text-align: center;
  text-decoration: none !important;outline: none; /* No outline on focus */

}
/* Close Button */
.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-left: 240px;
  margin-top: -20px;
}

.close-button:hover,
.close-button:focus {


  color: rgb(243, 10, 10);
  text-decoration: none;
  cursor: pointer;
}

/* Add Animation */
@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}


.ride-box-you{
  border: 1px none;
  border-radius: 30px;
  width: 700px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 height: 500px;
 margin-bottom: -250px;
 
  
}



.ride-main-1 {
  text-align: center;

 
  /* Center text horizontally */
}

.ride-tax-center {
  font-size: 40px;
  padding-top: 50px;
  margin-top: 20px;
  margin-bottom: 50px;
  flex-direction: column;
  /* display: grid; */
  margin-left: 50px;
  
}
.ride-tax-center-1{
  margin-bottom: -150px;
}


.ride-hr-ascam{
  margin-left: 15px;
  

}

.ride-report-options {
  list-style-type: none;
  padding: 10;
  text-align: left ;
  margin-top: 50px;
  margin-left: 25px;
  margin-right: 25px;

}


.ride-report-options li {
  
  cursor: pointer;
  height: 60px; /* Set height */
  line-height: 40px; /* Center text vertically */
  
 
  
}

.ride-report-options li:hover {
  background-color: #f0f0f0; /* Change to your desired hover color */
border-radius: 10px;


}


/* Style for list items */
.ride-report-options li {
  display: flex;
  align-items: center; /* Center align items vertically */
  padding: 10px 0; /* Adjust padding as needed */
}

/* Style for links */
.ride-report-options li a {
  color: black; /* Change text color as needed */
  text-decoration: none; /* Remove underline */
}

/* Style for the symbol */
.ride-report-options li .ride-symbol {
  margin-left: auto; /* Push the symbol to the right */
}

/* Hover effect */
.ride-report-options li:hover {
  background-color: #f0f0f0; /* Change to your desired hover color */
  border-radius: 20px;

  
}

/* Move list item and symbol when hovered */
.ride-report-options li:hover {
  transform: translateX(0px); /* Move left by 20px when hovered */
}

/* Move symbol when list item is hovered */
.ride-report-options li:hover .ride-symbol {
  transform: translateX(0px); /* Move right by 20px when hovered */
}


/* Scam.css */




.Scam-tax-center {
  font-size: 40px;
  padding-top: 50px;
  margin-top: 20px;
  margin-bottom: 50px;
  flex-direction: column;
  /* display: grid; */
  margin-left: 80px;
}

.Scam-symbol {
  font-size: 20px;
  margin-left: 550px;
  margin-top: -40px;
  
  
  }
  .Scam-symbol-1 {
  font-size: 20px;
  margin-left: 600px;
  margin-top: -40px;
  
  
  }

  .Scam-report-options {
    list-style-type: none;
    padding: 10;
    text-align: left ;
    margin-top: 50px;
    margin-left: 25px;
    margin-right: 25px;
  
  }
 
    
.Scam-box-you-1{
  border: 1px none;
  border-radius: 30px;
  width: 700px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 height: 400px;
 margin-bottom: 0px;
/* margin-left: 2px; */
  
}


.Scam-main {
display: flex;
justify-content: center; /* Center horizontally */
align-items: center; /* Center vertically */
height: 700px; /* Set the height */
}

.Scam-main-1 {
text-align: center;

/* Center text horizontally */
}


.Scam-hr-ascam{
margin-left: 15px;
}




.Scam-report-options li {

cursor: pointer;
height: 60px; /* Set height */
line-height: 40px; /* Center text vertically */



}

.Scam-report-options li:hover {
background-color: #f0f0f0; /* Change to your desired hover color */
border-radius: 10px;

}
.Scam-report-options li:hover {
  background-color: #f0f0f0; /* Change to your desired hover color */
  border-radius: 15px;
  }

.Scam-report-options li {
display: flex;
align-items: center; /* Center align items vertically */
padding: 10px 0; /* Adjust padding as needed */
}

.Scam-report-options li a {
color: black; /* Change text color as needed */
text-decoration: none; /* Remove underline */
}

.Scam-report-options li .Scam-symbol {
margin-left: auto; /* Push the symbol to the right */
}

.Scam-report-options li:hover {
transform: translateX(0px); /* Move left by 20px when hovered */
}

.Scam-report-options li:hover .Scam-symbol {
transform: translateX(0px); /* Move right by 20px when hovered */
}









  .Scam-symbol-1 {
  font-size: 20px;
  margin-left: 550px;
  margin-top: -40px;
  
  
  }

  .Scam-report-options {
    list-style-type: none;
    padding: 10;
    text-align: left ;
    margin-top: 50px;
    margin-left: 25px;
    margin-right: 25px;
  
  }




/* Textfield.css */
.Textfield {
text-align: center;

padding-top: 150px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 200px; 


}
.Textfield-tell-us{

  margin-top: 0px;
  display: flex;
}
.Textfield-tell {
text-align: center;
  display: flex;
  font-size: 40px;
  margin-top: 0px;
   /* Add margin below the heading */
  margin-left: 600px;  
}

.Textfield .Text-box-1 {

margin-top: 30px;
}

.Textfield .eqU-gyb {
background-color: rgb(7, 52, 125);
color: #fff;
padding: 10px 20px;
border: none;
border-radius: 50px;
font-size: 16px;
margin-bottom: -60px;
cursor: pointer;
transition: background-color 0.3s;
}

.Textfield .eqU-gyb:hover {
background-color: rgb(7, 52, 125);
}

.Textfield .button-container {
display: flex;
justify-content: center; /* Horizontally center the button */
align-items: center; /* Vertically center the button */
}

.Textfield textarea {
width: 100%;
max-width: 600px; /* Adjust max-width as needed */
height: 300px; /* Adjust height as needed */
padding: 10px;
border: 1px solid #ccc;
border-radius: 5px;
font-size: 16px;
resize: vertical;
}
@media only screen and (min-width: 320px) and (max-width: 359px) {

  /* Reportride.css */
  .ride-box-you{
    border: 1px none;
    border-radius: 30px;
    width: 300px;
    margin-left: -0px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   height: 480px;
   margin-bottom: -280px;
   
    
  }
  .Scam-box-you-1{
    border: 1px none;
    border-radius: 25px;
    width: 300px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   height: 350px;
   margin-bottom: 0px;
  
   margin-left: 10px;
    
  }
  .Scam-hr-ascam{
    margin-left: 1px;
    }
  
  .ride-tax-center {
    width: 400px;
    font-size: 22px;
    padding-top: 40px;
    margin-top: 20px;
    margin-bottom: 50px;
    flex-direction: column;
    /* display: grid; */
    margin-left: -20px;
    
  }
  .ride-hr-ascam{
    margin-left: 1px;
    margin-top: 0px;
  
  }
    .ride-main {
   
        width: 100%;
        height: 530px; /* Set the height */
      }
      
      .ride-main-1 {
        width: 300px;
        text-align: center;
       
        /* Center text horizontally */
      }
      .ride-report-options {
        list-style-type: none;
        padding: 10;
        text-align: left ;
        margin-top: 50px;
        margin-left: 0px;
    
      }
      .ride-report-options li:hover {
        background-color: #f0f0f0; /* Change to your desired hover color */
        border-radius: 20px;
      
        
      }
  
      .Scam-symbol {
        font-size: 20px;
        margin-left: 200px;
        }
  
        .Scam-symbol-1 {
          font-size: 20px;
          margin-left: 240px;
          margin-top: -40px;
          
          
          }
  
      /* Scam.css */
  
    
    .Scam-main {
      width: 100%;
      height: 600px; /* Set the height */
    }
    
    .Scam-main-1 {
      width: 400px;
      text-align: center;
     
      /* Center text horizontally */
    }
    
    .Scam-tax-center {
      width: 400px;
      font-size: 22px;
      margin-left: -10px;
    }
  
  
  
    
    .Scam-report-options {
      list-style-type: none;
      padding: 10;
      text-align: left ;
      margin-top: 50px;
      margin-left: 0px;
  
    }
  
  
      /* Textfield.css */
      .Textfield {
        text-align: center;
        
        padding-top: 100px;
          justify-content: center; /* Center horizontally */
          align-items: center; /* Center vertically */
          height: 200px; 
         /* Center horizontally */
         /* Center vertically */
         /* Set the height */
        
      }
      .Textfield-tell-us{
      
          margin-top: 0px;
          display: flex;
      }
      
      .Textfield-tell {
        text-align: center;
          display: flex;
          font-size: 30px;
          margin-top: 20px;
           /* Add margin below the heading */
          margin-left: 30px;  
      }
      
      .Textfield .Text-box-1 {
        
        margin-top: 0px;
      }
      
      .Textfield .eqU-gyb {
      
        padding: 10px 20px;
        border: none;
        border-radius: 50px;
        font-size: 16px;
        margin-left: 50px;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      
      
      
      .Textfield .button-container {
        display: flex;
        justify-content: center; /* Horizontally center the button */
        align-items: center;
        margin-right: 40px; /* Vertically center the button */
      }
      
      .Textfield textarea {
        width: 100%;
        max-width: 300px; /* Adjust max-width as needed */
        height: 200px; /* Adjust height as needed */
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        resize: vertical;
      }
    
  
  
  
  } 
@media only screen and (min-width: 360px) and (max-width: 374px) {

  /* Reportride.css */
  .ride-box-you{
    border: 1px none;
    border-radius: 30px;
    width: 340px;
    margin-left: -20px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   height: 480px;
   margin-bottom: -280px;
   
    
  }
  .Scam-box-you-1{
    border: 1px none;
    border-radius: 25px;
    width: 340px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   height: 350px;
   margin-bottom: 0px;
  
   margin-left: 10px;
    
  }
  
  .ride-tax-center {
    width: 400px;
    font-size: 25px;
    padding-top: 50px;
    margin-top: 20px;
    margin-bottom: 50px;
    flex-direction: column;
    /* display: grid; */
    margin-left: -20px;
    
  }
  .ride-hr-ascam{
    margin-left: 15px;
    margin-top: 0px;
  
  }
    .ride-main {
   
        width: 100%;
        height: 530px; /* Set the height */
      }
      
      .ride-main-1 {
        width: 300px;
        text-align: center;
       
        /* Center text horizontally */
      }
      .ride-report-options {
        list-style-type: none;
        padding: 10;
        text-align: left ;
        margin-top: 50px;
        margin-left: 0px;
    
      }
      .ride-report-options li:hover {
        background-color: #f0f0f0; /* Change to your desired hover color */
        border-radius: 20px;
      
        
      }
  
      .Scam-symbol {
        font-size: 20px;
        margin-left: 250px;
        }
  
        .Scam-symbol-1 {
          font-size: 20px;
          margin-left: 250px;
          margin-top: -40px;
          
          
          }
  
      /* Scam.css */
  
    
    .Scam-main {
      width: 100%;
      height: 600px; /* Set the height */
    }
    
    .Scam-main-1 {
      width: 400px;
      text-align: center;
     
      /* Center text horizontally */
    }
    
    .Scam-tax-center {
      width: 400px;
      font-size: 25px;
      margin-left: 0px;
    }
  
  
  
    
    .Scam-report-options {
      list-style-type: none;
      padding: 10;
      text-align: left ;
      margin-top: 50px;
      margin-left: 0px;
  
    }
  
  
      /* Textfield.css */
      .Textfield {
        text-align: center;
        
        padding-top: 130px;
          justify-content: center; /* Center horizontally */
          align-items: center; /* Center vertically */
          height: 200px; 
         /* Center horizontally */
         /* Center vertically */
         /* Set the height */
        
      }
      .Textfield-tell-us{
      
          margin-top: 0px;
          display: flex;
      }
      
      .Textfield-tell {
        text-align: center;
          display: flex;
          font-size: 30px;
          margin-top: 0px;
           /* Add margin below the heading */
          margin-left: 55px;  
      }
      
      .Textfield .Text-box-1 {
        
        margin-top: 0px;
      }
      
      .Textfield .eqU-gyb {
      
        padding: 10px 20px;
        border: none;
        border-radius: 50px;
        font-size: 16px;
        margin-left: 50px;
        cursor: pointer;
        transition: background-color 0.3s;
      }
      
      
      
      .Textfield .button-container {
        display: flex;
        justify-content: center; /* Horizontally center the button */
        align-items: center;
        margin-right: 40px; /* Vertically center the button */
      }
      
      .Textfield textarea {
        width: 100%;
        max-width: 300px; /* Adjust max-width as needed */
        height: 200px; /* Adjust height as needed */
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        resize: vertical;
      }
    
  
  
  
  } 

@media only screen and (min-width: 375px) and (max-width: 767px) {

/* Reportride.css */
.ride-box-you{
  border: 1px none;
  border-radius: 30px;
  width: 360px;
  margin-left: -30px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 height: 480px;
 margin-bottom: -280px;
 
  
}
.Scam-box-you-1{
  border: 1px none;
  border-radius: 25px;
  width: 360px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 height: 350px;
 margin-bottom: 0px;

 margin-left: 17px;
  
}

.ride-tax-center {
  width: 400px;
  font-size: 25px;
  padding-top: 50px;
  margin-top: 20px;
  margin-bottom: 50px;
  flex-direction: column;
  /* display: grid; */
  margin-left: -10px;
  
}
.ride-hr-ascam{
  margin-left: 15px;
  margin-top: 0px;

}
  .ride-main {
      width: 100%;
      height: 600px; /* Set the height */
    }
    
    .ride-main-1 {
      width: 300px;
      text-align: center;
     
      /* Center text horizontally */
    }
    .ride-report-options {
      list-style-type: none;
      padding: 10;
      text-align: left ;
      margin-top: 50px;
      margin-left: 0px;
  
    }
    .ride-report-options li:hover {
      background-color: #f0f0f0; /* Change to your desired hover color */
      border-radius: 20px;
    
      
    }

    .Scam-symbol {
      font-size: 20px;
      margin-left: 250px;
      }

      .Scam-symbol-1 {
        font-size: 20px;
        margin-left: 250px;
        margin-top: -40px;
        
        
        }

    /* Scam.css */

  
  .Scam-main {
    width: 100%;
    height: 600px; /* Set the height */
  }
  
  .Scam-main-1 {
    width: 400px;
    text-align: center;
   
    /* Center text horizontally */
  }
  
  .Scam-tax-center {
    width: 400px;
    font-size: 25px;
    margin-left: 0px;
  }



  
  .Scam-report-options {
    list-style-type: none;
    padding: 10;
    text-align: left ;
    margin-top: 50px;
    margin-left: 0px;

  }


    /* Textfield.css */
    .Textfield {
      text-align: center;
      
      padding-top: 130px;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        height: 200px; 
       /* Center horizontally */
       /* Center vertically */
       /* Set the height */
      
    }
    .Textfield-tell-us{
    
        margin-top: 0px;
        display: flex;
    }
    
    .Textfield-tell {
      text-align: center;
        display: flex;
        font-size: 30px;
        margin-top: 0px;
         /* Add margin below the heading */
        margin-left: 65px;  
    }
    
    .Textfield .Text-box-1 {
      
      margin-top: 0px;
    }
    
    .Textfield .eqU-gyb {
    
      padding: 10px 20px;
      border: none;
      border-radius: 50px;
      font-size: 16px;
      margin-left: 50px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    
    
    
    .Textfield .button-container {
      display: flex;
      justify-content: center; /* Horizontally center the button */
      align-items: center;
      margin-right: 40px; /* Vertically center the button */
    }
    
    .Textfield textarea {
      width: 100%;
      max-width: 300px; /* Adjust max-width as needed */
      height: 200px; /* Adjust height as needed */
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      resize: vertical;
    }
  



}
@media only screen and (min-width: 768px) and (max-width: 799px) {

.Textfield-tell {
  text-align: center;
    display: flex;
    font-size: 40px;
    margin-top: 0px;
     /* Add margin below the heading */
    margin-left: 200px;  
  } 
}
@media only screen and (min-width: 800px) and (max-width: 819px) {

  .Textfield-tell {
    text-align: center;
      display: flex;
      font-size: 35px;
      margin-top: 0px;
      margin-left: 250px;  
  }
}

@media only screen and (min-width: 820px) and (max-width: 883px) {
  .ride-box-you{
    border: 1px none;
    border-radius: 30px;
    width: 700px;
    margin-left: -200px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   height: 500px;
   margin-bottom: -340px;
   
    
  }
  .Scam-box-you-1{
    border: 1px none;
    border-radius: 25px;
    width: 700px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   height: 450px;
   margin-bottom: -40px;
   margin-right: 20px;
  
    
  }
  
  .ride-tax-center {
    width: 400px;
    font-size: 25px;
    padding-top: 50px;
    margin-top: 20px;
    margin-bottom: 50px;
    flex-direction: column;
    /* display: grid; */
    margin-left: 140px;
    
  }
  .ride-hr-ascam{
    margin-left: 15px;
    margin-top: 0px;
  
  }
    .ride-main {
        width: 100%;
        height: 600px; /* Set the height */
      }
      
      .ride-main-1 {
        width: 300px;
        text-align: center;
       
        /* Center text horizontally */
      }
      .ride-report-options {
        list-style-type: none;
        padding: 10;
        text-align: left ;
        margin-top: 50px;
        margin-left: 0px;
    
      }
  
  
      .Scam-symbol {
        font-size: 20px;
        margin-left: 550px;
        }
  
        .Scam-symbol-1 {
          font-size: 20px;
          margin-left: 550px;
          margin-top: -40px;
          
          
          }
  
  
  .Textfield-tell {
  text-align: center;
    display: flex;
    font-size: 35px;
    margin-top: 0px;
    margin-left: 250px;  
}

.Textfield textarea {
  width: 100%;
  max-width: 500px; /* Adjust max-width as needed */
  height: 250px; /* Adjust height as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;
}

}
@media only screen and (min-width: 884px) and (max-width: 1279px) {
  .Textfield-tell {
    text-align: center;
      display: flex;
      font-size: 40px;
      margin-top: 0px;
       /* Add margin below the heading */
      margin-left: 300px;  
    }
}


@media only screen and (min-width: 1280px) and (max-width: 1440px) {
/* Reportride.css */

.ride-main {
  
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 700px;

}

/* The Modal (background) */
.modal-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* background-color: rgb(0,0,0); */
  
}
.Success{
  text-align: center;
}




/* The Modal (background) */
.modal-cont-popyour {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.Success{
  text-align: center;
}
/* Modal Content */



/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
 max-height: 200px;
  max-width: 300px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  animation: fadeIn 0.3s;
  flex-grow: 1;

}


.ok-button-your{
  border-radius: 30px;
color: white;
  background-color: rgb(7, 52, 125);
  width: 50px;
margin-left: 100px;
  text-align: center;
  text-decoration: none !important;outline: none; /* No outline on focus */

}
/* Close Button */
.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Add Animation */
@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}


.ride-box-you{
  border: 1px none;
  border-radius: 30px;
  width: 700px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 height: 500px;
 margin-bottom: -250px;
 
  
}



.ride-main-1 {
  text-align: center;

 
  /* Center text horizontally */
}

.ride-tax-center {
  font-size: 40px;
  padding-top: 50px;
  margin-top: 20px;
  margin-bottom: 50px;
  flex-direction: column;
  /* display: grid; */
  margin-left: 50px;
  
}
.ride-tax-center-1{
  margin-bottom: -150px;
}


.ride-hr-ascam{
  margin-left: 15px;
  

}

.ride-report-options {
  list-style-type: none;
  padding: 10;
  text-align: left ;
  margin-top: 50px;
  margin-left: 25px;
  margin-right: 25px;

}


.ride-report-options li {
  
  cursor: pointer;
  height: 60px; /* Set height */
  line-height: 40px; /* Center text vertically */
  
 
  
}

.ride-report-options li:hover {
  background-color: #f0f0f0; /* Change to your desired hover color */
border-radius: 10px;


}


/* Style for list items */
.ride-report-options li {
  display: flex;
  align-items: center; /* Center align items vertically */
  padding: 10px 0; /* Adjust padding as needed */
}

/* Style for links */
.ride-report-options li a {
  color: black; /* Change text color as needed */
  text-decoration: none; /* Remove underline */
}

/* Style for the symbol */
.ride-report-options li .ride-symbol {
  margin-left: auto; /* Push the symbol to the right */
}

/* Hover effect */
.ride-report-options li:hover {
  background-color: #f0f0f0; /* Change to your desired hover color */
  border-radius: 20px;

  
}

/* Move list item and symbol when hovered */
.ride-report-options li:hover {
  transform: translateX(0px); /* Move left by 20px when hovered */
}

/* Move symbol when list item is hovered */
.ride-report-options li:hover .ride-symbol {
  transform: translateX(0px); /* Move right by 20px when hovered */
}


/* Scam.css */




.Scam-tax-center {
  font-size: 40px;
  padding-top: 50px;
  margin-top: 20px;
  margin-bottom: 50px;
  flex-direction: column;
  /* display: grid; */
  margin-left: 80px;
}

.Scam-symbol {
  font-size: 20px;
  margin-left: 550px;
  margin-top: -40px;
  
  
  }
  .Scam-symbol-1 {
  font-size: 20px;
  margin-left: 600px;
  margin-top: -40px;
  
  
  }

  .Scam-report-options {
    list-style-type: none;
    padding: 10;
    text-align: left ;
    margin-top: 50px;
    margin-left: 25px;
    margin-right: 25px;
  
  }
 
    
.Scam-box-you-1{
  border: 1px none;
  border-radius: 30px;
  width: 700px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 height: 400px;
 margin-bottom: 0px;
/* margin-left: 2px; */
  
}


.Scam-main {
display: flex;
justify-content: center; /* Center horizontally */
align-items: center; /* Center vertically */
height: 700px; /* Set the height */
}

.Scam-main-1 {
text-align: center;

/* Center text horizontally */
}


.Scam-hr-ascam{
margin-left: 15px;
}




.Scam-report-options li {

cursor: pointer;
height: 60px; /* Set height */
line-height: 40px; /* Center text vertically */



}

.Scam-report-options li:hover {
background-color: #f0f0f0; /* Change to your desired hover color */
border-radius: 10px;

}
.Scam-report-options li:hover {
  background-color: #f0f0f0; /* Change to your desired hover color */
  border-radius: 15px;
  }

.Scam-report-options li {
display: flex;
align-items: center; /* Center align items vertically */
padding: 10px 0; /* Adjust padding as needed */
}

.Scam-report-options li a {
color: black; /* Change text color as needed */
text-decoration: none; /* Remove underline */
}

.Scam-report-options li .Scam-symbol {
margin-left: auto; /* Push the symbol to the right */
}

.Scam-report-options li:hover {
transform: translateX(0px); /* Move left by 20px when hovered */
}

.Scam-report-options li:hover .Scam-symbol {
transform: translateX(0px); /* Move right by 20px when hovered */
}









  .Scam-symbol-1 {
  font-size: 20px;
  margin-left: 550px;
  margin-top: -40px;
  
  
  }

  .Scam-report-options {
    list-style-type: none;
    padding: 10;
    text-align: left ;
    margin-top: 50px;
    margin-left: 25px;
    margin-right: 25px;
  
  }




/* Textfield.css */
.Textfield {
text-align: center;

padding-top: 150px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 200px; 


}
.Textfield-tell-us{

  margin-top: 0px;
  display: flex;
}
.Textfield-tell {
text-align: center;
  display: flex;
  font-size: 40px;
  margin-top: 0px;
   /* Add margin below the heading */
  margin-left: 450px;  
}

.Textfield .Text-box-1 {

margin-top: 30px;
}

.Textfield .eqU-gyb {
background-color: rgb(7, 52, 125);
color: #fff;
padding: 10px 20px;
border: none;
border-radius: 50px;
font-size: 16px;
margin-bottom: -60px;
cursor: pointer;
transition: background-color 0.3s;
}

.Textfield .eqU-gyb:hover {
background-color: rgb(7, 52, 125);
}

.Textfield .button-container {
display: flex;
justify-content: center; /* Horizontally center the button */
align-items: center; /* Vertically center the button */
}

.Textfield textarea {
width: 100%;
max-width: 600px; /* Adjust max-width as needed */
height: 300px; /* Adjust height as needed */
padding: 10px;
border: 1px solid #ccc;
border-radius: 5px;
font-size: 16px;
resize: vertical;
}

}

