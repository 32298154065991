
.usernb-user{
  height: 100px;
 }

 
*{
    margin:0;
  }
  .usernb-linked-text{
    text-decoration: none;
    }
    /* .usernb-logo-10{
       
        margin-top: 20px;
      } */
 
 
      .usernb-cheader{
        height: 300px;
      }
  .usernb-yaa{
    /* border:1px solid rgb(246, 12, 12); */

  width:100px;
  color:#054652;
  height:50px;
  margin-left: 50px;

 
 
  }
 
  /* Navbar.css */
  .usernb-tiger a {
 
 
 
    transition: transform 0.3s ease; /* Add transition property */
  }
 
    .usernb-tiger ul p a{
    color:#054652
    COPY
    ;
    font-size: 20px;
    font-weight: 100px;
 
    transition: transform 0.3s ease; /* Add transition property */
    font-family:'Times New Roman', Times, serif;
  }
 
 
  .usernb-tiger a:hover {
    color: rgb(7, 7, 7);
    transform: scale(1.1);
   
    padding-left:4px;
 
  }
  .usernb-profile-info-container {
    display: block;
    margin-left:0px;
    align-items: center;
  }
  .usernb-profile-image {

      width: 70px; /* Adjust width as needed */
      height: 60px; /* Adjust height as needed */
      border-radius: 70%; /* To make it circular */
      background-size: cover;
      margin-top: -30px;
      background-position: center;
      margin-left: -100px;
      background-image: url('../Images/17134403639001.jpg'); 
    }
 
  .usernb-se-main{
      margin-top: 30px;
      /* border:  solid black; */
      margin-left: 60px;
  }
  
    .usernb-search-main{
      width: 120px;
      margin-left: 320px;
      margin-top: 0px;
  
    }
    .search-usernb{
    
      margin-top: 50px;
      /* margin-left: 100px; */
    }
    .usernb-search-main a:hover {
      color: rgb(7, 7, 7);
      transform: scale(0.1);
     
      padding-left:0px;
    
    }
    .usernb-publish-main a:hover {
      color: rgb(7, 7, 7);
      transform: scale(0.1);
     
      padding-left:0px;
    
    }
   
    .usernb-publish-main{
      width: 250px;
      margin-left: 0px;
    }
    .usernb-publish{
      margin-left: 0px;
    }
    
 
  .usernb-tiger{
    margin-right: -450px;
    margin-bottom: 40px;
   
  }
 
 
 
  .usernb-dropdown {
    position: relative;
    display: inline-block;
    align-items: center;
   
    margin-right: 40px;
  }
 
 
  .usernb-details {
   
    display: inline-block;
    align-items: center;
   
  }
  .usernb-publish{
    margin-left: 0px;
  }
 
  .usernb-dropdown-content {
    display: none;
    height:auto;
    margin-left: -140px;
    position: absolute;
    background-color: white;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
 
  .usernb-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
 .usernb-nav-btn-2{
  
  background: none;
  border: none;

  outline: none;
font-size: 0px;
 }
 .usernb-nav-btnn{
  font-size: 0px;
  margin-top: -20px;
  visibility: visible;
  opacity: 1;
color:rgb(243, 238, 238);
}
 
  .usernb-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    width:90%;
    color: white;
    margin-left:7.5px;
    height:50px;
 
  }
 
  .usernb-dropdown:hover .usernb-dropdown-content {
    display: block;
  }

.usernb-profile-details {
  position: absolute;
  top: 70%; /* Adjust as needed */
  right: 6%;
  transform: translateX(-50%);
  font-size: 10px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.usernb-profile-details p {
  margin: 0;
}
 
.usernb-profile-info-container:hover .usernb-profile-details {
  display: block; /* Show profile details when hovering over the image */
}
 
 
  .usernb-tiger ul{
    display:flex;
    margin-top: 30px;
 
 
 
  }
 
 
 
 
  .usernb-header-icon{
    width: 60px;
    height: 60px;
    margin-top: 20px;
   
  }
  .usernb-icons{
    font-size: 1px;
    color:#054652 COPY;
    background-color: white;
    border:1px solid rgb(227, 219, 219);
    width:30px;
    height:30px;
    border-radius: 100px;
    font-weight: 1000;
 margin-left: -58px;
    margin-top: 1px;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Titillium Web", sans-serif;
    }
   
    :root {
    --mainColor: #f8f7f2;
    --mainColorLight: #5767aa;
    --secondaryColor: #db2b39;
    --textColor: black;
    }
   
    header {
      position:fixed;
      z-index: 1000; /* Ensure the navbar stays on top of other elements */
      top: 0;
      width:100%;
  margin-top: 2%;
   height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 2rem;
    background-color:rgb(247, 243, 243);
    color: var(--textColor);
      /* padding-left: 25px; */
    }
   
    nav a {
    margin: 0 1rem;
    color: var(--textColor);
    text-decoration: none;
    }
   
   
   
   
    nav a:hover {
    color: var(--secondaryColor);
    }
   
    .usernb .usernb-nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
   
    opacity: 0;
    font-size: 1.8rem;
    }
   
    header div,
    nav {
    display: flex;
    align-items: center;
    }
    .usernb-cheader{
      background-color: #05144c;
      width:100%;
      height:30px;
      position:fixed;
      z-index: 1000;
    }
   
    @keyframes eyeMove {
    0%, 10% {
      background-position: 0px 8px;
    }
    13%, 40% {
      background-position: -5px 0px;
    }
    43%, 70% {
      background-position: 5px 0px;
    }
    73%, 90% {
      background-position: 0px 8px;
    }
    93%, 100% {
      background-position: 0px 8px;
    }
    }
   
    @keyframes blink {
    0%, 10%, 12%, 20%, 22%, 40%, 42%, 60%, 62%, 70%, 72%, 90%, 92%, 98%, 100% {
      height: 28px;
    }
    11%, 21%, 41%, 61%, 71%, 91%, 99% {
      height: 9px;
    }
    }
   
 
 
 
 
 
 
 
 
 
    @media only screen and (max-width: 767px)  {

      .usernb-yaa{
        /* border:1px solid rgb(246, 12, 12); */
    
      width:100px;
      color:#054652;
      font-weight: 800;
      margin-top: 0px;
      height:60px;
      margin-left: -10px;
     
     
      }
     
      header .responsive_nav {
        transform: translateY(100vh);
     height: 180px;
      }
     
    
      .usernb-se-main{
        margin-top: 20px;
        margin-right: 10px;

    }
    .usernb-search-main{
      
      width: 200px;
      margin-left: 0px;
     margin-top: 240px;
     position: center;
     
    }
    .usernb-publish-main{
      width: 250px;
      margin-left: -190px;
      margin-top: 160px;
      position: center;
    }
    .usernb-profile-image {
      width: 50px; /* Adjust width as needed */
      height: 40px; /* Adjust height as needed */
      border-radius: 50%; /* To make it circular */
      background-size: cover;
      margin-top: -360px;
      background-position: center;
      margin-left:-95px;
    }
    .usernb-profile-details {
      position: absolute;
      top: 35%; /* Adjust as needed */
      right: 38%;
      transform: translateX(-50%);
      font-size: 10px;
      background-color: white;
      padding: 5px 10px;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
.search-usernb{
width: 100px;

font-size: 20px;
color: white;
}
.usernb-publish-main a:hover {
color: rgb(255, 254, 254);
transform: scale(1.1);

padding-left:0px;

}
.usernb-se-main a:hover {
color: rgb(255, 254, 254);
transform: scale(1.1);

padding-left:0px;

}
nav .nav-close-btn {
position: absolute;
top: 0rem;
right: 2rem;

}

.usernb-publish{
margin-left: 0px;
font-size: 20px;
color: white;
}
/*mobl*/
.usernb-nav-btn-2{
background: none;
border: none;
color: rgb(15, 15, 15);
outline: none;
font-size: 30px;
height: 280px;
}


.usernb-nav-btnn {
font-size: 30px;
margin-top: 0px;
visibility: visible;
opacity: 1;
color:rgb(243, 238, 238);
}


.usernb-publish-1{
margin-left: 0px;
}
      .usernb-clr{
          background-color: #05144c;
          width:100%;
          height:15px;
          position:fixed;
          z-index: 100;
        }     
        .usernb-dropdown-content a:hover {
          background-color: #f1f1f1;
          border:1px solid rgb(201, 189, 189);
          width:90%;
          margin-left:8px;
          height:50px;
       
        }
        .usernb-tiger{
          margin-left: -400px;
        }
       
        .usernb-dropdown:hover .usernb-dropdown-content {
          display: block;
        }
       
      
       
        /* Additional styles for the profile details */
        /* UserHeader.css */
      .usernb-profile-info-container {
        display: flex;
        margin-left: 100px;
        align-items: center;
      }
    
      .usernb-dropdown-content a {
          color: black;
          padding: 10px 10px;
          text-decoration: none;
          display: block;
        }
        .usernb-dropdown-content a:hover {
          background-color: #05144c;
          border:1px solid rgb(201, 189, 189);
          color: white;
          width:91%;
          margin-left:11px;
          height:50px;
       
        }
        .usernb-dropdown-content {
          display: none;
          height: 228px;
          margin-left: -90px;
      font-size: 20px;
          position: absolute;
          background-color: white;
          min-width: auto;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          z-index: 1;
          margin-top: -203px;
        }
      
       
      .usernb-profile-details p {
        margin: 0;
      }
       
      .usernb-profile-info-container:hover .usernb-profile-details {
        display: block; /* Show profile details when hovering over the image */
      }
       
       
        .usernb-tiger ul{
          display:flex;
          margin-top: 0px;
       
       
       
        }
       
       
       
      
        .usernb-icons{
          font-size: 20px;
          color:#054652 COPY;
          background-color: white;
          border:1px solid rgb(227, 219, 219);
          width:25px;
          height:25px;
          border-radius: 50px;
          font-weight: 100;
       margin-left: -0px;
          margin-top: -410px;
        }
        header .usernb-nav-btn {
          visibility: visible;
          opacity: 1;
        color:black;
        }
       
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {  
      header .responsive_nav {
        transform: translateY(100vh);
     height: 240px;
   background-color: #05144c;
      }
      .usernb-yaa{
 
        width:100px;
        color:#ebf0f0;
        font-weight: 800;
        margin-bottom: 0px;
        height:50px;
        margin-left: -10px;
       
        }
     
      .usernb-se-main{
        margin-top: 20px;
margin-left: -0px;
    }
    .usernb-search-main{
      width: 200px;
      margin-left: 0px;
     margin-top: 270px;
     position: center;
    }
    .usernb-publish-main{
      width: 250px;
      margin-left: -180px;
      margin-top: 180px;
      position: center;
    }
    .usernb-profile-image {
      width: 70px; /* Adjust width as needed */
      height: 60px; /* Adjust height as needed */
      border-radius: 50%; /* To make it circular */
      background-size: cover;
      margin-top: -420px;
      background-position: center;
      margin-left:-110px;
    }
    .usernb-profile-details {
      position: absolute;
      top: 35%; /* Adjust as needed */
      right: 43%;
      transform: translateX(-50%);
      font-size: 15px;
      background-color: white;
      padding: 5px 10px;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
.search-usernb{
width: 100px;
color: white;
}
.usernb-search{
width: 40px;
color: white;
}
.usernb-publish-main a:hover {
color: rgb(255, 254, 254);
transform: scale(1.1);

padding-left:0px;

}
.usernb-se-main a:hover {
color: rgb(255, 254, 254);
transform: scale(1.1);

padding-left:0px;

}

.usernb-publish{
margin-left: 0px;
color: white;
}
.usernb-publish-1{
margin-left: 0px;
}
      .usernb-clr{
        background-color: #05144c;
        width:100%;
        height:15px;
        position:fixed;
        z-index: 100;
      }     
      .usernb-dropdown-content a:hover {
        background-color: #f1f1f1;
        border:1px solid rgb(201, 189, 189);
        width:90%;
        margin-left:8px;
        height:50px;
     
      }
      .usernb-tiger{
        margin-left: -400px;
        
      }
     
      .usernb-dropdown:hover .header-dropdown-content {
        display: block;
      }
     
     
      .usernb-icons{
        font-size: 20px;
        color:#054652 COPY;
        background-color: white;
        border:1px solid rgb(227, 219, 219);
        width:35px;
        height:35px;
        border-radius: 50px;
        font-weight: 100;
     margin-left: 30px;
        margin-top: -450px;
      }
   
     
     
      /* Additional styles for the profile details */
      /* UserHeader.css */
    .usernb-profile-info-container {
      display: flex;
      margin-left: 100px;
      align-items: center;
    }
  
    .usernb-dropdown-content a {
        color: black;
        padding: 10px 10px;
        text-decoration: none;
        display: block;
      }
      .usernb-dropdown-content a:hover {
        background-color: #05144c;
        border:1px solid rgb(201, 189, 189);
        color: white;
        width:90%;
        margin-left:10px;
        height:50px;
     
      }
      .usernb-dropdown-content {
        display: none;
        height: 225px;
        margin-left: -60px;
    
        position: absolute;
        background-color: white;
        min-width: auto;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        margin-top: -220px;
      }
    
 
     
    .usernb-profile-details p {
      margin: 0;
    }
   
    .usernb-profile-info-container:hover .usernb-profile-details {
      display: block; /* Show profile details when hovering over the image */
    }
     
     
      .usernb-tiger ul{
        width: 100%;
        display:flex;
        margin-top: 0px;
     
      }
     
      .usernb-nav-btn-2{
        background: none;
        border: none;
        color: rgb(15, 15, 15);
        outline: none;
      font-size: 30px;
      }
      
      
      .usernb-nav-btnn {
        font-size: 30px;
        margin-top: -20px;
        visibility: visible;
        opacity: 1;
      color:rgb(243, 238, 238);
      }
     
    
      
      header .usernb-nav-btn {
        visibility: visible;
        opacity: 1;
      color:black;
      }
   
   
  }
  @media only screen and (min-width: 1280px) and (max-width: 1440px) {
   
.usernb-user{
  height: 100px;
 }
 
*{
    margin:0;
  }
  .usernb-linked-text{
    text-decoration: none;
    }
    .usernb-logo-10{
       
        margin-top: 20px;
      }
 
 
      .usernb-cheader{
        height: 300px;
      }
  .usernb-yaa{
 
  width:150px;
  color:#054652;
  font-weight: 800;
  margin-top: 0px;
  height:80px;
 
 
  }
  /* Navbar.css */
  .usernb-tiger a {
 
 
 
    transition: transform 0.3s ease; /* Add transition property */
  }
 
    .usernb-tiger ul p a{
    color:#054652
    COPY
    ;
    font-size: 20px;
    font-weight: 100px;
 
    transition: transform 0.3s ease; /* Add transition property */
    font-family:'Times New Roman', Times, serif;
  }
 
 
  .usernb-tiger a:hover {
    color: rgb(7, 7, 7);
    transform: scale(1.1);
   
    padding-left:4px;
 
  }
  .usernb-profile-info-container {
    display: flex;
    margin-left:0px;
    align-items: center;
  }
  .usernb-profile-image {
      width: 70px; /* Adjust width as needed */
      height: 60px; /* Adjust height as needed */
      border-radius: 70%; /* To make it circular */
      background-size: cover;
      margin-top: -30px;
      background-position: center;
      margin-left: -100px;
      background-image: url('../Images/17134403639001.jpg'); 
    }
 
  .usernb-se-main{
      margin-top: 30px;
      /* border:  solid black; */
      margin-left: 220px;
  }
  
    .usernb-search-main{
      width: 120px;
      margin-left: 0px;
      margin-top: 0px;
  
    }
    .search-usernb{
    
      margin-top: 50px;
      /* margin-left: 100px; */
    }
    .usernb-search-main a:hover {
      color: rgb(7, 7, 7);
      transform: scale(0.1);
     
      padding-left:0px;
    
    }
    .usernb-publish-main a:hover {
      color: rgb(7, 7, 7);
      transform: scale(0.1);
     
      padding-left:0px;
    
    }
   
    .usernb-publish-main{
      width: 250px;
      margin-left: 0px;
    }
    .usernb-publish{
      margin-left: 0px;
    }
    
 
  .usernb-tiger{
    margin-right: -450px;
    margin-bottom: 40px;
   
  }
 
 
 
  .usernb-dropdown {
    position: relative;
    display: inline-block;
    align-items: center;
   
    margin-right: 100px;
  }
 
 
  .usernb-details {
   
    display: inline-block;
    align-items: center;
   
  }
  .usernb-publish{
    margin-left: 0px;
  }
 
  .usernb-dropdown-content {
    display: none;
    height:auto;
    margin-left: -150px;
    position: absolute;
    background-color: white;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
 
  .usernb-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
 .usernb-nav-btn-2{
  
  background: none;
  border: none;

  outline: none;
font-size: 0px;
 }
 .usernb-nav-btnn{
  font-size: 0px;
  margin-top: -20px;
  visibility: visible;
  opacity: 1;
color:rgb(243, 238, 238);
}
 
  .usernb-dropdown-content a:hover {
    background-color: #05144c;
    border:1px solid rgb(201, 189, 189);
    width:90%;
    color: white;
    margin-left:7.5px;
    height:50px;
 
  }
 
  .usernb-dropdown:hover .usernb-dropdown-content {
    display: block;
  }

.usernb-profile-details {
  position: absolute;
  top: 70%; /* Adjust as needed */
  right: 5.7%;
  transform: translateX(-50%);
  font-size: 10px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.usernb-profile-details p {
  margin: 0;
}
 
.usernb-profile-info-container:hover .usernb-profile-details {
  display: block; /* Show profile details when hovering over the image */
}
 
 
  .usernb-tiger ul{
    display:flex;
    margin-top: 30px;
 
 
 
  }
 
 
 
 
  .usernb-header-icon{
    width: 60px;
    height: 60px;
    margin-top: 20px;
   
  }
  .usernb-icons{
    font-size: 1px;
    color:#054652 COPY;
    background-color: #dfdfe1;
    border:1px solid rgb(227, 219, 219);
    width:30px;
    height:30px;
    border-radius: 100px;
    font-weight: 1000;
 margin-left: -58px;
    margin-top: 1px;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Titillium Web", sans-serif;
    }
   
    :root {
    --mainColor: #f8f7f2;
    --mainColorLight: #5767aa;
    --secondaryColor: #db2b39;
    --textColor: black;
    }
   
    header {
      position:fixed;
      z-index: 1000; /* Ensure the navbar stays on top of other elements */
      top: 0;
      width:100%;
  margin-top: 2%;
   height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 2rem;
    background-color:rgb(247, 243, 243);
    color: var(--textColor);
      /* padding-left: 25px; */
    }
   
    nav a {
    margin: 0 1rem;
    color: var(--textColor);
    text-decoration: none;
    }
   
   
   
   
    nav a:hover {
    color: var(--secondaryColor);
    }
   
    .usernb .usernb-nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
   
    opacity: 0;
    font-size: 1.8rem;
    }
   
    header div,
    nav {
    display: flex;
    align-items: center;
    }
    .usernb-cheader{
      background-color: #05144c;
      width:100%;
      height:30px;
      /* margin-top: -188px; */
      position:fixed;
      z-index: 1000;
    }
   
    @keyframes eyeMove {
    0%, 10% {
      background-position: 0px 8px;
    }
    13%, 40% {
      background-position: -5px 0px;
    }
    43%, 70% {
      background-position: 5px 0px;
    }
    73%, 90% {
      background-position: 0px 8px;
    }
    93%, 100% {
      background-position: 0px 8px;
    }
    }
   
    @keyframes blink {
    0%, 10%, 12%, 20%, 22%, 40%, 42%, 60%, 62%, 70%, 72%, 90%, 92%, 98%, 100% {
      height: 28px;
    }
    11%, 21%, 41%, 61%, 71%, 91%, 99% {
      height: 9px;
    }
    }
 
}


