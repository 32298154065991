 
body {
  font-family: Arial, sans-serif;
}
 
/* CSS for the h2 tag */
.rider-bookings-title {
  margin-left: 300px;
}
 
/* CSS for the test alliance center */
.test-alliance-center {
  /* Add your desired styles here */
  /* Example styles: */
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
}
 
 
/* Example styles for popup/modal */
.sstyled-popup {
position: fixed;
top: 58%;
left: 50%;
transform: translate(-50%, -50%);
background-color: white;
padding: 20px;
width: 900px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
z-index: 100; /* Ensure the popup is on top */
max-width: 100%;
max-height: auto;
overflow-y: auto;
border-radius: 5px;
}
 
.rider-search-delete {
  display: flex;
  justify-content: space-between;
}
.sstyled-popup-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  max-width: 900px;
  position: relative;
  border-radius: 5px;
}
 
.sstyled-tablepopup{
 
    height: 175px;
    width: 74.5%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
 
  .sstyled-tablepopup thead tr {
    background-color: #05144c;
    color: #ffffff;
    text-align: left;
  }
  .sstyled-tablepopup{
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }
  .sstyled-tablepopup th,
  .sstyled-tablepopup td{
 
   
 
    padding: 12px 30px;
    position: sticky;
    z-index: 1;
   
  }
   
  .sstyled-tablepopup tbody tr {
    border-bottom: 1px solid #dddddd;
  }
   
  .sstyled-tablepopup tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
   
  .sstyled-tablepopup tbody tr:last-of-type {
    border-bottom: 2px solid #dfdfdf;
  }
   
  .sstyled-tablepopup thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #0c2b99;
    color: white;
    /*layanu*/
    border: 0px solid white;
   
   
  }
 
.sstyled-popup-content h2 {
  margin-top: 0;
}
 
.sstyled-popup-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
 
}
 
.sstyled-popup-content table th, .sstyled-popup-content table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
 
.sstyled-popup-content table th {
  background-color: #04386b;
}
 
.sstyled-popup-content .close {
  color: black;
  position: absolute;
 
  top: 10px;
  right: 20px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}
 
.sstyled-popup-content .close:hover,
.sstyled-popup-content .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
 
.sstyled-popup-content p {
  text-align: center;
  margin-top: 20px;
}
 
/* Custom styles for the tabs */
.nav-tabs {
  border-bottom: 2px solid #04386b;
}
 
.nav-tabs .nav-link {
 
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: white;
  padding: 0.5rem 1rem;
  margin-bottom: -1px;
  background-color:#051554;
}
 
/* .nav-tabs .nav-link:hover {
  border-color: #0c79e6 #117be6 #0d75dd;
} */
 
.nav-tabs .nav-link.active {
  color: black;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
 
.tab-content > .tab-pane {
  height: 500px;
  padding: 1rem;
  border-top: none;
}
 
.tab-content > .tab-pane.active {
  animation: fadeIn 0.3s;
}
 
/* Add a fade-in animation for the tab content */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
 
/* Optional: Additional styling for better appearance */
.nav-tabs.justify-content-center .nav-link {
  flex: 1;
  text-align: center;
}
 
.nav-tabs.justify-content-center .nav-item {
  display: flex;
  justify-content: center;
}
 
.sstyled-main {
  width: 100%;
  overflow: auto;
  padding-top: 15px;
 
 
}
 
.sstyled-table-5 {
 
  border: 0px solid rgb(254, 253, 253);
  overflow-y: auto;
  margin-left: 150px;
 
  margin-top: 130px;
 
  max-width: 1200px;
 
  padding: 20px;
 
  padding-bottom: 0px;
 
  background-color: #f0f0f0;
 
  border: 1px solid #ccc;
 
  border-radius: 5px;
}
 
.sstyled-table {
 
  height: 430px;
  width: 90.5%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
 
.sstyled-table thead tr {
  background-color: #05144c;
  color: #ffffff;
  text-align: left;
}
 
 
 
 
 
th, td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: middle;
}
 
.table-wrapper {
  overflow-y: auto;
  max-height: 500px;
}
 
 
 
.sstyled-table-5,
.sstyled-table {
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}
 
 
.sstyled-table th,
.sstyled-table td {
  padding: 12px 30px;
  position: sticky;
  z-index: 1;
 
}
 
.sstyled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
 
.sstyled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
 
.sstyled-table tbody tr:last-of-type {
  border-bottom: 2px solid #dfdfdf;
}
 
.sstyled-table thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #05144c;
  color: white;
  /*layanu*/
  border: 0px solid white;
 
 
}
 
.me-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
 
.btn {
  border: none;
  color: white;
  padding: 5px 8px;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
 
.sbtn-edit {
  background-color: #f2f3f3;
}
 
.btn-delete {
  background-color: #f21b0c;
  width: 52px;
  border-radius: 10px;
 
  /* margin-left: 20px; */
  height: 35px;
  color: #f9f3f3;
}
.SA-rider-search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: 1px solid black; */
}
.SA-rider-search-input {
  margin: 10px 0px;
  padding: 7px;
  margin-right: auto;
  width:200px;
  height:30px;
  border-radius: 10px;
  /* margin-top: 150px; */
  /* margin-left: -380px; */
}
.sbtn-btn-delete{
    background-color: #f21b0c;
  width: 120px;
  border-radius: 10px;
  margin-top: 10px;
/* padding-right: 50px; */
  height: 30px;
  color: #f9f3f3;
 
}
.sbtn-btn-delete-main{
    margin-left: 650px;
}
 
.sbtn-btn-view {
  padding: 5px 10px;
 
  border-radius: 10px;
 
  background-color: #05144c;
  color: white;
}
.btn-contact {
  background-color: #e82764;
  margin-bottom: 10px;
}
 
 
.success-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: green;
  color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 16px;
  text-align: center;
}
@media only screen and (min-width: 320px) and (max-width: 343px) {  
 
 
 
  .sstyled-main {
    width: 100%;
    overflow: auto;
    margin-top: 0;
   
   
  }
   
  .sstyled-table-5 {
   
    border: 0px solid rgb(254, 253, 253);
    margin-top: 100px;
    overflow-y: auto;
    max-height: 600px;
    margin-left: 0px;
  }
   
  .sstyled-table {
    height: 320px;
    width: 250px;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-left: 0px;
  }
   
  .sstyled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
    text-align: left;
  }
   
  th, td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
    vertical-align: middle;
  }
   
  .table-wrapper {
    overflow-y: auto;
    max-height: 500px;
  }
   
   
   
  .sstyled-table-5,
  .sstyled-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }
   
   
  .sstyled-table th,
  .sstyled-table td {
    padding: 12px 30px;
    position: sticky;
    z-index: 1;
   
  }
   
  .sstyled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
   
  .sstyled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
   
  .sstyled-table tbody tr:last-of-type {
    border-bottom: 2px solid #dfdfdf;
  }
  .SA-rider-search-input {
    width:120px;
  }
 
  .sbtn-btn-delete{
  width: 100px;
 
  }
  .sstyled-table thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #05144c;
    color: white;
    /*layanu*/
    border: 0px solid white;
   
   
  }
   
  .me-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
   
  .btn {
    border: none;
    color: white;
    padding: 5px 8px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
   
  .sbtn-edit {
    background-color: #f2f3f3;
  }
   
  .btn-delete {
    background-color: #f21b0c;
    width: 70px;
    border-radius: 10px;
    height: 40px;
    color: #f9f3f3;
  }
   
  .btn-view {
    background-color: #e7e7e7;
    color: black;
  }
  .sbtn-btn-delete-main{
    margin-left: 70px;
  }
    /* CSS for the h2 tag */
.rider-bookings-title {
  margin-left: 20px;
 
}
   
  }
 
@media only screen and (min-width: 360px) and (max-width: 374px) {  
 
 
 
  .sstyled-main {
    width: 100%;
    overflow: auto;
    margin-top: 0;
   
   
  }
  .SA-rider-search-input {
    width:140px;
  }
 
  .sbtn-btn-delete{
  width: 100px;
 
  }
   
  .sstyled-table-5 {
   
    border: 0px solid rgb(254, 253, 253);
    margin-top: 100px;
    overflow-y: auto;
    max-height: 600px;
    margin-left: 0px;
  }
   
  .sstyled-table {
    height: 420px;
    width: 310px;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-left: -12px;
  }
   
  .sstyled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
    text-align: left;
  }
   
  th, td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
    vertical-align: middle;
  }
   
  .table-wrapper {
    overflow-y: auto;
    max-height: 500px;
  }
   
   
   
  .sstyled-table-5,
  .sstyled-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }
   
   
  .sstyled-table th,
  .sstyled-table td {
    padding: 12px 30px;
    position: sticky;
    z-index: 1;
   
  }
   
  .sstyled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
   
  .sstyled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
   
  .sstyled-table tbody tr:last-of-type {
    border-bottom: 2px solid #dfdfdf;
  }
   
  .sstyled-table thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #05144c;
    color: white;
    /*layanu*/
    border: 0px solid white;
   
   
  }
   
  .me-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
   
  .btn {
    border: none;
    color: white;
    padding: 5px 8px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
   
  .sbtn-edit {
    background-color: #f2f3f3;
  }
   
  .btn-delete {
    background-color: #f21b0c;
    width: 70px;
    border-radius: 10px;
    height: 40px;
    color: #f9f3f3;
  }
   
  .btn-view {
    background-color: #e7e7e7;
    color: black;
  }
  .sbtn-btn-delete-main{
    margin-left: 110px;
  }
    /* CSS for the h2 tag */
.rider-bookings-title {
  margin-left: 50px;
 
}
   
  }
 
@media only screen and (min-width: 375px) and (max-width: 383px) {  
 
 
 
  .sstyled-main {
    width: 100%;
    overflow: auto;
    margin-top: 0;
   
   
  }
  .SA-rider-search-input {
    width:150px;
  }
 
  .sbtn-btn-delete{
  width: 100px;
 
  }
   
  .sstyled-table-5 {
   
    border: 0px solid rgb(254, 253, 253);
    margin-top: 100px;
    overflow-y: auto;
    max-height: 600px;
    margin-left: 0px;
  }
   
  .sstyled-table {
    height: 420px;
    width: 325px;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-left: -12px;
  }
   
  .sstyled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
    text-align: left;
  }
   
  th, td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
    vertical-align: middle;
  }
   
  .table-wrapper {
    overflow-y: auto;
    max-height: 500px;
  }
   
   
   
  .sstyled-table-5,
  .sstyled-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }
   
   
  .sstyled-table th,
  .sstyled-table td {
    padding: 12px 30px;
    position: sticky;
    z-index: 1;
   
  }
   
  .sstyled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
   
  .sstyled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
   
  .sstyled-table tbody tr:last-of-type {
    border-bottom: 2px solid #dfdfdf;
  }
   
  .sstyled-table thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #05144c;
    color: white;
    /*layanu*/
    border: 0px solid white;
   
   
  }
   
  .me-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
   
  .btn {
    border: none;
    color: white;
    padding: 5px 8px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
   
  .sbtn-edit {
    background-color: #f2f3f3;
  }
   
  .btn-delete {
    background-color: #f21b0c;
    width: 70px;
    border-radius: 10px;
    height: 40px;
    color: #f9f3f3;
  }
   
  .btn-view {
    background-color: #e7e7e7;
    color: black;
  }
  .sbtn-btn-delete-main{
    margin-left: 120px;
  }
    /* CSS for the h2 tag */
.rider-bookings-title {
  margin-left: 50px;
 
}
   
  }
 
@media only screen and (min-width: 390px) and (max-width: 411px) {  
 
 
 
  .sstyled-main {
    width: 100%;
    overflow: auto;
    margin-top: 0;
   
   
  }
  .SA-rider-search-input {
    width:150px;
  }
 
  .sbtn-btn-delete{
  width: 100px;
 
  }
   
  .sstyled-table-5 {
   
    border: 0px solid rgb(254, 253, 253);
    margin-top: 100px;
    overflow-y: auto;
    max-height: 600px;
    margin-left: 0px;
  }
   
  .sstyled-table {
    height: 420px;
    width: 343px;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-left: -12px;
  }
   
 
  .sstyled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
    text-align: left;
  }
   
  th, td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
    vertical-align: middle;
  }
   
  .table-wrapper {
    overflow-y: auto;
    max-height: 500px;
  }
   
   
   
  .sstyled-table-5,
  .sstyled-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }
   
   
  .sstyled-table th,
  .sstyled-table td {
    padding: 12px 30px;
    position: sticky;
    z-index: 1;
   
  }
   
  .sstyled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
   
  .sstyled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
   
  .sstyled-table tbody tr:last-of-type {
    border-bottom: 2px solid #dfdfdf;
  }
   
  .sstyled-table thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #05144c;
    color: white;
    /*layanu*/
    border: 0px solid white;
   
   
  }
   
  .me-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
   
  .btn {
    border: none;
    color: white;
    padding: 5px 8px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
   
  .sbtn-edit {
    background-color: #f2f3f3;
  }
   
  .btn-delete {
    background-color: #f21b0c;
    width: 70px;
    border-radius: 10px;
    height: 40px;
    color: #f9f3f3;
  }
   
  .btn-view {
    background-color: #e7e7e7;
    color: black;
  }
  .sbtn-btn-delete-main{
    margin-left: 140px;
  }
 
 
 
  /* CSS for the h2 tag */
.rider-bookings-title {
  margin-left: 50px;
 
}
 
 
 
   
  }
 
@media only screen and (min-width: 412px) and (max-width: 427px) {  
  .rider-bookings-title {
    margin-left: 80px;
 
  }
  .sstyled-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 900px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100; /* Ensure the popup is on top */
    max-width: 100%;
    max-height: auto;
    overflow-y: auto;
    border-radius: 5px;
    }
    .sbtn-btn-delete-main{
      margin-left: 510px;
  }


 
  .sstyled-main {
    width: 100%;
    overflow: auto;
    margin-top: 0;
   
   
  }
   
  .sstyled-table-5 {
   
    border: 0px solid rgb(254, 253, 253);
    margin-top: 100px;
    overflow-y: auto;
    max-height: 600px;
    margin-left: 0px;
  }
   
  .sstyled-table {
    height: 420px;
    width: 350px;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-left: -5px;
  }
   
  .sstyled-table thead tr {
    background-color: #05144c;
    color: #ffffff;
    text-align: left;
  }
   
  th, td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
    vertical-align: middle;
  }
   
  .table-wrapper {
    overflow-y: auto;
    max-height: 500px;
  }
   
   
   
  .sstyled-table-5,
  .sstyled-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }
   
   
  .sstyled-table th,
  .sstyled-table td {
    padding: 12px 30px;
    position: sticky;
    z-index: 1;
   
  }
   
  .sstyled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
   
  .sstyled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
   
  .sstyled-table tbody tr:last-of-type {
    border-bottom: 2px solid #dfdfdf;
  }
   
  .sstyled-table thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #05144c;
    color: white;
    /*layanu*/
    border: 0px solid white;
   
   
  }
   
  .me-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
   
  .btn {
    border: none;
    color: white;
    padding: 5px 8px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
   
  .sbtn-edit {
    background-color: #f2f3f3;
  }
   
  .btn-delete {
    background-color: #f21b0c;
    width: 70px;
    border-radius: 10px;
    height: 40px;
    color: #f9f3f3;
  }
   
  .btn-view {
    background-color: #e7e7e7;
    color: black;
  }
  .sbtn-btn-delete-main{
    margin-left: 160px;
  }
   
  }
 
@media only screen and (min-width: 428px) and (max-width: 767px) {  
 
 
 
.sstyled-main {
  width: 100%;
  overflow: auto;
  margin-top: 0;
 
 
}
 
.sstyled-table-5 {
 
  border: 0px solid rgb(254, 253, 253);
  margin-top: 100px;
  overflow-y: auto;
  max-height: 600px;
  margin-left: 0px;
}
 
.sstyled-table {
  height: 420px;
  width: 380px;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-left: -15px;
}
 
.sstyled-table thead tr {
  background-color: #05144c;
  color: #ffffff;
  text-align: left;
}
 
th, td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: middle;
}
 
.table-wrapper {
  overflow-y: auto;
  max-height: 500px;
}
 
 
 
.sstyled-table-5,
.sstyled-table {
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}
 
 
.sstyled-table th,
.sstyled-table td {
  padding: 12px 30px;
  position: sticky;
  z-index: 1;
 
}
 
.sstyled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}
 
.sstyled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
 
.sstyled-table tbody tr:last-of-type {
  border-bottom: 2px solid #dfdfdf;
}
 
.sstyled-table thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #05144c;
  color: white;
  /*layanu*/
  border: 0px solid white;
 
 
}
 
.me-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
 
.btn {
  border: none;
  color: white;
  padding: 5px 8px;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
 
.sbtn-edit {
  background-color: #f2f3f3;
}
 
.btn-delete {
  background-color: #f21b0c;
  width: 70px;
  border-radius: 10px;
  height: 40px;
  color: #f9f3f3;
}
 
.btn-view {
  background-color: #e7e7e7;
  color: black;
}
.sbtn-btn-delete-main{
  margin-left: 170px;
}
 
}
 
 
@media only screen and (min-width: 768px) and (max-width: 799px) {  
  .sstyled-main {
      overflow: auto;
      margin-top: 0;
      margin-left: 0px;
  }
 
 
  .sstyled-table-5 {
      border: 130px solid white;
      margin-top: 20px;
      overflow-y: auto;
      margin-left: -30px;
      max-height: 1050px;
      overflow-x: auto;
  }
 
  .sstyled-table thead th {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: #05144c;
      color: white;
      border: 0px solid white;
 
 
  }
 
  .sstyled-table {
      height: 450px;
      width: 100%;
      overflow-x: auto;
      border-collapse: collapse;
      margin: auto;
      font-size: 0.9em;
      font-family: sans-serif;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
 
  .sstyled-table th,
  .sstyled-table td {
      padding: 0.8em;
  }
  .btn-delete {
      background-color: #f21b0c;
      width: 70px;
      border-radius: 10px;
      height: 40px;
      color: #f9f3f3;
  }
 
  .sstyled-popup {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 900px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100; /* Ensure the popup is on top */
    max-width: 100%;
    max-height: auto;
    overflow-y: auto;
    border-radius: 5px;
    }
    .sbtn-btn-delete-main{
      margin-left: 510px;
  }
}
 
@media only screen and (min-width: 800px) and (max-width: 819px) {  
  .sstyled-main {
      overflow: auto;
      margin-top: 0;
      margin-left: 0px;
  }
 
 
  .sstyled-table-5 {
      border: 130px solid white;
      margin-top: 20px;
      overflow-y: auto;
      margin-left: 0px;
      max-height: 1050px;
      overflow-x: auto;
  }
 
  .sstyled-table thead th {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: #05144c;
      color: white;
      border: 0px solid white;
 
 
  }
 
  .sstyled-table {
      height: 450px;
      width: 100%;
      overflow-x: auto;
      border-collapse: collapse;
      margin: auto;
      font-size: 0.9em;
      font-family: sans-serif;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
 
  .sstyled-table th,
  .sstyled-table td {
      padding: 0.8em;
  }
  .btn-delete {
      background-color: #f21b0c;
      width: 70px;
      border-radius: 10px;
      height: 40px;
      color: #f9f3f3;
  }
 
  .sstyled-popup {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 900px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100; /* Ensure the popup is on top */
    max-width: 100%;
    max-height: auto;
    overflow-y: auto;
    border-radius: 5px;
    }
    .sbtn-btn-delete-main{
      margin-left: 550px;
  }
}
 
 
@media only screen and (min-width: 820px) and (max-width: 883px) {  
  .sstyled-main {
      overflow: auto;
      margin-top: 0;
      margin-left: 0px;
  }
 
 
  .sstyled-table-5 {
      border: 130px solid white;
      margin-top: 20px;
      overflow-y: auto;
      margin-left: 0px;
      max-height: 1050px;
      overflow-x: auto;
  }
 
  .sstyled-table thead th {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: #05144c;
      color: white;
      border: 0px solid white;
 
 
  }
 
  .sstyled-table {
      height: 450px;
      width: 100%;
      overflow-x: auto;
      border-collapse: collapse;
      margin: auto;
      font-size: 0.9em;
      font-family: sans-serif;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
 
  .sstyled-table th,
  .sstyled-table td {
      padding: 0.8em;
  }
  .btn-delete {
      background-color: #f21b0c;
      width: 70px;
      border-radius: 10px;
      height: 40px;
      color: #f9f3f3;
  }
 
  .sstyled-popup {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 900px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100; /* Ensure the popup is on top */
    max-width: 100%;
    max-height: auto;
    overflow-y: auto;
    border-radius: 5px;
    }
    .sbtn-btn-delete-main{
      margin-left: 570px;
  }
}
  @media only screen and (min-width: 884px) and (max-width: 1279px) {  
    .sstyled-main {
        overflow: auto;
        margin-top: 0;
        margin-left: 0px;
    }
 
   
    .sstyled-table-5 {
        border: 130px solid white;
        margin-top: 20px;
        overflow-y: auto;
        margin-left: 0px;
        max-height: 1050px;
        overflow-x: auto;
    }
   
    .sstyled-table thead th {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #05144c;
        color: white;
        border: 0px solid white;
   
   
    }
   
    .sstyled-table {
        height: 450px;
        width: 94%;
        overflow-x: auto;
        border-collapse: collapse;
        margin: auto;
        font-size: 0.9em;
        font-family: sans-serif;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
   
    .sstyled-table th,
    .sstyled-table td {
        padding: 0.8em;
    }
    .btn-delete {
        background-color: #f21b0c;
        width: 70px;
        border-radius: 10px;
        height: 40px;
        color: #f9f3f3;
    }
 
    .sstyled-popup {
      position: fixed;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 20px;
      width: 900px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 100; /* Ensure the popup is on top */
      max-width: 100%;
      max-height: auto;
      overflow-y: auto;
      border-radius: 5px;
      }
  }
  @media only screen and (min-width: 1280px) and (max-width: 1440px) {  
    body {
      font-family: Arial, sans-serif;
    }
     

     
    /* Example styles for popup/modal */
    .sstyled-popup {
    position: fixed;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 900px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100; /* Ensure the popup is on top */
    max-width: 100%;
    max-height: auto;
    overflow-y: auto;
    border-radius: 5px;
    }
     
    .sstyled-popup-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 100%;
      max-width: 900px;
      position: relative;
      border-radius: 5px;
    }
   
    .sstyled-tablepopup{
     
        height: 175px;
        width: 74.5%;
        overflow-x: auto;
        border-collapse: collapse;
        margin: auto;
        font-size: 0.9em;
        font-family: sans-serif;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      }
     
      .sstyled-tablepopup thead tr {
        background-color: #05144c;
        color: #ffffff;
        text-align: left;
      }
       
       
       
       
   
       
       
   
      .sstyled-tablepopup{
        overflow-x: auto;
        display: block;
        white-space: nowrap;
      }
      .sstyled-tablepopup th,
      .sstyled-tablepopup td{
   
       
   
        padding: 12px 30px;
        position: sticky;
        z-index: 1;
       
      }
       
      .sstyled-tablepopup tbody tr {
        border-bottom: 1px solid #dddddd;
      }
       
      .sstyled-tablepopup tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
      }
       
      .sstyled-tablepopup tbody tr:last-of-type {
        border-bottom: 2px solid #dfdfdf;
      }
       
      .sstyled-tablepopup thead th {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #0c2b99;
        color: white;
        /*layanu*/
        border: 0px solid white;
       
       
      }
     
    .sstyled-popup-content h2 {
      margin-top: 0;
    }
     
    .sstyled-popup-content table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;
     
    }
     
    .sstyled-popup-content table th, .sstyled-popup-content table td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }
     
    .sstyled-popup-content table th {
      background-color: #04386b;
    }
     
    .sstyled-popup-content .close {
      color: black;
      position: absolute;
     
      top: 10px;
      right: 20px;
      font-size: 28px;
      font-weight: bold;
      cursor: pointer;
    }
     
    .sstyled-popup-content .close:hover,
    .sstyled-popup-content .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
     
    .sstyled-popup-content p {
      text-align: center;
      margin-top: 20px;
    }
     
    /* Custom styles for the tabs */
    .nav-tabs {
      border-bottom: 2px solid #04386b;
    }
     
    .nav-tabs .nav-link {
     
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      color: white;
      padding: 0.5rem 1rem;
      margin-bottom: -1px;
      background-color:#051554;
    }
     
    /* .nav-tabs .nav-link:hover {
      border-color: #0c79e6 #117be6 #0d75dd;
    } */
     
    .nav-tabs .nav-link.active {
      color: black;
      background-color: #fff;
      border-color: #dee2e6 #dee2e6 #fff;
    }
     
    .tab-content > .tab-pane {
      height: 500px;
      padding: 1rem;
      border-top: none;
    }
     
    .tab-content > .tab-pane.active {
      animation: fadeIn 0.3s;
    }
     
    /* Add a fade-in animation for the tab content */
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
     
    /* Optional: Additional styling for better appearance */
    .nav-tabs.justify-content-center .nav-link {
      flex: 1;
      text-align: center;
    }
     
    .nav-tabs.justify-content-center .nav-item {
      display: flex;
      justify-content: center;
    }
     
    .sstyled-main {
      width: 100%;
      overflow: auto;
      padding-top: 15px;
     
     
    }
     
    .sstyled-table-5 {
     
      border: 0px solid rgb(254, 253, 253);
      margin-top: 150px;
      overflow-y: auto;
      margin-left: 40px;
    }
     
    .sstyled-table {
     
      height: 450px;
      width: 74.5%;
      overflow-x: auto;
      border-collapse: collapse;
      margin: auto;
      font-size: 0.9em;
      font-family: sans-serif;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
   
    .sstyled-table thead tr {
      background-color: #05144c;
      color: #ffffff;
      text-align: left;
    }
     
     
     
     
     
    th, td {
      padding: 8px 12px;
      border: 1px solid #ddd;
      text-align: left;
      vertical-align: middle;
    }
     
    .table-wrapper {
      overflow-y: auto;
      max-height: 500px;
    }
     
     
     
    .sstyled-table-5,
    .sstyled-table {
      overflow-x: auto;
      display: block;
      white-space: nowrap;
    }
     
     
    .sstyled-table th,
    .sstyled-table td {
      padding: 12px 30px;
      position: sticky;
      z-index: 1;
     
    }
     
    .sstyled-table tbody tr {
      border-bottom: 1px solid #dddddd;
    }
     
    .sstyled-table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }
     
    .sstyled-table tbody tr:last-of-type {
      border-bottom: 2px solid #dfdfdf;
    }
     
    .sstyled-table thead th {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: #05144c;
      color: white;
      /*layanu*/
      border: 0px solid white;
     
     
    }
     
    .me-profile-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
     
    .btn {
      border: none;
      color: white;
      padding: 5px 8px;
      text-decoration: none;
      text-align: center;
      display: inline-block;
      border-radius: 5px;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
    }
     
    .sbtn-edit {
      background-color: #f2f3f3;
    }
     
    .btn-delete {
      background-color: #f21b0c;
      width: 52px;
      border-radius: 10px;
     
      /* margin-left: 20px; */
      height: 35px;
      color: #f9f3f3;
    }
    .sbtn-btn-delete{
        background-color: #f21b0c;
      width: 120px;
      border-radius: 10px;
     
    /* padding-right: 50px; */
      height: 35px;
      color: #f9f3f3;
   
    }
    .sbtn-btn-delete-main{
        margin-left: 650px;
    }
     
    .btn-view {
      background-color: #e7e7e7;
      color: black;
    }
     
    .btn-contact {
      background-color: #e82764;
      margin-bottom: 10px;
    }
     
     
    .success-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: green;
      color: white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      font-size: 16px;
      text-align: center;
    }
     
   
}
 