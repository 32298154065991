.tp-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}
.tp-travel-back{
  /* border: 1px solid #0af822; */
font-size: 20px;
margin-left: 40%;
}

.tp-travel-prefer {
  /* border: 1px solid #0af822; */
  margin-left: 35%;

  text-align: center;
  margin-bottom: 20px;
}

.tp-travel-preferences {
  border: 700px;
  height: 570px;
  margin-top: 120px;
  border-radius: 10px;

  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 1200px;
  margin-top: 150px;
}

.tp-sections-contents {
  margin-bottom: 20px;
}

.tp-travel, .vehicle {
  text-align: center;
  margin-bottom: 20px;
}

.tp-preferences, .tp-vehicle-preferences {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.tp-preference {
  flex: 1 1 300px; /* Adjust the width of each preference item */
}

.tp-preference label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.tp-custom-dropdown,
.tp-custom-input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.tp-custom-dropdown {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.tp-custom-input:disabled {
  background-color: #f9f9f9;
  cursor: not-allowed;

}

.tp-custom-links {
  color: #fff;
  background-color: #05144c;
  text-decoration: none !important;outline: none; /* No outline on focus */

  border-radius: 50px;
  padding: 10px 20px;
  text-align: center;
  display: block;
  margin: 20px auto 0;
  margin-top: 70px;
  margin-left: 100px;
}

.tp_save-success, .tp_save-error {
  position: fixed;
  top: 140px; /* Adjust top position to clear the navbar */
  right: 20px; /* Adjust right position to align with the right side of the screen */
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.tp-error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}





  /* Responsive Styles */
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .tp-travel-preferences {
      width: 95%;
      margin-top: 150px;
      padding: 15px;
    }
    .tp-travel-back{
      margin-left: 85%;
      }
    .tp-preferences, .tp-vehicle-preferences {
      gap: 10px;
    }
  
    .tp-custom-dropdown,
    .tp-custom-input {
      max-width: 100%;
      padding: 8px;
      font-size: 16px; /* Increase font size for tablet view */
    }
  
    .tp-preference label {
      font-size: 16px; /* Increase font size for labels in tablet view */
    }
  
    .tp-custom-links {
      padding: 8px 15px;
      font-size: 16px; /* Increase font size for links in tablet view */
    }
  
    .tp_save-success, .tp_save-error {
      top: 100px;
      right: 10px;
      padding: 3px 5px;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 883px) {
    .tp-travel-preferences {
      width: 95%;
      margin-top: 150px;
      padding: 15px;
      height: 580px;
    }
  
    .tp-preferences, .tp-vehicle-preferences {
      gap: 10px;
    }
    .tp-travel-back{
      margin-left: 90%;
      }
  
    .tp-custom-dropdown,
    .tp-custom-input {
      max-width: 100%;
      padding: 8px;
      font-size: 16px; /* Increase font size for tablet view */
    }
  
    .tp-preference label {
      font-size: 16px; /* Increase font size for labels in tablet view */
    }
  
    .tp-custom-links {
      padding: 8px 15px;
      font-size: 16px; /* Increase font size for links in tablet view */
    }
  
    .tp_save-success, .tp_save-error {
      top: 100px;
      right: 10px;
      padding: 3px 5px;
    }

  }
  @media only screen and (min-width: 884px) and (max-width: 1279px) {
    .tp-travel-preferences {
      width: 90%;
      margin-top: 150px;
      padding: 15px;
      height: 580px;
    }
  
    .tp-preferences, .tp-vehicle-preferences {
      gap: 10px;
    }
    .tp-travel-back{
      margin-left: 90%;
      }
  
    .tp-custom-dropdown,
    .tp-custom-input {
      max-width: 100%;
      padding: 8px;
      font-size: 16px; /* Increase font size for tablet view */
    }
  
    .tp-preference label {
      font-size: 16px; /* Increase font size for labels in tablet view */
    }
  
    .tp-custom-links {
      padding: 8px 15px;
      font-size: 16px; /* Increase font size for links in tablet view */
    }
  
    .tp_save-success, .tp_save-error {
      top: 100px;
      right: 10px;
      padding: 3px 5px;
    }

  }
  @media only screen and (min-width: 1280px) and (max-width: 1440px) {
    .tp-center-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    
    }
    .tp-travel-back{
    margin-left: 95%;
    }
    .tp-travel-preferences {
      border: 700px;
      height: 520px;
      border-radius: 10px;
    
      padding: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      width: 80%;
      max-width: 1200px;
      margin-top: 170px;
    }
    
    .tp-sections-contents {
      margin-bottom: 20px;
    }
    
    .tp-travel, .vehicle {
      text-align: center;
      margin-bottom: 20px;
    }
    
    .tp-preferences, .tp-vehicle-preferences {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    
    .tp-preference {
      flex: 1 1 300px; /* Adjust the width of each preference item */
    }
    
    .tp-preference label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }
    
    .tp-custom-dropdown,
    .tp-custom-input {
      width: 100%;
      max-width: 300px;
      padding: 10px;
      border-radius: 4px;
      font-size: 14px;
      box-sizing: border-box;
    }
    
    .tp-custom-dropdown {
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    
    .tp-custom-input:disabled {
      background-color: #f9f9f9;
      cursor: not-allowed;
    
    }
    
    .tp-custom-links {
      color: #fff;
      background-color: #05144c;
      text-decoration: none !important;outline: none; /* No outline on focus */
    
      border-radius: 50px;
      padding: 10px 20px;
      text-align: center;
      display: block;
      margin: 20px auto 0;
      /* margin-top: -20px; */
    }
    
    .tp_save-success, .tp_save-error {
      position: fixed;
      top: 140px; /* Adjust top position to clear the navbar */
      right: 20px; /* Adjust right position to align with the right side of the screen */
      background-color: #4CAF50;
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    
    .tp-error-message {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }
  
  }
  