.foot-foot{
  background-color: #112777ea;
  height:250px;
  width:100%;
  margin-top:60px;
  display: flex;
  justify-content: space-between;
  padding: 60px;

}

.foot-icon-img{
width: 100px;
height: 100px;
background-color: white;
border-radius: 50px;
margin-left: 100px;
margin-top: 10px;

}

.foot-foot-section {
  flex: 1;

  margin-left: 150px;
}

.foot-foot-section h4 {
  margin-bottom: 10px;
}

.foot-foot-section h2 {
  margin: 5px 0;
}

.foot-foot-section-1 {
  flex: 1;

  margin-left: 70px;
}

.foot-foot-section-1 h4 {
  margin-bottom: 10px;
}

.foot-foot-section-1 h2 {
  margin: 5px 0;
}
.foot-foot-section-2 {
  flex: 1;

  margin-left: 50px;
}

.foot-foot-section-2 h4 {
  margin-bottom: 10px;
}

.foot-foot-section-2 h2 {
  margin: 5px 0;
}


.foot-language-dropdown {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.foot-top{
width: 200px;
  font-size: 22px;
  text-align: left;
  letter-spacing: normal;
  color:white;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}  


.foot-work{
  font-size: 16px;
  text-align: left;
  letter-spacing: normal;
  color: white;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.foot-social-icons-container {
  display: flex;
  margin-top: 20px;
}

.foot-iicon {
  margin-right: 20px; /* Adjust as needed */
  font-size: 40px; /* Adjust as needed */
  color: white;
}
@media only screen and (min-width: 320px) and (max-width: 359px) {  
  .foot-foot{
    width:100%;
    height:570px;
    display:block;
    margin-top: 20px;

  }
  .foot-foot-section{
      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }

  .foot-foot-section-1{
    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
.foot-foot-section-2{
  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}
  .foot-icon-img{
    width: 100px;
    background-color: white;
    border-radius: 100px;
    margin-left: 45px;
    margin-top: -40px;
    margin-bottom: 15px;
    
  }
 
  .foot-top{
    margin-left: 0px;
    text-align: center;
  }

  .foot-work{
    text-align: center;
  }
  .foot-social-icons-container{
    margin-left: 60px;
  }
  .foot-iicon {
      margin-right: 20px; /* Adjust as needed */
      font-size: 20px; /* Adjust as needed */
      color: white;
      text-align: center;
    }

}
@media only screen and (min-width: 360px) and (max-width: 374px) {  
  .foot-foot{
    width:100%;
    height:570px;
    display:block;
    margin-top: 20px;

  }
  .foot-foot-section{
      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }

  .foot-foot-section-1{
    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
.foot-foot-section-2{
  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}
  .foot-icon-img{
    width: 100px;
    background-color: white;
    border-radius: 100px;
    margin-left: 80px;
    margin-top: -40px;
    margin-bottom: 15px;
    
  }
 
  .foot-top{
    margin-left: 40px;
    text-align: center;
  }

  .foot-work{
    text-align: center;
  }
  .foot-social-icons-container{
    margin-left: 80px;
  }
  .foot-iicon {
      margin-right: 20px; /* Adjust as needed */
      font-size: 20px; /* Adjust as needed */
      color: white;
      text-align: center;
    }

}
@media only screen and (min-width: 375px) and (max-width: 413px) {  
  .foot-foot{
    width:100%;
    height:570px;
    display:block;
    margin-top: 20px;

  }
  .foot-foot-section{
      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }

  .foot-foot-section-1{
    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
.foot-foot-section-2{
  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}
  .foot-icon-img{
    width: 100px;
    background-color: white;
    border-radius: 100px;
    margin-left: 80px;
    margin-top: -40px;
    margin-bottom: 15px;
    
  }
 
  .foot-top{
    margin-left: 40px;
    text-align: center;
  }

  .foot-work{
    text-align: center;
  }
  .foot-social-icons-container{
    margin-left: 80px;
  }
  .foot-iicon {
      margin-right: 20px; /* Adjust as needed */
      font-size: 20px; /* Adjust as needed */
      color: white;
      text-align: center;
    }
}
@media only screen and (min-width: 414px) and (max-width: 427px) {  
  .foot-foot{
    width:100%;
    height:570px;
    display:block;
    margin-top: 20px;

  }
  .foot-foot-section{
      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }

  .foot-foot-section-1{
    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
.foot-foot-section-2{
  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}
  .foot-icon-img{
    width: 100px;
    background-color: white;
    border-radius: 100px;
    margin-left: 90px;
    margin-top: -40px;
    margin-bottom: 15px;
    
  }
 
  .foot-top{
    margin-left: 40px;
    text-align: center;
  }

  .foot-work{
    text-align: center;
  }
  .foot-social-icons-container{
    margin-left: 90px;
  }
  .foot-iicon {
      margin-right: 20px; /* Adjust as needed */
      font-size: 20px; /* Adjust as needed */
      color: white;
      text-align: center;
    }
}
@media only screen and (min-width: 428px) and (max-width: 767px) {  
  .foot-foot{
    width:100%;
    height:570px;
    display:block;
    margin-top: 20px;

  }
  .foot-foot-section{
      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }

  .foot-foot-section-1{
    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
.foot-foot-section-2{
  margin-left: 0px;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}
  .foot-icon-img{
    width: 100px;
    background-color: white;
    border-radius: 100px;
    margin-left: 90px;
    margin-top: -40px;
    margin-bottom: 15px;
    
  }
 
  .foot-top{
    margin-left: 50px;
    text-align: center;
  }

  .foot-work{
    text-align: center;
  }
  .foot-social-icons-container{
    margin-left: 90px;
  }
  .foot-iicon {
      margin-right: 20px; /* Adjust as needed */
      font-size: 20px; /* Adjust as needed */
      color: white;
      text-align: center;
    }
}
@media only screen and (min-width: 768px) and (max-width: 819px) {  
  .foot-foot{
      width:100%;
      height:250px;
    
      margin-top: 20px;
 
    }
    .foot-foot-section{
        margin-left: -10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .foot-foot-section-1{
      margin-left: -10px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .foot-foot-section-2{
    margin-left: -50px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .foot-icon-img{
      width: 150px;
      background-color: white;
      border-radius: 100px;
      margin-left: -30px;
      margin-top: -10px;
      margin-bottom: 15px;
      
    }
    .foot-top{
      margin-left: 20px;
      text-align: center;
    }
    .foot-top-1{
      text-align: center;
    }
    .foot-top-2{
      text-align: center;
      margin-left: 0px;
    }
    .foot-work{
      text-align: center;
    }
    .foot-social-icons-container{
      margin-left: 70px;
    }
    .foot-iicon {
        margin-right: 10px; /* Adjust as needed */
        font-size: 30px; /* Adjust as needed */
        color: white;
        text-align: center;
      }


}
@media only screen and (min-width: 769px) and (max-width: 1024px) {  
  .foot-foot{
      width:100%;
      height:250px;
    
      margin-top: 20px;
 
    }
    .foot-foot-section{
        margin-left: -10px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .foot-foot-section-1{
      margin-left: -10px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }
  .foot-foot-section-2{
    margin-left: -50px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}
    .foot-icon-img{
      width: 150px;
      background-color: white;
      border-radius: 100px;
      margin-left: 0px;
      margin-top: -10px;
      margin-bottom: 15px;
      
    }
    .foot-top{
      margin-left: 20px;
      text-align: center;
    }
    .foot-top-1{
      text-align: center;
    }
    .foot-top-2{
      text-align: center;
      margin-left: 0px;
    }
    .foot-work{
      text-align: center;
    }
    .foot-social-icons-container{
      margin-left: 70px;
    }
    .foot-iicon {
        margin-right: 10px; /* Adjust as needed */
        font-size: 30px; /* Adjust as needed */
        color: white;
        text-align: center;
      }


}

