.lr-help {
  text-align: center;
  font-size: 40px;
  
  margin-top: 20px;
  color:black;
  font-family: 'Times New Roman', Times, serif;
}

.lr-flex-container {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap; 
  justify-content: space-between; 
}


.content-1 {
  width: calc(50% - 100px); 
  height: 400px; 
  padding: 50px;
  margin-bottom: 50px;
  border: 0px solid #bab5b5;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 100px;
  font-size:18px;
  
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.content-2 {
  width: calc(50% - 100px); 
  height: 425px; 
  padding: 50px;
  margin-bottom: 20px;
  border: 0px solid #bab5b5;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 100px;
  margin-top: 0px;
  font-size:18px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.content-3 {
  width: calc(50% - 100px); 
  height: 220px; 
  padding: 50px;
  margin-top: -250px;
  border: 0px solid #bab5b5;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 100px;
  font-size:18px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.lr-more-hr{
  margin-top: 10px;
}
.content-4 {
  width: calc(50% - 100px); 
  height: 250px;
  padding: 50px;
  margin-top: -250px;
  border: 0px solid #bab5b5;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 100px;

  font-size:18px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.lr-howdo-1{
margin-bottom: 20px;
}
.content h4 {
  margin-top: 50;
  font-size:20px;
  font-weight: 500;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.content hr {
  margin: 10px 0;
}
.lr-more-btn-small{
  color:  rgb(7, 52, 125);
}

.lr-expanded {
  max-height: none !important;
  overflow: visible !important;
  font-size:16px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.lr-collapsed {
  max-height: 200px;
  overflow: hidden;
  font-size:16px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

button {
 
  background: none;
  border: none;
  color: rgb(248, 248, 251);
  outline: none;
}



.lr-bts {
  margin-top: 20px;
}

.lr-btt:hover{
  color:rgb(210, 196, 196);
}
@media only screen and (min-width: 240px) and (max-width: 359px) {  
  .lr-flex-container{
    display:block;
   
  }
  .lr-help {
    
    text-align: center;
    font-size: 24px;
    width: 320px;
    margin-left: 0px;
    margin-top: 20px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  .lr-collapsed {
    height:150px;

    width: 280px;
    font-size:16px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .content-1{
  
    width:380px;
    height:395px;
    margin-left: -15px;
    margin-top: -40px;
    
  }
  .content-2{
  
    width:380px;
    height:510px;
    margin-left: -15px;
    margin-top: -180px;
    
  }
  .content-3{

    width:380px;
    height:475px;
    margin-left: -15px;
    margin-top: -300px;
  }
  .content-4{
  
    width:375px;
    height:300px;
    margin-left: -10px;
    margin-top: -220px;
  }


 
  .lr-bt,
  .lr-btt{
    margin-left: 100px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 374px) {  
  .lr-flex-container{
    display:block;
   
  }
  .lr-help {
    
    text-align: center;
    font-size: 25px;
    width: 330px;
    margin-left: 15px;
    margin-top: 20px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  .lr-collapsed {
    width: 315px;
    font-size:16px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .content-1{
  
    width:380px;
    height:375px;
    margin-left: -15px;
    margin-top: -40px;
    
  }
  .content-2{
  
    width:380px;
    height:550px;
    margin-left: -15px;
    margin-top: -180px;
    
  }
  .content-3{

    width:380px;
    height:470px;
    margin-left: -15px;
    margin-top: -300px;
  }
  .content-4{
  
    width:375px;
    height:300px;
    margin-left: -10px;
    margin-top: -220px;
  }


   .lr-bt,
  .lr-btt{
    margin-left: 100px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 383px) {  
  .lr-flex-container{
    display:block;
   
  }
  .lr-help {
    
    text-align: center;
    font-size: 25px;
    width: 330px;
    margin-left: 20px;
    margin-top: 20px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  .lr-collapsed {
    width: 315px;
    font-size:16px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .content-1{
  
    width:380px;
    height:375px;
    margin-left: -10px;
    margin-top: -40px;
    
  }
  .content-2{
  
    width:380px;
    height:550px;
    margin-left: -10px;
    margin-top: -180px;
    
  }
  .content-3{

    width:380px;
    height:470px;
    margin-left: -10px;
    margin-top: -300px;
  }
  .content-4{
  
    width:375px;
    height:300px;
    margin-left: -10px;
    margin-top: -220px;
  }



  .lr-bt,
  .lr-btt{
    margin-left: 100px;
  }
}
@media only screen and (min-width: 390px) and (max-width: 411px) {  
  .lr-flex-container{
    display:block;
   
  }
  .lr-help {
    
    text-align: center;
    font-size: 25px;
    width: 330px;
    margin-left: 15px;
    margin-top: 20px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  .lr-collapsed {
    width: 340px;
    font-size:16px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .content-1{
  
    width:450px;
    height:375px;
    margin-left: -15px;
    margin-top: -40px;
    
  }
  .content-2{
  
    width:450px;
    height:525px;
    margin-left: -15px;
    margin-top: -180px;
    
  }
  .content-3{

    width:450px;
    height:470px;
    margin-left: -15px;
    margin-top: -300px;
  }
  .content-4{
  
    width:450px;
    height:290px;
    margin-left: -10px;
    margin-top: -220px;
  }



  .lr-bt,
  .lr-btt{
    margin-left: 100px;
  }
}
@media only screen and (min-width: 412px) and (max-width: 427px) {  
  .lr-flex-container{
    display:block;
   
  }
  .lr-help {
  
    text-align: center;
    font-size: 25px;
    width: 400px;
    margin-left: 8px;
    margin-top: 20px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  .lr-collapsed {
   
    width: 380px;
    font-size:16px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .content-1{
 
    width:430px;
    height:355px;
    margin-left: -20px;
    margin-top: -40px;
    
  }
  .content-2{
   
    width:430px;
    height:525px;
    margin-left: -15px;
    margin-top: -180px;
    
  }
  .content-3{
   

    width:430px;
    height:450px;
    margin-left: -15px;
    margin-top: -300px;
  }
  .content-4{
  
  
    width:430px;
    height:290px;
    margin-left: -10px;
    margin-top: -220px;
  }


  
  .lr-bt,
  .lr-btt{
    margin-left: 100px;
  }
}
@media only screen and (min-width: 428px) and (max-width: 479px) {  
  .lr-flex-container{
    display:block;
   
  }
  .lr-help {
  
    text-align: center;
    font-size: 25px;
    width: 400px;
    margin-left: 8px;
    margin-top: 20px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  .lr-collapsed {
   
    width: 380px;
    font-size:16px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .content-1{
 
    width:430px;
    height:355px;
    margin-left: -20px;
    margin-top: -40px;
    
  }
  .content-2{
   
    width:430px;
    height:525px;
    margin-left: -15px;
    margin-top: -180px;
    
  }
  .content-3{
   

    width:430px;
    height:450px;
    margin-left: -15px;
    margin-top: -300px;
  }
  .content-4{
  
  
    width:430px;
    height:290px;
    margin-left: -10px;
    margin-top: -220px;
  }



  .lr-bt,
  .lr-btt{
    margin-left: 100px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 799px) {  
  .lr-flex-container{
    display:block;
   
  }
  .lr-help {
    text-align: center;
    font-size: 40px;
    
    margin-top: 40px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  .lr-collapsed {
  max-height: 200px;
  overflow: hidden;
  font-size:16px;
  FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
  .content-1{
    width:700px;
    height:275px;
    margin-left: 75px;
    margin-top: -10px;
  }
  .content-2{
    width:700px;
    height:450px;
    margin-left: 75px;
    margin-top: -180px;
    
  }
  .content-3{
    width:700px;
    height:380px;
    margin-left: 75px;
    margin-top: -300px;
  }
  .content-4{
    width:700px;
    height:200px;
    margin-left: 75px;
    margin-top: -220px;
  }
  
  .lr-bt,
  .lr-btt{
    margin-left: 300px;
  }
 
  }
@media only screen and (min-width: 769px) and (max-width: 883px) {  
.lr-flex-container{
  display:block;
 
}
.lr-help {
  text-align: center;
  font-size: 40px;
  
  margin-top: 40px;
  color:black;
  font-family: 'Times New Roman', Times, serif;
}
.content-1{
  width:700px;
  height:275px;
  margin-left: 75px;
  margin-top: -10px;
}
.content-2{
  width:700px;
  height:450px;
  margin-left: 75px;
  margin-top: -180px;
  
}
.content-3{
  width:700px;
  height:380px;
  margin-left: 75px;
  margin-top: -300px;
}
.content-4{
  width:700px;
  height:200px;
  margin-left: 75px;
  margin-top: -220px;
}

.lr-bt,
.lr-btt{
  margin-left: 300px;
}

}

@media only screen and (min-width: 884px) and (max-width: 1027px) {  
  .lr-flex-container{
    display:block;
   
  }
  .lr-help {
    text-align: center;
    font-size: 40px;
    
    margin-top: 40px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  .content-1{
    width:700px;
    height:275px;
    margin-left: 100px;
    margin-top: -10px;
  }
  .content-2{
    width:700px;
    height:450px;
    margin-left: 100px;
    margin-top: -180px;
    
  }
  .content-3{
    width:700px;
    height:380px;
    margin-left: 100px;
    margin-top: -300px;
  }
  .content-4{
    width:700px;
    height:200px;
    margin-left: 100px;
    margin-top: -220px;
  }
  
  .lr-bt,
  .lr-btt{
    margin-left: 300px;
  }

  }
  @media only screen and (min-width: 1028px) and (max-width: 1440px) {  

  .lr-help {
    text-align: center;
    font-size: 40px;
    
    margin-top: 20px;
    color:black;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .lr-flex-container {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap; 
    justify-content: space-between; 
  }
  
  
  .content-1 {
    width: calc(50% - 100px); 
    height: 410px; 
    padding: 50px;
    margin-bottom: 50px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 100px;
    font-size:18px;
    
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .content-2 {
    width: calc(50% - 100px); 
    height: 435px;
    padding: 50px;
    margin-bottom: 20px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 100px;
    margin-top: 0px;
    font-size:18px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .content-3 {
    width: calc(50% - 100px); 
    height: 280px; 
    padding: 50px;
    margin-top: -230px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 100px;
    font-size:18px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lr-more-hr{
    margin-top: 10px;
  }
  .content-4 {
    width: calc(50% - 100px); 
    height: 300px; 
    padding: 50px;
    margin-top: -230px;
    border: 0px solid #bab5b5;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 100px;
   
    font-size:18px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .lr-howdo-1{
  margin-bottom: 20px;
  }
  .content h4 {
    margin-top: 0;
    font-size:20px;
    font-weight: 500;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  
  }
  
  .content hr {
    margin: 10px 0;
  }
  .lr-more-btn-small{
    color:  rgb(7, 52, 125);
  }
  
  .lr-expanded {
    max-height: none !important;
    overflow: visible !important;
    font-size:16px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  .lr-collapsed {
    max-height: 200px;
    overflow: hidden;
    font-size:16px;
    FONT-FAMILY: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  
  button {
   
    background: none;
    border: none;
    color: rgb(248, 248, 251);
    outline: none;
  }
  
  
  
  .lr-bts {
    margin-top: 20px;
  }

  .lr-btt:hover{
    color:rgb(210, 196, 196);
  }
}
