
 
/* Main container */
.sua-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #eeeeee;
}
.sua-travel-backsss{
  margin-left: 150vh;
  position: relative; /* Ensure the element is positionable */

}
.sup-profile-image{
  width: 60px; /* Adjust width as needed */
  height: 60px; /* Adjust height as needed */
  border-radius: 50%; /* To make it circular */
  background-size: cover;
  margin-top: -60px;
  background-position: center;
  margin-left:200px;
 }
/* Form container */
.sua-form {
  width: 80%; /* Adjust form width */
  max-width: 1000px; /* Limit form width */
  margin-top: 100px;
}
 
/* Form rows */
.sua-form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Space between input groups */
  align-items: center; /* Align items vertically */
  margin-bottom: 40px; /* Increase space between rows */
}
 
/* Form groups */
.sua-form-group {
  flex: 0 0 calc(45% - 10px); /* Two items per row with space for margins */
  margin-bottom: 0px; /* Adjust spacing between groups */
}
 
.sua-form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif;
}
 
/* Inputs */
.sua-form-group input {
  width: 100%; /* Full width */
  padding: 12px;
  border: 1px solid #001A4F;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 10px; /* Adjust spacing */
  font-size: 16px;
}
 
/* Update button */
.button-container {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
}
 
/* Update button */
.sua-buttonn {
  padding: 10px 20px; /* Adjust button padding */
  background-color: #001A4F;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  height: 55px;
  text-decoration: none !important;  width: 200px; /* Set fixed width for buttons */
  font-size: 19px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0 10px; /* Add margin between buttons */
}
 
.sua-button:hover,
.delete-button:hover {
  background-color: #001A4F;
}
 
 
 /* Responsive design for mobile screens */
 @media only screen and (min-width: 320px) and (max-width: 343px) {
  .sua-main {
    height: 10%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: #eeeeee;
    /* Remove fixed height */
  }
  
 
  .sup-profile-image{
    width: 60px; /* Adjust width as needed */
    height: 60px; /* Adjust height as needed */
    border-radius: 50%; /* To make it circular */
    background-size: cover;
    margin-top: -60px;
    background-position: center;
    margin-left:170px;
   }
  .sua-form-row{
      width: 30px;
 
      margin-top: 0px;
  }
 

  .sua-travel-backsss{
    margin-left: 50vh;
   
  }

 
 
  .sua-form-group input {
    width: 250px; /* Input fields take full width */
    padding: 10px; /* Adjust padding for better appearance */
    box-sizing: border-box; /* Include padding in width calculation */
    border: 1px solid #ccc; /* Add border for better visibility */
    border-radius: 5px; /* Add border-radius for rounded corners */
  }
 
 

.delete-buttons{
  
    width: 200px; /* Edit button takes full width */
    padding: 10px; /* Adjust padding for better appearance */
    background-color: #001A4F; /* Dark blue background */
    color: #fff; /* White text color */
    border: none;
    cursor: pointer;
    font-size: 15px;

    transition: background-color 0.3s;
  }
 
 

  .sua-buttonn{
    font-size: 15px;

    margin-bottom: 10px;
    
  }
  .button-container{
    margin-left: 0px;
    margin-bottom: 20px;

  }
}
 @media only screen and (min-width: 360px) and (max-width: 374px) {
  .sua-main {
    height: 10%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: #eeeeee;
    /* Remove fixed height */
  }
  
 
 
  .sua-form-row{
      width: 30px;
 
      margin-top: 0px;
  }
 

  .sua-travel-backsss{
    margin-left: 37vh;
   
  }

 
 
  .sua-form-group input {
    width: 300px; /* Input fields take full width */
    padding: 10px; /* Adjust padding for better appearance */
    box-sizing: border-box; /* Include padding in width calculation */
    border: 1px solid #ccc; /* Add border for better visibility */
    border-radius: 5px; /* Add border-radius for rounded corners */
  }
 
 

.delete-buttons{
  
    width: 200px; /* Edit button takes full width */
    padding: 10px; /* Adjust padding for better appearance */
    background-color: #001A4F; /* Dark blue background */
    color: #fff; /* White text color */
    border: none;
    cursor: pointer;
    font-size: 15px;

    transition: background-color 0.3s;
  }
 
 

  .sua-buttonn{
    font-size: 15px;

    margin-bottom: 10px;
    
  }
  .button-container{
    margin-left: 0px;
    margin-bottom: 20px;

  }
}
 @media only screen and (min-width: 375px) and (max-width: 383px) {
  .sua-main {
    height: 10%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: #eeeeee;
    /* Remove fixed height */
  }
  
 
 
  .sua-form-row{
      width: 30px;
 
      margin-top: 0px;
  }
 

  .sua-travel-backsss{
    margin-left: 40vh;
   
  }

 
 
  .sua-form-group input {
    width: 300px; /* Input fields take full width */
    padding: 10px; /* Adjust padding for better appearance */
    box-sizing: border-box; /* Include padding in width calculation */
    border: 1px solid #ccc; /* Add border for better visibility */
    border-radius: 5px; /* Add border-radius for rounded corners */
  }
 
 

.delete-buttons{
  
    width: 200px; /* Edit button takes full width */
    padding: 10px; /* Adjust padding for better appearance */
    background-color: #001A4F; /* Dark blue background */
    color: #fff; /* White text color */
    border: none;
    cursor: pointer;
    font-size: 15px;

    transition: background-color 0.3s;
  }
 
 

  .sua-buttonn{
    font-size: 15px;

    margin-bottom: 10px;
    
  }
  .button-container{
    margin-left: 0px;
    margin-bottom: 20px;

  }
}
@media only screen and (min-width: 384px) and (max-width: 411px) {
  .sua-main {
    height: 10%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: #eeeeee;
    /* Remove fixed height */
  }
  
 
 
  .sua-form-row{
      width: 30px;
 
      margin-top: 0px;
  }
 

  .sua-travel-backsss{
    margin-left: 40vh;
   
  }

 
 
  .sua-form-group input {
    width: 300px; /* Input fields take full width */
    padding: 10px; /* Adjust padding for better appearance */
    box-sizing: border-box; /* Include padding in width calculation */
    border: 1px solid #ccc; /* Add border for better visibility */
    border-radius: 5px; /* Add border-radius for rounded corners */
  }
 
 

.delete-buttons{
  
    width: 200px; /* Edit button takes full width */
    padding: 10px; /* Adjust padding for better appearance */
    background-color: #001A4F; /* Dark blue background */
    color: #fff; /* White text color */
    border: none;
    cursor: pointer;
    font-size: 15px;

    transition: background-color 0.3s;
  }
 
 

  .sua-buttonn{
    font-size: 15px;

    margin-bottom: 10px;
    
  }
  .button-container{
    margin-left: 0px;
    margin-bottom: 20px;

  }
}
 @media only screen and (min-width: 412px) and (max-width: 413px) {
  .sua-main {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: #eeeeee;
    /* Remove fixed height */
  }
  .sua-travel-backsss {
    margin-left: 37vh;
  }
 
  .sua-form-row{
      width: 300px;
 
      margin-top: 0px;
  }
 
 
  .sua-image {
    width: 120px;
    height: 120px;
  }
 
 
 
 
  .sua-form-group input {
    width: 300px; /* Input fields take full width */
    padding: 10px; /* Adjust padding for better appearance */
    box-sizing: border-box; /* Include padding in width calculation */
    border: 1px solid #ccc; /* Add border for better visibility */
    border-radius: 5px; /* Add border-radius for rounded corners */
  }
 
 

.delete-button{
  
    width: 200px; /* Edit button takes full width */
    padding: 10px; /* Adjust padding for better appearance */
    background-color: #001A4F; /* Dark blue background */
    color: #fff; /* White text color */
    border: none;
    border-radius: 5px; /* Add border-radius for rounded corners */
    cursor: pointer;
    font-size: 16px;
 
    transition: background-color 0.3s;
  }
 
  .sua-button:hover,
  .delete-button:hover {
    background-color: #7cb2c0; /* Light blue on hover */
  }

  .sua-button{
    margin-bottom: 10px;
    
  }
  .button-container{
    margin-left: 50px;
    margin-bottom: 20px;

  }
}
@media only screen and (min-width: 414px) and (max-width: 427px) {
  .sua-main {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: #eeeeee;
    /* Remove fixed height */
  }
  .sua-travel-backsss {
    margin-left: 40vh;
  }
 
  .sua-form-row{
      width: 300px;
 
      margin-top: 0px;
  }
 
 
  .sua-image {
    width: 120px;
    height: 120px;
  }
 
 
 
 
  .sua-form-group input {
    width: 300px; /* Input fields take full width */
    padding: 10px; /* Adjust padding for better appearance */
    box-sizing: border-box; /* Include padding in width calculation */
    border: 1px solid #ccc; /* Add border for better visibility */
    border-radius: 5px; /* Add border-radius for rounded corners */
  }
 
 

.delete-button{
  
    width: 200px; /* Edit button takes full width */
    padding: 10px; /* Adjust padding for better appearance */
    background-color: #001A4F; /* Dark blue background */
    color: #fff; /* White text color */
    border: none;
    border-radius: 5px; /* Add border-radius for rounded corners */
    cursor: pointer;
    font-size: 16px;
 
    transition: background-color 0.3s;
  }
 
  .sua-button:hover,
  .delete-button:hover {
    background-color: #7cb2c0; /* Light blue on hover */
  }

  .sua-button{
    margin-bottom: 10px;
    
  }
  .button-container{
    margin-left: 50px;
    margin-bottom: 20px;

  }
}

@media only screen and (min-width: 428px) and (max-width: 767px) {
  .sua-main {
    height: auto; /* Adjusted height */
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: #eeeeee;
  }

  .sua-form-row {
    width: 30px;
    margin-top: 0px;
  }

  .sua-travel-backsss {
    margin-left: 40vh;
  }

  .sua-form-group input {
    width: 300px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .button-container {
    margin-left: 0px;
    margin-bottom: 20px;
  }
}



@media only screen and (min-width: 768px) and (max-width: 799px) {
  .sua-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eeeeee;
  }
  .sua-buttonn{
    margin-bottom: 50px;
    
  }
  .button-container{
    margin-left: 20px;
  }
  .sup-profile-image{
    width: 60px; /* Adjust width as needed */
    height: 60px; /* Adjust height as needed */
    border-radius: 50%; /* To make it circular */
    background-size: cover;
    margin-top: -60px;
    background-position: center;
    margin-left:180px;
   }
 
  .sua-travel-backsss{
    margin-left: 65vh;
    top: -30px; /* Add top position */

    position: relative; /* Ensure the element is positionable */

  }
}
@media only screen and (min-width: 800px) and (max-width: 819px) {
  .sua-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eeeeee;
  }
  .sua-buttonn{
    margin-bottom: 400px;
    
  }
  .button-container{
    margin-left: 20px;
  }
 
  .sua-travel-backsss{
    margin-left: 53vh;
    top: -0px; /* Add top position */

    position: relative; /* Ensure the element is positionable */

  }
}
@media only screen and (min-width: 820px) and (max-width: 852px) {
  .sua-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eeeeee;
  }
  .sua-buttonn{
    margin-bottom: 300px;
    
  }
  .button-container{
    margin-left: 20px;
  }
 
  .sua-travel-backsss{
    margin-left: 60vh;
    top: -20px; /* Add top position */

    position: relative; /* Ensure the element is positionable */

  }
}
@media only screen and (min-width: 884px) and (max-width: 1279px) {
  .sua-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eeeeee;
  }
  .sua-buttonn{
    margin-bottom: 50px;
    
  }
  .button-container{
    margin-left: 20px;
  }
 
  .sua-travel-backsss{
    margin-left: 70vh;
    top: -50px; /* Add top position */

    position: relative; /* Ensure the element is positionable */

  }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {  


 
/* Main container */
.sua-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #eeeeee;
}
.sua-travel-backsss{
  margin-left: 140vh;
  position: relative; /* Ensure the element is positionable */

}
.sup-profile-image{
  width: 60px; /* Adjust width as needed */
  height: 60px; /* Adjust height as needed */
  border-radius: 50%; /* To make it circular */
  background-size: cover;
  margin-top: -60px;
  background-position: center;
  margin-left:200px;
 }
/* Form container */
.sua-form {
  width: 80%; /* Adjust form width */
  max-width: 1000px; /* Limit form width */
  margin-top: 100px;
}
 
/* Form rows */
.sua-form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Space between input groups */
  align-items: center; /* Align items vertically */
  margin-bottom: 40px; /* Increase space between rows */
}
 
/* Form groups */
.sua-form-group {
  flex: 0 0 calc(45% - 10px); /* Two items per row with space for margins */
  margin-bottom: 0px; /* Adjust spacing between groups */
}
 
.sua-form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif;
}
 
/* Inputs */
.sua-form-group input {
  width: 100%; /* Full width */
  padding: 12px;
  border: 1px solid #001A4F;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 10px; /* Adjust spacing */
  font-size: 16px;
}
 
/* Update button */
.button-container {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
}
 
/* Update button */
.sua-buttonn,
.delete-buttons {
  padding: 10px 20px; /* Adjust button padding */
  background-color: #001A4F;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  height: 55px;
  text-decoration: none !important;  width: 200px; /* Set fixed width for buttons */
  font-size: 19px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0 10px; /* Add margin between buttons */
}
 
.sua-button:hover,
.delete-button:hover {
  background-color: #001A4F;
}
 
}
 