.home-page{
  width: 100%;
 }

.lg-hero-1{
  /* background-color: rgb(213, 86, 86); */
 
  display: flex;
  justify-content: center;  
      padding: 400px;    
      background-image: url('../Images/blablacar.jpg');
      background-repeat: no-repeat;
      color: black;
      
      background-position: 200px;
      background-size: cover; 
      background-size: auto 700px, cover;
      background-position:  center right 50px; 
 
}
.lg-hero-car-1{
  width: 100%;
  font-size: 30px;
   margin-top: -280px;
 
   text-align: center;
  
  }

.lg-comes-lion-2{
  width:150%;
  margin-left: -0px;
    font-size: 25px;
   margin-top:-0px;
   
  }

 
   /*code*/
   

   .lg-card {
    position: relative;
    width: 420px;
    height: 180px;
    background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 40px;
    margin-top: 0px;
 
   
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
 
  .lg-card svg {
    width: 100px;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
 
  .lg-card:hover {
    transform: rotate(-5deg) scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .lg-card__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  .ab-about {
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    text-align: center;
  }
  .lg-card:hover .lg-card__content {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 1;
  }
  .lg-card-container{
    display: flex;
    gap:3rem;
    margin-top:-30px;
    margin-left: 6%;
  }
  .lg-card__title {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-weight: 700;
  }
 
  .lg-card__description {
    margin: 10px 0 0;
    font-size: 14px;
    color: #777;
    line-height: 1.4;
  }
 
  .lg-card:hover svg {
    scale: 0;
    transform: rotate(-45deg);
  }
 
  .lg-card-lgicon {
    font-size: 5.5rem; /* Adjust the size as needed */
  }



   
  p {
    text-align: left; /* Align the text to the left */
    text-indent: 0; /* Remove any indentation */
  }
   
   
  .footer {
    padding: 1rem;
    text-align: center;
    background-color: #f0f0f0;
  }


  .lg-cta-our{
    border: none ;
    border-radius: 10px;
    height: 360px;
    background-color: #ededed; 
    border-radius: 50px;
    margin-bottom: 200px;
  }
.lg-our-img{
  width: 300px;
  margin-left: 200px;
  border-radius: 50px;
  margin-top: 20px;
  animation: moveRight 2s ease-in-out infinite alternate;

}

@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}




  .lg-paragraph-our{
    width: calc(50% - 20px); /* Adjust width of each paragraph */
    margin-left: 50px; /* Adjust spacing between paragraphs */
  }
  .lg-our-1{
    width: 65%;
   margin-top: -280px;
   font-size: 20px;
   margin-left: 750px;
   text-align: center ;
   margin-bottom: -80px;
   
  }
   
  .lg-our-2{
  
    margin-left: 100px;
    font-size: 25px;
    margin-bottom: 20px;
  }
  
   
  .lg-our-3 {
    margin-right:-150px;
  }


  
 
   
  .lg-text-center {
    text-align: center;
  }
   
  .lg-button-container {
    display: inline-block;
    margin-left: 350px;
  }
   
   
   
  .lg-learn-more-btn-1 {
    width: 25%;
    display: inline-block;
    background-color: rgb(7, 52, 125); /* Set background color to black */
    color: white; /* Set text color to red */
    padding: 10px 20px;
    border-radius: 50px;
    text-decoration: none;
    margin-top: 100px; /* Adjust margin as needed */
    margin-left: 620px;
  
  }
   /* .lg-paragraph-drive{
    margin-right: 20px;
   } */
   
  .lg-paragraph-car{
    width: calc(50% - 20px); /* Adjust width of each paragraph */
    margin-right: 20px; /* Adjust spacing between paragraphs */
  }

 

  .lg-car-img{
    width: 350px;
    margin-left: 1000px;
    border-radius: 50px;
    margin-top: 0px;
  overflow-x: hidden;
  animation: moveRight 2s ease-in-out infinite alternate;
  }
  @keyframes moveRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50px); /* Adjust this value as needed for the desired distance */
    }
}
  .lg-car-pn-pm{
    margin-top: -150px;
    margin-bottom: 40px;
    border: 1px none;
  }
  .lg-drive-1{
    width: 5%;
   margin-top: -350px;
   font-size: 20px;
   margin-left: 750px;
   text-align: center ;
   margin-bottom: -80px;
  
   
  }
  .lg-paragraph-1{
    width: 600px;
    margin-top: 100px;
    font-size: 25px;
    margin-left: -600px;
    text-align: center ;
    margin-bottom: 20px;
  }
  .lg-paragraph-2{
    margin-left: -580px;
    font-size: 20px;
    margin-bottom: 10px;
   
 
     
   }
  

  .lg-paragraph-car{
    margin-top: 100px;
    margin-right: 750px;
    
  }
   
  
   
   
  .lg-more-btn-2 {
    display: inline-block;
    background-color: rgb(7, 52, 125); /* Set background color to black */
    color: white; /* Set text color to red */
    padding: 10px 20px;
    border-radius: 50px;
    text-decoration: none;
    margin-top: 100px; /* Adjust margin as needed */
   margin-left: 380px;
  }
  
   
   
  .paragraph {
    text-align: center; /* Center-align the paragraph text */
  }
   
  .lg-want-ps {
    display: flex; /* Use Flexbox to allow side-by-side layout */
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center items horizontally */
    padding: 20px; /* Add padding */
    background-color:  #112777ea; /* Set background color to blue */
    background-repeat: no-repeat; /* Ensure the image is not repeated */
    background-position: right; /* Position the image on the right side */
    border-radius: 10px;

    color: white; /* Set text color to white for contrast */
  }
   
  .lg-destination-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Adjust spacing between boxes */
  }
   

  
  .lg-box-1 {
    display: flex;
    align-items: center;
    background-color: white; /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 20px;
  }
  .lg-box-2 {
    display: flex;
    align-items: center;
    background-color: white; /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 15px;
  }
  .lg-box-3 {
    display: flex;
    align-items: center;
    background-color: white; /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 10px;
  }

  .lg-box-4 {
    display: flex;
    align-items: center;
    background-color: white; /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 20px;
    display: block;
  }
  .lg-box-5 {
    display: flex;
    align-items: center;
    background-color: white; /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 15px;
    display: block;
  }
  .lg-box-6 {
    display: flex;
    align-items: center;
    background-color: white; /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 10px;
    display: block;
  }
  .lg-box-7 {
    display: flex;
    align-items: center;
    background-color: white; /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 20px;
    display: block;
  }
  .lg-box-8 {
    display: flex;
    align-items: center;
    background-color: white; /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 15px;
    display: block;
  }
  .lg-box-9 {
    display: flex;
    align-items: center;
    background-color: white; /* Set background color to blue */
    padding: 20px; /* Increase padding for bigger box */
    border-radius: 8px; /* Increase border-radius for rounded corners */
    margin-right: 10px;
    display: block;
 
    
  }
  
   
  .lg-destination {
    color: black;
    margin: 0 15px; /* Adjust spacing between destinations and arrows */
   
  }
   
  .lg-arrow {
    color: black;
    font-size: 20px; /* Increase arrow size */
  }
   
  .lg-end-arrow {
    color: black;
    margin-left: auto; /* Push the ">" symbol to the right */
    font-size: 20px; /* Increase ">" symbol size */
  }
   
  .lg-pn-pm-want{
    margin-bottom: 200px;
    margin-right: 25px;
    
    
  }
  .lg-pn-pm-See{
    margin-top: 00px;
    color: rgb(27, 106, 156);
     margin-left: 80%; 
    
  }
    
  @media only screen and (min-width: 240px) and (max-width: 319px) {  
    .navbar {
    padding-top: 80px; 
  }

  .lg-hero-1{
    overflow: hidden;
    display: flex;
    justify-content: center;  
        padding: 440px;    
        background-repeat: no-repeat;
        color: black;
        background-size: cover; 
        background-size: auto 400px, cover;
        background-position:  center left 0px; 
        background-position:  center top 10px; 

margin-top: 80px;

   
  }
  
  .lg-hero-car-1{
    overflow: hidden;
    width: 200px;
    font-size: 35px;
     margin-top: -425px;
     margin-left: 290px;

     text-align: center;
    
    }
  
  .lg-comes-lion-2{

    width:750px;
    margin-left: 10px;
      font-size: 30px;
     margin-top: -5px;
     
    }
  
    .lg-card-container{
     height: 930px;
width: 850px;
      display:block;
      margin-top: -500px;
      margin-left: 9%;
    }
    
    .lg-card {
      overflow: hidden;
      width: 320px;
      height: 200px;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
  .lg-card__description {
    margin: 10px 0 0;
    font-size: 30px;
    color: #515151;
    line-height: 1.4;
  }
    
    .lg-card{
      overflow: hidden;
    margin-top: 30px;
    }
    .lg-card__title {
      overflow: hidden;
      width: 650px;
      margin: 0;
      font-size: 25px;
      color: #333;
    }
    
    .lg-card {
      overflow: hidden;
      position: relative;
      width: 700px;
      height: 250px;
      background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-bottom: 50px;
      margin-top: 40px;
      margin-left: 8%;

      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
    .lg-card-lgicon {
      
      font-size: 4.5rem; /* Adjust the size as needed */
    }
  
.lg-paragraph-our{

  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}




.lg-cta-our{

  overflow-x: hidden;
  width: 850px;
  border: none ;
  border-radius: 10px;
  height: 830px;
  border-radius: 30px;
  margin-left: 20px;
  margin-top: 20px;
}
.lg-our-img{

width: 320px;
margin-left: 230px;
border-radius: 50px;
margin-top: 20px;
margin-bottom: 10px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.lg-our-1{
width: 285px;
 margin-top: 10px;

 margin-left: 15px;
 text-align: center ;
 
}


 
.lg-our-2{

  width: 370px;

  margin-top: 0px;
  margin-left: 200px;
  text-align: center;
  font-size: 35px;
  margin-bottom: 10px;
}


.lg-our-3{
  margin-left: 10px;

  width: 785px;
 padding-right: 2px;
  margin-bottom: 0px;
  font-size: 30px;
  
}
 
.lg-learn-more-btn-1 {
  padding: 10px 20px; 
  margin-left: -50px;
  width: 220px;
  font-size: 30px;
  margin-top: 100px;

}


p {
  overflow-x: hidden;

  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{
  width: 850px;
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.lg-drive-1{

 font-size: 20px;
 margin-left: -600px;
margin-top: -550px;


 
}
.lg-more-btn-2{
  padding: 10px 20px; 
  width: 230px;
  font-size: 30px;
  margin-top: 100px;
  margin-left: -250px;
}
.lg-car-img{
  width: 320px;
  margin-left: 705px;
  border-radius: 50px;
  margin-top: -430px;


}

.lg-paragraph-1{
  width: 450px;

 margin-top: 780px;
  font-size: 35px;
  margin-left: 250px;
  text-align: center ;
 
}
.lg-paragraph-2{
  width: 785px;

  font-size:30px;

  margin-left: 100px;

   
 }
 .lg-paragraph-car {

  width: 0%; /* Make paragraphs full width */
   margin-top: 00px;
}


.lg-want-ps {
  overflow-x: hidden;
  width: 850px;
  display: block;
  margin-left: 20px;
  border-radius: 10px;
  margin-top: -20px;
  
}
.lg-pn-pm-want{
  margin-left: -10px;
  font-size: 40px; /* Increase ">" symbol size */

  margin-bottom: 30px;
  width: 500px;
}


  .lg-box-1,
  .lg-box-2,
  .lg-box-3 {
    overflow-x: hidden;

    margin-right: 0; /* Remove right margin */
  }
  .lg-destination {
    overflow-x: hidden;
    color: black;
    margin: 0 1px; /* Adjust spacing between destinations and arrows */
    font-size: 30px; /* Increase ">" symbol size */

  }
   
  .lg-arrow {
    overflow-x: hidden;

    color: black;
    font-size: 30px; /* Increase arrow size */
  }
  .lg-end-arrow {
    overflow-x: hidden;
    color: black;
    margin-left: 0px; /* Push the ">" symbol to the right */
    font-size: 30px; /* Increase ">" symbol size */
  }
 
.lg-pn-pm-See{
  overflow-x: hidden;
  font-size: 30px; /* Increase ">" symbol size */

  width: 400px;
  margin-left: 400px;

}

}
  @media only screen and (min-width: 320px) and (max-width: 343px) {  
    .navbar {
    padding-top: 80px; 
  }

  .lg-hero-1{
    display: flex;
    justify-content: center;  
        padding: 90px;    
    width: 310px;
        background-repeat: no-repeat;
        color: black;
        background-size: cover; 
        background-size: auto 150px, cover;
        background-position:  center left 0px; 
        background-position:  center top 10px; 

margin-top: 80px;

   
  }
  
  .lg-hero-car-1{
    width: 100px;
    font-size: 15px;
     margin-top: -85px;
     margin-left: 100px;

     text-align: center;
    
    }
  
  .lg-comes-lion-2{
    width:300px;

    margin-left: 10px;
      font-size: 12px;
     margin-top: -5px;
     
    }
  
    .lg-card-container{
width: 265px;
      display:block;
      margin-top: -30px;
      margin-left: 9%;
    }
    
    .lg-card {
      width: 320px;
      height: 200px;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
    
    .lg-card{
    margin-top: 30px;
    }
    .lg-card__title {

      width: 230px;
      margin: 0;
      font-size: 20px;
      color: #333;
    }
    
    .lg-card {
      position: relative;
      width: 260px;
      height: 200px;
      background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-bottom: 10px;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
    .lg-card-lgicon {
      font-size: 4.5rem; /* Adjust the size as needed */
    }
  
.lg-paragraph-our{

  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}




.lg-cta-our{
  overflow-x: hidden;
  width: 300px;
  border: none ;
  border-radius: 10px;
  height: 580px;
  border-radius: 30px;
  margin-left: 10px;
  margin-top: 20px;
}
.lg-our-img{

width: 220px;
margin-left: 15px;
border-radius: 50px;
margin-top: 10px;
margin-bottom: 10px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.lg-our-1{
width: 285px;
 margin-top: 10px;

 margin-left: 15px;
 text-align: center ;
 
}


 
.lg-our-2{

  width: 220px;
  margin-top: 0px;
  margin-left: 30px;
  font-size: 20px;
  margin-bottom: 10px;
}


.lg-our-3{

  width: 285px;
 padding-right: 2px;
  margin-bottom: 0px;
  font-size: 16px;
  
}
 
.lg-learn-more-btn-1 {
  overflow-x: hidden;
  padding: 8px 16px; 
  margin-left: -400px;
  width: 120px;
  margin-top: 80px;

}


p {
  overflow-x: hidden;

  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{
  /* overflow-x: hidden; */

  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.lg-drive-1{


  font-size: 20px;
  margin-left: 495px;
  margin-top: -550px;
  
  
  
  }
  .lg-more-btn-2{
    width: 150px;
    padding: 10px 20px; 
    margin-left: 825px;
    margin-top: 100px;
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }
  .lg-car-img{
  width: 220px;
  margin-left: 770px;
  border-radius: 50px;
  margin-top: -30px;
  
  
  }
  
  .lg-paragraph-1{
  width: 300px;
  margin-top: 560px;
  font-size: 20px;
  margin-left: 260px;
  text-align: center ;
  
  }
.lg-paragraph-2{
width: 290px;

font-size: 17px;

margin-left: 270px;

 
}

/* .lg-drive-1{

 font-size: 20px;
 margin-left: -600px;
margin-top: -550px;


 
}
.lg-more-btn-2{
  margin-left: -150px;
}
.lg-car-img{
  width: 220px;
  margin-left: 705px;
  border-radius: 50px;
  margin-top: -360px;


}

.lg-paragraph-1{
  width: 300px;

 margin-top: 720px;
  font-size: 22px;
  margin-left: 360px;
  text-align: center ;
 
}
.lg-paragraph-2{
  width: 290px;

  font-size: 17px;

  margin-left: 365px;

   
 } */
 .lg-paragraph-car {

  width: 0%; /* Make paragraphs full width */
   margin-top: 00px;
}


.lg-want-ps {
  overflow-x: hidden;
  width: 95%;
  display: block;
  margin-left: 7px;
  border-radius: 10px;
  margin-top: -20px;
  
}
.lg-pn-pm-want{
  margin-left: -10px;

  margin-bottom: 30px;
  width: 290px;
}


  .lg-box-1,
  .lg-box-2,
  .lg-box-3 {
    overflow-x: hidden;

    margin-right: 0; /* Remove right margin */
  }
  .lg-destination {
    overflow-x: hidden;
    color: black;
    margin: 0 1px; /* Adjust spacing between destinations and arrows */

  }
   
  .lg-arrow {
    overflow-x: hidden;

    color: black;
    font-size: 20px; /* Increase arrow size */
  }
  .lg-end-arrow {
    overflow-x: hidden;
    color: black;
    margin-left: 0px; /* Push the ">" symbol to the right */
    font-size: 20px; /* Increase ">" symbol size */
  }
 
.lg-pn-pm-See{
  overflow-x: hidden;
  
  width: 200px;
  margin-left: 100px;

}

}
@media only screen and (min-width: 344px) and (max-width: 352px) {  
  .navbar {
  padding-top: 80px; 
}

.lg-hero-1{
  display: flex;
  justify-content: center;  
      padding: 90px;    
  width: 310px;
      background-repeat: no-repeat;
      color: black;
      background-size: cover; 
      background-size: auto 160px, cover;
      background-position:  center left 0px; 
      background-position:  center top 10px; 
margin-left: 15px;
margin-top: 80px;

 
}

.lg-hero-car-1{
  width: 100px;
  font-size: 15px;
   margin-top: -88px;
   margin-left: 110px;

   text-align: center;
  
  }

.lg-comes-lion-2{
  width:300px;

  margin-left: 10px;
    font-size: 12px;
   margin-top: -5px;
   
  }

  .lg-card-container{
width: 265px;
    display:block;
    margin-top: -30px;
    margin-left: 9%;
  }
  
  .lg-card {
    width: 320px;
    height: 200px;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  
  .lg-card{
  margin-top: 30px;
  }
  .lg-card__title {

    width: 230px;
    margin: 0;
    font-size: 20px;
    color: #333;
  }
  
  .lg-card {
    position: relative;
    width: 280px;
    height: 200px;
    background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 20px;
    margin-top: 15%;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .lg-card-lgicon {
    font-size: 4.5rem; /* Adjust the size as needed */
  }

.lg-paragraph-our{

width: calc(50% - 20px); /* Adjust width of each paragraph */
margin-left: 0px; /* Adjust spacing between paragraphs */
}




.lg-cta-our{
overflow-x: hidden;
width: 325px;
border: none ;
border-radius: 10px;
height: 560px;
border-radius: 30px;
margin-left: 10px;
margin-top: 30px;
}
.lg-our-img{

width: 220px;
margin-left: 25px;
border-radius: 50px;
margin-top: 10px;
margin-bottom: 10px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(50px); /* Adjust this value as needed for the desired distance */
}
}

.lg-our-1{
width: 285px;
margin-top: 10px;

margin-left: 15px;
text-align: center ;

}



.lg-our-2{

width: 220px;
margin-top: 0px;
margin-left: 40px;
font-size: 20px;
margin-bottom: 10px;
}


.lg-our-3{

width: 300px;
padding-right: 2px;
margin-bottom: 0px;
font-size: 16px;

}

.lg-learn-more-btn-1 {
overflow-x: hidden;
padding: 8px 16px; 
margin-left: -400px;
width: 120px;
margin-top: 90px;

}


p {
overflow-x: hidden;

text-align: left; /* Align the text to the left */
text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{
overflow-x: hidden;

border: 1px none;
display: flex;
align-items: center;
justify-content: center;
padding: 0px;
}


.lg-drive-1{

font-size: 20px;
margin-left: -600px;
margin-top: -550px;



}
.lg-more-btn-2{
  margin-top: 70px;
margin-left: -150px;
}
.lg-car-img{
width: 220px;
margin-left: 705px;
border-radius: 50px;
margin-top: -340px;


}

.lg-paragraph-1{
width: 300px;

margin-top: 720px;
font-size: 22px;
margin-left: 360px;
text-align: center ;

}
.lg-paragraph-2{
width: 330px;

font-size: 17px;

margin-left: 355px;

 
}
.lg-paragraph-car {

width: 0%; /* Make paragraphs full width */
 margin-top: 00px;
}


.lg-want-ps {
overflow-x: hidden;
width: 95%;
display: block;
margin-left: 7px;
border-radius: 10px;
margin-top: -20px;

}
.lg-pn-pm-want{
margin-left: -10px;

margin-bottom: 30px;
width: 290px;
}


.lg-box-1,
.lg-box-2,
.lg-box-3 {
  overflow-x: hidden;

  margin-right: 0; /* Remove right margin */
}
.lg-destination {
  overflow-x: hidden;
  color: black;
  margin: 0 1px; /* Adjust spacing between destinations and arrows */

}
 
.lg-arrow {
  overflow-x: hidden;

  color: black;
  font-size: 20px; /* Increase arrow size */
}
.lg-end-arrow {
  overflow-x: hidden;
  color: black;
  margin-left: 0px; /* Push the ">" symbol to the right */
  font-size: 20px; /* Increase ">" symbol size */
}

.lg-pn-pm-See{
overflow-x: hidden;

width: 200px;
margin-left: 100px;

}

}
@media only screen and (min-width: 353px) and (max-width: 359px) {  
  .navbar {
  padding-top: 80px; 
}

.lg-hero-1{
  display: flex;
  justify-content: center;  
      padding: 100px;    
  width: 350px;
      background-repeat: no-repeat;
      color: black;
      background-size: cover; 
      background-size: auto 160px, cover;
      background-position:  center right -10px; 
      background-position:  center top 10px; 

margin-top: 80px;

 
}

.lg-hero-car-1{
  width: 100px;
  font-size: 15px;
   margin-top: -95px;
   margin-left: 100px;

   text-align: center;
  
  }

.lg-comes-lion-2{
  width:300px;

  margin-left: 10px;
    font-size: 12px;
   margin-top: -5px;
   
  }

  .lg-card-container{
width: 265px;
    display:block;
    margin-top: -30px;
    margin-left: 9%;
  }
  
  .lg-card {
    width: 320px;
    height: 200px;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  
  .lg-card{
  margin-top: 30px;
  }
  .lg-card__title {

    width: 230px;
    margin: 0;
    font-size: 20px;
    color: #333;
  }
  
  .lg-card {
    position: relative;
    width: 290px;
    height: 200px;
    background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 10px;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .lg-card-lgicon {
    font-size: 4.5rem; /* Adjust the size as needed */
  }

.lg-paragraph-our{

width: calc(50% - 20px); /* Adjust width of each paragraph */
margin-left: 0px; /* Adjust spacing between paragraphs */
}




.lg-cta-our{
overflow-x: hidden;
width: 330px;
border: none ;
border-radius: 10px;
height: 570px;
border-radius: 30px;
margin-left: 10px;
margin-top: 20px;
}
.lg-our-img{

width: 225px;
margin-left: 25px;
border-radius: 50px;
margin-top: 10px;
margin-bottom: 10px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(50px); /* Adjust this value as needed for the desired distance */
}
}

.lg-our-1{
width: 285px;
margin-top: 10px;

margin-left: 15px;
text-align: center ;

}



.lg-our-2{

width: 220px;
margin-top: 0px;
margin-left: 40px;
font-size: 20px;
margin-bottom: 10px;
}


.lg-our-3{

width: 295px;
padding-right: 2px;
margin-bottom: 0px;
font-size: 16px;

}

.lg-learn-more-btn-1 {
overflow-x: hidden;
padding: 8px 16px; 
margin-left: -400px;
width: 120px;
margin-top: 90px;

}


p {
overflow-x: hidden;

text-align: left; /* Align the text to the left */
text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{
overflow-x: hidden;

border: 1px none;
display: flex;
align-items: center;
justify-content: center;
padding: 0px;
}


.lg-drive-1{

font-size: 20px;
margin-left: -600px;
margin-top: -550px;



}
.lg-more-btn-2{
margin-left: -150px;
}
.lg-car-img{
width: 220px;
margin-left: 705px;
border-radius: 50px;
margin-top: -360px;


}

.lg-paragraph-1{
width: 300px;

margin-top: 720px;
font-size: 22px;
margin-left: 360px;
text-align: center ;

}
.lg-paragraph-2{
width: 290px;

font-size: 17px;

margin-left: 365px;

 
}
.lg-paragraph-car {

width: 0%; /* Make paragraphs full width */
 margin-top: 00px;
}


.lg-want-ps {
overflow-x: hidden;
width: 95%;
display: block;
margin-left: 7px;
border-radius: 10px;
margin-top: -20px;

}
.lg-pn-pm-want{
margin-left: -10px;

margin-bottom: 30px;
width: 290px;
}


.lg-box-1,
.lg-box-2,
.lg-box-3 {
  overflow-x: hidden;

  margin-right: 0; /* Remove right margin */
}
.lg-destination {
  overflow-x: hidden;
  color: black;
  margin: 0 1px; /* Adjust spacing between destinations and arrows */

}
 
.lg-arrow {
  overflow-x: hidden;

  color: black;
  font-size: 20px; /* Increase arrow size */
}
.lg-end-arrow {
  overflow-x: hidden;
  color: black;
  margin-left: 0px; /* Push the ">" symbol to the right */
  font-size: 20px; /* Increase ">" symbol size */
}

.lg-pn-pm-See{
overflow-x: hidden;

width: 200px;
margin-left: 100px;

}

}

@media only screen and (min-width: 360px) and (max-width: 374px) {  
  .navbar {
  padding-top: 80px; 
}
.home-page{
  overflow: hidden;

}

.lg-hero-1{
  display: flex;
  justify-content: center;  
      padding: 90px;    
  width: 350px;
      background-repeat: no-repeat;
      color: black;
      background-size: cover; 
      background-size: auto 150px, cover;
      background-position:  center left 0px; 
      background-position:  center top 10px; 

margin-top: 80px;

 
}

.lg-hero-car-1{

  width: 100px;
  font-size: 15px;
   margin-top: -85px;
   margin-left: 120px;

   text-align: center;
  
  }

.lg-comes-lion-2{

  width:320px;
 
        overflow-x: hidden;
  margin-left: 10px;
    font-size: 13px;
   margin-top: -8px;
   
  }

  .lg-card-container{
width: 345px;
    display:block;
    margin-top: -40px;
    margin-left: 2%;
  }
  
  .lg-card {
    height: 200px;
    margin-left: 6.5%;

    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  
  .lg-card{
  margin-top: 40px;
  }
  .lg-card__title {


    width: 250px;
    margin: 0;
    font-size: 20px;
    color: #333;
  }
  
  .lg-card {
  
    position: relative;
    width: 300px;
    height: 200px;
    background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 10px;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .lg-card-lgicon {
   
    font-size: 4.5rem; /* Adjust the size as needed */
  }

.lg-paragraph-our{

width: calc(50% - 20px); /* Adjust width of each paragraph */
margin-left: 0px; /* Adjust spacing between paragraphs */
}




.lg-cta-our{

overflow-x: hidden;
width: 339px;
border: none ;
border-radius: 10px;
height: 570px;
border-radius: 30px;
margin-left: 10px;
margin-top: 20px;
}
.lg-our-img{

width: 230px;
margin-left: 30px;
border-radius: 50px;
margin-top: 10px;
margin-bottom: 10px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(50px); /* Adjust this value as needed for the desired distance */
}
}

.lg-our-1{

width: 285px;
margin-top: 10px;

margin-left: 15px;
text-align: center ;

}



.lg-our-2{

width: 220px;
margin-top: 0px;
margin-left: 40px;
font-size: 20px;
margin-bottom: 10px;
}


.lg-our-3{
 
width: 320px;
padding-right: 2px;
margin-bottom: 0px;
font-size: 16px;

}

.lg-learn-more-btn-1 {


padding: 8px 16px; 
margin-left: -350px;
width: 120px;
margin-top: 80px;

}


p {

overflow-x: hidden;
text-align: left; /* Align the text to the left */
text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{

/* overflow-x: hidden; */
border: 1px none;
display: flex;
align-items: center;
justify-content: center;
padding: 0px;
}


.lg-drive-1{


  font-size: 20px;
  margin-left: 495px;
  margin-top: -550px;
  
  
  
  }
  .lg-more-btn-2{
    width: 150px;
    padding: 10px 20px; 
    margin-left: 825px;
    margin-top: 100px;
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }
  .lg-car-img{
  width: 250px;
  margin-left: 750px;
  border-radius: 50px;
  margin-top: -30px;
  
  
  }
  
  .lg-paragraph-1{
  width: 320px;
  margin-top: 560px;
  font-size: 20px;
  margin-left: 250px;
  text-align: center ;
  
  }
.lg-paragraph-2{
width: 320px;

font-size: 17px;

margin-left: 250px;

 
}
.lg-paragraph-car {
 
width: 0%; /* Make paragraphs full width */
 margin-top: 00px;
}


.lg-want-ps {
 
overflow-x: hidden;
width: 96%;
display: block;
margin-left: 7px;
border-radius: 10px;
margin-top: -20px;

}
.lg-pn-pm-want{
  
margin-left: -10px;

margin-bottom: 30px;
width: 290px;
}


.lg-box-1,
.lg-box-2,
.lg-box-3 {
 
        overflow-x: hidden;
  margin-right: 0; /* Remove right margin */
}
.lg-destination {
 
  overflow-x: hidden;
  color: black;
  margin: 0 1px; /* Adjust spacing between destinations and arrows */

}
 
.lg-arrow {
 
  overflow-x: hidden;

  color: black;
  font-size: 20px; /* Increase arrow size */
}
.lg-end-arrow {
 
  overflow-x: hidden;
  color: black;
  margin-left: 0px; /* Push the ">" symbol to the right */
  font-size: 20px; /* Increase ">" symbol size */
}

.lg-pn-pm-See{
  
overflow-x: hidden;

width: 200px;
margin-left: 100px;

}

}
@media only screen and (min-width: 375px) and (max-width: 383px) {  
  .navbar {
  padding-top: 80px; 
}
.home-page{
  overflow: hidden;

}

.lg-hero-1{
 
  display: flex;
  justify-content: center;  
      padding: 200px;    
  
      background-repeat: no-repeat;
      color: black;
      background-position: 200px;
      background-size: cover; 
      background-size: auto 180px, cover;
      background-position:  center left 1px; 
   
     
  
 
}

.lg-hero-car-1{
  width: 100px;
  font-size: 20px;
   margin-top: -110px;
   margin-left: 90px;

   text-align: center;
  
  }

.lg-comes-lion-2{
  width:350px;

  margin-left: -20px;
    font-size: 14px;
   margin-top: 5px;
   
  }


.lg-paragraph-our{
width: calc(50% - 20px); /* Adjust width of each paragraph */
margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-card-container{
display:block;
margin-top: -120px;
margin-left: 8%;
}

.lg-card {

width: 350px;
height: 200px;
transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}


.lg-card{
margin-top: 30px;
}

.lg-card {
position: relative;
width: 310px;
height: 200px;
background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
border-radius: 50px;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
margin-bottom: 10px;
transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}



.lg-cta-our{
width: 355px;
border: none ;
border-radius: 10px;
height: 630px;
background-color: #ededed; 
border-radius: 50px;
margin-left: 10px;
margin-top: 30px;
}
.lg-our-img{
width: 260px;
margin-left: 20px;
border-radius: 50px;
margin-top: 10px;
margin-bottom: 10px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(50px); /* Adjust this value as needed for the desired distance */
}
}

.lg-our-1{

margin-top: 10px;

margin-left: 15px;
text-align: center ;

}



.lg-our-2{
width: 210px;
margin-top: 0px;
margin-left: 50px;
font-size: 22px;
margin-bottom: 10px;
}


.lg-our-3{


width: 320px;
margin-left:10px;
margin-bottom: 0px;
font-size: 17px;

}

.lg-learn-more-btn-1 {
padding: 8px 16px; 
margin-left: -350px;
width: 120px;
}


p {
text-align: left; /* Align the text to the left */
text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{


border: 1px none;
display: flex;
align-items: center;
justify-content: center;
padding: 0px;
}

.lg-drive-1{


  font-size: 20px;
  margin-left: 495px;
  margin-top: -550px;
  
  
  
  }
  .lg-more-btn-2{
    width: 9%;
    padding: 10px 20px; 
    margin-left: 780px;
    margin-top: 100px;
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }
  .lg-car-img{
  width: 280px;
  margin-left: 785px;
  border-radius: 50px;
  margin-top: -30px;
  
  
  }
  
  .lg-paragraph-1{
  width: 350px;
  margin-top: 560px;
  font-size: 22px;
  margin-left: 180px;
  text-align: center ;
  
  }
.lg-paragraph-2{
width: 380px;

font-size: 17px;

margin-left: 180px;

 
}
.lg-paragraph-car {
width: 100%; /* Make paragraphs full width */
margin-left: 100px;/* Remove right margin */
 margin-top: 00px;
}
.lg-paragraph-car{
margin-top: 00px;
margin-right: 750px;

}


/* Adjustments for search box */
.lg-search-box {
  /* Stack inputs vertically */
  padding: 10px;
  display: block; /* Reduce padding for smaller screens */
}


.lg-location,
.lg-arrow-box {
  margin-right: 0; /* Remove right margin for smaller screens */
}

.lg-location input {
  width: calc(100% - 40px); /* Make inputs full width */
  margin: 10px 0; /* Add spacing between inputs */
}



.lg-passenger-btn-group {
  margin-left: 0; /* Remove left margin for center alignment */
}

.lg-passenger-btn {
  margin-right: 10px; /* Add spacing between buttons */
  margin-bottom: 10px; /* Add spacing between buttons */
}

.lg-search-btn {
  width: 100%; /* Make button full width */
  margin: 10px 0; /* Add spacing around button */
}

.lg-paragraph-our {
  width: 100%; /* Make paragraphs full width */
  margin-right: 0; /* Remove right margin */
}
.lg-want-ps {
width: 96%;
display: block;
margin-left: 7px;


}
.lg-pn-pm-want{
margin-bottom: 50px;
}
.lg-car-pn-pm {

 background-image: center;
 
 
}

.lg-box-1,
.lg-box-2,
.lg-box-3 {
  margin-right: 0; /* Remove right margin */
}


.lg-pn-pm-See{

width: 200px;
margin-left: 100px;

}







}
@media only screen and (min-width: 384px) and (max-width: 389px) {  
  .navbar {
  padding-top: 80px; 
}
.home-page{
  overflow: hidden;

}

.lg-hero-1{
 
  display: flex;
  justify-content: center;  
      padding: 200px;    
  
      background-repeat: no-repeat;
      color: black;
      background-position: 200px;
      background-size: cover; 
      background-size: auto 180px, cover;
      background-position:  center left 8px; 
   
     
  
 
}

.lg-hero-car-1{
  width: 100px;
  font-size: 20px;
   margin-top: -110px;
   margin-left: 100px;

   text-align: center;
  
  }

.lg-comes-lion-2{
  width:350px;

  margin-left: -10px;
    font-size: 14px;
   margin-top: 5px;
   
  }


.lg-paragraph-our{
width: calc(50% - 20px); /* Adjust width of each paragraph */
margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-card-container{
display:block;
margin-top: -120px;
margin-left: 8%;
}

.lg-card {

width: 350px;
height: 200px;
transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}


.lg-card{
margin-top: 30px;
}

.lg-card {
position: relative;
width: 320px;
height: 200px;
background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
border-radius: 50px;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
margin-bottom: 10px;
transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}



.lg-cta-our{
width: 365px;
border: none ;
border-radius: 10px;
height: 590px;
background-color: #ededed; 
border-radius: 50px;
margin-left: 10px;
margin-top: 30px;
}
.lg-our-img{
width: 260px;
margin-left: 28px;
border-radius: 50px;
margin-top: 10px;
margin-bottom: 10px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(50px); /* Adjust this value as needed for the desired distance */
}
}

.lg-our-1{

margin-top: 10px;

margin-left: 15px;
text-align: center ;

}



.lg-our-2{
width: 210px;
margin-top: 0px;
margin-left: 60px;
font-size: 22px;
margin-bottom: 10px;
}


.lg-our-3{


width: 350px;
margin-left:-5px;
margin-bottom: 0px;
font-size: 17px;

}

.lg-learn-more-btn-1 {
padding: 8px 16px; 
margin-left: -350px;
width: 120px;
margin-top: 90px;
}


p {
text-align: left; /* Align the text to the left */
text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{


border: 1px none;
display: flex;
align-items: center;
justify-content: center;
padding: 0px;
}


.lg-drive-1{


font-size: 20px;
margin-left: -600px;
margin-top: -550px;



}
.lg-more-btn-2{
  margin-top: 80px;
margin-left: -260px;

}
.lg-car-img{
width: 260px;
margin-left: 795px;
border-radius: 50px;
margin-top: -315px;


}

.lg-paragraph-1{
width: 280px;
margin-top: 720px;
font-size: 22px;
margin-left: 250px;
text-align: center ;

}
.lg-paragraph-2{
width: 350px;

font-size: 17px;

margin-left: 230px;

 
}
.lg-paragraph-car {
width: 100%; /* Make paragraphs full width */
margin-left: 100px;/* Remove right margin */
 margin-top: 00px;
}
.lg-paragraph-car{
margin-top: 00px;
margin-right: 750px;

}


/* Adjustments for search box */
.lg-search-box {
  /* Stack inputs vertically */
  padding: 10px;
  display: block; /* Reduce padding for smaller screens */
}


.lg-location,
.lg-arrow-box {
  margin-right: 0; /* Remove right margin for smaller screens */
}

.lg-location input {
  width: calc(100% - 40px); /* Make inputs full width */
  margin: 10px 0; /* Add spacing between inputs */
}



.lg-passenger-btn-group {
  margin-left: 0; /* Remove left margin for center alignment */
}

.lg-passenger-btn {
  margin-right: 10px; /* Add spacing between buttons */
  margin-bottom: 10px; /* Add spacing between buttons */
}

.lg-search-btn {
  width: 100%; /* Make button full width */
  margin: 10px 0; /* Add spacing around button */
}

.lg-paragraph-our {
  width: 100%; /* Make paragraphs full width */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}
.lg-want-ps {
width: 96%;
display: block;
margin-left: 7px;


}
.lg-pn-pm-want{
margin-bottom: 50px;
}
.lg-car-pn-pm {

 background-image: center;
 
 
}

.lg-box-1,
.lg-box-2,
.lg-box-3 {
  margin-right: 0; /* Remove right margin */
}


.lg-pn-pm-See{

width: 200px;
margin-left: 100px;

}







}

@media only screen and (min-width: 390px) and (max-width: 411px) {  
  .navbar {
  padding-top: 80px; 
}
.home-page{
  overflow: hidden;

}

.lg-hero-1{
 
  display: flex;
  justify-content: center;  
      padding: 200px;    
  
      background-repeat: no-repeat;
      color: black;
      background-position: 200px;
      background-size: cover; 
      background-size: auto 180px, cover;
      background-position:  center left 10px; 
   
     
  
 
}

.lg-hero-car-1{
  width: 100px;
  font-size: 20px;
   margin-top: -110px;
   margin-left: 100px;

   text-align: center;
  
  }

.lg-comes-lion-2{
  width:350px;

  margin-left: -10px;
    font-size: 14px;
   margin-top: 5px;
   
  }


.lg-paragraph-our{
width: calc(50% - 20px); /* Adjust width of each paragraph */
margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-card-container{
display:block;
margin-top: -120px;
margin-left: 8%;
}

.lg-card {

width: 350px;
height: 200px;
transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}


.lg-card{
margin-top: 30px;
}

.lg-card {
position: relative;
width: 330px;
height: 200px;
background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
border-radius: 50px;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
margin-bottom: 10px;
transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}



.lg-cta-our{
width: 370px;
border: none ;
border-radius: 10px;
height: 640px;
background-color: #ededed; 
border-radius: 50px;
margin-left: 10px;
margin-top: 30px;
}
.lg-our-img{
width: 280px;
margin-left: 20px;
border-radius: 50px;
margin-top: 10px;
margin-bottom: 10px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(50px); /* Adjust this value as needed for the desired distance */
}
}

.lg-our-1{

margin-top: 10px;

margin-left: 15px;
text-align: center ;

}



.lg-our-2{
width: 210px;
margin-top: 0px;
margin-left: 65px;
font-size: 22px;
margin-bottom: 10px;
}


.lg-our-3{


width: 340px;
margin-left:10px;
margin-bottom: 0px;
font-size: 17px;

}

.lg-learn-more-btn-1 {
  margin-top: 90px;

padding: 8px 16px; 
margin-left: -350px;
width: 120px;
}


p {
text-align: left; /* Align the text to the left */
text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{


border: 1px none;
display: flex;
align-items: center;
justify-content: center;
padding: 0px;
}

.lg-drive-1{


  font-size: 20px;
  margin-left: 495px;
  margin-top: -550px;
  
  
  
  }
  .lg-more-btn-2{
    width: 9%;
    padding: 10px 20px; 
    margin-left: 780px;
    margin-top: 100px;
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }
  .lg-car-img{
  width: 310px;
  margin-left: 770px;
  border-radius: 50px;
  margin-top: -30px;
  
  
  }
  
  .lg-paragraph-1{
  width: 350px;
  margin-top: 560px;
  font-size: 22px;
  margin-left: 180px;
  text-align: center ;
  
  }
.lg-paragraph-2{
width: 380px;

font-size: 17px;

margin-left: 180px;

 
}
.lg-paragraph-car {
width: 100%; /* Make paragraphs full width */
margin-left: 100px;/* Remove right margin */
 margin-top: 00px;
}
.lg-paragraph-car{
margin-top: 00px;
margin-right: 750px;

}


/* Adjustments for search box */
.lg-search-box {
  /* Stack inputs vertically */
  padding: 10px;
  display: block; /* Reduce padding for smaller screens */
}


.lg-location,
.lg-arrow-box {
  margin-right: 0; /* Remove right margin for smaller screens */
}

.lg-location input {
  width: calc(100% - 40px); /* Make inputs full width */
  margin: 10px 0; /* Add spacing between inputs */
}



.lg-passenger-btn-group {
  margin-left: 0; /* Remove left margin for center alignment */
}

.lg-passenger-btn {
  margin-right: 10px; /* Add spacing between buttons */
  margin-bottom: 10px; /* Add spacing between buttons */
}

.lg-search-btn {
  width: 100%; /* Make button full width */
  margin: 10px 0; /* Add spacing around button */
}

.lg-paragraph-our {
  width: 100%; /* Make paragraphs full width */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}
.lg-want-ps {
width: 97%;
display: block;
margin-left: 7px;
margin-top: -20px;

}
.lg-pn-pm-want{
margin-bottom: 50px;
}
.lg-car-pn-pm {

 background-image: center;
 
 
}

.lg-box-1,
.lg-box-2,
.lg-box-3 {
  margin-right: 0; /* Remove right margin */
}


.lg-pn-pm-See{

width: 200px;
margin-left: 100px;

}







}


@media only screen and (min-width: 412px) and (max-width: 427px) {  
  .navbar {
  padding-top: 80px; 
}
.home-page{
  overflow: hidden;

}

.lg-hero-1{
 
  display: flex;
  justify-content: center;  
      padding: 200px;    
  
      background-repeat: no-repeat;
      color: black;
      background-position: 200px;
      background-size: cover; 
      background-size: auto 200px, cover;
      background-position:  center left 1px; 
   
     
  
 
}

.lg-hero-car-1{
  width: 100px;
  font-size: 20px;
   margin-top: -110px;
   margin-left: 130px;

   text-align: center;
  
  }

.lg-comes-lion-2{
  width:380px;

  margin-left: 10px;
    font-size: 15px;
   margin-top: 5px;
   
  }


.lg-paragraph-our{
width: calc(50% - 20px); /* Adjust width of each paragraph */
margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-card-container{
display:block;
margin-top: -110px;
margin-left: 8%;
}

.lg-card {

width: 350px;
height: 200px;
transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}


.lg-card{
margin-top: 30px;
}

.lg-card {
position: relative;
width: 350px;
height: 200px;
background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
border-radius: 50px;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
margin-bottom: 10px;
transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}



.lg-cta-our{
width: 395px;
border: none ;
border-radius: 10px;
height: 650px;
background-color: #ededed; 
border-radius: 50px;
margin-left: 10px;
margin-top: 30px;
}
.lg-our-img{
width: 300px;
margin-left: 20px;
border-radius: 50px;
margin-top: 10px;
margin-bottom: 10px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(50px); /* Adjust this value as needed for the desired distance */
}
}

.lg-our-1{

margin-top: 10px;

margin-left: 15px;
text-align: center ;

}



.lg-our-2{
width: 210px;
margin-top: 0px;
margin-left: 70px;
font-size: 22px;
margin-bottom: 10px;
}


.lg-our-3{


width: 350px;
margin-left:10px;
margin-bottom: 0px;
font-size: 17px;

}

.lg-learn-more-btn-1 {
padding: 8px 16px; 
margin-left: -350px;
width: 120px;
}


p {
text-align: left; /* Align the text to the left */
text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{


border: 1px none;
display: flex;
align-items: center;
justify-content: center;
padding: 0px;
}

.lg-drive-1{


  font-size: 20px;
  margin-left: 495px;
  margin-top: -550px;
  
  
  
  }
  .lg-more-btn-2{
    width: 9%;
    padding: 10px 20px; 
    margin-left: 780px;
    margin-top: 100px;
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }
  .lg-car-img{
  width: 310px;
  margin-left: 780px;
  border-radius: 50px;
  margin-top: -30px;
  
  
  }
  
  .lg-paragraph-1{
  width: 350px;
  margin-top: 560px;
  font-size: 22px;
  margin-left: 180px;
  text-align: center ;
  
  }
.lg-paragraph-2{
width: 380px;

font-size: 17px;

margin-left: 180px;

 
}
.lg-paragraph-car {
width: 100%; /* Make paragraphs full width */
margin-left: 100px;/* Remove right margin */
 margin-top: 00px;
}
.lg-paragraph-car{
margin-top: 00px;
margin-right: 750px;

}


/* Adjustments for search box */
.lg-search-box {
  /* Stack inputs vertically */
  padding: 10px;
  display: block; /* Reduce padding for smaller screens */
}


.lg-location,
.lg-arrow-box {
  margin-right: 0; /* Remove right margin for smaller screens */
}

.lg-location input {
  width: calc(100% - 40px); /* Make inputs full width */
  margin: 10px 0; /* Add spacing between inputs */
}



.lg-passenger-btn-group {
  margin-left: 0; /* Remove left margin for center alignment */
}

.lg-passenger-btn {
  margin-right: 10px; /* Add spacing between buttons */
  margin-bottom: 10px; /* Add spacing between buttons */
}

.lg-search-btn {
  width: 100%; /* Make button full width */
  margin: 10px 0; /* Add spacing around button */
}

.lg-paragraph-our {
  width: 100%; /* Make paragraphs full width */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}
.lg-want-ps {
width: 96%;
display: block;
margin-left: 7px;


}
.lg-pn-pm-want{
margin-bottom: 50px;
}
.lg-car-pn-pm {

 background-image: center;
 
 
}

.lg-box-1,
.lg-box-2,
.lg-box-3 {
  margin-right: 0; /* Remove right margin */
}


.lg-pn-pm-See{

width: 200px;
margin-left: 100px;

}







}

@media only screen and (min-width: 428px) and (max-width: 479px) {  
  .navbar {
  padding-top: 80px; 
}
.home-page{
  overflow: hidden;

}

.lg-hero-1{
 
  display: flex;
  justify-content: center;  
      padding: 200px;    
  
      background-repeat: no-repeat;
      color: black;
      background-position: 200px;
      background-size: cover; 
      background-size: auto 200px, cover;
      background-position:  center left 8px; 
   
     
  
 
}

.lg-hero-car-1{
  width: 100px;
  font-size: 20px;
   margin-top: -110px;
   margin-left: 130px;

   text-align: center;
  
  }

.lg-comes-lion-2{
  width:380px;

  margin-left: 10px;
    font-size: 15px;
   margin-top: 5px;
   
  }


.lg-paragraph-our{
width: calc(50% - 20px); /* Adjust width of each paragraph */
margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-card-container{
display:block;
margin-top: -110px;
margin-left: 8%;
}

.lg-card {

width: 350px;
height: 200px;
transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}


.lg-card{
margin-top: 30px;
}

.lg-card {
position: relative;
width: 360px;
height: 200px;
background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
border-radius: 50px;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
margin-bottom: 10px;
transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}



.lg-cta-our{
width: 405px;
border: none ;
border-radius: 10px;
height: 650px;
background-color: #ededed; 
border-radius: 50px;
margin-left: 10px;
margin-top: 30px;
}
.lg-our-img{
width: 310px;
margin-left: 20px;
border-radius: 50px;
margin-top: 10px;
margin-bottom: 10px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(50px); /* Adjust this value as needed for the desired distance */
}
}

.lg-our-1{

margin-top: 10px;

margin-left: 15px;
text-align: center ;

}



.lg-our-2{
width: 210px;
margin-top: 0px;
margin-left: 80px;
font-size: 22px;
margin-bottom: 10px;
}


.lg-our-3{


width: 380px;
margin-left:0px;
margin-bottom: 0px;
font-size: 17px;

}

.lg-learn-more-btn-1 {
padding: 8px 16px; 
margin-left: -350px;
width: 120px;
margin-top: 90px;
}


p {
text-align: left; /* Align the text to the left */
text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{


border: 1px none;
display: flex;
align-items: center;
justify-content: center;
padding: 0px;
}


.lg-drive-1{


font-size: 20px;
margin-left: 495px;
margin-top: -550px;



}
.lg-more-btn-2{
  width: 9%;
  padding: 10px 20px; 
  margin-left: 780px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 0px;
  font-size: 20px;
}
.lg-car-img{
width: 310px;
margin-left: 780px;
border-radius: 50px;
margin-top: -30px;


}

.lg-paragraph-1{
width: 350px;
margin-top: 560px;
font-size: 22px;
margin-left: 200px;
text-align: center ;

}
.lg-paragraph-2{
width: 380px;

font-size: 17px;

margin-left: 200px;

 
}
.lg-paragraph-car {
width: 100%; /* Make paragraphs full width */
margin-left: 100px;/* Remove right margin */
 margin-top: 00px;
}
.lg-paragraph-car{
margin-top: 00px;
margin-right: 750px;

}


/* Adjustments for search box */
.lg-search-box {
  /* Stack inputs vertically */
  padding: 10px;
  display: block; /* Reduce padding for smaller screens */
}


.lg-location,
.lg-arrow-box {
  margin-right: 0; /* Remove right margin for smaller screens */
}

.lg-location input {
  width: calc(100% - 40px); /* Make inputs full width */
  margin: 10px 0; /* Add spacing between inputs */
}



.lg-passenger-btn-group {
  margin-left: 0; /* Remove left margin for center alignment */
}

.lg-passenger-btn {
  margin-right: 10px; /* Add spacing between buttons */
  margin-bottom: 10px; /* Add spacing between buttons */
}

.lg-search-btn {
  width: 100%; /* Make button full width */
  margin: 10px 0; /* Add spacing around button */
}

.lg-paragraph-our {
  width: 100%; /* Make paragraphs full width */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}
.lg-want-ps {
  margin-top: -20px;

width: 96%;
display: block;
margin-left: 7px;


}
.lg-pn-pm-want{
margin-bottom: 50px;
}
.lg-car-pn-pm {

 background-image: center;
 
 
}

.lg-box-1,
.lg-box-2,
.lg-box-3 {
  margin-right: 0; /* Remove right margin */
}


.lg-pn-pm-See{

width: 200px;
margin-left: 100px;

}


}

@media only screen and (min-width: 480px) and (max-width: 539px) {  
  .home-page{
    overflow: hidden;
  
  }

    .navbar {
      padding-top: 80px; /* Increase padding to make room for the fixed header */
    }

    .lg-hero-car-1{
   
      width: 120px;
      font-size: 25px;
       margin-top: -150px;
     margin-left: 130px;
       text-align: center;
      
      }
    
    .lg-comes-lion-2{
      width:400px;
      margin-left: 0px;
        font-size: 16px;
       margin-top: -0px;
       
      }
    
    .lg-hero-1{
      
      overflow-x: hidden;
      display: flex;
      justify-content: center;  
          padding: 235px;    
      
          background-repeat: no-repeat;
          color: black;
          background-position: 200px;
          background-size: cover; 
          background-size: auto 230px, cover;
          background-position:  center left 5px; 
     
    }
    
  
  
  
  .lg-card {
    position: relative;
    width: 400px;
    height: 250px;
    background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 160px;
    margin-top: -120px;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .lg-card svg {
    width: 100px;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .lg-card:hover {
    transform: rotate(-5deg) scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  
  .ab-about {
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    text-align: center;
  }
  .lg-card:hover .lg-card__content {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 1;
  }
  .lg-card-container{
    display: flex;
    gap:3rem;
    margin-top:40px;
    margin-left: 7%;
  }
  .lg-card__title {
    margin: 0;
    font-size: 25px;
    color: #333;
    font-weight: 700;
  }
  
  .lg-card__description {
    margin: 10px 0 0;
    font-size: 20px;
    color: #777;
    line-height: 1.4;
  }
  
  .lg-card:hover svg {
    scale: 0;
    transform: rotate(-45deg);
  }
  
  .lg-card-lgicon {
    font-size: 5.5rem; /* Adjust the size as needed */
  }
    .lg-button-container {
      margin-left: 0; /* Remove left margin for center alignment */
    }
  
    .lg-learn-more-btn-1 {
      padding: 8px 16px; /* Adjust padding for smaller buttons */
    }
  
  
  .lg-paragraph-our{
    width: calc(50% - 20px); /* Adjust width of each paragraph */
    margin-left: 0px; /* Adjust spacing between paragraphs */
  }
  
  .lg-cta-our{
    width: 460px;
    margin-left: 10px;
    border: none ;
    border-radius: 10px;
    height: 760px;
    background-color: #d4d3d3; 
    border-radius: 40px;
    
    margin-top: -120px;
  }
  .lg-our-img{
  width: 350px;
  margin-left: 25px;
  border-radius: 50px;
  margin-top: 20px;
  animation: moveRight 2s ease-in-out infinite alternate;
  
  }
  @keyframes moveRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50px); /* Adjust this value as needed for the desired distance */
    }
  }
  
  .lg-our-1{
    width: 100%;
   margin-top: 50px;
   font-size: 24.5px;
   margin-left: 10px;
   text-align: center ;
   
  }
  
  
   
  .lg-our-2{

    margin-top: -20px;
    margin-left: 50px;
    font-size: 25px;
    margin-bottom: 20px;
    width: 300px;
  }
  
  
  .lg-our-3{
    font-size: 20px;

    width: 420px;
    margin-left:10px;
    
  }
   
  
  .lg-learn-more-btn-1 {
    width: 100%;
    padding: 10px 20px; 
    margin-left: 10px;
    margin-top: 75px;
    font-size: 20px;
  }
  
  p {
    text-align: left; /* Align the text to the left */
    text-indent: 0; /* Remove any indentation */
  }
  
  
  .lg-car-pn-pm{
  
    
    border: 1px none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  
  
  .lg-drive-1{


    font-size: 20px;
    margin-left: -500px;
    margin-top: -550px;
    
    
    
    }
    .lg-more-btn-2{
      margin-top: 70px;
    
    margin-left: -270px;
    
    }
    .lg-car-img{
    width: 310px;
    margin-left: 780px;
    border-radius: 50px;
    margin-top: -340px;
    
    
    }
    
    .lg-paragraph-1{
    width: 350px;
    margin-top: 790px;
    font-size: 22px;
    margin-left: 200px;
    text-align: center ;
    
    }
    .lg-paragraph-2{
    width: 400px;
    
    font-size: 17px;
    
    margin-left: 180px;
    
     
    }
  
   .lg-more-btn-2{
    width: 140px;
    padding: 10px 20px; 
    margin-left: -200px;
    margin-top: 90px;
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }
  
   .lg-paragraph-car {
    width: 100%; /* Make paragraphs full width */
    margin-right: 10px;/* Remove right margin */
     margin-top: 00px;
  }
  .lg-paragraph-car{
    margin-top: 00px;
    margin-right: 750px;
    
  }
   
  
    .lg-search-box {
      padding: 10px;
      display: block; /* Reduce padding for smaller screens */
    }
  
  
    .lg-location,
    .lg-arrow-box {
      margin-right: 0; /* Remove right margin for smaller screens */
    }
  
    .lg-location input {
      width: calc(100% - 40px); /* Make inputs full width */
      margin: 10px 0; /* Add spacing between inputs */
    }
  
   
  
    .lg-passenger-btn-group {
      margin-left: 0; /* Remove left margin for center alignment */
    }
  
    .lg-passenger-btn {
      margin-right: 10px; /* Add spacing between buttons */
      margin-bottom: 10px; /* Add spacing between buttons */
    }
  
    .lg-search-btn {
      width: 100%; /* Make button full width */
      margin: 10px 0; /* Add spacing around button */
    }
  
    .lg-paragraph-our {
      width: 100%; /* Make paragraphs full width */
      margin-left: 0px; /* Adjust spacing between paragraphs */
    }
  .lg-want-ps {
    width: 97%;
    display: block;
    margin-left: 7px;
   
    
  }
  .lg-pn-pm-want{
    margin-bottom: 50px;
  }
    .lg-car-pn-pm {
  
     background-image: center;
     
    }
  
   
  .lg-pn-pm-See{
    
    width: 300px;
    margin-left: 480px;
    font-size: 20px;
  
  }
  
  
  
  
  
  
  .lg-card-container{
    display:block;
    margin-top: 30px;
    
  }
  
  
  

}
@media only screen and (min-width: 540px) and (max-width: 599px) {  
  .home-page{
    overflow: hidden;
  
  }

    .navbar {
      padding-top: 80px; /* Increase padding to make room for the fixed header */
    }

    .lg-hero-car-1{
   
      width: 120px;
      font-size: 20px;
       margin-top: -120px;
     margin-left: 140px;
       text-align: center;
      
      }
    
    .lg-comes-lion-2{
      width:400px;
      margin-left: 0px;
        font-size: 16px;
       margin-top: -10px;
       
      }
    
    .lg-hero-1{
      
      overflow-x: hidden;
      display: flex;
      justify-content: center;  
          padding: 205px;    
      
          background-repeat: no-repeat;
          color: black;
          background-position: 200px;
          background-size: cover; 
          background-size: auto 250px, cover;
          background-position:  center left 10px; 
     
    }
    
  
  
  
  .lg-card {
    position: relative;
    width: 460px;
    height: 250px;
    background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 130px;
    margin-top: -80px;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .lg-card svg {
    width: 100px;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .lg-card:hover {
    transform: rotate(-5deg) scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  
  .ab-about {
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    text-align: center;
  }
  .lg-card:hover .lg-card__content {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 1;
  }
  .lg-card-container{
    display: flex;
    gap:3rem;
    margin-top:40px;
    margin-left: 7%;
  }
  .lg-card__title {
    margin: 0;
    font-size: 25px;
    color: #333;
    font-weight: 700;
  }
  
  .lg-card__description {
    margin: 10px 0 0;
    font-size: 20px;
    color: #777;
    line-height: 1.4;
  }
  
  .lg-card:hover svg {
    scale: 0;
    transform: rotate(-45deg);
  }
  
  .lg-card-lgicon {
    font-size: 5.5rem; /* Adjust the size as needed */
  }
    .lg-button-container {
      margin-left: 0; /* Remove left margin for center alignment */
    }
  
    .lg-learn-more-btn-1 {
      padding: 8px 16px; /* Adjust padding for smaller buttons */
    }
  
  
  .lg-paragraph-our{
    width: calc(50% - 20px); /* Adjust width of each paragraph */
    margin-left: 0px; /* Adjust spacing between paragraphs */
  }
  
  .lg-cta-our{
    width: 500px;
    margin-left: 20px;
    border: none ;
    border-radius: 10px;
    height: 740px;
    background-color: #d4d3d3; 
    border-radius: 40px;
    
    margin-top: -90px;
  }
  .lg-our-img{
  width: 350px;
  margin-left: 50px;
  border-radius: 50px;
  margin-top: 20px;
  animation: moveRight 2s ease-in-out infinite alternate;
  
  }
  @keyframes moveRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50px); /* Adjust this value as needed for the desired distance */
    }
  }
  
  .lg-our-1{
    width: 100%;
   margin-top: 50px;
   font-size: 24.5px;
   margin-left: 10px;
   text-align: center ;
   
  }
  
  
   
  .lg-our-2{

    margin-top: -20px;
    margin-left: 90px;
    font-size: 25px;
    margin-bottom: 20px;
    width: 300px;
  }
  
  
  .lg-our-3{
    font-size: 20px;

    width: 470px;
    margin-left:5px;
    
  }
   
  
  .lg-learn-more-btn-1 {
    width: 100%;
    padding: 10px 20px; 
    margin-left: 10px;
    margin-top: 75px;
    font-size: 20px;
  }
  
  p {
    text-align: left; /* Align the text to the left */
    text-indent: 0; /* Remove any indentation */
  }
  
  
  .lg-car-pn-pm{
  
    
    border: 1px none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  
  
  .lg-drive-1{


    font-size: 20px;
    margin-left: -500px;
    margin-top: -550px;
    
    
    
    }
    .lg-more-btn-2{
      margin-top: 70px;
    
    margin-left: -270px;
    
    }
    .lg-car-img{
    width: 310px;
    margin-left: 800px;
    border-radius: 50px;
    margin-top: -340px;
    
    
    }
    
    .lg-paragraph-1{
    width: 350px;
    margin-top: 790px;
    font-size: 22px;
    margin-left: 200px;
    text-align: center ;
    
    }
    .lg-paragraph-2{
    width: 450px;
    
    font-size: 17px;
    
    margin-left: 150px;
    
     
    }
  
   .lg-more-btn-2{
    width: 140px;
    padding: 10px 20px; 
    margin-left: -200px;
    margin-top: 90px;
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }
  
   .lg-paragraph-car {
    width: 100%; /* Make paragraphs full width */
    margin-right: 10px;/* Remove right margin */
     margin-top: 00px;
  }
  .lg-paragraph-car{
    margin-top: 00px;
    margin-right: 750px;
    
  }
   
  
    .lg-search-box {
      padding: 10px;
      display: block; /* Reduce padding for smaller screens */
    }
  
  
    .lg-location,
    .lg-arrow-box {
      margin-right: 0; /* Remove right margin for smaller screens */
    }
  
    .lg-location input {
      width: calc(100% - 40px); /* Make inputs full width */
      margin: 10px 0; /* Add spacing between inputs */
    }
  
   
  
    .lg-passenger-btn-group {
      margin-left: 0; /* Remove left margin for center alignment */
    }
  
    .lg-passenger-btn {
      margin-right: 10px; /* Add spacing between buttons */
      margin-bottom: 10px; /* Add spacing between buttons */
    }
  
    .lg-search-btn {
      width: 100%; /* Make button full width */
      margin: 10px 0; /* Add spacing around button */
    }
  
    .lg-paragraph-our {
      width: 100%; /* Make paragraphs full width */
      margin-left: 0px; /* Adjust spacing between paragraphs */
    }
  .lg-want-ps {
    width: 97%;
    display: block;
    margin-left: 7px;
   
    
  }
  .lg-pn-pm-want{
    margin-bottom: 50px;
  }
    .lg-car-pn-pm {
  
     background-image: center;
     
    }
  
   
  .lg-pn-pm-See{
    
    width: 300px;
    margin-left: 180px;
    font-size: 20px;
  
  }
  
  
  
  
  
  
  .lg-card-container{
    display:block;
    margin-top: 30px;
    
  }
  
  
  

}
@media only screen and (min-width: 600px) and (max-width: 639px) {  
    .home-page{
      overflow: hidden;
    
    }
  
      .navbar {
        padding-top: 80px; /* Increase padding to make room for the fixed header */
      }
  
      .lg-hero-car-1{
        overflow-x: hidden;

        width: 200px;
        font-size: 30px;
         margin-top: -160px;
       margin-left: 170px;
         text-align: center;
        
        }
      
      .lg-comes-lion-2{
        overflow-x: hidden;

        width:550px;
        margin-left: 10px;
          font-size: 22px;
         margin-top: -5px;
         
        }
      
      .lg-hero-1{
        
       
        display: flex;
        justify-content: center;  
            padding: 250px;    
        
            background-repeat: no-repeat;
            color: black;
            background-position: 200px;
            background-size: cover; 
            background-size: auto 300px, cover;
            background-position:  center left -5px; 
       
      }
      
    
    
    
    .lg-card {
     

      
      border:1px solid rgb(250, 247, 247);
      position: relative;
      width: 500px;
      height: 250px;
      background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-bottom: 150px;
      margin-top: -100px;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
    .lg-card svg {
      
      width: 100px;
      fill: #333;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
    .lg-card:hover {
      transform: rotate(-5deg) scale(1.1);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
    
    
    .ab-about {
      font-size: 30px;
      font-family: 'Times New Roman', Times, serif;
      font-weight: 700;
      text-align: center;
    }
    .lg-card:hover .lg-card__content {
      transform: translate(-50%, -50%) rotate(0deg);
      opacity: 1;
    }
    .lg-card-container{
    margin-top: 20px;
      display: flex;
      gap:3rem;
      margin-left: 8%;
    }
    .lg-card__title {
      margin: 0;
      font-size: 25px;
      color: #333;
      font-weight: 700;
    }
    
    .lg-card__description {
      margin: 10px 0 0;
      font-size: 25px;
      color: #777;
      line-height: 1.4;
    }
    
    .lg-card:hover svg {
      scale: 0;
      transform: rotate(-45deg);
    }
    
    .lg-card-lgicon {
      font-size: 5.5rem; /* Adjust the size as needed */
    }
      .lg-button-container {
        margin-left: 0; /* Remove left margin for center alignment */
      }
    
      .lg-learn-more-btn-1 {
        padding: 8px 16px; /* Adjust padding for smaller buttons */
      }
    
    
    .lg-paragraph-our{
      width: calc(50% - 20px); /* Adjust width of each paragraph */
      margin-left: 0px; /* Adjust spacing between paragraphs */
    }
    
    .lg-cta-our{
      width: 560px;
      margin-left: 20px;
      border: none ;
      border-radius: 10px;
      height: 910px;
      background-color: #d4d3d3; 
      border-radius: 40px;
      
      margin-top: -100px;
    }
    .lg-our-img{
    width: 450px;
    margin-left: 30px;
    border-radius: 50px;
    margin-top: 20px;
    animation: moveRight 2s ease-in-out infinite alternate;
    
    }
    @keyframes moveRight {
      0% {
          transform: translateX(0);
      }
      100% {
          transform: translateX(50px); /* Adjust this value as needed for the desired distance */
      }
    }
    
    .lg-our-1{
      width: 100%;
     margin-top: 50px;
     font-size: 24.5px;
     margin-left: 10px;
     text-align: center ;
     
    }
    
    
     
    .lg-our-2{
     
    
      margin-top: -20px;
      margin-right: 120px;
      font-size: 30px;
      margin-bottom: 20px;
    }
    
    
    .lg-our-3{
      
      width: 550px;
      margin-left:0px;
      
    }
     
    
    .lg-learn-more-btn-1 {
      width: 100%;
      padding: 10px 20px; 
      margin-left: 10px;
      margin-top: 65px;
      font-size: 20px;
    }
    
    p {
      text-align: left; /* Align the text to the left */
      text-indent: 0; /* Remove any indentation */
    }
    
    
    .lg-car-pn-pm{

      border: 1px none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
    }
    
    
    .lg-drive-1{

     font-size: 20px;
     margin-left: -500px;
    
    text-align: center;
    margin-top: -500px;
     
    }
    
    .lg-car-img{
      width: 450px;
      margin-left: 790px;
      border-radius: 50px;
      margin-top: -420px;

    
    
    }
    .lg-paragraph-1{
      width: 500px;
     margin-top: 840px;
      font-size: 30px;
      margin-left: 50px;
      text-align: center ;
     
    }
    .lg-paragraph-2{
      width: 540px;
     
      font-size: 25px;
     
      margin-left: 50px;
    
       
     }
    
     .lg-more-btn-2{
      width: 120px;
      margin-left: -260px;
      margin-top: 80px;
      text-align: center;
     
      padding: 10px 20px; 
      
    }
    
     .lg-paragraph-car {
      width: 100%; /* Make paragraphs full width */
      margin-right: 10px;/* Remove right margin */
       margin-top: 00px;
    }
    .lg-paragraph-car{
      margin-top: 00px;
      margin-right: 750px;
      
    }
     
    
      .lg-search-box {
        padding: 10px;
        display: block; /* Reduce padding for smaller screens */
      }
    
    
      .lg-location,
      .lg-arrow-box {
        margin-right: 0; /* Remove right margin for smaller screens */
      }
    
      .lg-location input {
        width: calc(100% - 40px); /* Make inputs full width */
        margin: 10px 0; /* Add spacing between inputs */
      }
    
     
    
      .lg-passenger-btn-group {
        margin-left: 0; /* Remove left margin for center alignment */
      }
    
      .lg-passenger-btn {
        margin-right: 10px; /* Add spacing between buttons */
        margin-bottom: 10px; /* Add spacing between buttons */
      }
    
      .lg-search-btn {
        width: 100%; /* Make button full width */
        margin: 10px 0; /* Add spacing around button */
      }
    
      .lg-paragraph-our {
        width: 100%; /* Make paragraphs full width */
        margin-left: 0px; /* Adjust spacing between paragraphs */
      }
    .lg-want-ps {
      width: 97.5%;
      display: block;
      margin-left: 7px;
     
      
    }
    .lg-pn-pm-want{
      margin-bottom: 50px;
    }
      .lg-car-pn-pm {
    
       background-image: center;
       
      }
    
     
    .lg-pn-pm-See{

      width: 300px;
      margin-left: 280px;
      font-size: 20px;
    
    }
    
    
    
    
    
    
    .lg-card-container{
      display:block;
      margin-top: 30px;
      
    }
    
    
    
  
  }
  @media only screen and (min-width: 640px) and (max-width: 711px) {  
    .home-page{
      overflow: hidden;
    
    }
  
      .navbar {
        padding-top: 80px; /* Increase padding to make room for the fixed header */
      }
  
      .lg-hero-car-1{
        overflow-x: hidden;

        width: 200px;
        font-size: 30px;
         margin-top: -160px;
       margin-left: 170px;
         text-align: center;
        
        }
      
      .lg-comes-lion-2{
        overflow-x: hidden;

        width:550px;
        margin-left: 10px;
          font-size: 22px;
         margin-top: -5px;
         
        }
      
      .lg-hero-1{
        
       
        display: flex;
        justify-content: center;  
            padding: 250px;    
        
            background-repeat: no-repeat;
            color: black;
            background-position: 200px;
            background-size: cover; 
            background-size: auto 300px, cover;
            background-position:  center left 10px; 
       
      }
      
    
    
    
    .lg-card {
     

      
      border:1px solid rgb(250, 247, 247);
      position: relative;
      width: 530px;
      height: 250px;
      background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-bottom: 150px;
      margin-top: -100px;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
    .lg-card svg {
      
      width: 100px;
      fill: #333;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
    .lg-card:hover {
      transform: rotate(-5deg) scale(1.1);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
    
    
    .ab-about {
      font-size: 30px;
      font-family: 'Times New Roman', Times, serif;
      font-weight: 700;
      text-align: center;
    }
    .lg-card:hover .lg-card__content {
      transform: translate(-50%, -50%) rotate(0deg);
      opacity: 1;
    }
    .lg-card-container{
    margin-top: 20px;
      display: flex;
      gap:3rem;
      margin-left: 8%;
    }
    .lg-card__title {
      margin: 0;
      font-size: 25px;
      color: #333;
      font-weight: 700;
    }
    
    .lg-card__description {
      margin: 10px 0 0;
      font-size: 25px;
      color: #777;
      line-height: 1.4;
    }
    
    .lg-card:hover svg {
      scale: 0;
      transform: rotate(-45deg);
    }
    
    .lg-card-lgicon {
      font-size: 5.5rem; /* Adjust the size as needed */
    }
      .lg-button-container {
        margin-left: 0; /* Remove left margin for center alignment */
      }
    
      .lg-learn-more-btn-1 {
        padding: 8px 16px; /* Adjust padding for smaller buttons */
      }
    
    
    .lg-paragraph-our{
      width: calc(50% - 20px); /* Adjust width of each paragraph */
      margin-left: 0px; /* Adjust spacing between paragraphs */
    }
    
    .lg-cta-our{
      width: 600px;
      margin-left: 20px;
      border: none ;
      border-radius: 10px;
      height: 910px;
      background-color: #d4d3d3; 
      border-radius: 40px;
      
      margin-top: -100px;
    }
    .lg-our-img{
    width: 450px;
    margin-left: 50px;
    border-radius: 50px;
    margin-top: 20px;
    animation: moveRight 2s ease-in-out infinite alternate;
    
    }
    @keyframes moveRight {
      0% {
          transform: translateX(0);
      }
      100% {
          transform: translateX(50px); /* Adjust this value as needed for the desired distance */
      }
    }
    
    .lg-our-1{
      width: 100%;
     margin-top: 50px;
     font-size: 24.5px;
     margin-left: 10px;
     text-align: center ;
     
    }
    
    
     
    .lg-our-2{
     
    
      margin-top: -20px;
      margin-right: 120px;
      font-size: 30px;
      margin-bottom: 20px;
    }
    
    
    .lg-our-3{
      
      width: 550px;
      margin-left:20px;
      
    }
     
    
    .lg-learn-more-btn-1 {
      width: 100%;
      padding: 10px 20px; 
      margin-left: 10px;
      margin-top: 65px;
      font-size: 20px;
    }
    
    p {
      text-align: left; /* Align the text to the left */
      text-indent: 0; /* Remove any indentation */
    }
    
    
    .lg-car-pn-pm{

      border: 1px none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
    }
    
    
    .lg-drive-1{

     font-size: 20px;
     margin-left: -450px;
    
    text-align: center;
    margin-top: -500px;
     
    }
    
    .lg-car-img{
      width: 450px;
      margin-left: 800px;
      border-radius: 50px;
      margin-top: -420px;

    
    
    }
    .lg-paragraph-1{
      width: 500px;
     margin-top: 840px;
      font-size: 30px;
      margin-left: 20px;
      text-align: center ;
     
    }
    .lg-paragraph-2{
      width: 540px;
     
      font-size: 25px;
     
      margin-left: -10px;
    
       
     }
    
     .lg-more-btn-2{
      width: 120px;
      margin-left: -260px;
      margin-top: 80px;
      text-align: center;
     
      padding: 10px 20px; 
      
    }
    
     .lg-paragraph-car {
      width: 100%; /* Make paragraphs full width */
      margin-right: 10px;/* Remove right margin */
       margin-top: 00px;
    }
    .lg-paragraph-car{
      margin-top: 00px;
      margin-right: 750px;
      
    }
     
    
      .lg-search-box {
        padding: 10px;
        display: block; /* Reduce padding for smaller screens */
      }
    
    
      .lg-location,
      .lg-arrow-box {
        margin-right: 0; /* Remove right margin for smaller screens */
      }
    
      .lg-location input {
        width: calc(100% - 40px); /* Make inputs full width */
        margin: 10px 0; /* Add spacing between inputs */
      }
    
     
    
      .lg-passenger-btn-group {
        margin-left: 0; /* Remove left margin for center alignment */
      }
    
      .lg-passenger-btn {
        margin-right: 10px; /* Add spacing between buttons */
        margin-bottom: 10px; /* Add spacing between buttons */
      }
    
      .lg-search-btn {
        width: 100%; /* Make button full width */
        margin: 10px 0; /* Add spacing around button */
      }
    
      .lg-paragraph-our {
        width: 100%; /* Make paragraphs full width */
        margin-left: 0px; /* Adjust spacing between paragraphs */
      }
    .lg-want-ps {
      width: 97.5%;
      display: block;
      margin-left: 7px;
     
      
    }
    .lg-pn-pm-want{
      margin-bottom: 50px;
    }
      .lg-car-pn-pm {
    
       background-image: center;
       
      }
    
     
    .lg-pn-pm-See{

      width: 300px;
      margin-left: 280px;
      font-size: 20px;
    
    }
    
    
    
    
    
    
    .lg-card-container{
      display:block;
      margin-top: 30px;
      
    }
    
    
    
  
  }
@media only screen and (min-width: 712px) and (max-width: 767px) {  
    .home-page{
      overflow: hidden;
    
    }
  
      .navbar {
        padding-top: 80px; /* Increase padding to make room for the fixed header */
      }
  
      .lg-hero-car-1{
        overflow-x: hidden;

        width: 200px;
        font-size: 30px;
         margin-top: -160px;
       margin-left: 170px;
         text-align: center;
        
        }
      
      .lg-comes-lion-2{
        overflow-x: hidden;

        width:550px;
        margin-left: 10px;
          font-size: 22px;
         margin-top: -10px;
         
        }
      
      .lg-hero-1{
        
       
        display: flex;
        justify-content: center;  
            padding: 250px;    
        
            background-repeat: no-repeat;
            color: black;
            background-position: 200px;
            background-size: cover; 
            background-size: auto 340px, cover;
            background-position:  center left 5px; 
       
      }
      
    
    
    
    .lg-card {
     

      
      border:1px solid rgb(250, 247, 247);
      position: relative;
      width: 600px;
      height: 250px;
      background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-bottom: 120px;
      margin-top: -80px;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
    .lg-card svg {
      
      width: 100px;
      fill: #333;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
    .lg-card:hover {
      transform: rotate(-5deg) scale(1.1);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
    
    
    .ab-about {
      font-size: 30px;
      font-family: 'Times New Roman', Times, serif;
      font-weight: 700;
      text-align: center;
    }
    .lg-card:hover .lg-card__content {
      transform: translate(-50%, -50%) rotate(0deg);
      opacity: 1;
    }
    .lg-card-container{
    margin-top: 20px;
      display: flex;
      gap:3rem;
      margin-left: 8%;
    }
    .lg-card__title {
      margin: 0;
      font-size: 25px;
      color: #333;
      font-weight: 700;
    }
    
    .lg-card__description {
      margin: 10px 0 0;
      font-size: 25px;
      color: #777;
      line-height: 1.4;
    }
    
    .lg-card:hover svg {
      scale: 0;
      transform: rotate(-45deg);
    }
    
    .lg-card-lgicon {
      font-size: 5.5rem; /* Adjust the size as needed */
    }
      .lg-button-container {
        margin-left: 0; /* Remove left margin for center alignment */
      }
    
      .lg-learn-more-btn-1 {
        padding: 8px 16px; /* Adjust padding for smaller buttons */
      }
    
    
    .lg-paragraph-our{
      width: calc(50% - 20px); /* Adjust width of each paragraph */
      margin-left: 0px; /* Adjust spacing between paragraphs */
    }
    
    .lg-cta-our{
      width: 670px;
      margin-left: 20px;
      border: none ;
      border-radius: 10px;
      height: 850px;
      background-color: #d4d3d3; 
      border-radius: 40px;
      
      margin-top: -70px;
    }
    .lg-our-img{
    width: 450px;
    margin-left: 90px;
    border-radius: 50px;
    margin-top: 20px;
    animation: moveRight 2s ease-in-out infinite alternate;
    
    }
    @keyframes moveRight {
      0% {
          transform: translateX(0);
      }
      100% {
          transform: translateX(50px); /* Adjust this value as needed for the desired distance */
      }
    }
    
    .lg-our-1{
      width: 100%;
     margin-top: 50px;
     font-size: 25px;
     margin-left: 10px;
     text-align: center ;
     
    }
    
    
     
    .lg-our-2{
     
    
      margin-top: -20px;
      margin-right: 120px;
      font-size: 30px;
      margin-bottom: 20px;
    }
    
    
    .lg-our-3{
      font-size: 23px;

      width: 630px;
      margin-left:20px;
      
    }
     
    
    .lg-learn-more-btn-1 {
      width: 100%;
      padding: 10px 20px; 
      margin-left: 10px;
      margin-top: 80px;
      font-size: 20px;
    }
    
    p {
      text-align: left; /* Align the text to the left */
      text-indent: 0; /* Remove any indentation */
    }
    
    
    .lg-car-pn-pm{

      border: 1px none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
    }
    
    
    .lg-drive-1{

     font-size: 20px;
     margin-left: -400px;
    
    text-align: center;
    margin-top: -500px;
     
    }
    
    .lg-car-img{
      width: 450px;
      margin-left: 800px;
      border-radius: 50px;
      margin-top: -420px;

    
    
    }
    .lg-paragraph-1{
      width: 500px;
     margin-top: 840px;
      font-size: 30px;
      margin-left: -50px;
      text-align: center ;
     
    }
    .lg-paragraph-2{
      width: 640px;
     
      font-size: 25px;
     
      margin-left: -100px;
    
       
     }
    
     .lg-more-btn-2{
      width: 120px;
      margin-left: -260px;
      margin-top: 80px;
      text-align: center;
     
      padding: 10px 20px; 
      
    }
    
     .lg-paragraph-car {
      width: 100%; /* Make paragraphs full width */
      margin-right: 10px;/* Remove right margin */
       margin-top: 00px;
    }
    .lg-paragraph-car{
      margin-top: 00px;
      margin-right: 750px;
      
    }
     
    
      .lg-search-box {
        padding: 10px;
        display: block; /* Reduce padding for smaller screens */
      }
    
    
      .lg-location,
      .lg-arrow-box {
        margin-right: 0; /* Remove right margin for smaller screens */
      }
    
      .lg-location input {
        width: calc(100% - 40px); /* Make inputs full width */
        margin: 10px 0; /* Add spacing between inputs */
      }
    
     
    
      .lg-passenger-btn-group {
        margin-left: 0; /* Remove left margin for center alignment */
      }
    
      .lg-passenger-btn {
        margin-right: 10px; /* Add spacing between buttons */
        margin-bottom: 10px; /* Add spacing between buttons */
      }
    
      .lg-search-btn {
        width: 100%; /* Make button full width */
        margin: 10px 0; /* Add spacing around button */
      }
    
      .lg-paragraph-our {
        width: 100%; /* Make paragraphs full width */
        margin-left: 0px; /* Adjust spacing between paragraphs */
      }
    .lg-want-ps {
      width: 97.5%;
      display: block;
      margin-left: 7px;
     
      
    }
    .lg-pn-pm-want{
      margin-bottom: 50px;
    }
      .lg-car-pn-pm {
    
       background-image: center;
       
      }
    
     
    .lg-pn-pm-See{

      width: 300px;
      margin-left: 350px;
      font-size: 20px;
    
    }
    
    
    
    
    
    
    .lg-card-container{
      display:block;
      margin-top: 30px;
      
    }
    
    
    
  
}
@media only screen and (min-width: 768px) and (max-width: 768px) {  
  .home-page{
    overflow: hidden;
  
  }

    .navbar {
      padding-top: 80px; /* Increase padding to make room for the fixed header */
    }

    .lg-hero-car-1{
      overflow-x: hidden;

      width: 200px;
      font-size: 30px;
       margin-top: -160px;
     margin-left: 170px;
       text-align: center;
      
      }
    
    .lg-comes-lion-2{
      overflow-x: hidden;

      width:550px;
      margin-left: 10px;
        font-size: 22px;
       margin-top: -10px;
       
      }
    
    .lg-hero-1{
      
     
      display: flex;
      justify-content: center;  
          padding: 250px;    
      
          background-repeat: no-repeat;
          color: black;
          background-position: 200px;
          background-size: cover; 
          background-size: auto 340px, cover;
          background-position:  center left 30px; 
     
    }
    
  
  
  
  .lg-card {
   


    border:1px solid rgb(250, 247, 247);
    position: relative;
    width: 600px;
    height: 250px;
    background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 130px;
    margin-top: -80px;
    margin-left: 3%;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .lg-card svg {
    
    width: 100px;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .lg-card:hover {
    transform: rotate(-5deg) scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  
  .ab-about {
    font-size: 30px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    text-align: center;
  }
  .lg-card:hover .lg-card__content {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 1;
  }
  .lg-card-container{
  margin-top: 20px;
    display: flex;
    gap:3rem;
    margin-left: 8%;
  }
  .lg-card__title {
    margin: 0;
    font-size: 25px;
    color: #333;
    font-weight: 700;
  }
  
  .lg-card__description {
    margin: 10px 0 0;
    font-size: 25px;
    color: #777;
    line-height: 1.4;
  }
  
  .lg-card:hover svg {
    scale: 0;
    transform: rotate(-45deg);
  }
  
  .lg-card-lgicon {
    font-size: 5.5rem; /* Adjust the size as needed */
  }
    .lg-button-container {
      margin-left: 0; /* Remove left margin for center alignment */
    }
  
    .lg-learn-more-btn-1 {
      padding: 8px 16px; /* Adjust padding for smaller buttons */
    }
  
  
  .lg-paragraph-our{
    width: calc(50% - 20px); /* Adjust width of each paragraph */
    margin-left: 0px; /* Adjust spacing between paragraphs */
  }
  
  .lg-cta-our{
    width: 730px;
    margin-left: 20px;
    border: none ;
    border-radius: 10px;
    height: 850px;
    background-color: #d4d3d3; 
    border-radius: 40px;
    
    margin-top: -70px;
  }
  .lg-our-img{
  width: 450px;
  margin-left: 110px;
  border-radius: 50px;
  margin-top: 20px;
  animation: moveRight 2s ease-in-out infinite alternate;
  
  }
  @keyframes moveRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50px); /* Adjust this value as needed for the desired distance */
    }
  }
  
  .lg-our-1{
    width: 100%;
   margin-top: 50px;
   font-size: 25px;
   margin-left: 10px;
   text-align: center ;
   
  }
  
  
   
  .lg-our-2{
   
  
    margin-top: -20px;
    margin-right: 120px;
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  
  .lg-our-3{
    font-size: 23px;

    width: 630px;
    margin-left:40px;
    
  }
   
  
  .lg-learn-more-btn-1 {
    width: 100%;
    padding: 10px 20px; 
    margin-left: 10px;
    margin-top: 80px;
    font-size: 20px;
  }
  
  p {
    text-align: left; /* Align the text to the left */
    text-indent: 0; /* Remove any indentation */
  }
  
  
  .lg-car-pn-pm{

    border: 1px none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  
  
  .lg-drive-1{

   font-size: 20px;
   margin-left: 800px;
  
  text-align: center;
  margin-top: -500px;
   
  }
  
  .lg-car-img{
    width: 450px;
    margin-left: 840px;
    border-radius: 50px;
    margin-top: 0px;

  
  
  }
  .lg-paragraph-1{
    width: 500px;
   margin-top: 520px;
    font-size: 30px;
    margin-left: -0px;
    text-align: center ;
   
  }
  .lg-paragraph-2{
    width: 640px;
   
    font-size: 25px;
   
    margin-left: -10px;
  
     
   }
  
   .lg-more-btn-2{
    width: 9%;
    padding: 10px 20px; 
    margin-left: 970px;
    margin-top: 100px;
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }
  
   .lg-paragraph-car {
    
    width: 100%; /* Make paragraphs full width */
    margin-right: 10px;/* Remove right margin */
     margin-top: 00px;
  }
  .lg-paragraph-car{
    margin-top: 00px;
    margin-right: 750px;
    
  }
   
  
    .lg-search-box {
      padding: 10px;
      display: block; /* Reduce padding for smaller screens */
    }
  
  
    .lg-location,
    .lg-arrow-box {
      margin-right: 0; /* Remove right margin for smaller screens */
    }
  
    .lg-location input {
      width: calc(100% - 40px); /* Make inputs full width */
      margin: 10px 0; /* Add spacing between inputs */
    }
  
   
  
    .lg-passenger-btn-group {
      margin-left: 0; /* Remove left margin for center alignment */
    }
  
    .lg-passenger-btn {
      margin-right: 10px; /* Add spacing between buttons */
      margin-bottom: 10px; /* Add spacing between buttons */
    }
  
    .lg-search-btn {
      width: 100%; /* Make button full width */
      margin: 10px 0; /* Add spacing around button */
    }
  
    .lg-paragraph-our {
      width: 100%; /* Make paragraphs full width */
      margin-left: 0px; /* Adjust spacing between paragraphs */
    }
  .lg-want-ps {
    width: 97.5%;
    display: block;
    margin-left: 7px;
   
    
  }
  .lg-end-arrow {
    overflow: hidden;
    color: black;
    margin-left: auto; /* Push the ">" symbol to the right */
    font-size: 20px; /* Increase ">" symbol size */
  }
  .lg-destination {
 
    color: black;
    margin: 0 12px; /* Adjust spacing between destinations and arrows */
   
  }
  .lg-pn-pm-want{
    margin-bottom: 50px;
  }
    .lg-car-pn-pm {
  
     background-image: center;
     
    }
  
   
  .lg-pn-pm-See{

    width: 300px;
    margin-left: 350px;
    font-size: 20px;
  
  }
  
  
  
  
  
  
  .lg-card-container{
    display:block;
    margin-top: 30px;
    
  }
  
  
  

}
@media only screen and (min-width: 769px) and (max-width: 799px) {  

  .home-page{
    overflow: hidden;
  
  }
  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .lg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -180px;
   margin-left: 50px;
     text-align: center;
    
    }
  
  .lg-comes-lion-2{
    width:300%;
    margin-left: -170px;
      font-size: 25px;
     margin-top: -10px;
     
    }
  
  .lg-hero-1{
    
   
    display: flex;
    justify-content: center;  
        padding: 275px;    
    
        background-repeat: no-repeat;
        color: black;
        background-position: 200px;
        background-size: cover; 
        background-size: auto 400px, cover;
        background-position:  center left 0px; 
   
  }
  



.lg-card {
  position: relative;
  width: 700px;
  height: 280px;
  background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 100px;
  margin-top: -50px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card svg {
  width: 100px;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.ab-about {
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: center;
}
.lg-card:hover .lg-card__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}
.lg-card-container{
  display: flex;
  gap:3rem;
  margin-top:40px;
  margin-left: 7%;
}
.lg-card__title {
  margin: 0;
  font-size: 25px;
  color: #333;
  font-weight: 700;
}

.lg-card__description {
  margin: 10px 0 0;
  font-size: 25px;
  color: #777;
  line-height: 1.4;
}

.lg-card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}

.lg-card-lgicon {
  font-size: 5.5rem; /* Adjust the size as needed */
}
  .lg-button-container {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-learn-more-btn-1 {
    padding: 8px 16px; /* Adjust padding for smaller buttons */
  }


.lg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-cta-our{
  width: 770px;
  margin-left: 20px;
  border: none ;
  border-radius: 10px;
  height: 850px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -60px;
}
.lg-our-img{
width: 400px;
margin-left: 160px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.lg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.lg-our-2{

  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.lg-our-3{
  
  width: 700px;
  margin-left:40px;
  
}
 

.lg-learn-more-btn-1 {
  width: 100%;
  padding: 20px 40px; 
  margin-left: 10px;
  margin-top: 100px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.lg-drive-1{
  

 font-size: 20px;
 margin-left: -600px;

text-align: center;
margin-top: -500px;
 
}

.lg-car-img{
  width: 400px;
  margin-left: 870px;
  border-radius: 50px;
  margin-top: -420px;



}
.lg-paragraph-1{
  width: 600px;
 margin-top: 840px;
  font-size: 30px;
  margin-left: 130px;
  text-align: center ;
 
}
.lg-paragraph-2{
  width: 690px;
 
  font-size: 25px;
 
  margin-left: 100px;

   
 }

 .lg-more-btn-2{
  width: 150%;
  padding: 20px 40px; 
  margin-left: -290px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

 .lg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.lg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  .lg-search-box {
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .lg-location,
  .lg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .lg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .lg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .lg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  .lg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-left: 0px; /* Adjust spacing between paragraphs */
  }
.lg-want-ps {
  width: 100%;
  display: block;
 
  
}
.lg-pn-pm-want{
  margin-bottom: 50px;
}
  .lg-car-pn-pm {

   background-image: center;
   
  }

 
.lg-pn-pm-See{
  
  width: 300px;
  margin-left: 480px;
  font-size: 20px;

}






.lg-card-container{
  display:block;
  margin-top: 30px;
  
}


}
@media only screen and (min-width: 800px) and (max-width: 819px) {  

  .home-page{
    overflow: hidden;
  
  }
  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .lg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -180px;
   margin-left: 20px;
     text-align: center;
    
    }
  
  .lg-comes-lion-2{
    width:300%;
    margin-left: -180px;
      font-size: 25px;
     margin-top: -10px;
     
    }
  
  .lg-hero-1{
    
    display: flex;
    justify-content: center;  
        padding: 275px;    
    
        background-repeat: no-repeat;
        color: black;
        background-position: 200px;
        background-size: cover; 
        background-size: auto 380px, cover;
        background-position:  center left 10px; 
   
  }
  



.lg-card {

  position: relative;
  width: 680px;
  height: 230px;
  background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 150px;
  margin-top: -80px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card svg {
  width: 100px;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.ab-about {
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: center;
}
.lg-card:hover .lg-card__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}
.lg-card-container{
width: 660px;
  display: flex;
  gap:3rem;
  margin-left: 7%;
}
.lg-card__title {
  margin: 0;
  font-size: 25px;
  color: #333;
  font-weight: 700;
}

.lg-card__description {
  margin: 10px 0 0;
  font-size: 25px;
  color: #777;
  line-height: 1.4;
}

.lg-card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}

.lg-card-lgicon {
  font-size: 5.5rem; /* Adjust the size as needed */
}
  .lg-button-container {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-learn-more-btn-1 {
    padding: 8px 16px; /* Adjust padding for smaller buttons */
  }


.lg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-cta-our{
  width: 750px;
  margin-left: 25px;
  border: none ;
  border-radius: 10px;
  height: 810px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -100px;
}
.lg-our-img{
width: 400px;
margin-left: 150px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.lg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.lg-our-2{

  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.lg-our-3{
  
  width: 700px;
  margin-left:10px;
  
}
 

.lg-learn-more-btn-1 {
  width: 100%;
  padding: 10px 20px; 
  margin-left: 0px;
  margin-top: 80px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.lg-drive-1{
  

 font-size: 20px;
 margin-left: 680px;

text-align: center;
margin-top: -500px;
 
}

.lg-car-img{
  width: 400px;
  margin-left: 870px;
  border-radius: 50px;
  margin-top: 0px;



}
.lg-paragraph-1{
  width: 600px;
 margin-top: 520px;
  font-size: 30px;
  margin-left: 130px;
  text-align: center ;
 
}
.lg-paragraph-2{
  width: 690px;
 
  font-size: 25px;
 
  margin-left: 90px;

   
 }

 .lg-more-btn-2{
  width: 9%;
  padding: 10px 20px; 
  margin-left: 970px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 0px;
  font-size: 20px;
}

 .lg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.lg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  .lg-search-box {
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .lg-location,
  .lg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .lg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .lg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .lg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  .lg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-left: 0px; /* Adjust spacing between paragraphs */
  }
.lg-want-ps {
  width: 97%;
  display: block;
  margin-left: 10px;
 
  
}
.lg-pn-pm-want{
  margin-bottom: 50px;
}
  .lg-car-pn-pm {

   background-image: center;
   
  }

 
.lg-pn-pm-See{
  
  width: 300px;
  margin-left: 450px;
  font-size: 20px;

}






.lg-card-container{
  display:block;
  margin-top: 30px;
  
}


}
@media only screen and (min-width: 820px) and (max-width: 852px) {  

  .home-page{
    overflow: hidden;
  
  }
  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .lg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -180px;
   margin-left: 30px;
     text-align: center;
    
    }
  
  .lg-comes-lion-2{
    width:300%;
    margin-left: -180px;
      font-size: 25px;
     margin-top: -10px;
     
    }
  
  .lg-hero-1{
    
    display: flex;
    justify-content: center;  
        padding: 275px;    
    
        background-repeat: no-repeat;
        color: black;
        background-position: 200px;
        background-size: cover; 
        background-size: auto 380px, cover;
        background-position:  center left 20px; 
   
  }
  



.lg-card {

  position: relative;
  width: 650px;
  height: 230px;
  background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 150px;
  margin-left: 4%;
  margin-top: -80px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card svg {
  width: 100px;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.ab-about {
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: center;
}
.lg-card:hover .lg-card__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}
.lg-card-container{
width: 660px;
  display: flex;
  gap:3rem;
  margin-left: 7%;
}
.lg-card__title {
  margin: 0;
  font-size: 25px;
  color: #333;
  font-weight: 700;
}

.lg-card__description {
  margin: 10px 0 0;
  font-size: 25px;
  color: #777;
  line-height: 1.4;
}

.lg-card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}

.lg-card-lgicon {
  font-size: 5.5rem; /* Adjust the size as needed */
}
  .lg-button-container {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-learn-more-btn-1 {
    padding: 8px 16px; /* Adjust padding for smaller buttons */
  }


.lg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-cta-our{
  width: 780px;
  margin-left: 20px;
  border: none ;
  border-radius: 10px;
  height: 810px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -100px;
}
.lg-our-img{
width: 400px;
margin-left: 160px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.lg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.lg-our-2{

  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.lg-our-3{
  
  width: 720px;
  margin-left:20px;
  
}
 

.lg-learn-more-btn-1 {
  width: 100%;
  padding: 10px 20px; 
  margin-left: 0px;
  margin-top: 80px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.lg-drive-1{
  

 font-size: 20px;
 margin-left: 650px;

text-align: center;
margin-top: -500px;
 
}

.lg-car-img{
  width: 400px;
  margin-left: 890px;
  border-radius: 50px;
  margin-top: -50px;



}
.lg-paragraph-1{
  width: 600px;
 margin-top: 500px;
  font-size: 30px;
  margin-left: 130px;
  text-align: center ;
 
}
.lg-paragraph-2{
  width: 690px;
 
  font-size: 25px;
 
  margin-left: 90px;

   
 }

 .lg-more-btn-2{
  width: 10%;
  padding: 10px 20px; 
  margin-left: 950px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 0px;
  font-size: 20px;
}

 .lg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.lg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  .lg-search-box {
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .lg-location,
  .lg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .lg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .lg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .lg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  .lg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-right: 0; /* Remove right margin */
  }
.lg-want-ps {
  width: 790px;
  display: block;
  margin-left: 10px;
 
  
}
.lg-pn-pm-want{
  margin-bottom: 50px;
}
  .lg-car-pn-pm {

   background-image: center;
   
  }

 
.lg-pn-pm-See{
  
  width: 300px;
  margin-left: 450px;
  font-size: 20px;

}






.lg-card-container{
  display:block;
  margin-top: 30px;
  
}


}
@media only screen and (min-width: 853px) and (max-width: 883px) {  

  .home-page{
    overflow: hidden;
  
  }
  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .lg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -180px;
   margin-left: 40px;
     text-align: center;
    
    }
  
  .lg-comes-lion-2{
    width:300%;
    margin-left: -170px;
      font-size: 25px;
     margin-top: -10px;
     
    }
  
  .lg-hero-1{
    
    display: flex;
    justify-content: center;  
        padding: 275px;    
    
        background-repeat: no-repeat;
        color: black;
        background-position: 200px;
        background-size: cover; 
        background-size: auto 380px, cover;
        background-position:  center left 30px; 
   
  }
  



.lg-card {

  position: relative;
  width: 650px;
  height: 230px;
  background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 150px;
  margin-left: 5%;
  margin-top: -80px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card svg {
  width: 100px;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.ab-about {
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: center;
}
.lg-card:hover .lg-card__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}
.lg-card-container{
width: 660px;
  display: flex;
  gap:3rem;
  margin-left: 7%;
}
.lg-card__title {
  margin: 0;
  font-size: 25px;
  color: #333;
  font-weight: 700;
}

.lg-card__description {
  margin: 10px 0 0;
  font-size: 25px;
  color: #777;
  line-height: 1.4;
}

.lg-card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}

.lg-card-lgicon {
  font-size: 5.5rem; /* Adjust the size as needed */
}
  .lg-button-container {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-learn-more-btn-1 {
    padding: 8px 16px; /* Adjust padding for smaller buttons */
  }


.lg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-cta-our{
  width: 800px;
  margin-left: 20px;
  border: none ;
  border-radius: 10px;
  height: 810px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -100px;
}
.lg-our-img{
width: 400px;
margin-left: 200px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.lg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.lg-our-2{

  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.lg-our-3{
  
  width: 750px;
  margin-left:20px;
  
}
 

.lg-learn-more-btn-1 {
  width: 100%;
  padding: 10px 20px; 
  margin-left: 0px;
  margin-top: 70px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.lg-drive-1{
  

 font-size: 20px;
 margin-left: -600px;

text-align: center;
margin-top: -500px;
 
}

.lg-car-img{
  width: 400px;
  margin-left: 910px;
  border-radius: 50px;
  margin-top: -370px;



}
.lg-paragraph-1{
  width: 600px;
 margin-top: 790px;
  font-size: 30px;
  margin-left: 130px;
  text-align: center ;
 
}
.lg-paragraph-2{
  width: 740px;
 
  font-size: 25px;
 
  margin-left: 30px;

   
 }

 .lg-more-btn-2{
  width: 90%;
  padding: 10px 20px; 
  margin-left: -270px;
  margin-top: 80px;
  text-align: center;
  margin-bottom: 0px;
  font-size: 20px;
}

 .lg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.lg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  .lg-search-box {
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .lg-location,
  .lg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .lg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .lg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .lg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  .lg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-left: 0px; /* Adjust spacing between paragraphs */
  }
.lg-want-ps {
  width: 820px;
  display: block;
  margin-left: 10px;
 
  
}
.lg-pn-pm-want{
  margin-bottom: 50px;
}
  .lg-car-pn-pm {

   background-image: center;
   
  }

 
.lg-pn-pm-See{
  
  width: 300px;
  margin-left: 450px;
  font-size: 20px;

}






.lg-card-container{
  display:block;
  margin-top: 30px;
  
}


}

@media only screen and (min-width: 884px) and (max-width: 911px) {  
  .home-page{
    overflow: hidden;
  
  }

  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .lg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -190px;
   margin-left: 80px;
     text-align: center;
    
    }
  
  .lg-comes-lion-2{
    width:300%;
    margin-left: -150px;
      font-size: 25px;
     margin-top: -10px;
     
    }
  
  .lg-hero-1{
    
   
    display: flex;
    justify-content: center;  
        padding: 275px;    
    
        background-repeat: no-repeat;
        color: black;
        background-position: 200px;
        background-size: cover; 
        background-size: auto 440px, cover;
        background-position:  center left -5px; 
   
  }
  



.lg-card {
  position: relative;
  width: 750px;
  height: 200px;
  background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 120px;
  margin-top: -50px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card svg {
  width: 100px;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.ab-about {
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: center;
}
.lg-card:hover .lg-card__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}
.lg-card-container{
  display: flex;
  gap:3rem;
  margin-top:40px;
  margin-left: 7%;
}
.lg-card__title {
  margin: 0;
  font-size: 25px;
  color: #333;
  font-weight: 700;
}

.lg-card__description {
  margin: 10px 0 0;
  font-size: 25px;
  color: #777;
  line-height: 1.4;
}

.lg-card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}

.lg-card-lgicon {
  font-size: 5.5rem; /* Adjust the size as needed */
}
  .lg-button-container {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-learn-more-btn-1 {
    padding: 8px 16px; /* Adjust padding for smaller buttons */
  }


.lg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-cta-our{
  width: 840px;
  margin-left: 20px;
  border: none ;
  border-radius: 10px;
  height: 850px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -60px;
}
.lg-our-img{
width: 450px;
margin-left: 170px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.lg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.lg-our-2{

  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.lg-our-3{
  
  width: 750px;
  margin-left:40px;
  
}
 

.lg-learn-more-btn-1 {
  width: 100%;
  padding: 20px 40px; 
  margin-left: 10px;
  margin-top: 60px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.lg-drive-1{
  

 font-size: 20px;
 margin-left: 710px;

text-align: center;
margin-top: -500px;
 
}

.lg-car-img{
  width: 450px;
  margin-left: 890px;
  border-radius: 50px;
  margin-top: -30px;



}
.lg-paragraph-1{
  width: 600px;
 margin-top: 510px;
  font-size: 30px;
  margin-left: 130px;
  text-align: center ;
 
}
.lg-paragraph-2{
  width: 780px;
 
  font-size: 25px;
 
  margin-left: 30px;

   
 }

 .lg-more-btn-2{
  width: 9%;
  padding: 20px 40px; 
  margin-left: 1000px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

 .lg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.lg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  .lg-search-box {
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .lg-location,
  .lg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .lg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .lg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .lg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  .lg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-left: 0px; /* Adjust spacing between paragraphs */
  }
.lg-want-ps {
  width: 98%;
  display: block;
 margin-left: 8px;
  
}
.lg-pn-pm-want{
  margin-bottom: 50px;
}
  .lg-car-pn-pm {

   background-image: center;
   
  }

 
.lg-pn-pm-See{
  
  width: 300px;
  margin-left: 480px;
  font-size: 20px;

}






.lg-card-container{
  display:block;
  margin-top: 30px;
  
}


}
@media only screen and (min-width: 912px) and (max-width: 1023px) {  
  .home-page{
    overflow: hidden;
  
  }

  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .lg-hero-car-1{
    width: 200px;
    font-size: 30px;
     margin-top: -185px;
   margin-left: 50px;
     text-align: center;
    
    }
  
  .lg-comes-lion-2{
    width:300%;
    margin-left: -145px;
      font-size: 25px;
     margin-top: -10px;
     
    }
  
  .lg-hero-1{
    
   
    display: flex;
    justify-content: center;  
        padding: 275px;    
    
        background-repeat: no-repeat;
        color: black;
        background-position: 200px;
        background-size: cover; 
        background-size: auto 440px, cover;
        background-position:  center left 0px; 
   
  }
  



.lg-card {
  position: relative;
  width: 750px;
  height: 200px;
  background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 100px;
  margin-top: -50px;
  margin-left: 2%;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card svg {
  width: 100px;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.ab-about {
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: center;
}
.lg-card:hover .lg-card__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}
.lg-card-container{
  display: flex;
  gap:3rem;
  margin-top:40px;
  margin-left: 7%;
}
.lg-card__title {
  margin: 0;
  font-size: 25px;
  color: #333;
  font-weight: 700;
}

.lg-card__description {
  margin: 10px 0 0;
  font-size: 25px;
  color: #777;
  line-height: 1.4;
}

.lg-card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}

.lg-card-lgicon {
  font-size: 5.5rem; /* Adjust the size as needed */
}
  .lg-button-container {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-learn-more-btn-1 {
    padding: 8px 16px; /* Adjust padding for smaller buttons */
  }


.lg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-cta-our{
  width: 870px;
  margin-left: 20px;
  border: none ;
  border-radius: 10px;
  height: 850px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -60px;
}
.lg-our-img{
width: 450px;
margin-left: 190px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.lg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.lg-our-2{

  margin-top: -20px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.lg-our-3{
  
  width: 750px;
  margin-left:40px;
  
}
 

.lg-learn-more-btn-1 {
  width: 100%;
  padding: 10px 20px; 
  margin-left: 10px;
  margin-top: 70px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.lg-drive-1{
  

 font-size: 20px;
 margin-left: -600px;

text-align: center;
margin-top: -500px;
 
}

.lg-car-img{
  width: 450px;
  margin-left: 890px;
  border-radius: 50px;
  margin-top: -420px;



}
.lg-paragraph-1{
  width: 600px;
 margin-top: 840px;
  font-size: 30px;
  margin-left: 130px;
  text-align: center ;
 
}
.lg-paragraph-2{
  width: 780px;
 
  font-size: 25px;
 
  margin-left: 30px;

   
 }

 .lg-more-btn-2{
  width: 90%;
  padding: 10px 20px; 
  margin-left: -290px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

 .lg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.lg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  .lg-search-box {
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .lg-location,
  .lg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .lg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .lg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .lg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  .lg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-left: 0px; /* Adjust spacing between paragraphs */
  }
.lg-want-ps {
  width: 98%;
  display: block;
 margin-left: 8px;
  
}
.lg-pn-pm-want{
  margin-bottom: 50px;
}
  .lg-car-pn-pm {

   background-image: center;
   
  }

 
.lg-pn-pm-See{
  
  width: 300px;
  margin-left: 480px;
  font-size: 20px;

}






.lg-card-container{
  display:block;
  margin-top: 30px;
  
}


}
@media only screen and (min-width: 1024px) and (max-width: 1024px) {  
  .home-page{
    overflow: hidden;
  
  }

  .navbar {
    padding-top: 80px; /* Increase padding to make room for the fixed header */
  }
  .lg-hero-car-1{
    width: 200px;
    font-size: 27px;
     margin-top: -180px;
   margin-left: 100px;
     text-align: center;
    
    }
  
  .lg-comes-lion-2{
    width:300%;
    margin-left: -80px;
      font-size: 25px;
     margin-top: -15px;
     
    }
  
  .lg-hero-1{
    
   
    display: flex;
    justify-content: center;  
        padding: 275px;    
    
        background-repeat: no-repeat;
        color: black;
        background-position: 200px;
        background-size: cover; 
        background-size: auto 440px, cover;
        background-position:  center left 50px; 
   
  }
  



.lg-card {
  position: relative;
  width: 750px;
  height: 200px;
  background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 100px;
  margin-left: 7%;
  margin-top: -50px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card svg {
  width: 100px;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.lg-card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.ab-about {
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: center;
}
.lg-card:hover .lg-card__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}
.lg-card-container{
  display: flex;
  gap:3rem;
  margin-top:40px;
  margin-left: 7%;
}
.lg-card__title {
  margin: 0;
  font-size: 25px;
  color: #333;
  font-weight: 700;
}

.lg-card__description {
  margin: 10px 0 0;
  font-size: 25px;
  color: #777;
  line-height: 1.4;
}

.lg-card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}

.lg-card-lgicon {
  font-size: 5.5rem; /* Adjust the size as needed */
}
  .lg-button-container {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-learn-more-btn-1 {
    padding: 8px 16px; /* Adjust padding for smaller buttons */
  }


.lg-paragraph-our{
  width: calc(50% - 20px); /* Adjust width of each paragraph */
  margin-left: 0px; /* Adjust spacing between paragraphs */
}

.lg-cta-our{
  width: 940px;
  margin-left: 40px;
  border: none ;
  border-radius: 10px;
  height: 850px;
  background-color: #d4d3d3; 
  border-radius: 40px;
  
  margin-top: -60px;
}
.lg-our-img{
width: 500px;
margin-left: 200px;
border-radius: 50px;
margin-top: 20px;
animation: moveRight 2s ease-in-out infinite alternate;

}
@keyframes moveRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(50px); /* Adjust this value as needed for the desired distance */
  }
}

.lg-our-1{
  width: 100%;
 margin-top: 50px;
 font-size: 24.5px;
 margin-left: 10px;
 text-align: center ;
 
}


 
.lg-our-2{

  margin-top: -30px;
  margin-right: 120px;
  font-size: 30px;
  margin-bottom: 20px;
}


.lg-our-3{
  margin-top: -10px;

  width: 850px;
  margin-left:40px;
  
}
 

.lg-learn-more-btn-1 {
  width: 100%;
  padding: 10px 20px; 
  margin-left: 10px;
  margin-top: 78px;
  font-size: 20px;
}

p {
  text-align: left; /* Align the text to the left */
  text-indent: 0; /* Remove any indentation */
}


.lg-car-pn-pm{

  
  border: 1px none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}


.lg-drive-1{
  

 font-size: 20px;
 margin-left: -600px;

text-align: center;
margin-top: -500px;
 
}

.lg-car-img{
  width: 500px;
  margin-left: 890px;
  border-radius: 50px;
  margin-top: -380px;



}
.lg-paragraph-1{
  width: 600px;
 margin-top: 900px;
  font-size: 30px;
  margin-left: 100px;
  text-align: center ;
 
}
.lg-paragraph-2{
  width: 780px;
 
  font-size: 25px;
 
  margin-left: 30px;

   
 }

 .lg-more-btn-2{
  width: 95%;
  padding: 10px 20px; 
  margin-left: -290px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

 .lg-paragraph-car {
  width: 100%; /* Make paragraphs full width */
  margin-right: 10px;/* Remove right margin */
   margin-top: 00px;
}
.lg-paragraph-car{
  margin-top: 00px;
  margin-right: 750px;
  
}
 

  .lg-search-box {
    padding: 10px;
    display: block; /* Reduce padding for smaller screens */
  }


  .lg-location,
  .lg-arrow-box {
    margin-right: 0; /* Remove right margin for smaller screens */
  }

  .lg-location input {
    width: calc(100% - 40px); /* Make inputs full width */
    margin: 10px 0; /* Add spacing between inputs */
  }

 

  .lg-passenger-btn-group {
    margin-left: 0; /* Remove left margin for center alignment */
  }

  .lg-passenger-btn {
    margin-right: 10px; /* Add spacing between buttons */
    margin-bottom: 10px; /* Add spacing between buttons */
  }

  .lg-search-btn {
    width: 100%; /* Make button full width */
    margin: 10px 0; /* Add spacing around button */
  }

  .lg-paragraph-our {
    width: 100%; /* Make paragraphs full width */
    margin-left: 0px; /* Adjust spacing between paragraphs */
  }
.lg-want-ps {
  width: 98%;
  display: block;
 margin-left: 8px;
  
}
.lg-pn-pm-want{
  margin-bottom: 50px;
}
  .lg-car-pn-pm {

   background-image: center;
   
  }

 
.lg-pn-pm-See{
  
  width: 300px;
  margin-left: 480px;
  font-size: 20px;

}






.lg-card-container{
  display:block;
  margin-top: 30px;
  
}


}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {  
  .home-page{
    overflow: hidden;
  
  }
  .home-page{
    width: 100%;
   }
  
  .lg-hero-1{
    /* background-color: rgb(213, 86, 86); */
   
    display: flex;
    justify-content: center;  
        padding: 395px;    
        background-repeat: no-repeat;
        color: black;
        background-position: 200px;
        background-size: cover; 
        background-size: auto 630px, cover;
        background-position:  center right 0px; 
   
  }
  .lg-hero-car-1{

    width: 40%;
    font-size: 35px;
     margin-top: -290px;
     margin-left: 150px;

     text-align: center;
    
    }
  
  .lg-comes-lion-2{

    width:150%;
    margin-left: -120px;
      font-size: 30px;
     margin-top:-0px;
     
    }
  
   
     /*code*/
     
  
     .lg-card {
      position: relative;
      width: 370px;
      height: 200px;
      background: linear-gradient(-45deg, #7476c1 0%, #0d094b 100%);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-bottom: 40px;
      margin-top: -80px;
   
     
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
   
    .lg-card svg {
      width: 100px;
      fill: #333;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
   
    .lg-card:hover {
      transform: rotate(-5deg) scale(1.1);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
  
    .lg-card__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      opacity: 0;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    .ab-about {
      font-size: 30px;
      font-family: 'Times New Roman', Times, serif;
      font-weight: 700;
      text-align: center;
    }
    .lg-card:hover .lg-card__content {
      transform: translate(-50%, -50%) rotate(0deg);
      opacity: 1;
    }
    .lg-card-container{
      display: flex;
      gap:3rem;
      margin-top:40px;
      margin-left: 3%;
    }
    .lg-card__title {
      margin: 0;
      font-size: 24px;
      color: #333;
      font-weight: 700;
    }
   
    .lg-card__description {
      margin: 10px 0 0;
      font-size: 14px;
      color: #777;
      line-height: 1.4;
    }
   
    .lg-card:hover svg {
      scale: 0;
      transform: rotate(-45deg);
    }
   
    .lg-card-lgicon {
      font-size: 5.5rem; /* Adjust the size as needed */
    }
  
  
  
     
    p {
      text-align: left; /* Align the text to the left */
      text-indent: 0; /* Remove any indentation */
    }
     
     
    .footer {
      padding: 1rem;
      text-align: center;
      background-color: #f0f0f0;
    }
  
  
    .lg-cta-our{
      border: none ;
      border-radius: 10px;
      height: 380px;
      background-color: #ededed; 
      border-radius: 50px;
      margin-bottom: 200px;
    }
  .lg-our-img{
    width: 300px;
    margin-left: 180px;
    border-radius: 50px;
    margin-top: 20px;
    animation: moveRight 2s ease-in-out infinite alternate;
  
  }
  
  @keyframes moveRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50px); /* Adjust this value as needed for the desired distance */
    }
  }
  
  
  
  
    .lg-paragraph-our{
      width: calc(50% - 20px); /* Adjust width of each paragraph */
      margin-left: 0px; /* Adjust spacing between paragraphs */
    }
    .lg-our-1{
      width: 65%;
     margin-top: -280px;
     font-size: 20px;
     margin-left: 750px;
     text-align: center ;
     margin-bottom: -80px;
     
    }
     
    .lg-our-2{
    
      margin-left: 60px;
      font-size: 25px;
      margin-bottom: 20px;
    }
    
     
    .lg-our-3 {
      margin-right:-80px;
      font-size: 20px;
    }
  
  
    
   
     
    .lg-text-center {
      text-align: center;
    }
     
    .lg-button-container {
      display: inline-block;
      margin-left: 350px;
    }
     
     
     
    .lg-learn-more-btn-1 {
      width: 20%;
      display: inline-block;
      background-color: rgb(7, 52, 125); /* Set background color to black */
      color: white; /* Set text color to red */
      padding: 10px 20px;
      border-radius: 50px;
      text-decoration: none;
      margin-top: 80px; /* Adjust margin as needed */
      margin-left: 580px;
    
    }
     
    .lg-paragraph-car{
      width: calc(50% - 20px); /* Adjust width of each paragraph */
      margin-right: 20px; /* Adjust spacing between paragraphs */
    }
  
   
  
    .lg-car-img{
      width: 300px;
      margin-left: 820px;
      border-radius: 50px;
      margin-top: 0px;
    overflow-x: hidden;
    animation: moveRight 2s ease-in-out infinite alternate;
    }
    @keyframes moveRight {
      0% {
          transform: translateX(0);
      }
      100% {
          transform: translateX(50px); /* Adjust this value as needed for the desired distance */
      }
  }
    .lg-car-pn-pm{
      margin-top: -150px;
      margin-bottom: 40px;
      border: 1px none;
    }
    .lg-drive-1{
      width: 5%;
     margin-top: -350px;
     font-size: 20px;
     margin-left: 750px;
     text-align: center ;
     margin-bottom: -80px;
    
     
    }
    .lg-paragraph-1{
      width: 400px;
      margin-top: 100px;
      font-size: 25px;
      margin-left: -600px;
      text-align: center ;
      margin-bottom: 20px;
    }
    .lg-paragraph-2{
      margin-left: -580px;
      font-size: 20px;
      margin-bottom: 10px;
     width: 450px;
   
       
     }
    
     
    .lg-paragraph-car{
      margin-top: 100px;
      margin-right: 750px;
      
    }
     
    
     
     
    .lg-more-btn-2 {
      display: inline-block;
      background-color: rgb(7, 52, 125); /* Set background color to black */
      color: white; /* Set text color to red */
      padding: 10px 20px;
      border-radius: 50px;
      text-decoration: none;
      margin-top: 100px; /* Adjust margin as needed */
     margin-left: 300px;
    }
     
     
     
    .paragraph {
      text-align: center; /* Center-align the paragraph text */
    }
     
    .lg-want-ps {
      display: flex; /* Use Flexbox to allow side-by-side layout */
      align-items: center; /* Align items vertically */
      justify-content: center; /* Center items horizontally */
      padding: 20px; /* Add padding */
      background-color:  #112777ea; /* Set background color to blue */
      background-repeat: no-repeat; /* Ensure the image is not repeated */
      background-position: right; /* Position the image on the right side */
      border-radius: 10px;
  
      color: white; /* Set text color to white for contrast */
    }
     
    .lg-destination-box {
      display: flex;
      align-items: center;
      margin-bottom: 20px; /* Adjust spacing between boxes */
    }
     
  
    
    .lg-box-1 {
      display: flex;
      align-items: center;
      background-color: white; /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 20px;
    }
    .lg-box-2 {
      display: flex;
      align-items: center;
      background-color: white; /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 15px;
    }
    .lg-box-3 {
      display: flex;
      align-items: center;
      background-color: white; /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 10px;
    }
  
    .lg-box-4 {
      display: flex;
      align-items: center;
      background-color: white; /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 20px;
      display: block;
    }
    .lg-box-5 {
      display: flex;
      align-items: center;
      background-color: white; /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 15px;
      display: block;
    }
    .lg-box-6 {
      display: flex;
      align-items: center;
      background-color: white; /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 10px;
      display: block;
    }
    .lg-box-7 {
      display: flex;
      align-items: center;
      background-color: white; /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 20px;
      display: block;
    }
    .lg-box-8 {
      display: flex;
      align-items: center;
      background-color: white; /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 15px;
      display: block;
    }
    .lg-box-9 {
      display: flex;
      align-items: center;
      background-color: white; /* Set background color to blue */
      padding: 20px; /* Increase padding for bigger box */
      border-radius: 8px; /* Increase border-radius for rounded corners */
      margin-right: 10px;
      display: block;
   
      
    }
    
     
    .lg-destination {
      color: black;
      margin: 0 15px; /* Adjust spacing between destinations and arrows */
     
    }
     
    .lg-arrow {
      color: black;
      font-size: 20px; /* Increase arrow size */
    }
     
    .lg-end-arrow {
      color: black;
      margin-left: auto; /* Push the ">" symbol to the right */
      font-size: 20px; /* Increase ">" symbol size */
    }
     
    .lg-pn-pm-want{
      margin-bottom: 200px;
      margin-right: 25px;
      
      
    }
    .lg-pn-pm-See{
      margin-top: 00px;
      color: rgb(27, 106, 156);
       margin-left: 80%; 
      
    }
  

}



