 
* {
  box-sizing: border-box;
  font-family: sans-serif;
}

.lp_background {
  background-image: url('../Images/blueinn.png'); 
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.lp_login-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
 
 
}
 
.lp_login {
  width: 700px; 
  height: 450px;
 
  border-radius: 20px;
  overflow: hidden;
  display: flex;
}
 
.lp_animation {
  width: 50%;
 
  height: 100%;
  background: url(https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExeWkzbHhzeWdsajE1cjJhbm5yZncxNHdoMHYwMjdpN2d3MDh1cTdyZiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o7WIFkzzJiyRNIBbO/giphy.gif) center center no-repeat;
  background-size: cover;
}
.lp-heading {
  margin-top: 50px;
}
.lp_form {
  width: 50%;
  padding: 30px;
  background-color: #001f3f; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}
 
.lp_form h2 {
  color: 074173;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 30px;
}
 
.lp_login h3 {
  color: #FFF;
  text-align: center;
  font-weight: normal;
  font-size: 22px;
  margin-bottom: 30px;
}
 
 
.lp_login h2 {
  color: #FFF;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 30px;
}
 
 
 
.lp_form input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  background: rgba(255,255,255,.5);
  border: 1px solid rgba(255,255,255,.1);
  padding: 0 15px;
  color: 074173;
  border-radius: 5px;
  font-size: 14px;
}
 
.lp_form input:focus {
  border: 1px solid rgba(255,255,255,.8);
  outline: none;
}
 
::-webkit-input-placeholder {
  color: #DDD;
}
 
.login-text {
  text-align: center;
  margin-top: 20px;
  color: #FFF; 
}
 
.login-link {
  color: #1dffdf; 
  text-decoration: none; 
}
 
.login-link:hover {
  text-decoration: underline; 
}
 
.lp_submit {
  margin-top: 50px;
  width: 50%;
  background: #1dffdf;
  color: #444;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  margin-left: 75px;
  transition: 0.5; 
}
 
.lp_submit:hover {
  background-color: #001A4F;
  color: #CCC;
}
 
@media (max-width: 768px) {
  .lp_login {
    width: 100%;
   
    height: auto;
  }
 
  .lp_animation {
    width: 100%;
    height: auto;
  }
 
  .lp_form {
    width: 100%;
  }
 
  .lp_submit {
    width: 70%;
    margin: 10px auto;
  }
}
 