.reports-main {
  width: 100%;
  overflow: auto;
  margin-top: 0px;

}

.reports-reports{   
   text-align: center;
  background-color: #05144c;
  color: #ffffff;
  /* border: 1px solid #f10909;  */

  border: 0px solid #eaebf2;
  width: 78.9%;
  margin-left: 151px;
  margin-top: 170px;
  height: 70px;
}
.reports-reports-re{
padding-top: 15px;
  text-align: center;

}

.reports-table {
  
  width: 85%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
}

.reports-table thead tr {
  background-color: #05144c;
  color: #ffffff;
  text-align: left;
}

.reports-table th,
.reports-table td {
  padding: 12px 30px;
 
}

.reports-table-5 {
  width: 80%;

  border: 1px solid white;
  margin-top: -1px;
  overflow-y: auto;
  max-height: 400px;
  margin-left: 150px;
}



.reports-table thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  /*layanu*/
  border: 0px solid white;

  background-color: #05144c;
  color: #ffffff;
}

.reports-table th,
.reports-table td {
  padding: 10px 30px;
  font-size: 16px;
  text-align: center;
}

.reports-table tbody tr {
  border-bottom: 1px solid #dddddd;
  overflow-y: auto;
  font-size: 13px;
}

.reports-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.reports-table tbody tr:last-of-type {
  border-bottom: 2px solid #f6f8f8;
}

.button-container {
  display: flex;
  flex-direction: row;
}

.button {
  border: none;
  color: white;
  padding: 5px 8px;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  margin: 6px 4px;
  cursor: pointer;
  width: 80px;
  height: 40px;
}

.reports-view {
  background-color: #000080;
  color: white;
  order: 1;
}

th, td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
}

.reports-delete {
  background-color: #f44336;
  order: 2;
}

.reports-status {
  width: 115px;
  background-color: #4287f5;
  order: 3;
}

.update-button {
  background-color: #05144c;
}

.reports-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.reports-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
 
@media only screen and (min-width: 320px) and (max-width: 343px) {  
  .reports-main {
      width: 100%;
      overflow: auto;
   
  
    }
    .reports-reports{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 250px;
     margin-left: 35px;
     margin-top: 130px;
     height: 70px;
   }
   .reports-reports-re{
   padding-top: 15px;
     text-align: center;
   
   }

   .reports-table {
  
    width: 85%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
  }
  .reports-table-5 {
    width: 79%;
    border: 1px solid white;
    margin-top: -1px;
    overflow-y: auto;
    max-height: 200px;
    margin-left: 35px;
  }
  .reports-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 

}
@media only screen and (min-width: 360px) and (max-width: 374px) {  
  .reports-main {
      width: 100%;
      overflow: auto;
   
  
    }
    .reports-reports{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 347px;
     margin-left: 5px;
     margin-top: 130px;
     height: 70px;
   }
   .reports-reports-re{
   padding-top: 15px;
     text-align: center;
   
   }

   .reports-table {
  
    width: 85%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
  }
  .reports-table-5 {
    width: 97%;
    border: 1px solid white;
    margin-top: -1px;
    overflow-y: auto;
    max-height: 450px;
    margin-left: 5px;
  }
  .reports-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 

}
@media only screen and (min-width: 375px) and (max-width: 383px) {  
  .reports-main {
      width: 100%;
      overflow: auto;
   
  
    }
    .reports-reports{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 355px;
     margin-left: 10px;
     margin-top: 130px;
     height: 70px;
   }
   .reports-reports-re{
   padding-top: 15px;
     text-align: center;
   
   }

   .reports-table {
  
    width: 85%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
  }
  .reports-table-5 {
    width: 95%;
    border: 1px solid white;
    margin-top: -1px;
    overflow-y: auto;
    max-height: 450px;
    margin-left: 10px;
  }
  .reports-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 

}
@media only screen and (min-width: 390px) and (max-width: 411px) {  
  .reports-main {
      width: 100%;
      overflow: auto;
   
  
    }
    .reports-reports{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 350px;
     margin-left: 20px;
     margin-top: 130px;
     height: 70px;
   }
   .reports-reports-re{
   padding-top: 15px;
     text-align: center;
   
   }

   .reports-table {
  
    width: 85%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
  }
  .reports-table-5 {
    width: 90%;
    border: 1px solid white;
    margin-top: -1px;
    overflow-y: auto;
    max-height: 450px;
    margin-left: 20px;
  }
  .reports-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 

}
@media only screen and (min-width: 412px) and (max-width: 427px) {  
  .reports-main {
      width: 100%;
      overflow: auto;
   
  
    }
    .reports-reports{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 360px;
     margin-left: 27px;
     margin-top: 130px;
     height: 70px;
   }
   .reports-reports-re{
   padding-top: 15px;
     text-align: center;
   
   }

   .reports-table {
  
    width: 85%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
  }
  .reports-table-5 {
    width: 87%;
    border: 1px solid white;
    margin-top: -1px;
    overflow-y: auto;
    max-height: 450px;
    margin-left: 27px;
  }
  .reports-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 

}
 
@media only screen and (min-width: 428px) and (max-width: 767px) {  
  .reports-main {
      width: 100%;
      overflow: auto;
   
  
    }
    .reports-reports{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 370px;
     margin-left: 27px;
     margin-top: 130px;
     height: 70px;
   }
   .reports-reports-re{
   padding-top: 15px;
     text-align: center;
   
   }

   .reports-table {
  
    width: 85%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
  }
  .reports-table-5 {
    width: 87%;
    border: 1px solid white;
    margin-top: -1px;
    overflow-y: auto;
    max-height: 450px;
    margin-left: 27px;
  }
  .reports-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 

}
@media only screen and (min-width: 768px) and (max-width: 799px) {  
  
  .reports-main {
    width: 100%;
    overflow: auto;
    margin-top: -0px;
  }
  .reports-reports{   
    text-align: center;
   background-color: #05144c;
   color: #ffffff;
   border: 0px solid #eaebf2;
   width: 730px;
   margin-left: 20px;
   margin-top: 150px;
   height: 70px;
 }
 .reports-reports-re{
 padding-top: 20px;
   text-align: center;
 
 }

 .reports-table {

  width: 85%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
}
.reports-table-5 {
  width: 95%;
  border: 1px solid white;
  margin-top: -1px;
  overflow-y: auto;
  max-height: 470px;
  margin-left: 19.5px;
}
.reports-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

}
@media only screen and (min-width: 800px) and (max-width: 819px) {  
  
  .reports-main {
    width: 100%;
    overflow: auto;
    margin-top: -0px;
  }
  .reports-reports{   
    text-align: center;
   background-color: #05144c;
   color: #ffffff;
   border: 0px solid #eaebf2;
   width: 757px;
   margin-left: 20px;
   margin-top: 150px;
   height: 70px;
 }
 .reports-reports-re{
 padding-top: 20px;
   text-align: center;
 
 }

 .reports-table {

  width: 85%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
}
.reports-table-5 {
  width: 95%;
  border: 1px solid white;
  margin-top: -1px;
  overflow-y: auto;
  max-height: 470px;
  margin-left: 19.5px;
}
.reports-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

}

@media only screen and (min-width: 820px) and (max-width: 833px) {  
  
  .reports-main {
    width: 100%;
    overflow: auto;
    margin-top: -0px;
  }
  .reports-reports{   
    text-align: center;
   background-color: #05144c;
   color: #ffffff;
   border: 0px solid #eaebf2;
   width: 780px;
   margin-left: 20px;
   margin-top: 150px;
   height: 70px;
 }
 .reports-reports-re{
 padding-top: 20px;
   text-align: center;
 
 }

 .reports-table {

  width: 85%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
}
.reports-table-5 {
  width: 95%;
  border: 1px solid white;
  margin-top: -1px;
  overflow-y: auto;
  max-height: 470px;
  margin-left: 19.5px;
}
.reports-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

}
  @media only screen and (min-width: 834px) and (max-width: 883px) {  
  
    .reports-main {
      width: 100%;
      overflow: auto;
      margin-top: -0px;
    }
    .reports-reports{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 790px;
     margin-left: 20px;
     margin-top: 150px;
     height: 70px;
   }
   .reports-reports-re{
   padding-top: 20px;
     text-align: center;
   
   }

   .reports-table {
  
    width: 85%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
  }
  .reports-table-5 {
    width: 95%;
    border: 1px solid white;
    margin-top: -1px;
    overflow-y: auto;
    max-height: 470px;
    margin-left: 19.5px;
  }
  .reports-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  }
  @media only screen and (min-width: 884px) and (max-width: 1279px) {  
  
    .reports-main {
      width: 100%;
      overflow: auto;
      margin-top: -0px;
    }
    .reports-reports{   
      text-align: center;
     background-color: #05144c;
     color: #ffffff;
     border: 0px solid #eaebf2;
     width: 835px;
     margin-left: 20px;
     margin-top: 150px;
     height: 70px;
   }
   .reports-reports-re{
   padding-top: 20px;
     text-align: center;
   
   }

   .reports-table {
  
    width: 85%;
    overflow-x: auto;
    border-collapse: collapse;
    margin: auto;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
  }
  .reports-table-5 {
    width: 95%;
    border: 1px solid white;
    margin-top: -1px;
    overflow-y: auto;
    max-height: 470px;
    margin-left: 19.5px;
  }
  .reports-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  }
  @media only screen and (min-width: 1280px) and (max-width: 1440px) {  
    .reports-main {
      width: 100%;
      overflow: auto;
      margin-top: 0px;
    }
    
    .reports-reports{   
       text-align: center;
      background-color: #05144c;
      color: #ffffff;
      border: 0px solid #eaebf2;
      width: 1025px;
      margin-left: 151px;
      margin-top: 170px;
      height: 70px;
    }
    .reports-reports-re{
    padding-top: 15px;
      text-align: center;
    
    }
    
    .reports-table {
      
      width: 85%;
      overflow-x: auto;
      border-collapse: collapse;
      margin: auto;
      font-size: 0.9em;
      font-family: sans-serif;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      white-space: nowrap;
    }
    
    .reports-table thead tr {
      background-color: #05144c;
      color: #ffffff;
      text-align: left;
    }
    
    .reports-table th,
    .reports-table td {
      padding: 12px 30px;
     
    }
    
    .reports-table-5 {
      width: 80%;
      border: 1px solid white;
      margin-top: -1px;
      overflow-y: auto;
      max-height: 400px;
      margin-left: 150px;
    }
    
    
    
    .reports-table thead th {
      position: sticky;
      top: 0;
      z-index: 2;
      /*layanu*/
      border: 0px solid white;
    
      background-color: #05144c;
      color: #ffffff;
    }
    
    .reports-table th,
    .reports-table td {
      padding: 10px 30px;
      font-size: 16px;
      text-align: center;
    }
    
    .reports-table tbody tr {
      border-bottom: 1px solid #dddddd;
      overflow-y: auto;
      font-size: 13px;
    }
    
    .reports-table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }
    
    .reports-table tbody tr:last-of-type {
      border-bottom: 2px solid #f6f8f8;
    }
    
    .button-container {
      display: flex;
      flex-direction: row;
    }
    
    .button {
      border: none;
      color: white;
      padding: 5px 8px;
      text-decoration: none;
      text-align: center;
      border-radius: 5px;
      font-size: 16px;
      margin: 6px 4px;
      cursor: pointer;
      width: 80px;
      height: 40px;
    }
    
    .reports-view {
      background-color: #000080;
      color: white;
      order: 1;
    }
    
    th, td {
      padding: 8px 12px;
      border: 1px solid #ddd;
      text-align: center;
      vertical-align: middle;
    }
    
    .reports-delete {
      background-color: #f44336;
      order: 2;
    }
    
    .reports-status {
      width: 115px;
      background-color: #4287f5;
      order: 3;
    }
    
    .update-button {
      background-color: #05144c;
    }
    
    .reports-popup {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .reports-popup-content {
      background-color: #fff;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
     
  }

