body.l-body {
    font-family: 'Poppins', Arial, sans-serif;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    margin-top: 0px;
  }
  /* .l-prev-navbar{
    padding-bottom: 1000px;
  } */
  .l-card {
    width: 90%;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  .SA-l-step-container-step{
    padding-top: 190px;
  }
 
  .SA-l-step-container {
 
    text-align: center;
    border-radius: 8px;
    width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding-bottom: 20px;  
    /* align-items: center; */
    margin-left: 480px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
 
  .SA-l-step-container-step p {
    /* border: 1px solid black; */
    /* align-items: center; */
    margin-left: 50px;
 
    /* text-align: left;  */
    text-indent: 0; /* Remove any indentation */
  }
has context menu
  
  .l-left-half,
  .l-right-half {
    width: 50%;
    box-sizing: border-box;
  }
  
  .l-next-button {
    padding: 10px 10px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
    width: 100px; /* Set fixed width */
    height: 50px;
    transition: background-color 0.3s ease;
  }
  
  .l-next-button:hover {
    background-color: #0056b3;
  }
  
  .l-input-container1 {
    width: 70%; /* Adjust width as needed */
    padding: 10px;
    margin-top: -15px;
    border-radius: 8px;
  }
  
  .l-step-container1 {
    text-align: center;
    width: 50%;
    height: 100%;
    margin-left: 30px;
  height: 580px;
margin-left: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: hsl(0, 0%, 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  
  .l-step-container2 {
    text-align: center;
    width: 70%;
    height: 100%;
    margin-left: 230px;
    margin-bottom: 45px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: hsl(0, 0%, 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .l-step-container.case-2 input[type="text"] {
    width: 100%; /* Adjust width as needed */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* Add any additional styling you require */
  }
  .l-step-container-step{
  padding-top: 190px;
  }
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
   
  .l-step-container {

    text-align: center;
    border-radius: 8px;
    width: 1100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
padding-bottom: 20px;  
  align-items: center;
    margin-left: 220px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .l-step-container.case-4 {
    width: auto;
    padding: 20px;
  }
  
  .maprelated-input {
    padding-top: 150px;
    display: flex;
    gap: 15px;
  }
  
  .onlymap {
    height: 580px;
    width: 50%;
    border: 1px solid red;
    margin-right: 10px;
  }
  
  .map-containers {
    width: 100%;
    height: 100%;
  }
  
  .l-step-container h2 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem; /* Adjust the font size as per your preference */
    font-weight: bold;
  }
  
  .l-step-container1 h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem; /* Adjust the font size as per your preference */
    font-weight: bold;
  }
  
  .l-step-container2 h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem; /* Adjust the font size as per your preference */
    font-weight: bold;
  }
  
  .l-step-container input[type="text"],
  .l-step-container input[type="date"],
  .l-step-container input[type="time"],
  .l-step-container input[type="number"] {
    width: 50%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 20px;
  }
  
  .l-step-container1 input[type="text"] {
    width: 80%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    position: relative;
  }
  
  .l-step-container1 input[type="text"]::after {
    content: '▼';
    font-size: 1.2rem;
    color: #aaa;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  .l-step-container.case-4 input[type="text"],
  .l-step-container.case-4 input[type="date"],
  .l-step-container.case-4 input[type="time"],
  .l-step-container.case-4 input[type="number"] {
    width: 100%; /* Adjust width as needed */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .l-add-city-input {
    width: 100%; /* Adjust width as needed */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .l-add-button {
    padding: 10px 20px;
    margin: 5px;
    margin-left: 35px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .l-add-button:hover {
    background-color: #0056b3;
  }
  
  /* Case 3 */
  .l-step-container.case-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .l-checkbox-container {
    margin-top: 20px;
  }
  
  /* Case 4 */
  .l-step-container.case-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .l-add-city-button {
    margin-bottom: 20px;
  }
  
  .l-checkbox-container label {
    display: inline-block;
    margin-right: 50px; /* Adjust margin as needed */
    font-size: 2.1rem; /* Increase font size */
  }
  
  .l-checkbox-container input[type="checkbox"] {
    width: 20px; /* Increase checkbox size */
    height: 20px; /* Increase checkbox size */
    margin-right: 10px;
  }
  
  .l-passenger-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .l-passenger-container input[type="number"] {
    width: 60px;
    text-align: center;
  }
  
  .l-button,
  .l-prev-button,
  .l-next-button,
  .l-submit-button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
    width: 100px; /* Set fixed width */
    height: 50px;
    transition: background-color 0.3s ease;
  }
  
  .l-next-button1 {
    padding: 10px 20px;
    margin-left: 750px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
    width: 100px; /* Set fixed width */
    height: 50px;
    transition: background-color 0.3s ease;
  }
  
  .l-decrement-button,
  .l-increment-button {
    padding: 0px; /* Adjust padding as needed */
    margin: 5px;
    border: none;
    width: 30px; /* Set equal width and height for round shape */
    height: 30px;
    border-radius: 50%; /* Make buttons perfectly round */
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .l-button:disabled,
  .l-prev-button:disabled,
  .l-next-button:disabled,
  .l-submit-button:disabled,
  .l-increment-button:disabled,
  .l-decrement-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .l-button:hover:not(:disabled),
  .l-prev-button:hover:not(:disabled),
  .l-next-button:hover:not(:disabled),
  .l-submit-button:hover:not(:disabled),
  .l-add-city-button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .l-increment-button {
    background-color: #28a745;
  }
  
  .l-increment-button:hover {
    background-color: #218838;
  }
  
  .l-decrement-button {
    background-color: #dc3545;
  }
  
  .l-decrement-button:hover {
    background-color: #c82333;
  }
  
  .l-suggestions-dropdown {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 135px;
    overflow-y: auto;
    width: 400px; /* Adjust width to match input field */
    z-index: 1000;
    left: 12%;
    margin-top: -20px;
  }
  
  .l-suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .l-suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .l-suggestions-dropdown1 {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
    width: 45%; /* Adjust width to match input field */
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    margin-top: -10px;
  }
  
  .l-suggestion-item1 {
    padding: 10px;
    cursor: pointer;
  }
  
  .l-suggestion-item1:hover {
    background-color: #f0f0f0;
  }
  
  .l-via-input-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .l-via-input-container input[type="text"] {
    margin-bottom: 10px;
  }
  
  
  .l-alert {
    position: fixed;
    top: 200px;
    right: 20px;
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  @media only screen and (min-width: 320px) and (max-width: 343px) {
    /* Styling for map-related inputs */
    body.l-body {
      font-family: 'Poppins', Arial, sans-serif;
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      margin-right: 20px;
      margin-top: 0px;
    }
    .SA-l-step-container {
 
      text-align: center;
      width: 300px;
      padding-top: 0px;
      margin-top: -50px;
      margin-left: 10px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }
    .l-step-container.case1 {
      flex-direction: column;
    }
    .l-step-container1 h2 {
      margin-top: 10px;
      font-family: 'Poppins', sans-serif;
      font-size: 1.5rem; /* Adjust the font size as per your preference */
      font-weight: bold;
    }
    .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 15px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 1px;
      position: relative;
    }
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      margin-bottom: 25px;
      transition: background-color 0.3s ease;
    }
    .maprelated-input {
      display: block;
      gap: 15px;
    }
    .l-suggestions-dropdown {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 100px;
      overflow-y: auto;
      width: 165px; /* Adjust width to match input field */
      z-index: 1000;
      left: 24%;
      margin-top: -10px;
    }
    .onlymap {
      margin-bottom: 20px;
      margin-top: 20px;
margin-left: 20px;
      height: 580px;
      width: 96%;
      border: 1px solid rgb(235, 8, 8);
    }
    .l-step-container {
      text-align: center;
      margin-top: 55px;
      border-radius: 8px;
      width: 280px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      align-items: center;
      margin-left: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .l-input-container1.case1 {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between sections */
    }
  
    .onlymap.case1 {
      display: block;

      width: 100%;
    }
  
    .l-step-container.case1 input[type="text"] {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between inputs */
    }
  
    .l-step-container.case1 h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button.case1 {
      margin-left: 0;
    }
  
    .l-checkbox-container.case1 label {
      margin-right: 0;
      font-size: 1.8rem;
    }
  
    .l-step-container1 {
      width: auto;
      margin-left: 5%;
    }
  
    .l-step-container2 {
      width: 90%;
      margin-left: 5%;
    }
  
    .l-input-container1 input[type="text"] {
      width: 90%;
    }
  
    .l-step-container.case-2 input[type="text"] {
      width: 90%;
      margin-left: 10px;
    }
  
    .l-step-container h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button {
      margin-left: 0;
    }
  
    .l-checkbox-container label {
      margin-right: 30px;
      font-size: 1.8rem;
    }
  
  }
  @media only screen and (min-width: 360px) and (max-width: 374px) {
    /* Styling for map-related inputs */
    body.l-body {
      font-family: 'Poppins', Arial, sans-serif;
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      margin-right: 20px;
      margin-top: 0px;
    }
    .SA-l-step-container {
 
      text-align: center;
      width: 350px;
      padding-top: 0px;
      margin-top: -50px;
      margin-left: 10px;
      padding-bottom: 0px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }
    .l-step-container.case1 {
      flex-direction: column;
    }
    .l-step-container1 h2 {
      margin-top: 10px;
      font-family: 'Poppins', sans-serif;
      font-size: 1.5rem; /* Adjust the font size as per your preference */
      font-weight: bold;
    }
    .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 15px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 1px;
      position: relative;
    }
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      margin-bottom: 25px;
      transition: background-color 0.3s ease;
    }
    .maprelated-input {
      display: block;
      gap: 15px;
    }
    .l-suggestions-dropdown {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 100px;
      overflow-y: auto;
      width: 185px; /* Adjust width to match input field */
      z-index: 1000;
      left: 24%;
      margin-top: -10px;
    }
    .onlymap {
      margin-bottom: 20px;
      margin-top: 20px;
margin-left: 20px;
      height: 580px;
      width: 96%;
      border: 1px solid rgb(235, 8, 8);
    }
    .l-step-container {
      text-align: center;
      margin-top: 55px;
      border-radius: 8px;
      width: 350px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      align-items: center;
      margin-left: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .l-input-container1.case1 {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between sections */
    }
  
    .onlymap.case1 {
      display: block;

      width: 100%;
    }
  
    .l-step-container.case1 input[type="text"] {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between inputs */
    }
  
    .l-step-container.case1 h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button.case1 {
      margin-left: 0;
    }
  
    .l-checkbox-container.case1 label {
      margin-right: 0;
      font-size: 1.8rem;
    }
  
    .l-step-container1 {
      width: auto;
      margin-left: 5%;
    }
  
    .l-step-container2 {
      width: 90%;
      margin-left: 5%;
    }
  
    .l-input-container1 input[type="text"] {
      width: 90%;
    }
  
    .l-step-container.case-2 input[type="text"] {
      width: 90%;
      margin-left: 10px;
    }
  
    .l-step-container h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button {
      margin-left: 0;
    }
  
    .l-checkbox-container label {
      margin-right: 30px;
      font-size: 1.8rem;
    }
  
  }
  @media only screen and (min-width: 375px) and (max-width: 383px) {
    /* Styling for map-related inputs */
    body.l-body {
      font-family: 'Poppins', Arial, sans-serif;
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      margin-right: 20px;
      margin-top: 0px;
    }
    .l-step-container.case1 {
      flex-direction: column;
    }
    .SA-l-step-container {
 
      text-align: center;
      width: 350px;
      padding-top: 0px;
      margin-top: -50px;
      margin-left: 15px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }
    .l-step-container1 h2 {
      margin-top: 10px;
      font-family: 'Poppins', sans-serif;
      font-size: 1.5rem; /* Adjust the font size as per your preference */
      font-weight: bold;
    }
    .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 15px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 1px;
      position: relative;
    }
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      margin-bottom: 25px;
      transition: background-color 0.3s ease;
    }
    .maprelated-input {
      display: block;
      gap: 15px;
    }
    .l-suggestions-dropdown {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 100px;
      overflow-y: auto;
      width: 195px; /* Adjust width to match input field */
      z-index: 1000;
      left: 24%;
      margin-top: -10px;
    }
    .onlymap {
      margin-bottom: 20px;
      margin-top: 20px;
margin-left: 20px;
      height: 580px;
      width: 96%;
      border: 1px solid rgb(235, 8, 8);
    }
    .l-step-container {
      text-align: center;
      margin-top: 55px;
      border-radius: 8px;
      width: 350px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      align-items: center;
      margin-left: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .l-input-container1.case1 {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between sections */
    }
  
    .onlymap.case1 {
      display: block;

      width: 100%;
    }
  
    .l-step-container.case1 input[type="text"] {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between inputs */
    }
  
    .l-step-container.case1 h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button.case1 {
      margin-left: 0;
    }
  
    .l-checkbox-container.case1 label {
      margin-right: 0;
      font-size: 1.8rem;
    }
  
    .l-step-container1 {
      width: auto;
      margin-left: 5%;
    }
  
    .l-step-container2 {
      width: 90%;
      margin-left: 5%;
    }
  
    .l-input-container1 input[type="text"] {
      width: 90%;
    }
  
    .l-step-container.case-2 input[type="text"] {
      width: 90%;
      margin-left: 10px;
    }
  
    .l-step-container h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button {
      margin-left: 0;
    }
  
    .l-checkbox-container label {
      margin-right: 30px;
      font-size: 1.8rem;
    }
  
  }
  @media only screen and (min-width: 390px) and (max-width: 411px) {
    /* Styling for map-related inputs */
    body.l-body {
      font-family: 'Poppins', Arial, sans-serif;
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      margin-right: 20px;
      margin-top: 0px;
    }
    .SA-l-step-container {
 
      text-align: center;
      width: 350px;
      padding-top: 0px;
      margin-top: -50px;
      margin-left: 20px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }
    .l-step-container.case1 {
      flex-direction: column;
    }
    .l-step-container1 h2 {
      margin-top: 10px;
      font-family: 'Poppins', sans-serif;
      font-size: 1.5rem; /* Adjust the font size as per your preference */
      font-weight: bold;
    }
    .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 15px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 1px;
      position: relative;
    }
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      margin-bottom: 25px;
      transition: background-color 0.3s ease;
    }
    .maprelated-input {
      display: block;
      gap: 15px;
    }
    .l-suggestions-dropdown {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 100px;
      overflow-y: auto;
      width: 200px; /* Adjust width to match input field */
      z-index: 1000;
      left: 24%;
      margin-top: -10px;
    }
    .onlymap {
      margin-bottom: 20px;

      margin-top: 20px;
margin-left: 20px;
      height: 580px;
      width: 96%;
      border: 1px solid rgb(235, 8, 8);
    }
    .l-step-container {
      text-align: center;
      margin-top: 55px;
      border-radius: 8px;
      width: 350px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      align-items: center;
      margin-left: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .l-input-container1.case1 {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between sections */
    }
  
    .onlymap.case1 {
      display: block;

      width: 100%;
    }
  
    .l-step-container.case1 input[type="text"] {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between inputs */
    }
  
    .l-step-container.case1 h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button.case1 {
      margin-left: 0;
    }
  
    .l-checkbox-container.case1 label {
      margin-right: 0;
      font-size: 1.8rem;
    }
  
    .l-step-container1 {
      width: auto;
      margin-left: 5%;
    }
  
    .l-step-container2 {
      width: 90%;
      margin-left: 5%;
    }
  
    .l-input-container1 input[type="text"] {
      width: 90%;
    }
  
    .l-step-container.case-2 input[type="text"] {
      width: 90%;
      margin-left: 10px;
    }
  
    .l-step-container h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button {
      margin-left: 0;
    }
  
    .l-checkbox-container label {
      margin-right: 30px;
      font-size: 1.8rem;
    }
  
  }
  @media only screen and (min-width: 412px) and (max-width: 427px) {
    /* Styling for map-related inputs */
    body.l-body {
      font-family: 'Poppins', Arial, sans-serif;
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      margin-right: 20px;
      margin-top: 0px;
    }
    .l-step-container.case1 {
      flex-direction: column;
    }
    .SA-l-step-container {
 
      text-align: center;
      width: 350px;
      padding-top: 0px;
      margin-top: -50px;
      margin-left: 30px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }
    .l-step-container1 h2 {
      margin-top: 10px;
      font-family: 'Poppins', sans-serif;
      font-size: 1.5rem; /* Adjust the font size as per your preference */
      font-weight: bold;
    }
    .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 15px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 1px;
      position: relative;
    }
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      margin-bottom: 25px;
      transition: background-color 0.3s ease;
    }
    .maprelated-input {
      display: block;
      gap: 15px;
    }
    .l-suggestions-dropdown {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 100px;
      overflow-y: auto;
      width: 215px; /* Adjust width to match input field */
      z-index: 1000;
      left: 24%;
      margin-top: -10px;
    }
    .onlymap {
      margin-bottom: 20px;

      margin-top: 20px;
margin-left: 20px;
      height: 580px;
      width: 96%;
      border: 1px solid rgb(235, 8, 8);
    }
    .l-step-container {
      text-align: center;
      margin-top: 55px;
      border-radius: 8px;
      width: 350px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      align-items: center;
      margin-left: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .l-input-container1.case1 {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between sections */
    }
  
    .onlymap.case1 {
      display: block;

      width: 100%;
    }
  
    .l-step-container.case1 input[type="text"] {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between inputs */
    }
  
    .l-step-container.case1 h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button.case1 {
      margin-left: 0;
    }
  
    .l-checkbox-container.case1 label {
      margin-right: 0;
      font-size: 1.8rem;
    }
  
    .l-step-container1 {
      width: auto;
      margin-left: 5%;
    }
  
    .l-step-container2 {
      width: 90%;
      margin-left: 5%;
    }
  
    .l-input-container1 input[type="text"] {
      width: 90%;
    }
  
    .l-step-container.case-2 input[type="text"] {
      width: 90%;
      margin-left: 10px;
    }
  
    .l-step-container h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button {
      margin-left: 0;
    }
  
    .l-checkbox-container label {
      margin-right: 30px;
      font-size: 1.8rem;
    }
  
  }
  
  /* Media queries for Mobile view */
  @media only screen and (min-width: 428px) and (max-width: 767px) {
    /* Styling for map-related inputs */
    body.l-body {
      font-family: 'Poppins', Arial, sans-serif;
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      margin-right: 20px;
      margin-top: 0px;
    }
    .l-step-container.case1 {
      flex-direction: column;
    }
    .SA-l-step-container {
 
      text-align: center;
      width: 350px;
      padding-top: 0px;
      margin-top: -50px;
      margin-left: 40px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }
    .l-step-container1 h2 {
      margin-top: 10px;
      font-family: 'Poppins', sans-serif;
      font-size: 1.5rem; /* Adjust the font size as per your preference */
      font-weight: bold;
    }
    .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 15px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 1px;
      position: relative;
    }
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      margin-bottom: 25px;
      transition: background-color 0.3s ease;
    }
    .maprelated-input {
      display: block;
      gap: 15px;
    }
    .l-suggestions-dropdown {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 100px;
      overflow-y: auto;
      width: 223px; /* Adjust width to match input field */
      z-index: 1000;
      left: 24%;
      margin-top: -10px;
    }
    .onlymap {
      margin-bottom: 20px;

      margin-top: 20px;
margin-left: 20px;
      height: 580px;
      width: 96%;
      border: 1px solid rgb(235, 8, 8);
    }
    .l-step-container {
      text-align: center;
      margin-top: 55px;
      border-radius: 8px;
      width: 350px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      align-items: center;
      margin-left: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .l-input-container1.case1 {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between sections */
    }
  
    .onlymap.case1 {
      display: block;

      width: 100%;
    }
  
    .l-step-container.case1 input[type="text"] {
      width: 100%;
      margin-left: 0;
      margin-bottom: 10px; /* Optional: adjust spacing between inputs */
    }
  
    .l-step-container.case1 h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button.case1 {
      margin-left: 0;
    }
  
    .l-checkbox-container.case1 label {
      margin-right: 0;
      font-size: 1.8rem;
    }
  
    .l-step-container1 {
      width: auto;
      margin-left: 5%;
    }
  
    .l-step-container2 {
      width: 90%;
      margin-left: 5%;
    }
  
    .l-input-container1 input[type="text"] {
      width: 90%;
    }
  
    .l-step-container.case-2 input[type="text"] {
      width: 90%;
      margin-left: 10px;
    }
  
    .l-step-container h2 {
      font-size: 1.8rem;
    }
  
    .l-add-button {
      margin-left: 0;
    }
  
    .l-checkbox-container label {
      margin-right: 30px;
      font-size: 1.8rem;
    }
  
  }
  @media only screen and (min-width: 768px) and (max-width: 799px) {
  
    .l-step-container.case1 {
      display: flex;
      flex-direction: column;
    }
    .maprelated-input {
      display: block;
      gap: 15px;
    }
  
    .l-input-container1.case1,
    .onlymap.case1 {
      width: 100%;
      margin-bottom: 10px; /* Optional: adjust spacing between sections */
    }
  
    .l-step-container.case1 input[type="text"] {
      width: 100%;
      margin-bottom: 10px; /* Optional: adjust spacing between inputs */
    }
  
    .l-step-container.case1 h2 {
      font-size: 1.8rem; /* Adjust font size as needed */
    }
  
    .l-checkbox-container.case1 label {
      font-size: 1.8rem; /* Adjust font size as needed */
    }
    .SA-l-step-container {
 
      text-align: center;
      width: 550px;
      padding-top: 0px;
      /* margin-top: -100px; */
      margin-left: 110px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }

    .l-suggestions-dropdown {
      /* position: absolute; */
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 130px;
      /* overflow-y: auto; */
      width: 430px; /* Adjust width to match input field */
      z-index: 1000;
      margin-left: -30px;
      left: 26%;
      margin-top: -20px;
    }
    .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      position: relative;
    }
    
    
    .l-step-container {
      width: 80%;
      margin-left: 10%;
    }
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      margin-bottom: 30px;
      transition: background-color 0.3s ease;
    }
  

    
    .onlymap {
      margin-bottom: 20px;
      margin-top: 20px;
margin-left: 20px;
      height: 580px;
      width: 96%;
      border: 1px solid rgb(235, 8, 8);
    }
  
    .l-input-container1 {
      width: 80%;
      margin-left: 0%;
    }
  
    .l-step-container1 {
      width: 80%;
      margin-left: 10%;
    }
  
    .l-step-container2 {
      width: 80%;
      margin-left: 10%;
    }
  
    .l-input-container1 input[type="text"] {
      width: 90%;
    }
  
    .l-step-container.case-2 input[type="text"] {
      width: 90%;
      margin-left: 10px;
    }
  
    .l-step-container h2 {
      font-size: 1.9rem;
    }
  
    .l-checkbox-container label {
      margin-right: 40px;
      font-size: 1.9rem;
    }
   
  }
  @media only screen and (min-width: 800px) and (max-width: 819px) {
  
    .l-step-container.case1 {
      display: flex;
      flex-direction: column;
    }
    .maprelated-input {
      display: block;
      gap: 15px;
    }

  
    .l-input-container1.case1,
    .onlymap.case1 {
      width: 100%;
      margin-bottom: 10px; /* Optional: adjust spacing between sections */
    }
   .SA-l-step-container {
 
      text-align: center;
      width: 550px;
      padding-top: 0px;
      /* margin-top: -100px; */
      margin-left: 120px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }

    .l-step-container.case1 input[type="text"] {
      width: 100%;
      margin-bottom: 10px; /* Optional: adjust spacing between inputs */
    }
  
    .l-step-container.case1 h2 {
      font-size: 1.8rem; /* Adjust font size as needed */
    }
  
    .l-checkbox-container.case1 label {
      font-size: 1.8rem; /* Adjust font size as needed */
    }

    .l-suggestions-dropdown {
      /* position: absolute; */
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 150px;
      /* overflow-y: auto; */
      width: 445px; /* Adjust width to match input field */
      z-index: 1000;
      margin-left: -30px;
      left: 26%;
      margin-top: -20px;
    }
    .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      position: relative;
    }
    
    
    .l-step-container {
      width: 80%;
      margin-left: 10%;
    }
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      margin-bottom: 30px;
      transition: background-color 0.3s ease;
    }
  

    
    .onlymap {
      margin-bottom: 20px;
      margin-top: 20px;
margin-left: 20px;
      height: 580px;
      width: 96%;
      border: 1px solid rgb(235, 8, 8);
    }
  
    .l-input-container1 {
      width: 80%;
      margin-left: 0%;
    }
  
    .l-step-container1 {
      width: 80%;
      margin-left: 10%;
    }
  
    .l-step-container2 {
      width: 80%;
      margin-left: 10%;
    }
  
    .l-input-container1 input[type="text"] {
      width: 90%;
    }
  
    .l-step-container.case-2 input[type="text"] {
      width: 90%;
      margin-left: 10px;
    }
  
    .l-step-container h2 {
      font-size: 1.9rem;
    }
  
    .l-checkbox-container label {
      margin-right: 40px;
      font-size: 1.9rem;
    }
   
  }
  
  /* Media queries for Tablet view */
  @media only screen and (min-width: 820px) and (max-width: 833px) {
  
    .l-step-container.case1 {
      display: flex;
      flex-direction: column;
    }
    .maprelated-input {
      display: block;
      gap: 15px;
    }
  
    .l-input-container1.case1,
    .onlymap.case1 {
      width: 100%;
      margin-bottom: 10px; /* Optional: adjust spacing between sections */
    }
  
    .SA-l-step-container {
 
      text-align: center;
      width: 550px;
      padding-top: 0px;
      /* margin-top: -100px; */
      margin-left: 140px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }
    .l-step-container.case1 input[type="text"] {
      width: 100%;
      margin-bottom: 10px; /* Optional: adjust spacing between inputs */
    }
  
    .l-step-container.case1 h2 {
      font-size: 1.8rem; /* Adjust font size as needed */
    }
  
    .l-checkbox-container.case1 label {
      font-size: 1.8rem; /* Adjust font size as needed */
    }

    .l-suggestions-dropdown {
      /* position: absolute; */
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 150px;
      /* overflow-y: auto; */
      width: 450px; /* Adjust width to match input field */
      z-index: 1000;
      margin-left: -30px;
      left: 26%;
      margin-top: -20px;
    }
    .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      position: relative;
    }
    
    
    .l-step-container {
      width: 80%;
      margin-left: 10%;
    }
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      margin-bottom: 30px;
      transition: background-color 0.3s ease;
    }
  

    
    .onlymap {
      margin-bottom: 20px;
      margin-top: 20px;
margin-left: 20px;
      height: 580px;
      width: 96%;
      border: 1px solid rgb(235, 8, 8);
    }
  
    .l-input-container1 {
      width: 80%;
      margin-left: 0%;
    }
  
    .l-step-container1 {
      width: 80%;
      margin-left: 10%;
    }
  
    .l-step-container2 {
      width: 80%;
      margin-left: 10%;
    }
  
    .l-input-container1 input[type="text"] {
      width: 90%;
    }
  
    .l-step-container.case-2 input[type="text"] {
      width: 90%;
      margin-left: 10px;
    }
  
    .l-step-container h2 {
      font-size: 1.9rem;
    }
  
    .l-checkbox-container label {
      margin-right: 40px;
      font-size: 1.9rem;
    }
   
  }


  @media only screen and (min-width: 834px) and (max-width: 1279px) {
  
    .l-step-container.case1 {
      display: flex;
      flex-direction: column;
    }

    .SA-l-step-container {
 
      text-align: center;
      width: 550px;
      padding-top: 0px;
      /* margin-top: -100px; */
      margin-left: 140px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }

   
    body.l-body {
      font-family: 'Poppins', Arial, sans-serif;
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      /* margin-top: 150px; */
    }
    .maprelated-input {
      padding-top: 150px;

      margin-bottom: 20px;
      display: block;
      gap: 15px;
    }
  
    .l-input-container1.case1,
    .onlymap.case1 {
      width: 100%;
      margin-bottom: 10px; /* Optional: adjust spacing between sections */
    }
  
    .l-step-container.case1 input[type="text"] {
      width: 100%;
      margin-bottom: 10px; /* Optional: adjust spacing between inputs */
    }
  
    .l-step-container.case1 h2 {
      font-size: 1.8rem; /* Adjust font size as needed */
    }
  
    .l-checkbox-container.case1 label {
      font-size: 1.8rem; /* Adjust font size as needed */
    }

    .l-suggestions-dropdown {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 140px;
      margin-left: -35px;
      overflow-y: auto;
      width: 490px; /* Adjust width to match input field */
      z-index: 1000;
      left: 26%;
      margin-top: -20px;
    }

    
    /* .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      position: relative;
      margin-right: 60px;
    } */
    
    .l-step-container {
      width: 80%;
      margin-left: 10%;
    }
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      margin-bottom: 30px;
      transition: background-color 0.3s ease;
    }
  
  
    
    .onlymap {
      margin-top: 20px;
margin-left: 20px;
      height: 580px;
      width: 96%;
      border: 1px solid rgb(235, 8, 8);
    }
  
    .l-input-container1 {
      width: 80%;
      margin-left: 0%;
    }
  
    .l-step-container1 {
      width: 80%;
      margin-left: 10%;
    }
  
    .l-step-container2 {
      width: 80%;
      margin-left: 10%;
    }
  
    .l-input-container1 input[type="text"] {
      width: 90%;
    }
  
    .l-step-container.case-2 input[type="text"] {
      width: 90%;
      margin-left: 10px;
    }
  
    .l-step-container h2 {
      font-size: 1.9rem;
    }
  
    .l-checkbox-container label {
      margin-right: 40px;
      font-size: 1.9rem;
    }
   
  }

  @media only screen and (min-width: 1280px) and (max-width: 1440px) {
    body.l-body {
      font-family: 'Poppins', Arial, sans-serif;
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      margin-top: 0px;
    }
    .SA-l-step-container {
 
      text-align: center;
      width: 550px;
      padding-top: 0px;
      /* margin-top: -100px; */
      margin-left: 380px;
      padding: 20px;
    }
 
    .SA-l-step-container-step p {
      margin-left: 20px;
      text-indent: 0; /* Remove any indentation */
    }
    
    .l-card {
      width: 90%;
      max-width: 800px;
      margin: 20px auto;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: #fff;
    }
    
    .l-left-half,
    .l-right-half {
      width: 50%;
      box-sizing: border-box;
    }
    
    .l-next-button {
      padding: 10px 10px;
      margin: 5px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      transition: background-color 0.3s ease;
    }
    
    .l-next-button:hover {
      background-color: #0056b3;
    }
    
    .l-input-container1 {
      width: 70%; /* Adjust width as needed */
      padding: 10px;
      margin-top: 10px;
      border-radius: 8px;
    }
    
    .l-step-container1 {
      text-align: center;
      width: 50%;
      height: 100%;
      margin-left: 30px;
    height: 580px;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-color: hsl(0, 0%, 100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    
    .l-step-container2 {
      text-align: center;
      width: 70%;
      height: 100%;
      margin-left: 230px;
      margin-bottom: 45px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-color: hsl(0, 0%, 100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .l-step-container.case-2 input[type="text"] {
      width: 100%; /* Adjust width as needed */
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      /* Add any additional styling you require */
    }
    
    .l-step-container {
      text-align: center;
      margin-top: 55px;
      border-radius: 8px;
      width: 750px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      align-items: center;
      margin-left: 260px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    .l-step-container.case-4 {
      width: auto;
      padding: 20px;
    }
    
    .maprelated-input {
      padding-top: 150px;

      display: flex;
      gap: 15px;
    }
    
    .onlymap {
      height: 580px;
      width: 45%;
      border: 1px solid red;
    }
    
    .map-containers {
      width: 100%;
      height: 100%;
    }
    
    .l-step-container h2 {
      margin-bottom: 20px;
      margin-top: 20px;
      font-family: 'Poppins', sans-serif;
      font-size: 2rem; /* Adjust the font size as per your preference */
      font-weight: bold;
    }
    
    .l-step-container1 h2 {
      font-family: 'Poppins', sans-serif;
      font-size: 2rem; /* Adjust the font size as per your preference */
      font-weight: bold;
    }
    
    .l-step-container2 h2 {
      font-family: 'Poppins', sans-serif;
      font-size: 2rem; /* Adjust the font size as per your preference */
      font-weight: bold;
    }
    
    .l-step-container input[type="text"],
    .l-step-container input[type="date"],
    .l-step-container input[type="time"],
    .l-step-container input[type="number"] {
      width: 50%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 20px;
    }
    
    .l-step-container1 input[type="text"] {
      width: 80%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      position: relative;
    }
    
    .l-step-container1 input[type="text"]::after {
      content: '▼';
      font-size: 1.2rem;
      color: #aaa;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
    
    .l-step-container.case-4 input[type="text"],
    .l-step-container.case-4 input[type="date"],
    .l-step-container.case-4 input[type="time"],
    .l-step-container.case-4 input[type="number"] {
      width: 100%; /* Adjust width as needed */
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    .l-add-city-input {
      width: 100%; /* Adjust width as needed */
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    .l-add-button {
      padding: 10px 20px;
      margin: 5px;
      margin-left: 35px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    .l-add-button:hover {
      background-color: #0056b3;
    }
    
    /* Case 3 */
    .l-step-container.case-3 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .l-checkbox-container {
      margin-top: 20px;
    }
    
    /* Case 4 */
    .l-step-container.case-4 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .l-add-city-button {
      margin-bottom: 20px;
    }
    
    .l-checkbox-container label {
      display: inline-block;
      margin-right: 50px; /* Adjust margin as needed */
      font-size: 2.1rem; /* Increase font size */
    }
    
    .l-checkbox-container input[type="checkbox"] {
      width: 20px; /* Increase checkbox size */
      height: 20px; /* Increase checkbox size */
      margin-right: 10px;
    }
    
    .l-passenger-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    
    .l-passenger-container input[type="number"] {
      width: 60px;
      text-align: center;
    }
    
    .l-button,
    .l-prev-button,
    .l-next-button,
    .l-submit-button {
      padding: 10px 20px;
      margin: 5px;
      border: none;
      margin-bottom: 30px;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      transition: background-color 0.3s ease;
    }
    
    .l-next-button1 {
      padding: 10px 20px;
      margin-left: 750px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      width: 100px; /* Set fixed width */
      height: 50px;
      transition: background-color 0.3s ease;
    }
    
    .l-decrement-button,
    .l-increment-button {
      padding: 0px; /* Adjust padding as needed */
      margin: 5px;
      border: none;
      width: 30px; /* Set equal width and height for round shape */
      height: 30px;
      border-radius: 50%; /* Make buttons perfectly round */
      background-color: #007bff;
      color: #ffffff;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    .l-button:disabled,
    .l-prev-button:disabled,
    .l-next-button:disabled,
    .l-submit-button:disabled,
    .l-increment-button:disabled,
    .l-decrement-button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
    
    .l-button:hover:not(:disabled),
    .l-prev-button:hover:not(:disabled),
    .l-next-button:hover:not(:disabled),
    .l-submit-button:hover:not(:disabled),
    .l-add-city-button:hover:not(:disabled) {
      background-color: #0056b3;
    }
    
    .l-increment-button {
      background-color: #28a745;
    }
    
    .l-increment-button:hover {
      background-color: #218838;
    }
    
    .l-decrement-button {
      background-color: #dc3545;
    }
    
    .l-decrement-button:hover {
      background-color: #c82333;
    }
    
    .l-suggestions-dropdown {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 140px;
      overflow-y: auto;
      width: 340px; /* Adjust width to match input field */
      z-index: 1000;
      left: 14%;
      margin-top: -20px;
    }
    
    .l-suggestion-item {
      padding: 10px;
      cursor: pointer;
    }
    
    .l-suggestion-item:hover {
      background-color: #f0f0f0;
    }
    
    .l-suggestions-dropdown1 {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 150px;
      overflow-y: auto;
      width: 45%; /* Adjust width to match input field */
      z-index: 1000;
      left: 50%;
      transform: translateX(-50%); /* Center horizontally */
      margin-top: -10px;
    }
    
    .l-suggestion-item1 {
      padding: 10px;
      cursor: pointer;
    }
    
    .l-suggestion-item1:hover {
      background-color: #f0f0f0;
    }
    
    .l-via-input-container {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }
    
    .l-via-input-container input[type="text"] {
      margin-bottom: 10px;
    }
    
    
    .l-alert {
      position: fixed;
      top: 200px;
      right: 20px;
      background-color: rgba(255, 0, 0, 0.8);
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }

  }

  
  