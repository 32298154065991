 
 
.verify-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    border:1px solid rgb(247, 246, 246);
    gap:10rem;
    background: linear-gradient(to bottom, white, black);
 
  }
  .verify-profile-carimagecss{
    width:700px;
    height:500px;
  }
  .verify-profile-previous-but{
  width: 90px;
  height: 30px;
  cursor: pointer;
  margin-left: 140px;
  margin-bottom: 10px;
 
  }
  .verify-profile-previous-but:hover{
    text-align: center;
    /* margin-left: 150px; */
 
    transform: scale(1.1); /* Zoom in on hover */
 
    }
 
  .verify-profile-link-csss{
    text-decoration: none;
 
  }
  .verify-profile-box {
    /* background: #fff; */
    background: rgba(255, 255, 255, 0.8);
    padding: 30px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    /* box-shadow: 0 4px 8px rgba(5, 20, 76, 0.6);  */
    box-shadow: 0 0 15px rgba(5, 20, 76, 0.6);
 
    border-radius: 8px;
    text-align: center;
    width: 100%;
    max-width: 400px; /* Adjust the width as needed */
    border:1px solid #05144c;
  }
 
  .verify-profile-box h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #05144c;
  }
 
  .verify-profile-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
 
  .verify-profile-button {
    width: 100%;
    padding: 10px;
    background-color: #071e70;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
 
  .verify-profile-button:hover {
    background-color: #9dadbe;
    color: #05144c;
 
  }
 
/* Responsive page */
 
@media only screen and (min-width: 320px) and (max-width: 343px) {
  .verify-profile-carimagecss{
    width:200px;
    height:100px;
    margin-left: 50px;
    margin-top: 40px;
  }
 
  .verify-profile-container {
    display: block;
    height: 100vh;
 
  }
 
  .verify-profile-box {
    margin-left: 10px;
    margin-top: 30px;
    max-width: 300px;
  }
 
  .verify-profile-previous-but{
    margin-left: 115px;
    margin-top: 30px;
    margin-bottom: -30px;
  }
}
 
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .verify-profile-carimagecss{
    width:300px;
    height:150px;
    margin-left: 30px;
    margin-top: 40px;
  }
 
  .verify-profile-container {
    display: block;
    height: 100vh;
 
  }
 
  .verify-profile-box {
    margin-left: 30px;
    margin-top: 30px;
    max-width: 300px;
  }
 
  .verify-profile-previous-but{
    margin-left: 135px;
    margin-top: 80px;
    margin-bottom: -30px;
  }
}
 
@media only screen and (min-width: 375px) and (max-width: 383px) {
  .verify-profile-carimagecss{
    width:300px;
    height:150px;
    margin-left: 30px;
    margin-top: 40px;
  }
 
  .verify-profile-container {
    display: block;
    height: 100vh;
 
  }
 
  .verify-profile-box {
    margin-left: 35px;
    margin-top: 30px;
    max-width: 300px;
  }
 
  .verify-profile-previous-but{
    margin-left: 140px;
    margin-top: 100px;
    margin-bottom: -30px;
  }
}
 
@media only screen and (min-width: 390px) and (max-width: 411px) {
  .verify-profile-carimagecss{
    width:300px;
    height:200px;
    margin-left: 40px;
    margin-top: 40px;
  }
 
  .verify-profile-container {
    display: block;
    height: 100vh;
 
  }
 
  .verify-profile-box {
    margin-left: 45px;
    margin-top: 30px;
    max-width: 300px;
  }
 
  .verify-profile-previous-but{
    margin-left: 150px;
    margin-top: 100px;
    margin-bottom: -30px;
  }
}
 
@media only screen and (min-width: 412px) and (max-width: 767px) {
  .verify-profile-carimagecss{
    width:350px;
    height:200px;
    margin-left: 30px;
    margin-top: 40px;
  }
 
  .verify-profile-container {
    display: block;
    height: 100vh;
 
  }
 
  .verify-profile-box {
    margin-left: 40px;
    margin-top: 30px;
    max-width: 330px;
  }
 
  .verify-profile-previous-but{
    margin-left: 165px;
    margin-top: 100px;
    margin-bottom: -30px;
  }
}
 
@media only screen and (min-width: 428px) and (max-width: 767px) {
  .verify-profile-carimagecss{
    width:350px;
    height:250px;
    margin-left: 30px;
    margin-top: 40px;
  }
 
  .verify-profile-container {
    display: block;
    height: 100vh;
 
  }
 
  .verify-profile-box {
    margin-left: 50px;
    margin-top: 30px;
    max-width: 330px;
  }
 
  .verify-profile-previous-but{
    margin-left: 175px;
    margin-top: 100px;
    margin-bottom: -30px;
  }
}
 
@media only screen and (min-width: 768px) and (max-width: 819px) {
  .verify-profile-carimagecss{
    width:500px;
    height:300px;
    margin-left: 120px;
    margin-top: 60px;
  }
 
  .verify-profile-container {
    display: block;
    height: 100vh;
 
  }
 
  .verify-profile-box {
    margin-left: 180px;
    margin-top: 30px;
    max-width: 400px;
  }
 
  .verify-profile-previous-but{
    margin-left: 335px;
    margin-top: 130px;
    margin-bottom: -30px;
  }
}
 
@media only screen and (min-width: 820px) and (max-width: 833px) {
  .verify-profile-carimagecss{
    width:600px;
    height:350px;
    margin-left: 110px;
    margin-top: 60px;
  }
 
  .verify-profile-container {
    display: block;
    height: 100vh;
 
  }
 
  .verify-profile-box {
    margin-left: 210px;
    margin-top: 30px;
    max-width: 400px;
  }
 
  .verify-profile-previous-but{
    margin-left: 365px;
    margin-top: 180px;
    margin-bottom: -30px;
  }
}
 
@media only screen and (min-width: 834px) and (max-width: 883px) {
  .verify-profile-carimagecss{
    width:600px;
    height:350px;
    margin-left: 110px;
    margin-top: 80px;
  }
 
  .verify-profile-container {
    display: block;
    height: 100vh;
 
  }
 
  .verify-profile-box {
    margin-left: 210px;
    margin-top: 30px;
    max-width: 400px;
  }
 
  .verify-profile-previous-but{
    margin-left: 365px;
    margin-top: 200px;
    margin-bottom: -30px;
  }
}
 
@media only screen and (min-width: 884px) and (max-width: 1279px) {
  .verify-profile-carimagecss{
    width:600px;
    height:350px;
    margin-left: 130px;
    margin-top: 80px;
  }
 
  .verify-profile-container {
    display: block;
    height: 100vh;
 
  }
 
  .verify-profile-box {
    margin-left: 230px;
    margin-top: 30px;
    max-width: 400px;
  }
 
  .verify-profile-previous-but{
    margin-left: 385px;
    margin-top: 200px;
    margin-bottom: -30px;
  }
}
 
@media only screen and (min-width: 1280px) and (max-width: 1440px) {}
 
 
 