/* Default styles */
.yourcrea-main{
    border: 1px solid white; 

}
.yourcrea-container {
  
  width: 70%;
  margin-top: 150px;
    margin-left:200px;


}

.yourcrea-content {
    width: 100%;
    max-width: 70vw;
    margin-top: 0px;

}



.yourcrea-rides{   
    text-align: center;
   background-color: #05144c;
   color: #ffffff;
   border: 0px solid #eaebf2;

   width: 70vw;
   margin-right:130px;
   margin-top: 10px;
   height: 70px;
 }
 .yourcrea-rides-your{
 padding-top: 15px;
   text-align: center;
 
 }

.yourcrea-title {
    margin-bottom: 20px;
    text-align: center;
    width: 70vw;
    background-color: #05144c;
    color: white;
    font-family: 'Times New Roman', Times, serif;
}

.yourcrea-table-container {
    max-height: 350px; /* Adjust the max height as per your requirement */
    overflow-y: auto;
    width: 70.1vw;
    margin-top: -0px;
    overflow-x: auto;
    border: 1px solid #ccc; /* Add border to the table container */
}

.yourcrea-table {
    width: 70vw;
    border-collapse: collapse;
    margin-top: 0px;
}

.yourcrea-table thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    border: 0px solid white;
    background-color: #05144c;
    color: #ffffff;
}

.yourcrea-rides-th, .yourcrea-rides-td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.yourcrea-rides-th {
    background-color: #05144c;
    color: white;
}

.yourcrea-action-btn {
    margin-right: 8px;
    padding: 6px 12px;
    cursor: pointer;
}

.yourcrea-reports-view {
    background-color: #000080; /* Adjust the background color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 6px 12px;
    cursor: pointer;
}

.button:hover {
    opacity: 0.8;
}

.yourcrea-td1 {
    display: flex;
}
.yourcrea-no-booking{
    text-align: center;
 
}
@media only screen and (min-width: 320px) and (max-width: 343px) {
    .yourcrea-content {
     
        max-width: 90vw;
        margin-top: 0px;

    }

    .yourcrea-container {
width: 390px;
        padding: 10px;
        margin-left: 5%;


    }
    .yourcrea-table-container table{
        width: 800px;

    }
    .yourcrea-rides{   
        text-align: center;
       background-color: #05144c;
       color: #ffffff;
       border: 0px solid #eaebf2;
       width: 90vw;
       margin-left:-10px;
       margin-top: 0px;
       height: 70px;
     }
     .yourcrea-rides-your{
     padding-top: 20px;
       text-align: center;
     
     }

    .yourcrea-table-container {
        max-height: 480px; /* Adjust the max height as per your requirement */
        overflow-y: auto;
        width: 90.5vw;
        margin-top: 0px;
        overflow-x: auto;
        margin-left: -10px;
        border: 1px solid #ccc; /* Add border to the table container */
    }
    .yourcrea-action-btn {
        font-size: 12px;
        padding: 4px 8px;
    }

    .yourcrea-rides-th,
    .yourcrea-rides-td {
        padding: 6px;
    }
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
    .yourcrea-content {
     
        max-width: 90vw;
        margin-top: 0px;

    }

    .yourcrea-container {
width: 390px;
        padding: 10px;
        margin-left: 5%;


    }
    .yourcrea-table-container table{
        width: 800px;

    }
    .yourcrea-rides{   
        text-align: center;
       background-color: #05144c;
       color: #ffffff;
       border: 0px solid #eaebf2;
       width: 90vw;
       margin-left:-10px;
       margin-top: 0px;
       height: 70px;
     }
     .yourcrea-rides-your{
     padding-top: 20px;
       text-align: center;
     
     }

    .yourcrea-table-container {
        max-height: 480px; /* Adjust the max height as per your requirement */
        overflow-y: auto;
        width: 90.5vw;
        margin-top: 0px;
        overflow-x: auto;
        margin-left: -10px;
        border: 1px solid #ccc; /* Add border to the table container */
    }
    .yourcrea-action-btn {
        font-size: 12px;
        padding: 4px 8px;
    }

    .yourcrea-rides-th,
    .yourcrea-rides-td {
        padding: 6px;
    }
}
@media only screen and (min-width: 375px) and (max-width: 385px) {
    .yourcrea-content {
     
        max-width: 90vw;
        margin-top: 0px;

    }

    .yourcrea-container {
width: 390px;
        padding: 10px;
        margin-left: 5%;


    }
    .yourcrea-table-container table{
        width: 800px;

    }
    .yourcrea-rides{   
        text-align: center;
       background-color: #05144c;
       color: #ffffff;
       border: 0px solid #eaebf2;
       width: 90vw;
       margin-left:-10px;
       margin-top: 0px;
       height: 70px;
     }
     .yourcrea-rides-your{
     padding-top: 20px;
       text-align: center;
     
     }

    .yourcrea-table-container {
        max-height: 480px; /* Adjust the max height as per your requirement */
        overflow-y: auto;
        width: 90.5vw;
        margin-top: 0px;
        overflow-x: auto;
        margin-left: -10px;
        border: 1px solid #ccc; /* Add border to the table container */
    }
    .yourcrea-action-btn {
        font-size: 12px;
        padding: 4px 8px;
    }

    .yourcrea-rides-th,
    .yourcrea-rides-td {
        padding: 6px;
    }
}
@media only screen and (min-width: 390px) and (max-width: 411px) {
    .yourcrea-content {
     
        max-width: 90vw;
        margin-top: 0px;

    }

    .yourcrea-container {
width: 390px;
        padding: 10px;
        margin-left: 5%;


    }
    .yourcrea-table-container table{
        width: 800px;

    }
    .yourcrea-rides{   
        text-align: center;
       background-color: #05144c;
       color: #ffffff;
       border: 0px solid #eaebf2;
       width: 90vw;
       margin-left:-10px;
       margin-top: 0px;
       height: 70px;
     }
     .yourcrea-rides-your{
     padding-top: 20px;
       text-align: center;
     
     }

    .yourcrea-table-container {
        max-height: 480px; /* Adjust the max height as per your requirement */
        overflow-y: auto;
        width: 90.5vw;
        margin-top: 0px;
        overflow-x: auto;
        margin-left: -10px;
        border: 1px solid #ccc; /* Add border to the table container */
    }
    .yourcrea-action-btn {
        font-size: 12px;
        padding: 4px 8px;
    }

    .yourcrea-rides-th,
    .yourcrea-rides-td {
        padding: 6px;
    }
}
@media only screen and (min-width: 412px) and (max-width: 427px) {
    .yourcrea-content {
     
        max-width: 90vw;
        margin-top: 0px;

    }

    .yourcrea-container {
width: 390px;
        padding: 10px;
        margin-left: 5%;


    }
    .yourcrea-table-container table{
        width: 800px;

    }
    .yourcrea-rides{   
        text-align: center;
       background-color: #05144c;
       color: #ffffff;
       border: 0px solid #eaebf2;
       width: 90vw;
       margin-left:-10px;
       margin-top: 0px;
       height: 70px;
     }
     .yourcrea-rides-your{
     padding-top: 20px;
       text-align: center;
     
     }

    .yourcrea-table-container {
        max-height: 480px; /* Adjust the max height as per your requirement */
        overflow-y: auto;
        width: 90.5vw;
        margin-top: 0px;
        overflow-x: auto;
        margin-left: -10px;
        border: 1px solid #ccc; /* Add border to the table container */
    }
    .yourcrea-action-btn {
        font-size: 12px;
        padding: 4px 8px;
    }

    .yourcrea-rides-th,
    .yourcrea-rides-td {
        padding: 6px;
    }
}
@media only screen and (min-width: 428px) and (max-width: 767px) {
    /* .yourcrea-content {
        padding: 10px;
        border: 1px solid rgb(228, 8, 8); 

    } */
    .yourcrea-content {
     
        max-width: 90vw;
        margin-top: 0px;

    }

    .yourcrea-container {
width: 390px;
        padding: 10px;
        margin-left: 5%;


    }
    .yourcrea-table-container table{
        width: 800px;

    }
    .yourcrea-rides{   
        text-align: center;
       background-color: #05144c;
       color: #ffffff;
       border: 0px solid #eaebf2;
       width: 90vw;
       margin-left:-10px;
       margin-top: 0px;
       height: 70px;
     }
     .yourcrea-rides-your{
     padding-top: 20px;
       text-align: center;
     
     }

    .yourcrea-table-container {
        max-height: 480px; /* Adjust the max height as per your requirement */
        overflow-y: auto;
        width: 90.5vw;
        margin-top: 0px;
        overflow-x: auto;
        margin-left: -10px;
        border: 1px solid #ccc; /* Add border to the table container */
    }
    .yourcrea-action-btn {
        font-size: 12px;
        padding: 4px 8px;
    }

    .yourcrea-rides-th,
    .yourcrea-rides-td {
        padding: 6px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 799px) {
    .yourcrea-container {
        width: 800px;

        padding: 10px;
        margin-left: 4%;


    }
   
    .yourcrea-table-container table{
        width: 900px;

    }
    .yourcrea-content {
        width: 100%;
        max-width: 70vw;
        margin-top: 0px;
    
    }
    

    .yourcrea-rides{   
        text-align: center;
       background-color: #05144c;
       color: #ffffff;
       border: 0px solid #eaebf2;
       width: 90vw;
       margin-top: 0px;
       height: 70px;
     }
     .yourcrea-rides-your{

     padding-top: 15px;
       text-align: center;
     
     }
    .yourcrea-title {
        margin-top: -10px;
        margin-bottom: 20px;
        text-align: center;
        border: 1px solid rgb(216, 207, 207);
        width: 90vw;
        background-color: #05144c;
        margin-left: 0px;
        color: white;
        font-family: 'Times New Roman', Times, serif;
    }

    .yourcrea-table-container {
        max-height: 480px; /* Adjust the max height as per your requirement */
        overflow-y: auto;
        width: 90vw;
        margin-top: -15px;
        overflow-x: auto;
        margin-left: -0px;
        border: 1px solid #ccc; /* Add border to the table container */
    }
    .yourcrea-action-btn {
        font-size: 14px;
        padding: 6px 10px;
    }

    .yourcrea-rides-th,
    .yourcrea-rides-td {
        padding: 8px;
    }
}
@media only screen and (min-width: 800px) and (max-width: 819px) {
    .yourcrea-container {
        width: 800px;

        padding: 10px;
        margin-left: 4%;


    }
   
    .yourcrea-table-container table{
        width: 900px;

    }
    .yourcrea-content {
        width: 100%;
        max-width: 70vw;
        margin-top: 0px;
    
    }
    

    .yourcrea-rides{   
        text-align: center;
       background-color: #05144c;
       color: #ffffff;
       border: 0px solid #eaebf2;
       width: 90vw;
       margin-top: 0px;
       height: 70px;
     }
     .yourcrea-rides-your{

     padding-top: 15px;
       text-align: center;
     
     }
    .yourcrea-title {
        margin-top: -10px;
        margin-bottom: 20px;
        text-align: center;
        border: 1px solid rgb(216, 207, 207);
        width: 90vw;
        background-color: #05144c;
        margin-left: 0px;
        color: white;
        font-family: 'Times New Roman', Times, serif;
    }

    .yourcrea-table-container {
        max-height: 480px; /* Adjust the max height as per your requirement */
        overflow-y: auto;
        width: 90vw;
        margin-top: -15px;
        overflow-x: auto;
        margin-left: -0px;
        border: 1px solid #ccc; /* Add border to the table container */
    }
    .yourcrea-action-btn {
        font-size: 14px;
        padding: 6px 10px;
    }

    .yourcrea-rides-th,
    .yourcrea-rides-td {
        padding: 8px;
    }
}
@media only screen and (min-width: 820px) and (max-width: 852px) {
    .yourcrea-container {
        width: 800px;

        padding: 10px;
        margin-left: 4%;


    }
   
    .yourcrea-table-container table{
        width: 900px;

    }
    .yourcrea-content {
        width: 100%;
        max-width: 70vw;
        margin-top: 0px;
    
    }
    

    .yourcrea-rides{   
        text-align: center;
       background-color: #05144c;
       color: #ffffff;
       border: 0px solid #eaebf2;
       width: 90vw;
       margin-top: 0px;
       height: 70px;
     }
     .yourcrea-rides-your{

     padding-top: 15px;
       text-align: center;
     
     }
    .yourcrea-title {
        margin-top: -10px;
        margin-bottom: 20px;
        text-align: center;
        border: 1px solid rgb(216, 207, 207);
        width: 90vw;
        background-color: #05144c;
        margin-left: 0px;
        color: white;
        font-family: 'Times New Roman', Times, serif;
    }

    .yourcrea-table-container {
        max-height: 480px; /* Adjust the max height as per your requirement */
        overflow-y: auto;
        width: 90vw;
        margin-top: -15px;
        overflow-x: auto;
        margin-left: -0px;
        border: 1px solid #ccc; /* Add border to the table container */
    }
    .yourcrea-action-btn {
        font-size: 14px;
        padding: 6px 10px;
    }

    .yourcrea-rides-th,
    .yourcrea-rides-td {
        padding: 8px;
    }
}

@media only screen and (min-width: 884px) and (max-width: 1279px) {
    .yourcrea-container {
        width: 800px;

        padding: 10px;
        margin-left: 4%;


    }
   
    .yourcrea-table-container table{
        width: 900px;

    }
    .yourcrea-content {
        width: 100%;
        max-width: 70vw;
        margin-top: 0px;
    
    }
    

    .yourcrea-rides{   
        text-align: center;
       background-color: #05144c;
       color: #ffffff;
       border: 0px solid #eaebf2;
       width: 90vw;
       margin-top: 0px;
       height: 70px;
     }
     .yourcrea-rides-your{

     padding-top: 15px;
       text-align: center;
     
     }
    .yourcrea-title {
        margin-top: -10px;
        margin-bottom: 20px;
        text-align: center;
        border: 1px solid rgb(216, 207, 207);
        width: 90vw;
        background-color: #05144c;
        margin-left: 0px;
        color: white;
        font-family: 'Times New Roman', Times, serif;
    }

    .yourcrea-table-container {
        max-height: 480px; /* Adjust the max height as per your requirement */
        overflow-y: auto;
        width: 90vw;
        margin-top: -15px;
        overflow-x: auto;
        margin-left: -0px;
        border: 1px solid #ccc; /* Add border to the table container */
    }
    .yourcrea-action-btn {
        font-size: 14px;
        padding: 6px 10px;
    }

    .yourcrea-rides-th,
    .yourcrea-rides-td {
        padding: 8px;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
  /* Default styles */
  .yourcrea-main{
    border: 1px solid white; 

}
.yourcrea-container {
  
  width: 70%;
  margin-top: 150px;
    margin-left:200px;


}

.yourcrea-content {
    width: 100%;
    max-width: 70vw;
    margin-top: 0px;

}



.yourcrea-rides{   
    text-align: center;
   background-color: #05144c;
   color: #ffffff;
   border: 0px solid #eaebf2;

   width: 70vw;
   margin-right:130px;
   margin-top: 10px;
   height: 70px;
 }
 .yourcrea-rides-your{
 padding-top: 15px;
   text-align: center;
 
 }

.yourcrea-title {
    margin-bottom: 20px;
    text-align: center;
    width: 70vw;
    background-color: #05144c;
    color: white;
    font-family: 'Times New Roman', Times, serif;
}

.yourcrea-table-container {
    max-height: 350px; /* Adjust the max height as per your requirement */
    overflow-y: auto;
    width: 70.1vw;
    margin-top: -0px;
    overflow-x: auto;
    border: 1px solid #ccc; /* Add border to the table container */
}

.yourcrea-table {
    width: 70vw;
    border-collapse: collapse;
    margin-top: 0px;
}

.yourcrea-table thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    border: 0px solid white;
    background-color: #05144c;
    color: #ffffff;
}

.yourcrea-rides-th, .yourcrea-rides-td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.yourcrea-rides-th {
    background-color: #05144c;
    color: white;
}

.yourcrea-action-btn {
    margin-right: 8px;
    padding: 6px 12px;
    cursor: pointer;
}

.yourcrea-reports-view {
    background-color: #000080; /* Adjust the background color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 6px 12px;
    cursor: pointer;
}

.button:hover {
    opacity: 0.8;
}

.yourcrea-td1 {
    display: flex;
}
.yourcrea-no-booking{
    text-align: center;
 
}
}
