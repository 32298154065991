.lscard {
  border: 1px solid #cbc5c5;
 
  border-radius: 8px;
 
  padding: 16px;
 
  margin: 0px;
 
  color: #05144c;
 
  width: 60vw;
  margin-top: -30px;
  height: 29vh;
 
  display: flex;
 
  flex-direction: column;
 
  align-items: center;
}
 
.SA-ride-pick-up {
  margin-top: -20px;
}
 
.SA-ride-normal-card {
  /* border: 1px solid black; */
  margin-top: 40px;
}
 
.lscard-details {
  width: 100%;
 
  /* margin-top: 200px; */
 
  display: flex;
 
  flex-direction: column;
 
  align-items: center;
}
 
.lscard-location {
  width: 100%;
 
  display: flex;
 
  flex-direction: column;
 
  align-items: center;
 
  margin-bottom: 16px;
}
.lscard-location-full {
  width: 100%;
 
  display: flex;
 
  flex-direction: column;
 
  align-items: center;
 
  margin-bottom: 16px;
}
 
.lscard-ride-info {
  width: 100%;
  margin-top: -50px;
}
.lscard-ride-info-full {
  width: 100%;
  margin-top: 5px;
}
 
.lscard-dest {
  display: flex;
 
  justify-content: space-between;
 
  margin-top: -10px;
  width: 70%;
  /* border: 1px solid rgb(251, 9, 9); */
}
 
 
 
 
.lscard-seats-total{
 
  margin-right: 5px;
}
.lscard-price {
  display: block;
 
  margin-left: -725px;
  /* border: 1px solid rgb(224, 15, 15); */
 
  margin-top: 0px;
}
.lscard-price1-price{
  /* border: 1px solid rgb(15, 224, 64); */
  margin-left: 5px;
}
.lscard-price-price{
  margin-left: 0px;
  /* border: 1px solid rgb(224, 15, 15); */
 
}
.lscard-price-icon{
  margin-left: -30px;
 
}
 
.lscard-pricenoof{
 
  margin-left:0px;
 
 
}
 
.lscard-price-icon-full{
  margin-left: 0px;
padding-right: 0px;
/* font-size: 22px; */
 
}
.lscard-seats-price-full{
  /* border: 1px solid rgb(224, 15, 15); */
margin-left: 5px;
}
 
.lscard-seats-total-full{
  margin-right: 0px;
  /* border: 1px solid rgb(224, 15, 15); */
 
  margin-right: 0px;
}
 
.lscard-price-full {
  display: block;
  /* border: 1px solid rgb(241, 8, 8); */
width: 40%;
  margin-right: 530px;
  text-align: left;
margin-top: -15px;
}
 
 
.lscard-ride {
  margin: 0 8px;
 
  font-size: 16px;
}
 
.lscard-ride-goi {
  margin: 0 8px;
  margin-right: 160px;
 
  font-size: 16px;
}
 
.lscard-arrow {
  margin-right: 0px;
  font-size: 20px;
}
.lscard-time-date-full {
  display: flex;
 
  /* align-items: center; */
  text-align: left;
  justify-content: space-between;
  /* border: 1px solid rgb(252, 22, 22); */
height: 80px;
  width: 40%;
  margin-right: 530px;
  margin-top: -15px;
}
 
.lscard-time-date {
  display: flex;
  height: 80px;
 
  /* align-items: center; */
  text-align: left;
  justify-content: space-between;
  /* border: 1px solid rgb(252, 22, 22); */
 
  width: 40%;
  margin-right: 530px;
  margin-top: 0px;
}
 
.lscard-pikeuptiming {
  display: flex;
 
  align-items: center;
  margin-left: 20px;
 
  justify-content: space-between;
 
  width: 100%;
}
 
.lscard-time {
  /* border: 1px solid rgb(252, 22, 22); */
 
  display: block;
  height: 80px;
  flex-direction: row;
 
  align-items: center;
 
  justify-content: space-between;
 
  width: 100%;
}
 
 
.lscard-ride-time,
.lscard-ride-duration {
  margin: 0 16px;
}
 
.lsride-times {
  margin-top: 20px;
}
 
.lsride-time-pickup {
  /* border: 1px solid rgb(224, 15, 15); */
 
  margin-top: -20px;
}
.lsride-time-dropoff {
  /* border: 1px solid rgb(224, 15, 15); */
 
  margin-top: -20px;
}
 
.lsride-time-full {
  margin-top: -20px;
}
 
.lscard-date {
  font-size: 24px;
 
  font-weight: 700;
 
  margin-top: 35px;
 
  margin-bottom: -50px;
}
.lscard-date-full {
  font-size: 24px;
 
  font-weight: 700;
 
  margin-top: -20px;
 
  margin-bottom: -0px;
}
 
.lscard-date-1 {
  font-size: 24px;
 
  font-weight: 700;
 
  margin-top: 0px;
 
  margin-bottom: 20px;
}
 
.SA-lscard-user {
  display: block;
 
  margin-top: -150px;
 
  margin-left: 70%;
}
 
.lscard-user {
  display: block;
 
  margin-top: -180px;
 
  margin-left: 70%;
}
 
.lscard-user-image {
  width: 100px;
 
  height: 100px;
 
  margin-top: 10px;
  border-radius: 50%;
 
  margin-right: -6px;
}
.lscard-user-image-full{
  width: 100px;
 
  height: 100px;
 
  margin-top: 10px;
  border-radius: 50%;
 
  margin-right: -6px;
}
 
.lscard-user-id {
  font-size: 16px;
}
.lscard-seats-avail-full{
  margin-top:-20px;
 
}
.lscard-seats-avail{
  margin-top: -25px;
  font-size: 15px;
}
 
 
 
 
.card-price-icon {
  margin-right: 4px;
}
 
.lscard-back-button {
  color: black;
 
  padding: 8px 16px;
 
  border: none;
 
  border-radius: 4px;
 
  cursor: pointer;
 
  margin-left: 80%;
}
 
.lscard-back-button:hover {
  text-decoration: underline;
 
  color: #007bff;
}
 
.disabled-card {
  padding: 0px;
 
  width: 100%;
 
  opacity: 0.6;
  /* Example of making it visually appear disabled */
}
 
.full-text-full {
  /* border: 1px solid #e91111; */
 
  color: red;
font-size: 30px;
margin: 0px;
margin-bottom: 0px;
 
}
.ls-river{
  background-color: #f6f4f4;
  padding: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
 
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(red, green, blue, alpha);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-decoration: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
width: 97.5%;
filter: grayscale(0) opacity(0.5);
  transition: opacity 0.5s ease-in-out, filter 0.5s ease-in-out;
  pointer-events: none;
 
 
}
.ls-river.disabled{
  filter: grayscale(100%) blur(5px);
  opacity: 0.7;
  pointer-events: none;
 
 
}
 
.lsh-filter-container {
  display: flex;
 
  flex-direction: column;
 
  align-items: flex-start;
 
  padding: 10px;
 
  width: 360px;
 
  height: 350px;
 
  margin-top: 4vh;
  /* Adjust this margin as needed */
 
  border: 1px solid #ccc;
 
  border-radius: 8px;
 
  background-color: #f9f9f9;
}
 
.lsh-filter-header {
  display: flex;
 
  justify-content: space-between;
 
  align-items: center;
 
  width: 100%;
}
 
.lsh-clear-filters {
  margin-right: 10px;
 
  padding: 7px 10px;
 
  background-color: #05144c;
 
  color: white;
 
  border: none;
 
  border-radius: 50px;
 
  cursor: pointer;
}
 
.lsh-filter-options {
  display: block;
 
  flex-wrap: wrap;
 
  gap: 15px;
  /* Increase gap between checkboxes and labels */
 
  margin-top: 10px;
}
 
.lsh-filter-option {
  display: flex;
 
  align-items: center;
}
 
.lsh-filter-option input {
  margin-right: 10px;
  /* Adjust margin between input and text */
}
 
.lsh-filter-option label {
  font-size: 24px;
  /* Increase font size of text */
 
  margin-top: 15px;
 
  margin-left: 10px;
  /* Add margin between icon and text */
 
  font-family: "Times New Roman", Times, serif;
  /* Add margin-top */
 
  display: flex;
 
  align-items: center;
}
 
.lsh-fli {
  font-family: "Times New Roman", Times, serif;
 
  font-weight: 600;
 
  margin-left: 10px;
}
 
.lsh-box {
  flex: 0 0 auto;
  /* Prevent checkboxes from expanding */
 
  margin-left: 10px;
 
  margin-top: 20px;
  /* Adjust margin between input and text */
}
 
.ls-search-container {
  max-width: 95%;
 
  margin: 0 auto;
 
  height: 100px;
 
  margin-top: 140px;
 
  padding: 20px;
 
  border: 1px solid #ccc;
 
  background-color: #f3f4f6;
 
  border-radius: 20px;
 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
 
.ls-search-inputs {
  display: flex;
 
  flex-wrap: wrap;
margin-left: 100px;
  gap: 20px;
 
  margin-bottom: 10px;
}
 
 
.ls-gapsu1 {
  display: flex;
}
 
.ls-leaving {
  border: 1px solid rgb(170, 169, 169);
 
  width: 220px;
 
  /* Adjust the height as needed */
 
  border-radius: 5px;
}
 
.ls-search-inputs input,
.ls-search-inputs select {
  flex: 1;
 
  padding: 10px;
 
  border: 1px solid rgb(176, 173, 173);
 
  border-radius: 5px;
 
  font-size: 16px;
 
  /* Set max height for input and select */
}
 
.ls-search-inputs select {
  flex: 1;
 
  padding: 10px;
 
  border: 1px solid rgb(176, 173, 173);
 
  border-radius: 5px;
 
  font-size: 16px;
 
  /* Set max height for input and select */
}
 
.ls-search-inputs button {
  padding: 10px 20px;
 
  height: 60px;
 
  color: #fff;
 
  border: none;
 
  border-radius: 50px;
 
  margin-left: 30px;
 
  cursor: pointer;
}
 
.ls-search-inputs button:disabled {
  background-color: #ccc;
 
  cursor: not-allowed;
}
 
.ls-search-results {
  margin-top: 20px;
}
 
.ls-search-results ul {
  list-style: none;
 
  padding: 0;
}
 
.ls-search-results li {
  margin-bottom: 5px;
}
 
.ls-passenger-input {
  display: flex;
 
  align-items: center;
width: 230px;
  margin-left: 10px;
 
  gap: 10px;
}
 
/*  */
 
.ls-passenger-controls {
  position: relative;
 
  display: inline-block;
 
  margin-left: -120px;
width: 90px;
  border: none;
}
 
.ls-passenger-customm {
  width: 100px;
 
  padding: 10px;
 
  padding-left: 30px;
  /* Adjust based on icon size */
 
  padding-right: 30px;
  /* Adjust based on icon size */
 
  text-align: center;
 
  font-size: 16px;
}
 
.ls-passenger-minus-icon,
.ls-passenger-plus-icon {
  position: absolute;
 
  top: 50%;
 
  transform: translateY(-50%);
 
  cursor: pointer;
 
  font-size: 16px;
 
  color: #333;
}
 
.ls-passenger-minus-icon {
  left: 10px;
  /* Adjust based on input padding */
}
 
.ls-passenger-plus-icon {
  right: 10px;
  /* Adjust based on input padding */
}
 
.ls-search {
  width: 180px;
 
  background-color: #05144c;
 
  text-decoration: none !important;
  outline: none;
  /* No outline on focus */
 
  border-radius: 10px;
}
 
.ls-radio-icon,
.ls-calendar-icon,
.ls-arrow-icon {
  margin-top: 20px;
 
  font-size: 20px;
}
 
.ls-passenger-count {
  border: 1px solid rgb(176, 173, 173);
 
  height: 60px;
 
  width: 250px;
}
 
.ls-search-results {
  margin-left: 30%;
}
 
.ls-res {
  text-align: center;
 
  font-family: "Times New Roman", Times, serif;
 
  margin-top: -15px;
 
  margin-right: 80px;
}
 
.ls-inn {
  border-radius: 5px;
 
  width: 60%px;
 
  color: #05144c;
 
  text-align: center;
 
  margin-top: 50px;
 
  margin-left: 30px;
 
  font-size: 20px;
 
  font-family: "Times New Roman", Times, serif;
}
 
.ls-passenger-customm {
  margin-left: -1px;
}
 
.location-info {
  display: block;
 
  align-items: center;
 
  margin-bottom: 20px;
}
 
.ride {
  margin-top: 10px;
}
 
 
 
 
.ls-create-ride-button {
  background-color: #05144c;
 
  color: white;
 
  border: none;
 
  padding: 10px 20px;
 
  font-size: 16px;
 
  margin-left: 100px;
 
  border-radius: 50px;
 
  cursor: pointer;
 
  text-decoration: none;
 
  margin-top: 0px;
  /* Adjust margin as needed */
}
 
.ls-create-ride-button-container {
  margin-top: -450px;
 
  margin-left: 700px;
}
 
.ls-create-ride-button1 {
  background-color: #05144c;
 
  color: white;
 
  border: none;
 
  padding: 10px 20px;
 
  font-size: 16px;
 
  margin-left: 00px;
 
  border-radius: 50px;
 
  cursor: pointer;
 
  text-decoration: none;
 
  margin-top: 0px;
  /* Adjust margin as needed */
}
 
.ls-create-ride-button1-container {
  margin-top: 50px;
 
  margin-left: 500px;
}
 
.ls-additional-info {
  width: 400px;
 
  margin-top: 20px;
  /* Adjust margin as needed */
}
 
.ls-filter {
  font-size: 20px;
}
 
.ls-additional-info {
  font-size: 20px;
 
  width: 400px;
 
  margin-top: -40px;
}
 
.ls-additional-info h1 {
  font-size: 26px;
 
  margin-top: 20px;
}
 
.ls-additional-info .detail {
  display: flex;
 
  align-items: center;
 
  margin-top: 20px;
}
 
.ls-additional-info .detail svg {
  margin-right: 10px;
}
 
.ls-additional-info .detail span {
  margin-left: auto;
}
 
.ls-additional-info .detail span::before {
  content: "|";
 
  margin-right: 10px;
 
  opacity: 0.6;
}
 
.ls-additional-info .detail:last-child span::before {
  content: "";
 
  margin-right: 0;
 
  opacity: 1;
}
 
.ride-link {
  text-decoration: none;
 
  color: #05144c;
}
 
.ls-results-container {
  border: 1px solid rgb(239, 236, 236);
 
  width: 103%;
 
  margin-top: 30px;
 
  height: 488px;
  /* Fixed height for the container */
 
  background-color: #f6f8f9;
 
  margin-left: -20px;
  /* Remove any left margin */
 
  margin-right: -20px;
  /* Remove any right margin */
 
  padding-left: 20px;
  /* Adjust padding as needed */
 
  padding-right: 20px;
  /* Adjust padding as needed */
 
  overflow-y: auto;
}
 
.details {
  margin-left: 5px;
 
  font-size: 15px;
 
  font-weight: 600;
 
  margin-bottom: 0px;
}
 
.detail {
  margin-top: 20px;
 
  display: block;
}
 
.detaill {
  font-weight: 300;
}
 
.slidee {
  font-size: 60px;
 
  margin-left: -200px;
 
  margin-bottom: 80px;
}
 
.man-image {
  width: 50px;
 
  height: 50px;
 
  border-radius: 100px;
 
  border: 4px solid #05144c;
 
  margin-top: 0px;
 
  margin-left: 80px;
}
 
.immtext {
  display: flex;
 
  align-items: center;
  /* Align items vertically */
}
 
.text-container {
  display: flex;
 
  flex-direction: column;
 
  margin-top: 30px;
 
  margin-left: 20px;
}
 
.sort {
  display: flex;
 
  align-items: center;
  /* Align items vertically */
 
  margin-top: -10px;
}
 
.sortt {
  font-size: 20px;
 
  color: #002366;
 
  margin-top: 20px;
 
  margin-left: 170px;
  /* Add margin between "Sort by" and "Clear all" */
}
 
.sorttt {
  margin-left: 100px;
  /* Add margin between "Sort by" and "Clear all" */
}
 
.sorttt-1 {
  margin-left: 145px;
}
 
.sorttt-2 {
  margin-left: 45px;
}
 
.sorttt-3 {
  margin-left: 75px;
}
 
.sorttt-4 {
  margin-left: 145px;
}
 
.sorttt-5 {
  margin-left: 135px;
}
 
.sorttt-6 {
  margin-left: 125px;
}
 
.sorttt-7 {
  margin-left: 112px;
}
 
.sorttt-8 {
  margin-left: 150px;
}
 
.ls-res1 {
  font-size: 30px;
 
  width: 600px;
 
  margin-left: 350px;
 
  font-family: "Times New Roman", Times, serif;
 
  margin-top: -200px;
}
 
.filter {
  margin-left: -400px;
}
 
.baggg {
  margin-left: -100px;
 
  margin-top: -150px;
}
 
.ls-fa {
  color: navy;
  margin-right: 50px;
 
  margin-bottom: 0px;
}
 
.ls-faa {
  margin-top: 50px;
}
 
.ls-mainbb {
  border: 1px solid rgb(246, 243, 243);
}
 
.lscard-dest-Efficient {
  /* border: 1px solid rgb(224, 15, 15); */
 
  width: 70%;
  height: 30px;
  margin-top: 40px;
 
}
.lscard-dest-ride{
  /* border: 1px solid rgb(247, 10, 10); */
margin-top: 0px;
  width: 100%;
  height: 30px;
 
}
.lscard-dest-ride-full{
  /* border: 1px solid rgb(247, 10, 10); */
  border: 1px solid black;
margin-top: 0px;
  width: 100%;
  height: 30px;
 
}
 
.ls-sectt {
  width: 230px;
 
  margin-top: 0px;
 
  display: flex;
 
  gap: 5px;
}
 
.ls-sectp {
  margin-top: 0px;
width: 200px;
  display: flex;
}
 
.ls-divide {
  border: 1px solid rgb(213, 211, 211);
 
  border-radius: 10px;
/* width: 920px; */
  overflow-y: scroll;
 
  height: 400px;
}
 
.ls-inno {
  margin-left: 300px;
}
 



@media only screen and (min-width: 320px) and (max-width:343px) {
  /* Add your specific styles here */
  .ls-mainbb {
    border:1px solid white;
      }
      .ls-search-container {
        width: 100%;
       height:400px;
       margin-top: 120px;
       margin-left: 20p;
       
      }
      .ls-sectt {
        width: 250px;
        margin-left: -15px;

       padding-top: 0px;
        margin-top: 0px;
       
        display: flex;
       
        gap: 5px;
      }
      .lscard{
   
       height:260px;
       margin-top: 70px;
       /* margin-bottom: 100px; */
       width:268px;
       margin-left: -20px;
       border: 1px solid rgb(205, 203, 203);
      }
      .lscard-1{
   
        height:350px;
        margin-top: -20px;
        width:250px;
        /* border: 1px solid black; */
       }
     
       .ls-res{
        margin-left: 80px;
       }
      .ls-create-ride-button1-container {
        margin-top: 50px;
       
        margin-left: 150px;
      }
       .SA-rider-full-card{
        margin-left: -25px;
        margin-top: 100px;
        font-size: 13px;
        width:310px;

       }
       .baggg {
        margin-left: 150px;
       width: 250px;
height: 250px;
       margin-top: -150px;
      }
      .filter{
        margin-left: 20px;
        font-size: 23px;
padding-top: 70px;
      }
   
        .ls-res1 {
          font-size: 18px;
         
          width: 330px;
         
          margin-left: 30px;
         
          font-family: "Times New Roman", Times, serif;
         
          margin-top: -00px;
        }
      
     
      .ls-arrow-icon{
        display: none;
      }
      .ls-gapsu1{
width: 300px;
        display: block;
        margin-left: -90px;
      }
      .ls-cal{
        width: 300px;


      }
      .ls-passenger-count{
      margin-top: 0px;
      }
      .ls-search-inputs button {
   
    margin-left: -50px;
      }
      .ls-sectp{

        width:250px;
        margin-top:16px;
margin-left: -15px;
      }
     .ls-leaving{
      width:320px;


     }
   
    .ls-passenger-controls{
      border:1px solid rgba(39, 94, 7, 0.437);

   
      margin-bottom: 10px;
    }
    
      .ls-passenger-input {
        width:230px;

    margin-top:10px;
    margin-left: -15px;
   
      }
  
     
      .SA-ride-normal-card{
        margin-left: -40px;
        margin-top: 120px;
        font-size: 13px;
        width:330px;

       }
   
      .lscard-seats-avail{
        margin-top: 145px;
        text-align: left;

        margin-left: -150px;
      }
    
   
   
      .lscard-user-image{
        width:50px;
       margin-top: -335px;
       margin-left: -300px;

        height:50px;
      }
      .lscard-seats-name{
        margin-left: -150px;
        margin-top: -153px;;
text-align: left;
/* border: 1px solid rgb(243, 16, 16); */
width: 250px;

     
      }
      .ls-passenger-controls {
        margin-top: 10px;
        width:90px;
      }
      .ls-search-results{
   
    width:350px;
    display:block;
    height:150px;
    margin-left: -30px;
      }
      .ls-inno{
        margin-left: 15px;
        width:280px;

   
      }
      
      .ls-create-ride-button-container {
        margin-top: 15px;
        margin-left: 0px;
    }
      .ride-info{
   
        height:260px;
      }
      .ride-info-1{
   
        height:200px;
      }
      .lscard-dest{
        font-size:10px;
        margin-left: -100px;
   
   
      }
   
      .lscard-dest-ride-full {
        font-size: 15px;
   
        margin-top: 100px;
   
        height: 100px;
        width: 500px; /* Example width */
        margin-left: 120px; /* Example margin-left */
    }
   
      .lscard-date{
        font-size:15px;
        margin-left: -25px;
        margin-top: 20px;
       
      }
      .lscard-date-full{
        font-size:15px;
        margin-left: -110px;
        margin-top: -60px;
      }
    
      .SA-lscard-user{
        font-size: 13px;
 
      }
      .lscard-location {
        width: 180px;
        margin-left: 14px;
        display: block;
   
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
       
   
      }
     
     
      /* .lscard-seats-name-full{
        margin-left: 20px;
        margin-top: -200px;
        text-align: left;

     
      } */

      .lscard-seats-name-full{
        margin-top: 0px;
        margin-left:-220px;
        /* margin-right: 350px; */
        /* border: 1px solid rgb(243, 16, 16); */
        text-align: left;
font-size: 13px;
        width: 250px;

      }
      .lscard-dest-ride{
        font-size:15px;
        width:180px;
        height:50px;
       
        margin-top:130px;
        margin-left: 0px;
      }
   
      .lscard-price{
   
        margin-left: -2px;
        width:130px;
        margin-top: 3px;
        font-size:15px;
      }
      .lscard-location-full{
        width: 300px;
        margin-left: 100px;
        display: block;
        flex-direction: row;
        align-items: center;
        margin-top: 50px;
      }
      .lscard-time-date{
        margin-top: 10px;
       
        margin-left: 0px;
        font-size:15px;
        width:200px;
   
      }
      .lscard-user{
        font-size: 15px;
 
 
      }
      .lscard-seats-avail-full{
 
    margin-top:-0px;
    margin-left: -150px;
      }
      .full-text-full{
        font-size: 15px;
       
      }
     
      .lscard-user-image-full{
        width:50px;
       margin-top: -40px;
       margin-left: -480px;
        height:50px;
      }
     
   
      .lscard-time-date-full{
        margin-top: -23px;
       
        margin-left: 19px;
        font-size:15px;
        width:200px;
      }
      .lscard-price-full{
        margin-left: -50px;
        margin-top: -10px;
     
        font-size: 14px;
      }
     
 
     
   
      .ls-divide{

        width:287px;
        margin-left: 15px;
        margin-top: 15px;
       
      }
      .lsh-filter-container{
       
        margin-top: 400px;
        width:280px;
        margin-left: -10px;
        margin-bottom: 10px;
      }
      .ls-results-container{

    display:block;
    width:300px;
    overflow-x: hidden;
    margin-top: 40px;
      }
      .ls-inn {
        border-radius: 5px;
       
        width: 60%px;
       
        color: #05144c;
       
        text-align: center;
       
        margin-top: -55px;
        margin-bottom: 50px;
       
        margin-left: 30px;
       
        font-size: 20px;
       
        font-family: "Times New Roman", Times, serif;
      }
      .lscard-details {
        width: 100%;

      
        /* margin-top: 200px; */
      
        display: flex;
      
        flex-direction: column;
      
        align-items: center;
      }

      .ls-create-ride-button1 {
        background-color: #05144c;
       
        color: white;
       
        border: none;
       
        padding: 10px 20px;
       
        font-size: 16px;
       
        margin-left: -20px;
       
        border-radius: 50px;
       
        cursor: pointer;
       
        text-decoration: none;
       
        margin-top: 0px;
        /* Adjust margin as needed */
      }
}

@media only screen and (min-width: 360px) and (max-width:374px) {
  /* Add your specific styles here */
  .ls-mainbb {
    border:1px solid white;
      }
      .ls-search-container {
        width: 100%;
       height:400px;
       margin-top: 120px;
       margin-left: 20p;
       
      }
      .ls-sectt {
        width: 280px;

       padding-top: 0px;
        margin-top: 0px;
       
        display: flex;
       
        gap: 5px;
      }
      .lscard{
   
       height:260px;
       margin-top: 70px;
       /* margin-bottom: 100px; */
       width:315px;
       margin-left: -20px;
       border: 1px solid rgb(205, 203, 203);
      }
      .lscard-1{
   
        height:350px;
        margin-top: -20px;
        width:250px;
        /* border: 1px solid black; */
       }
     
       .ls-res{
        margin-left: 80px;
       }
      .ls-create-ride-button1-container {
        margin-top: 50px;
       
        margin-left: 150px;
      }
       .SA-rider-full-card{
        margin-left: -60px;
        margin-top: 100px;
        font-size: 15px;
        width:310px;

       }
       .baggg {
        margin-left: 150px;
       width: 250px;
height: 250px;
       margin-top: -150px;
      }
      .filter{
        margin-left: 20px;
        font-size: 23px;
padding-top: 70px;
      }
   
        .ls-res1 {
          font-size: 18px;
         
          width: 330px;
         
          margin-left: 30px;
         
          font-family: "Times New Roman", Times, serif;
         
          margin-top: -00px;
        }
      
     
      .ls-arrow-icon{
        display: none;
      }
      .ls-gapsu1{
width: 300px;
        display: block;
        margin-left: -90px;
      }
      .ls-cal{
        width: 300px;


      }
      .ls-passenger-count{
      margin-top: 0px;
      }
      .ls-search-inputs button {
   
    margin-left: -35px;
      }
      .ls-sectp{

        width:280px;
        margin-top:16px;
      }
     .ls-leaving{
      width:320px;


     }
   
    .ls-passenger-controls{
      border:1px solid rgba(39, 94, 7, 0.437);

   
      margin-bottom: 10px;
    }
    
      .ls-passenger-input {
        width:270px;

    margin-top:10px;
    margin-left: 0px;
   
      }
     
      .SA-ride-normal-card{
        margin-left: -67px;
        margin-top: 120px;
        font-size: 15px;
        width:330px;

       }
   
      .lscard-seats-avail{
        margin-top: 145px;
        text-align: left;

        margin-left: -180px;
      }
    
   
   
      .lscard-user-image{
        width:50px;
       margin-top: -395px;
       margin-left: -330px;

        height:50px;
      }
      .lscard-seats-name{
        margin-left: -180px;
        margin-top: -180px;;
text-align: left;
/* border: 1px solid rgb(243, 16, 16); */
width: 250px;

     
      }
      .ls-passenger-controls {
        margin-top: 10px;
        width:100px;
      }
      .ls-search-results{
   
    width:350px;
    display:block;
    height:150px;
    margin-left: -30px;
      }
      .ls-inno{
        margin-left: 15px;
        width:340px;

   
      }
      
      .ls-create-ride-button-container {
        margin-top: 30px;
        margin-left: 30px;
    }
      .ride-info{
   
        height:260px;
      }
      .ride-info-1{
   
        height:200px;
      }
      .lscard-dest{
        font-size:10px;
        margin-left: -100px;
   
   
      }
   
      .lscard-dest-ride-full {
        font-size: 15px;
   
        margin-top: 100px;
   
        height: 100px;
        width: 500px; /* Example width */
        margin-left: 120px; /* Example margin-left */
    }
   
      .lscard-date{
        font-size:15px;
        margin-left: -25px;
        margin-top: 20px;
       
      }
      .lscard-date-full{
        font-size:15px;
        margin-left: -110px;
        margin-top: -60px;
      }
    
      .SA-lscard-user{
        font-size: 15px;
        /* margin-left: 50px; */
 
      }
      .lscard-location {
        width: 180px;
        margin-left: 14px;
        display: block;
   
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
       
   
      }
     
     
      /* .lscard-seats-name-full{
        margin-left: 20px;
        margin-top: -200px;
        text-align: left;

     
      } */

      .lscard-seats-name-full{
        margin-top: 5px;
        margin-left:-250px;
        /* margin-right: 350px; */
        /* border: 1px solid rgb(243, 16, 16); */
        text-align: left;

        width: 250px;

      }
      .lscard-dest-ride{
        font-size:15px;
        width:180px;
        height:50px;
       
        margin-top:130px;
        margin-left: 0px;
      }
   
      .lscard-price{
   
        margin-left: -2px;
        width:130px;
        margin-top: 3px;
        font-size:15px;
      }
      .lscard-location-full{
        width: 300px;
        margin-left: 100px;
        display: block;
        flex-direction: row;
        align-items: center;
        margin-top: 50px;
      }
      .lscard-time-date{
        margin-top: 10px;
       
        margin-left: 0px;
        font-size:15px;
        width:200px;
   
      }
      .lscard-user{
        font-size: 15px;
 
 
      }
      .lscard-seats-avail-full{
 
    margin-top:-210px;
    margin-left: -150px;
      }
      .full-text-full{
        margin-top: 0px;
        font-size: 15px;
        /* display:none; */
   
       
      }
     
      .lscard-user-image-full{
        width:50px;
       margin-top: -30px;
       margin-left: -500px;
        height:50px;
      }
     
   
      .lscard-time-date-full{
        margin-top: -23px;
       
        margin-left: 19px;
        font-size:15px;
        width:200px;
      }
      .lscard-price-full{
        margin-left: -50px;
        margin-top: -10px;
     
        font-size: 14px;
      }
     
 
     
   
      .ls-divide{

        width:335px;
        margin-left: 15px;
        margin-top: 15px;
       
      }
      .lsh-filter-container{
       
        margin-top: 400px;
        width:320px;
        margin-left: -10px;
        margin-bottom: 10px;
      }
      .ls-results-container{

    display:block;
    width:345px;
    overflow-x: hidden;
    margin-top: 40px;
      }
      .ls-inn {
        border-radius: 5px;
       
        width: 60%px;
       
        color: #05144c;
       
        text-align: center;
       
        margin-top: -55px;
        margin-bottom: 50px;
       
        margin-left: 30px;
       
        font-size: 20px;
       
        font-family: "Times New Roman", Times, serif;
      }
      .lscard-details {
        width: 100%;

      
        /* margin-top: 200px; */
      
        display: flex;
      
        flex-direction: column;
      
        align-items: center;
      }

      .ls-create-ride-button1 {
        background-color: #05144c;
       
        color: white;
       
        border: none;
       
        padding: 10px 20px;
       
        font-size: 16px;
       
        margin-left: -20px;
       
        border-radius: 50px;
       
        cursor: pointer;
       
        text-decoration: none;
       
        margin-top: 0px;
        /* Adjust margin as needed */
      }
}


@media only screen and (min-width: 375px) and (max-width:383px) {
  /* Add your specific styles here */
  .ls-mainbb {
    border:1px solid white;
      }
      .ls-search-container {
        width: 100%;
       height:400px;
       margin-top: 120px;
       margin-left: 20p;
       
      }
      .ls-sectt {
        width: 280px;

       padding-top: 0px;
        margin-top: 0px;
       
        display: flex;
       
        gap: 5px;
      }
      .lscard{
   
       height:260px;
       margin-top: 70px;
       /* margin-bottom: 100px; */
       width:330px;
       margin-left: -20px;
       border: 1px solid rgb(205, 203, 203);
      }
      .lscard-1{
   
        height:350px;
        margin-top: -20px;
        width:250px;
        /* border: 1px solid black; */
       }
     
       .ls-res{
        margin-left: 80px;
       }
      .ls-create-ride-button1-container {
        margin-top: 50px;
       
        margin-left: 150px;
      }
       .SA-rider-full-card{
        margin-left: -60px;
        margin-top: 100px;
        font-size: 15px;
        width:310px;

       }
       .baggg {
        margin-left: 150px;
       width: 300px;
height: 300px;
       margin-top: -150px;
      }
      .filter{
        margin-left: 20px;
        font-size: 23px;
padding-top: 70px;
      }
   
        .ls-res1 {
          font-size: 20px;
         
          width: 330px;
         
          margin-left: 30px;
         
          font-family: "Times New Roman", Times, serif;
         
          margin-top: -00px;
        }
      
     
      .ls-arrow-icon{
        display: none;
      }
      .ls-gapsu1{
width: 300px;
        display: block;
        margin-left: -90px;
      }
      .ls-cal{
        width: 300px;


      }
      .ls-passenger-count{
      margin-top: 0px;
      }
      .ls-search-inputs button {
   
    margin-left: -15px;
      }
      .ls-sectp{

        width:280px;
        margin-top:16px;
      }
     .ls-leaving{
      width:320px;


     }
   
    .ls-passenger-controls{
      border:1px solid rgba(39, 94, 7, 0.437);

   
      margin-bottom: 10px;
    }
    
      .ls-passenger-input {
        width:270px;

    margin-top:10px;
    margin-left: 0px;
   
      }
     
      .SA-ride-normal-card{
        margin-left: -75px;
        margin-top: 120px;
        font-size: 15px;
        width:330px;

       }
   
      .lscard-seats-avail{
        margin-top: 145px;
        text-align: left;

        margin-left: -180px;
      }
    
   
   
      .lscard-user-image{
        width:50px;
       margin-top: -395px;
       margin-left: -330px;

        height:50px;
      }
      .lscard-seats-name{
        margin-left: -180px;
        margin-top: -180px;;
text-align: left;
/* border: 1px solid rgb(243, 16, 16); */
width: 250px;

     
      }
      .ls-passenger-controls {
        margin-top: 10px;
        width:100px;
      }
      .ls-search-results{
   
    width:400px;
    display:block;
    height:150px;
    margin-left: -30px;
      }
      .ls-inno{
        margin-left: 15px;
        width:340px;

   
      }
      
      .ls-create-ride-button-container {
        margin-top: 30px;
        margin-left: 30px;
    }
      .ride-info{
   
        height:260px;
      }
      .ride-info-1{
   
        height:200px;
      }
      .lscard-dest{
        font-size:10px;
        margin-left: -100px;
   
   
      }
   
      .lscard-dest-ride-full {
        font-size: 15px;
   
        margin-top: 100px;
   
        height: 100px;
        width: 500px; /* Example width */
        margin-left: 120px; /* Example margin-left */
    }
   
      .lscard-date{
        font-size:15px;
        margin-left: -25px;
        margin-top: 20px;
       
      }
      .lscard-date-full{
        font-size:15px;
        margin-left: -110px;
        margin-top: -60px;
      }
    
      .SA-lscard-user{
        font-size: 15px;
        /* margin-left: 50px; */
 
      }
      .lscard-location {
        width: 180px;
        margin-left: 14px;
        display: block;
   
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
       
   
      }
     
     
      /* .lscard-seats-name-full{
        margin-left: 20px;
        margin-top: -200px;
        text-align: left;

     
      } */

      .lscard-seats-name-full{
        margin-top: 5px;
        margin-left:-250px;
        /* margin-right: 350px; */
        /* border: 1px solid rgb(243, 16, 16); */
        text-align: left;

        width: 250px;

      }
      .lscard-dest-ride{
        font-size:15px;
        width:180px;
        height:50px;
       
        margin-top:130px;
        margin-left: 0px;
      }
   
      .lscard-price{
   
        margin-left: -2px;
        width:130px;
        margin-top: 3px;
        font-size:15px;
      }
      .lscard-location-full{
        width: 300px;
        margin-left: 100px;
        display: block;
        flex-direction: row;
        align-items: center;
        margin-top: 50px;
      }
      .lscard-time-date{
        margin-top: 10px;
       
        margin-left: 0px;
        font-size:15px;
        width:200px;
   
      }
      .lscard-user{
        font-size: 15px;
 
 
      }
      .lscard-seats-avail-full{
 
    margin-top:-210px;
    margin-left: -150px;
      }
      .full-text-full{
        margin-top: 0px;
        font-size: 15px;
        /* display:none; */
   
       
      }
     
      .lscard-user-image-full{
        width:50px;
       margin-top: -30px;
       margin-left: -500px;
        height:50px;
      }
     
   
      .lscard-time-date-full{
        margin-top: -23px;
       
        margin-left: 19px;
        font-size:15px;
        width:200px;
      }
      .lscard-price-full{
        margin-left: -50px;
        margin-top: -10px;
     
        font-size: 14px;
      }
     
 
     
   
      .ls-divide{

        width:350px;
        margin-left: 15px;
        margin-top: 15px;
       
      }
      .lsh-filter-container{
       
        margin-top: 400px;
        width:335px;
        margin-left: -10px;
        margin-bottom: 10px;
      }
      .ls-results-container{

    display:block;
    width:355px;
    overflow-x: hidden;
    margin-top: 40px;
      }
      .ls-inn {
        border-radius: 5px;
       
        width: 60%px;
       
        color: #05144c;
       
        text-align: center;
       
        margin-top: -55px;
        margin-bottom: 50px;
       
        margin-left: 30px;
       
        font-size: 20px;
       
        font-family: "Times New Roman", Times, serif;
      }
      .lscard-details {
        width: 100%;

      
        /* margin-top: 200px; */
      
        display: flex;
      
        flex-direction: column;
      
        align-items: center;
      }

      .ls-create-ride-button1 {
        background-color: #05144c;
       
        color: white;
       
        border: none;
       
        padding: 10px 20px;
       
        font-size: 16px;
       
        margin-left: -20px;
       
        border-radius: 50px;
       
        cursor: pointer;
       
        text-decoration: none;
       
        margin-top: 0px;
        /* Adjust margin as needed */
      }
}

@media only screen and (min-width: 390px) and (max-width:411px) {
  /* Add your specific styles here */
  .ls-mainbb {
    border:1px solid white;
      }
      .ls-search-container {
        width: 100%;
       height:400px;
       margin-top: 120px;
       margin-left: 20p;
       
      }
      .ls-sectt {
        width: 300px;

       padding-top: 0px;
        margin-top: 0px;
       
        display: flex;
       
        gap: 5px;
      }
      .lscard{
   
       height:260px;
       margin-top: 70px;
       /* margin-bottom: 100px; */
       width:330px;
       margin-left: -20px;
       border: 1px solid rgb(209, 206, 206);
      }
      .lscard-1{
   
        height:350px;
        margin-top: -20px;
        width:250px;
        /* border: 1px solid black; */
       }
     
       .ls-res{
        margin-left: 80px;
       }
      .ls-create-ride-button1-container {
        margin-top: 50px;
       
        margin-left: 150px;
      }
       .SA-rider-full-card{
        margin-left: -60px;
        margin-top: 100px;
        font-size: 15px;
        width:310px;

       }
       .baggg {
        margin-left: 150px;
       width: 300px;
height: 300px;
       margin-top: -150px;
      }
      .filter{
        margin-left: 20px;
        font-size: 23px;
padding-top: 70px;
      }
   
        .ls-res1 {
          font-size: 20px;
         
          width: 330px;
         
          margin-left: 30px;
         
          font-family: "Times New Roman", Times, serif;
         
          margin-top: -00px;
        }
      
     
      .ls-arrow-icon{
        display: none;
      }
      .ls-gapsu1{
width: 300px;
        display: block;
        margin-left: -90px;
      }
      .ls-cal{
        width: 300px;


      }
      .ls-passenger-count{
      margin-top: 0px;
      }
      .ls-search-inputs button {
   
    margin-left: -15px;
      }
      .ls-sectp{

        width:300px;
        margin-top:16px;
      }
     .ls-leaving{
      width:320px;


     }
   
    .ls-passenger-controls{
      border:1px solid rgba(39, 94, 7, 0.437);

   
      margin-bottom: 10px;
    }
    
      .ls-passenger-input {
        width:290px;

    margin-top:10px;
    margin-left: 0px;
   
      }
     
      .SA-ride-normal-card{
        margin-left: -75px;
        margin-top: 120px;
        font-size: 15px;
        width:330px;

       }
   
      .lscard-seats-avail{
        margin-top: 145px;
        text-align: left;

        margin-left: -180px;
      }
    
   
   
      .lscard-user-image{
        width:50px;
       margin-top: -395px;
       margin-left: -330px;

        height:50px;
      }
      .lscard-seats-name{
        margin-left: -180px;
        margin-top: -180px;;
text-align: left;
/* border: 1px solid rgb(243, 16, 16); */
width: 250px;

     
      }
      .ls-passenger-controls {
        margin-top: 10px;
        width:100px;
      }
      .ls-search-results{
   
    width:400px;
    display:block;
    height:150px;
    margin-left: -30px;
      }
      .ls-inno{
        margin-left: 25px;
        width:338px;

   
      }
      
      .ls-create-ride-button-container {
        margin-top: 30px;
        margin-left: 30px;
    }
      .ride-info{
   
        height:260px;
      }
      .ride-info-1{
   
        height:200px;
      }
      .lscard-dest{
        font-size:10px;
        margin-left: -100px;
   
   
      }
   
      .lscard-dest-ride-full {
        font-size: 15px;
   
        margin-top: 100px;
   
        height: 100px;
        width: 500px; /* Example width */
        margin-left: 120px; /* Example margin-left */
    }
   
      .lscard-date{
        font-size:15px;
        margin-left: -25px;
        margin-top: 20px;
       
      }
      .lscard-date-full{
        font-size:15px;
        margin-left: -110px;
        margin-top: -60px;
      }
    
      .SA-lscard-user{
        font-size: 15px;
        /* margin-left: 50px; */
 
      }
      .lscard-location {
        width: 180px;
        margin-left: 14px;
        display: block;
   
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
       
   
      }
     
     
      /* .lscard-seats-name-full{
        margin-left: 20px;
        margin-top: -200px;
        text-align: left;

     
      } */

      .lscard-seats-name-full{
        margin-top: 5px;
        margin-left:-250px;
        /* margin-right: 350px; */
        /* border: 1px solid rgb(243, 16, 16); */
        text-align: left;

        width: 250px;

      }
      .lscard-dest-ride{
        font-size:15px;
        width:180px;
        height:50px;
       
        margin-top:130px;
        margin-left: 0px;
      }
   
      .lscard-price{
   
        margin-left: -2px;
        width:130px;
        margin-top: 3px;
        font-size:15px;
      }
      .lscard-location-full{
        width: 300px;
        margin-left: 100px;
        display: block;
        flex-direction: row;
        align-items: center;
        margin-top: 50px;
      }
      .lscard-time-date{
        margin-top: 10px;
       
        margin-left: 0px;
        font-size:15px;
        width:200px;
   
      }
      .lscard-user{
        font-size: 15px;
 
 
      }
      .lscard-seats-avail-full{
 
    margin-top:-210px;
    margin-left: -150px;
      }
      .full-text-full{
        margin-top: 0px;
        font-size: 15px;
        /* display:none; */
   
       
      }
     
      .lscard-user-image-full{
        width:50px;
       margin-top: -30px;
       margin-left: -500px;
        height:50px;
      }
     
   
      .lscard-time-date-full{
        margin-top: -23px;
       
        margin-left: 19px;
        font-size:15px;
        width:200px;
      }
      .lscard-price-full{
        margin-left: -50px;
        margin-top: -10px;
     
        font-size: 14px;
      }
     
 
     
   
      .ls-divide{

        width:350px;
        margin-left: 20px;
        margin-top: 15px;
       
      }
      .lsh-filter-container{
       
        margin-top: 400px;
        width:335px;
        margin-bottom: 10px;
      }
      .ls-results-container{

    display:block;
    width:370px;
    overflow-x: hidden;
    margin-top: 40px;
      }
      .ls-inn {
        border-radius: 5px;
       
        width: 60%px;
       
        color: #05144c;
       
        text-align: center;
       
        margin-top: -55px;
        margin-bottom: 50px;
       
        margin-left: 30px;
       
        font-size: 20px;
       
        font-family: "Times New Roman", Times, serif;
      }
      .lscard-details {
        width: 100%;

      
        /* margin-top: 200px; */
      
        display: flex;
      
        flex-direction: column;
      
        align-items: center;
      }

      .ls-create-ride-button1 {
        background-color: #05144c;
       
        color: white;
       
        border: none;
       
        padding: 10px 20px;
       
        font-size: 16px;
       
        margin-left: -20px;
       
        border-radius: 50px;
       
        cursor: pointer;
       
        text-decoration: none;
       
        margin-top: 0px;
        /* Adjust margin as needed */
      }
}
@media only screen and (min-width: 412px) and (max-width:427px) {
  /* Add your specific styles here */
  .ls-mainbb {
    border:1px solid white;
      }
      .ls-search-container {
        width: 100%;
       height:430px;
       margin-top: 120px;
       margin-left: 20p;
       
      }
      .ls-sectt {
        width: 320px;

       padding-top: 0px;
        margin-top: 0px;
       
        display: flex;
       
        gap: 5px;
      }
      .lscard{
   
       height:260px;
       margin-top: 70px;
       /* margin-bottom: 100px; */
       width:350px;
       margin-left: -20px;
       border: 1px solid rgb(209, 206, 206);
      }
      .lscard-1{
   
        height:350px;
        margin-top: -20px;
        width:250px;
        /* border: 1px solid black; */
       }
     
       .ls-res{
        margin-left: 80px;
       }
      .ls-create-ride-button1-container {
        margin-top: 50px;
       
        margin-left: 150px;
      }
       .SA-rider-full-card{
        margin-left: -60px;
        margin-top: 100px;
        font-size: 15px;
        width:310px;

       }
       .baggg {
        margin-left: 150px;
       width: 300px;
height: 300px;
       margin-top: -150px;
      }
      .filter{
        margin-left: 20px;
        font-size: 23px;
padding-top: 70px;
      }
   
        .ls-res1 {
          font-size: 22px;
         
          width: 370px;
         
          margin-left: 30px;
         
          font-family: "Times New Roman", Times, serif;
         
          margin-top: -00px;
        }
      
     
      .ls-arrow-icon{
        display: none;
      }
      .ls-gapsu1{
width: 320px;
        display: block;
        margin-left: -90px;
      }
      .ls-cal{
        width: 300px;


      }
      .ls-passenger-count{
      margin-top: 0px;
      }
      .ls-search-inputs button {
   
    margin-left: -15px;
      }
      .ls-sectp{

        width:320px;
        margin-top:16px;
      }
     .ls-leaving{
      width:320px;


     }
   
    .ls-passenger-controls{
      border:1px solid rgba(39, 94, 7, 0.437);

   
      margin-bottom: 10px;
    }
    
      .ls-passenger-input {
        width:310px;

    margin-top:10px;
    margin-left: 0px;
   
      }
     
      .SA-ride-normal-card{
        margin-left: -75px;
        margin-top: 120px;
        font-size: 15px;
        width:330px;

       }
   
      .lscard-seats-avail{
        margin-top: 145px;
        text-align: left;

        margin-left: -180px;
      }
    
   
   
      .lscard-user-image{
        width:50px;
       margin-top: -395px;
       margin-left: -330px;

        height:50px;
      }
      .lscard-seats-name{
        margin-left: -180px;
        margin-top: -180px;;
text-align: left;
/* border: 1px solid rgb(243, 16, 16); */
width: 250px;

     
      }
      .ls-passenger-controls {
        margin-top: 10px;
        width:100px;
      }
      .ls-search-results{
   
    width:400px;
    display:block;
    height:150px;
    margin-left: -30px;
      }
      .ls-inno{
        margin-left: 40px;
        width:380px;

   
      }
      
      .ls-create-ride-button-container {
        margin-top: 30px;
        margin-left: 30px;
    }
      .ride-info{
   
        height:260px;
      }
      .ride-info-1{
   
        height:200px;
      }
      .lscard-dest{
        font-size:10px;
        margin-left: -100px;
   
   
      }
   
      .lscard-dest-ride-full {
        font-size: 15px;
   
        margin-top: 100px;
   
        height: 100px;
        width: 500px; /* Example width */
        margin-left: 120px; /* Example margin-left */
    }
   
      .lscard-date{
        font-size:15px;
        margin-left: -25px;
        margin-top: 20px;
       
      }
      .lscard-date-full{
        font-size:15px;
        margin-left: -110px;
        margin-top: -60px;
      }
    
      .SA-lscard-user{
        font-size: 15px;
        /* margin-left: 50px; */
 
      }
      .lscard-location {
        width: 180px;
        margin-left: 14px;
        display: block;
   
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
       
   
      }
     
     
      /* .lscard-seats-name-full{
        margin-left: 20px;
        margin-top: -200px;
        text-align: left;

     
      } */

      .lscard-seats-name-full{
        margin-top: 5px;
        margin-left:-250px;
        /* margin-right: 350px; */
        /* border: 1px solid rgb(243, 16, 16); */
        text-align: left;

        width: 250px;

      }
      .lscard-dest-ride{
        font-size:15px;
        width:180px;
        height:50px;
       
        margin-top:130px;
        margin-left: 0px;
      }
   
      .lscard-price{
   
        margin-left: -2px;
        width:130px;
        margin-top: 3px;
        font-size:15px;
      }
      .lscard-location-full{
        width: 300px;
        margin-left: 100px;
        display: block;
        flex-direction: row;
        align-items: center;
        margin-top: 50px;
      }
      .lscard-time-date{
        margin-top: 10px;
       
        margin-left: 0px;
        font-size:15px;
        width:200px;
   
      }
      .lscard-user{
        font-size: 15px;
 
 
      }
      .lscard-seats-avail-full{
 
    margin-top:-210px;
    margin-left: -150px;
      }
      .full-text-full{
        margin-top: 0px;
        font-size: 15px;
        /* display:none; */
   
       
      }
     
      .lscard-user-image-full{
        width:50px;
       margin-top: -30px;
       margin-left: -500px;
        height:50px;
      }
     
   
      .lscard-time-date-full{
        margin-top: -23px;
       
        margin-left: 19px;
        font-size:15px;
        width:200px;
      }
      .lscard-price-full{
        margin-left: -50px;
        margin-top: -10px;
     
        font-size: 14px;
      }
     
 
     
   
      .ls-divide{

        width:370px;
        margin-left: 20px;
        margin-top: 15px;
       
      }
      .lsh-filter-container{
       
        margin-top: 400px;
        width:365px;
        margin-bottom: 10px;
      }
      .ls-results-container{

    display:block;
    width:400px;
    overflow-x: hidden;
    margin-top: 60px;
      }
      .ls-inn {
        border-radius: 5px;
       
        width: 60%px;
       
        color: #05144c;
       
        text-align: center;
       
        margin-top: -55px;
        margin-bottom: 50px;
       
        margin-left: 30px;
       
        font-size: 20px;
       
        font-family: "Times New Roman", Times, serif;
      }
      .lscard-details {
        width: 100%;

      
        /* margin-top: 200px; */
      
        display: flex;
      
        flex-direction: column;
      
        align-items: center;
      }
}
@media only screen and (min-width: 428px) and (max-width:767px) {
  /* Add your specific styles here */
  .ls-mainbb {
    border:1px solid white;
      }
      .ls-search-container {
        width: 100%;
       height:430px;
       margin-top: 120px;
       margin-left: 20p;
       
      }
      .ls-sectt {
        width: 320px;

       padding-top: 0px;
        margin-top: 0px;
       
        display: flex;
       
        gap: 5px;
      }
      .lscard{
   
       height:260px;
       margin-top: 70px;
       /* margin-bottom: 100px; */
       width:350px;
       margin-left: -20px;
       border: 1px solid rgb(209, 206, 206);
      }
      .lscard-1{
   
        height:350px;
        margin-top: -20px;
        width:250px;
        /* border: 1px solid black; */
       }
     
       .ls-res{
        margin-left: 80px;
       }
      .ls-create-ride-button1-container {
        margin-top: 50px;
       
        margin-left: 150px;
      }
       .SA-rider-full-card{
        margin-left: -60px;
        margin-top: 100px;
        font-size: 15px;
        width:310px;

       }
       .baggg {
        margin-left: 150px;
       width: 300px;
height: 300px;
       margin-top: -150px;
      }
      .filter{
        margin-left: 20px;
        font-size: 23px;
padding-top: 70px;
      }
   
        .ls-res1 {
          font-size: 22px;
         
          width: 370px;
         
          margin-left: 30px;
         
          font-family: "Times New Roman", Times, serif;
         
          margin-top: -00px;
        }
      
     
      .ls-arrow-icon{
        display: none;
      }
      .ls-gapsu1{
width: 320px;
        display: block;
        margin-left: -90px;
      }
      .ls-cal{
        width: 300px;


      }
      .ls-passenger-count{
      margin-top: 0px;
      }
      .ls-search-inputs button {
   
    margin-left: -15px;
      }
      .ls-sectp{

        width:320px;
        margin-top:16px;
      }
     .ls-leaving{
      width:320px;


     }
   
    .ls-passenger-controls{
      border:1px solid rgba(39, 94, 7, 0.437);

   
      margin-bottom: 10px;
    }
    
      .ls-passenger-input {
        width:310px;

    margin-top:10px;
    margin-left: 0px;
   
      }
     
      .SA-ride-normal-card{
        margin-left: -75px;
        margin-top: 120px;
        font-size: 15px;
        width:330px;

       }
   
      .lscard-seats-avail{
        margin-top: 145px;
        text-align: left;

        margin-left: -180px;
      }
    
   
   
      .lscard-user-image{
        width:50px;
       margin-top: -395px;
       margin-left: -330px;

        height:50px;
      }
      .lscard-seats-name{
        margin-left: -180px;
        margin-top: -180px;;
text-align: left;
/* border: 1px solid rgb(243, 16, 16); */
width: 250px;

     
      }
      .ls-passenger-controls {
        margin-top: 10px;
        width:100px;
      }
      .ls-search-results{
   
    width:400px;
    display:block;
    height:150px;
    margin-left: -30px;
      }
      .ls-inno{
        margin-left: 40px;
        width:380px;

   
      }
      
      .ls-create-ride-button-container {
        margin-top: 30px;
        margin-left: 30px;
    }
      .ride-info{
   
        height:260px;
      }
      .ride-info-1{
   
        height:200px;
      }
      .lscard-dest{
        font-size:10px;
        margin-left: -100px;
   
   
      }
   
      .lscard-dest-ride-full {
        font-size: 15px;
   
        margin-top: 100px;
   
        height: 100px;
        width: 500px; /* Example width */
        margin-left: 120px; /* Example margin-left */
    }
   
      .lscard-date{
        font-size:15px;
        margin-left: -25px;
        margin-top: 20px;
       
      }
      .lscard-date-full{
        font-size:15px;
        margin-left: -110px;
        margin-top: -60px;
      }
    
      .SA-lscard-user{
        font-size: 15px;
        /* margin-left: 50px; */
 
      }
      .lscard-location {
        width: 180px;
        margin-left: 14px;
        display: block;
   
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
       
   
      }
     
     
      /* .lscard-seats-name-full{
        margin-left: 20px;
        margin-top: -200px;
        text-align: left;

     
      } */

      .lscard-seats-name-full{
        margin-top: 5px;
        margin-left:-250px;
        /* margin-right: 350px; */
        /* border: 1px solid rgb(243, 16, 16); */
        text-align: left;

        width: 250px;

      }
      .lscard-dest-ride{
        font-size:15px;
        width:180px;
        height:50px;
       
        margin-top:130px;
        margin-left: 0px;
      }
   
      .lscard-price{
   
        margin-left: -2px;
        width:130px;
        margin-top: 3px;
        font-size:15px;
      }
      .lscard-location-full{
        width: 300px;
        margin-left: 100px;
        display: block;
        flex-direction: row;
        align-items: center;
        margin-top: 50px;
      }
      .lscard-time-date{
        margin-top: 10px;
       
        margin-left: 0px;
        font-size:15px;
        width:200px;
   
      }
      .lscard-user{
        font-size: 15px;
 
 
      }
      .lscard-seats-avail-full{
 
    margin-top:-210px;
    margin-left: -150px;
      }
      .full-text-full{
        margin-top: 0px;
        font-size: 15px;
        /* display:none; */
   
       
      }
     
      .lscard-user-image-full{
        width:50px;
       margin-top: -30px;
       margin-left: -500px;
        height:50px;
      }
     
   
      .lscard-time-date-full{
        margin-top: -23px;
       
        margin-left: 19px;
        font-size:15px;
        width:200px;
      }
      .lscard-price-full{
        margin-left: -50px;
        margin-top: -10px;
     
        font-size: 14px;
      }
     
 
     
   
      .ls-divide{

        width:370px;
        margin-left: 20px;
        margin-top: 15px;
       
      }
      .lsh-filter-container{
       
        margin-top: 400px;
        width:365px;
        margin-bottom: 10px;
      }
      .ls-results-container{

    display:block;
    width:400px;
    overflow-x: hidden;
    margin-top: 60px;
      }
      .ls-inn {
        border-radius: 5px;
       
        width: 60%px;
       
        color: #05144c;
       
        text-align: center;
       
        margin-top: -55px;
        margin-bottom: 50px;
       
        margin-left: 30px;
       
        font-size: 20px;
       
        font-family: "Times New Roman", Times, serif;
      }
      .lscard-details {
        width: 100%;

      
        /* margin-top: 200px; */
      
        display: flex;
      
        flex-direction: column;
      
        align-items: center;
      }
}


@media only screen and (min-width: 768px) and (max-width: 799px) {
 
  .lscard {
    border: 1px solid #7e7d7d;
  
    border-radius: 8px;
  
    padding: 16px;
  
    margin: 0px;
  
    color: #05144c;
  
    width: 51vw;
  margin-top: -30px;
    height: 25vh;
  
    display: flex;
  
    flex-direction: column;
  
    align-items: center;
  }
  .lsh-filter-container {
    display: block;

    flex-direction: column;

    align-items: flex-start;

    padding: 10px;

    width: 270px;

    height: 340px;

    margin-top: -43vh;
    /* Adjust this margin as needed */

    border: 1px solid #ccc;

    border-radius: 8px;

    background-color: #f9f9f9;
  }
  .lscard-dest-Efficient{
    margin-top: 50px;
  }
  .lsh-clear-filters {
    margin-right: 10px;

    padding: 7px 10px;

    background-color: #05144c;

    color: white;

    border: none;

    border-radius: 50px;

    cursor: pointer;
  }

  .ls-create-ride-button {
    background-color: #05144c;

    color: white;

    border: none;

    padding: 15px 20px;

    font-size: 16px;

    margin-left: 300px;

    border-radius: 50px;

    cursor: pointer;

    text-decoration: none;

    margin-bottom: -200px;
    /* Adjust margin as needed */
  }

  .ls-create-ride-button-container {
    margin-top: 30px;

    margin-left: 0px;
  }

  .ls-create-ride-button1 {
    background-color: #05144c;

    color: white;

    border: none;

    padding: 15px 20px;

    font-size: 16px;

    margin-left: 330px;

    border-radius: 50px;

    cursor: pointer;

    text-decoration: none;

    margin-bottom: -200px;
    /* Adjust margin as needed */
  }

  .ls-create-ride-button1-container {
    margin-top: 30px;

    margin-left: 0px;
  }

  .ls-res {
    margin-left: 50px;

    margin-top: 20px;
  }

  .ls-inno {
    margin-left: 400px;
  }

  .ls-search-results {

    margin-left: -30px;
  }

  .slidee {
    display: none;
  }

  .ls-fa {
    margin-left: -700px;

    margin-top: 230px;
  }

  

  .details {
    text-align: center;

    margin-right: 85px;
  }

  .man-image {
    margin-left: 40px;

    width: 50px;
    /* Adjust the width of the image if necessary */

    height: 50px;
    /* Adjust the height of the image if necessary */

    margin-top: 5px;
  }

  .rahul {
    font-size: 15px;

    margin-top: -20px;
  }

  .ls-sectt {
    width: 300px;

    margin-left: 30px;

    gap: 10px;
  }



  .ride-info {
    width: 370px;

    margin-left: -15px;
  }

  .ls-arrow-icon {
    display: none;
  }



  .ls-sectp {
width: 320px;
    margin-left: 20px;

    gap: 10px;
  }

  .ls-passenger-count {
    width: 300px;
  }

  .ls-passenger-input {
    gap: 10px;
    width: 300px;
  }

  .location-info {
    margin-left: 100px;

    margin-top: -10px;
  }

  .ls-additional-info {
    font-size: 20px;

    width: 400px;
  }

  .filter {
    margin-left: 50px;

    margin-top: 100px;
  }

  .baggg {
    margin-left: 350px;

    width: 350px;

    height: 350px;

    margin-top: -160px;
  }

  .ls-res1 {
    margin-left: 140px;

    font-size: 25px;

    width: 500px;

    margin-top: 0px;
  }

  .ls-search-container {
    height: 270px;
  }

  .ls-search {
    margin-left: 300px;
  }

  .location-info>div {
    margin-left: -500px;

    font-size: 15px;
  }

  .ls-results-container {
 

    border: 1px solid rgb(239, 236, 236);

    width: 735px;

    margin-top: 50px;

    height: 580px;
    /* Fixed height for the container */

    background-color: #f6f8f9;

    margin-left: -20px;
    /* Remove any left margin */

    margin-right: -0px;
    /* Remove any right margin */

    padding-left: 20px;
    /* Adjust padding as needed */

    padding-right: 0px;
    /* Adjust padding as needed */

    overflow-y: auto;
    /* Enable vertical scrolling */
  }

    
  .ls-search-inputs {
    /* border: 1px solid rgb(224, 20, 20); */

    display: flex;
  
    flex-wrap: wrap;
  margin-left: -10px;
    gap: 20px;
  
    margin-bottom: 10px;
  }
   
  
  .ls-gapsu1 {
    /* border: 1px solid rgb(224, 20, 20); */
    width: 700px;

    display: flex;
  }
  
  .ls-leaving {
    /* border: 1px solid rgb(226, 21, 21); */
  
    width: 300px;
  
    /* Adjust the height as needed */
  
    border-radius: 5px;
  }
  
  .ls-search-inputs input,
  .ls-search-inputs select {
    
    flex: 1;
  
    padding: 10px;
  
    border: 1px solid rgb(176, 173, 173);
  
    border-radius: 5px;
  
    font-size: 16px;
  
    /* Set max height for input and select */
  }
  
  .ls-search-inputs select {
    flex: 1;
  
    padding: 10px;
  
    border: 1px solid rgb(176, 173, 173);
  
    border-radius: 5px;
  
    font-size: 16px;
  
    /* Set max height for input and select */
  }
  
  .ls-search-inputs button {
    padding: 10px 20px;
  
    height: 60px;
  
    color: #fff;
  
    border: none;
  
    border-radius: 50px;
  
    margin-left: 30px;
  
    cursor: pointer;
  }
  
  .ls-search-inputs button:disabled {
    background-color: #ccc;
  
    cursor: not-allowed;
  }

  .ls-passenger-input {
    margin-left: 27px;
  }

  .ls-divide {
    border: 1px solid rgb(176, 173, 173);

    border-radius: 10px;

    overflow-y: scroll;

    width: 418px;

    margin-left: 320px;
    height: 480px;
  }

  .search-container {
    display: flex;

    margin-left: 30%;

    justify-content: center;
    /* Center horizontally */

    align-items: center;
    /* Center vertically, if necessary */

    margin-top: 10px;
    /* Space above the button */
  }

  .ls-search {
    width: 200px;

    border: 2px solid black;
  }
  
  .lscard-dest {
    display: flex;
    justify-content: space-between;
  
    margin-top: 0px;
    width: 100%;
    /* border: 1px solid rgb(251, 9, 9); */
  }
  .lscard-time-date-full {
    display: flex;
  
    /* align-items: center; */
    text-align: left;
    justify-content: space-between;
  
    width: 60%;
    margin-right: 160px;
    margin-top: -20px;
  }
  
  .lscard-price-full {
    display: block;

    margin-left: 300px;
  
    margin-top: -15px;
  }
  .lscard-seats-avail-full{
    margin-top:20px;
  
  }
  .lscard-seats-name-full{

    width: 170px;
  }
  .lscard-dest-ride{

  margin-top: 0px;
    width: 400px;
    height: 30px;
  
  }
  .lscard-time-date {
    display: flex;
  
    /* align-items: center; */
    text-align: left;
    justify-content: space-between;
  
    width: 80%;
    margin-right: 80px;
    margin-top: 0px;
  }
  .lscard-price {
    display: block;

    margin-left: -240px;
  
    margin-top: 0px;
  }
  .lscard-user {
    display: block;

    margin-top: -160px;
  
    margin-left: 65%;
  }
  
  .lscard-user-image {
    width: 80px;
  
    height: 80px;
  
    margin-top: 10px;
    border-radius: 50%;
  
    margin-right: -6px;
  }
  .lscard-user-image-full {
    width: 80px;
  
    height: 80px;
  
    margin-top: -1px;
    border-radius: 50%;
  
    margin-right: -6px;
  }
  .SA-ride-normal-card{
    padding-top: 10px;
  }
  .full-text-full{
    padding-top: 0px;
    font-size: 25px;
    /* display:none; */

   
  }
 
  
  .lscard-user-id {
    font-size: 16px;
  }
  .lscard-seats-avail{
width: 170px;
    margin-top: 10px;
    margin-left: -5%;


  }
  .ls-create-ride-button-container {
    margin-top: -530px;

    margin-left: -150px;
  }
  
  .lsh-filter-container {
    display: flex;
  
    flex-direction: column;
  
    align-items: flex-start;
  
    padding: 10px;
  
    width: 280px;
  
    height: 350px;
  
    margin-top: 3vh;
    /* Adjust this margin as needed */
  
    border: 1px solid #ccc;
  
    border-radius: 8px;
  
    background-color: #f9f9f9;
  }
  
  .ls-res {
    text-align: center;
  
    font-family: "Times New Roman", Times, serif;
  
    margin-top: -15px;
  
    margin-right: -150px;
  }
  .lscard-details {
    width: 100%;
  
    /* margin-top: 200px; */
  
    display: flex;

    flex-direction: column;
  
    align-items: center;
  }
  
  
}
@media only screen and (min-width: 800px) and (max-width: 819px) {
 
  .lscard {
    border: 1px solid #7e7d7d;
  
    border-radius: 8px;
  
    padding: 16px;
  
    margin: 0px;
  
    color: #05144c;
  
    width: 54vw;
  margin-top: -30px;
    height: 22vh;
  
    display: flex;
  
    flex-direction: column;
  
    align-items: center;
  }
  .lsh-filter-container {
    display: block;

    flex-direction: column;

    align-items: flex-start;

    padding: 10px;

    width: 270px;

    height: 340px;

    margin-top: -43vh;
    /* Adjust this margin as needed */

    border: 1px solid #ccc;

    border-radius: 8px;

    background-color: #f9f9f9;
  }
  .lscard-dest-Efficient{
    margin-top: 50px;
  }
  .lsh-clear-filters {
    margin-right: 10px;

    padding: 7px 10px;

    background-color: #05144c;

    color: white;

    border: none;

    border-radius: 50px;

    cursor: pointer;
  }

  .ls-create-ride-button {
    background-color: #05144c;

    color: white;

    border: none;

    padding: 15px 20px;

    font-size: 16px;

    margin-left: 300px;

    border-radius: 50px;

    cursor: pointer;

    text-decoration: none;

    margin-bottom: -200px;
    /* Adjust margin as needed */
  }

  .ls-create-ride-button-container {
    margin-top: 30px;

    margin-left: 0px;
  }

  .ls-create-ride-button1 {
    background-color: #05144c;

    color: white;

    border: none;

    padding: 15px 20px;

    font-size: 16px;

    margin-left: 330px;

    border-radius: 50px;

    cursor: pointer;

    text-decoration: none;

    margin-bottom: -200px;
    /* Adjust margin as needed */
  }

  .ls-create-ride-button1-container {
    margin-top: 30px;

    margin-left: 0px;
  }

  .ls-res {
    margin-left: 50px;

    margin-top: 20px;
  }

  .ls-inno {
    margin-left: 400px;
  }

  .ls-search-results {

    margin-left: -30px;
  }

  .slidee {
    display: none;
  }

  .ls-fa {
    margin-left: -700px;

    margin-top: 230px;
  }

  

  .details {
    text-align: center;

    margin-right: 85px;
  }

  .man-image {
    margin-left: 40px;

    width: 50px;
    /* Adjust the width of the image if necessary */

    height: 50px;
    /* Adjust the height of the image if necessary */

    margin-top: 5px;
  }

  .rahul {
    font-size: 15px;

    margin-top: -20px;
  }

  .ls-sectt {
    width: 300px;

    margin-left: 30px;

    gap: 10px;
  }



  .ride-info {
    width: 370px;

    margin-left: -15px;
  }

  .ls-arrow-icon {
    display: none;
  }



  .ls-sectp {
width: 320px;
    margin-left: 20px;

    gap: 10px;
  }

  .ls-passenger-count {
    width: 300px;
  }

  .ls-passenger-input {
    gap: 10px;
    width: 300px;
  }

  .location-info {
    margin-left: 100px;

    margin-top: -10px;
  }

  .ls-additional-info {
    font-size: 20px;

    width: 400px;
  }

  .filter {
    margin-left: 50px;

    margin-top: 100px;
  }

  .baggg {
    margin-left: 350px;

    width: 350px;

    height: 350px;

    margin-top: -160px;
  }

  .ls-res1 {
    margin-left: 140px;

    font-size: 25px;

    width: 500px;

    margin-top: 0px;
  }

  .ls-search-container {
    height: 270px;
  }

  .ls-search {
    margin-left: 300px;
  }

  .location-info>div {
    margin-left: -500px;

    font-size: 15px;
  }

  .ls-results-container {
 

    border: 1px solid rgb(239, 236, 236);

    width: 765px;

    margin-top: 50px;

    height: 580px;
    /* Fixed height for the container */

    background-color: #f6f8f9;

    margin-left: -20px;
    /* Remove any left margin */

    margin-right: -0px;
    /* Remove any right margin */

    padding-left: 20px;
    /* Adjust padding as needed */

    padding-right: 0px;
    /* Adjust padding as needed */

    overflow-y: auto;
    /* Enable vertical scrolling */
  }

    
  .ls-search-inputs {
    /* border: 1px solid rgb(224, 20, 20); */

    display: flex;
  
    flex-wrap: wrap;
  margin-left: 30px;
    gap: 20px;
  
    margin-bottom: 10px;
  }
   
  
  .ls-gapsu1 {
    /* border: 1px solid rgb(224, 20, 20); */
    width: 700px;

    display: flex;
  }
  
  .ls-leaving {
    /* border: 1px solid rgb(226, 21, 21); */
  
    width: 300px;
  
    /* Adjust the height as needed */
  
    border-radius: 5px;
  }
  
  .ls-search-inputs input,
  .ls-search-inputs select {
    
    flex: 1;
  
    padding: 10px;
  
    border: 1px solid rgb(176, 173, 173);
  
    border-radius: 5px;
  
    font-size: 16px;
  
    /* Set max height for input and select */
  }
  
  .ls-search-inputs select {
    flex: 1;
  
    padding: 10px;
  
    border: 1px solid rgb(176, 173, 173);
  
    border-radius: 5px;
  
    font-size: 16px;
  
    /* Set max height for input and select */
  }
  
  .ls-search-inputs button {
    padding: 10px 20px;
  
    height: 60px;
  
    color: #fff;
  
    border: none;
  
    border-radius: 50px;
  
    margin-left: 30px;
  
    cursor: pointer;
  }
  
  .ls-search-inputs button:disabled {
    background-color: #ccc;
  
    cursor: not-allowed;
  }

  .ls-passenger-input {
    margin-left: 27px;
  }

  .ls-divide {
    border: 1px solid rgb(176, 173, 173);

    border-radius: 10px;

    overflow-y: scroll;

    width: 470px;

    margin-left: 320px;
    height: 480px;
  }

  .search-container {
    display: flex;

    margin-left: 30%;

    justify-content: center;
    /* Center horizontally */

    align-items: center;
    /* Center vertically, if necessary */

    margin-top: 10px;
    /* Space above the button */
  }

  .ls-search {
    width: 200px;

    border: 2px solid black;
  }
  
  .lscard-dest {
    display: flex;
    justify-content: space-between;
  
    margin-top: 0px;
    width: 100%;
    /* border: 1px solid rgb(251, 9, 9); */
  }
  .lscard-time-date-full {
    display: flex;
  
    /* align-items: center; */
    text-align: left;
    justify-content: space-between;
  
    width: 60%;
    margin-right: 160px;
    margin-top: -20px;
  }
  
  .lscard-price-full {
    display: block;

    margin-left: 300px;
  
    margin-top: -15px;
  }
  .lscard-seats-avail-full{
    margin-top:20px;
  
  }
  .lscard-seats-name-full{

    width: 170px;
  }
  .lscard-dest-ride{

  margin-top: 0px;
    width: 400px;
    height: 30px;
  
  }
  .lscard-time-date {
    display: flex;
  
    /* align-items: center; */
    text-align: left;
    justify-content: space-between;
  
    width: 80%;
    margin-right: 80px;
    margin-top: 0px;
  }
  .lscard-price {
    display: block;

    margin-left: -240px;
  
    margin-top: 0px;
  }
  .lscard-user {
    display: block;

    margin-top: -160px;
  
    margin-left: 65%;
  }
  
  .lscard-user-image {
    width: 100px;
  
    height: 100px;
  
    margin-top: 10px;
    border-radius: 50%;
  
    margin-right: -6px;
  }
  .lscard-user-image-full {
    width: 100px;
  
    height: 100px;
  
    margin-top: 0px;
    border-radius: 50%;
  
    margin-right: -6px;
  }
  .SA-ride-normal-card{
    padding-top: 10px;
  }
  
  .lscard-user-id {
    font-size: 16px;
  }
  .lscard-seats-avail{
width: 170px;
    margin-top: 10px;
    margin-left: -5%;


  }
  .ls-create-ride-button-container {
    margin-top: -530px;

    margin-left: -150px;
  }
  
  .lsh-filter-container {
    display: flex;
  
    flex-direction: column;
  
    align-items: flex-start;
  
    padding: 10px;
  
    width: 280px;
  
    height: 350px;
  
    margin-top: 3vh;
    /* Adjust this margin as needed */
  
    border: 1px solid #ccc;
  
    border-radius: 8px;
  
    background-color: #f9f9f9;
  }
  
  .ls-res {
    text-align: center;
  
    font-family: "Times New Roman", Times, serif;
  
    margin-top: -15px;
  
    margin-right: -150px;
  }
  .lscard-details {
    width: 100%;
  
    /* margin-top: 200px; */
  
    display: flex;

    flex-direction: column;
  
    align-items: center;
  }
  
  
}
@media only screen and (min-width: 820px) and (max-width: 852px) {
 
  .lscard {
    border: 1px solid #7e7d7d;
  
    border-radius: 8px;
  
    padding: 16px;
  
    margin: 0px;
  
    color: #05144c;
  
    width: 54vw;
  margin-top: -30px;
    height: 23.5vh;
  
    display: flex;
  
    flex-direction: column;
  
    align-items: center;
  }
  .lsh-filter-container {
    display: block;

    flex-direction: column;

    align-items: flex-start;

    padding: 10px;

    width: 270px;

    height: 340px;

    margin-top: -43vh;
    /* Adjust this margin as needed */

    border: 1px solid #ccc;

    border-radius: 8px;

    background-color: #f9f9f9;
  }

  .lsh-clear-filters {
    margin-right: 10px;

    padding: 7px 10px;

    background-color: #05144c;

    color: white;

    border: none;

    border-radius: 50px;

    cursor: pointer;
  }
  .lscard-dest-Efficient{
    margin-top: 50px;
  }
  .ls-create-ride-button {
    background-color: #05144c;

    color: white;

    border: none;

    padding: 15px 20px;

    font-size: 16px;

    margin-left: 300px;

    border-radius: 50px;

    cursor: pointer;

    text-decoration: none;

    margin-bottom: -200px;
    /* Adjust margin as needed */
  }

  .ls-create-ride-button-container {
    margin-top: 30px;

    margin-left: 0px;
  }

  .ls-create-ride-button1 {
    background-color: #05144c;

    color: white;

    border: none;

    padding: 15px 20px;

    font-size: 16px;

    margin-left: 320px;

    border-radius: 50px;

    cursor: pointer;

    text-decoration: none;

    margin-bottom: -200px;
    /* Adjust margin as needed */
  }

  .ls-create-ride-button1-container {
    margin-top: 30px;

    margin-left: 0px;
  }

  .ls-res {
    margin-left: 50px;

    margin-top: 20px;
  }

  .ls-inno {
    margin-left: 400px;
  }

  .ls-search-results {

    margin-left: -30px;
  }

  .slidee {
    display: none;
  }

  .ls-fa {
    margin-left: -700px;

    margin-top: 230px;
  }

  

  .details {
    text-align: center;

    margin-right: 85px;
  }

  .man-image {
    margin-left: 40px;

    width: 50px;
    /* Adjust the width of the image if necessary */

    height: 50px;
    /* Adjust the height of the image if necessary */

    margin-top: 5px;
  }

  .rahul {
    font-size: 15px;

    margin-top: -20px;
  }

  .ls-sectt {
    width: 300px;

    margin-left: 30px;

    gap: 10px;
  }



  .ride-info {
    width: 370px;

    margin-left: -15px;
  }

  .ls-arrow-icon {
    display: none;
  }



  .ls-sectp {
width: 320px;
    margin-left: 20px;

    gap: 10px;
  }

  .ls-passenger-count {
    width: 300px;
  }

  .ls-passenger-input {
    gap: 10px;
    width: 300px;
  }

  .location-info {
    margin-left: 100px;

    margin-top: -10px;
  }

  .ls-additional-info {
    font-size: 20px;

    width: 400px;
  }

  .filter {
    margin-left: 50px;

    margin-top: 100px;
  }

  .baggg {
    margin-left: 350px;

    width: 350px;

    height: 350px;

    margin-top: -160px;
  }

  .ls-res1 {
    margin-left: 150px;

    font-size: 25px;

    width: 500px;

    margin-top: 20px;
  }

  .ls-search-container {
    height: 270px;
  }

  .ls-search {
    margin-left: 300px;
  }

  .location-info>div {
    margin-left: -500px;

    font-size: 15px;
  }

  .ls-results-container {
 

    border: 1px solid rgb(239, 236, 236);

    width: 788px;

    margin-top: 50px;

    height: 580px;
    /* Fixed height for the container */

    background-color: #f6f8f9;

    margin-left: -20px;
    /* Remove any left margin */

    margin-right: -0px;
    /* Remove any right margin */

    padding-left: 20px;
    /* Adjust padding as needed */

    padding-right: 0px;
    /* Adjust padding as needed */

    overflow-y: auto;
    /* Enable vertical scrolling */
  }

    
  .ls-search-inputs {
    /* border: 1px solid rgb(224, 20, 20); */

    display: flex;
  
    flex-wrap: wrap;
  margin-left: 30px;
    gap: 20px;
  
    margin-bottom: 10px;
  }
   
  
  .ls-gapsu1 {
    /* border: 1px solid rgb(224, 20, 20); */
    width: 700px;

    display: flex;
  }
  
  .ls-leaving {
    /* border: 1px solid rgb(226, 21, 21); */
  
    width: 300px;
  
    /* Adjust the height as needed */
  
    border-radius: 5px;
  }
  
  .ls-search-inputs input,
  .ls-search-inputs select {
    
    flex: 1;
  
    padding: 10px;
  
    border: 1px solid rgb(176, 173, 173);
  
    border-radius: 5px;
  
    font-size: 16px;
  
    /* Set max height for input and select */
  }
  
  .ls-search-inputs select {
    flex: 1;
  
    padding: 10px;
  
    border: 1px solid rgb(176, 173, 173);
  
    border-radius: 5px;
  
    font-size: 16px;
  
    /* Set max height for input and select */
  }
  
  .ls-search-inputs button {
    padding: 10px 20px;
  
    height: 60px;
  
    color: #fff;
  
    border: none;
  
    border-radius: 50px;
  
    margin-left: 30px;
  
    cursor: pointer;
  }
  
  .ls-search-inputs button:disabled {
    background-color: #ccc;
  
    cursor: not-allowed;
  }

  .ls-passenger-input {
    margin-left: 27px;
  }

  .ls-divide {
    border: 1px solid rgb(176, 173, 173);

    border-radius: 10px;

    overflow-y: scroll;

    width: 470px;

    margin-left: 320px;
    height: 480px;
  }

  .search-container {
    display: flex;

    margin-left: 30%;

    justify-content: center;
    /* Center horizontally */

    align-items: center;
    /* Center vertically, if necessary */

    margin-top: 10px;
    /* Space above the button */
  }

  .ls-search {
    width: 200px;

    border: 2px solid black;
  }
  
  .lscard-dest {
    display: flex;
    justify-content: space-between;
  
    margin-top: 0px;
    width: 90%;
    /* border: 1px solid rgb(251, 9, 9); */
  }
  .lscard-time-date-full {
    display: flex;
  
    /* align-items: center; */
    text-align: left;
    justify-content: space-between;
  
    width: 60%;
    margin-right: 160px;
    margin-top: -20px;
  }
  
  .lscard-price-full {
    display: block;

    margin-left: 295px;
  
    margin-top: -18px;
  }
  .lscard-seats-avail-full{
    margin-top:20px;
  
  }
  .lscard-seats-name-full{

    width: 170px;
  }
  .lscard-dest-ride{

  margin-top: 0px;
    width: 400px;
    height: 30px;
  
  }
  .lscard-time-date {
    display: flex;
  
    /* align-items: center; */
    text-align: left;
    justify-content: space-between;
  
    width: 80%;
    margin-right: 80px;
    margin-top: 0px;
  }
  .lscard-price {
    display: block;

    margin-left: -250px;
  
    margin-top: 0px;
  }
  .lscard-user {
    display: block;

    margin-top: -160px;
  
    margin-left: 65%;
  }
  
  .lscard-user-image {
    width: 100px;
  
    height: 100px;
  
    margin-top: 10px;
    border-radius: 50%;
  
    margin-right: -6px;
  }
  .lscard-user-image-full {
    width: 100px;
  
    height: 100px;
  
    margin-top: 0px;
    border-radius: 50%;
  
    margin-right: -6px;
  }
  .SA-ride-normal-card{
    padding-top: 10px;
  }
  
  .lscard-user-id {
    font-size: 16px;
  }
  .lscard-seats-avail{
width: 170px;
    margin-top: 10px;
    margin-left: -5%;


  }
  .ls-create-ride-button-container {
    margin-top: -530px;

    margin-left: -150px;
  }
  
  .lsh-filter-container {
    display: flex;
  
    flex-direction: column;
  
    align-items: flex-start;
  
    padding: 10px;
  
    width: 280px;
  
    height: 350px;
  
    margin-top: 3vh;
    /* Adjust this margin as needed */
  
    border: 1px solid #ccc;
  
    border-radius: 8px;
  
    background-color: #f9f9f9;
  }
  
  .ls-res {
    text-align: center;
  
    font-family: "Times New Roman", Times, serif;
  
    margin-top: -15px;
  
    margin-right: -150px;
  }
  .lscard-details {
    width: 100%;
  
    /* margin-top: 200px; */
  
    display: flex;

    flex-direction: column;
  
    align-items: center;
  }
  
  
}

@media only screen and (min-width: 884px) and (max-width: 1279px) {
  /* .ls-mainbb{
    overflow-y: hidden;
  } */

  .lscard {
    border: 1px solid #7e7d7d;
  
    border-radius: 8px;
  
    padding: 16px;
  
    margin: 0px;
  
    color: #05144c;
  
    width: 54vw;
  margin-top: -30px;
    height: 25vh;
  
    display: flex;
  
    flex-direction: column;
  
    align-items: center;
  }
 
  .lsh-filter-container {
    display: block;

    flex-direction: column;

    align-items: flex-start;

    padding: 10px;

    width: 270px;

    height: 340px;

    margin-top: -43vh;
    /* Adjust this margin as needed */

    border: 1px solid #ccc;

    border-radius: 8px;

    background-color: #f9f9f9;
  }

  .lsh-clear-filters {
    margin-right: 10px;

    padding: 7px 10px;

    background-color: #05144c;

    color: white;

    border: none;

    border-radius: 50px;

    cursor: pointer;
  }

  .ls-create-ride-button {
    background-color: #05144c;

    color: white;

    border: none;

    padding: 15px 20px;

    font-size: 16px;

    margin-left: 300px;

    border-radius: 50px;

    cursor: pointer;

    text-decoration: none;

    margin-bottom: -200px;
    /* Adjust margin as needed */
  }

  .ls-create-ride-button-container {
    margin-top: 30px;

    margin-left: 0px;
  }

  .ls-create-ride-button1 {
    background-color: #05144c;

    color: white;

    border: none;

    padding: 15px 20px;

    font-size: 16px;

    margin-left: 350px;

    border-radius: 50px;

    cursor: pointer;

    text-decoration: none;

    margin-bottom: -200px;
    /* Adjust margin as needed */
  }

  .ls-create-ride-button1-container {
    margin-top: 30px;

    margin-left: 0px;
  }

  .ls-res {
    margin-left: 50px;

    margin-top: 20px;
  }

  .ls-inno {
    margin-left: 400px;
  }

  .ls-search-results {

    margin-left: -30px;
  }

  .slidee {
    display: none;
  }

  .ls-fa {
    margin-left: -700px;

    margin-top: 230px;
  }

  

  .details {
    text-align: center;

    margin-right: 85px;
  }

  .man-image {
    margin-left: 40px;

    width: 50px;
    /* Adjust the width of the image if necessary */

    height: 50px;
    /* Adjust the height of the image if necessary */

    margin-top: 5px;
  }

  .rahul {
    font-size: 15px;

    margin-top: -20px;
  }

  .ls-sectt {
    width: 300px;

    margin-left: 30px;

    gap: 10px;
  }



  .ride-info {
    width: 370px;

    margin-left: -15px;
  }

  .ls-arrow-icon {
    display: none;
  }



  .ls-sectp {
width: 320px;
    margin-left: 20px;

    gap: 10px;
  }

  .ls-passenger-count {
    width: 300px;
  }

  .ls-passenger-input {
    gap: 10px;
    width: 300px;
  }

  .location-info {
    margin-left: 100px;

    margin-top: -10px;
  }

  .ls-additional-info {
    font-size: 20px;

    width: 400px;
  }

  .filter {
    margin-left: 50px;

    margin-top: 100px;
  }

  .baggg {
    margin-left: 350px;

    width: 350px;

    height: 350px;

    margin-top: -160px;
  }

  .ls-res1 {
    margin-left: 170px;

    font-size: 25px;

    width: 500px;

    margin-top: 30px;
  }

  .ls-search-container {
    height: 270px;
  }

  .ls-search {
    margin-left: 300px;
  }

  .location-info>div {
    margin-left: -500px;

    font-size: 15px;
  }

  .ls-results-container {
 

    border: 1px solid rgb(239, 236, 236);

    width: 830px;

    margin-top: 50px;

    height: 565px;
    /* Fixed height for the container */

    background-color: #f6f8f9;

    margin-left: -20px;
    /* Remove any left margin */

    margin-right: -0px;
    /* Remove any right margin */

    padding-left: 20px;
    /* Adjust padding as needed */

    padding-right: 0px;
    /* Adjust padding as needed */

    overflow-y: auto;
    /* Enable vertical scrolling */
  }

    
  .ls-search-inputs {
    /* border: 1px solid rgb(224, 20, 20); */

    display: flex;
  
    flex-wrap: wrap;
  margin-left: 30px;
    gap: 20px;
  
    margin-bottom: 10px;
  }
   
  
  .ls-gapsu1 {
    /* border: 1px solid rgb(224, 20, 20); */
    width: 700px;

    display: flex;
  }
  
  .ls-leaving {
    /* border: 1px solid rgb(226, 21, 21); */
  
    width: 300px;
  
    /* Adjust the height as needed */
  
    border-radius: 5px;
  }
  
  .ls-search-inputs input,
  .ls-search-inputs select {
    
    flex: 1;
  
    padding: 10px;
  
    border: 1px solid rgb(176, 173, 173);
  
    border-radius: 5px;
  
    font-size: 16px;
  
    /* Set max height for input and select */
  }
  
  .ls-search-inputs select {
    flex: 1;
  
    padding: 10px;
  
    border: 1px solid rgb(176, 173, 173);
  
    border-radius: 5px;
  
    font-size: 16px;
  
    /* Set max height for input and select */
  }
  
  .ls-search-inputs button {
    padding: 10px 20px;
  
    height: 60px;
  
    color: #fff;
  
    border: none;
  
    border-radius: 50px;
  
    margin-left: 30px;
  
    cursor: pointer;
  }
  
  .ls-search-inputs button:disabled {
    background-color: #ccc;
  
    cursor: not-allowed;
  }

  .ls-passenger-input {
    margin-left: 27px;
  }

  .ls-divide {
    border: 1px solid rgb(176, 173, 173);

    border-radius: 10px;

    overflow-y: scroll;

    width: 510px;

    margin-left: 320px;
    height: 480px;
  }

  .search-container {
    display: flex;

    margin-left: 30%;

    justify-content: center;
    /* Center horizontally */

    align-items: center;
    /* Center vertically, if necessary */

    margin-top: 10px;
    /* Space above the button */
  }

  .ls-search {
    width: 200px;

    border: 2px solid black;
  }
  
  .lscard-dest {
    display: flex;
    justify-content: space-between;
  
    margin-top: 0px;
    width: 90%;
    /* border: 1px solid rgb(251, 9, 9); */
  }
  .lscard-time-date-full {
    display: flex;
  
    /* align-items: center; */
    text-align: left;
    justify-content: space-between;
  
    width: 50%;
    margin-right: 220px;
    margin-top: -20px;
  }
  
  .lscard-price-full {
    display: block;

    margin-left: 270px;
  
    margin-top: -17px;
  }
  .lscard-seats-avail-full{
    margin-top:20px;
  
  }
  .lscard-seats-name-full{

    width: 170px;
  }
  .lscard-dest-ride{

  margin-top: 0px;
    width: 400px;
    height: 30px;
  
  }
  .lscard-time-date {
    display: flex;
  
    /* align-items: center; */
    text-align: left;
    justify-content: space-between;
  
    width: 50%;
    margin-right: 220px;
    margin-top: 0px;
  }
  .lscard-dest-Efficient{
    margin-top: 50px;
  }
  .lscard-price {
    display: block;

    margin-left: -280px;
  
    margin-top: 0px;
  }
  .lscard-user {
    display: block;

    margin-top: -160px;
  
    margin-left: 65%;
  }
  
  .lscard-user-image {
    width: 100px;
  
    height: 100px;
  
    margin-top: 10px;
    border-radius: 50%;
  
    margin-right: -6px;
  }
  .lscard-user-image-full {
    width: 100px;
  
    height: 100px;
  
    margin-top: 0px;
    border-radius: 50%;
  
    margin-right: -6px;
  }

.SA-ride-normal-card{
  padding-top: 10px;
}
  
  .lscard-user-id {
    font-size: 16px;
  }
  .lscard-seats-avail{
width: 170px;
    margin-top: 10px;
    margin-left: -5%;


  }
  .ls-create-ride-button-container {
    margin-top: -530px;

    margin-left: -150px;
  }
  
  .lsh-filter-container {
    display: flex;
  
    flex-direction: column;
  
    align-items: flex-start;
  
    padding: 10px;
  
    width: 280px;
  
    height: 350px;
  
    margin-top: 3vh;
    /* Adjust this margin as needed */
  
    border: 1px solid #ccc;
  
    border-radius: 8px;
  
    background-color: #f9f9f9;
  }
  
  .ls-res {
    text-align: center;
  
    font-family: "Times New Roman", Times, serif;
  
    margin-top: -15px;
  
    margin-right: -150px;
  }
  .lscard-details {
    width: 100%;
  
    /* margin-top: 200px; */
  
    display: flex;

    flex-direction: column;
  
    align-items: center;
  }
  
  
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
 
  .lscard {
    border: 1px solid #cbc5c5;
  
    border-radius: 8px;
  
    padding: 16px;
  
    margin: 0px;
  
    color: #05144c;
  
    width: 60vw;
  margin-top: -30px;
    height: 29vh;
  
    display: flex;
  
    flex-direction: column;
  
    align-items: center;
  }
  
  .lscard-details {
    width: 100%;
  
    /* margin-top: 200px; */
  
    display: flex;
  
    flex-direction: column;
  
    align-items: center;
  }
  
  .lscard-location {
    width: 100%;
  
    display: flex;
  
    flex-direction: column;
  
    align-items: center;
  
    margin-bottom: 16px;
  }
  .lscard-location-full {
    width: 100%;
  
    display: flex;
  
    flex-direction: column;
  
    align-items: center;
  
    margin-bottom: 16px;
  }
  
  .lscard-ride-info {
    width: 100%;
    margin-top: -50px;
  }
  .lscard-ride-info-full {
    width: 100%;
    margin-top: 5px;
  }
  
  .lscard-dest {
    display: flex;
  
    justify-content: space-between;
  
    margin-top: -10px;
    width: 70%;
  }
  .lscard-price-icon{
    margin-left: -30px;
  }
  .lscard-pricenoof{
    margin-left: 10px;
  
  }
  .lscard-pricenoof{
  
    margin-left: 0px;
  
    
  }
  .lscard-seats-total{
  
    margin-right: 5px;
  }
  .lscard-price-price{
    margin-left: 5px;
  }
  
  .lscard-ride {
    margin: 0 8px;
  
    font-size: 16px;
  }
  
  .lscard-ride-goi {
    margin: 0 8px;
    margin-right: 160px;
  
    font-size: 16px;
  }
  
  .lscard-arrow {
    margin-right: 0px;
    font-size: 20px;
  }
  .lscard-time-date-full {
    display: flex;
  
    /* align-items: center; */
    text-align: left;
    justify-content: space-between;
    /* border: 1px solid rgb(252, 22, 22); */
  
    width: 40%;
    margin-right: 440px;
    margin-top: -15px;
  }
  
  .lscard-time-date {
    display: flex;
  
    /* align-items: center; */
    text-align: left;
    justify-content: space-between;
    /* border: 1px solid rgb(252, 22, 22); */
  
    width: 40%;
    margin-right: 440px;
    margin-top: -5px;
  }
  
  .lscard-pikeuptiming {
    display: flex;
  
    align-items: center;
    margin-left: 20px;
  
    justify-content: space-between;
  
    width: 100%;
  }
  
  .lscard-time {
    /* border: 1px solid rgb(252, 22, 22); */
  
    display: block;
    height: 50px;
    flex-direction: row;
  
    align-items: center;
  
    justify-content: space-between;
  
    width: 150%;
  }
  
  .lscard-ride-time,
  .lscard-ride-duration {
    margin: 0 16px;
  }
  
  .lsride-times {
    margin-top: 20px;
  }
  
  .lsride-time {
    margin-top: -20px;
  }
  
  .lscard-date {
    font-size: 24px;
  
    font-weight: 700;
  
    margin-top: 35px;
  
    margin-bottom: -50px;
  }
  .lscard-date-full {
    font-size: 24px;
  
    font-weight: 700;
  
    margin-top: -20px;
  
    margin-bottom: -0px;
  }
  
  .lscard-date-1 {
    font-size: 24px;
  
    font-weight: 700;
  
    margin-top: 0px;
  
    margin-bottom: 20px;
  }
  
  .lscard-user {
    display: block;
  
    margin-top: -160px;
  
    margin-left: 70%;
  }
  
  .lscard-user-image {
    width: 100px;
  
    height: 100px;
  
    margin-top: 10px;
    border-radius: 50%;
  
    margin-right: -6px;
  }
  .lscard-user-image-full{
    width: 100px;
   
    height: 100px;
   
    margin-top: 10px;
    border-radius: 50%;
   
    margin-right: -6px;
  }
  .lscard-user-id {
    font-size: 16px;
  }
  .lscard-seats-avail-full{
    margin-top:-20px;
  
  }
  .lscard-seats-avail{
    margin-top: -25px;
  }
  .lscard-price-full {
    display: block;
  
    margin-left: 100px;
  
    margin-top: -18px;
  }
  
  .lscard-price {
    display: block;
  
    margin-left: -580px;
  
    margin-top: 0px;
  }
  
  .card-price-icon {
    margin-right: 4px;
  }
  
  .lscard-back-button {
    color: black;
  
    padding: 8px 16px;
  
    border: none;
  
    border-radius: 4px;
  
    cursor: pointer;
  
    margin-left: 80%;
  }
  
  .lscard-back-button:hover {
    text-decoration: underline;
  
    color: #007bff;
  }
  
  .disabled-card {
    padding: 0px;
  
    width: 100%;
  
    opacity: 0.6;
    /* Example of making it visually appear disabled */
  }
  
  .full-text-full {
    /* border: 1px solid #e91111; */
  
    color: red;
  font-size: 30px;
  margin: 0px;
  margin-bottom: 0px;
  
  }
  .ls-river{
    background-color: #f6f4f4;
    padding: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(red, green, blue, alpha);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-decoration: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
    width: 97.5%;
      filter: grayscale(0) opacity(0.5);
    transition: opacity 0.5s ease-in-out, filter 0.5s ease-in-out;
    pointer-events: none;
  
  
  }
  .ls-river.disabled{
    filter: grayscale(100%) blur(5px);
    opacity: 0.7;
    pointer-events: none;
    
  
  }
  
  .lsh-filter-container {
    display: flex;
  
    flex-direction: column;
  
    align-items: flex-start;
  
    padding: 10px;
  
    width: 320px;
  
    height: 350px;
  
    margin-top: 4vh;
    /* Adjust this margin as needed */
  
    border: 1px solid #ccc;
  
    border-radius: 8px;
  
    background-color: #f9f9f9;
  }
  
  .lsh-filter-header {
    display: flex;
  
    justify-content: space-between;
  
    align-items: center;
  
    width: 100%;
  }
  
  .lsh-clear-filters {
    margin-right: 10px;
  
    padding: 7px 10px;
  
    background-color: #05144c;
  
    color: white;
  
    border: none;
  
    border-radius: 50px;
  
    cursor: pointer;
  }
  
  .lsh-filter-options {
    display: block;
  
    flex-wrap: wrap;
  
    gap: 15px;
    /* Increase gap between checkboxes and labels */
  
    margin-top: 10px;
  }
  
  .lsh-filter-option {
    display: flex;
  
    align-items: center;
  }
  
  .lsh-filter-option input {
    margin-right: 10px;
    /* Adjust margin between input and text */
  }
  
  .lsh-filter-option label {
    font-size: 24px;
    /* Increase font size of text */
  
    margin-top: 15px;
  
    margin-left: 10px;
    /* Add margin between icon and text */
  
    font-family: "Times New Roman", Times, serif;
    /* Add margin-top */
  
    display: flex;
  
    align-items: center;
  }
  
  .lsh-fli {
    font-family: "Times New Roman", Times, serif;
  
    font-weight: 600;
  
    margin-left: 10px;
  }
  
  .lsh-box {
    flex: 0 0 auto;
    /* Prevent checkboxes from expanding */
  
    margin-left: 10px;
  
    margin-top: 20px;
    /* Adjust margin between input and text */
  }
  
  .ls-search-container {
    max-width: 95%;
  
    margin: 0 auto;
  
    height: 100px;
  
    margin-top: 140px;
  
    padding: 20px;
  
    border: 1px solid #ccc;
  
    background-color: #f3f4f6;
  
    border-radius: 20px;
  
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .ls-search-inputs {
    display: flex;
  
    flex-wrap: wrap;
  margin-left: 0px;
    gap: 20px;
  
    margin-bottom: 10px;
  }
   
  
  .ls-gapsu1 {
    display: flex;
  }
  
  .ls-leaving {
    border: 1px solid rgb(174, 172, 172);
  
    width: 220px;
  
    /* Adjust the height as needed */
  
    border-radius: 5px;
  }
  
  .ls-search-inputs input,
  .ls-search-inputs select {
    flex: 1;
  
    padding: 10px;
  
    border: 1px solid rgb(176, 173, 173);
  
    border-radius: 5px;
  
    font-size: 16px;
  
    /* Set max height for input and select */
  }
  
  .ls-search-inputs select {
    flex: 1;
  
    padding: 10px;
  
    border: 1px solid rgb(176, 173, 173);
  
    border-radius: 5px;
  
    font-size: 16px;
  
    /* Set max height for input and select */
  }
  
  .ls-search-inputs button {
    padding: 10px 20px;
  
    height: 60px;
  
    color: #fff;
  
    border: none;
  
    border-radius: 50px;
  
    margin-left: 30px;
  
    cursor: pointer;
  }
  
  .ls-search-inputs button:disabled {
    background-color: #ccc;
  
    cursor: not-allowed;
  }
  
  .ls-search-results {
    margin-top: 20px;
  }
  
  .ls-search-results ul {
    list-style: none;
  
    padding: 0;
  }
  
  .ls-search-results li {
    margin-bottom: 5px;
  }
  
  .ls-passenger-input {
    display: flex;
  
    align-items: center;
  width: 230px;
    margin-left: 10px;
  
    gap: 10px;
  }
  
  /*  */
  
  .ls-passenger-controls {
    position: relative;
  
    display: inline-block;
  
    margin-left: -120px;
  width: 90px;
    border: none;
  }
  
  .ls-passenger-customm {
    width: 100px;
  
    padding: 10px;
  
    padding-left: 30px;
    /* Adjust based on icon size */
  
    padding-right: 30px;
    /* Adjust based on icon size */
  
    text-align: center;
  
    font-size: 16px;
  }
  
  .ls-passenger-minus-icon,
  .ls-passenger-plus-icon {
    position: absolute;
  
    top: 50%;
  
    transform: translateY(-50%);
  
    cursor: pointer;
  
    font-size: 16px;
  
    color: #333;
  }
  
  .ls-passenger-minus-icon {
    left: 10px;
    /* Adjust based on input padding */
  }
  
  .ls-passenger-plus-icon {
    right: 10px;
    /* Adjust based on input padding */
  }
  
  .ls-search {
    width: 180px;
  
    background-color: #05144c;
  
    text-decoration: none !important;
    outline: none;
    /* No outline on focus */
  
    border-radius: 10px;
  }
  
  .ls-radio-icon,
  .ls-calendar-icon,
  .ls-arrow-icon {
    margin-top: 20px;
  
    font-size: 20px;
  }
  
  .ls-passenger-count {
    border: 1px solid rgb(176, 173, 173);
  
    height: 60px;
  
    width: 250px;
  }
  
  .ls-search-results {
    margin-left: 30%;
  }
  
  .ls-res {
    text-align: center;
  
    font-family: "Times New Roman", Times, serif;
  
    margin-top: -15px;
  
    margin-right: 80px;
  }
  
  .ls-inn {
    border-radius: 5px;
  
    width: 60%px;
  
    color: #05144c;
  
    text-align: center;
  
    margin-top: 50px;
  
    margin-left: 30px;
  
    font-size: 20px;
  
    font-family: "Times New Roman", Times, serif;
  }
  
  .ls-passenger-customm {
    margin-left: -1px;
  }
  
  .location-info {
    display: block;
  
    align-items: center;
  
    margin-bottom: 20px;
  }
  
  .ride {
    margin-top: 10px;
  }
  
  
  
  
  .ls-create-ride-button {
    background-color: #05144c;
  
    color: white;
  
    border: none;
  
    padding: 10px 20px;
  
    font-size: 16px;
  
    margin-left: 100px;
  
    border-radius: 50px;
  
    cursor: pointer;
  
    text-decoration: none;
  
    margin-top: 0px;
    /* Adjust margin as needed */
  }
  
  .ls-create-ride-button-container {
    margin-top: -450px;
  
    margin-left: 580px;
  }
  
  .ls-create-ride-button1 {
    background-color: #05144c;
  
    color: white;
  
    border: none;
  
    padding: 10px 20px;
  
    font-size: 16px;
  
    margin-left: 00px;
  
    border-radius: 50px;
  
    cursor: pointer;
  
    text-decoration: none;
  
    margin-top: 0px;
    /* Adjust margin as needed */
  }
  
  .ls-create-ride-button1-container {
    margin-top: 50px;
  
    margin-left: 500px;
  }
  
  .ls-additional-info {
    width: 400px;
  
    margin-top: 20px;
    /* Adjust margin as needed */
  }
  
  .ls-filter {
    font-size: 20px;
  }
  
  .ls-additional-info {
    font-size: 20px;
  
    width: 400px;
  
    margin-top: -40px;
  }
  
  .ls-additional-info h1 {
    font-size: 26px;
  
    margin-top: 20px;
  }
  
  .ls-additional-info .detail {
    display: flex;
  
    align-items: center;
  
    margin-top: 20px;
  }
  
  .ls-additional-info .detail svg {
    margin-right: 10px;
  }
  
  .ls-additional-info .detail span {
    margin-left: auto;
  }
  
  .ls-additional-info .detail span::before {
    content: "|";
  
    margin-right: 10px;
  
    opacity: 0.6;
  }
  
  .ls-additional-info .detail:last-child span::before {
    content: "";
  
    margin-right: 0;
  
    opacity: 1;
  }
  
  .ride-link {
    text-decoration: none;
  
    color: #05144c;
  }
  
  .ls-results-container {
    border: 1px solid rgb(239, 236, 236);
  
    width: 103%;
  
    margin-top: 30px;
  
    height: 488px;
    /* Fixed height for the container */
  
    background-color: #f6f8f9;
  
    margin-left: -20px;
    /* Remove any left margin */
  
    margin-right: -20px;
    /* Remove any right margin */
  
    padding-left: 20px;
    /* Adjust padding as needed */
  
    padding-right: 20px;
    /* Adjust padding as needed */
  
    overflow-y: auto;
  }
  
  .details {
    margin-left: 5px;
  
    font-size: 15px;
  
    font-weight: 600;
  
    margin-bottom: 0px;
  }
  
  .detail {
    margin-top: 20px;
  
    display: block;
  }
  
  .detaill {
    font-weight: 300;
  }
  
  .slidee {
    font-size: 60px;
  
    margin-left: -200px;
  
    margin-bottom: 80px;
  }
  
  .man-image {
    width: 50px;
  
    height: 50px;
  
    border-radius: 100px;
  
    border: 4px solid #05144c;
  
    margin-top: 0px;
  
    margin-left: 80px;
  }
  
  .immtext {
    display: flex;
  
    align-items: center;
    /* Align items vertically */
  }
  
  .text-container {
    display: flex;
  
    flex-direction: column;
  
    margin-top: 30px;
  
    margin-left: 20px;
  }
  
  .sort {
    display: flex;
  
    align-items: center;
    /* Align items vertically */
  
    margin-top: -10px;
  }
  
  .sortt {
    font-size: 20px;
  
    color: #002366;
  
    margin-top: 20px;
  
    margin-left: 170px;
    /* Add margin between "Sort by" and "Clear all" */
  }
  
  .sorttt {
    margin-left: 100px;
    /* Add margin between "Sort by" and "Clear all" */
  }
  
  .sorttt-1 {
    margin-left: 145px;
  }
  
  .sorttt-2 {
    margin-left: 45px;
  }
  
  .sorttt-3 {
    margin-left: 75px;
  }
  
  .sorttt-4 {
    margin-left: 145px;
  }
  
  .sorttt-5 {
    margin-left: 135px;
  }
  
  .sorttt-6 {
    margin-left: 125px;
  }
  
  .sorttt-7 {
    margin-left: 112px;
  }
  
  .sorttt-8 {
    margin-left: 150px;
  }
  
  .ls-res1 {
    font-size: 30px;
  
    width: 500px;
  
    margin-left: 300px;
  
    font-family: "Times New Roman", Times, serif;
  
    margin-top: -200px;
  }
  
  .filter {
    margin-left: -340px;
  }
  
  .baggg {
    margin-left: -100px;
  
    margin-top: -150px;
  }
  
  .ls-fa {
    color: navy;
    margin-right: 50px;
  
    margin-bottom: 0px;
  }
  
  .ls-faa {
    margin-top: 50px;
  }
  
  .ls-mainbb {
    border: 1px solid rgb(246, 243, 243);
  }
  
  .lscard-dest-Efficient {
    width: 55%;
    height: 30px;
    margin-top: 40px;

  
  }
  .lscard-dest-ride{
    /* border: 1px solid rgb(247, 10, 10); */
  margin-top: 0px;
    width: 100%;
    height: 30px;
  
  }
  .lscard-dest-ride-full{
    /* border: 1px solid rgb(247, 10, 10); */
  margin-top: 0px;
    width: 100%;
    height: 30px;
  
  }
  
  .ls-sectt {
    width: 230px;
  
    margin-top: 0px;
  
    display: flex;
  
    gap: 5px;
  }
  
  .ls-sectp {
    margin-top: 0px;
  width: 200px;
    display: flex;
  }
  
  .ls-divide {
    border: 1px solid rgb(213, 211, 211);
  
    border-radius: 10px;
  /* width: 920px; */
    overflow-y: scroll;
  
    height: 400px;
  }
  
  .ls-inno {
    margin-left: 220px;
  }

}