.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;
}

.dash {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  margin: 20px;
  gap: 60px;
  margin-top: 150px;
}

.dash-main {
  margin-top: 0px;
}

.card {
  background: linear-gradient(-45deg, #333363 0%, #0d094b 100%);
  color: white;
  border: 1px solid #27273f;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  margin: 10px;

  width: 250px;
  height: 180px;
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
}

.card h2 {
  width: 220px;
  font-size: 1.2rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.card p {
  font-size: 2.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card .icon {
  font-size: 2rem;
  margin-right: 10px;
}

.charts-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px;
}
.ridecont {
  font-size: 10px;
}
.chart-container {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  width: 45%;
  height: 300px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  margin: 10px;
  transition: transform 0.2s ease-in-out;
}
.chart-container:hover {
  transform: scale(1.02);
}

.chart h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.activity-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.activity-line p {
  font-size: 1.2rem;
  color: #8884d8;

  margin-right: 10px;
}

.activity-line .line {
  flex-grow: 1;
  height: 2px;

  background-color: #8884d8;
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
  .dash {
    margin-top: 120px;
  }

  .card {
    width: 90%;
  }

  .chart-container {
    width: 100%;
  }
}

@media only screen and (min-width: 360px) and (max-width: 769px) {
  .dash {
    margin-top: 120px;
  }

  .card {
    width: 80%;
  }

  .chart-container {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 852px) {
  .card {
    width: 300px;
  }

  .chart-container {
    width: 45%;
  }
}

@media only screen and (min-width: 884px) and (max-width: 1440px) {
  .card {
    width: 300px;
  }

  .chart-container {
    width: 45%;
  }
}
