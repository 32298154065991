.report-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust height as needed */
  text-align: center;
}

.report-details-heading {
  margin-bottom: -20px;
  font-size: 30px;
  margin-right: -350px;
  align-items: center; /* Adjust margin as needed */
}

.report-details-table {
  border-collapse: collapse;
  width: 50%; /* Adjust width as needed */
  table-layout: fixed;
  margin-top: 100px; /* Ensure table cells have fixed width */
}
.report-button-page{
  color: #000;
  /* border: 1px solid #f70f0f; */
  width: 200px;

margin-bottom: 20px;
  margin-left: 550px;

}

.report-details-table td,
.report-details-table th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 19px;
  text-align: left;
  word-wrap: break-word; /* Ensure text wraps within the cell */
}

.report-details-table th {
  background-color: #f2f2f2;
}

.report-details-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.report-details-table tr:hover {
  background-color: #ddd;
}

@media only screen and (max-width: 768px) {
  .report-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height as needed */
    text-align: center; /* Center the text horizontally */
  }

  .report-details-heading {
    margin-bottom: 10px; /* Adjust margin as needed */
    font-size: 20px; /* Adjust font size as needed */
    margin-right: -150px; /* Adjust margin as needed */
  }

  .report-details-table {
    border-collapse: collapse;
    width: 90%; /* Adjust width as needed */
  }

  .report-details-table td,
  .report-details-table th {
    border: 1px solid #ddd;
    padding: 4px;
    font-size: 12px; /* Adjust font size as needed */
    text-align: left;
    word-wrap: break-word; /* Ensure text wraps within the cell */
  }

  .report-details-table th {
    background-color: #f2f2f2;
  }

  .report-details-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .report-details-table tr:hover {
    background-color: #ddd;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .report-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Adjust height as needed */
    text-align: center; /* Center the text horizontally */
  }

  .report-details-heading {
    margin-bottom: 10px; /* Adjust margin as needed */
    font-size: 20px; /* Adjust font size as needed */
    margin-right: -350px; /* Adjust margin as needed */
  }

  .report-details-table {
    border-collapse: collapse;
    width: 90%; /* Adjust width as needed */
    table-layout: fixed; /* Ensure table cells have fixed width */
  }

  .report-details-table td,
  .report-details-table th {
    border: 1px solid #ddd;
    padding: 4px;
    font-size: 12px; /* Adjust font size as needed */
    text-align: left;
    word-wrap: break-word; /* Ensure text wraps within the cell */
  }

  .report-details-table th {
    background-color: #f2f2f2;
  }

  .report-details-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .report-details-table tr:hover {
    background-color: #ddd;
  }
}
