.pf-main {
  border: 1px solid rgb(248, 243, 243);
  padding: 20px;
  margin-left: 0px;
}

.pf-head {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  gap: 200px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
}
.pf-verified {
  color: green;
  font-weight: bold;
  margin-left: 10px;
}
 
.verify-email-text {
  color: green;
  font-weight: bold;
  margin-left: 10px;
}

.pf-profile-image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 150px;

}

.pf-profile-icon-arrow {
  font-size: 2rem;
  color: #888;
}

.pf-tab.active::after {
  content: '';
  display: block;
  position: absolute;
  margin-left: 30%;
  bottom: -5px;
  left: 0;
  height: 1px;
  background-color: grey;
}

.pf-tab {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s;
  position: relative;
}

.pf-tab.active {
  border-bottom: 2px solid #000;
}

.pf-content {
  margin-top: 60px;
}

.pf-about-content {
  display: flex;
  align-items: center;
  
  
}

.pf-name {
  color: #08144c;
  margin-left: -120px;
  margin-top: 20px;
}



.pf-aboutt-content {

  align-items: center;
  margin-top: 20px;
}



.pf-abouttt-content {

  align-items: center;
}

.pf-profile-info {
  width: 40%;
  margin-left: 25%;
  font-size: 20px;
  height: 100px;
  padding-top: 10px;
  padding-left: 5px;
  border-radius: 5px;
  font-family: 'Times New Roman', Times, serif;
  cursor: pointer;
  transition: all 0.3s ease;
}







.pf-profile-info-1 {
  margin-top: 15px;
  margin-left: 40px;
  }
  
.pf-profille-info-2 {
  margin-top: 15px;
  margin-left: 40px;
}
.pf-profille-info-3 {
  margin-top: 15px;
  margin-left: 40px;
  
 
}


.pf-add-iconne {
  margin-left: 0px;
  font-size: 20px;
}
.pf-add-iconnee {
  margin-left: 0px;
  font-size: 20px;
}
.pf-about-content-ba2{

  align-items: center;
  border: 1px solid white;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  margin-left: 450px;
  width: 600px;
  height: 150px;
}
.pf-about-content-ba{
  display: flex;
  align-items: center;
  border: 1px solid white;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  margin-left: 450px;
  width: 600px;
  height: 150px;
}
.pf-about-content-ba5{
  align-items: center;
  border: 1px solid white;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  margin-left: 450px;
  width: 600px;
  height: 150px;
  margin-top: 20px;
}
.button-disabled {
  color: aliceblue;
 
}

.pf-about-content-ba1 {
  display: flex;
  align-items: center;
  color: rgb(9, 142, 237);
  margin-left: 0px;
  border-radius: 50px;
  width: 190px;
  height: 50px;
  margin-top: -20px;
  position: relative;
}

.pf-about-content-ba1::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: rgb(9, 142, 237);
  bottom: 10px;
  left: 0;
  
  transition: width 0.3s ease-in-out;
}

.pf-about-content-ba1:hover::after {
  width: 100%;
}



.pf-about-content-ba4 {
  display: flex;
  align-items: center;
  color: rgb(9, 142, 237);
  margin-left: 450px;
  border-radius: 50px;
  width: 190px;
  height: 50px;
  margin-top: -20px;
  position: relative;
}

.pf-about-content-ba4::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: rgb(9, 142, 237);
  bottom: 10px;
  left: 0;
  
  transition: width 0.3s ease-in-out;
}
.pf-about-content-ba4:hover::after {
  width: 105%;
}

.pf-about-content-ba3{
  display: flex;
  align-items: center;
  border: 1px solid #ebe9e9;
  background-color: #08144c;
  color: white;
  border-radius: 50px;
  margin-left: 450px;
  width: 210px;
  height: 50px;
  margin-top: 0px;
}



.pf-account-content-delete {
  display: flex;
  align-items: center;
  color: red;
  margin-left: 450px;
  border-radius: 50px;
  width: 190px;
  height: 50px;
  margin-top: -20px;
  position: relative;
  cursor: pointer; /* Added for better UX */
}

.pf-account-content-delete::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: rgb(237, 77, 9);
  bottom: 10px;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.pf-account-content-delete:hover::after {
  width: 75%;
}



.pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 15px;
margin-left: 40px;
}



.pf-profile-inffoo1 {
  width: 40%;
  margin-left: 10%;
  font-size: 20px;
  height: 100px;
  padding-top: 10px;
  padding-left: 5px;
  border-radius: 5px;
  font-family: 'Times New Roman', Times, serif;
  cursor: pointer;
  transition: all 0.3s ease;
}


.pf-profille-info-ba2 {
  width: 50%;
  margin-left: -54%;
  font-size: 20px;
  height: 50px;
  padding-top: 50px;
  padding-left: 5px;
  border-radius: 5px;
  font-family: 'Times New Roman', Times, serif;
  cursor: pointer;
  transition: all 0.3s ease;
}
.pf-profille-info-ba1 {
  width: 50%;
  margin-left: -84%;
  font-size: 20px;
  height: 50px;
  padding-top: 50px;
  padding-left: 5px;
  border-radius: 5px;
  font-family: 'Times New Roman', Times, serif;
  cursor: pointer;
  transition: all 0.3s ease;
}



.pf-add-icon {
  margin-left: 10px;
  font-size: 18px;
}

.pf-line-hr {
  width: 50%;
  height: 0px;
  margin-left: 28%;
  color: aliceblue;
  margin-top: 10px;
}



.pf-profille-info {
  width: 40%;
  margin-top: 10px;
  margin-left: 30%;
  font-size: 18px;
  height: 50px;
  padding-top: 10px;
  padding-left: 5px;
  border-radius: 5px;
  font-family: 'Times New Roman', Times, serif;
  cursor: pointer;
  transition: all 0.3s ease;
}


.pf-profillee-info {
  width: 40%;
  margin-top: 10px;
  margin-left: 30%;
  font-size: 18px;
  height: 50px;
  padding-top: 10px;
  padding-left: 5px;
  border-radius: 5px;
  font-family: 'Times New Roman', Times, serif;
  cursor: pointer;
  transition: all 0.3s ease;
}


.pf-profile-icon {
  display: block;
  align-items: center;
  margin-left: -100px;
}
.pf-Userid{
  margin-left: 170px;

}

.pf-profile-icon .pf-profile-iccon {
  font-size: 50px;
}

.pf-profile-icon .pf-profile-icconn {
  font-size: 20px;
  margin-left: 10px;
}
@media only screen and (min-width: 320px) and (max-width: 343px) {
  .pf-about-content-ba2{

    align-items: center;

    margin-left: 0px;
    width: 280px;
    height: 170px;
    margin-top: 10px;
  }
    .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  
  .pf-about-content-ba{
    display: flex;
    align-items: center;
  
    margin-left: 0px;
    width: 280px;
    height: 150px;
  }
  .pf-about-content-ba5{
    align-items: center;
 
    margin-left: 0px;
    width: 280px;
    height: 170px;
    margin-top: 20px;
  }

  
.pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 15px;
margin-left: 20px;
}

.pf-about-content-ba4:hover::after {
  width: 92%;
}
.pf-about-content-ba1:hover::after {
  width: 92%;
}
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: 0px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
    
    border-radius: 50px;
    margin-left: 0px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;

    border-radius: 50px;
   
    margin-left: 0px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -85%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 0px;
    border-radius: 0px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .pf-name {
    font-size: 25px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -40px;
    margin-top: 0px;
  }
  .pf-profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: 20px;
    margin-left: 150px;
  }


  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  
   
  }
  .pf-profille-info, .pf-profile-inffo, .pf-profille-info {
    width: 80%;
    margin-left: 0%;
  }

  .pf-about-content, .pf-aboutt-content, .pf-abouttt-content {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
  .pf-add-icon{
    margin-left: 5px;
    
  }
  .pf-profile-info {
    width: 40%;
    margin-left: 10%;
    margin-bottom: 10px;
    text-align: center;
  }
  .pf-profile-info-1 {
    margin-top: 15px;
    margin-left: 20px;
    }
      
.pf-profille-info-2 {
  margin-top: 15px;
  margin-left: 20px;
}
.pf-profille-info-3 {
  margin-top: 15px;
  margin-left: 20px;

 
}


.pf-verified {
  color: green;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 20px;
}
 
.verify-email-text {
  color: green;
  font-weight: bold;
  display: block;
  margin-left: 10px;
}
  .pf-name {
    font-size: 25px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -40px;
    margin-top: 20px;
  }
  .pf-Userid{
    margin-left: 150px;
  
  }
}

@media only screen and (min-width: 360px) and (max-width: 374px) {
  .pf-about-content-ba2{

    align-items: center;

    margin-left: -5px;
    width: 333px;
    height: 150px;
    margin-top: 10px;
  }
  .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  .verify-email-text {
    color: green;
    font-weight: bold;
    display: block;
    margin-left: 10px;
  }
  .pf-about-content-ba{
    display: flex;
    align-items: center;
  
    margin-left: 0px;
    width: 350px;
    height: 150px;
  }
  .pf-about-content-ba5{
    align-items: center;
 
    margin-left: -5px;
    width: 333px;
    height: 170px;
    margin-top: 20px;
  }
  
.pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 15px;
margin-left: 20px;
}

.pf-about-content-ba4:hover::after {
  width: 92%;
}
.pf-about-content-ba1:hover::after {
  width: 92%;
}
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: 0px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
    
    border-radius: 50px;
    margin-left: 0px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;

    border-radius: 50px;
   
    margin-left: 0px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }

 
  .pf-profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: 20px;
    margin-left: 200px;
  }


  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  
   
  }
  .pf-profille-info, .pf-profile-inffo, .pf-profille-info {
    width: 80%;
    margin-left: 0%;
  }

  .pf-about-content, .pf-aboutt-content, .pf-abouttt-content {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
  .pf-add-icon{
    margin-left: 5px;
    
    
  }
  .pf-profile-info {
    width: 40%;
    margin-left: 5%;
    margin-bottom: 10px;
    text-align: center;
  }
  .pf-name {
    font-size: 25px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -40px;
    margin-top: 20px;
  }
  .pf-Userid{
    margin-left: 200px;
  
  }
}
@media only screen and (min-width: 375px) and (max-width: 383px) {
  .pf-about-content-ba2{

    align-items: center;

    margin-left: -5px;
    width: 350px;
    height: 150px;
    margin-top: 10px;
  }
  .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  .verify-email-text {
    color: green;
    font-weight: bold;
    display: block;
    margin-left: 10px;
  }
  
  .pf-about-content-ba{
    display: flex;
    align-items: center;
  
    margin-left: 0px;
    width: 350px;
    height: 150px;
  }
  .pf-about-content-ba5{
    align-items: center;
 
    margin-left: -5px;
    width: 350px;
    height: 170px;
    margin-top: 20px;
  }
  
.pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 15px;
margin-left: 20px;
}

.pf-about-content-ba4:hover::after {
  width: 92%;
}
.pf-about-content-ba1:hover::after {
  width: 92%;
}
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: 0px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
    
    border-radius: 50px;
    margin-left: 0px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;

    border-radius: 50px;
   
    margin-left: 0px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }

 
  .pf-profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: 20px;
    margin-left: 200px;
  }


  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  
   
  }
  .pf-profille-info, .pf-profile-inffo, .pf-profille-info {
    width: 80%;
    margin-left: 0%;
  }

  .pf-about-content, .pf-aboutt-content, .pf-abouttt-content {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
  .pf-add-icon{
    margin-left: 5px;
    
    
  }
  .pf-profile-info {
    width: 40%;
    margin-left: 5%;
    margin-bottom: 10px;
    text-align: center;
  }
  .pf-name {
    font-size: 25px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -40px;
    margin-top: 20px;
  }
  .pf-Userid{
    margin-left: 200px;
  
  }
}
@media only screen and (min-width: 390px) and (max-width: 411px) {
  .pf-about-content-ba2{

    align-items: center;

    margin-left: 0px;
    width: 350px;
    height: 150px;
    margin-top: 10px;
  }
  .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  .verify-email-text {
    color: green;
    font-weight: bold;
    display: block;
    margin-left: 10px;
  }
  
  .pf-about-content-ba{
    display: flex;
    align-items: center;
  
    margin-left: 0px;
    width: 350px;
    height: 150px;
  }
  .pf-about-content-ba5{
    align-items: center;
 
    margin-left: 0px;
    width: 350px;
    height: 170px;
    margin-top: 20px;
  }
  
.pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 15px;
margin-left: 20px;
}

.pf-about-content-ba4:hover::after {
  width: 92%;
}
.pf-about-content-ba1:hover::after {
  width: 92%;
}
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: 0px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
    
    border-radius: 50px;
    margin-left: 0px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;

    border-radius: 50px;
   
    margin-left: 0px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }

 
  .pf-profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: 20px;
    margin-left: 200px;
  }


  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  
   
  }
  .pf-profille-info, .pf-profile-inffo, .pf-profille-info {
    width: 80%;
    margin-left: 0%;
  }

  .pf-about-content, .pf-aboutt-content, .pf-abouttt-content {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
  .pf-add-icon{
    margin-left: 5px;
    
    
  }
  .pf-profile-info {
    width: 40%;
    margin-left: 5%;
    margin-bottom: 10px;
    text-align: center;
  }
  .pf-name {
    font-size: 25px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -40px;
    margin-top: 20px;
  }
  .pf-Userid{
    margin-left: 200px;
  
  }
}
@media only screen and (min-width: 412px) and (max-width: 427px) {
  .pf-about-content-ba2{

    align-items: center;

    margin-left: 20px;
    width: 350px;
    height: 150px;
    margin-top: 10px;
  }
  .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  .verify-email-text {
    color: green;
    font-weight: bold;
    display: block;
    margin-left: 10px;
  }
  
  .pf-about-content-ba{
    display: flex;
    align-items: center;
  
    margin-left: 0px;
    width: 350px;
    height: 150px;
  }
  .pf-about-content-ba5{
    align-items: center;
 
    margin-left: 20px;
    width: 350px;
    height: 170px;
    margin-top: 20px;
  }
  
.pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 15px;
margin-left: 20px;
}

.pf-about-content-ba4:hover::after {
  width: 92%;
}
.pf-about-content-ba1:hover::after {
  width: 92%;
}
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: 10px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
    
    border-radius: 50px;
    margin-left: 20px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;

    border-radius: 50px;
   
    margin-left: 20px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  
  .pf-profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: 20px;
    margin-left: 200px;
  }


  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  
   
  }
  .pf-profille-info, .pf-profile-inffo, .pf-profille-info {
    width: 80%;
    margin-left: 0%;
  }

  .pf-about-content, .pf-aboutt-content, .pf-abouttt-content {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
  .pf-add-icon{
    margin-left: 5px;
    
    
  }
  .pf-profile-info {
    width: 40%;
    margin-left: 5%;
    margin-bottom: 10px;
    text-align: center;
  }
  .pf-name {
    font-size: 25px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -40px;
    margin-top: 20px;
  }
  .pf-Userid{
    margin-left: 200px;
  
  }
}
@media only screen and (min-width: 428px) and (max-width: 767px) {
  .pf-about-content-ba2{

    align-items: center;

    margin-left: 20px;
    width: 350px;
    height: 150px;
    margin-top: 10px;
  }
  .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  .verify-email-text {
    color: green;
    font-weight: bold;
    display: block;
    margin-left: 10px;
  }
  
  .pf-about-content-ba{
    display: flex;
    align-items: center;
  
    margin-left: 0px;
    width: 350px;
    height: 150px;
  }
  .pf-about-content-ba5{
    align-items: center;
 
    margin-left: 20px;
    width: 350px;
    height: 170px;
    margin-top: 20px;
  }
  
.pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 15px;
margin-left: 20px;
}

.pf-about-content-ba4:hover::after {
  width: 92%;
}
.pf-about-content-ba1:hover::after {
  width: 92%;
}
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: 10px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
    
    border-radius: 50px;
    margin-left: 20px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;

    border-radius: 50px;
   
    margin-left: 20px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }


  .pf-profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: 20px;
    margin-left: 200px;
  }
  .pf-Userid{
    margin-left: 200px;
  
  }


  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  
   
  }
  .pf-profille-info, .pf-profile-inffo, .pf-profille-info {
    width: 80%;
    margin-left: 0%;
  }

  .pf-about-content, .pf-aboutt-content, .pf-abouttt-content {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }
  .pf-add-icon{
    margin-left: 5px;
    
    
  }
  .pf-profile-info {
    width: 40%;
    margin-left: 5%;
    margin-bottom: 10px;
    text-align: center;
  }
  .pf-name {
    font-size: 25px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -40px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 799px) {
  .pf-about-content-ba2{

    align-items: center;
 
    margin-left: 80px;
    width: 580px;
    height: 190px;
    margin-top: 10px;
  }
  .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  
  .pf-about-content-ba{
    display: flex;
    align-items: center;
   
    margin-left: 80px;
    width: 580px;
    height: 200px;
  }
  .pf-about-content-ba5{
    align-items: center;

    margin-left: 80px;
    width: 580px;
    height: 200px;
    margin-top: 20px;
  }
  .pf-profile-info-1 {
    margin-top: 38px;
    margin-left: 40px;
    }
    .pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 30px;
margin-left: 40px;
}

.pf-about-content-ba4:hover::after {
  width: 97%;
}
.pf-about-content-ba1:hover::after {
  width: 100%;
}
    
  .pf-profille-info-2 {
    margin-top: 15px;
    margin-left: 40px;
  }
  .pf-profille-info-3 {
    margin-top: 15px;
    margin-left: 40px;
    
   
  }
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: -150px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
 
    border-radius: 50px;
    margin-left: 80px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;
  
    margin-left: 80px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .pf-name {
    font-size: 40px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -300px;
    margin-top: 0px;
  }
  .pf-profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: 20px;
    margin-left: 180px;
  }
  .pf-Userid{
    margin-left: 190px;
  
  }


  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  
   
  }
}
@media only screen and (min-width: 800px) and (max-width: 819px) {
  .pf-about-content-ba2{

    align-items: center;
 
    margin-left: 90px;
    width: 580px;
    height: 190px;
    margin-top: 10px;
  }
  .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  
  .pf-about-content-ba{
    display: flex;
    align-items: center;
   
    margin-left: 80px;
    width: 580px;
    height: 200px;
  }
  .pf-about-content-ba5{
    align-items: center;

    margin-left: 90px;
    width: 580px;
    height: 200px;
    margin-top: 20px;
  }
  .pf-profile-info-1 {
    margin-top: 38px;
    margin-left: 40px;
    }
    .pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 30px;
margin-left: 40px;
}

.pf-about-content-ba4:hover::after {
  width: 97%;
}
.pf-about-content-ba1:hover::after {
  width: 100%;
}
    
  .pf-profille-info-2 {
    margin-top: 15px;
    margin-left: 40px;
  }
  .pf-profille-info-3 {
    margin-top: 15px;
    margin-left: 40px;
    
   
  }
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: -155px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
 
    border-radius: 50px;
    margin-left: 90px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;
  
    margin-left: 90px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .pf-name {
    font-size: 40px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -300px;
    margin-top: 0px;
  }
  .pf-profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: 20px;
    margin-left: 180px;
  }
  .pf-Userid{
    margin-left: 190px;
  
  }


  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  
   
  }
}
@media only screen and (min-width: 820px) and (max-width: 833px) {
  .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  
  
  .pf-about-content-ba2{

    align-items: center;
 
    margin-left: 110px;
    width: 580px;
    height: 190px;
    margin-top: 10px;
  }
  .pf-about-content-ba{
    display: flex;
    align-items: center;
   
    margin-left: 110px;
    width: 580px;
    height: 200px;
  }
  .pf-about-content-ba5{
    align-items: center;

    margin-left: 110px;
    width: 580px;
    height: 200px;
    margin-top: 20px;
  }
  .pf-profile-info-1 {
    margin-top: 38px;
    margin-left: 40px;
    }
    .pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 30px;
margin-left: 40px;
}

.pf-about-content-ba4:hover::after {
  width: 97%;
}
.pf-about-content-ba1:hover::after {
  width: 100%;
}
    
  .pf-profille-info-2 {
    margin-top: 15px;
    margin-left: 40px;
  }
  .pf-profille-info-3 {
    margin-top: 15px;
    margin-left: 40px;
    
   
  }
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: -140px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
 
    border-radius: 50px;
    margin-left: 110px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;
  
    margin-left: 110px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .pf-name {
    font-size: 40px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -300px;
    margin-top: 0px;
  }
  .pf-profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: 10px;
    margin-left: 180px;
  }


  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  
   
  }
  .pf-Userid{
    margin-left: 190px;
  
  }
}
@media only screen and (min-width: 834px) and (max-width: 883px) {
  .pf-main {
    padding: 20px;
    margin-left: -30px;

  }
  
 
  .pf-about-content-ba2{

    align-items: center;
 
    margin-left: 140px;
    width: 580px;
    height: 190px;
    margin-top: 10px;
  }
  .pf-about-content-ba{
    display: flex;
    align-items: center;
   
    margin-left: 140px;
    width: 580px;
    height: 200px;
  }
  .pf-about-content-ba5{
    align-items: center;

    margin-left: 140px;
    width: 580px;
    height: 200px;
    margin-top: 20px;
  }
  .pf-profile-info-1 {
    margin-top: 38px;
    margin-left: 40px;
    }
    .pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 30px;
margin-left: 40px;
}

.pf-about-content-ba4:hover::after {
  width: 97%;
}
.pf-about-content-ba1:hover::after {
  width: 100%;
}
    
  .pf-profille-info-2 {
    margin-top: 15px;
    margin-left: 40px;
  }
  .pf-profille-info-3 {
    margin-top: 15px;
    margin-left: 40px;
    
   
  }
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: -120px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
 
    border-radius: 50px;
    margin-left: 140px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;
  
    margin-left: 140px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .pf-name {
    font-size: 40px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -300px;
    margin-top: 0px;
  }
  .pf-profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: 0px;
    margin-left: 180px;
  }
  .pf-Userid{
    margin-left: 200px;
  
  }

  

  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
   
  }
}
@media only screen and (min-width: 884px) and (max-width: 1279px) {
  .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  .pf-profile-icon {
    display: block;
    align-items: center;
    margin-left: -100px;
  }
  .pf-Userid{
    margin-left: 200px;
  
  }
 
  .pf-about-content-ba2{

    align-items: center;
 
    margin-left: 140px;
    width: 580px;
    height: 190px;
    margin-top: 10px;
  }
  .pf-about-content-ba{
    display: flex;
    align-items: center;
   
    margin-left: 140px;
    width: 580px;
    height: 200px;
  }
  .pf-about-content-ba5{
    align-items: center;

    margin-left: 140px;
    width: 580px;
    height: 200px;
    margin-top: 20px;
  }
  .pf-profile-info-1 {
    margin-top: 38px;
    margin-left: 40px;
    }
    .pf-profille-info-pf1, 
.pf-profille-info-pff2,
.pf-profille-info-pfff3  {
margin-top: 30px;
margin-left: 40px;
}

.pf-about-content-ba4:hover::after {
  width: 97%;
}
.pf-about-content-ba1:hover::after {
  width: 100%;
}
    
  .pf-profille-info-2 {
    margin-top: 15px;
    margin-left: 40px;
  }
  .pf-profille-info-3 {
    margin-top: 15px;
    margin-left: 40px;
    
   
  }
  
  .pf-about-content-ba1{
    display: flex;
    align-items: center;

    margin-left: -120px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -10px;
  }
  
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
 
    border-radius: 50px;
    margin-left: 140px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  .pf-about-content-ba4{
    display: flex;
    align-items: center;
  
    margin-left: 140px;
    width: 210px;
    height: 50px;
    margin-top: 10px;
  }
  
  
  .pf-profille-info-ba1 {
    width: 80%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 40px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .pf-name {
    font-size: 40px;
    text-align: center;
    margin-bottom: 0px;
    margin-left: -300px;
    margin-top: 0px;
  }
  .pf-profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    margin-top: -30px;
    margin-left: 180px;
  }


  .pf-head {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  
   
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
  .pf-main {
    border: 1px solid rgb(248, 243, 243);
    padding: 20px;
    margin-left: 0px;
  }
  
  .pf-head {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    gap: 200px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-align: center;
  }
  
  .pf-profile-image {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 150px;
  
  }
  
  .pf-profile-icon-arrow {
    font-size: 2rem;
    color: #888;
  }
  
  .pf-tab.active::after {
    content: '';
    display: block;
    position: absolute;
    margin-left: 30%;
    bottom: -5px;
    left: 0;
    height: 1px;
    background-color: grey;
  }
  
  .pf-tab {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s;
    position: relative;
  }
  
  .pf-tab.active {
    border-bottom: 2px solid #000;
  }
  
  .pf-content {
    margin-top: 60px;
  }
  
  .pf-about-content {
    display: flex;
    align-items: center;
    
    
  }
  
  .pf-name {
    color: #08144c;
    margin-left: -120px;
  }
  
  
  
  .pf-aboutt-content {
  
    align-items: center;
    margin-top: 20px;
  }
  
  
  
  .pf-abouttt-content {
  
    align-items: center;
  }
  
  .pf-profile-info {
    width: 40%;
    margin-left: 25%;
    font-size: 20px;
    height: 100px;
    padding-top: 10px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  
  
  
  
  
  
  .pf-profile-info-1 {
    margin-top: 15px;
    margin-left: 40px;
    }
    
  .pf-profille-info-2 {
    margin-top: 15px;
    margin-left: 40px;
  }
  .pf-profille-info-3 {
    margin-top: 15px;
    margin-left: 40px;
    
   
  }
  
  
  .pf-add-iconne {
    margin-left: 0px;
    font-size: 20px;
  }
  .pf-add-iconnee {
    margin-left: 0px;
    font-size: 20px;
  }
  .pf-about-content-ba2{
  
    align-items: center;
    border: 1px solid white;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    margin-left: 350px;
    width: 600px;
    height: 150px;
  }
  .pf-about-content-ba{
    display: flex;
    align-items: center;
    border: 1px solid white;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    margin-left: 350px;
    width: 600px;
    height: 150px;
  }
  .pf-about-content-ba5{
    align-items: center;
    border: 1px solid white;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    margin-left: 350px;
    width: 600px;
    height: 150px;
    margin-top: 20px;
  }
  .button-disabled {
    color: aliceblue;
   
  }
  
  .pf-about-content-ba1 {
    display: flex;
    align-items: center;
    color: rgb(9, 142, 237);
    margin-left: 10px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -20px;
    position: relative;
  }
  
  .pf-about-content-ba1::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: rgb(9, 142, 237);
    bottom: 10px;
    left: 0;
    
    transition: width 0.3s ease-in-out;
  }
  
  .pf-about-content-ba1:hover::after {
    width: 100%;
  }
  
  
  
  .pf-about-content-ba4 {
    display: flex;
    align-items: center;
    color: rgb(9, 142, 237);
    margin-left: 350px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -20px;
    position: relative;
  }
  
  .pf-about-content-ba4::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: rgb(9, 142, 237);
    bottom: 10px;
    left: 0;
    
    transition: width 0.3s ease-in-out;
  }
  .pf-about-content-ba4:hover::after {
    width: 105%;
  }
  
  .pf-about-content-ba3{
    display: flex;
    align-items: center;
    border: 1px solid #ebe9e9;
    background-color: #08144c;
    color: white;
    border-radius: 50px;
    margin-left: 350px;
    width: 210px;
    height: 50px;
    margin-top: 0px;
  }
  
  
  
  .pf-account-content-delete {
    display: flex;
    align-items: center;
    color: red;
    margin-left: 450px;
    border-radius: 50px;
    width: 190px;
    height: 50px;
    margin-top: -20px;
    position: relative;
    cursor: pointer; /* Added for better UX */
  }
  
  .pf-account-content-delete::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: rgb(237, 77, 9);
    bottom: 10px;
    left: 0;
    transition: width 0.3s ease-in-out;
  }
  
  .pf-account-content-delete:hover::after {
    width: 75%;
  }
  
  
  
  .pf-profille-info-pf1, 
  .pf-profille-info-pff2,
  .pf-profille-info-pfff3  {
  margin-top: 15px;
  margin-left: 40px;
  }
  
  
  
  .pf-profile-inffoo1 {
    width: 40%;
    margin-left: 10%;
    font-size: 20px;
    height: 100px;
    padding-top: 10px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  
  .pf-profille-info-ba2 {
    width: 50%;
    margin-left: -54%;
    font-size: 20px;
    height: 50px;
    padding-top: 50px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .pf-profille-info-ba1 {
    width: 50%;
    margin-left: -84%;
    font-size: 20px;
    height: 50px;
    padding-top: 50px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  
  
  .pf-add-icon {
    margin-left: 10px;
    font-size: 18px;
  }
  
  .pf-line-hr {
    width: 50%;
    height: 0px;
    margin-left: 28%;
    color: aliceblue;
    margin-top: 10px;
  }
  .pf-verified {
    color: green;
    font-weight: bold;
    margin-left: 10px;
  }
   
  .verify-email-text {
    color: green;
    font-weight: bold;
    margin-left: 10px;
  }
  
  
  .pf-profille-info {
    width: 40%;
    margin-top: 10px;
    margin-left: 27%;
    font-size: 18px;
    height: 50px;
    padding-top: 10px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  
  .pf-profillee-info {
    width: 40%;
    margin-top: 10px;
    margin-left: 30%;
    font-size: 18px;
    height: 50px;
    padding-top: 10px;
    padding-left: 5px;
    border-radius: 5px;
    font-family: 'Times New Roman', Times, serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  
  .pf-profile-icon {
    display: block;
    align-items: center;
    margin-left: -100px;
  }
  
  .pf-profile-icon .pf-profile-iccon {
    font-size: 50px;
  }
  
  .pf-profile-icon .pf-profile-icconn {
    font-size: 20px;
    margin-left: 10px;
  }
}
