/* Base styles */
.comer-main {
  border: 1px solid white;
}


.comer-about-content-view {
  /* border: 1px solid #e80d0d; */

  display: flex;
  align-items: center;
  color: rgb(9, 142, 237);
  margin-left: 10px;
  border-radius: 50px;
  width: 190px;
  height: 50px;
  margin-top: -0px;
  position: relative;
}
.nonew-passen{
  text-align: center;
}
.modal-actions{
  text-align: center;

}

.comer-about-content-view::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: rgb(9, 142, 237);
  bottom: 10px;
  left: 0;
  
  transition: width 0.3s ease-in-out;
}

.comer-about-content-view:hover::after {
  width: 90%;
}


.comer-contents {
  width: 50%;
  overflow-y: auto;
  height: 500px;
  margin-left: 25%;
  border-radius: 10px;
  margin-top: 160px;
}
.comer-page{
  color: #136ee4;
  margin-left: 600px;
}


.comer-book, .comer-user, .comer-userpass, .comer-userpasss, .comer-user1, .comer-user2, .comer-user3, .comer-userprice {
  font-weight: 600;
  color: #05144c;
  margin: 0;
  margin-left: 10px;
}

.comer-passeng {
  margin-left: 550px;
}

.comer-user-date-music {
  width: 500px;

  margin-top: -25px;
}

.comer-info {
  width: 410px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.comer-card {
  border: 1px solid #eeebeb;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  width: 98%;
  box-shadow: 0 0 10px rgba(165, 166, 165, 0.91);
  background-color: white;
}
.comer-card1 {
  border: 1px solid #eeebeb;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  width: 98%;
  box-shadow: 0 0 10px rgba(165, 166, 165, 0.91);
  background-color: white;
}
.comer-card {
  height: 185px;
}

.comer-card1 {
  height: 100px;
  margin-top: 30px;
}

.comer-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  width: 100%;
  margin: 0 auto;
}

.comer-usering-found, .comer-usering {
  width: 760px;
  border: 1px solid rgb(12, 12, 12);
  height: 600px;
}

.comer-useredsect {
  margin-top: -10px;
}

.comer-about-content {
  display: flex;
  text-align: center;
  align-items: center;
  padding-left: 17px;
  border: 1px solid #ebe9e9;
  background-color: #08144c;
  color: white;
  margin-top: 25px;
  border-radius: 50px;
  margin-left: 10px;
  width: 210px;
  height: 50px;
}

.comer-add-icon {
  margin-left: 10px;
  font-size: 18px;
}

/* Table styles */
.new-passenger-table {
  margin-top: 30px;
  width: 100%;
  max-width: 800px;
  overflow-x: auto;
}

.new-passenger-table table {
  width: 100%;
  border-collapse: collapse;
}

.new-passenger-table th, .new-passenger-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.new-passenger-table th {
  background-color: #f2f2f2;
}

.new-passenger-table-booking {
  width: 83.3%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(249, 247, 247, 0.15);
}

.new-passenger-table-booking thead tr {
  color: #ffffff;
  text-align: left;
}

th, td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: middle;
}

.table-wrapper {
  overflow-y: auto;
}

.new-passenger-table-booking tbody tr {
  border-bottom: 1px solid #dddddd;
}

.new-passenger-table-booking tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.new-passenger-table-booking tbody tr:last-of-type {
  border-bottom: 2px solid #dfdfdf;
}

.new-passenger-table-booking thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #05144c;
  color: white;
}

/* Centering the Close Button in Modal */
.close-new {
  border: 1px solid rgb(235, 228, 228);
  background-color: #05144c;
  width: 150px;
  color: #f5f5f7;
  margin: auto;
  text-align: center;
  padding: 10px;
  border-radius: 50px;
  margin-left: 100px;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 20px;
}

/* Modal and Overlay Styles */
.modal-pop {
  position: fixed;
  top: 50%;
  left: 700px;
  transform: translate(-40%, -40%);
  background: white;
  padding: 20px;
  z-index: 1000;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-left: 50%; */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}



/* Responsive Styles */
@media only screen and (min-width: 320px) and (max-width: 343px) {
  .comer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    height: 80vh;
    font-size: 14px;
    overflow: hidden;


  }
  
  .comer-user-date-music {


    width: 500px;
    margin-top: -20px;
    margin-left: -20px;
  }
  .comer-user-date-music-smoking{
    width: 500px;
    margin-top: -10px;
    margin-left: -20px;
  }
  .comer-user, .comer-userpass, .comer-userpasss {
    margin-left: 10px;
  }
  .comer-info {
    width: 350px;
    display: flex;
    align-items: center;
    gap: 0px;
  }
  .comer-info {

    width: 300px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
 
 
  .comer-page{
    color: rgb(9, 142, 237);

    margin-left: 200px;
  }
  
  .comer-card {
    border: 1px solid #a4a2a2;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    margin-left: 12px;
    box-shadow: 0 0 10px rgba(93, 93, 92, 0.945);
    width: 300px;
    height: 190px;
  }
  .comer-card1 {
    height: 80px;
   margin-left: 50px;
    border: 1px solid #a0a0a0;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    margin-left: 12px;
    box-shadow: 0 0 10px rgba(93, 93, 92, 0.945);
    width: 300px;
  }
  .comer-passeng {
    margin-top: -10px;

    width: 200px;
    margin-left: 160px;
  }
  .comer-contents {

    margin-top: 140px;
    width: 98%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 1%;
    border-radius: 10px;
    height: 500px;
  }
  .close-new {
    margin-left: 60px;
    margin-top: 25px;
  }
  .new-passenger-table table {
    width: 880px;
    border-collapse: collapse;
  }
  .modal-pop {
    top: 58%;
    left: 50%;
    transform: translate(-50%, -40%);
    max-width: 100%;
    max-height: 75%;
    overflow-y: auto;
  }
  .new-passenger-table {
    margin-top: -0px;
    margin-left: 5px;
    width: 95%;
  }


}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .comer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    height: 80vh;
    overflow: hidden;
  }
  
  .comer-user-date-music {
    width: 500px;
    margin-top: -25px;
    margin-left: -20px;
  }
  .comer-user-date-music-smoking{
    width: 500px;
    margin-top: 0px;
    margin-left: -20px;
  }
  .comer-user, .comer-userpass, .comer-userpasss {
    margin-left: 10px;
  }
  .comer-info {
    width: 300px;
    display: flex;
    align-items: center;
    gap: 0px;
  }

  .comer-card {
    height: 210px;
  }
  .comer-page{
    color: rgb(9, 142, 237);

    margin-left: 200px;
  }
  
  .comer-card {
    border: 1px solid #a0a0a0;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    margin-right: 10px;
    box-shadow: 0 0 10px rgba(93, 93, 92, 0.945);
    width: 340px;
  }
  .comer-card1 {
    height: 80px;
   margin-left: 50px;
    border: 1px solid #a0a0a0;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    margin-right: 10px;
    box-shadow: 0 0 10px rgba(93, 93, 92, 0.945);
    width: 340px;
   padding-top: 5px;
   padding-left: 0px;
  }
  .comer-passeng {
    margin-top: -10px;

    width: 200px;
    margin-left: 200px;
  }
  .comer-contents {

    margin-top: 140px;
    width: 98%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 1%;
    border-radius: 10px;
    height: 500px;
  }
  .close-new {
    margin-left: 80px;
    margin-top: 25px;
  }
  .new-passenger-table table {
    width: 790px;
    border-collapse: collapse;
  }
  .modal-pop {
    top: 48%;
    left: 50%;
    transform: translate(-50%, -40%);
    max-width:100%;
    max-height: 75%;
    overflow-y: auto;
  }
  .new-passenger-table {
    margin-top: -0px;
    margin-left: -0px;
    width: 300px;
  }


}
@media only screen and (min-width: 375px) and (max-width: 383px) {
  .comer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    height: 80vh;
    overflow: hidden;
  }
  .comer-info {

    width: 300px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .comer-card {
    height: 210px;
  }
  .comer-page{
    color: rgb(9, 142, 237);

    margin-left: 200px;
  }
  
  
  .comer-user-date-music {
    width: 500px;
    margin-left: -10px;
    margin-top: -25px;
  }
  .comer-user, .comer-userpass, .comer-userpasss {
    margin-left: 10px;
  }
  .comer-card, .comer-card1 {
    border: 1px solid #a0a0a0;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    margin-right: 10px;
    box-shadow: 0 0 10px rgba(93, 93, 92, 0.945);
    width: 360px;
  }
  .comer-card1{
    padding-top: 10px;
    padding-left: 0px;
  }
  .comer-passeng {
    margin-top: -10px;

    width: 200px;
    margin-left: 220px;
  }
  .comer-contents {

    margin-top: 140px;
    width: 98%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 1%;
    border-radius: 10px;
    height: 500px;
  }
  .close-new {
    margin-left: 100px;
    margin-top: 45px;
  }
  .new-passenger-table table {
    width: 800px;
    border-collapse: collapse;
  }
  .modal-pop {
    top: 38%;
    left: 50%;
    transform: translate(-50%, -25%);
    max-width: 100%;
    max-height: 75%;
    overflow-y: auto;
  }
  .new-passenger-table {
    margin-top: -0px;
    margin-right: 0px;
    width: 99%;
  }


}
@media only screen and (min-width: 390px) and (max-width: 411px) {
  .comer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    height: 80vh;
    overflow: hidden;
  }
  .comer-info {

    width: 300px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .comer-card {
    height: 210px;
  }
  .comer-page{
    color: rgb(9, 142, 237);

    margin-left: 200px;
  }
  
  .comer-user-date-music {
    width: 500px;
    margin-top: -25px;
  }
  .comer-user, .comer-userpass, .comer-userpasss {
    margin-left: 10px;
  }
  .comer-card, .comer-card1 {
    border: 1px solid #a0a0a0;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    margin-right: 10px;
    box-shadow: 0 0 10px rgba(93, 93, 92, 0.945);
    width: 380px;
  }
  .comer-passeng {
    margin-top: -10px;

    width: 200px;
    margin-left: 230px;
  }
  .comer-contents {

    margin-top: 140px;
    width: 98%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 1%;
    border-radius: 10px;
    height: 500px;
  }
  .close-new {
    margin-left: 100px;
    margin-top: 25px;
  }
  .new-passenger-table table {
    width: 815px;
    border-collapse: collapse;
  }
  .modal-pop {
    top: 38%;
    left: 50%;
    transform: translate(-50%, -30%);
    max-width: 100%;
    max-height: 85%;
    overflow-y: auto;
  }
  .new-passenger-table {
    margin-top: -0px;
    margin-right: 0px;
    width: 99%;
  }


}
@media only screen and (min-width: 412px) and (max-width: 427px) {
  .comer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    height: 80vh;
    overflow: hidden;
  }
  .comer-info {

    width: 300px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .comer-card {
    height: 210px;
  }
  .comer-page{
    color: rgb(9, 142, 237);

    margin-left: 200px;
  }
  .comer-user-date-music {
    width: 500px;
    margin-top: -25px;
  }
  .comer-user, .comer-userpass, .comer-userpasss {
    margin-left: 10px;
  }
  .comer-card, .comer-card1 {
    border: 1px solid #a0a0a0;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    margin-right: 10px;
    box-shadow: 0 0 10px rgba(93, 93, 92, 0.945);
    width: 400px;
  }
  .comer-passeng {
    margin-top: -10px;

    width: 200px;
    margin-left: 250px;
  }
  .comer-contents {

    margin-top: 140px;
    width: 98%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 1%;
    border-radius: 10px;
    height: 500px;
  }
  .close-new {
    margin-left:100px;
    margin-top: 25px;
  }
  .new-passenger-table table {
    width: 880px;
    border-collapse: collapse;
  }
  .modal-pop {
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 75%;
    overflow-y: auto;
  }
  .new-passenger-table {
    margin-top: -0px;
    margin-right: 0px;
    width: 99%;
  }


}
@media only screen and (min-width: 428px) and (max-width: 767px) {
  .comer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    height: 80vh;
    overflow: hidden;
  }
  .comer-page{
    color: rgb(9, 142, 237);

    margin-left: 200px;
  }
  .comer-card {
    height: 220px;
  }
  
  .comer-info {

    width: 300px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .comer-user-date-music {
    width: 500px;
   margin-left: -20px;
    margin-top: -30px;
  }
  .comer-user, .comer-userpass, .comer-userpasss {
    margin-left: 10px;
  }
  .comer-card, .comer-card1 {
    border: 1px solid #a0a0a0;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    margin-right: 10px;
    box-shadow: 0 0 10px rgba(93, 93, 92, 0.945);
    width: 400px;
  }
  .comer-passeng {
    margin-top: -0px;
  

    width: 200px;
    margin-left: 240px;
  }

  .comer-contents {

    margin-top: 140px;
    width: 98%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: 1%;
    border-radius: 10px;
    height: 500px;
  }
  .close-new {
    margin-left: 100px;
    margin-top: 25px;
  }
  .new-passenger-table table {
    width: 800px;
    border-collapse: collapse;
  }
  .modal-pop {
    top: 38%;
    left: 50%;
    transform: translate(-50%, -30%);
    max-width: 95%;
    max-height: 75%;
    overflow-y: auto;
  }
  .new-passenger-table {
    margin-top: -0px;
    margin-left: -10px;
    width: 390px;
  }


}
@media only screen and (min-width: 768px) and (max-width: 799px) {
  .comer-contents {
    width: 80%;
    overflow-y: scroll;
    height: 760px;
    margin-left: 10%;
    border-radius: 10px;
  }

  .comer-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    width: 97%;
    box-shadow: 0 0 10px rgba(165, 54, 54, 0.1);
  }

  .comer-passeng {
    margin-left: 400px;
  }
   .new-passenger-table table {
    width: 800px;
    border-collapse: collapse;
  }

  .modal-pop {
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%);
    max-width: 95%;
    max-height: 55%;
    overflow-y: auto;
  }
  .comer-page{
    color: rgb(9, 142, 237);

    margin-left: 400px;
  }
  .comer-card {
    height: 200px;
  }

  .close-new {
    margin-left: 250px;
    margin-top: 30px;
  }
}
@media only screen and (min-width: 800px) and (max-width: 819px) {
  .comer-contents {
    width: 80%;
    overflow-y: scroll;
    height: 760px;
    margin-left: 10%;
    border-radius: 10px;
  }

  .comer-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    width: 97%;
    box-shadow: 0 0 10px rgba(165, 54, 54, 0.1);
  }
  .comer-card {
    height: 200px;
  }

  .comer-passeng {
    margin-left: 450px;
  }
   .new-passenger-table table {
    width: 800px;
    border-collapse: collapse;
  }

  .modal-pop {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -40%);
    max-width: 95%;
    max-height: 55%;
    overflow-y: auto;
  }

  .close-new {
    margin-left: 300px;
    margin-top: 30px;
  }
  .comer-page{
    color: rgb(9, 142, 237);

    margin-left: 450px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 852px) {
  .comer-contents {
    width: 80%;
    overflow-y: scroll;
    height: 760px;
    margin-left: 10%;
    border-radius: 10px;
  }
  .comer-card {
    height: 200px;
  }

  .comer-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    width: 97%;
    box-shadow: 0 0 10px rgba(165, 54, 54, 0.1);
  }
  .comer-page{
    color: rgb(9, 142, 237);

    margin-left: 500px;
  }

  .comer-passeng {
    margin-left: 450px;
  }
   .new-passenger-table table {
    width: 790px;
    border-collapse: collapse;
  }

  .modal-pop {
    position: fixed;
    top: 30%;
    left: 10px;
    transform: translate(-0%, -30%);
    background: white;
    padding: 10px;
    z-index: 1000;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 98%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-left: 50%; */
  }

  .close-new {
    margin-left: 300px;
    margin-top: 30px;
  }
}
@media only screen and (min-width: 884px) and (max-width: 1279px) {
  /* Base styles */
.comer-main {
  border: 1px solid rgb(254, 252, 252);
}

.comer-contents {
  width: 80%;
  overflow-y: auto;
  height: 500px;
  margin-left: 10%;
  border-radius: 10px;
  margin-top: 160px;
}

.comer-book, .comer-user, .comer-userpass, .comer-userpasss, .comer-user1, .comer-user2, .comer-user3, .comer-userprice {
  font-weight: 600;
  color: #05144c;
  margin: 0;
  margin-left: 10px;
}

.comer-passeng {
  margin-left: 500px;
}

.comer-user-date-music {
  width: 500px;

  margin-top: -30px;
}

.comer-info {

  width: 450px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.comer-card, .comer-card1 {
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  width: 97%;
  box-shadow: 0 0 10px rgba(42, 43, 42, 0.91);
  background-color: white;
}

.comer-card {
  height: 190px;
}

.comer-card1 {
  height: 100px;
  margin-top: 30px;
}

.comer-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  width: 100%;
  margin: 0 auto;
}

.comer-usering-found, .comer-usering {
  width: 760px;
  border: 1px solid rgb(12, 12, 12);
  height: 600px;
}

.comer-useredsect {
  margin-top: -10px;
}

.comer-about-content {
  display: flex;
  text-align: center;
  align-items: center;
  padding-left: 17px;
  border: 1px solid #ebe9e9;
  background-color: #08144c;
  color: white;
  margin-top: 25px;
  border-radius: 50px;
  margin-left: 10px;
  width: 210px;
  height: 50px;
}

.comer-add-icon {
  margin-left: 10px;
  font-size: 18px;
}

/* Table styles */
.new-passenger-table {
  margin-top: 30px;
  width: 100%;
  max-width: 800px;
  overflow-x: auto;
}

.new-passenger-table table {
  width: 100%;
  border-collapse: collapse;
}

.new-passenger-table th, .new-passenger-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.new-passenger-table th {
  background-color: #f2f2f2;
}

.new-passenger-table-booking {
  width: 83.3%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(249, 247, 247, 0.15);
}

.new-passenger-table-booking thead tr {
  color: #ffffff;
  text-align: left;
}

th, td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: middle;
}

.table-wrapper {
  overflow-y: auto;
}

.new-passenger-table-booking tbody tr {
  border-bottom: 1px solid #dddddd;
}

.new-passenger-table-booking tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.new-passenger-table-booking tbody tr:last-of-type {
  border-bottom: 2px solid #dfdfdf;
}

.new-passenger-table-booking thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #05144c;
  color: white;
}

/* Centering the Close Button in Modal */
.close-new {
  border: 1px solid rgb(235, 228, 228);
  background-color: #05144c;
  width: 150px;
  color: #f5f5f7;
  margin: auto;
  text-align: center;
  padding: 10px;
  border-radius: 50px;
  margin-left: 300px;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 20px;
}

/* Modal and Overlay Styles */
.modal-pop {
  position: fixed;
  top: 30%;
  left: 30px;
  transform: translate(-0%, -30%);
  background: white;
  padding: 20px;
  z-index: 1000;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-left: 50%; */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.comer-page{
  color: #000;
  margin-left: 500px;
}

}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
  /* Base styles */
.comer-main {
  border: 1px solid white;
}

.comer-contents {

  width: 60%;
  overflow-y: auto;
  height: 500px;
  margin-left: 25%;
  border-radius: 10px;
  margin-top: 160px;
}

.comer-book, .comer-user, .comer-userpass, .comer-userpasss, .comer-user1, .comer-user2, .comer-user3, .comer-userprice {
  font-weight: 500;

  color: #05144c;
  margin: 0;
  margin-left: 10px;
}
.comer-card-passeng{
  margin-right: 150px;
}

.comer-passeng {

  margin-left: 450px;
}

.comer-user-date-music {
  width: 600px;

  margin-top: -25px;
}

.comer-info {

  width: 500px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.comer-card, .comer-card1 {
  border: 1px solid #eeebeb;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  width: 98%;
  box-shadow: 0 0 10px rgba(165, 166, 165, 0.91);
  background-color: white;
}

.comer-card {
  height: 210px;
}

.comer-card1 {
  height: 100px;
  margin-top: 30px;
}

.comer-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  width: 100%;
  margin: 0 auto;
}

.comer-usering-found, .comer-usering {
  width: 760px;
  border: 1px solid rgb(12, 12, 12);
  height: 600px;
}

.comer-useredsect {
  margin-top: -10px;
}

.comer-about-content {
  display: flex;
  text-align: center;
  align-items: center;
  padding-left: 17px;
  border: 1px solid #ebe9e9;
  background-color: #08144c;
  color: white;
  margin-top: 25px;
  border-radius: 50px;
  margin-left: 10px;
  width: 210px;
  height: 50px;
}

.comer-add-icon {
  margin-left: 10px;
  font-size: 18px;
}

/* Table styles */
.new-passenger-table {
  margin-top: 30px;
  width: 100%;
  max-width: 800px;
  overflow-x: auto;
}

.new-passenger-table table {
  width: 100%;
  border-collapse: collapse;
}

.new-passenger-table th, .new-passenger-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.new-passenger-table th {
  background-color: #f2f2f2;
}

.new-passenger-table-booking {
  width: 83.3%;
  overflow-x: auto;
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(249, 247, 247, 0.15);
}

.new-passenger-table-booking thead tr {
  color: #ffffff;
  text-align: left;
}

th, td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: middle;
}

.table-wrapper {
  overflow-y: auto;
}

.new-passenger-table-booking tbody tr {
  border-bottom: 1px solid #dddddd;
}
.comer-page{
  color: #000;
  margin-left: 300px;
}
.new-passenger-table-booking tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.new-passenger-table-booking tbody tr:last-of-type {
  border-bottom: 2px solid #dfdfdf;
}

.new-passenger-table-booking thead th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #05144c;
  color: white;
}

/* Centering the Close Button in Modal */
.close-new {
  border: 1px solid rgb(235, 228, 228);
  background-color: #05144c;
  width: 150px;
  color: #f5f5f7;
  margin: auto;
  text-align: center;
  padding: 10px;
  border-radius: 50px;
  margin-left: 300px;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 20px;
}

/* Modal and Overlay Styles */
.modal-pop {
  position: fixed;
  top: 50%;
  left: 220px;
  transform: translate(-0%, -40%);
  background: white;
  padding: 20px;
  z-index: 1000;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-left: 50%; */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}


}